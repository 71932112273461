import { TChartStyle } from '@fto/lib/charting/auxiliary_classes_charting/ChartingEnums'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { makeAutoObservable } from 'mobx'

export type SettingsType = {
    TestingSpeed: number
    isPlaying: boolean
    isSymbolLoading: boolean
    ChartStyle: TChartStyle
}

const initialSettings = {
    TestingSpeed: 9,
    isPlaying: false,
    isSymbolLoading: false,
    ChartStyle: TChartStyle.cs_Bar
}

class ChartSettingsStore {
    settings: SettingsType

    constructor() {
        makeAutoObservable(this)

        this.settings = initialSettings
        this.setSettings = this.setSettings.bind(this)
        this.resetStore = this.resetStore.bind(this)
        this.updateChartSettings = this.updateChartSettings.bind(this)
    }

    setSettings(updater: ((prevSettings: SettingsType) => SettingsType) | SettingsType) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.settings = {
                ...this.settings,
                ...updater(this.settings)
            }
        } else {
            // If updater is an object, directly apply the updates
            this.settings = {
                ...this.settings,
                ...updater
            }
        }
    }

    updateChartSettings() {
        GlobalChartsController.Instance.getActiveChart()?.ImportSettings()
    }

    resetStore() {
        this.settings = initialSettings
    }
}

export default new ChartSettingsStore()
