import { TIndexBuffer } from '../api/IIndicatorApi'
import { t } from 'i18next'

import Constants, {
    TDrawStyle,
    TMAType,
    TOptValue_number,
    TOptionType,
    TOutputWindow,
    TPenStyle,
    TPriceType
} from '../api/IndicatorInterfaceUnit'
import { UserIndicator } from '../api/UserIndicator'

export default class MovingAverage extends UserIndicator {
    period: TOptValue_number = new TOptValue_number(8)
    Shift: TOptValue_number = new TOptValue_number(0)
    MAtype: TOptValue_number = new TOptValue_number(TMAType.ma_SMA)
    ApplyToPrice: TOptValue_number = new TOptValue_number(TPriceType.pt_Close)
    VShift: TOptValue_number = new TOptValue_number(0)

    // Buffers
    SMA!: TIndexBuffer
    SSMA!: TIndexBuffer

    Init(): void {
        this.api.RecalculateMeAlways()
        this.api.IndicatorShortName(t('indicators.movingAverage'))
        this.api.SetOutputWindow(TOutputWindow.ow_ChartWindow)
        this.api.SetEmptyValue(0)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.general.generalFields.period'), TOptionType.ot_Integer, this.period)
        this.api.SetOptionRange(t('indicatorModal.movingAverage.fields.period'), 1, Constants.MAX_INT)
        this.api.RegOption(t('indicatorModal.movingAverage.fields.hShift'), TOptionType.ot_Integer, this.Shift)
        this.api.RegOption(t('indicatorModal.movingAverage.fields.vShift'), TOptionType.ot_Integer, this.VShift)

        this.api.RegMATypeOption(this.MAtype, t('indicatorModal.movingAverage.fields.maType'))

        this.api.RegApplyToPriceOption(this.ApplyToPrice, t('indicatorModal.general.applyToPrice'))

        this.SMA = this.api.CreateIndexBuffer()
        this.SSMA = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(1)
        this.api.SetIndexBuffer(0, this.SSMA)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#FF0000')
        this.api.SetIndexLabel(0, t('indicatorModal.general.generalFields.ma'))

        this.api.SetIndexDrawBegin(0, this.period.value - 1 + this.Shift.value)
    }

    Calculate(index: number): void {
        if (index + this.period.value >= this.api.Bars()) {
            return
        }

        const calculatedSMA = this.api.GetMA(
            index,
            0,
            this.period.value,
            this.MAtype.value,
            this.ApplyToPrice.value,
            this.SMA.getValue(index + 1)
        )

        this.SMA.setValue(index, calculatedSMA)

        this.SSMA.setValue(index, calculatedSMA + this.VShift.value * this.api.Point())
    }

    OnParamsChange() {
        this.api.SetBufferShift(0, this.Shift.value)
    }
}
