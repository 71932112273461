import { FC, useCallback } from 'react'

import cx from 'classnames'
import { Button, Flex, Link, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { DASHBOARD_ROUTE, makeRoute } from '@root/constants/routes'

const SUPPORT_LINK = 'support@forextester.com'

type Props = {
    isOnline?: boolean
    noBoxShadow?: boolean
}

const ErrorBoundaryPreview: FC<Props> = ({ isOnline = true, noBoxShadow = false }) => {
    const { t } = useTranslation()

    const redirectToDashboard = useCallback(() => {
        window.location.assign(makeRoute(DASHBOARD_ROUTE))
    }, [])

    return (
        <Flex
            className={cx(styles.container, { [styles.noBoxShadow]: noBoxShadow })}
            direction='column'
            gap={24}
            alignItems='center'
        >
            <Icon name={isOnline ? 'page-failed' : 'no-internet-connection'} size={140} />
            <Flex direction='column' gap={8} alignItems='center'>
                <Typography type='h3-bold' color='gray-1000' align='center'>
                    {t(`errorBoundary.${isOnline ? 'error' : 'noNetwork'}.title`)}
                </Typography>
                <Typography type='text-regular' color='gray-900' align='center'>
                    {t(`errorBoundary.${isOnline ? 'error' : 'noNetwork'}.subtitle`)}
                </Typography>
            </Flex>
            <Button
                onClick={redirectToDashboard}
                type='primary'
                label={t(`errorBoundary.${isOnline ? 'error' : 'noNetwork'}.refreshButtonTitle`)}
                before={<Icon name='arrow-path' size={24} color='var(--color-white)' />}
            />
            <Flex alignItems='center' justifyContent='center' gap={4} className={styles.Contacts}>
                <Typography type='interface-medium' color='gray-900'>
                    {t('errorBoundary.contactUs')}
                </Typography>
                <Link className={styles.Link} href={`mailto:${SUPPORT_LINK}`}>
                    {SUPPORT_LINK}
                </Link>
            </Flex>
        </Flex>
    )
}

export default ErrorBoundaryPreview
