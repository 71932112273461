import { KeyboardEvent, FC, useEffect, useCallback, useState } from 'react'
import debounce from 'lodash/debounce'

import {
    BarForwardBarBackCounterType,
    SetBarForwardBarBackEventsTotalCountType
} from '@fto/chart_components/ProjectInterface/types'
import GlobalChartsController from '@fto/lib//globals/GlobalChartsController'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'

import MainChartComponent from './ChartComponent/MainChartComponent'

import styles from '@fto/chart_components/ChartStyle.module.scss'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'

interface ChartWindowComponentProps {
    chartInstance: TChartWindow
    symbolName: string
    setBarForwardBarBackEventsTotalCount: SetBarForwardBarBackEventsTotalCountType
    showBorderWhenSelected: boolean
}

const ChartWindowComponent: FC<ChartWindowComponentProps> = ({
    chartInstance,
    symbolName,
    setBarForwardBarBackEventsTotalCount,
    showBorderWhenSelected
}) => {
    DebugUtils.logTopic(ELoggingTopics.lt_Loading, 'Creating ChartWindowComponent')
    const [repaintCount, setRepaintCount] = useState(0)

    const debouncedCounterUpdate = useCallback(debounce(setBarForwardBarBackEventsTotalCount, 100), [
        setBarForwardBarBackEventsTotalCount
    ])

    useEffect(() => {
        GlobalChartsController.Instance.addChart(chartInstance)
    }, [])

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            //ToDo: move key logging to hotkeys
            if (e.key.toLowerCase() === ' ' || e.key === 'ArrowRight' /* space */) {
                const isNewHotKey = e.key === 'ArrowRight'
                debouncedCounterUpdate(
                    (prevState: BarForwardBarBackCounterType): BarForwardBarBackCounterType => ({
                        ...prevState,
                        bar_forward_new: isNewHotKey ? prevState.bar_forward_new + 1 : prevState.bar_forward_new,
                        bar_forward_old: !isNewHotKey ? prevState.bar_forward_old + 1 : prevState.bar_forward_old
                    })
                )
                return
            }

            if (e.key === 'Backspace' || e.key === 'ArrowLeft') {
                const isNewHotKey = e.key === 'ArrowLeft'

                debouncedCounterUpdate((prevState) => ({
                    ...prevState,
                    bar_back_new: isNewHotKey ? prevState.bar_back_new + 1 : prevState.bar_back_new,
                    bar_back_old: !isNewHotKey ? prevState.bar_back_old + 1 : prevState.bar_back_old
                }))
                return
            }
        },
        [debouncedCounterUpdate]
    )

    const handleChartCreated = useCallback(() => {
        setRepaintCount((prevCount) => prevCount + 1)
    }, [])

    const handleMouseEnter = useCallback((event: any) => {
        chartInstance.OnMouseEnter(event)
    }, [])

    const handleMouseLeave = useCallback((event: any) => {
        chartInstance.OnMouseLeave(event)
    }, [])

    return (
        <div
            className={styles.chartContainer}
            id={`chart-container-${chartInstance.getID()}`}
            tabIndex={0}
            onKeyDown={(event) => handleKeyDown(event)}
            onMouseEnter={(event) => handleMouseEnter(event)}
            onMouseLeave={(event) => handleMouseLeave(event)}
        >
            <MainChartComponent
                chartWindow={chartInstance}
                symbolName={symbolName}
                showBorderWhenSelected={showBorderWhenSelected}
            />
        </div>
    )
}

export default ChartWindowComponent
