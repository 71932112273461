import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { Button, Flex, addModal, Separator } from '@fto/ui'
import CreateOrderStore from '@fto/lib/store/createOrder'
import { OrderDataType } from '@root/pages/ChartPage/components/Terminal/types'
import { MODAL_NAMES } from '@root/constants/modalNames'
import OrdersStore from '@fto/lib/store/ordersStore'
import { getOrderTypeLabel } from '@root/pages/ChartPage/components/Terminal/components/Table/components/Body/components/Row/components/CellRenderer/utils'

import styles from '../common.module.scss'

type Props = {
    activeTab: string
    removeMenu: () => void
    order?: OrderDataType
}

const TerminalMenuContent: FC<Props> = ({ activeTab, removeMenu, order }) => {
    const { t } = useTranslation()
    const isPending = activeTab === 'pending'

    const handleNewOrder = useCallback(() => {
        addModal(MODAL_NAMES.chart.orderModal, { source: 'terminal_context_menu' })
        removeMenu()
    }, [removeMenu])

    const closeOrder = useCallback(() => {
        if (!order) return
        OrdersStore.closeOrder(order)
        removeMenu()
    }, [order, OrdersStore.closeOrder, removeMenu])

    const modifyOrder = useCallback(() => {
        if (!order) return
        const { editOrder } = CreateOrderStore

        addModal(MODAL_NAMES.chart.orderModal, {
            isEdit: true,
            headerLabel: t('orders.modal.editHeader', {
                id: order.id,
                orderType: t(getOrderTypeLabel(order.type))
            })
        })
        editOrder(order)
        removeMenu()
    }, [order, removeMenu])

    const reversePosition = useCallback(() => {
        if (!order) return
        CreateOrderStore.reverseOrder(order)
        removeMenu()
    }, [order, CreateOrderStore.moveToBrakeEven, removeMenu])

    const doublePosition = useCallback(() => {
        if (!order) return
        CreateOrderStore.doublePosition(order)
        removeMenu()
    }, [order, CreateOrderStore.moveToBrakeEven, removeMenu])

    const moveToBrakeEven = useCallback(() => {
        if (!order) return
        CreateOrderStore.moveToBrakeEven(order)
        removeMenu()
    }, [order, CreateOrderStore.moveToBrakeEven, removeMenu])

    const closeAllMarketOrders = useCallback(() => {
        OrdersStore.closeAllOpenOrders()
        removeMenu()
    }, [OrdersStore.closeAllOpenOrders, removeMenu])

    const closeAllPendingOrders = useCallback(() => {
        OrdersStore.closeAllPendingOrders()
        removeMenu()
    }, [OrdersStore.closeAllPendingOrders, removeMenu])

    return (
        <Flex direction='column' gap={4} className={styles.content}>
            <Button
                before={<Icon name='menu-new-order' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('contextMenu.newOrder')}
                onClick={handleNewOrder}
                block
            />
            {order && (
                <>
                    <Separator width='90%' />
                    <Button
                        before={<Icon className={styles.smallIcon} name='edit' size={12} />}
                        classNames={{ content: styles.text }}
                        size='tiny'
                        type='tetriatry-white'
                        label={t('contextMenu.modifyOrder')}
                        onClick={modifyOrder}
                        block
                    />
                    {!isPending && (
                        <>
                            <Button
                                before={<Icon className={styles.smallIcon} name='arrow-turn-down' size={12} />}
                                classNames={{ content: styles.text }}
                                size='tiny'
                                type='tetriatry-white'
                                label={t('contextMenu.reverseOrder')}
                                onClick={reversePosition}
                                block
                            />
                            <Button
                                before={<Icon className={styles.smallIcon} name='double-arrow' size={12} />}
                                classNames={{ content: styles.text }}
                                size='tiny'
                                type='tetriatry-white'
                                label={t('contextMenu.doublePosition')}
                                onClick={doublePosition}
                                block
                            />
                            <Button
                                before={<Icon className={styles.smallIcon} name='move-sl-to-breakeven' size={12} />}
                                classNames={{ content: styles.text }}
                                size='tiny'
                                type='tetriatry-white'
                                label={t('contextMenu.moveSlToBreakEven')}
                                onClick={moveToBrakeEven}
                                block
                            />
                        </>
                    )}
                </>
            )}
            <Separator width='90%' />
            {order && (
                <>
                    <Button
                        before={
                            isPending ? (
                                <Icon name='trash-line' size={16} />
                            ) : (
                                <Icon className={styles.smallIcon} name='stop' size={12} />
                            )
                        }
                        classNames={{ content: styles.text }}
                        size='tiny'
                        type='tetriatry-white'
                        label={isPending ? t('contextMenu.deleteOrder') : t('contextMenu.closeOrder')}
                        onClick={closeOrder}
                        block
                    />
                </>
            )}
            <Button
                before={
                    isPending ? (
                        <Icon name='trash-line' size={16} />
                    ) : (
                        <Icon className={styles.smallIcon} name='stop' size={12} />
                    )
                }
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={isPending ? t('contextMenu.deleteAllOrders') : t('contextMenu.closeAllOrders')}
                onClick={isPending ? closeAllPendingOrders : closeAllMarketOrders}
                block
            />
        </Flex>
    )
}

export default TerminalMenuContent
