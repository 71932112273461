import { TBrushStyle, TColor, TPenStyle, TRect } from '../delphi_compatibility/DelphiBasicTypes'
import { TRoundRectType } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TMkFontStyle } from '@fto/lib/drawing_interface/vclCanvas'
import StrangeError from '../common/common_errors/StrangeError'
import StrangeSituationNotifier from '../common/StrangeSituationNotifier'

export class IGPGraphics {
    private context: CanvasRenderingContext2D
    private canvas: HTMLCanvasElement

    constructor(canvas: HTMLCanvasElement) {
        const theContext = canvas.getContext('2d')
        if (!theContext) {
            throw new Error('Failed to get 2d context')
        }
        this.context = theContext
        this.canvas = canvas
    }

    public get Canvas(): HTMLCanvasElement {
        return this.canvas
    }

    public get Context(): CanvasRenderingContext2D {
        return this.context
    }

    drawLine(pen: IGPPen, x1: number, y1: number, x2: number, y2: number) {
        this.context.save()

        this.context.strokeStyle = pen.color
        this.context.globalAlpha = pen.opacity
        this.context.lineWidth = pen.width
        pen.applyDashPattern(this.context)
        this.context.beginPath()
        this.context.moveTo(x1, y1)
        this.context.lineTo(x2, y2)
        this.context.stroke()

        this.context.restore()
    }

    public roundRect(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        radius: number
    ) {
        if (radius > width / 2) radius = width / 2
        if (radius > height / 2) radius = height / 2

        ctx.beginPath()
        ctx.moveTo(x + radius, y)
        ctx.lineTo(x + width - radius, y)
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
        ctx.lineTo(x + width, y + height - radius)
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
        ctx.lineTo(x + radius, y + height)
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
        ctx.lineTo(x, y + radius)
        ctx.quadraticCurveTo(x, y, x + radius, y)
        ctx.closePath()
    }

    public fillRoundRectangle(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        borderRadius: number,
        brush: IGPSolidBrush,
        roundTopLeft: boolean = true,
        roundTopRight: boolean = true,
        roundBottomRight: boolean = true,
        roundBottomLeft: boolean = true
    ) {
        ctx.beginPath()
        if (roundTopLeft) {
            ctx.moveTo(x + borderRadius, y)
        } else {
            ctx.moveTo(x, y)
        }

        if (roundTopRight) {
            ctx.arcTo(x + width, y, x + width, y + height, borderRadius)
        } else {
            ctx.lineTo(x + width, y)
        }

        if (roundBottomRight) {
            ctx.arcTo(x + width, y + height, x, y + height, borderRadius)
        } else {
            ctx.lineTo(x + width, y + height)
        }

        if (roundBottomLeft) {
            ctx.arcTo(x, y + height, x, y, borderRadius)
        } else {
            ctx.lineTo(x, y + height)
        }

        if (roundTopLeft) {
            ctx.arcTo(x, y, x + width, y, borderRadius)
        } else {
            ctx.lineTo(x, y)
        }

        ctx.closePath()
        ctx.fillStyle = this.hexToRgba(brush.getColor(), brush.getOpacity())
        ctx.fill()
    }

    fillRectangle(brush: IGPSolidBrush, x: number, y: number, width: number, height: number) {
        this.context.fillStyle = this.hexToRgba(brush.getColor(), brush.getOpacity())
        this.context.fillRect(x, y, width, height)
    }

    drawString(text: string, font: IGPFont, brush: IGPSolidBrush, x: number, y: number) {
        this.context.font = font.toString()
        this.context.fillStyle = brush.getColor()
        this.context.fillText(text, x, y)
    }

    drawStringWithOwnParams(x: number, y: number, s: string, font: TMkFontStyle) {
        const fontString = new IGPFont(new TGPFontFamily(font.name), font.size, [font.style]).toString()
        this.context.font = fontString
        this.context.fillStyle = font.color
        this.context.fillText(s, x, y)
    }

    drawPath(pen: IGPPen, path: IGPGraphicsPath) {
        this.context.strokeStyle = pen.color
        this.context.lineWidth = pen.width
        this.context.stroke(path.getPath())
    }

    fillPath(brush: IGPSolidBrush, path: IGPGraphicsPath) {
        this.context.fillStyle = this.hexToRgba(brush.getColor(), brush.getOpacity())
        this.context.fill(path.getPath())
    }

    RotateTransform(angle: number) {
        // The angle should be in radians. If you're passing degrees, convert them to radians.
        this.context.rotate(angle)
    }

    TranslateTransform(dx: number, dy: number) {
        this.context.translate(dx, dy)
    }

    ResetTransform() {
        // Resetting the transformation matrix to the identity matrix
        this.context.setTransform(1, 0, 0, 1, 0, 0)
    }

    drawEllipse(pen: IGPPen, R: TRect) {
        this.context.strokeStyle = pen.color
        this.context.lineWidth = pen.width
        pen.applyDashPattern(this.context)
        this.context.beginPath()
        this.context.ellipse(R.Left + R.Width / 2, R.Top + R.Height / 2, R.Width / 2, R.Height / 2, 0, 0, 2 * Math.PI)
        this.context.stroke()

        pen.setDashPattern([])
        pen.applyDashPattern(this.context)
    }

    fillEllipse(brush: IGPSolidBrush, R: TRect) {
        this.context.fillStyle = this.hexToRgba(brush.getColor(), brush.getOpacity())
        this.context.beginPath()
        this.context.ellipse(R.Left + R.Width / 2, R.Top + R.Height / 2, R.Width / 2, R.Height / 2, 0, 0, 2 * Math.PI)
        this.context.fill()
    }

    hexToRgba(hex: string, alpha = 1) {
        // Remove the hash at the start if it's there
        hex = hex.replace(/^#/, '')

        // Parse the hex color
        let r, g, b
        if (hex.length === 3) {
            // If it's a shorthand (3 characters) hex color
            r = parseInt(hex.charAt(0) + hex.charAt(0), 16)
            g = parseInt(hex.charAt(1) + hex.charAt(1), 16)
            b = parseInt(hex.charAt(2) + hex.charAt(2), 16)
        } else if (hex.length === 6 || hex.length === 8) {
            // If it's a full (6 characters) hex color
            r = parseInt(hex.substring(0, 2), 16)
            g = parseInt(hex.substring(2, 4), 16)
            b = parseInt(hex.substring(4, 6), 16)
        } else {
            throw new StrangeError(`Invalid hex color: ${hex}`)
        }

        // Return the RGBA color string
        return `rgba(${r}, ${g}, ${b}, ${alpha})`
    }

    drawDelphiStyleArc(
        pen: IGPPen,
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        x3: number,
        y3: number,
        x4: number,
        y4: number,
        counterclockwise: boolean,
        fullEllipse = false
    ) {
        // Ensure width and height are positive
        const width = Math.abs(x2 - x1)
        const height = Math.abs(y2 - y1)

        // Calculate the center of the ellipse
        const centerX = (x1 + x2) / 2
        const centerY = (y1 + y2) / 2

        // Calculate the radius for both axes
        const radiusX = width / 2
        const radiusY = height / 2

        // Function to convert coordinates to angle
        const coordinateToAngle = (x: number, y: number) => {
            const dx = (x - centerX) / radiusX
            const dy = (y - centerY) / radiusY
            return Math.atan2(dy, dx)
        }

        // Calculate start and end angles
        const startAngle = coordinateToAngle(x3, y3)
        let endAngle = coordinateToAngle(x4, y4)

        if (fullEllipse) {
            endAngle = startAngle + 2 * Math.PI
        }
        this.context.strokeStyle = pen.color
        this.context.lineWidth = pen.width
        pen.applyDashPattern(this.context)
        this.context.beginPath()
        this.context.ellipse(centerX, centerY, radiusX, radiusY, 0, startAngle, endAngle, counterclockwise)
        this.context.stroke()

        this.context.setLineDash([])
    }

    public strokeRoundedRect(
        topLeftX: number,
        topLeftY: number,
        width: number,
        height: number,
        roundedType: TRoundRectType,
        radius = 5,
        fillInside = false,
        pen: IGPPen,
        brush: IGPSolidBrush
    ) {
        this.context.beginPath()

        this.context.strokeStyle = pen.color
        this.context.lineWidth = pen.width
        pen.applyDashPattern(this.context)
        this.context.fillStyle = this.hexToRgba(brush.getColor(), brush.getOpacity())

        switch (roundedType) {
            case TRoundRectType.NONE: {
                this.context.rect(topLeftX, topLeftY, width, height)
                break
            }
            case TRoundRectType.TOP: {
                this.context.moveTo(topLeftX + radius, topLeftY)
                this.context.lineTo(topLeftX + width - radius, topLeftY)
                this.context.quadraticCurveTo(topLeftX + width, topLeftY, topLeftX + width, topLeftY + radius)
                this.context.lineTo(topLeftX + width, topLeftY + height)
                this.context.lineTo(topLeftX, topLeftY + height)
                this.context.lineTo(topLeftX, topLeftY + radius)
                this.context.quadraticCurveTo(topLeftX, topLeftY, topLeftX + radius, topLeftY)
                break
            }
            case TRoundRectType.BOTTOM: {
                this.context.moveTo(topLeftX + width, topLeftY)
                this.context.lineTo(topLeftX + width, topLeftY + height - radius)
                this.context.quadraticCurveTo(
                    topLeftX + width,
                    topLeftY + height,
                    topLeftX + width - radius,
                    topLeftY + height
                )
                this.context.lineTo(topLeftX + radius, topLeftY + height)
                this.context.quadraticCurveTo(topLeftX, topLeftY + height, topLeftX, topLeftY + height - radius)
                this.context.lineTo(topLeftX, topLeftY)
                break
            }
            case TRoundRectType.BOTH: {
                this.context.moveTo(topLeftX + radius, topLeftY)
                this.context.lineTo(topLeftX + width - radius, topLeftY)
                this.context.quadraticCurveTo(topLeftX + width, topLeftY, topLeftX + width, topLeftY + radius)
                this.context.lineTo(topLeftX + width, topLeftY + height - radius)
                this.context.quadraticCurveTo(
                    topLeftX + width,
                    topLeftY + height,
                    topLeftX + width - radius,
                    topLeftY + height
                )
                this.context.lineTo(topLeftX + radius, topLeftY + height)
                this.context.quadraticCurveTo(topLeftX, topLeftY + height, topLeftX, topLeftY + height - radius)
                this.context.lineTo(topLeftX, topLeftY + radius)
                this.context.quadraticCurveTo(topLeftX, topLeftY, topLeftX + radius, topLeftY)
                break
            }
            default: {
                throw new StrangeError('Invalid TRoundRectType')
            }
        }

        this.context.closePath()
        this.context.stroke()

        if (fillInside) {
            this.context.fill()
        }
    }

    drawRoundedRect(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        borderRadius: number,
        color: string
    ): void {
        ctx.save()
        ctx.fillStyle = '#FFFFFF'
        ctx.strokeStyle = color
        ctx.lineWidth = 1
        ctx.shadowColor = 'rgba(0, 29, 67, 0.05)'
        ctx.shadowBlur = 2
        ctx.shadowOffsetY = 1

        ctx.beginPath()
        ctx.moveTo(x + borderRadius, y)
        ctx.lineTo(x + width - borderRadius, y)
        ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius)
        ctx.lineTo(x + width, y + height - borderRadius)
        ctx.quadraticCurveTo(x + width, y + height, x + width - borderRadius, y + height)
        ctx.lineTo(x + borderRadius, y + height)
        ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius)
        ctx.lineTo(x, y + borderRadius)
        ctx.quadraticCurveTo(x, y, x + borderRadius, y)
        ctx.closePath()
        ctx.fill()
        ctx.stroke()
        ctx.restore()
    }
}

export enum TGPDashStyle {
    Solid,
    Dash,
    Dot,
    DashDot,
    DashDotDot,
    Custom
    // Add other styles if needed
}

export class IGPPen {
    color: TColor
    opacity: number
    width: number
    private dashPattern: number[]
    public dashStyle: TGPDashStyle
    private opionsForRestoring: {
        color: TColor
        opacity: number
        width: number
        dashPattern: number[]
        dashStyle: TGPDashStyle
    }

    //do not add pen style, use dash style instead

    constructor(color: TColor, width = 1, opacity = 1) {
        this.color = color
        this.opacity = opacity
        this.width = width
        this.dashStyle = TGPDashStyle.Solid
        this.dashPattern = []

        this.opionsForRestoring = {
            color,
            opacity: opacity,
            width,
            dashPattern: [],
            dashStyle: TGPDashStyle.Solid
        }
    }

    clone(): IGPPen {
        const cloned = new IGPPen(this.color, this.width, this.opacity)
        cloned.dashPattern = this.dashPattern
        cloned.dashStyle = this.dashStyle
        return cloned
    }

    setDashPattern(pattern: number[]): void {
        this.dashPattern = pattern
        this.dashStyle = TGPDashStyle.Custom
    }

    setDashStyle(style: TGPDashStyle): void {
        this.dashStyle = style
        switch (style) {
            case TGPDashStyle.Solid: {
                this.dashPattern = []
                break
            }
            case TGPDashStyle.Dash: {
                this.dashPattern = [4, 4]
                break
            }
            case TGPDashStyle.Dot: {
                this.dashPattern = [1, 2]
                break
            }
            case TGPDashStyle.DashDot: {
                this.dashPattern = [4, 2, 1, 2]
                break
            }
            case TGPDashStyle.DashDotDot: {
                this.dashPattern = [4, 2, 1, 2, 1, 2]
                break
            }
            case TGPDashStyle.Custom: {
                //TODO: what to do with Custom style??
                break
            }
            default: {
                throw new StrangeError('Invalid dash style')
            }
        }
    }

    setPenStyle_TPenStyle(style: TPenStyle): void {
        switch (style) {
            case TPenStyle.psSolid: {
                this.setDashPattern([])
                break
            }
            case TPenStyle.psDash: {
                this.setDashPattern([5, 5])
                break
            }
            case TPenStyle.psDot: {
                this.setDashPattern([1, 2])
                break
            }
            case TPenStyle.psDashDot: {
                this.setDashPattern([5, 3, 1, 3])
                break
            }
            case TPenStyle.psDashDotDot: {
                this.setDashPattern([5, 3, 1, 3, 1, 3])
                break
            }
            //TODO: Handle other styles and add default case
        }
    }

    getPenStyleFromPattern(): TPenStyle {
        if (JSON.stringify(this.dashPattern) === JSON.stringify([])) {
            return TPenStyle.psSolid
        }

        if (JSON.stringify(this.dashPattern) === JSON.stringify([5, 5])) {
            return TPenStyle.psDash
        }

        if (JSON.stringify(this.dashPattern) === JSON.stringify([1, 2])) {
            return TPenStyle.psDot
        }

        if (JSON.stringify(this.dashPattern) === JSON.stringify([5, 3, 1, 3])) {
            return TPenStyle.psDashDot
        }
        if (JSON.stringify(this.dashPattern) === JSON.stringify([5, 3, 1, 3, 1, 3])) {
            return TPenStyle.psDashDotDot
        }

        return TPenStyle.psSolid
    }

    setPenStyle(style: TPenStyle | TGPDashStyle): void {
        if (Object.values(TPenStyle).includes(style as TPenStyle)) {
            this.setPenStyle_TPenStyle(style as TPenStyle)
        } else if (Object.values(TGPDashStyle).includes(style as TGPDashStyle)) {
            this.setPenStyle_TGPDashStyle(style as TGPDashStyle)
        } else {
            throw new StrangeError('Invalid pen style')
        }
    }

    setPenStyle_TGPDashStyle(style: TGPDashStyle) {
        switch (style) {
            case TGPDashStyle.Dash: {
                this.setDashPattern([6, 2])
                break
            }
            case TGPDashStyle.Dot: {
                this.setDashPattern([2, 2])
                break
            }
            case TGPDashStyle.DashDot: {
                this.setDashPattern([8, 3, 3, 3])
                break
            }
            case TGPDashStyle.DashDotDot: {
                this.setDashPattern([8, 3, 3, 3, 3, 3])
                break
            }
            default: {
                this.setDashStyle(TGPDashStyle.Solid)
                break
            }
        }
    }

    setColor(color: TColor): void {
        this.color = color
    }

    setWidth(width: number): void {
        this.width = width
    }

    // Method to apply dash pattern to a CanvasRenderingContext2D
    applyDashPattern(context: CanvasRenderingContext2D): void {
        context.setLineDash(this.dashPattern)
    }

    restoreDashPattern(context: CanvasRenderingContext2D): void {
        context.setLineDash([])
    }

    saveOptions(): void {
        this.opionsForRestoring = {
            color: this.color,
            opacity: this.opacity,
            width: this.width,
            dashPattern: this.dashPattern,
            dashStyle: this.dashStyle
        }
    }

    restoreOptions(): void {
        this.color = this.opionsForRestoring.color
        this.opacity = this.opionsForRestoring.opacity
        this.width = this.opionsForRestoring.width
        this.dashPattern = this.opionsForRestoring.dashPattern
        this.dashStyle = this.opionsForRestoring.dashStyle
    }
}

export interface IGPSolidBrushJSON {
    color: string
    opacity: number
    style: TBrushStyle
}

export class IGPSolidBrush {
    private _color: TColor
    private _opacity: number
    private _style: TBrushStyle

    public static fromSerialized(serialized: IGPSolidBrushJSON): IGPSolidBrush {
        return new IGPSolidBrush(serialized.color, serialized.opacity, serialized.style)
    }
    constructor(color: TColor, opacity = 1.0, style: TBrushStyle = TBrushStyle.bsSolid) {
        this._color = color
        this._opacity = opacity
        this._style = style
    }

    clone(): IGPSolidBrush {
        return new IGPSolidBrush(this._color, this._opacity, this._style)
    }

    set Color(color: TColor) {
        this._color = color
    }

    set Style(style: TBrushStyle) {
        this._style = style
    }

    setColor(color: TColor): void {
        this._color = color
    }

    setOpacity(opacity: number): void {
        this._opacity = Math.max(0, Math.min(1, opacity)) // Ensures opacity is between 0 and 1
    }

    setStyle(style: TBrushStyle): void {
        this._style = style
    }

    getColor(): TColor {
        return this._color
    }

    getOpacity(): number {
        return this._opacity
    }

    getStyle(): TBrushStyle {
        return this._style
    }

    // Converts the color and opacity to CSS color format (rgba)
    getCssColor(): string {
        const color = this._color
        if (!color.startsWith('#') || color.length !== 7) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                'IGPSolidBrush: Currently only supports hex color codes in the format #RRGGBB.'
            )
            return color
        }

        const r = parseInt(color.substr(1, 2), 16)
        const g = parseInt(color.substr(3, 2), 16)
        const b = parseInt(color.substr(5, 2), 16)
        return `rgba(${r}, ${g}, ${b}, ${this._opacity})`
    }

    public getSerialized(): IGPSolidBrushJSON {
        return {
            color: this._color,
            opacity: this._opacity,
            style: this._style
        }
    }
}

export class TGPFontFamily {
    private _name: string

    constructor(name: string) {
        this._name = name
    }

    get name(): string {
        return this._name
    }

    set name(name: string) {
        this._name = name
    }

    // Additional functionalities related to font family can be added here
}

export class IGPGraphicsPath {
    private path: Path2D

    constructor() {
        this.path = new Path2D()
    }

    moveTo(x: number, y: number) {
        this.path.moveTo(x, y)
    }

    lineTo(x: number, y: number) {
        this.path.lineTo(x, y)
    }

    bezierCurveTo(cp1x: number, cp1y: number, cp2x: number, cp2y: number, x: number, y: number) {
        this.path.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y)
    }

    quadraticCurveTo(cpx: number, cpy: number, x: number, y: number) {
        this.path.quadraticCurveTo(cpx, cpy, x, y)
    }

    arc(
        centerX: number,
        centerY: number,
        radius: number,
        startAngle: number,
        endAngle: number,
        counterclockwise = false
    ) {
        this.path.arc(centerX, centerY, radius, startAngle, endAngle, counterclockwise)
    }

    closePath() {
        this.path.closePath()
    }

    getPath(): Path2D {
        return this.path
    }

    reset() {
        this.path = new Path2D()
    }
}

type TGpFontStyles = TGpFontStyle[]

export enum TGpFontStyle {
    FontStyleRegular,
    FontStyleBold,
    FontStyleItalic,
    FontStyleBoldItalic,
    FontStyleUnderline,
    FontStyleStrikeOut
    // Add other styles if needed
}

export class IGPFont {
    private fontStyles: TGpFontStyles
    private fontSize: number
    private fontFamily: string

    constructor(
        fontFamily: TGPFontFamily,
        fontSize: number,
        fontStyles: TGpFontStyles = [TGpFontStyle.FontStyleRegular]
    ) {
        this.fontFamily = fontFamily.name
        this.fontSize = fontSize
        this.fontStyles = fontStyles
    }

    setFontSize(fontSize: number) {
        this.fontSize = fontSize
    }

    setFontStyles(fontStyles: TGpFontStyles) {
        this.fontStyles = fontStyles
    }

    setFontFamily(fontFamily: TGPFontFamily) {
        this.fontFamily = fontFamily.name
    }

    public getFontParams() {
        return {
            size: this.fontSize,
            fontStyles: this.fontStyles
        }
    }

    toString(): string {
        const styleString = this.fontStyles
            .map((style) => {
                switch (style) {
                    case TGpFontStyle.FontStyleBold: {
                        return 'bold'
                    }
                    case TGpFontStyle.FontStyleItalic: {
                        return 'italic'
                    }
                    case TGpFontStyle.FontStyleBoldItalic: {
                        return 'italic bold'
                    }
                    case TGpFontStyle.FontStyleUnderline: {
                        // Underline is not a part of font string in CSS, handled separately if needed
                        return ''
                    }
                    case TGpFontStyle.FontStyleStrikeOut: {
                        // Strikeout is not a part of font string in CSS, handled separately if needed
                        return ''
                    }
                    default: {
                        return 'normal'
                    }
                }
            })
            .filter((part) => part !== '')
            .join(' ')

        return `${styleString} ${this.fontSize}px ${this.fontFamily}`
    }

    static fromString(fontString: string): IGPFont {
        const parts = fontString.split(/\s+/)
        const sizeIndex = parts.findIndex((part) => /\d+px/.test(part))
        const fontSize = parseInt(parts[sizeIndex].replace('px', ''))
        const fontFamily = parts.slice(sizeIndex + 1).join(' ')
        const styleParts = parts.slice(0, sizeIndex).join(' ')

        const fontStyles: TGpFontStyles = []
        switch (styleParts) {
            case 'bold': {
                fontStyles.push(TGpFontStyle.FontStyleBold)
                break
            }
            case 'italic': {
                fontStyles.push(TGpFontStyle.FontStyleItalic)
                break
            }
            case 'italic bold': {
                fontStyles.push(TGpFontStyle.FontStyleBoldItalic)
                break
            }
            case 'underline': {
                fontStyles.push(TGpFontStyle.FontStyleUnderline)
                break
            }
            case 'strikeout': {
                fontStyles.push(TGpFontStyle.FontStyleStrikeOut)
                break
            }
            default: {
                break
            }
        }

        return new IGPFont(new TGPFontFamily(fontFamily), fontSize, fontStyles)
    }

    toHTMLFontFormat(): string {
        const styleParts: string[] = []

        if (this.fontStyles.includes(TGpFontStyle.FontStyleBold)) {
            styleParts.push('font-weight: bold;')
        }
        if (this.fontStyles.includes(TGpFontStyle.FontStyleItalic)) {
            styleParts.push('font-style: italic;')
        }
        //TODO: Note: CSS doesn't have direct equivalents for underline and strikeout.
        // These would typically be applied to the text element itself, not the font.

        styleParts.push(`font-size: ${this.fontSize}px;`, `font-family: ${this.fontFamily};`)

        return styleParts.join(' ')
    }

    clone(): IGPFont {
        return new IGPFont(new TGPFontFamily(this.fontFamily), this.fontSize, this.fontStyles)
    }

    getTextWidthByContext(text: string, context: CanvasRenderingContext2D): number {
        let result = 0
        context.font = this.toString()
        result = context.measureText(text).width
        return result
    }
}
