import { AnyAction, Reducer, combineReducers, configureStore } from '@reduxjs/toolkit'

import authSlice from './auth/auth.slice'
import projectSlice from './projects/project.slice'
import symbolsSlice from './symbols/symbol.slice'
import appSettingsReducer from '@root/store/appSettings/slice'

const combinedReducer = combineReducers({
    auth: authSlice,
    projects: projectSlice,
    symbols: symbolsSlice,
    appSettings: appSettingsReducer
})

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === 'auth/LOGOUT') {
        state = {} as RootState
    }
    return combinedReducer(state, action)
}

const store = configureStore({
    reducer: rootReducer,
    devTools: true
})

export type RootState = ReturnType<typeof combinedReducer>
export type AppDispatch = typeof store.dispatch

export default store
