import { FC, ReactNode } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Flex, FlexAligns, Typography } from '@fto/ui'

import commonStyles from '../../common.module.scss'

type Props = {
    label: string
    className?: string
    alignItems?: (typeof FlexAligns)[number]
    children: ReactNode
}

const FieldWrapper: FC<Props> = ({ label, className, children, alignItems = 'center' }) => {
    const { t } = useTranslation()
    return (
        <Flex alignItems={alignItems} justifyContent='space-between' gap={8}>
            <Typography type='interface-regular' color='gray-1000' className={cx(className, commonStyles.LeftPart)}>
                {t(label)}
            </Typography>
            {children}
        </Flex>
    )
}

export default FieldWrapper
