import { FC, useCallback, useEffect } from 'react'

import { fireMixpanelEvent } from '@root/utils/api'

import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { ContextMenu, removeContextMenu } from '@fto/ui'
import { News } from '@fto/lib/News/News'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'
import NewsStore from '@fto/lib/store/news'

import NewsMenuContent from '../components/NewsMenuContent'

type Props = {
    anchorX: number
    anchorY: number
    additionalProps: {
        news: News[]
    }
    setIsNewsOpen: (value: boolean) => void
}

const NewsContextMenu: FC<Props> = ({ anchorX, anchorY, additionalProps, setIsNewsOpen }) => {
    const removeMenu = useCallback(() => {
        removeContextMenu(CONTEXT_MENU_NAMES.news)
        GlobalChartsController.Instance.enableMouseEvents()
    }, [])

    useEffect(() => {
        const { news } = additionalProps

        // NOTE: track mixpanel event

        const countriesList = Array.from(new Set(news.map((item) => item.Country)))

        const {
            params: {
                filters: { interval, priority, selectedCountries }
            }
        } = NewsStore

        fireMixpanelEvent('news_popup_opened', {
            number_of_news_shown: news.length,
            range_selected: interval,
            priority_selected: priority,
            shown_countries_total: countriesList.length,
            selected_country_list: selectedCountries,
            country_name_selected: countriesList.length >= 3 ? ['Entire World'] : countriesList
        })
        return () => {
            removeMenu()
        }
    }, [])

    return (
        <ContextMenu name={CONTEXT_MENU_NAMES.news} anchorX={anchorX} anchorY={anchorY} direction='top-right'>
            <NewsMenuContent news={additionalProps.news} setIsNewsOpen={setIsNewsOpen} />
        </ContextMenu>
    )
}

export default NewsContextMenu
