import { FC } from 'react'
import cx from 'classnames'

import { Tooltip } from '@fto/ui'

import { RibbonInnerDropdownType } from '@fto/chart_components/ProjectInterface/components/Ribbon/components/types'
import { FavoriteIndicatorListType } from '@fto/lib/store/indicatorsStore/types'

import InnerDropdownButton from './components/InnerDropdownButton'
import InnerDropdownMenu from './components/InnerDropdownMenu'

import styles from './index.module.scss'

type Props = {
    dropdown: RibbonInnerDropdownType
    close: () => void
    favoriteIndicators: FavoriteIndicatorListType
    toggleFavorite: (value: string) => void
}

const InnerDropdown: FC<Props> = ({ dropdown, close, favoriteIndicators, toggleFavorite }) => {
    if (dropdown.options.length === 0) {
        return <InnerDropdownButton name={dropdown.name} icon={dropdown.icon} />
    }

    return (
        <Tooltip
            color='light'
            classNames={{ tooltip: cx(styles.dropdownTooltip, styles.tooltip) }}
            content={
                <InnerDropdownMenu
                    name={dropdown.name}
                    close={close}
                    options={dropdown.options}
                    favoriteIndicators={favoriteIndicators}
                    toggleFavorite={toggleFavorite}
                    isScrollable={dropdown.value === 'favorite'}
                />
            }
            interactive
            arrow={false}
            placement='right-start'
        >
            <InnerDropdownButton name={dropdown.name} icon={dropdown.icon} />
        </Tooltip>
    )
}
export default InnerDropdown
