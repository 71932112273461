import { t } from 'i18next'
import { UserIndicator } from '../api/UserIndicator'
import { TDrawStyle, TOutputWindow, TPenStyle } from '../api/IndicatorInterfaceUnit'
import { TIndexBuffer } from '../api/IIndicatorApi'
import { DelphiColors } from '../../../delphi_compatibility/DelphiBasicTypes'

export default class Volume extends UserIndicator {
    vol!: TIndexBuffer

    Init(): void {
        this.api.RecalculateMeAlways()
        this.api.IndicatorShortName(t('indicators.volume'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.AddLevel(0, TPenStyle.psDot, 1, DelphiColors.clBlack, 1)

        this.vol = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(1)
        this.api.SetIndexBuffer(0, this.vol)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Histogram, TPenStyle.psSolid, 1, DelphiColors.clGreen)
        this.api.SetIndexLabel(0, t('indicatorModal.volume.fields.volume'))
    }

    Calculate(index: number): void {
        this.vol.setValue(index, this.api.Volume(index))
    }
}
