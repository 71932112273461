import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TIndexPos, TLineStyleRec } from '../../drawing_interface/GraphicObjects'
import { TIndexBufferProgramSide } from './IndexBuffers'

export class SelectionMarkers {
    public step: number | null = null
    public selectionMarkersIndexes: number[]

    constructor(step: number | null, selectionMarkersIndexes: number[]) {
        this.step = step
        this.selectionMarkersIndexes = selectionMarkersIndexes
    }
}

export class TVisibleIndexBuffer {
    buffer: TIndexBufferProgramSide | null = null

    style: TLineStyleRec
    name!: string
    PaintFrom!: number
    public selectionMarkers: SelectionMarkers
    private isSelected = false
    private isVisible = true

    constructor() {
        this.style = new TLineStyleRec()
        this.selectionMarkers = new SelectionMarkers(null, [])
    }

    CheckIndex(index: number): TIndexPos {
        //TODO: refactor this with property checks
        if (this.buffer?.shift) {
            index -= this.buffer.shift
        }

        if (!this.buffer) {
            throw new StrangeError('Buffer is not assigned.')
        }

        if (!this.buffer.HasSomeValues()) {
            return TIndexPos.ip_Invalid
        }

        if (index < 0) {
            return TIndexPos.ip_InvBefore
        }

        if (index > this.buffer.LastItemInTestingIndex) {
            return TIndexPos.ip_InvAfter
        }

        return TIndexPos.ip_Valid
    }

    GetValue(index: number): number {
        if (!this.buffer) {
            throw new StrangeError('Buffer is not assigned.')
        }
        return this.buffer.GetValue(index - this.buffer.shift)
    }

    show(): void {
        this.isVisible = true
    }

    hide(): void {
        this.isVisible = false
    }

    public IsVisible(): boolean {
        return this.isVisible
    }

    get EmptyValue(): number {
        if (!this.buffer) {
            throw new StrangeError('Buffer is not assigned.')
        }
        return this.buffer.EmptyValue
    }

    get Count(): number {
        if (!this.buffer) {
            return 0
        }

        return this.buffer.LastItemInTestingIndex
    }

    public onIndicatorSelected(): void {
        this.isSelected = true
    }

    public onIndicatorUnselected(): void {
        this.isSelected = false
    }

    public IsSelected(): boolean {
        return this.isSelected
    }
}
