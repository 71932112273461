import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { TBasicPaintTool } from '@fto/lib/charting/paint_tools/BasicPaintTool'
import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'
import { TPaintToolStatus, TPaintToolType } from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { TPtText } from '@fto/lib/charting/paint_tools/SpecificTools/ptText'
import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { DelphiColors } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TMouseButton } from '@fto/lib/delphi_compatibility/DelphiFormsBuiltIns'
import { TMkFontStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { TCursor } from '@fto/lib/ft_types/common/CursorPointers'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'

export class TPtWaveSymbols extends TBasicPaintTool {
    private fTptText: TPtText | null = null
    private fOldChart: TChart
    private fRow: number
    private fCol: number

    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptWaveSymbols)
        this.ShortName = PaintToolNames.ptWaveSymbols
        this.fToolType = TPaintToolType.tt_WaveSymbols
        this.fMaxPoints = 1000
        this.fClosedPolygon = false
        this.fShouldFillInside = false
        this.CursorStyle = TCursor.crCross
        this.icon = 42

        this.fRow = 0
        this.fCol = 14
        this.CreateNextSymbol()
        this.fOldChart = this.fChart as TMainChart
    }

    public Paint(): void {}

    public OnPointPlaced(): void {
        // if fText is null, then exit the function
        if (this.fTptText === null) {
            return
        }

        this.fTptText.chart = this.fChart
        this.fTptText.points[0].time = this.fPoints[this.fPoints.length - 1].time
        this.fTptText.points[0].price = this.fPoints[this.fPoints.length - 1].price

        // if not continuing the wave symbol sequence, set fText to null
        if (GlobalOptions.Options.ContinueWaveSymbolSequence) {
            // otherwise, create the next symbol
            this.CreateNextSymbol()
        } else {
            this.fTptText = null
        }

        // if fText is null, report the cancellation of work
        if (this.fTptText === null) {
            this.ReportEndOfWork()
        }
    }

    public OnMouseMove(time: TDateTime, price: number, chart: TChart): void {
        super.OnMouseMove(time, price, chart)

        // if fText is not initialized, exit the function
        if (this.fText === null) {
            return
        }

        // if the chart has changed, transfer the tool and update references
        if (this.fOldChart !== this.fChart && this.fTptText !== null) {
            // Assuming TChart is a type that encapsulates chart logic, including paint tools
            // TransferTool method is assumed to exist for transferring tools between charts
            ;(this.fOldChart as any).PaintTools.TransferTool(this.fText, (this.fChart as any).PaintTools)
            this.fTptText.chart = this.fChart
            this.fOldChart = this.fChart as TMainChart
        }

        if (this.fTptText !== null) {
            // Update the first point of fText with the last point's time and price
            this.fTptText.points[0].time = this.fPoints.LastItem.time
            this.fTptText.points[0].price = this.fPoints.LastItem.price
        }
    }

    public OnMouseDown(time: TDateTime, price: number, button: TMouseButton): void {
        if (
            button === TMouseButton.mbRight && // If right mouse button is clicked
            this.fTptText
        ) {
            // Assuming TChart is similar to fText.chart in TypeScript and has a method to delete tools
            this.ReportEndOfWork()
            ;(this.fTptText.chart as TMainChart).PaintTools.DeleteTool(this.fTptText)
            this.fTptText = null
        }
        super.OnMouseDown(time, price, button)
    }

    private CreateNextSymbol(): void {
        this.fTptText = null
        if (this.fRow > 8) return

        this.fOldChart = this.fChart as TMainChart

        this.fTptText = new TPtText(this.fChart)
        this.fTptText.SetAllTimeframes()
        ;(this.fChart as TMainChart).PaintTools.AddTool(this.fTptText)
        this.fTptText.status = TPaintToolStatus.ts_Completed

        // get wave symbol
        const s: string = GlobalOptions.Options.WaveSymbols[this.fRow]
        const s1 = StrsConv.GetQuotedStr(s.split(',')[this.fCol])
        this.fTptText.Text = `{${s1}}`

        let fnt = new TMkFontStyle('Roboto Flex', 10, 0, DelphiColors.clRed)
        fnt.LoadFromStr(GlobalOptions.Options.WaveFonts[Math.floor(this.fRow / 3)])
        fnt.color = GlobalOptions.Options.WaveColors[this.fRow]
        this.fTptText.FontStyle = fnt

        this.fCol++
        const arr: number[][] = [
            [0, 5],
            [5, 10],
            [10, 14],
            [14, 17]
        ]
        for (const element of arr) {
            if (this.fCol === element[1]) {
                this.fCol = element[0]
                this.fRow++
            }
        }
    }
}
