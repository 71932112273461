import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { DelphiMathCompatibility } from '@fto/lib/delphi_compatibility/DelphiMathCompatibility'

export default class ChartUtils {
    public static NumberOfVisibleBars(paintRect: TRect, barSizeInPixels: number, left_x_offset: number): number {
        if (isNaN(left_x_offset)) {
            throw new StrangeError('left_x_offset is NaN')
        }
        const width = paintRect.Width
        const adjustedWidth = width + Math.abs(left_x_offset)
        let result = Math.floor(adjustedWidth / (barSizeInPixels || 1))
        if (DelphiMathCompatibility.Mod(adjustedWidth, barSizeInPixels || 1) > 0) {
            result += 1
        }
        return result
    }

    public static GetLeftMostIndexByRightMostIndex(rightIndex: number, numberOfItems: number): number {
        return rightIndex - (numberOfItems - 1)
    }

    //makes sure that x_offs is in the range of -barSizeInPixels..0, adjusts globalIndex accordingly
    public static Correct_X_offset(
        globalIndex_param: number,
        x_offs_param: number,
        barSizeInPixels: number
    ): [number, number] {
        let x_offs = Math.round(x_offs_param)
        let globalIndex = globalIndex_param

        if (x_offs < 0 && Math.abs(x_offs) > barSizeInPixels) {
            const indexDelta = Math.floor(Math.abs(x_offs) / barSizeInPixels)
            globalIndex += indexDelta
            x_offs = -Math.abs(x_offs) % barSizeInPixels
        } else if (x_offs > 0) {
            const globIndexDelta = Math.floor(x_offs / barSizeInPixels)
            globalIndex -= globIndexDelta
            const restOfX_offs = x_offs % barSizeInPixels
            if (restOfX_offs > 0) {
                x_offs = -(barSizeInPixels - restOfX_offs)
                globalIndex -= 1
            } else {
                x_offs = 0
            }
        }
        return [globalIndex, Math.round(x_offs)]
    }
}
