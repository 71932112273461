import { CrosshairStrategy } from '@fto/chart_components/crosshair/strategies/common'
import { DelphiFormsBuiltIns, TMouseButton } from '@fto/lib/delphi_compatibility/DelphiFormsBuiltIns'
import { TCursorMode } from '@fto/lib/charting/ChartBasicClasses'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { TCursor } from '@fto/lib/ft_types/common/CursorPointers'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { crosshairManager } from '@fto/lib/globals/CrosshairManager'
import { CrosshairControl } from '@fto/chart_components/crosshair/CrosshairControl'

export class CrosshairWithRooler implements CrosshairStrategy {
    constructor(
        private chartWnd: TChartWindow,
        private crosshairControl: CrosshairControl
    ) {}

    public onMouseDown(event: MouseEvent): boolean {
        const button = DelphiFormsBuiltIns.ExtractTMouseButton(event)
        const isLeftClick = button === TMouseButton.mbLeft

        const chart = this.chartWnd.ChartUnderMouse()

        if (!chart) {
            throw new StrangeError('chart is null in OnMouseDown')
        }

        if (isLeftClick) {
            const crosshair = crosshairManager.getCrosshairState()
            if (!crosshair) {
                throw new StrangeError('crosshair is null in OnMouseDown')
            }

            crosshairManager.updateRulerDateTime(
                this.chartWnd,
                crosshair.DateTime,
                crosshair.point,
                chart instanceof TMainChart
            )

            this.chartWnd.cursorMode = TCursorMode.cm_CrossHairRooler
            this.chartWnd.SetCursor(TCursor.crCross)
            this.chartWnd.invalidate()

            return true
        }

        return false
    }

    public onMouseMove(event: MouseEvent): boolean {
        return false
    }

    public onMouseUp(event: MouseEvent): boolean {
        if (this.chartWnd.cursorMode === TCursorMode.cm_CrossHairRooler) {
            this.crosshairControl.stopCrosshairMode()

            return true
        }
        return false
    }
}
