import * as Yup from 'yup'
import { SESSION_ZONES } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/CreateProjectAdvancedForm/advanced-form.schema'

const createProjectFormSchema = Yup.object().shape({
    ProjectName: Yup.string().required('errors.projectRequired'),
    Deposit: Yup.number()
        .min(1, 'errors.depositRequired')
        .positive('errors.depositRequired')
        .integer('errors.depositRequired')
        .nonNullable()
        .required('errors.depositRequired')
        .typeError('errors.depositRequired'),
    StartDate: Yup.date().required('errors.startDateRequired'),
    EndDate: Yup.date().nullable(),
    Symbols: Yup.array().min(1, 'errors.symbolsRequired'),
    ForwardMode: Yup.boolean().required(),
    TimeZone: Yup.number().required(),
    DataType: Yup.string(),
    StartFrom: Yup.date().required(),
    SessionCloseTime: Yup.string(),
    DaylightSavingTime: Yup.string()
})
export type CreateProjectModalFormData = Yup.InferType<typeof createProjectFormSchema>

const initCreateProjectFormData: CreateProjectModalFormData = {
    ProjectName: '',
    Deposit: 10000,
    StartDate: new Date(),
    EndDate: new Date(),
    Symbols: [],
    SessionCloseTime: SESSION_ZONES.newYork.value,
    DaylightSavingTime: 'None',
    DataType: 'OneMin',
    ForwardMode: false,
    TimeZone: SESSION_ZONES.newYork.tz,
    StartFrom: new Date()
}

export { createProjectFormSchema, initCreateProjectFormData }
