import { FC, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'

import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { SetBarForwardBarBackEventsTotalCountType } from '@fto/chart_components/ProjectInterface/types'

import ChartWindowComponent from './ChartWindowComponent'
import KeyboardTrackerComponent from './KeyBoarTrackerComponent'
import MouseTrackerComponent from './MouseTrackerComponent'

import styles from '@fto/chart_components/ChartStyle.module.scss'
import { ChartInfoType } from '@fto/chart_components/types/chartTypes'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import ToolInfoStore from '@fto/lib/store/tools'

type Props = {
    selectedChartInfo: ChartInfoType
    setBarForwardBarBackEventsTotalCount: SetBarForwardBarBackEventsTotalCountType
}

const MultiChartComponent: FC<Props> = ({ selectedChartInfo, setBarForwardBarBackEventsTotalCount }) => {
    const [chartInstances, setChartInstances] = useState<TChartWindow[]>([])

    const chartsToDisplay = useMemo(() => {
        for (const chart of chartInstances) {
            GlobalChartsController.Instance.attachChart(chart)
        }
        return chartInstances || []
    }, [chartInstances])

    useEffect(() => {
        DebugUtils.logTopic(
            ELoggingTopics.lt_Loading,
            'MultiChartComponent: useEffect selectedChartInfo',
            selectedChartInfo
        )

        const newCharts = GlobalChartsController.Instance.updateChartList(
            selectedChartInfo.count,
            selectedChartInfo.symbol
        )

        for (const chartWin of newCharts) {
            const chartWinLayers = chartWin.getChartWindowLayers()
            if (chartWinLayers && chartWinLayers.isManagerLayersInitialized) {
                chartWinLayers.getManagerLayers().resize(true)
            }
        }

        setChartInstances(newCharts)
    }, [selectedChartInfo])

    useEffect(() => {
        const { setInfo } = ToolInfoStore
        setInfo((prevState) => ({
            ...prevState,
            anyHiddenTools: GlobalChartsController.Instance.hasHiddenTools(),
            anyHiddenIndicators: GlobalChartsController.Instance.hasHiddenIndicators()
        }))
    }, [chartsToDisplay])

    return (
        <div className={styles.multiChart}>
            <MouseTrackerComponent />
            <KeyboardTrackerComponent />

            <div className={cx(styles.chartsGrid, styles[selectedChartInfo.layout])}>
                {chartsToDisplay.map((chartInstance) => {
                    return (
                        <ChartWindowComponent
                            showBorderWhenSelected={chartsToDisplay.length > 1}
                            key={chartInstance.getID()}
                            chartInstance={chartInstance}
                            symbolName={selectedChartInfo.symbol}
                            setBarForwardBarBackEventsTotalCount={setBarForwardBarBackEventsTotalCount}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default MultiChartComponent
