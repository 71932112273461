import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { Button, Flex, Typography, addModal, Separator } from '@fto/ui'
import { Icon } from '@fto/icons'
import { MODAL_NAMES } from '@root/constants/modalNames'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

import styles from '../common.module.scss'

type Props = {
    chart: TChartWindow
    removeMenu: () => void
    setShowSettings: (value: boolean) => void
}

const ChartMenuContent: FC<Props> = ({ chart, removeMenu, setShowSettings }) => {
    const { t } = useTranslation()

    const [autoFollow, setAutoFollow] = useState(chart.ChartOptions.AutoScroll)
    const handleNewOrder = useCallback(() => {
        addModal(MODAL_NAMES.chart.orderModal, { source: 'right_click_menu' })
        removeMenu()
    }, [removeMenu])

    const addNote = useCallback(() => {
        removeMenu()
        GlobalChartsController.Instance.registerPaintTool('Text')
    }, [removeMenu])

    const removeDrawings = useCallback(() => {
        chart.DeleteAllTools()
        removeMenu()
    }, [chart, removeMenu])

    const showChartSettings = useCallback(() => {
        setShowSettings(true)
        removeMenu()
    }, [removeMenu])

    const toggleAutoFollow = useCallback(() => {
        chart.ChartOptions.AutoScroll = !autoFollow
        setAutoFollow(!autoFollow)
    }, [autoFollow, chart])

    const handlePaste = useCallback(() => {
        chart.pastePaintTools()
        removeMenu()
    }, [chart, removeMenu])

    return (
        <Flex direction='column' gap={4} className={styles.content}>
            <Button
                before={<Icon name='menu-new-order' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('contextMenu.newOrder')}
                onClick={handleNewOrder}
                block
            />
            <Separator width='90%' />
            <Button
                classNames={{ content: styles.textNoIcon }}
                size='tiny'
                type='tetriatry-white'
                label={t('contextMenu.paste')}
                onClick={handlePaste}
                block
            />
            <div className={styles.separator} />
            <Button
                before={<Icon name='menu-note' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('contextMenu.addNote')}
                onClick={addNote}
                block
            />
            <Separator width='90%' />
            <Button
                classNames={{ content: styles.textNoIcon }}
                size='tiny'
                type='tetriatry-white'
                label={t('contextMenu.removeDrawings')}
                onClick={removeDrawings}
                block
            />
            <Separator width='90%' />
            <Button
                before={autoFollow && <Icon color={'var(--color-gray-900)'} name='check' size={16} />}
                classNames={{ content: autoFollow ? styles.text : styles.textNoIcon }}
                size='tiny'
                type='tetriatry-white'
                label={
                    <Typography type={autoFollow ? 'interface-medium' : 'interface-regular'}>
                        {t('contextMenu.autoFollow')}
                    </Typography>
                }
                onClick={toggleAutoFollow}
                block
            />
            <Separator width='90%' />
            <Button
                before={<Icon name='settings' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('contextMenu.chartSettings')}
                onClick={showChartSettings}
                block
            />
            <div className={styles.separator} />
        </Flex>
    )
}

export default ChartMenuContent
