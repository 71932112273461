import { makeAutoObservable } from 'mobx'
import { initialData } from './constants'

import { OrderDataType, StoreDataType, UpdaterType } from '@fto/lib/store/ordersStore/types'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { TerminalOrderManager } from '@fto/lib/OrderModalClasses/TerminalOrderManager'

class OrdersStore {
    data: StoreDataType
    private terminalOrderManager: TerminalOrderManager

    constructor() {
        makeAutoObservable(this)

        this.data = initialData

        this.updateData = this.updateData.bind(this)
        this.closeAllOpenOrders = this.closeAllOpenOrders.bind(this)
        this.closeAllPendingOrders = this.closeAllPendingOrders.bind(this)
        this.closeOrder = this.closeOrder.bind(this)
        this.selectPosition = this.selectPosition.bind(this)
        this.deselectPosition = this.deselectPosition.bind(this)

        this.terminalOrderManager = new TerminalOrderManager()

        this.initialize()
    }

    initialize() {
        this.updateData((prevState) => ({ ...prevState, ...this.terminalOrderManager.init() }))
    }

    updateData(updater: UpdaterType) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.data = updater(this.data)
        } else {
            // If updater is a value, directly apply the updates
            this.data = updater
        }
    }

    resetStore() {
        this.data = initialData
    }

    closeOrder(order: OrderDataType) {
        this.updateData(this.terminalOrderManager.closeOrder(this.data, order))
    }

    jumpToOpenPrice(order: OrderDataType) {
        GlobalChartsController.Instance.jumpToOpenPrice(order)
    }

    closeAllOpenOrders() {
        this.updateData(this.terminalOrderManager.closeAllOpenOrders(this.data))
    }

    closeAllPendingOrders() {
        this.updateData(this.terminalOrderManager.closeAllPendingOrders(this.data))
    }

    public selectPosition(id: number) {
        if (!this.data.selectedPositionsIds.includes(id)) {
            this.data.selectedPositionsIds.push(id)
        }
    }

    public deselectPosition(id: number) {
        this.data.selectedPositionsIds = this.data.selectedPositionsIds.filter((orderId) => orderId !== id)
    }

    public deselectAllPositions() {
        this.data.selectedPositionsIds = []
    }
}

export default new OrdersStore()
