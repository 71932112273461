import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex, Button, Typography, Link } from '@fto/ui'
import { Icon } from '@fto/icons'

import { DASHBOARD_ROUTE, makeRoute } from '@root/constants/routes'
import styles from './index.module.scss'

const SUPPORT_LINK = 'support@forextester.com'

const ErrorState: FC = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Flex alignItems='center' justifyContent='center' className={styles.ErrorState}>
            <Flex flexBasis={400} direction='column' gap={24} alignItems='center'>
                <Icon name='no-project-loaded' size={140} />

                <Flex direction='column' gap={8}>
                    <Typography type='h3-bold' color='gray-1000' block align='center'>
                        {t('projectsErrorState.title')}
                    </Typography>

                    <Typography type='text-regular' color='gray-900' block align='center'>
                        {t('projectsErrorState.subTitle')}
                    </Typography>
                </Flex>

                <Flex direction='column' gap={16} alignItems='center'>
                    <Flex gap={16} alignItems='center'>
                        <Button
                            onClick={() => {
                                location.reload()
                            }}
                            label={t('projectsErrorState.controls.refresh')}
                            type='primary'
                            before={<Icon name='arrow-path' size={18} color='currentColor' />}
                            classNames={{ content: styles.Btn }}
                        />
                        <Button
                            data-featurebase-feedback-portal={true}
                            label={t('projectsErrorState.controls.sendBug')}
                            before={<Icon name='bug' size={18} color='currentColor' />}
                            type='marketing'
                            classNames={{ content: styles.BugBtn }}
                        />
                    </Flex>

                    <Button
                        label={
                            <Flex alignItems='center' gap={4}>
                                <Icon name='arrow-long-left' size={24} color='currentColor' />
                                <Typography type='interface-medium'>
                                    {t('projectsErrorState.controls.backToDashboard')}
                                </Typography>
                            </Flex>
                        }
                        type='secondary'
                        onClick={() => navigate(makeRoute(DASHBOARD_ROUTE))}
                        classNames={{ content: styles.Btn }}
                    />
                </Flex>

                <Typography type='interface-medium' color='gray-900' block align='center'>
                    <Trans
                        i18nKey='projectsErrorState.controls.contactUs'
                        components={{
                            supportEmailLink: <Link href={`mailto:${SUPPORT_LINK}`}>{SUPPORT_LINK}</Link>
                        }}
                    />
                </Typography>
            </Flex>
        </Flex>
    )
}

export default ErrorState
