import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import { IconButton } from '@fto/icons'
import { addContextMenu, addModal, Flex, Tooltip } from '@fto/ui'

import CreateOrderStore from '@fto/lib/store/createOrder'
import OrdersStore from '@fto/lib/store/ordersStore'
import { MODAL_NAMES } from '@root/constants/modalNames'
import CellRenderer from '@root/pages/ChartPage/components/Terminal/components/Table/components/Body/components/Row/components/CellRenderer'
import { getOrderTypeLabel } from '@root/pages/ChartPage/components/Terminal/components/Table/components/Body/components/Row/components/CellRenderer/utils'
import { CellType, ColumnsListType, OrderDataType, TabType } from '@root/pages/ChartPage/components/Terminal/types'
import { UserTerminalSettings } from '@root/utils/localStorage'
import { COLUMNS_LIST_WITH_SIZES } from '@root/pages/ChartPage/components/Terminal/components/Table/constants/defaultCellsList'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'
import commonStyles from '../../../../commonStyles.module.scss'
import styles from './index.module.scss'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { isCtrlEvent } from '@fto/lib/utils/eventsUtils'

type Props = {
    cellsList: ColumnsListType
    posData: OrderDataType
    activeTab: TabType
    tableSettings: UserTerminalSettings
    isSelected: boolean
}
const Row: FC<Props> = ({ cellsList, posData, activeTab, tableSettings, isSelected }) => {
    const { t } = useTranslation()
    const closeOrder = useCallback(() => {
        OrdersStore.closeOrder(posData)
    }, [posData, OrdersStore.closeOrder])

    const modifyOrder = useCallback(() => {
        const { editOrder } = CreateOrderStore

        addModal(MODAL_NAMES.chart.orderModal, {
            isEdit: true,
            headerLabel: t('orders.modal.editHeader', {
                id: posData.id,
                orderType: t(getOrderTypeLabel(posData.type))
            })
        })
        editOrder(posData)
    }, [posData])

    const jumpToOpenPrice = useCallback(() => {
        OrdersStore.jumpToOpenPrice(posData)
    }, [posData])

    const reversePosition = useCallback(() => {
        CreateOrderStore.reverseOrder(posData)
    }, [posData])

    const doublePosition = useCallback(() => {
        CreateOrderStore.doublePosition(posData)
    }, [posData])

    const handleContextMenu = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()
        if (activeTab === 'history') return
        addContextMenu(CONTEXT_MENU_NAMES.terminal, {
            anchorX: event.clientX,
            anchorY: event.clientY,
            additionalProps: { order: posData, activeTab: activeTab }
        })
    }, [])

    const handleRowDoubleClick = useCallback(() => {
        if (activeTab === 'history') {
            // Define behavior for history tab
            jumpToOpenPrice()
        } else {
            modifyOrder()
        }
    }, [activeTab, modifyOrder])

    function onRowClick(event: MouseEvent) {
        const openPos = GlobalProcessingCore.ProcessingCore.OpenPositions.find((pos) => pos.tpos.ticket === posData.id)

        if (!openPos) {
            throw new StrangeError('Order not found in open positions')
        }
        const isMultiSelect = isCtrlEvent(event)
        const posIsSelected = OrdersStore.data.selectedPositionsIds.includes(posData.id)

        if (isMultiSelect) {
            handleMultipleSelection(posData, posIsSelected)
        } else {
            handleSingleSelection(posData, posIsSelected)
        }

        GlobalChartsController.Instance.updateCharts()
    }

    const controllersJSX = useMemo(() => {
        if (activeTab === 'history') {
            return null
        }

        const isOpenTab = activeTab === 'open'

        const BTN_SIZE = 12
        return (
            <Flex justifyContent='flex-end' alignItems='center' className={styles.ControlsWrapper} gap={4}>
                <Tooltip
                    color='dark'
                    placement='top'
                    content={t(`terminal.tooltips.${isOpenTab ? 'modifyPosition' : 'modifyOrder'}`)}
                >
                    <IconButton name='edit' size={BTN_SIZE} color='var(--color-gray-900)' onClick={modifyOrder} />
                </Tooltip>

                {isOpenTab && (
                    <>
                        <Tooltip color='dark' placement='top' content={t('terminal.tooltips.reversePosition')}>
                            <IconButton
                                name='arrow-turn-down'
                                size={BTN_SIZE}
                                color='var(--color-gray-900)'
                                onClick={reversePosition}
                            />
                        </Tooltip>
                        <Tooltip color='dark' placement='top' content={t('terminal.tooltips.doublePositions')}>
                            <IconButton
                                name='double-arrow'
                                size={BTN_SIZE}
                                color='var(--color-gray-900)'
                                onClick={doublePosition}
                            />
                        </Tooltip>
                    </>
                )}
                <Tooltip
                    color='dark'
                    placement='top'
                    content={t(`terminal.tooltips.${isOpenTab ? 'closePosition' : 'cancelOrder'}`)}
                >
                    <IconButton name='close' size={BTN_SIZE} color='var(--color-gray-900)' onClick={closeOrder} />
                </Tooltip>
            </Flex>
        )
    }, [activeTab, closeOrder, modifyOrder, reversePosition])

    return (
        <Flex
            className={cx(styles.Row, { [styles.selected]: isSelected })}
            onContextMenu={handleContextMenu}
            onDoubleClick={handleRowDoubleClick}
            onClick={(e) => onRowClick(e.nativeEvent)}
        >
            <Flex gap={4} className={styles.cellInfo}>
                {cellsList.map(({ value }) => (
                    <Flex
                        style={{
                            width: tableSettings[activeTab].settings[value]?.width || undefined
                        }}
                        justifyContent='center'
                        alignItems='center'
                        className={cx(
                            styles.cell,
                            commonStyles[`cell-${value}`],
                            tableSettings[activeTab]?.settings[value]?.isHidden && 'hidden',
                            COLUMNS_LIST_WITH_SIZES[activeTab].find((item) => item.value === value)?.notSortable &&
                                styles.notSortableCell
                        )}
                        key={value}
                        gap={4}
                    >
                        <CellRenderer type={value as CellType} value={posData[value as keyof typeof posData]} />
                    </Flex>
                ))}
            </Flex>
            <div className={cx(commonStyles.cellSettings, styles.cell, styles.controlCell)}>{controllersJSX}</div>
        </Flex>
    )
}

function arePropsEqual(prevProps: Props, nextProps: Props) {
    return (
        isEqual(prevProps.cellsList, nextProps.cellsList) &&
        isEqual(prevProps.posData, nextProps.posData) &&
        prevProps.activeTab === nextProps.activeTab &&
        isEqual(prevProps.tableSettings, nextProps.tableSettings) &&
        prevProps.isSelected === nextProps.isSelected
    )
}

export default React.memo(Row, arePropsEqual)

function handleSingleSelection(posData: OrderDataType, posIsSelected: boolean) {
    const openPos = GlobalProcessingCore.ProcessingCore.OpenPositions.find((pos) => pos.tpos.ticket === posData.id)

    if (!openPos) {
        throw new StrangeError('Order not found in open positions')
    }

    if (posIsSelected && OrdersStore.data.selectedPositionsIds.length === 1) {
        OrdersStore.deselectPosition(posData.id)
        openPos.deselectAllRelatedOrderLevels()
    } else {
        for (const id of OrdersStore.data.selectedPositionsIds) {
            const deselectPos = GlobalProcessingCore.ProcessingCore.OpenPositions.find((pos) => pos.tpos.ticket === id)
            if (deselectPos) {
                deselectPos.deselectAllRelatedOrderLevels()
            }
        }
        OrdersStore.deselectAllPositions()
        OrdersStore.selectPosition(posData.id)
        openPos.selectAllRelatedOrderLevels(false)
    }
}

function handleMultipleSelection(posData: OrderDataType, posIsSelected: boolean) {
    const openPos = GlobalProcessingCore.ProcessingCore.OpenPositions.find((pos) => pos.tpos.ticket === posData.id)

    if (!openPos) {
        throw new StrangeError('Order not found in open positions')
    }

    OrdersStore.selectPosition(posData.id)
    openPos.selectAllRelatedOrderLevels(true)
}
