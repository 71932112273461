import { FC, useCallback, ReactNode } from 'react'
import cx from 'classnames'

import { Icon } from '@fto/icons'
import { Button, Flex, Typography } from '@fto/ui'

import { Closable, RibbonInnerDropdownType, RibbonOptionType } from '../types'
import InnerDropdown from '../InnerDropdown'

import styles from '../../../../common.module.scss'

type Props = {
    options: RibbonOptionType[]
    name: string
    close: () => void
    dropdowns?: RibbonInnerDropdownType[]
    additionalElements?: FC<Closable> | ReactNode
    activeOption?: string
    withDropdownsSearch?: boolean
}

const RibbonDropdownMenu: FC<Props> = ({
    options,
    name,
    dropdowns,
    additionalElements,
    close,
    activeOption,
    withDropdownsSearch = false
}) => {
    const handleClick = useCallback((option: RibbonOptionType) => {
        option.action && option.action()
        close()
    }, [])

    return (
        <Flex direction='column' justifyContent='flex-start' className={styles.dropdown} gap={4}>
            <Typography className={styles.text} color='gray-600' type='tiny-regular'>
                {name}
            </Typography>
            <Flex direction='column' gap={4}>
                {options.map((option) => {
                    const isActive = option.value === activeOption

                    return (
                        <Button
                            key={option.value}
                            disabled={option.action === null}
                            block
                            classNames={{ content: cx(styles.buttonContent, { [styles.selected]: isActive }) }}
                            before={
                                <Icon
                                    name={option.icon || 'one-chart'}
                                    size={16}
                                    color={isActive ? 'primary-500' : 'gray-1000'}
                                />
                            }
                            type='tetriatry-white'
                            size='compact'
                            label={option.name}
                            onClick={() => handleClick(option)}
                        />
                    )
                })}
                {dropdowns &&
                    dropdowns.map((dropdown) => {
                        return (
                            <InnerDropdown
                                dropdown={dropdown}
                                close={close}
                                withDropdownsSearch={withDropdownsSearch}
                            />
                        )
                    })}
                {typeof additionalElements === 'function' ? additionalElements({ close }) : additionalElements}
            </Flex>
        </Flex>
    )
}

export default RibbonDropdownMenu
