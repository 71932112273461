import { SymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/SymbolCalculationStrategy'
import { TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'

export class USDBaseSymbolCalculationStrategy extends SymbolCalculationStrategy {
    public getPointCostForOneStandardLot(operationType: TTradePositionType): number {
        const closePrice = this.symbolData.getCurrentOpenPriceByOrderType(operationType)

        return (1 / closePrice) * this.symbolData.symbolInfo.lot * this.symbolData.symbolInfo.MinPoint
    }

    public calculateMarginRequirementForSymbol(operationType: TTradePositionType): number {
        return this.symbolData.symbolInfo.lot / this.symbolData.symbolInfo.CreditShoulder
    }
}
