import { OrderLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/OrderLevel'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { MarketValues, OrderValues, StoplossValues } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import { TPoint, TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { StoplossCalculationStrategy } from '@fto/lib/OrderModalClasses/StoplossCalculationStrategies/StoplossCalculationStrategy'
import { ChartControl } from '@fto/chart_components/ChartControl'
import { MarketLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/MarketLevel'
import { formatCurrency } from '@fto/lib/utils/ordersUtils'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import { PendingLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/PendingLevel'
import { showErrorToast, showSuccessToast } from '@root/utils/toasts'
import { t } from 'i18next'

export class StopLossLevel extends OrderLevel {
    public stopLossValues: StoplossValues | null = null
    private lastCorrectValue: StoplossValues | null = null

    draw(canvas: TGdiPlusCanvas): void {
        const isActive = this.IsCaptured() || this.isMouseInside() || this.isSelected

        const levels = this.owner.orderLevels.get(this.chartWindow)

        const openPriceOrderLevel = levels?.openPriceOrderLevel as MarketLevel

        if (
            this.owner.tpos.StopLoss === 0 &&
            (openPriceOrderLevel.isSelected ||
                openPriceOrderLevel.IsCaptured() ||
                openPriceOrderLevel.isMouseInside()) &&
            !this.IsCaptured()
        ) {
            this.tooltip = null
            this.drawEmptyState(canvas)
            return
        }

        const ctx = canvas.graphics.Context
        const { Top } = this.getLocation()
        const baseColor = this.isValid ? this.chartWindow.ChartOptions.ColorScheme.StopLossColor : this.invalidColor
        const labelText = 'SL'
        let valueText = Number(this.owner.getStopLossUSD().toFixed(2))
        let pointsText = `${formatCurrency(this.owner.getStopLossPoints() * -1, '', 0)} points`

        if ((this.IsCaptured() || openPriceOrderLevel.IsCaptured()) && this.stopLossValues) {
            valueText = Number(
                this.owner.getStopLossUSD(
                    this.stopLossValues.price,
                    (openPriceOrderLevel as PendingLevel).currentOpenPrice!
                )
            )

            pointsText = `${formatCurrency(this.stopLossValues.points * -1, '', 0)} points`
        }

        const ticketText = `#${this.owner.tpos.ticket}`
        const font = '12px Roboto Flex'
        this.drawCommonElements(
            canvas,
            this.getXCoordinateToDrawLevel(ctx),
            Top,
            labelText,
            formatCurrency(Number(valueText)),
            pointsText,
            ticketText,
            isActive,
            baseColor,
            16,
            font
        )

        const mousePos = this.chartWindow.MainChart.getLocalMousePos()
        if (
            this.isMouseInside() &&
            this.isMouseInsideInfoBox(new TPoint(mousePos.x, mousePos.y)) &&
            !this.IsCaptured()
        ) {
            const percentFromBalance =
                (((this.stopLossValues?.usd || this.owner.getStopLossUSD()) * 100) /
                    GlobalProcessingCore.ProcessingCore.Balance) *
                -1

            this.tooltip = `Stop Loss ${percentFromBalance.toFixed(2)}%`
        }
    }

    public recalculateLocationByY(price: number, atPrice: number | null = null): void {
        super.recalculateLocationByY(price)

        let newValues: StoplossValues | null = null
        const mainChart = this.chartWindow.MainChart

        if (this.calculationStrategy) {
            if (this.calculationStrategy instanceof StoplossCalculationStrategy) {
                const stoplossValues: StoplossValues = new StoplossValues()
                stoplossValues.price = price
                stoplossValues.points = this.owner.getStopLossPoints()

                const orderValues: OrderValues = new OrderValues()
                orderValues.lot = this.owner.tpos.lot
                orderValues.atPrice = atPrice || this.owner.tpos.OpenPrice

                const marketValues = new MarketValues()
                marketValues.minimumDistanceToPrice = mainChart.marketValues.minimumDistanceToPrice
                marketValues.minimumPriceChange = mainChart.marketValues.minimumPriceChange
                marketValues.pointValueForOneStandardLot = mainChart.marketValues.pointValueForOneStandardLot
                marketValues.equity = mainChart.marketValues.equity

                if (
                    [TTradePositionType.tp_Buy, TTradePositionType.tp_BuyStop, TTradePositionType.tp_BuyLimit].includes(
                        this.owner.PosType
                    )
                ) {
                    marketValues.ask = atPrice || this.owner.tpos.OpenPrice
                } else {
                    marketValues.bid = atPrice || this.owner.tpos.OpenPrice
                }

                // values that should be shown when dragging the stoploss line
                newValues = this.calculationStrategy.onChangeStoplossPrice(stoplossValues, marketValues, orderValues)

                if (newValues) {
                    this.owner.orderLevels.forEach((levels) => {
                        if (
                            levels.stopLossOrderLevel &&
                            this.type === 'Stoploss' &&
                            levels.stopLossOrderLevel instanceof StopLossLevel
                        ) {
                            levels.stopLossOrderLevel.stopLossValues = newValues
                        }
                    })
                }
            }
        }
    }

    onMouseDown(event: MouseEvent, sender: TMainChart): ChartControl | null {
        this.lastCorrectValue = this.stopLossValues
            ? { ...this.stopLossValues }
            : {
                  price: this.owner.tpos.StopLoss,
                  points: this.owner.getStopLossPoints(),
                  usd: this.owner.getStopLossUSD(),
                  percent: 0
              }
        return super.onMouseDown(event, sender)
    }

    close(): void {
        this.owner.resetStopLoss()
    }

    drawEmptyState(canvas: TGdiPlusCanvas): void {
        // paint only drag dots and label under the line if owner has buy pos and над the line if owner has sell pos
        const ctx = canvas.graphics.Context

        const levels = this.owner.orderLevels.get(this.chartWindow)
        if (!levels) {
            return
        }
        const { Left, Top } = (levels.openPriceOrderLevel as MarketLevel).infoBoxLocation

        const x = Left - 20
        const y = Top + 7.5
        const labelText = 'SL'
        const baseColor = this.chartWindow.ChartOptions.ColorScheme.StopLossColor
        const font = '12px Roboto Flex'
        ctx.font = font

        // Measure text widths
        const labelTextWidth = ctx.measureText(labelText).width
        const boxPadding = 8

        // Calculate box width
        const textWidth = labelTextWidth
        const boxWidth = textWidth + boxPadding * 2
        const boxHeight = 16

        const boxX = x - boxWidth / 2

        // Draw rounded rect
        this.drawRoundedRect(ctx, boxX, y - boxHeight / 2, boxWidth, boxHeight, 2, baseColor)

        // Draw dots for drag
        this.drawDragDots(ctx, boxX + 2.5, y - 3, baseColor, false)

        // Draw title inside the box
        const labelX = boxX + 10
        this.drawText(ctx, labelText, labelX, y, baseColor, font, false)

        this.infoBoxLocation = new TRect(boxX - 2, y - 8, boxX + boxWidth + 2, y + 8)
        this.setLocation(this.infoBoxLocation)
    }

    changePoints(newPoints: number, atPrice: number | null): void {
        const mainChart = this.chartWindow.MainChart

        if (this.calculationStrategy) {
            if (this.calculationStrategy instanceof StoplossCalculationStrategy) {
                const stoplossValues: StoplossValues = new StoplossValues()
                stoplossValues.points = newPoints

                const orderValues: OrderValues = new OrderValues()
                orderValues.lot = this.owner.tpos.lot
                orderValues.atPrice = atPrice || this.owner.tpos.OpenPrice

                const marketValues = new MarketValues()
                marketValues.minimumDistanceToPrice = mainChart.marketValues.minimumDistanceToPrice
                marketValues.minimumPriceChange = mainChart.marketValues.minimumPriceChange
                marketValues.pointValueForOneStandardLot = mainChart.marketValues.pointValueForOneStandardLot
                marketValues.equity = mainChart.marketValues.equity

                if (
                    [TTradePositionType.tp_Buy, TTradePositionType.tp_BuyStop, TTradePositionType.tp_BuyLimit].includes(
                        this.owner.PosType
                    )
                ) {
                    marketValues.ask = atPrice || this.owner.tpos.OpenPrice
                } else {
                    marketValues.bid = atPrice || this.owner.tpos.OpenPrice
                }

                const newValues = this.calculationStrategy.onChangeStoplossPoints(
                    stoplossValues,
                    marketValues,
                    orderValues
                )

                if (newValues) {
                    this.stopLossValues = newValues
                    this.owner.tpos.StopLoss = newValues.price
                    this.recalculateLocationByY(this.owner.tpos.StopLoss, atPrice)
                }
            }
        }
    }

    onMouseUp(event: MouseEvent, sender: TMainChart) {
        const x = event.offsetX
        const y = event.offsetY
        const isCurrentControl = this.isMouseInsideInfoBox(new TPoint(x, y)) || this.isPointInside(x, y)
        if (!this.isValid) {
            this.stopLossValues = this.lastCorrectValue
            this.owner.tpos.StopLoss = this.lastCorrectValue?.price || 0
            if (this.owner.tpos.PosType === TTradePositionType.tp_Buy && isCurrentControl) {
                showErrorToast({
                    title: t('orders.modal.toasts.invalidStopLossBuyTitle'),
                    message: t('orders.modal.toasts.invalidStopLossBuyText')
                })
            } else if (this.owner.tpos.PosType === TTradePositionType.tp_Sell && isCurrentControl) {
                showErrorToast({
                    title: t('orders.modal.toasts.invalidStopLossSellTitle'),
                    message: t('orders.modal.toasts.invalidStopLossSellText')
                })
            }
        } else {
            if (this.stopLossValues && this.IsCaptured()) {
                if (isCurrentControl && this.lastCorrectValue?.price !== this.stopLossValues.price) {
                    const decimals = this.owner.symbol?.symbolInfo.decimals
                    showSuccessToast({
                        title: t('orders.modal.toasts.stopLossUpdated', {
                            value: this.stopLossValues.price.toFixed(decimals || 5)
                        })
                    })
                }
                this.owner.tpos.StopLoss = this.stopLossValues.price
            }
        }

        this.recalculateLocationByY(this.owner.tpos.StopLoss)
        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
    }
}
