import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { Checkbox, ColorPicker, Flex, Typography } from '@fto/ui'
import { CheckboxAndColorSettingType, OptionSettingRef } from '../../../types'
import styles from '../CheckboxSetting/index.module.scss'
import { t } from 'i18next'
import { getBooleanValue, getStringValue, setBooleanValue, setStringValue } from '../../../utils'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

const CheckboxAndColorSetting = forwardRef<OptionSettingRef, CheckboxAndColorSettingType>(
    ({ tab, chart, option, applyToAll, onDifferentSettingsUpdate }, ref) => {
        const [checked, setChecked] = useState<boolean>(() =>
            getBooleanValue(chart, option.booleanConfigType, option.booleanKey)
        )
        const [selectedColor, setSelectedColor] = useState<string>(() =>
            getStringValue(chart, option.colorConfigType, option.colorKey)
        )

        const setColorValue = useCallback(
            (value: string, redraw = true, shouldUpdateConfig = true) => {
                setSelectedColor(value)

                shouldUpdateConfig && setStringValue(chart, option.colorConfigType, option.colorKey, value, applyToAll)

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [chart, option, applyToAll]
        )

        const handleColorChange = useCallback(
            (value: string) => {
                onDifferentSettingsUpdate(tab, value, selectedColor, option.colorKey)
                setColorValue(value)
            },
            [tab, option, selectedColor, setColorValue]
        )

        const setCheckboxValue = useCallback(
            (value: boolean, redraw = true, shouldUpdateConfig = true) => {
                setChecked(value)

                shouldUpdateConfig &&
                    setBooleanValue(chart, option.booleanConfigType, option.booleanKey, value, applyToAll)

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [chart, option, applyToAll]
        )

        const handleCheckboxChange = useCallback(
            (value: boolean) => {
                onDifferentSettingsUpdate(tab, value, checked, option.colorKey)
                setCheckboxValue(value)
            },
            [tab, option, checked, setCheckboxValue, onDifferentSettingsUpdate]
        )

        const loadOptionSetting = useCallback(
            (data: any, shouldUpdateConfig = true) => {
                setColorValue(data.color.value, false, shouldUpdateConfig)
                setCheckboxValue(data.checked, false, shouldUpdateConfig)
            },
            [setColorValue, setCheckboxValue]
        )

        useImperativeHandle(ref, () => ({ name: option.key, loadOptionSetting }), [option.key, loadOptionSetting])

        return (
            <Flex gap={4} justifyContent='space-between' alignItems='center'>
                <Checkbox
                    className={styles.checkbox}
                    label={
                        <Typography className={styles.checkboxLabel} type='text-regular'>
                            {t(option.name)}
                        </Typography>
                    }
                    block
                    inputControlVariant={'row-reverse'}
                    checked={checked}
                    onChange={handleCheckboxChange}
                ></Checkbox>
                <ColorPicker color={selectedColor} onColorChange={handleColorChange} withOpacity={false} />
            </Flex>
        )
    }
)

export default CheckboxAndColorSetting
