import { FC } from 'react'

import { Icon } from '@fto/icons'
import { Button, Flex, Modal, Typography, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'

import styles from './modals.module.scss'
import { useTranslation } from 'react-i18next'

export const ThankYouModal: FC = () => {
    const { t } = useTranslation()

    return (
        <Modal name={MODAL_NAMES.payment.thankYou} size='xs'>
            <Modal.Header>
                <Flex direction='column' alignItems='center'>
                    <Icon name='success' size={72} />
                    <Typography type='h3-bold' align='center'>
                        {t('profile.modals.projects.thankYouCongratsHeader')}
                    </Typography>
                    <Typography type='h3-bold' align='center'>
                        {t('profile.modals.projects.thankYouStartedHeader')}
                    </Typography>
                </Flex>
            </Modal.Header>
            <Modal.Content className={styles.line}>
                <Typography color='gray-900' type='text-regular' align='center'>
                    {t('profile.modals.projects.successText')}
                </Typography>
                <Typography color='gray-900' type='text-regular' align='center'>
                    {t('profile.modals.projects.emailText')}
                </Typography>
            </Modal.Content>
            <Modal.Controls>
                <Button
                    label={t('profile.modals.projects.getStarted')}
                    onClick={() => removeModal(MODAL_NAMES.payment.thankYou)}
                    type='primary'
                    block
                />
            </Modal.Controls>
        </Modal>
    )
}
