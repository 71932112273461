import { t } from 'i18next'

import { TIndexBuffer } from '../api/IIndicatorApi'
import Constants, {
    TDrawStyle,
    TOptValue_number,
    TOptionType,
    TOutputWindow,
    TPenStyle,
    TPriceType
} from '../api/IndicatorInterfaceUnit'
import { UserIndicator } from '../api/UserIndicator'

export default class ADX extends UserIndicator {
    ADXperiod: TOptValue_number = new TOptValue_number(14)
    ApplyToPrice: TOptValue_number = new TOptValue_number(TPriceType.pt_Close)

    // Buffers
    ADXBuffer!: TIndexBuffer
    PlusDiBuffer!: TIndexBuffer
    MinusDiBuffer!: TIndexBuffer
    PlusSdiBuffer!: TIndexBuffer
    MinusSdiBuffer!: TIndexBuffer
    TempBuffer!: TIndexBuffer

    Init(): void {
        this.api.RecalculateMeAlways()
        this.api.IndicatorShortName(t('indicators.adx'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.AddLevel(0, TPenStyle.psDot, 1, '#ada9a9', 1)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.general.generalFields.period'), TOptionType.ot_Integer, this.ADXperiod)
        this.api.SetOptionRange(t('indicatorModal.general.generalFields.period'), 1, Constants.MAX_INT)

        this.api.RegApplyToPriceOption(this.ApplyToPrice, '')

        this.ADXBuffer = this.api.CreateIndexBuffer()
        this.PlusDiBuffer = this.api.CreateIndexBuffer()
        this.MinusDiBuffer = this.api.CreateIndexBuffer()
        this.PlusSdiBuffer = this.api.CreateIndexBuffer()
        this.MinusSdiBuffer = this.api.CreateIndexBuffer()
        this.TempBuffer = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(3)
        this.api.SetIndexBuffer(0, this.ADXBuffer)
        this.api.SetIndexBuffer(1, this.PlusDiBuffer)
        this.api.SetIndexBuffer(2, this.MinusDiBuffer)

        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#FFFF00')
        this.api.SetIndexLabel(0, t('indicatorModal.adx.fields.adx'))

        this.api.SetIndexStyle(1, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#00FF00')
        this.api.SetIndexLabel(1, t('indicatorModal.adx.fields.plusDi'))

        this.api.SetIndexStyle(2, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#0000FF')
        this.api.SetIndexLabel(2, t('indicatorModal.adx.fields.minusDi'))
    }

    Calculate(index: number): void {
        let tr, pdm, mdm, divide, num1, num2, num3, k: number
        const price_low = this.api.Low(index)
        const price_high = this.api.High(index)

        pdm = price_high - this.api.High(index + 1)
        mdm = this.api.Low(index + 1) - price_low
        pdm = pdm < 0 ? 0 : pdm
        mdm = mdm < 0 ? 0 : mdm

        if (pdm === mdm) {
            pdm = 0
            mdm = 0
        } else if (pdm < mdm) {
            pdm = 0
        } else if (mdm < pdm) {
            mdm = 0
        }

        num1 = Math.abs(price_high - price_low)
        num2 = Math.abs(price_high - this.api.GetPrice(index + 1, this.ApplyToPrice.value))
        num3 = Math.abs(price_low - this.api.GetPrice(index + 1, this.ApplyToPrice.value))
        tr = Math.max(num1, num2)
        tr = Math.max(tr, num3)

        if (tr === 0) {
            this.PlusSdiBuffer.setValue(index, 0)
            this.MinusSdiBuffer.setValue(index, 0)
        } else {
            this.PlusSdiBuffer.setValue(index, (100.0 * pdm) / tr)
            this.MinusSdiBuffer.setValue(index, (100.0 * mdm) / tr)
        }

        k = 2 / (this.ADXperiod.value + 1)
        if (index === this.api.Bars() - 2) {
            this.PlusDiBuffer.setValue(index + 1, this.PlusSdiBuffer.getValue(index + 1))
            this.MinusDiBuffer.setValue(index + 1, this.MinusSdiBuffer.getValue(index + 1))
        }

        this.PlusDiBuffer.setValue(
            index,
            this.PlusSdiBuffer.getValue(index) * k + this.PlusDiBuffer.getValue(index + 1) * (1 - k)
        )

        this.MinusDiBuffer.setValue(
            index,
            this.MinusSdiBuffer.getValue(index) * k + this.MinusDiBuffer.getValue(index + 1) * (1 - k)
        )

        divide = Math.abs(this.PlusDiBuffer.getValue(index) + this.MinusDiBuffer.getValue(index))
        if (divide === 0) {
            this.TempBuffer.setValue(index, 0)
        } else {
            this.TempBuffer.setValue(
                index,
                (100 * Math.abs(this.PlusDiBuffer.getValue(index) - this.MinusDiBuffer.getValue(index))) / divide
            )
        }

        if (index === this.api.Bars() - 2) {
            this.ADXBuffer.setValue(index + 1, this.TempBuffer.getValue(index + 1))
        }

        this.ADXBuffer.setValue(
            index,
            this.TempBuffer.getValue(index) * k + this.ADXBuffer.getValue(index + 1) * (1 - k)
        )
    }
}
