import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChartOptions, TPosMarker } from '../../charting/ChartBasicClasses'
import { TPaintToolsList } from '../../charting/paint_tools/PaintToolsList'
import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import { TBrushStyle, TColor, TPenStyle, TPoint, TRect } from '../../delphi_compatibility/DelphiBasicTypes'
import { DelphiMathCompatibility } from '../../delphi_compatibility/DelphiMathCompatibility'
import { ColorHelperFunctions } from '../../drawing_interface/ColorHelperFunctions'
import { TGdiPlusCanvas } from '../../drawing_interface/GdiPlusCanvas'
import { TIndexPos, TLevelData } from '../../drawing_interface/GraphicObjects'
import { TIndicator } from '../../extension_modules/indicators/IndicatorUnit'
import { TRuntimeIndicatorsList } from '../../extension_modules/indicators/RuntimeIndicatorList'
import { TVisibleIndexBuffer } from '../../extension_modules/indicators/VisibleIndexBuffer'
import { TMyObjectList } from '../../ft_types/common/Common'
import { CustomCursorPointers, TCursor, TCustomCursor } from '../../ft_types/common/CursorPointers'
import { StrsConv } from '../../ft_types/common/StrsConv'
import { TChunkMapStatus } from '@fto/lib/ft_types/data/TChunkMapStatus'
import { TNoExactMatchBehavior } from '../../ft_types/data/chunks/ChunkEnums'
import IFMBarsArray from '../../ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import GlobalOptions from '../../globals/GlobalOptions'
import MouseTracker from '../../utils/MouseTracker'
import { NotImplementedError } from '../../utils/common_utils'
import { TGridLine, TLevelType } from '../auxiliary_classes_charting/ChartingEnums'
import { TGridLineList } from '../auxiliary_classes_charting/GridLines/GridLines'
import TPaintContext from '../auxiliary_classes_charting/PaintContextCache'
import { TPaintToolStatus, TPaintToolType } from '../paint_tools/PaintToolsAuxiliaryClasses'
import { TBasicChart } from './VeryBasicChart'
import { TChartInfo, TDrawStyle } from '@fto/lib/extension_modules/indicators/api/IndicatorInterfaceUnit'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { TChartWindow } from '../chart_windows/ChartWindow'
import CommonDataUtils from '@fto/lib/ft_types/data/DataUtils/CommonDataUtils'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import TDateDiff from '@fto/lib/delphi_compatibility/DateTimeAuxiliary/TDateDiff'
import IDateRange from '@fto/lib/delphi_compatibility/DateTimeAuxiliary/IDateRange'
import INumberRange from '@fto/lib/common/UtilObjects/INumberRange'
import ChartUtils from './ChartUtils'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/DllIndicatorUnit'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import { crosshairManager } from '@fto/lib/globals/CrosshairManager'
import { TimeframeUtils } from '@fto/lib/ft_types/common/TimeframeUtils'
import { IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'

//TODO: move these classes to separate files
class TPointsList extends TMyObjectList<TPointPair> {}

export class DateIndex {
    DateTime: TDateTime
    index: number

    constructor(DateTime: TDateTime, index: number) {
        this.DateTime = DateTime
        this.index = index
    }
}

class TDateIndexCache extends TMyObjectList<DateIndex> {
    constructor() {
        super()
    }

    public AddItem(DateTime: TDateTime, index: number): void {
        const item = new DateIndex(DateTime, index)
        this.push(item)
    }

    public GetIndexByDate(DateTime: TDateTime): {
        found: boolean
        index: number
    } {
        for (const item of this) {
            if (item.DateTime === DateTime) {
                return { found: true, index: item.index }
            }
        }
        return { found: false, index: -1 }
    }
}

export class TScrollInfo {
    public TotalWidthPix: number
    public PageWidthPix: number
    public MaxLeftBar: number
    public x_offset: number
    public LeftOffset: number
    public PixBetweenBars: number

    constructor() {
        this.TotalWidthPix = 0 // Default value
        this.PageWidthPix = 0 // Default value
        this.MaxLeftBar = 0 // Default value
        this.x_offset = 0 // Default value
        this.LeftOffset = 0 // Default value
        this.PixBetweenBars = 0 // Default value
    }
}

export class TSelOrder {
    OrderHandle: number
    LevelType: TLevelType
    value: number

    constructor() {
        this.OrderHandle = -1 // Default value
        this.LevelType = TLevelType.lt_Price // Default value
        this.value = 0 // Default value
        this.clear()
    }

    init(handle: number, _type: TLevelType, aValue: number): void {
        this.OrderHandle = handle
        this.LevelType = _type
        this.value = aValue
    }

    empty(): boolean {
        return this.OrderHandle === -1
    }

    clear(): void {
        this.OrderHandle = -1
    }
}

export class TPointPair {
    x: number
    y1: number
    y2: number

    constructor(x: number, y1: number, y2: number) {
        this.x = x
        this.y1 = y1
        this.y2 = y2
    }
}

export class TSelIndValue {
    indicator: TIndicator | null
    runtimeIndicator: TRuntimeIndicator | null
    buffer: TVisibleIndexBuffer | null
    index: number
    value: number
    sender: TChart | null = null

    constructor() {
        this.indicator = null
        this.runtimeIndicator = null
        this.buffer = null
        this.index = 0
        this.value = 0
    }

    init(ind: TIndicator, buff: TVisibleIndexBuffer, idx: number, v: number): void {
        this.indicator = ind
        this.runtimeIndicator = this.indicator as TRuntimeIndicator
        this.buffer = buff
        this.index = idx
        this.value = v
    }

    clear(): void {
        this.indicator = null
    }

    empty(): boolean {
        // Check if the indicator is null
        return this.indicator === null
    }

    equalTo(rec: TSelIndValue): boolean {
        return (
            this.indicator === rec.indicator &&
            this.buffer?.buffer === rec.buffer?.buffer &&
            this.index === rec.index &&
            this.value === rec.value
        )
    }

    protected FilterPoints(points: TPointsList, threshold: number): TPointPair[] {
        if (points.length < 2) return points // Need at least 3 points to apply the filter to the first and last points

        const filteredPoints: TPointPair[] = []

        // Check the first point against the second point
        if (
            Math.abs(points[1].x - points[0].x) <= threshold &&
            Math.abs(points[1].y1 - points[0].y1) <= threshold &&
            Math.abs(points[1].y2 - points[0].y2) <= threshold
        ) {
            filteredPoints.push(points[0])
        }

        // Add all middle points
        for (let i = 1; i < points.length - 1; i++) {
            filteredPoints.push(points[i])
        }

        // Check the last point against the second to last point
        const lastPoint = points[points.length - 1]
        const secondLastPoint = points[points.length - 2]
        if (
            Math.abs(lastPoint.x - secondLastPoint.x) <= threshold &&
            Math.abs(lastPoint.y1 - secondLastPoint.y1) <= threshold &&
            Math.abs(lastPoint.y2 - secondLastPoint.y2) <= threshold
        ) {
            filteredPoints.push(lastPoint)
        }

        return filteredPoints
    }

    drawMarker(canvas: CanvasRenderingContext2D, chart: TChart): void {
        if (this.buffer && this.buffer.buffer && this.buffer.IsVisible()) {
            const color = this.buffer.style.color || '#000000'

            const x = chart.GetX(chart.GetMouseIndex())
            const y = chart.GetY(this.value)

            canvas.beginPath()
            canvas.arc(x || 0, y || 0, 4, 0, 2 * Math.PI)
            canvas.fillStyle = color
            canvas.fill()
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Could not get buffer or buffer.buffer')
        }
    }
}

export abstract class TChart extends TBasicChart {
    protected _bars: IFMBarsArray | null = null

    protected fVariableDt: boolean | undefined

    //FIXME: get rid of all these ! marks
    protected fMargins!: TRect
    public PaintTools: TPaintToolsList
    public staticPaintTools: TPaintToolsList
    public initialPaintTools: TPaintToolsList

    private _barSizeInPixels: number | undefined //former fBarSpace - space between bars in pixels
    public get barSizeInPixels(): number {
        //do not do (!this._barSizeInPixels comparison for numbers because 0 will return false)
        if (this._barSizeInPixels === undefined) {
            throw new StrangeError('BarSizeInPixels is not initialized in Chart')
        }
        return this._barSizeInPixels
    }

    protected fBarWidth2!: number // bar width div 2
    protected fVScale!: number // vertical scale
    protected fLeftOffs!: number // left offset of the first bar on chart in pixels
    protected fVPoint!: number // horis. grid value in currency points
    protected fMinValue!: number // min visible low value
    protected fMaxValue!: number // max visible high value
    protected _areDimensionsCalculated = false // arrays are not empty and could be painted

    protected fPosMarker: TPosMarker
    protected fFocusedOrder!: TSelOrder

    protected fIndicators: TRuntimeIndicatorsList
    public static SelIndValue: TSelIndValue | null
    public static SelectedIndicator: TIndicator | null = null

    //cache
    private fDateCache: TDateIndexCache = new TDateIndexCache()

    //diagnostics
    public PaintCounter = 0

    constructor(parentChartWindow: TChartWindow) {
        super(parentChartWindow)

        this.PaintTools = new TPaintToolsList()
        this.staticPaintTools = new TPaintToolsList()

        this.fIndicators = new TRuntimeIndicatorsList()

        this._areDimensionsCalculated = false

        this.fDateCache = new TDateIndexCache()
        this.PaintTools = new TPaintToolsList()
        this.PaintTools.chart = this
        this.fPosMarker = new TPosMarker()

        this.initialPaintTools = new TPaintToolsList()

        this.SetName('TChart')
    }

    //may be overridden in inherited classes
    protected get isDataLoaded(): boolean {
        // console.log('isDataLoaded in BasicChart', this._bars, this._bars?.IsSeeked)
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (this._bars && this._bars.IsSeeked) {
            return true
        }
        return false
    }

    public get GdiCanvas(): TGdiPlusCanvas {
        if (this.IsPaintContextCacheInitialized) {
            return this.PaintContextCache.GdiCanvas
        } else {
            return new TGdiPlusCanvas(this.HTML_Canvas)
        }
    }

    public get indicators(): TRuntimeIndicatorsList {
        return this.fIndicators
    }

    public get CanBePainted(): boolean {
        return this._areDimensionsCalculated
    }

    public set Cursor(value: TCursor | TCustomCursor) {
        if (CustomCursorPointers.isCustomCursor(value)) {
            // Handle custom cursor
            CustomCursorPointers.setCursor(this.HTML_Canvas, value)
        } else {
            // Handle standard cursor (assumed to be a string)
            this.HTML_Canvas.style.cursor = value as string
        }
    }

    // Getter for VScale
    public get VScale(): number {
        return this.fVScale
    }

    // Optionally, if you need a setter for VScale
    public set VScale(value: number) {
        this.fVScale = value
    }

    get LeftPosition(): number {
        return this._chartWindow.LeftPosition
    }

    set LeftPosition(newLeftPosition: number) {
        this._chartWindow.LeftPosition = newLeftPosition
    }

    //TODO: get rid of it since it is the same as LeftPosition
    get position(): number {
        return this.LeftPosition
    }

    get left_X_offset(): number {
        return this._chartWindow.left_X_offset
    }

    set left_X_offset(newLeftXOffset: number) {
        this._chartWindow.left_X_offset = newLeftXOffset
    }

    get Bars(): IFMBarsArray {
        if (!this._bars) {
            throw new StrangeError('Bars are not initialized in chart')
        }
        return this._bars
    }

    set Bars(newBars: IFMBarsArray) {
        this._bars = newBars
    }

    public abstract Paint(): void

    public abstract GetY(value: number): number

    public invalidate(): void {
        this._chartWindow.invalidate()
    }

    public InitVars(opt: TChartOptions, pos: number, offs: number, p_marker: TPosMarker, f_order: TSelOrder): void {
        this.SetChartOptions(opt)
        this.LeftPosition = pos
        this.left_X_offset = offs

        this.fPosMarker = p_marker
        this.fFocusedOrder = f_order
    }

    public MagnetPoint(dateTime: TDateTime, price: number): number {
        let MinPrice: number, dist: number

        const GetMin = (value: number) => {
            if (Math.abs(price - value) < dist) {
                MinPrice = value
                dist = Math.abs(price - value)
            }
        }

        const index = this.GetGlobalIndexByDate(dateTime, TNoExactMatchBehavior.nemb_ReturnNearestLower, true)
        if (!this.Bars.IsIndexValid(index)) {
            return price
        }

        const foundBar = this.Bars.GetItemByGlobalIndex(index)
        if (!foundBar) {
            throw new StrangeError('Bar is not available - TChart.MagnetPoint')
        }
        const bar = foundBar
        dist = 100
        MinPrice = price

        GetMin(bar.open)
        GetMin(bar.high)
        GetMin(bar.low)
        GetMin(bar.close)

        dist = Math.abs(this.GetY(price) - this.GetY(price + dist))
        if (GlobalOptions.Options.StrongMagnetMode) {
            if (dist <= GlobalOptions.Options.StrongMagnetSensitivity) {
                price = MinPrice
            }
        } else {
            if (dist <= GlobalOptions.Options.MagnetSensitivity) {
                price = MinPrice
            }
        }

        return price
    }

    public getBoundingClientRect(): DOMRect {
        return this.HTML_Canvas.getBoundingClientRect()
    }

    public MouseToLocal(param: MouseEvent | TPoint | null = null): {
        x: number
        y: number
    } {
        const dpr = window.devicePixelRatio
        const rect = this.HTML_Canvas.getBoundingClientRect()
        let x = 0
        let y = 0

        if (param instanceof MouseEvent) {
            const mouseEvent: MouseEvent = param
            x = mouseEvent.clientX - rect.left
            y = mouseEvent.clientY - rect.top
        } else if (param instanceof TPoint) {
            const point: TPoint = param
            x = point.x - rect.left
            y = point.y - rect.top
        } else if (param === null) {
            const tracker = MouseTracker.getInstance()
            x = tracker.x - rect.left
            y = tracker.y - rect.top
        } else {
            throw new StrangeError('Invalid arguments')
        }

        return {
            x: x * dpr,
            y: y * dpr
        }
    }

    public abstract GetIndicatorUnderMouse(
        event: MouseEvent
    ): [TVisibleIndexBuffer | null, number | null, TIndicator | null]

    public ScreenToLocal(point: TPoint): TPoint {
        const rect = this.HTML_Canvas.getBoundingClientRect()
        const dpr = window.devicePixelRatio
        const x = point.x - rect.left
        const y = point.y - rect.top

        return new TPoint(x * dpr, y * dpr)
    }

    public IsMouseInsideXY(mouseX_inCanvasCoords: number, mouseY_inCanvasCoords: number): boolean {
        // Check if the mouse is inside the canvas element
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (
            mouseX_inCanvasCoords >= 0 &&
            mouseX_inCanvasCoords <= this.HTML_Canvas.width &&
            mouseY_inCanvasCoords >= 0 &&
            mouseY_inCanvasCoords <= this.HTML_Canvas.height
        ) {
            return true
        }
        return false
    }

    public IsMouseInside(): boolean {
        // Mouse position relative to the document
        // TODO: check if we should use MouseToLocal here
        const relativeCoordinates = this.MouseToLocal()

        const relativeX = relativeCoordinates.x // X relative to chartElement
        const relativeY = relativeCoordinates.y // Y relative to chartElement

        return this.IsMouseInsideXY(relativeX, relativeY)
    }

    // Getter for margins
    public getMargins(): TRect {
        return this.fMargins
    }

    protected ClearBox(): void {
        const context = this.PaintContextCache.canvasContext

        const paintRect = this.PaintContextCache.PaintRect

        // Set brush style
        context.fillStyle = this.ChartOptions.ColorScheme.BackgroundColor

        // Clear any existing paths to start drawing a new shape
        context.beginPath()

        // Fill the rectangle with the background color
        context.fillRect(paintRect.Left, paintRect.Top, paintRect.Width, paintRect.Height)
    }

    public NumberOfVisibleBars(): number {
        //TODO: maybe this can be optimized by taking the value from PaintContextCache
        const paintRect: TRect = this.GetPaintRect()

        return ChartUtils.NumberOfVisibleBars(paintRect, this.barSizeInPixels, this.left_X_offset)
    }

    public GetVisibleIndexRange_includingEmptySpace(): INumberRange {
        return { start: this.position, end: this.GetLastVisibleBarIndex() }
    }

    public GtEmptySpaceSizeAsIndexDiff(): number {
        const wholeChartRange = this.GetVisibleIndexRange_includingEmptySpace()
        const actualBarsRange = this.GetVisibleIndexRange_excludingEmptySpace()

        return wholeChartRange.end - actualBarsRange.end
    }

    public GetEmptySpaceSizeAsDateDiff(): TDateDiff {
        const indexDiff = this.GtEmptySpaceSizeAsIndexDiff()
        const barSizeInDateTimeUnits = this.getBarSizeInDateTime().diffInTDateTimeUnits
        return new TDateDiff(indexDiff * barSizeInDateTimeUnits)
    }

    public GetEmptySpaceIndexRange(): INumberRange {
        const wholeChartRange = this.GetVisibleIndexRange_includingEmptySpace()
        const actualBarsRange = this.GetVisibleIndexRange_excludingEmptySpace()

        return { start: actualBarsRange.end + 1, end: wholeChartRange.end }
    }

    public GetVisibleIndexRange_excludingEmptySpace(): INumberRange {
        let firstVisibleIndex = 0
        let lastVisibleIndex_excluding_emptySpace = 0
        if (this.Bars.LastItemInTestingIndexAvailable) {
            firstVisibleIndex = this.position
            lastVisibleIndex_excluding_emptySpace = Math.min(
                this.GetLastVisibleBarIndex(),
                this.Bars.LastItemInTestingIndex
            )
        } else {
            firstVisibleIndex = 0
            lastVisibleIndex_excluding_emptySpace = 0
        }

        if (firstVisibleIndex > lastVisibleIndex_excluding_emptySpace) {
            throw new StrangeError(
                `Invalid VisibleIndexRange excluding empty space: firstVisibleIndex: ${firstVisibleIndex}, lastVisibleIndex_excluding_emptySpace: ${lastVisibleIndex_excluding_emptySpace}`
            )
        }

        return { start: firstVisibleIndex, end: lastVisibleIndex_excluding_emptySpace }
    }

    public GetChartInfo(): TChartInfo {
        const result = new TChartInfo()

        result.FirstIndex = this.Bars.LastItemInTestingIndex - this.position
        result.LastIndex = result.FirstIndex - this.NumberOfVisibleBars()
        if (result.LastIndex < 0) {
            result.LastIndex = 0
        }

        result.PaintRect = this.PaintContextCache.PaintRect
        result.BarWidth = this.barSizeInPixels
        result.currZoom = this.ChartOptions.HorzMagnifier
        return result
    }

    public getBarSizeInDateTime(): TDateDiff {
        return new TDateDiff(this.ChartOptions.Timeframe * DateUtils.OneMinute)
    }

    public GetPreciseVisibleDateRange(): IDateRange {
        const paintRect = this.GetPaintRect()
        const firstDateVisible = this.GetPreciseDateFromX(paintRect.Left)
        const lastDateVisible = this.GetPreciseDateFromX(paintRect.Right)

        DebugUtils.logTopic(
            ELoggingTopics.lt_ScrollAndZoom,
            'firstDateVisible:',
            firstDateVisible,
            DateUtils.DF(firstDateVisible),
            'lastDateVisible:',
            lastDateVisible,
            DateUtils.DF(lastDateVisible)
        )

        return { start: firstDateVisible, end: lastDateVisible }
    }

    public GetFirstVisibleBarIndex(): number {
        return this.position
    }

    public GetLastVisibleBarIndex(): number {
        let numberOfVisibleBars
        if (this.IsPaintContextCacheInitialized) {
            numberOfVisibleBars = this.PaintContextCache.NumberOfVisibleBars
        } else {
            numberOfVisibleBars = this.NumberOfVisibleBars()
        }
        return this.position + numberOfVisibleBars - 1
    }

    public GetCenterVisibleBarIndex(): number {
        return Math.floor((this.GetFirstVisibleBarIndex() + this.GetLastVisibleBarIndex()) / 2)
    }

    protected GetVisibleDateRangeAccordingToBars(): [TDateTime, TDateTime] {
        if (
            this.Bars.ChunkMapStatus === TChunkMapStatus.cms_Empty ||
            this.Bars.ChunkMapStatus === TChunkMapStatus.cms_Loading ||
            isNaN(this.position)
        ) {
            return [DateUtils.EmptyDate, DateUtils.EmptyDate]
        }

        const startDateOfVisibleRange = this.Bars.GetDateByGlobalIndex(this.position, true)

        const endDateOfVisibleRange = this.getEndDateOfVisibleRange()

        this.__validateVisibleRange(startDateOfVisibleRange, endDateOfVisibleRange)

        return [startDateOfVisibleRange, endDateOfVisibleRange]
    }

    private __validateVisibleRange(startDateOfVisibleRange: TDateTime, endDateOfVisibleRange: TDateTime): void {
        if (endDateOfVisibleRange < startDateOfVisibleRange) {
            if (DebugUtils.DebugMode) {
                // eslint-disable-next-line no-debugger
                debugger
                // eslint-disable-next-line no-console
                console.log('Deliberate stack overflow error here for debugging purposes')
                this.GetVisibleDateRangeAccordingToBars()
            }
            throw new StrangeError(
                'Invalid date range in GetVisibleDateRangeAccordingToBars, endDateOfVisibleRange < startDateOfVisibleRange'
            )
        }

        //for debug
        if (DebugUtils.DebugMode && endDateOfVisibleRange - startDateOfVisibleRange > DateUtils.OneYear_Approx * 50) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Visible date range is too big')
            const __startDateOfVisibleRange = this.Bars.GetDateByGlobalIndex(this.position, true)
            const __endDateOfVisibleRange = this.getEndDateOfVisibleRange()
        }
    }

    private getEndDateOfVisibleRange(): TDateTime {
        const lastVisibleBarIndex = this.GetLastVisibleBarIndex()

        if (this.Bars.LastItemInTestingIndex >= lastVisibleBarIndex) {
            return this.Bars.GetDateByGlobalIndex(lastVisibleBarIndex, true)
        } else {
            //last bar is within the visible range
            // get range even if it is less bars then visible chart space
            if (this.Bars.LastItemInTestingIndexAvailable) {
                const barSizeInDateTimeUnits = this.getBarSizeInDateTime().diffInTDateTimeUnits
                //TODO: do we need this +1 here?
                const sizeOfEmptySpaceAfterLastBarInTesting =
                    barSizeInDateTimeUnits * (lastVisibleBarIndex - this.Bars.LastItemInTestingIndex)
                return this.Bars.LastItemInTesting.DateTime + sizeOfEmptySpaceAfterLastBarInTesting
            } else {
                //it seems that we are not seeked yet, so we should not be here
                throw new StrangeError(
                    'LastItemInTestingIndex is not available, but we are trying to get the visible date range'
                )
            }
        }
    }

    public getDateRange(): [TDateTime, TDateTime] {
        return this.GetVisibleDateRangeAccordingToBars()
    }

    public CalcDimensions(): void {
        const scale = this.ChartOptions.GetScaleInfo()
        this._barSizeInPixels = scale.PixBetweenBars
        this.fBarWidth2 = scale.BarWidth2
        this.fLeftOffs = this.fBarWidth2 + 2 + this.left_X_offset
    }

    public get ClientRect(): TRect {
        return new TRect(
            0, // x position
            0, // y position
            this.HTML_Canvas.width, // width of the canvas
            this.HTML_Canvas.height // height of the canvas
        )
    }

    public GetPaintRect(): TRect {
        const result = new TRect(
            this.ClientRect.Left,
            this.ClientRect.Top,
            this.ClientRect.Right,
            this.ClientRect.Bottom
        )

        result.DoSetRect(
            result.Left + this.fMargins.Left,
            result.Top + this.fMargins.Top,
            result.Right - this.fMargins.Right,
            result.Bottom - this.fMargins.Bottom
        )

        return result
    }

    protected PaintGrid(): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Painting, 'Painting grid')
        if (!this._areDimensionsCalculated) {
            return
        }

        this.PaintVerticalGrid()
        this.PaintHorizontalGrid()
    }

    protected PaintRooler(): void {
        const crosshair = crosshairManager.getCrosshairState()

        if (!crosshair || crosshair.roolerDateTime === -1 || !crosshairManager.isChartSource(this.ChartWindow)) {
            return
        }

        const context = this.PaintContextCache.canvasContext

        const x1 = this.GetXFromDate(crosshair.DateTime)
        const x2 = this.ScreenToLocal(crosshair.roolerPoint).x
        const y1 = this.ScreenToLocal(crosshair.point).y
        const y2 = this.ScreenToLocal(crosshair.roolerPoint).y

        const index1 = this.GetGlobalIndexByDate(
            crosshair.DateTime,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            true
        )
        const index2 = this.GetGlobalIndexByDate(
            crosshair.roolerDateTime,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            true
        )
        const tm = crosshair.roolerDateTime - crosshair.DateTime

        const price1 = this.GetPriceFromY(y1)
        const price2 = this.GetPriceFromY(y2)

        const w = Math.abs(index2 - index1)
        const h = Math.abs(price1 - price2)

        const hp = price1 === 0 ? 100 : (h / price1) * 100

        const s1 = crosshair.RoundRoolerV
            ? Math.round(h * Math.pow(10, this.ScaleDecimals())).toString()
            : StrsConv.StrDouble(h, this.ScaleDecimals())

        const s2 = StrsConv.StrDouble(price2, this.ScaleDecimals())
        const s3 = StrsConv.StrDouble(hp, 2)

        this.PaintContextCache.GdiCanvas.SetPen(
            1,
            TPenStyle.psSolid,
            ColorHelperFunctions.MakeColor(this.ChartOptions.ColorScheme.FrameAndTextColor, 255)
        )
        this.PaintContextCache.GdiCanvas.MoveTo(x1, y1)
        this.PaintContextCache.GdiCanvas.LineTo(x2, y2)

        context.fillStyle = this.ChartOptions.ColorScheme.BackgroundColor
        context.font = '8pt Roboto Flex'
        context.fillStyle = this.ChartOptions.ColorScheme.FrameAndTextColor

        const shouldUseTime = TimeframeUtils.shouldTimeBeUsed(this.ChartOptions.Timeframe)

        const text2 = crosshair.RoundRoolerV ? `${s1} points, ${s2}, ${s3}%` : `${s2}`
        const text1 = `${w} bars, ${StrsConv.StrDateTime(GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(crosshair.roolerDateTime), shouldUseTime, shouldUseTime)} (${DateUtils.GetTimeDifferenceStr(
            tm,
            shouldUseTime
        )})`
        const paddingX = 24
        const paddingY = 10
        const textHeight1 = this.PaintContextCache.GdiCanvas.TextHeight(text1)
        const textHeight2 = this.PaintContextCache.GdiCanvas.TextHeight(text2)
        const textWidth1 = context.measureText(text1).width
        const textWidth2 = context.measureText(text2).width
        const textWidth = Math.max(textWidth1, textWidth2)
        const offsetBetweenStr = 5
        const paddingToLeftSide = 8
        const paddingToTopSide = 4
        const offset = new TPoint(paddingToLeftSide + paddingToLeftSide, paddingToTopSide + paddingToTopSide)

        const bgColor = ColorHelperFunctions.GetAntiColor(this.ChartOptions.ColorScheme.BackgroundColor)

        const topLeftPoint = new TPoint(x2 - paddingX / 2 + offset.x, y2 - paddingY / 2 + offset.y)

        const bottomRightPoint = new TPoint(
            topLeftPoint.x + textWidth + paddingX,
            topLeftPoint.y + textHeight1 + textHeight2 + offsetBetweenStr + paddingY
        )

        const rectToDraw = new TRect(topLeftPoint.x, topLeftPoint.y, bottomRightPoint.x, bottomRightPoint.y)

        this._paintContextCache?.GdiCanvas.FillRectRounded(rectToDraw, new IGPSolidBrush(bgColor), 4)

        context.fillStyle = ColorHelperFunctions.GetAntiColor(bgColor)
        context.fillText(text1, x2 + offset.x, y2 + textHeight1 + offset.y)
        context.fillText(text2, x2 + offset.x, y2 + textHeight1 + textHeight2 + offsetBetweenStr + offset.y)
    }

    protected PaintRightMarker(value: number, color: TColor, drawArrow: boolean): void {
        const y: number = this.GetY(value)
        const text: string = this.FormatNumberWithSuffix(value)
        const dpr = window.devicePixelRatio

        const canvas = this.PaintContextCache.GdiCanvas
        const context = this.PaintContextCache.canvasContext
        const paintRect = this.PaintContextCache.PaintRect
        const textX = paintRect.Right + 5
        const textY = y + canvas.TextHeight('0', GlobalOptions.Options.VERTICAL_GRID_FONT) / 2

        context.fillStyle = color
        context.fillRect(
            paintRect.Right,
            y - (canvas.TextHeight('0', GlobalOptions.Options.VERTICAL_GRID_FONT) + 6) / 2,
            this.getBoundingClientRect().right * dpr - paintRect.Right,
            canvas.TextHeight('0', GlobalOptions.Options.VERTICAL_GRID_FONT) + 6
        )
        if (drawArrow) {
            context.beginPath()
            context.moveTo(paintRect.Right - 9, y)
            context.lineTo(paintRect.Right, y - 8) // Line to the left and up (top of the arrow)
            context.lineTo(paintRect.Right, y + 8)
            context.closePath()
            context.fill()
        }

        context.beginPath()
        context.strokeStyle = color
        context.lineWidth = 1
        context.moveTo(paintRect.Right, y)
        context.lineTo(paintRect.Right + 4, y)
        context.stroke()

        // Text
        context.font = GlobalOptions.Options.VERTICAL_GRID_FONT
        context.fillStyle = ColorHelperFunctions.GetAntiColor(color)
        context.fillText(text, textX, textY)
    }

    public PaintOrdersRightMarker(value: number, color: string): void {
        const y: number = this.GetY(value)
        const text: string = this.FormatNumberWithSuffix(value)
        const dpr = window.devicePixelRatio

        const canvas = this.PaintContextCache.GdiCanvas
        const context = this.PaintContextCache.canvasContext
        const paintRect = this.PaintContextCache.PaintRect
        const textX = paintRect.Right + 5
        const textY = y + canvas.TextHeight('0') / 2

        context.save()

        context.fillRect(
            paintRect.Right,
            y - (canvas.TextHeight('0') + 6) / 2,
            this.getBoundingClientRect().right * dpr - paintRect.Right,
            canvas.TextHeight('0') + 6
        )

        context.strokeStyle = color
        context.strokeRect(paintRect.Right, y - (canvas.TextHeight('0') + 6) / 2, 100, canvas.TextHeight('0') + 6)

        context.beginPath()
        context.font = '12px Roboto Flex'
        context.fillStyle = color
        context.fillText(text, textX, textY)

        context.restore()
    }

    protected GetGridLines(): TGridLine[] {
        const [visibleDateStart, visibleDateEnd] = this.GetVisibleDateRangeAccordingToBars()

        const gridLinesList = new TGridLineList(
            this.Bars,
            GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(visibleDateStart),
            GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(visibleDateEnd),
            this.position,
            this.barSizeInPixels
        )

        for (let i = 0; i < gridLinesList.Lines.length; i++) {
            const line = gridLinesList.Lines[i]
            line.x += this.PaintContextCache.PaintRect.Left + this.fLeftOffs
        }

        return gridLinesList.Lines
    }

    protected PaintVerticalGrid(): void {
        const context = this.PaintContextCache.canvasContext

        const lines = this.GetGridLines()

        const paintRect = this.PaintContextCache.PaintRect
        context.strokeStyle = this.ChartOptions.ColorScheme.GridColor
        context.lineWidth = 1
        context.setLineDash([1, 1])

        for (const line of lines) {
            if (line.visible) {
                context.beginPath()
                context.moveTo(line.x, paintRect.Top)
                context.lineTo(line.x, paintRect.Bottom)
                context.stroke()
            }
        }
        context.setLineDash([])
    }

    protected PaintHorizontalGrid(): void {
        //this method is empty in the base class like in Delphi
    }

    protected clearMargins(): void {
        const context = this.PaintContextCache.canvasContext
        const paintRect = this.PaintContextCache.PaintRect

        // Clear back sides
        context.fillStyle = this.ChartOptions.ColorScheme.BackgroundColor
        const R = this.ClientRect

        // Fill left margin
        context.fillRect(0, 0, this.fMargins.Left, R.Height)

        // Fill right margin
        context.fillRect(R.Width - this.fMargins.Right, 0, this.fMargins.Right, R.Height)

        // Fill top margin
        context.fillRect(0, 0, R.Width, this.fMargins.Top)

        // Fill bottom margin
        context.fillRect(0, R.Height - this.fMargins.Bottom, R.Width, this.fMargins.Bottom)
    }

    protected PaintBorder(): void {
        this.clearMargins()
        const context = this.PaintContextCache.canvasContext
        const paintRect = this.PaintContextCache.PaintRect

        context.strokeStyle = this.ChartOptions.ColorScheme.FrameAndTextColor

        context.beginPath()
        context.moveTo(paintRect.Right, paintRect.Top)
        context.lineTo(paintRect.Right, paintRect.Bottom)
        context.stroke()
    }

    private isTestingIndexVisible(start_visibleIndex: number, end_VisibleIndex: number): boolean {
        let result = false
        if (
            this.Bars.LastItemInTestingIndex >= start_visibleIndex &&
            this.Bars.LastItemInTestingIndex <= end_VisibleIndex
        ) {
            result = true
        }
        return result
    }

    private GetVisibleIndexRangePlusOne_excludingEmptySpace(): INumberRange {
        const visibleIndexRange_exclEmptySpace = this.GetVisibleIndexRange_excludingEmptySpace()
        //extend the range by 1 because the lines should have start outside of the visible area
        const start_visibleIndex = Math.max(0, visibleIndexRange_exclEmptySpace.start - 1)
        const end_VisibleIndexPlusOne = Math.min(
            this.Bars.LastItemInTestingIndex,
            visibleIndexRange_exclEmptySpace.end + 1
        )
        return { start: start_visibleIndex, end: end_VisibleIndexPlusOne }
    }

    private RecountIndicatorsInRange(startIndex: number, endIndex: number) {
        for (let i = 0; i < this.fIndicators.Count; i++) {
            const indicator = this.fIndicators[i]
            const startIndexWithOffset = Math.max(0, startIndex - indicator.getBackOffsetForCalculation())
            indicator.RecountValuesForTheRange(startIndexWithOffset, endIndex)
        }
    }

    protected RecountIndicatorsForVisibleAndTestingRange(): void {
        const idxes = this.GetVisibleIndexRangePlusOne_excludingEmptySpace()

        if (!this.isTestingIndexVisible(idxes.start, idxes.end)) {
            //in this case let's just count the last item to display the marker
            this.RecountIndicatorsInRange(this.Bars.LastItemInTestingIndex, this.Bars.LastItemInTestingIndex)
        }

        this.RecountIndicatorsInRange(idxes.start, idxes.end)
    }

    protected PaintIndicators(): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Painting, 'Painting indicators')

        this.__debugValidateIndicators()

        this.RecountIndicatorsForVisibleAndTestingRange()

        for (let i = 0; i < this.fIndicators.Count; i++) {
            const indicator = this.fIndicators[i]

            // paint performed by indicators on background
            if (indicator.OnPaintProc) {
                indicator.OnPaintProc(this.PaintContextCache.canvasContext)
            }
        }

        for (let i = 0; i < this.fIndicators.Count; i++) {
            const indicator = this.fIndicators[i]

            if (indicator.IsVisible()) {
                this.PaintIndicatorBuffers(indicator)
            }
        }
    }

    private __debugValidateIndicators() {
        for (const indicator of this.fIndicators) {
            if (!CommonDataUtils.isDataDescriptorEqual(indicator.BarsArray.DataDescriptor, this.Bars.DataDescriptor)) {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                    'DataDescriptor of indicator is not equal to BarsArray'
                )
            }
        }
    }

    protected PaintIndicatorBuffers(indicator: TIndicator): void {
        const { VisibleBuffers } = indicator

        for (let i = 0; i < VisibleBuffers.length; i++) {
            const buff = VisibleBuffers[i]

            // skip invisible buffers
            if (!buff.buffer || !buff.buffer.HasSomeValues() || !buff.IsVisible()) {
                continue
            }

            switch (buff.style.DrawingStyle) {
                case TDrawStyle.ds_Line: {
                    this.PaintLineBuffer(buff)
                    if (buff.selectionMarkers.step && buff.IsSelected()) {
                        this.drawSelectionMarkers(buff)
                    }
                    break
                }
                case TDrawStyle.ds_Histogram: {
                    this.PaintHistogramBuffer(buff)
                    if (buff.selectionMarkers.step && buff.IsSelected()) {
                        this.drawSelectionMarkers(buff)
                    }
                    break
                }
                case TDrawStyle.ds_Fill: {
                    if (i > 0) {
                        this.PaintFillBuffer(VisibleBuffers[i - 1], buff, true)
                    }
                    if (buff.selectionMarkers.step && buff.IsSelected()) {
                        this.drawSelectionMarkers(buff)
                    }
                    break
                }
                case TDrawStyle.ds_HistogramFill: {
                    if (i > 0) {
                        this.PaintFillBuffer(VisibleBuffers[i - 1], buff, false)
                    }
                    if (buff.selectionMarkers.step && buff.IsSelected()) {
                        this.drawSelectionMarkers(buff)
                    }
                    break
                }
                case TDrawStyle.ds_Symbol: {
                    this.PaintSymbolBuffer(buff)
                    if (buff.selectionMarkers.step && buff.IsSelected()) {
                        this.drawSelectionMarkers(buff)
                    }
                    break
                }
                case TDrawStyle.ds_Section: {
                    this.PaintSectionBuffer(buff)
                    if (buff.selectionMarkers.step && buff.IsSelected()) {
                        this.drawSelectionMarkers(buff)
                    }
                    break
                }
                case TDrawStyle.ds_ColoredCandles: {
                    if (i > 2) {
                        this.PaintCandlesBuffer(
                            VisibleBuffers[i - 3],
                            VisibleBuffers[i - 2],
                            VisibleBuffers[i - 1],
                            buff
                        )
                    }
                    if (buff.selectionMarkers.step && buff.IsSelected()) {
                        this.drawSelectionMarkers(buff)
                    }
                    break
                }
                default: {
                    throw new StrangeError(`Drawing style ${buff.style.DrawingStyle} is not implemented.`)
                }
            }
        }
    }

    public drawSelectionMarkers(buff: TVisibleIndexBuffer): void {
        let value2: number
        let x: number
        let y: number

        const visibleRangeStart = Math.max(this.position, buff.PaintFrom)
        const visibleRangeEnd = this.position + this.PaintContextCache.NumberOfVisibleBars

        buff.selectionMarkers.selectionMarkersIndexes = buff.selectionMarkers.selectionMarkersIndexes.filter(
            (index) => index > visibleRangeStart && index < visibleRangeEnd
        )

        if (buff.selectionMarkers.step) {
            if (buff.selectionMarkers.selectionMarkersIndexes.length === 0) {
                for (let i = visibleRangeStart; i < visibleRangeEnd; i += buff.selectionMarkers.step) {
                    if (!buff.selectionMarkers.selectionMarkersIndexes.includes(i)) {
                        buff.selectionMarkers.selectionMarkersIndexes.push(i)
                    }
                }
            } else {
                let temp: number[] = []

                for (
                    let i =
                        buff.selectionMarkers.selectionMarkersIndexes[
                            buff.selectionMarkers.selectionMarkersIndexes.length - 1
                        ] + buff.selectionMarkers.step;
                    i < visibleRangeEnd;
                    i += buff.selectionMarkers.step
                ) {
                    if (!buff.selectionMarkers.selectionMarkersIndexes.includes(i)) {
                        temp.push(i)
                    }
                }
                buff.selectionMarkers.selectionMarkersIndexes =
                    buff.selectionMarkers.selectionMarkersIndexes.concat(temp)

                temp = []

                for (
                    let i = buff.selectionMarkers.selectionMarkersIndexes[0] - buff.selectionMarkers.step;
                    i > visibleRangeStart;
                    i -= buff.selectionMarkers.step
                ) {
                    if (!buff.selectionMarkers.selectionMarkersIndexes.includes(i)) {
                        temp.unshift(i)
                    }
                }
                buff.selectionMarkers.selectionMarkersIndexes = temp.concat(
                    buff.selectionMarkers.selectionMarkersIndexes
                )
            }
        }

        if (buff.selectionMarkers.step) {
            for (let i = 0; i < buff.selectionMarkers.selectionMarkersIndexes.length; i++) {
                const selectionMarkerIndex = buff.selectionMarkers.selectionMarkersIndexes[i]
                if (selectionMarkerIndex > this.Bars.LastItemInTestingIndex + buff.buffer?.shift!) {
                    break
                }
                value2 = buff.GetValue(selectionMarkerIndex)
                if (value2 !== buff.EmptyValue && value2) {
                    x = this.GetX(selectionMarkerIndex)
                    y = this.GetY(value2)

                    const radius = 2.5
                    const color = '#FFFFFF'
                    const strokeColor = '#0000FF'

                    this.PaintContextCache.canvasContext.beginPath()
                    this.PaintContextCache.canvasContext.arc(x, y, radius, 0, 2 * Math.PI)
                    this.PaintContextCache.canvasContext.fillStyle = color
                    this.PaintContextCache.canvasContext.fill()

                    // Add a stroke around the dot
                    this.PaintContextCache.canvasContext.strokeStyle = strokeColor
                    this.PaintContextCache.canvasContext.lineWidth = 1
                    this.PaintContextCache.canvasContext.stroke()
                }
            }
        }
    }

    protected PaintLineBuffer(buff: TVisibleIndexBuffer): void {
        let i: number, x: number, y: number
        let IdxPos: TIndexPos
        let value1: number, value2: number

        this.PaintContextCache.GdiCanvas.ClearPath()
        this.PaintContextCache.GdiCanvas.SetPen(buff.style.width, buff.style.style, buff.style.color)
        this.PaintContextCache.GdiCanvas.pen.applyDashPattern(this.PaintContextCache.canvasContext)
        value1 = buff.EmptyValue
        for (
            i = Math.max(this.position, buff.PaintFrom);
            i < this.position + this.PaintContextCache.NumberOfVisibleBars;
            i++
        ) {
            IdxPos = buff.CheckIndex(i)
            if (IdxPos === TIndexPos.ip_InvAfter) {
                break
            }

            if (IdxPos !== TIndexPos.ip_Valid) {
                continue
            }

            value2 = buff.GetValue(i)

            if (value2 !== buff.EmptyValue) {
                x = this.GetX(i)
                y = this.GetY(value2)

                if (value1 === buff.EmptyValue) {
                    this.PaintContextCache.GdiCanvas.MoveTo(x, y) // Corrected method name to match Delphi's case
                } else {
                    this.PaintContextCache.GdiCanvas.LineToPath(x, y) // Corrected method name to match Delphi's case
                }
            }

            value1 = value2
        }

        this.PaintContextCache.GdiCanvas.DrawPath() // Corrected method name to match Delphi's case

        this.PaintContextCache.GdiCanvas.pen.setDashPattern([])
        this.PaintContextCache.GdiCanvas.pen.applyDashPattern(this.PaintContextCache.GdiCanvas.graphics.Context)
    }

    protected PaintSymbolBuffer(buff: TVisibleIndexBuffer): void {
        let i: number, x: number, y: number
        let IdxPos: TIndexPos
        let value: number

        const context = this.PaintContextCache.canvasContext

        // Set font properties to match Delphi's TCanvas font settings
        context.font = '12px "Wingdings"' // Added quotes around font name for proper CSS font-family syntax
        context.textBaseline = 'middle' // Ensure the text is aligned correctly vertically
        context.textAlign = 'center' // Ensure the text is aligned correctly horizontally

        // Convert the symbol to a string using the char code, assuming buff.style.Symbol is the char code
        const symbolName = String.fromCharCode(buff.style.Symbol)
        const symbolNameSize = context.measureText(symbolName)

        // Calculate width and height offsets based on the text size and style offsets
        const w = symbolNameSize.width / 2 - buff.style.xoffs
        const h = symbolNameSize.actualBoundingBoxAscent / 2 + buff.style.yoffs

        for (
            i = Math.max(this.position, buff.PaintFrom);
            i < this.position + this.PaintContextCache.NumberOfVisibleBars;
            i++
        ) {
            // Check if index is valid
            IdxPos = buff.CheckIndex(i)
            if (IdxPos === TIndexPos.ip_InvAfter) {
                break // Exit the loop if the index position is invalid after the current range
            }

            // Skip not valid index
            if (IdxPos !== TIndexPos.ip_Valid) {
                continue // Continue to the next iteration if the index position is not valid
            }

            // Get buffer value
            value = buff.GetValue(i)
            if (value === buff.EmptyValue) {
                continue // Continue to the next iteration if the value is the empty value
            }

            // Calculate the x and y positions for the symbol
            x = this.GetX(i)
            y = this.GetY(value)

            // Draw the text at the calculated position with the correct offsets
            context.fillText(symbolName, x - w, y - h)
        }
    }

    protected PaintHistogramBuffer(buff: TVisibleIndexBuffer): void {
        this.PaintContextCache.GdiCanvas.SetBrushColor(buff.style.color)

        for (
            let i = Math.max(this.position, buff.PaintFrom);
            i < this.position + this.PaintContextCache.NumberOfVisibleBars;
            i++
        ) {
            const idxPos = buff.CheckIndex(i)
            if (idxPos === TIndexPos.ip_InvAfter) break

            if (idxPos !== TIndexPos.ip_Valid) continue

            const value = buff.GetValue(i)
            if (value !== buff.EmptyValue) {
                const x = this.GetX(i)
                const y = this.GetY(value)
                const R = TRect.SetRect(x - this.fBarWidth2, y, x + this.fBarWidth2 + 1, this.GetY(0))
                if (R.IsValidForDrawing()) {
                    this.PaintContextCache.GdiCanvas.FillRect(R)
                }
            }
        }
    }

    protected PaintSectionBuffer(buff: TVisibleIndexBuffer): void {
        if (!this.PaintContextCache.GdiCanvas) {
            throw new StrangeError('Canvas object is not initialized')
        }

        let i: number, j: number, x1: number, x2: number, y1: number, y2: number
        let value1: number, value2: number
        const canvas = this.PaintContextCache.GdiCanvas

        canvas.SetPenFromLineStyleRec(buff.style)
        value1 = buff.EmptyValue
        x1 = 0

        for (
            i = Math.max(this.position, buff.PaintFrom + 1);
            i < this.position + this.PaintContextCache.NumberOfVisibleBars;
            i++
        ) {
            const IdxPos = buff.CheckIndex(i)
            if (IdxPos === TIndexPos.ip_InvAfter) {
                return // Use 'return' to exit the function, similar to 'exit' in Delphi
            }

            if (IdxPos !== TIndexPos.ip_Valid) {
                continue
            }

            value2 = buff.GetValue(i)
            if (value2 === buff.EmptyValue) {
                continue
            }

            x2 = this.GetX(i)

            if (value1 === buff.EmptyValue) {
                j = i - 1
                while (buff.CheckIndex(j) === TIndexPos.ip_Valid && buff.GetValue(j) === buff.EmptyValue) {
                    j--
                }

                if (buff.CheckIndex(j) === TIndexPos.ip_Valid) {
                    value1 = buff.GetValue(j)
                    x1 = this.GetX(j)
                }
            }

            if (value1 !== buff.EmptyValue) {
                y1 = this.GetY(value1)
                y2 = this.GetY(value2)
                canvas.MoveTo(x1, y1)
                canvas.LineTo(x2, y2)
            }

            value1 = value2
            x1 = x2
        }

        if (value1 !== buff.EmptyValue) {
            j = this.position + this.PaintContextCache.NumberOfVisibleBars
            while (buff.CheckIndex(j) === TIndexPos.ip_Valid && buff.GetValue(j) === buff.EmptyValue) {
                j++
            }

            if (buff.CheckIndex(j) === TIndexPos.ip_Valid) {
                value2 = buff.GetValue(j)
                x2 = this.GetX(j)
                y1 = this.GetY(value1)
                y2 = this.GetY(value2)
                canvas.MoveTo(x1, y1)
                canvas.LineTo(x2, y2)
            }
        }
    }

    protected PaintFillPoly(points: TPointsList, color: TColor): void {
        if (points.length === 0) return

        const canvas = this.PaintContextCache.GdiCanvas
        canvas.ClearPath()

        canvas.MoveTo(points[0].x, points[0].y1)
        for (let i = 1; i < points.length; i++) {
            canvas.LineToPath(points[i].x, points[i].y1)
        }

        canvas.LineToPath(points.LastItem.x, points.LastItem.y2)

        for (let i = points.length - 2; i >= 0; i--) {
            canvas.LineToPath(points[i].x, points[i].y2)
        }

        const opacity = ColorHelperFunctions.GetOpacity(color)
        canvas.SetBrushColor(color, opacity)
        canvas.FillPath()
    }

    protected PaintFillBuffer(buff1: TVisibleIndexBuffer, buff2: TVisibleIndexBuffer, PaintLines: boolean): void {
        let i: number,
            x: number,
            y1: number,
            y2: number,
            prev_x = 0,
            prev_y1 = 0,
            prev_y2 = 0
        let IdxPos1: TIndexPos, IdxPos2: TIndexPos
        let value1: number, value2: number
        let flag = false,
            flag_n: boolean,
            PrevPointExists = false
        const points: TPointsList = new TPointsList()

        const PaintPointsAndClear = (): void => {
            if (points.length === 0) return

            const color: TColor = getHigherLineColor()
            this.PaintFillPoly(points, color)
            points.Clear()
        }

        const getHigherLineColor = (): TColor => {
            let higherBuff1 = 0,
                higherBuff2 = 0

            for (const point of points) {
                if (point.y1 > point.y2) {
                    higherBuff1++
                } else {
                    higherBuff2++
                }
            }

            return higherBuff1 > higherBuff2 ? buff2.style.color : buff1.style.color
        }

        const AddPoints = (x: number, y1: number, y2: number): void => {
            if (x >= 0 && y1 >= 0 && y2 >= 0) {
                points.Add(new TPointPair(x, y1, y2))
            }
        }

        const ProcessPoint = (x: number, y1: number, y2: number): void => {
            flag_n = DateUtils.MoreOrEqual(y2, y1)

            if (points.length === 0) {
                flag = flag_n
                if (PrevPointExists) AddPoints(prev_x, prev_y1, prev_y2)
            }

            if (flag === flag_n) {
                AddPoints(x, y1, y2)
                return
            }

            if (PrevPointExists && points.length > 0) {
                const { x: xs, y: ys } = this.GetIntersection(prev_x, x, prev_y1, y1, prev_y2, y2) // Assuming GetIntersection returns a tuple [xs, ys]
                AddPoints(xs, ys, ys)
                PaintPointsAndClear()
                AddPoints(xs, ys, ys)
            }

            flag = flag_n
            AddPoints(x, y1, y2)
        }

        const maxIndex = this.position + this.PaintContextCache.NumberOfVisibleBars - 1
        for (i = Math.max(this.position, buff1.PaintFrom); i <= maxIndex; i++) {
            IdxPos1 = buff1.CheckIndex(i)
            IdxPos2 = buff2.CheckIndex(i)
            if (IdxPos1 === TIndexPos.ip_InvAfter || IdxPos2 === TIndexPos.ip_InvAfter) break

            if (IdxPos1 !== TIndexPos.ip_Valid || IdxPos2 !== TIndexPos.ip_Valid) continue

            value1 = buff1.GetValue(i)
            value2 = buff2.GetValue(i)
            if (value1 === buff1.EmptyValue || value2 === buff2.EmptyValue || value1 === null || value2 === null) {
                continue
            }

            x = this.GetX(i)
            y1 = this.GetY(value1)
            y2 = this.GetY(value2)

            if (DateUtils.AreEqual(y1, y2)) {
                if (points.length > 0) {
                    AddPoints(x, y1, y2)
                    PaintPointsAndClear()
                }
                continue
            }

            ProcessPoint(x, y1, y2)

            prev_x = x
            prev_y1 = y1
            prev_y2 = y2
            PrevPointExists = true
        }

        PaintPointsAndClear()

        if (PaintLines) {
            this.PaintLineBuffer(buff1)
            this.PaintLineBuffer(buff2)
        }
    }

    protected PaintCandlesBuffer(
        buff1: TVisibleIndexBuffer,
        buff2: TVisibleIndexBuffer,
        buff3: TVisibleIndexBuffer,
        buff4: TVisibleIndexBuffer
    ): void {
        let i: number, x: number
        let IdxPos: TIndexPos
        let o: number, h: number, l: number, c: number
        let R: TRect
        let c1: TColor, c2: TColor

        const canvas = this.PaintContextCache.GdiCanvas

        for (
            i = Math.max(this.position, buff1.PaintFrom);
            i < this.position + this.PaintContextCache.NumberOfVisibleBars;
            i++
        ) {
            IdxPos = buff4.CheckIndex(i)
            if (IdxPos === TIndexPos.ip_InvAfter) {
                return
            }

            if (IdxPos !== TIndexPos.ip_Valid) {
                continue
            }

            o = this.GetY(buff1.GetValue(i))
            h = this.GetY(buff2.GetValue(i))
            l = this.GetY(buff3.GetValue(i))
            c = this.GetY(buff4.GetValue(i))
            x = this.GetX(i)

            if (o <= c) {
                R = new TRect(x - this.fBarWidth2, o, x + this.fBarWidth2 + 1, c)
                c1 = ColorHelperFunctions.BasicColor(buff3.style.color)
                c2 = ColorHelperFunctions.BasicColor(buff4.style.color)
            } else {
                R = new TRect(x - this.fBarWidth2, c, x + this.fBarWidth2 + 1, o)
                c1 = ColorHelperFunctions.BasicColor(buff1.style.color)
                c2 = ColorHelperFunctions.BasicColor(buff2.style.color)
            }

            // Set pen properties for drawing lines
            canvas.pen.color = ColorHelperFunctions.BasicColor(c1)
            canvas.pen.setPenStyle_TPenStyle(TPenStyle.psSolid)
            canvas.pen.width = 1

            // Draw the high to low line of the candle
            canvas.MoveTo(x, h)
            canvas.LineTo(x, l)

            // Draw the open to close rectangle of the candle
            if (R.Top === R.Bottom) {
                // Draw a line if the rectangle has no height
                canvas.MoveTo(R.Left, R.Top)
                canvas.LineTo(R.Right, R.Top)
            } else {
                // Frame the rectangle
                canvas.brush.Style = TBrushStyle.bsSolid
                canvas.brush.Color = ColorHelperFunctions.BasicColor(c1)
                canvas.FrameRect(R)

                // Fill the rectangle if it's large enough after inflating
                if (R.Bottom - R.Top > 2 && R.Right - R.Left > 1) {
                    canvas.SetBrushColor(c2)
                    R.Inflate(-1, -1)
                    canvas.FillRect(R)
                }
            }
        }
    }

    private GetIntersection(
        x1: number,
        x2: number,
        y1: number,
        y2: number,
        y3: number,
        y4: number
    ): { x: number; y: number } {
        const denom: number = y1 - y2 - y3 + y4
        const x: number = (x2 * (y1 - y3) - x1 * (y2 - y4)) / denom
        const y: number = (y1 * y4 - y2 * y3) / denom

        return { x, y }
    }

    public GetScrollParams(): TScrollInfo {
        const ScrollInfo = new TScrollInfo()

        const paintRect = this.IsPaintContextCacheInitialized ? this.PaintContextCache.PaintRect : this.GetPaintRect()

        this.CalcDimensions()

        let r_offsPx: number
        if (this.ChartOptions.RightOffset) {
            r_offsPx = Math.round(paintRect.Width * this.ChartOptions.OffsetPercentage)
        } else {
            r_offsPx = 0
        }

        ScrollInfo.TotalWidthPix = this.Bars.LastItemInTestingIndex * this.barSizeInPixels + r_offsPx
        ScrollInfo.PageWidthPix = paintRect.Width

        if (ScrollInfo.PageWidthPix < ScrollInfo.TotalWidthPix) {
            ScrollInfo.MaxLeftBar = Math.ceil((ScrollInfo.TotalWidthPix - paintRect.Width) / this.barSizeInPixels)
            ScrollInfo.x_offset = -(
                this.barSizeInPixels - DelphiMathCompatibility.Mod(ScrollInfo.TotalWidthPix, this.barSizeInPixels)
            )
            ScrollInfo.LeftOffset = paintRect.Left + this.fBarWidth2 + 2
            ScrollInfo.PixBetweenBars = this.barSizeInPixels
            return ScrollInfo
        } else {
            ScrollInfo.MaxLeftBar = 0
            ScrollInfo.x_offset = 0
            return ScrollInfo
        }
    }

    protected PaintLevels(indicator: TIndicator): void {
        const paintRect = this.PaintContextCache.PaintRect

        indicator.levels.forEach((level: TLevelData) => {
            if (level.isActive === 1) {
                this.PaintContextCache.GdiCanvas.SetPen(level.style.width, level.style.style, level.style.color)
                const y: number = this.GetY(level.value)
                this.PaintContextCache.GdiCanvas.MoveTo(paintRect.Left, y)
                this.PaintContextCache.GdiCanvas.LineTo(paintRect.Right, y)
            }
        })
    }

    protected PaintIndicatorMarks(): void {
        if (!this.ChartOptions.ShowIndicatorValues) {
            return
        }

        for (let i = 0; i < this.fIndicators.Count; i++) {
            if (!this.fIndicators[i].IsVisible()) continue

            for (let j = 0; j < this.fIndicators[i].VisibleBuffers.length; j++) {
                const buff: TVisibleIndexBuffer = this.fIndicators[i].VisibleBuffers[j]

                if (buff && buff.buffer && buff.IsVisible()) {
                    if (!buff.buffer.HasSomeValues()) {
                        continue
                    }

                    const value: number = buff.buffer.GetValue(buff.buffer.LastItemInTestingIndex)
                    if (value && value !== buff.EmptyValue) {
                        this.PaintRightMarker(value, ColorHelperFunctions.BasicColor(buff.style.color), false)
                    }
                }
            }
        }
    }

    protected PaintHLinesMarkers(): void {
        for (let i = 0; i < this.PaintTools.Count; i++) {
            const tool = this.PaintTools[i]

            if (!tool.IsVisible()) continue

            if (tool.ToolType === TPaintToolType.tt_HLine && tool.status !== TPaintToolStatus.ts_Completed) {
                this.PaintRightMarker(
                    tool.points[0].price,
                    ColorHelperFunctions.BasicColor(tool.LineStyle.color),
                    false
                )
            } else if (tool.ToolType === TPaintToolType.tt_HLine && tool.visible) {
                this.PaintRightMarker(tool.points[0].price, ColorHelperFunctions.BasicColor(tool.LineStyle.color), true)
            }
        }
    }

    public GetMouseIndex(): number {
        const mousePos = this.MouseToLocal()
        return this.GetIndexFromX(mousePos.x)
    }

    public MouseInPriceRange(p: TPoint, value: number): boolean {
        const y: number = this.GetY(value)
        // This checks if the absolute difference between the point's y-coordinate and the calculated y is within the mouse sensitivity range
        return Math.abs(p.y - y) <= GlobalOptions.Options.MouseSensitivity
    }

    public GetIndexFromX(x: number, FitToRange = true): number {
        // Converts an x-coordinate on the chart to the corresponding bar index, optionally fitting the result within the range of visible bars.
        let paintRect
        if (this.IsPaintContextCacheInitialized) {
            paintRect = this.PaintContextCache.PaintRect
        } else {
            paintRect = this.GetPaintRect()
        }

        const barsAreaLeft = paintRect.Left + this.left_X_offset //left_X_offset is negative hence +
        const delta = x - barsAreaLeft

        let result: number = this.position + Math.floor(delta / this.barSizeInPixels)
        if (FitToRange) {
            result = Math.max(this.position, Math.min(result, this.GetLastVisibleBarIndex()))
        }
        return result
    }

    public GetDateByIndex(index: number, approximationAllowed = true): TDateTime {
        return this.Bars.GetDateByGlobalIndex(index, approximationAllowed, true)
    }

    public GetBarDateFromX(x: number, fitToRange = true, approximationAllowed = true): TDateTime {
        const index: number = this.GetIndexFromX(x, fitToRange)
        return this.GetDateByIndex(index, approximationAllowed)
    }

    public getPixelSizeInDateTimeUnits(): TDateDiff {
        const barSizeInDateTimeUnits = this.getBarSizeInDateTime().diffInTDateTimeUnits
        return new TDateDiff(barSizeInDateTimeUnits / this.barSizeInPixels)
    }

    public GetPreciseDateFromX(x: number, approximationAllowed = true): TDateTime {
        const barDate = this.GetBarDateFromX(x, true, approximationAllowed)
        const pixelSizeInDateTimeUnits = this.getPixelSizeInDateTimeUnits().diffInTDateTimeUnits

        return barDate + ((x - this.left_X_offset) % this.barSizeInPixels) * pixelSizeInDateTimeUnits
    }

    //TODO: see if it is possible to move the cache into TFMBarsArray and get rid of this method
    public GetGlobalIndexByDate(
        dateTime: TDateTime,
        noExactMatchBehavior: TNoExactMatchBehavior,
        approximationAllowed: boolean
    ): number {
        //TODO: this can be optimized a lot using cache (see fDateCache)
        //check Delphi code and old code here
        return this.Bars.GetGlobalIndexByDate(dateTime, noExactMatchBehavior, approximationAllowed)
    }

    public GetX(globalIndex: number): number {
        const paintRect = this.IsPaintContextCacheInitialized ? this.PaintContextCache.PaintRect : this.GetPaintRect()
        // Converts a bar index to the corresponding x-coordinate on the chart
        return paintRect.Left + this.fLeftOffs + Math.round((globalIndex - this.position) * this.barSizeInPixels)
    }

    public GetXFromDate(dateTime: TDateTime): number {
        const index = this.GetGlobalIndexByDate(dateTime, TNoExactMatchBehavior.nemb_ReturnNearestLower, true)
        return this.GetX(index)
    }

    public ScaleDecimals(): number {
        return 6
    }

    public GetRayCoords(
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        price1: number,
        price2: number
    ): [number, number, number, number] {
        if (!this.IsRayVisible(x1, y1, x2, y2)) {
            return [x1, y1, x2, y2]
        }

        const R: TRect = this.GetPaintRect()

        if (x1 === x2) {
            if (y2 > y1) {
                y2 = R.Bottom
            } else if (y2 <= y1) {
                y2 = R.Top
            }
        } else {
            const angle: number = (price2 - price1) / (x2 - x1)

            if (x2 > x1) {
                x2 = R.Right
            } else {
                x2 = R.Left
            }

            y2 = this.GetY(price1 + angle * (x2 - x1))
        }

        return [x1, y1, x2, y2]
    }

    public IsRayVisible(x1: number, y1: number, x2: number, y2: number): boolean {
        const R: TRect = this.GetPaintRect()

        // remove invisible rays by direction
        if ((x1 < R.Left && x2 <= x1) || (x1 > R.Right && x2 >= x1)) {
            return false
        }

        // remove invisible rays by angle
        if ((y2 <= y1 && y1 <= R.Top) || (y2 >= y1 && y1 >= R.Bottom)) {
            return false
        }

        return true
    }

    IsLineVisible(x1: number, y1: number, x2: number, y2: number): boolean {
        const R: TRect = this.GetPaintRect()
        return !(
            Math.max(x1, x2) < R.Left ||
            Math.min(x1, x2) > R.Right ||
            Math.max(y1, y2) < R.Top ||
            Math.min(y1, y2) > R.Bottom
        )
    }

    ChangeToolName(tool: any, newName: string): void {
        throw new NotImplementedError('changeToolName method not implemented.')
    }

    public ClearData(): void {
        // this.fIndicators.Clear()
        this.fDateCache.Clear()
    }

    protected PaintLevel(value: number, style: TPenStyle, color: TColor): void {
        const y: number = this.GetY(value)
        const paintRect = this.PaintContextCache.PaintRect

        this.PaintContextCache.GdiCanvas.SetPen(1, style, color)
        this.PaintContextCache.GdiCanvas.MoveTo(paintRect.Left, y)
        this.PaintContextCache.GdiCanvas.LineTo(paintRect.Right, y)
    }

    public ClearDateCache(): void {
        this.fDateCache.Clear()
    }

    protected InitPaintContextCache(): void {
        const canvasContext = this.CanvasContext
        if (!canvasContext) {
            throw new StrangeError('No 2D context available for the canvas.')
        }

        const gdiCanvas = new TGdiPlusCanvas(this.HTML_Canvas)
        const paintRect = this.GetPaintRect()

        const numberOfVisibleBars = this.NumberOfVisibleBars()
        const [firstVisibleDate, lastVisibleDate] = this.GetVisibleDateRangeAccordingToBars()
        const visibleIndexRange = this.GetVisibleIndexRange_excludingEmptySpace()
        const firstVisibleIndex = visibleIndexRange.start
        const lastVisibleIndex = visibleIndexRange.end

        this._paintContextCache = new TPaintContext(
            canvasContext,
            numberOfVisibleBars,
            firstVisibleDate,
            lastVisibleDate,
            paintRect,
            gdiCanvas,
            firstVisibleIndex,
            lastVisibleIndex
        )

        this._isPaintContextCacheInitialized = true
        DebugUtils.logTopic(ELoggingTopics.lt_Painting, 'Paint context cache initialized.')
    }

    //TODO: see if there are situations when the cache is outdated and needs to be reinitialized
    protected FreePaintContextCache(): void {
        // this._isPaintContextCacheInitialized = false
    }

    public FormatNumberWithSuffix(value: number): string {
        const suffixes = ['', 'K', 'M', 'B', 'T', 'Q', 'P', 'E', 'Z', 'Y']
        const absValue = Math.abs(value)

        if (absValue <= 99999) {
            const scaleDecimals = this.ScaleDecimals()
            return value.toFixed(scaleDecimals)
        }

        const idx = Math.floor(Math.log10(absValue) / 3)
        const i = Math.min(idx, suffixes.length - 1)
        const scaledValue = (absValue / Math.pow(1000, i)).toFixed(1)
        return `${value < 0 ? '-' : ''}${scaledValue}${suffixes[i]}`
    }
}
