import { DateUtils, TDateTime } from '../delphi_compatibility/DateUtils'
import CommonConstants from '../ft_types/common/CommonConstants'
import { TimeframeUtils } from '../ft_types/common/TimeframeUtils'
import { TTickRec } from '../ft_types/data/TickRec'
import DataNotDownloadedYetError from '../ft_types/data/data_errors/DataUnavailableError'
import { AfterEndOfHistoryError } from '../ft_types/data/data_errors/OutOfHistoryBoundsError'
import GlobalChartsController from '../globals/GlobalChartsController'
import GlobalOptions from '../globals/GlobalOptions'
import GlobalProcessingCore from '../globals/GlobalProcessingCore'
import GlobalProjectInfo from '../globals/GlobalProjectInfo'
import GlobalSymbolList from '../globals/GlobalSymbolList'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { EducationProcessor } from '@fto/lib/Education/EducationProcessor'
import { GlobalNewsController } from '@fto/lib/News/GlobalNewsController'
import ChartSettingsStore from '@fto/lib/store/chartSettings'
import { EGoingForwardStyle } from './ProcessingCoreEnums'
import { Direction } from '@fto/lib/ft_types/data/TickData'
import StrangeError from '../common/common_errors/StrangeError'
import { DebugUtils } from '../utils/DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import { showWarningToast } from '@root/utils/toasts'
import { t } from 'i18next'
import { throttle } from 'lodash'
import { TDataArrayEvents } from '../ft_types/data/data_downloading/DownloadRelatedEnums'

enum TEducationCourseMessages {
    msg_TestStoppedTrialVer,
    msg_PendingOrderExecuted
}

const TIME_MULTIPLIERS: { [key: number]: number } = {
    1: 1, //1x (similar to real-time)
    2: 2, //~2x
    3: 5, //~5x
    4: 10, //~10x
    5: 30, //~30x
    6: 60, //~60x ~one minute of testing data per real second
    7: 60 * 2, //~120x
    8: 60 * 5, //~300x (M5 bar per sec)
    9: 60 * 15, //~900x (M15 bar per sec)
    10: 60 * 30, //~1800x (M30 bar per sec)
    11: 60 * 60, //~3600x (H1 bar per sec)
    12: 60 * 60 * 2, //~3600x
    13: 60 * 60 * 4, //~14400x (H4 bar per sec)
    14: 60 * 60 * 12, //~43200x
    15: 60 * 60 * 24, //~86400x (D1 bar per sec)
    16: 60 * 60 * 24 * 30 //max speed (MN bar per sec)
}

const MAX_GAP_IN_DATA_MILLISECONDS = 1 * 60 * 1000 //1 minute
const MAX_SPEED_REDUCTION = 2
const TICK_PACKAGE_PROCESSING_TIMEOUT = 250 //250 ms
const TICK_PROCESSING_TIMER_DELAY_MS = 33 //33 ms is ~30 fps

enum EStopByDateCriteria {
    sbdc_JustBefore,
    sbdc_ExactlyAtOrJustAfter
}

interface TickProcessingSettings {
    goingForwardStyle: EGoingForwardStyle
    targetDate?: TDateTime
    stopByDateCriteria?: EStopByDateCriteria
    skipThisTimer?: boolean
    // overrideSpeed?: boolean
}

export class TTestingManager {
    private testingInterval: ReturnType<typeof setInterval> | undefined = undefined
    private fWaitForPendOrderFlag = false
    private lastRealTimeWhenLastTickWasProcessed: TDateTime = DateUtils.EmptyDate
    private lastTickTimeAtSync: TDateTime = DateUtils.EmptyDate
    private currentTimeMultiplier = 1
    private speedReduction = 0
    private testingSpeed = 0
    private isTestingNow = false
    public __debug_ActualTestingSpeed = 0
    private __debug_lastRealTime: TDateTime = DateUtils.EmptyDate
    private __debug_lastTestingTime: TDateTime = DateUtils.EmptyDate

    constructor() {
        GlobalSymbolList.SymbolList.Events.on(
            TDataArrayEvents.de_OutOfHistoryBoundsError,
            this.boundHandleOutOfHistoryBoundsError
        )
    }

    private get ActiveChart(): TChartWindow | null {
        return GlobalChartsController.Instance.getActiveChart()
    }

    //Former TicksTimerTimer
    private ProcessTicksPackage(tickProcessingSettings_param: TickProcessingSettings): void {
        DebugUtils.logTopic(ELoggingTopics.lt_TestingControl, 'ProcessTicksPackage')
        let processedTicks = 0
        const processOnlyOneTick = tickProcessingSettings_param.goingForwardStyle === EGoingForwardStyle.gf_OneTick

        if (!GlobalSymbolList.SymbolList.isReadyToTick()) {
            GlobalChartsController.Instance.enableLoader()
            return
        }

        let tickProcessingSettings = tickProcessingSettings_param

        if (tickProcessingSettings.goingForwardStyle === EGoingForwardStyle.gf_ByTimer) {
            this.CorrectSpeedBasedOnDataAvailability()

            tickProcessingSettings = this.getAdjustedTickProcessingSettings(tickProcessingSettings)
            if (tickProcessingSettings.skipThisTimer) {
                return
            }
        }

        this.__validateTargetDate(tickProcessingSettings)

        try {
            GlobalSymbolList.SymbolList.UpdateChunksInfo(GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true))

            const timeoutEndDate_JSMilliseconds = Date.now() + TICK_PACKAGE_PROCESSING_TIMEOUT

            if (this.canSeekInsteadOfProcessingAllTicks(tickProcessingSettings)) {
                this.startSeekProcess(tickProcessingSettings)
            } else {
                do {
                    const tickToProcess = GlobalSymbolList.SymbolList.GetQueuedTick() //it will be marked as processed later

                    if (!tickToProcess || !tickToProcess.tick || tickToProcess.SymbolName === '') {
                        throw new StrangeError('empty tickToProcess, breaking the cycle')
                    }

                    this.accountForDataGaps(tickToProcess.tick.DateTime)

                    this.ProcessTick(tickToProcess)
                    processedTicks++

                    if (GlobalProcessingCore.ProcessingCore.PendingFlag && this.fWaitForPendOrderFlag) {
                        if (!GlobalOptions.Options.Paused) {
                            this.stopTesting()
                        }

                        this.fWaitForPendOrderFlag = false
                        this.PostEduCourseMsg(TEducationCourseMessages.msg_PendingOrderExecuted)
                        break
                    }

                    const queuedTick = GlobalSymbolList.SymbolList.GetQueuedTick()
                    if (!queuedTick || !queuedTick.tick) {
                        throw new StrangeError('empty queuedTick, but no EndOfHistoryError')
                    }

                    if (
                        this.IsItNecessaryToStopProcessing(
                            queuedTick,
                            tickProcessingSettings,
                            timeoutEndDate_JSMilliseconds
                        )
                    ) {
                        DebugUtils.logTopic(ELoggingTopics.lt_TestingSpeed, 'IsItNecessaryToStopProcessing = true')
                        if (this.IsItNecessaryToStopTesting(queuedTick, tickProcessingSettings)) {
                            this.stopTesting()
                        } else {
                            DebugUtils.logTopic(
                                ELoggingTopics.lt_TestingSpeed,
                                'IsItNecessaryToStopProcessing = true, but not stopping the testing'
                            )
                            //if we have not reached the desired date yet, we will continue the testing
                            this.launchTimerOrSeekIfDesiredDateIsNotReached(tickProcessingSettings, queuedTick)
                        }
                        break
                    }

                    this.__debugCheckDataConsistency()
                    // eslint-disable-next-line sonarjs/no-redundant-boolean
                } while (true && !processOnlyOneTick)

                DebugUtils.logTopic(ELoggingTopics.lt_TestingSpeed, `Processed ticks: ${processedTicks}`)
                if (processedTicks > 0) {
                    this.UpdateEverything(true)
                    GlobalChartsController.Instance.updateMarketValues()
                    this.__debugCheckLastTickTime()
                }
            }

            GlobalChartsController.Instance.disableLoader()
        } catch (error) {
            DebugUtils.logTopic(ELoggingTopics.lt_TestingSpeed, 'Error in ProcessTicksPackage:', error)
            // eslint-disable-next-line unicorn/throw-new-error
            this.HandleTickProcessingError(error)
            const tickToProcess = GlobalSymbolList.SymbolList.GetQueuedTick()
            this.launchTimerOrSeekIfDesiredDateIsNotReached(tickProcessingSettings, tickToProcess)
            //FIXME: do update everything here?
        } finally {
            GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
            EducationProcessor.Instance.processChecks()
        }
    }

    private startSeekProcess(tickProcessingSettings: TickProcessingSettings) {
        if (tickProcessingSettings.goingForwardStyle === EGoingForwardStyle.gf_ToDate_NoChartUpdate) {
            //we will immediately reach the destination after the seek is completed, so no need to continue the testing
            this.stopTesting()
        }
        GlobalSymbolList.SymbolList.Events.on(TDataArrayEvents.de_SeekCompleted, this.boundHandleSeekCompleted)

        const dateToSeekTo = this.getDateToSeekTo(tickProcessingSettings)
        GlobalSymbolList.SymbolList.Seek(dateToSeekTo)
    }

    private getDateToSeekTo(tickProcessingSettings: TickProcessingSettings) {
        if (!tickProcessingSettings.targetDate) {
            throw new StrangeError('targetDate is not defined')
        }
        if (tickProcessingSettings.stopByDateCriteria === EStopByDateCriteria.sbdc_JustBefore) {
            return tickProcessingSettings.targetDate - CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_DATETIME
        }
        return tickProcessingSettings.targetDate
    }

    private canSeekInsteadOfProcessingAllTicks(tickProcessingSettings: TickProcessingSettings) {
        if (!tickProcessingSettings.targetDate) {
            return false
        }

        const currentTime = GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false)
        const dateDiff = DateUtils.GetDateDiff(tickProcessingSettings.targetDate, currentTime)
        if (dateDiff.getDifferenceInHours() < 4) {
            //let's just process ticks for this short distance, the Seek process may take too long
            return false
        }

        return (
            (tickProcessingSettings.goingForwardStyle === EGoingForwardStyle.gf_ToDate_NoChartUpdate ||
                tickProcessingSettings.goingForwardStyle === EGoingForwardStyle.gf_ByTimer) &&
            !GlobalProcessingCore.ProcessingCore.HasOpenOrders
        )
    }

    private __validateTargetDate(tickProcessingSettings: TickProcessingSettings) {
        if (
            (tickProcessingSettings.goingForwardStyle === EGoingForwardStyle.gf_ToDate_NoChartUpdate ||
                tickProcessingSettings.goingForwardStyle === EGoingForwardStyle.gf_ByTimer) &&
            tickProcessingSettings.targetDate &&
            tickProcessingSettings.targetDate < GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false)
        ) {
            throw new StrangeError('targetDate is before current date in testing')
        }
    }

    private launchTimerOrSeekIfDesiredDateIsNotReached(
        tickProcessingSettings: TickProcessingSettings,
        queuedTick: TTickRec | undefined
    ) {
        if (this.canSeekInsteadOfProcessingAllTicks(tickProcessingSettings)) {
            this.startSeekProcess(tickProcessingSettings)
        } else if (
            tickProcessingSettings.goingForwardStyle === EGoingForwardStyle.gf_ToDate_NoChartUpdate &&
            (!queuedTick || !this.wasTheDesiredDateReached(queuedTick, tickProcessingSettings))
        ) {
            DebugUtils.logTopic(
                ELoggingTopics.lt_TestingSpeed,
                'The desired date was not reached, continuing the testing'
            )
            this.launchTesting(tickProcessingSettings)
        }
    }

    private accountForDataGaps(nextTickToProcessDate: TDateTime) {
        const lastProcessedTickDate = GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false)

        if (nextTickToProcessDate < lastProcessedTickDate) {
            if (DebugUtils.DebugMode) {
                //very weird situation, we need to stop here and see
                // eslint-disable-next-line no-debugger
                debugger
                GlobalSymbolList.SymbolList.GetQueuedTick()
            }
            throw new StrangeError('The next tick to process is older than the last processed tick')
        }

        const diffBetweenProcessedAndQueuedTick_ms = DateUtils.DifferenceInMilliseconds(
            nextTickToProcessDate,
            lastProcessedTickDate
        )
        if (this.lastTickTimeAtSync > 0 && diffBetweenProcessedAndQueuedTick_ms > MAX_GAP_IN_DATA_MILLISECONDS) {
            DebugUtils.logTopic(
                ELoggingTopics.lt_TestingSpeed,
                `The gap between processed and queued tick is too big: ${DateUtils.GetSecondsFromMilliseconds(diffBetweenProcessedAndQueuedTick_ms)} sec, adjusting the remembered time`
            )
            this.lastTickTimeAtSync = DateUtils.IncMilliSecond(
                this.lastTickTimeAtSync,
                diffBetweenProcessedAndQueuedTick_ms
            )
        }
    }

    private IsItNecessaryToStopTesting(queuedTick: TTickRec, tickProcessingSettings: TickProcessingSettings): boolean {
        if (
            tickProcessingSettings.goingForwardStyle === EGoingForwardStyle.gf_ToDate_NoChartUpdate &&
            this.wasTheDesiredDateReached(queuedTick, tickProcessingSettings)
        ) {
            return true
        }
        return false
    }

    private getAdjustedTickProcessingSettings(
        tickProcessingSettings_param: TickProcessingSettings
    ): TickProcessingSettings {
        const updatedTickProcessingSettings = tickProcessingSettings_param

        const realTimePassed_ms = DateUtils.DifferenceInMilliseconds(
            DateUtils.Now(),
            this.lastRealTimeWhenLastTickWasProcessed
        )
        const testingTimePassed_ms = DateUtils.DifferenceInMilliseconds(
            GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false),
            this.lastTickTimeAtSync
        )

        this.__debugUpdateActualTestingSpeed()

        const expectedTestingTimePassed_ms = realTimePassed_ms * this.currentTimeMultiplier
        DebugUtils.logTopic(
            ELoggingTopics.lt_TestingSpeed,
            `Real time passed: ${DateUtils.GetSecondsFromMilliseconds(realTimePassed_ms)} sec, testing time passed: ${DateUtils.GetSecondsFromMilliseconds(testingTimePassed_ms)} sec, expected testing time passed: ${DateUtils.GetSecondsFromMilliseconds(expectedTestingTimePassed_ms)} sec`
        )
        const weAreBehindInTestingTimeFor_ms = expectedTestingTimePassed_ms - testingTimePassed_ms
        // const additionalRealTimeNeededToCatchUp_ms = weAreBehindInTestingTimeFor_ms / this.currentTimeMultiplier
        const expectedTestingTimeStepOnEveryTimer_ms = TICK_PROCESSING_TIMER_DELAY_MS * this.currentTimeMultiplier
        if (weAreBehindInTestingTimeFor_ms > 0) {
            const expectedTestingTimeDeltaOnThisTimer_ms =
                expectedTestingTimeStepOnEveryTimer_ms + weAreBehindInTestingTimeFor_ms
            DebugUtils.logTopic(
                ELoggingTopics.lt_TestingSpeed,
                `We are behind on time for ${DateUtils.GetSecondsFromMilliseconds(weAreBehindInTestingTimeFor_ms)} testing sec, increasing the tick package size so we will process ${DateUtils.GetSecondsFromMilliseconds(expectedTestingTimeDeltaOnThisTimer_ms)} testing sec on this timer`
            )
            updatedTickProcessingSettings.targetDate = DateUtils.IncMilliSecond(
                GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false),
                expectedTestingTimeDeltaOnThisTimer_ms
            )
            this.RememberRealAndTestingTime()
        } else {
            //are ahead of time
            DebugUtils.logTopic(ELoggingTopics.lt_TestingSpeed, 'We are ahead of time, skipping the timer')
            //do not remember the real and testing time here because we are skipping the timer
            return { ...updatedTickProcessingSettings, skipThisTimer: true }
        }

        DebugUtils.logTopic(
            ELoggingTopics.lt_TestingSpeed,
            'Testing settings adjusted:',
            updatedTickProcessingSettings,
            'Target date:',
            DateUtils.DF(updatedTickProcessingSettings.targetDate)
        )
        return updatedTickProcessingSettings
    }

    private __debugUpdateActualTestingSpeed = throttle(() => {
        const realTimePassed_ms = DateUtils.DifferenceInMilliseconds(DateUtils.Now(), this.__debug_lastRealTime)
        const testingTimePassed_ms = DateUtils.DifferenceInMilliseconds(
            GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false),
            this.__debug_lastTestingTime
        )
        this.__debug_lastRealTime = DateUtils.Now()
        this.__debug_lastTestingTime = GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false)
        this.__debug_ActualTestingSpeed = Math.round(testingTimePassed_ms / realTimePassed_ms)
    }, 1000)

    private RememberRealAndTestingTime() {
        this.lastRealTimeWhenLastTickWasProcessed = DateUtils.Now()
        this.lastTickTimeAtSync = GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false)
    }

    //TODO: remove this later or turn it off when the debug mode is off
    private __debugCheckDataConsistency() {
        if (DebugUtils.DebugMode) {
            for (const symbol of GlobalSymbolList.SymbolList.Symbols) {
                const ticksBid = symbol.bid
                const activeTFs = symbol.GetActiveBarArrays()
                for (const activeTF of activeTFs) {
                    if (activeTF.IsSeeked) {
                        const lastBar = activeTF.LastItemInTesting
                        const lastBarClose = lastBar.close
                        if (lastBarClose !== ticksBid) {
                            throw new StrangeError(
                                `Data inconsistency detected in ${symbol.symbolInfo.SymbolName} TF: ${activeTF.DataDescriptor.timeframe}. Bid according to ticks is: ${ticksBid}, last close in bars: ${lastBarClose}`
                            )
                        }
                    }
                }
            }
        }
    }

    private HandleTickProcessingError(e: unknown) {
        if (e instanceof DataNotDownloadedYetError) {
            GlobalChartsController.Instance.enableLoader()
        } else if (e instanceof AfterEndOfHistoryError) {
            showWarningToast({
                title: t('testingManager.toasts.endOfHistoryTitle'),
                message: t('testingManager.toasts.endOfHistoryMessage')
            })
        } else {
            throw e
        }
    }

    private IsItNecessaryToStopProcessing(
        queuedTick: TTickRec,
        tickProcessingSettings: TickProcessingSettings,
        timeoutEndDate_JSMilliseconds: number
    ): boolean {
        if (queuedTick.SymbolName === '') {
            DebugUtils.warn('No more ticks to process, breaking the cycle')
            return true
        }
        //Do not do this check in debug mode because while we are in debug the timeout will be reached
        if (!DebugUtils.Instance.IgnoreTimeoutInTestingManager && Date.now() > timeoutEndDate_JSMilliseconds) {
            DebugUtils.warn('The processing of tick package takes too long, breaking the cycle')
            return true
        }

        switch (tickProcessingSettings.goingForwardStyle) {
            case EGoingForwardStyle.gf_ByTimer:
            case EGoingForwardStyle.gf_ToDate_NoChartUpdate: {
                const wasTheDesiredDateReached = this.wasTheDesiredDateReached(queuedTick, tickProcessingSettings)
                if (wasTheDesiredDateReached) {
                    DebugUtils.logTopic(
                        ELoggingTopics.lt_TestingSpeed,
                        'The desired date was reached IsItNecessaryToStopProcessing = true'
                    )
                }
                return wasTheDesiredDateReached
            }
            case EGoingForwardStyle.gf_OneTick: {
                //no need to check anything else, we are processing only one tick
                return false
            }
            default: {
                throw new StrangeError('Unknown goingForwardStyle')
            }
        }
    }

    private wasTheDesiredDateReached(queuedTick: TTickRec, tickProcessingSettings: TickProcessingSettings): boolean {
        if (!tickProcessingSettings.targetDate) {
            throw new StrangeError('targetDate is not defined')
        }

        switch (tickProcessingSettings.stopByDateCriteria) {
            case EStopByDateCriteria.sbdc_JustBefore: {
                if (
                    queuedTick.tick &&
                    DateUtils.MoreOrEqual(queuedTick.tick.DateTime, tickProcessingSettings.targetDate)
                ) {
                    return true
                }
                return false
            }
            case EStopByDateCriteria.sbdc_ExactlyAtOrJustAfter: {
                if (
                    DateUtils.MoreOrEqual(
                        GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false),
                        tickProcessingSettings.targetDate
                    )
                ) {
                    return true
                }
                return false
            }
            default: {
                throw new StrangeError('Unknown stopByDateCriteria')
            }
        }
    }

    private CorrectSpeedBasedOnDataAvailability() {
        if (GlobalSymbolList.SymbolList.AreNextChunksPreloadedForAll()) {
            if (this.speedReduction > 0) {
                this.speedReduction--
                DebugUtils.log('All data is preloaded, increasing speed to:', this.testingSpeed - this.speedReduction)
            }
        } else {
            if (this.speedReduction < this.testingSpeed - 1 && this.speedReduction < MAX_SPEED_REDUCTION) {
                this.speedReduction++
                DebugUtils.log('Not all data is preloaded, reducing speed to:', this.testingSpeed - this.speedReduction)
            }
        }

        const adjustedTestingSpeed = this.testingSpeed - this.speedReduction

        this.currentTimeMultiplier = TIME_MULTIPLIERS[adjustedTestingSpeed]
    }

    public StartTesting(testingSpeed: number): void {
        clearInterval(this.testingInterval)

        if (!GlobalSymbolList.SymbolList.isReadyToTick() && !this.isTestingNow) {
            showWarningToast({
                title: t('testingManager.toasts.cannotStartTesting'),
                message: t('testingManager.toasts.dataIsNotLoaded')
            })
        }

        this.testingSpeed = testingSpeed

        const tickProcessingSettings = this.GetTickProcessingSettings_ByTimer()

        this.launchTesting(tickProcessingSettings)
    }

    private launchTesting(tickProcessingSettings: TickProcessingSettings) {
        this.RememberRealAndTestingTime()

        this.stopTheTimer()

        const { setSettings } = ChartSettingsStore

        setSettings((settings) => ({
            ...settings,
            isPlaying: true
        }))

        DebugUtils.logTopic(ELoggingTopics.lt_TestingControl, 'launchTesting')

        //avoiding blocking the main thread
        setTimeout(() => {
            this.launchTimer(tickProcessingSettings)
        }, 100)
    }

    private launchTimer(tickProcessingSettings: TickProcessingSettings) {
        DebugUtils.logTopic(ELoggingTopics.lt_TestingControl, 'inside timeout')

        const localIntervalID = setInterval(() => {
            DebugUtils.logTopic(ELoggingTopics.lt_TestingControl, 'inside interval')
            if (!this.isTestingNow) {
                //this is necessary for cases when the timer is already stopped, but the callback function is still in the queue
                return
            }
            try {
                if (localIntervalID === this.testingInterval) {
                    this.ProcessTicksPackage(tickProcessingSettings)
                } else {
                    clearInterval(localIntervalID)
                    DebugUtils.logTopic(
                        ELoggingTopics.lt_TestingControl,
                        'Interval ID mismatch',
                        localIntervalID,
                        this.testingInterval
                    )
                }
            } catch (error) {
                DebugUtils.error('Error in testing interval:', error)
                throw error
            }
        }, TICK_PROCESSING_TIMER_DELAY_MS)

        this.testingInterval = localIntervalID

        this.isTestingNow = true

        GlobalChartsController.Instance.onTestingStart()
        GlobalSymbolList.SymbolList.SetLoadingPriority(Direction.NEXT)
    }

    public stopTheTimer(): void {
        DebugUtils.logTopic(ELoggingTopics.lt_TestingControl, 'stopTheTimer')
        this.isTestingNow = false
        clearInterval(this.testingInterval) //the timer can still tick one more time after this because the callback may be already in the queue
    }

    public StopTestingIfPlaying(): void {
        if (this.isTestingNow) {
            this.stopTesting()
        }
    }

    private GetTickProcessingSettings_ByTimer(): TickProcessingSettings {
        return {
            goingForwardStyle: EGoingForwardStyle.gf_ByTimer,
            stopByDateCriteria: EStopByDateCriteria.sbdc_JustBefore
        }
    }

    private ProcessTick(tickToProcess: TTickRec): void {
        // update symbol
        if (!tickToProcess.symbolData || !tickToProcess.tick) {
            throw new StrangeError('trec.data or .tick is not defined')
        }
        tickToProcess.symbolData.AddSingleTick(tickToProcess.tick)

        // calculate positions and profit
        GlobalProcessingCore.ProcessingCore.CurrTime = tickToProcess.tick.DateTime
        GlobalProcessingCore.ProcessingCore.UpdateOpenPositions()

        // update balance/equity
        GlobalProcessingCore.ProcessingCore.EquityArr.AddValue(
            tickToProcess.tick.DateTime,
            GlobalProcessingCore.ProcessingCore.Equity,
            GlobalProcessingCore.ProcessingCore.Balance,
            GlobalProcessingCore.ProcessingCore.Margin,
            GlobalProcessingCore.ProcessingCore.Drawdown
        )

        GlobalProjectInfo.ProjectInfo.SetPreciseLastTickTime(tickToProcess.tick.DateTime)
        //TODO: implement this
        // FTGlobal.StrategiesList.ProcessTick(trec);
        GlobalSymbolList.SymbolList.markLastTickAsProcessed(tickToProcess)
    }

    private __debugCheckLastTickTime() {
        if (!DebugUtils.DebugMode) {
            return
        }
        const commonLastTickTime = GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false)
        for (const symbol of GlobalSymbolList.SymbolList.Symbols) {
            const symbolLastTickTime = symbol.LastProcessedTickTime
            if (symbol.isCurrentTestingDateInAvailableRange && symbolLastTickTime > commonLastTickTime) {
                throw new StrangeError(
                    `Last tick time in symbol ${symbol.symbolInfo.SymbolName} is different than the common last tick time (common: ${DateUtils.DF(commonLastTickTime)}, symbol: ${DateUtils.DF(symbolLastTickTime)})`
                )
            }
        }
    }

    private PostEduCourseMsg(msg: TEducationCourseMessages): void {
        DebugUtils.error('PostEduCourseMsg not implemented')
    }

    private boundHandleSeekCompleted = this.handleSeekCompleted.bind(this)
    private handleSeekCompleted(): void {
        this.UpdateEverything(true)
        GlobalChartsController.Instance.updateMarketValues()
        GlobalSymbolList.SymbolList.Events.off(TDataArrayEvents.de_SeekCompleted, this.boundHandleSeekCompleted)
    }

    private UpdateEverything(forceRepaint = false): void {
        GlobalNewsController.Instance.throttledFilterNews(false)
        GlobalChartsController.Instance.RefreshCharts(true, forceRepaint, true)
        GlobalChartsController.Instance.updateOneClickTradingInfo()
        GlobalProcessingCore.ProcessingCore.UpdateStatistics()
        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
    }

    public StepBackBy1Bar(): void {
        if (!this.ActiveChart) {
            DebugUtils.error('ActiveChart is not defined cannot step back by 1 bar')
            return
        }

        if (this.ActiveChart.Bars.LastItemInTestingIndex <= 0) {
            showWarningToast({
                title: t('testingManager.toasts.cannotStepBack'),
                message: t('testingManager.toasts.cannotStepBackWhenAtTheBeginning')
            })
            return
        }

        const timeToRollBackTo =
            this.ActiveChart.LastItemInTesting.DateTime - CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_DATETIME * 2

        try {
            this.RollBackToTime(timeToRollBackTo)
        } catch (error) {
            if (error instanceof DataNotDownloadedYetError) {
                DebugUtils.log('Data is not downloaded yet, cannot step back by 1 bar for now')
            } else {
                throw error
            }
        }
    }

    private RollBackToTime(timeToRollBackTo: TDateTime, forceRoll = false): void {
        if (!forceRoll) {
            if (GlobalProjectInfo.ProjectInfo.ForwardTestingOnly) {
                showWarningToast({
                    title: t('testingManager.toasts.cannotStepBack'),
                    message: t('testingManager.toasts.cannotStepBackWhenForwardTesting')
                })
                return
            }

            if (!GlobalSymbolList.SymbolList.IsSeeked) {
                throw new DataNotDownloadedYetError('SymbolList is not seeked and trying to go back in history')
            }
        }
        if (!this.isTestingNow) {
            GlobalSymbolList.SymbolList.SetLoadingPriority(Direction.PREV)
        }

        const tickProcessingSettings = this.GetTickProcessingSettings_ByTargetDate(timeToRollBackTo)

        this.startSeekProcess(tickProcessingSettings)

        //If the seek is not completed yet, then this will defer itself until the seek is completed
        GlobalProcessingCore.ProcessingCore.rollBackToDate(timeToRollBackTo)

        GlobalChartsController.Instance.ClearDateCache()
    }

    public StepForwardBy1Bar(): void {
        GlobalSymbolList.SymbolList.SetLoadingPriority(Direction.NEXT)

        if (this.ActiveChart) {
            const tickPackageSettings = this.GetTickProcessingSettings_ByBar(this.ActiveChart.DataDescriptor.timeframe)

            this.ProcessTicksPackage(tickPackageSettings)
        }
    }

    private GetTickProcessingSettings_ByBar(timeframe: number): TickProcessingSettings {
        const currentDate = GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false)

        const currentBarEndDate = TimeframeUtils.GetPeriodEnd(currentDate, timeframe)

        if (currentBarEndDate < currentDate) {
            throw new StrangeError('currentBarEndDate is less than currentDate in GetTickProcessingSettingsByBar')
        }

        const nextTick = GlobalSymbolList.SymbolList.GetQueuedTick().tick

        if (!nextTick) {
            throw new StrangeError('nextTick is not defined in GettickProcessingSettingsByBar')
        }

        const nextTickBarEndDate = TimeframeUtils.GetPeriodEnd(nextTick.DateTime, timeframe)

        return {
            goingForwardStyle: EGoingForwardStyle.gf_ToDate_NoChartUpdate,
            targetDate: Math.max(currentBarEndDate, nextTickBarEndDate),
            stopByDateCriteria: EStopByDateCriteria.sbdc_JustBefore
        }
    }

    private GetTickProcessingSettings_ByTargetDate(date: TDateTime): TickProcessingSettings {
        return {
            goingForwardStyle: EGoingForwardStyle.gf_ToDate_NoChartUpdate,
            targetDate: date,
            stopByDateCriteria: EStopByDateCriteria.sbdc_ExactlyAtOrJustAfter
        }
    }

    public StepForwardBy1Tick(): void {
        GlobalSymbolList.SymbolList.SetLoadingPriority(Direction.NEXT)

        const loopStoppingCriteria = this.GetTickProcessingSettings_ByTick()

        this.ProcessTicksPackage(loopStoppingCriteria)
    }

    private GetTickProcessingSettings_ByTick(): TickProcessingSettings {
        return { goingForwardStyle: EGoingForwardStyle.gf_OneTick }
    }

    private boundHandleOutOfHistoryBoundsError = this.handleOutOfHistoryBoundsError.bind(this)
    private handleOutOfHistoryBoundsError(): void {
        this.stopTesting()
        let minLastProcessedTick = DateUtils.MaxPossibleDate
        for (const symbol of GlobalSymbolList.SymbolList.Symbols) {
            if (symbol.LastProcessedTickTime < minLastProcessedTick) {
                minLastProcessedTick = symbol.LastProcessedTickTime
            }
        }
        if (minLastProcessedTick < DateUtils.MaxPossibleDate) {
            this.RollBackToTime(DateUtils.IncMinute(minLastProcessedTick, -1), true)
        }
    }

    public stopTesting(): void {
        DebugUtils.logTopic(ELoggingTopics.lt_TestingControl, 'stopTesting')
        const { setSettings } = ChartSettingsStore

        setSettings((settings) => ({
            ...settings,
            isPlaying: false
        }))

        this.stopTheTimer()

        GlobalChartsController.Instance.onTestingStop()
    }

    public RollForwardToTime(timeToRollForwardTo: TDateTime): void {
        GlobalSymbolList.SymbolList.SetLoadingPriority(Direction.NEXT)

        const tickProcessingSettings = this.GetTickProcessingSettings_ByTargetDate(timeToRollForwardTo)
        if (GlobalProcessingCore.ProcessingCore.HasOpenOrders) {
            this.ProcessTicksPackage(tickProcessingSettings)
        } else {
            this.startSeekProcess(tickProcessingSettings)
        }
    }

    public RollTestingTo(jumpToDateUTC: TDateTime): void {
        this.stopTesting()
        if (GlobalSymbolList.SymbolList.IsSeeked) {
            //we add 30 sec because seek will try to find the previous tick which may go into previous bar (like we jump to 3:00:00 ,but we end up at 2:59:59)
            //it is better to end up at 3:00:30 in this case
            const adjustedJumpToDate = DateUtils.IncSecond(jumpToDateUTC, 30)
            GlobalChartsController.Instance.ScrollAllChartsToDate(adjustedJumpToDate)
            GlobalChartsController.Instance.setPreparingChartBlockingLayers()
            if (adjustedJumpToDate < GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true)) {
                this.RollBackToTime(adjustedJumpToDate)
            } else {
                this.RollForwardToTime(adjustedJumpToDate)
            }
        } else {
            showWarningToast({
                title: t('testingManager.toasts.cannotJumpToDate'),
                message: t('testingManager.toasts.dataIsNotLoaded')
            })
        }
    }
}
