import React, { FC, useCallback, useState, useMemo } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { Button, Flex, Typography, InputField, Separator, EmptyState, HoverElement } from '@fto/ui'

import { Closable, RibbonInnerDropdownType } from '../../../../../types'
import InnerDropdown from './components/InnerDropdown'

import { FavoriteIndicatorListType } from '@fto/lib/store/indicatorsStore/types'
import IndicatorsStore from '@fto/lib/store/indicatorsStore'

import styles from './index.module.scss'

type Props = {
    name: string
    close: () => void
    dropdowns?: RibbonInnerDropdownType[]
    additionalElements?: FC<Closable> | React.ReactNode
    favoriteIndicators: FavoriteIndicatorListType
}

const RibbonDropdownMenu: FC<Props> = ({ name, dropdowns, additionalElements, close, favoriteIndicators }) => {
    const [searchValue, setSearchValue] = useState('')

    const { t } = useTranslation()

    const filteredIndicators = useMemo(() => {
        if (!searchValue) {
            return []
        }

        const dropdownToSearch = dropdowns?.filter((list) => list.value !== 'favorite') || []

        const allIndicators = dropdownToSearch.flatMap((group) => group.options)

        if (!allIndicators) {
            return []
        }

        return allIndicators.filter((option) => option.name.toLowerCase().includes(searchValue.toLowerCase()))
    }, [searchValue, dropdowns])

    const handleAction = useCallback(
        (action: (() => void) | null) => {
            action?.()
            close()
        },
        [close]
    )

    const toggleFavorite = useCallback((indicatorValue: string) => {
        const { updateFavoriteIndicators } = IndicatorsStore

        updateFavoriteIndicators((list) => {
            const isPreselected = list.includes(indicatorValue)

            const updatedList = isPreselected
                ? list.filter((item) => item !== indicatorValue)
                : [...list, indicatorValue]

            return updatedList
        })
    }, [])

    return (
        <Flex direction='column' justifyContent='flex-start' className={styles.dropdown} gap={4}>
            <Typography className={styles.text} color='gray-600' type='tiny-regular'>
                {name}
            </Typography>
            <InputField
                value={searchValue}
                onChange={setSearchValue}
                suffix={
                    <Flex alignItems='center' gap={8}>
                        {searchValue && <Separator width='1px' height='24px' color='gray-500' />}
                        <Icon name='search' size={18} />
                    </Flex>
                }
                placeholder={t('general.search')}
                reset
                id='incicator-name'
            />
            <Flex direction='column' gap={4}>
                {dropdowns &&
                    !searchValue &&
                    dropdowns.map((dropdown) => {
                        return (
                            <InnerDropdown
                                dropdown={dropdown}
                                close={close}
                                favoriteIndicators={favoriteIndicators}
                                toggleFavorite={toggleFavorite}
                            />
                        )
                    })}

                {searchValue &&
                    (filteredIndicators.length > 0 ? (
                        <Flex direction='column' className={styles.SearchContainer}>
                            {filteredIndicators.map((option) => {
                                const isPreselected = favoriteIndicators.includes(option.value)

                                return (
                                    <Button
                                        block
                                        classNames={{
                                            content: cx(styles.buttonContent, { [styles.selected]: option.isSelected }),
                                            label: styles.SearchOptionLabel
                                        }}
                                        key={option.value}
                                        type='tetriatry-white'
                                        size='compact'
                                        label={
                                            <Flex
                                                alignItems='center'
                                                justifyContent='space-between'
                                                gap={8}
                                                flexShrink={0}
                                                className={styles.FilteredOption}
                                            >
                                                <Typography truncate title={option.name}>
                                                    {option.name}
                                                </Typography>
                                                <HoverElement
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        toggleFavorite(option.value)
                                                    }}
                                                    size={24}
                                                    classNames={{
                                                        root: cx(styles.FavoriteIcon, {
                                                            [styles.FavoriteIconVisible]: isPreselected
                                                        })
                                                    }}
                                                >
                                                    <Icon
                                                        name={isPreselected ? 'star' : 'star-empty'}
                                                        size={20}
                                                        color={
                                                            isPreselected
                                                                ? 'var(--color-orange-400)'
                                                                : 'var(--color-gray-1000'
                                                        }
                                                    />
                                                </HoverElement>
                                            </Flex>
                                        }
                                        onClick={() => handleAction(option.action)}
                                    />
                                )
                            })}
                        </Flex>
                    ) : (
                        <EmptyState
                            classNames={{ root: styles.EmptyState }}
                            title={t('ribbon.indicators.emptyState.header')}
                            description={t('ribbon.indicators.emptyState.subHeader')}
                        />
                    ))}
                {typeof additionalElements === 'function' ? additionalElements({ close }) : additionalElements}
            </Flex>
        </Flex>
    )
}

export default RibbonDropdownMenu
