import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import {
    GetBrushStyleByNumber,
    TBrushStyle,
    TColor,
    TFontStyle,
    TFontStyles
} from '../../delphi_compatibility/DelphiBasicTypes'
import { ColorHelperFunctions } from '../../drawing_interface/ColorHelperFunctions'
import { TFormatSettings } from '../../ft_types/common/FormatSettings'

export class StrsConv {
    private readonly EngUpperChars: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    private readonly EngLowerChars: string = 'abcdefghijklmnopqrstuvwxyz'
    private readonly NumChars: string = '0123456789'
    private readonly OneTo31: string =
        '#1#2#3#4#5#6#7#8#9#10#11#12#13#14#15#16#17#18#19#20#21#22#23#24#25#26#27#28#29#30#31'

    public static f_settings: TFormatSettings = new TFormatSettings()

    private static NativeDecimalSeparator: string = StrsConv.f_settings.DecimalSeparator

    public static StrColor(color: TColor, useOpacity = true): string {
        return ColorHelperFunctions.ModifyColor(color, useOpacity)
    }

    public static StrBool(value: boolean): string {
        return value ? '1' : '0'
    }

    public static StrInt(value: number, width = 0, symbol = ' '): string {
        // Convert the integer value to a string
        let result: string = value.toString()
        // If the absolute width is greater than the length of the result,
        // use the ExtStr function to adjust the string length
        if (Math.abs(width) > result.length) {
            result = StrsConv.ExtStr(result, width, symbol)
        }
        return result
    }

    public static formatValue(formatString: string, value: number, scaleDecimals: number): string {
        const formattedValue = value.toFixed(scaleDecimals)
        return formatString.replace('%f', formattedValue)
    }

    public static StrDouble(
        value: number,
        precision = 2,
        width = 0,
        symbol = ' ',
        thousandSeparator = ',',
        decimalSeparator = '.'
    ): string {
        if (symbol.length !== 1) {
            throw new StrangeError('Symbol must be a single character')
        }

        // Use Intl.NumberFormat for formatting the number
        // const formatter = new Intl.NumberFormat('en-US', {
        //     minimumFractionDigits: precision,
        //     maximumFractionDigits: precision,
        //     useGrouping: thousandSeparator !== ''
        // })

        let result: string = value.toFixed(precision)

        // Replace the default decimal separator with the one from f_settings
        if (decimalSeparator !== '.') {
            result = result.replace('.', decimalSeparator)
        }

        // Handle padding
        const absWidth: number = Math.abs(width)
        if (absWidth > result.length) {
            const padding: string = symbol.repeat(absWidth - result.length)
            result = width < 0 ? padding + result : result + padding
        }

        return result
    }

    public static GetDateTime(s: string): TDateTime {
        return DateUtils.StrToDateTime(s)
    }

    public static GetColor(s: string, UseOpacity = true): TColor {
        return ColorHelperFunctions.ModifyColor(s, UseOpacity)
    }

    public static GetDouble(s: string, decimals: number = Number.MAX_SAFE_INTEGER): number {
        let result: number = parseFloat(s.replace(',', '.'))
        // Check if the parsing was successful, otherwise throw an error.
        if (isNaN(result)) {
            throw new StrangeError(`The string '${s}' is not a valid float.`)
        }
        // Only round the number if 'decimals' is not the default value.
        if (decimals !== Number.MAX_SAFE_INTEGER) {
            // The toFixed method returns a string, so we need to convert it back to a number.
            //TODO: maybe use Math here instead of parseFloat
            result = parseFloat(result.toFixed(decimals).replace(',', '.'))
        }
        return result
    }

    public static GetQuotedStr(s: string, LeftQuote = '"', RightQuote = '"'): string {
        const pattern = new RegExp(`${LeftQuote}(.*?)${RightQuote}`)
        const match = pattern.exec(s)
        return match ? match[1] : s
    }

    public static GetNumOfQuotes(s: string, LeftQuote = '"', RightQuote = '"'): number {
        const combinedQuotes = `${LeftQuote}${RightQuote}`
        return (s.match(new RegExp(`[${combinedQuotes}]`, 'g')) || []).length
    }

    public static GetStrInt(s: string, Delimiter = ' '): [number, string] {
        const [subStr, rest] = this.GetSubStr(s, Delimiter)
        const result = parseInt(subStr)
        if (isNaN(result)) {
            throw new StrangeError(`The extracted substring '${subStr}' is not a valid integer.`)
        }
        return [result, rest]
    }

    public static GetStrBrushStyle(s: string, Delimiter = ' '): [TBrushStyle, string] {
        let numberOfBrushStyle
        let remainingString
        ;[numberOfBrushStyle, remainingString] = this.GetStrInt(s, Delimiter)
        const result = GetBrushStyleByNumber(numberOfBrushStyle)
        return [result, remainingString]
    }

    // Improved TypeScript implementation of the ExtStr function from Delphi.
    // Changes made:
    // - Ensured that the symbol parameter is a single character as in Delphi default parameters.
    // - Added a check to ensure that the symbol string is not empty and has only one character.
    // - Added a check to throw an error if the symbol string is longer than one character,
    //   to match the behavior of the Delphi function which expects a char.
    public static ExtStr(s: string, pos: number, symbol = ' '): string {
        // Ensure that the symbol is a single character, as the default parameter in Delphi is a char.
        if (symbol.length === 0 || symbol.length > 1) {
            throw new StrangeError("The 'symbol' parameter must be a single character.")
        }

        const len: number = s.length

        // Add string with symbol from left
        if (pos < 0 && Math.abs(pos) > len) {
            s = symbol.repeat(Math.abs(pos) - len) + s
        } else if (pos > len) {
            // Add string with symbol from right
            s = s + symbol.repeat(pos - len)
        }

        return s
    }

    // Improved the QuoteStr method to handle edge cases where LeftQuote and RightQuote are different.
    // The original Delphi code had a bug where it would only remove the LeftQuote if LeftQuote and RightQuote were different.
    // The TypeScript implementation now correctly removes both LeftQuote and RightQuote characters from the string.
    // Additionally, the method now properly escapes the quotes in the regular expression to avoid unintended behavior.
    public static QuoteStr(s: string, LeftQuote = '"', RightQuote = '"'): string {
        // Escape special characters for use in regular expression
        const escapedLeftQuote = LeftQuote.replaceAll(/[$()*+.?[\\\]^{|}]/g, String.raw`\$&`)
        const escapedRightQuote = RightQuote.replaceAll(/[$()*+.?[\\\]^{|}]/g, String.raw`\$&`)

        // Remove LeftQuote and RightQuote symbols from the string
        s = s.replaceAll(new RegExp(escapedLeftQuote, 'g'), '')
        s = s.replaceAll(new RegExp(escapedRightQuote, 'g'), '')

        // Return the string enclosed with LeftQuote and RightQuote
        return LeftQuote + s + RightQuote
    }

    public static GetStrColor(s: string, Delimiter = ' '): [TColor, string] {
        //TODO: check if this is correct
        return StrsConv.GetStrHex(s, Delimiter)
    }

    public static GetStrHex(s: string, Delimiter = ' '): [string, string] {
        const [hexStr, rest] = StrsConv.GetSubStr(s, Delimiter)
        // if (hexStr.length > 0 && hexStr.charAt(0) === '#') {
        //     hexStr = hexStr.substring(1)
        // }
        return [hexStr, rest]
    }

    public static GetStrFontStyle(s: string, Delimiter = ' '): [TFontStyles, string] {
        let value: number
        ;[value, s] = StrsConv.GetStrInt(s, Delimiter)
        const result: [TFontStyle, string] = [TFontStyle.fsBold, s]
        if (value === TFontStyle.fsBold) {
            result[0] = TFontStyle.fsBold
        }
        if (value === TFontStyle.fsItalic) {
            result[0] = TFontStyle.fsItalic
        }
        if (value === TFontStyle.fsUnderline) {
            result[0] = TFontStyle.fsUnderline
        }
        if (value === TFontStyle.fsStrikeout) {
            result[0] = TFontStyle.fsStrikeout
        }

        return result
    }

    // The original Delphi code uses a var parameter for the string, which means it is modified in place.
    // In TypeScript, strings are immutable, so we need to return the modified string as part of the result.
    // Additionally, the Delimiter parameter should be of type 'string', but since it represents a single character,
    // we enforce it by slicing the first character of the provided string.
    // The parseFloat function is used to convert the substring to a floating-point number.
    // If the conversion fails (result is NaN), an error is thrown to mimic Delphi's exception on conversion failure.
    // The original Delphi code uses a global settings object 'f_settings' for the StrToFloat function,
    // which is not provided here. If 'f_settings' affects parsing, this needs to be implemented in TypeScript.
    // If 'f_settings' is not relevant for the TypeScript version, it can be omitted.
    public static GetStrDouble(s: string, Delimiter = ' '): [number, string] {
        // Ensure Delimiter is a single character, as in Delphi
        Delimiter = Delimiter.slice(0, 1)
        const [subStr, rest] = this.GetSubStr(s, Delimiter)
        const result = parseFloat(subStr.replace(',', '.'))
        if (isNaN(result)) {
            throw new StrangeError(`The extracted substring '${subStr}' is not a valid float.`)
        }
        return [result, rest]
    }

    public static GetStrDateTime(s: string, Delimiter = ' '): [TDateTime, string] {
        const [subStr, rest] = this.GetSubStr(s, Delimiter)
        const result = DateUtils.StrToDateTime(subStr)
        return [result, rest]
    }

    public static StrDateTime(DateTime: TDateTime, showSeconds = false, showTime = true): string {
        let format = 'yyyy.mm.dd'

        if (showTime) {
            format = 'yyyy.mm.dd HH:nn'
            if (showSeconds) {
                format = 'yyyy.mm.dd HH:nn:ss'
            }
        }

        return DateUtils.FormatDateTime(format, DateTime)
    }

    public static GetSubStr(incomingString: string, Delimiter = ' ', SkipMultipleDelimiters = true): [string, string] {
        let i = 1 // Start from 1 to match Delphi's 1-based indexing
        let result = ''
        let remainingString = incomingString

        // If the delimiter is a single character, use the first implementation
        if (Delimiter.length === 1) {
            // skip delimiters and tabs
            if (SkipMultipleDelimiters) {
                while (
                    i <= remainingString.length &&
                    (remainingString.charAt(i - 1) === Delimiter || remainingString.charAt(i - 1) === '\u0009')
                ) {
                    i++
                }
            }

            if (i > remainingString.length) {
                return ['', '']
            }

            remainingString = remainingString.slice(Math.max(0, i - 1)) // Adjust index for 0-based indexing
            i = 1

            // find next delimiter or tab
            while (
                i <= remainingString.length &&
                remainingString.charAt(i - 1) !== Delimiter &&
                remainingString.charAt(i - 1) !== '\u0009'
            ) {
                i++
            }

            result = remainingString.slice(0, Math.max(0, i - 1))
            if (result === '~') {
                result = ''
            }

            remainingString = remainingString.slice(Math.max(0, i)) // Adjust index for 0-based indexing
        } else {
            // If the delimiter is a string, use the second implementation
            i = remainingString.indexOf(Delimiter)
            if (i === -1) {
                result = remainingString
                remainingString = ''
            } else {
                result = remainingString.slice(0, Math.max(0, i))
                remainingString = remainingString.slice(Math.max(0, i + Delimiter.length))
            }
        }

        return [result, remainingString]
    }

    public static GetBool(s: string): boolean {
        return s === '1'
    }

    public static DelSymbolsRight(s: string, count: number): string {
        // Ensure count is not negative and does not exceed the string length
        count = Math.max(0, Math.min(count, s.length))
        // Return the string after removing 'count' number of symbols from the right
        return s.slice(0, Math.max(0, s.length - count))
    }

    public static IntToStr(value: number): string {
        return value.toString()
    }

    public static Utf8ToString(bytes: Uint8Array): string {
        return new TextDecoder().decode(bytes)
    }

    public static AddValueToStr(s: string, value: string | number, delimiter = ','): string {
        // Convert number to string if necessary to match Delphi's IntToStr behavior
        if (typeof value === 'number') {
            value = value.toString()
        }
        // Preserve the original Delphi behavior of using a single character as a delimiter
        delimiter = delimiter.charAt(0)
        // If the input string is empty, assign the value directly
        if (s === '') {
            s = value
        } else {
            // Otherwise, append the value to the string with the delimiter
            s += delimiter + value
        }
        return s
    }

    public static FormatNumberWithSuffix(value: number, decimals: number): string {
        const suffixes = ['', 'K', 'M', 'B', 'T', 'Q', 'P', 'E', 'Z', 'Y']
        const absValue = Math.abs(value)

        if (absValue <= 99999) {
            return value.toFixed(decimals)
        }

        const idx = Math.floor(Math.log10(absValue) / 3)
        const i = Math.min(idx, suffixes.length - 1)
        const scaledValue = (absValue / Math.pow(1000, i)).toFixed(1)
        return `${value < 0 ? '-' : ''}${scaledValue}${suffixes[i]}`
    }
}
