import { FC, useCallback } from 'react'

import { Icon } from '@fto/icons'
import { Button, Flex, Typography } from '@fto/ui'
import { OptionType } from 'packages/fto/root/src/chart_components/ProjectInterface/components/ToolsSidebar/types'

import styles from '../../../../common.module.scss'

type DropdownMenuProps = {
    name: string
    options: OptionType[]
    action: (option: OptionType) => void
    activeToolName: string | null
}

export const DropdownMenu: FC<DropdownMenuProps> = ({ name, options, action, activeToolName }) => {
    const handleClick = useCallback(
        (option: OptionType) => {
            action(option)
        },
        [action]
    )

    return (
        <Flex direction='column' justifyContent='flex-start' alignItems='center' className={styles.dropdown} gap={4}>
            <Typography className={styles.text} color='gray-600' type='tiny-regular'>
                {name}
            </Typography>
            {options.map((option) => {
                const isCurrentTool = activeToolName === option.toolKey
                return (
                    <Button
                        block
                        classNames={{ root: isCurrentTool ? styles.selected : '', content: styles.buttonContent }}
                        before={
                            option.icon && (
                                <Icon
                                    color={isCurrentTool ? '--color-primary-500' : '--color-gray-1000'}
                                    name={option.icon}
                                    size={16}
                                />
                            )
                        }
                        key={option.name}
                        type='tetriatry-white'
                        size='compact'
                        label={option.name}
                        onClick={() => handleClick(option)}
                    />
                )
            })}
        </Flex>
    )
}
