import { FC, useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Flex, InputField, Typography } from '@fto/ui'
import oneClickTradingStore from '@fto/lib/store/oneClickTradingStore'

import styles from '../OneClickTradingContextMenu/index.module.scss'

const OneClickTradingMenuContent: FC = observer(() => {
    const { data, updateData } = oneClickTradingStore
    const [lot, setLot] = useState(data.lot)

    const handleSetLot = useCallback(
        (value: string) => {
            updateData({ ...data, lot: Number(value) })
            setLot(Number(value))
        },
        [updateData]
    )

    const handleFocus = useCallback(() => {
        updateData({ ...data, focused: true })
    }, [updateData])

    useEffect(() => {
        return () => {
            updateData({ lot: lot, focused: false })
        }
    }, [updateData, lot])

    return (
        <Flex gap={4} alignItems='center'>
            <Typography>Lot:</Typography>
            <InputField
                type='number'
                className={styles.input}
                onChange={handleSetLot}
                onFocus={handleFocus}
                value={lot}
                step={0.01}
                min={0.01}
                max={100000}
            />
        </Flex>
    )
})

export default OneClickTradingMenuContent
