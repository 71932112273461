import { TValueType } from '@fto/lib/extension_modules/indicators/api/IndicatorInterfaceUnit'
import { ValueTypeLevel } from '@fto/lib/globals/HighestLowestCache/ValueTypeLevel'

export class IndexesLevel {
    private map: Map<number, ValueTypeLevel>

    constructor() {
        this.map = new Map<number, ValueTypeLevel>()
    }

    set(globalIndex: number, valueType: TValueType, offset: number, value: number): void {
        if (!this.map.has(globalIndex)) {
            this.map.set(globalIndex, new ValueTypeLevel())
        }
        this.map.get(globalIndex)?.set(valueType, offset, value)
    }

    get(globalIndex: number, valueType: TValueType, offset: number): number | undefined {
        return this.map.get(globalIndex)?.get(valueType, offset)
    }

    has(globalIndex: number): boolean {
        return this.map.has(globalIndex) ?? false
    }
}
