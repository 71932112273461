import styles from '@fto/chart_components/ChartStyle.module.scss'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import { TOscWindow } from '@fto/lib/charting/OscWinsListUnit'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { TDateTimeBar } from '@fto/lib/charting/chart_classes/DateTimeBarUnit'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { TBasicChartWindow } from '@fto/lib/charting/chart_windows/BasicChartWindow'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { OscInfo } from '@fto/lib/charting/chart_windows/Oscillators/OscInfo'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import globalChartsStore from '@fto/lib/store/globalChartsStore'
import { observer } from 'mobx-react-lite'

type MainChartComponentProps = {
    chartWindow: TChartWindow
    symbolName?: string
    showBorderWhenSelected: boolean
}

const MainChartComponent: FC<MainChartComponentProps> = observer(
    ({ chartWindow, symbolName, showBorderWhenSelected }) => {
        const [oscCanvases, setOscCanvases] = useState<HTMLCanvasElement[]>([])
        const currentChart = chartWindow
        const oscContainer = useRef<HTMLDivElement | null>(null)
        const mainChartCanvasElement = useRef<HTMLCanvasElement | null>(null)
        const dateScaleCanvasElement = useRef<HTMLCanvasElement | null>(null)

        const mainChart = useRef<TMainChart | null>(null) // Declare mainChart here
        const dateScale = useRef<TDateTimeBar | null>(null) // Declare mainChart here

        const animationFrameId = useRef<number>(0)

        const resizeCanvasToDisplaySize = useCallback(() => {
            if (mainChart.current) {
                resizeSingleCanvas(mainChartCanvasElement.current as HTMLCanvasElement, mainChart.current)

                chartWindow.onBrowserWndSizing()
            }
            if (dateScale.current) {
                if (dateScaleCanvasElement.current) {
                    resizeSingleCanvas(dateScaleCanvasElement.current, dateScale.current)
                }
            }
        }, [chartWindow, dateScaleCanvasElement])

        const resizeSingleCanvas = useCallback(
            (canvasElement: HTMLCanvasElement, chart: TChart) => {
                if (canvasElement && chart) {
                    const rect = canvasElement.getBoundingClientRect()
                    const dpr = window.devicePixelRatio || 1

                    const computedStyle = window.getComputedStyle(canvasElement)

                    const borderLeft = parseFloat(computedStyle.borderLeftWidth) || 0
                    const borderRight = parseFloat(computedStyle.borderRightWidth) || 0
                    const borderTop = parseFloat(computedStyle.borderTopWidth) || 0
                    const borderBottom = parseFloat(computedStyle.borderBottomWidth) || 0

                    const paddingLeft = parseFloat(computedStyle.paddingLeft) || 0
                    const paddingRight = parseFloat(computedStyle.paddingRight) || 0
                    const paddingTop = parseFloat(computedStyle.paddingTop) || 0
                    const paddingBottom = parseFloat(computedStyle.paddingBottom) || 0

                    const innerWidth = rect.width - borderLeft - borderRight - paddingLeft - paddingRight
                    const innerHeight = rect.height - borderTop - borderBottom - paddingTop - paddingBottom

                    canvasElement.width = Math.round(innerWidth * dpr)
                    canvasElement.height = Math.round(innerHeight * dpr)

                    if (chart instanceof TDateTimeBar) {
                        const parentElement = dateScaleCanvasElement.current
                        if (parentElement) {
                            const heightInPx = GlobalOptions.Options.DateScaleFontSize + 6
                            const adjustedHeight = heightInPx / dpr
                            parentElement.style.height = `${adjustedHeight}px`
                            parentElement.width = Math.round(innerWidth * dpr)
                        }
                    }

                    chart.Paint()
                }
            },
            [TDateTimeBar]
        )

        const startChartAnimation = useCallback(() => {
            const updateChart = () => {
                animationFrameId.current = requestAnimationFrame(updateChart)
            }
            requestAnimationFrame(updateChart)
        }, [])

        const getTotalChartHeight = useCallback((): number => {
            let totalHeight = 0

            if (mainChartCanvasElement.current) {
                totalHeight += mainChartCanvasElement.current.clientHeight
            }

            const oscContainerElement = oscContainer.current
            if (oscContainerElement) {
                totalHeight += oscContainerElement.clientHeight
            }

            if (dateScaleCanvasElement.current) {
                totalHeight += dateScaleCanvasElement.current.clientHeight
            }

            return totalHeight
        }, [])

        const addOscWindow = useCallback(
            (chartWindow: TBasicChartWindow, oscCanvasOwnerWindow: TOscWindow): OscInfo | null => {
                const totalChartHeight = getTotalChartHeight()
                const oscWindowHeight = (totalChartHeight * GlobalOptions.Options.DefaultOscWindowHeightInPercent) / 100
                const splitterHeight = GlobalOptions.Options.DefaultOscSplitterHeightInPixels
                const containerHeight = oscWindowHeight + splitterHeight
                const minChartHeight = (totalChartHeight * GlobalOptions.Options.MinChartHeightInPercent) / 100

                const containerWithAllOsc = oscContainer.current

                const dpr = window.devicePixelRatio || 1
                const oscWindowContainer = document.createElement('div')
                oscWindowContainer.style.position = 'relative'

                oscWindowContainer.classList.add('osc-window-container')
                oscWindowContainer.id = `osc-window-container-${oscCanvasOwnerWindow.getID()}`

                const splitterCanvas = document.createElement('canvas')
                splitterCanvas.classList.add('splitter-window')
                splitterCanvas.style.height = `${splitterHeight}px`
                splitterCanvas.style.width = `${(mainChart.current?.HTML_Canvas?.width || 0) / dpr}px`
                splitterCanvas.style.display = 'block'
                oscWindowContainer.appendChild(splitterCanvas)

                const oscCanvas = document.createElement('canvas')
                oscCanvas.classList.add('osc-window')
                oscCanvas.style.height = `${oscWindowHeight}px`
                oscCanvas.style.width = `${(mainChart.current?.HTML_Canvas?.width || 0) / dpr}px`
                oscCanvas.style.display = 'block'
                oscWindowContainer.appendChild(oscCanvas)

                oscWindowContainer.style.height = `${containerHeight}px`

                if (containerWithAllOsc) {
                    if (containerWithAllOsc.firstChild) {
                        containerWithAllOsc.insertBefore(oscWindowContainer, containerWithAllOsc.firstChild)
                    } else {
                        containerWithAllOsc.appendChild(oscWindowContainer)
                    }
                    if (mainChart.current) {
                        resizeSingleCanvas(oscCanvas, mainChart.current)
                        resizeSingleCanvas(splitterCanvas, mainChart.current) // Может понадобиться функция для изменения размера сплиттера
                    }

                    // Here we save min canvas and splitter`s canvas
                    setOscCanvases((prevState) => [...prevState, oscCanvas, splitterCanvas])

                    return {
                        oscCanvas: oscCanvas,
                        splitterCanvas: splitterCanvas,
                        oscContainer: oscWindowContainer,
                        oscWindow: oscCanvas,
                        oscSplitter: splitterCanvas,
                        totalChartHeight: totalChartHeight,
                        mainChartCanvasElement: mainChartCanvasElement.current as HTMLCanvasElement,
                        mainOscContainer: containerWithAllOsc
                    }
                } else {
                    DebugUtils.error(`Container with id not found.`)
                    return null
                }
            },
            [
                getTotalChartHeight,
                GlobalOptions.Options.DefaultOscWindowHeightInPercent,
                GlobalOptions.Options.DefaultOscSplitterHeightInPixels,
                GlobalOptions.Options.MinChartHeightInPercent
            ]
        )
        const deleteOscWindow = useCallback((id: number) => {
            const oscWindowContainer = document.getElementById(`osc-window-container-${id}`)
            if (oscWindowContainer && oscContainer.current) {
                oscContainer.current.removeChild(oscWindowContainer)
            }
        }, [])

        const deleteAllOscWindows = useCallback(() => {
            for (const canvas of oscCanvases) {
                canvas.remove()
            }
            setOscCanvases([])
        }, [oscCanvases])

        useEffect(() => {
            mainChart.current = chartWindow.MainChart
            dateScale.current = chartWindow.TimeBar
            chartWindow.TimeBar.Set_HTML_Canvas(dateScaleCanvasElement.current as HTMLCanvasElement)
            chartWindow.MainChart.Set_HTML_Canvas(mainChartCanvasElement.current as HTMLCanvasElement)
            chartWindow.restoreScrollPosition()

            const resizeObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    if (entry.target === mainChartCanvasElement.current) {
                        resizeCanvasToDisplaySize()
                    }
                }
            })
            resizeObserver.observe(mainChartCanvasElement.current as HTMLCanvasElement)

            startChartAnimation()
            chartWindow.setCreateCanvasCallback(addOscWindow)
            chartWindow.setDeleteCanvasCallback(deleteOscWindow)
            chartWindow.setDeleteAllCanvasCallback(deleteAllOscWindows)
            chartWindow.TimeBar.setResizeCallback(resizeCanvasToDisplaySize)

            window.addEventListener('resize', resizeCanvasToDisplaySize)

            GlobalProjectJSONAdapter.Instance.loadChartWindowFromJSON(chartWindow)

            chartWindow.isInitialized = true

            return () => {
                window.removeEventListener('resize', resizeCanvasToDisplaySize)

                if (typeof cancelAnimationFrame === 'function') {
                    cancelAnimationFrame(animationFrameId.current)
                }
                if (resizeObserver) {
                    if (mainChartCanvasElement.current !== null) {
                        resizeObserver.unobserve(mainChartCanvasElement.current as HTMLCanvasElement)
                    }
                    resizeObserver.disconnect()
                }
            }
        }, [])

        const {
            data: { activeChart: focusedChart }
        } = globalChartsStore

        const useOutline = showBorderWhenSelected && focusedChart === currentChart
        const border = useOutline ? '1px solid #1313d4' : '1px solid transparent'

        return (
            <>
                <canvas
                    style={{ borderTop: border, borderLeft: border, borderRight: border }}
                    ref={mainChartCanvasElement}
                    className={styles.mainChart}
                    id={`main-chart-${chartWindow.getID()}`}
                ></canvas>
                <div
                    style={{ borderLeft: border, borderRight: border }}
                    ref={oscContainer}
                    className='osc-container'
                    id={`osc-container-${chartWindow.getID()}`}
                ></div>
                <canvas
                    style={{ borderLeft: border, borderRight: border, borderBottom: border }}
                    ref={dateScaleCanvasElement}
                    className={styles.timeScale}
                    id={`time-scale-${chartWindow.getID()}`}
                ></canvas>
            </>
        )
    }
)

export default MainChartComponent
