import React, { FC, ReactNode, useCallback, useMemo } from 'react'
import cx from 'classnames'
import { t } from 'i18next'

import { Icon } from '@fto/icons'
import { TriggerOverlay, Flex, Typography, TriggerOverlayProps } from '@fto/ui'

import styles from './index.module.scss'

export type ValueType = string | number

export type OptionType = {
    before?: ReactNode
    after?: ReactNode | ((handleClose: () => void, isSelected: boolean) => ReactNode)
    value: ValueType
    label?: ReactNode | string
    localeKey?: string
    className?: string
}

type Props = {
    onChange: (value: any) => void
    value: ValueType
    title?: string | ReactNode
    transformOrigin?: TriggerOverlayProps['transformOrigin']
    anchorOrigin?: TriggerOverlayProps['anchorOrigin']
    disableBackdropChartActivity?: TriggerOverlayProps['disableBackdropChartActivity']
    options: OptionType[]
    shouldCloseAfterSelection?: boolean
    classNames?: {
        header?: string
        title?: string
        content?: string
        body?: string
        root?: string
        option?: string
    }
    children?: ReactNode
    headerIcon?: ReactNode
    withArrow?: boolean
    оptionsTruncate?: boolean
    disabled?: boolean
}
export const SimpleDropdown: FC<Props> = ({
    value,
    onChange,
    title,
    transformOrigin = { vertical: 'top', horizontal: 'right' },
    anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
    shouldCloseAfterSelection = true,
    options,
    classNames = {},
    children,
    withArrow = true,
    disabled = false,
    disableBackdropChartActivity = false,
    headerIcon,
    оptionsTruncate = false
}) => {
    const optionRenderer = useCallback(
        (onClose: () => void) => {
            return (
                <Flex direction='column' className={cx(styles.Select, classNames.content)} gap={4}>
                    {title &&
                        (typeof title === 'string' ? (
                            <Typography
                                color='gray-600'
                                type='tiny-regular'
                                className={cx(styles.Title, classNames.title)}
                            >
                                {title}
                            </Typography>
                        ) : (
                            title
                        ))}
                    <div className={classNames.body}>
                        {options.map((option: OptionType) => {
                            const onSelect = () => {
                                onChange(option.value)
                                shouldCloseAfterSelection && onClose?.()
                            }

                            const isSelected = value === option.value
                            const labelValue = option.localeKey ? t(option.localeKey) : option.label
                            const lavelTitle = typeof labelValue === 'string' ? labelValue : ''

                            return (
                                <Flex
                                    alignItems='center'
                                    justifyContent='flex-start'
                                    className={cx(styles.Option, classNames.option, option.className, {
                                        [styles.Selected]: isSelected
                                    })}
                                    onClick={onSelect}
                                    key={option.value}
                                    gap={4}
                                >
                                    {option.before}
                                    {option.localeKey || typeof option.label === 'string' ? (
                                        <Typography
                                            type='interface-regular'
                                            color={isSelected ? 'white' : 'gray-1000'}
                                            title={lavelTitle}
                                            truncate={оptionsTruncate}
                                        >
                                            {labelValue}
                                        </Typography>
                                    ) : (
                                        option.label
                                    )}
                                    {typeof option.after === 'function'
                                        ? option.after(onClose, isSelected)
                                        : option.after}
                                </Flex>
                            )
                        })}
                    </div>
                </Flex>
            )
        },
        [classNames, title, options, value, onChange, shouldCloseAfterSelection]
    )

    const headerViewJSX = useMemo(() => {
        if (!children) {
            const selectedOption = options.find((option) => option.value === value)

            return (
                <Flex
                    alignItems='center'
                    justifyContent='center'
                    gap={8}
                    className={cx(styles.Header, classNames.header)}
                >
                    {headerIcon}
                    <Typography color='gray-1000'>
                        {selectedOption?.localeKey ? t(selectedOption?.localeKey) : selectedOption?.label}
                    </Typography>
                    {withArrow && <Icon name='chevron-down' size={12} color={'var(--color-gray-1000)'} />}
                </Flex>
            )
        }

        return children
    }, [children, options, value, classNames.header, withArrow, headerIcon])

    return (
        <div className={cx(styles.SimpleDropdown, classNames.root)}>
            <TriggerOverlay
                disableBackdropChartActivity={disableBackdropChartActivity}
                optionsRenderer={optionRenderer}
                transformOrigin={transformOrigin}
                anchorOrigin={anchorOrigin}
                disabled={disabled}
            >
                {headerViewJSX}
            </TriggerOverlay>
        </div>
    )
}
