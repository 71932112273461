import { t } from 'i18next'
import { UserIndicator } from '../api/UserIndicator'
import {
    TChartInfo,
    TObjectType,
    TOptionType,
    TOptValue_bool,
    TOptValue_number,
    TOptValue_str,
    TOutputWindow
} from '../api/IndicatorInterfaceUnit'
import { ObjProp } from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export interface FvgRectangle {
    name: string
    max: number
    min: number
    timeStart: number
    timeEnd: number
}

class FairValueGap extends UserIndicator {
    static instanceCounter = 0
    instanceNumber: number = FairValueGap.instanceCounter
    rectangleSize: TOptValue_number = new TOptValue_number(10)
    barsToCalculate: TOptValue_number = new TOptValue_number(500)
    removeFilledRectangles: TOptValue_bool = new TOptValue_bool(false)
    //used for tests
    calculateAllBars: TOptValue_bool = new TOptValue_bool(false)
    fillPriceType: TOptValue_number = new TOptValue_number(0) //0 for Close, 1 for High/Low
    opacity = '30'
    bullColor: TOptValue_str = new TOptValue_str('#089981')
    bearColor: TOptValue_str = new TOptValue_str('#f23645')
    time = 0
    isVisible = true
    bullRectangles: FvgRectangle[] = []
    bearRectangles: FvgRectangle[] = []

    // bullMinBuffer!: TIndexBuffer
    // bullMaxBuffer!: TIndexBuffer
    // bearMinBuffer!: TIndexBuffer
    // bearMaxBuffer!: TIndexBuffer

    Init() {
        // this.api.RecalculateMeAlways()
        FairValueGap.instanceCounter++
        this.api.IndicatorShortName(t('indicators.fairValueGap'))
        this.api.SetOutputWindow(TOutputWindow.ow_ChartWindow)
        this.api.RegOption(
            t('indicatorModal.fairValueGap.fields.barsToCalculate'),
            TOptionType.ot_Integer,
            this.barsToCalculate
        )
        this.api.RegOption(
            t('indicatorModal.fairValueGap.fields.rectangleWidth'),
            TOptionType.ot_Integer,
            this.rectangleSize
        )
        this.api.RegOption(
            t('indicatorModal.fairValueGap.fields.removeFilledRectangles'),
            TOptionType.ot_Boolean,
            this.removeFilledRectangles
        )
        this.api.RegOption(
            t('indicatorModal.fairValueGap.fields.fillPriceType'),
            TOptionType.ot_EnumType,
            this.fillPriceType
        )

        this.api.AddOptionValue(
            t('indicatorModal.fairValueGap.fields.fillPriceType'),
            t('indicatorModal.general.applyToPriceOptions.close')
        )
        this.api.AddOptionValue(
            t('indicatorModal.fairValueGap.fields.fillPriceType'),
            t('indicatorModal.general.applyToPriceOptions.highLow')
        )

        this.api.RegOption(t('indicatorModal.fairValueGap.fields.bullColor'), TOptionType.ot_Color, this.bullColor)
        this.api.RegOption(t('indicatorModal.fairValueGap.fields.bearColor'), TOptionType.ot_Color, this.bearColor)

        // this.bullMinBuffer = this.api.CreateIndexBuffer()
        // this.bullMaxBuffer = this.api.CreateIndexBuffer()
        // this.bearMinBuffer = this.api.CreateIndexBuffer()
        // this.bearMaxBuffer = this.api.CreateIndexBuffer()
        // this.api.IndicatorBuffers(4)
        // this.api.SetIndexBuffer(0, this.bullMinBuffer)
        // this.api.SetIndexBuffer(1, this.bullMaxBuffer)
        // this.api.SetIndexBuffer(2, this.bearMinBuffer)
        // this.api.SetIndexBuffer(3, this.bearMaxBuffer)
        //
        // this.api.SetIndexStyle(0, TDrawStyle.ds_Histogram, TPenStyle.psSolid, 1, '#66CDAA')
        // this.api.SetIndexStyle(1, TDrawStyle.ds_Histogram, TPenStyle.psSolid, 1, '#8ccd66')
        // this.api.SetIndexStyle(2, TDrawStyle.ds_Histogram, TPenStyle.psSolid, 1, '#cd6691')
        // this.api.SetIndexStyle(3, TDrawStyle.ds_Histogram, TPenStyle.psSolid, 1, '#cd6666')
    }

    private detect(index: number): [boolean, boolean, FvgRectangle | null] {
        const threshold = 0

        const bullFvg =
            this.api.Low(index) > this.api.High(index + 2) &&
            this.api.Close(index + 1) > this.api.High(index + 2) &&
            (this.api.Low(index) - this.api.High(index + 2)) / this.api.High(index + 2) > threshold

        const bearFvg =
            this.api.High(index) < this.api.Low(index + 2) &&
            this.api.Close(index + 1) < this.api.Low(index + 2) &&
            (this.api.Low(index + 2) - this.api.High(index)) / this.api.High(index) > threshold

        if (bullFvg && bearFvg) {
            throw new StrangeError('Both bear and bull FVG detected')
        }

        let newFvg: FvgRectangle | null = null
        const timeEnd = this.api.Time(index + 2 - this.rectangleSize.value)
        if (bullFvg) {
            newFvg = {
                name: `Bullish_FVG${this.api.Time(index + 2)}${this.instanceNumber}`,
                max: this.api.Low(index),
                min: this.api.High(index + 2),
                timeStart: this.api.Time(index + 2),
                timeEnd: timeEnd
            }
        } else if (bearFvg) {
            newFvg = {
                name: `Bearish_FVG${this.api.Time(index + 2)}${this.instanceNumber}`,
                max: this.api.Low(index + 2),
                min: this.api.High(index),
                timeStart: this.api.Time(index + 2),
                timeEnd: timeEnd
            }
        }

        return [bullFvg, bearFvg, newFvg]
    }

    Calculate(index: number): void {
        this.rectangleSize.value = Math.max(this.rectangleSize.value, 1)

        if (!this.calculateAllBars.value) {
            this.barsToCalculate.value = Math.min(this.barsToCalculate.value, 500)
            if (index > this.barsToCalculate.value) {
                this.ResetObjects()
                return
            }
        }

        if (this.isVisible) {
            const [bullFvg, bearFvg, newFvg] = this.detect(index)

            if (!this.calculateAllBars.value) {
                const chartInfo = this.api.GetChartInfo()
                if (chartInfo) {
                    const lastIndexTime = this.api.Time(chartInfo.LastIndex)
                    if (this.time > lastIndexTime) {
                        this.ResetObjects()
                    }
                }
            }

            if (newFvg) {
                if (this.api.ObjectExists(newFvg.name, true)) {
                    this.api.ObjectDelete(newFvg.name, true)
                }
                this.api.ObjectCreate(
                    newFvg.name,
                    TObjectType.obj_Rectangle,
                    0,
                    newFvg.timeStart,
                    newFvg.max,
                    newFvg.timeEnd,
                    newFvg.min,
                    undefined,
                    undefined,
                    true
                )

                if (bullFvg) {
                    this.bullRectangles.push(newFvg)
                    this.api.ObjectSet(
                        newFvg.name,
                        ObjProp.OBJPROP_FILLCOLOR,
                        this.bullColor.value + this.opacity,
                        true
                    )
                } else if (bearFvg) {
                    this.bearRectangles.push(newFvg)
                    this.api.ObjectSet(
                        newFvg.name,
                        ObjProp.OBJPROP_FILLCOLOR,
                        this.bearColor.value + this.opacity,
                        true
                    )
                }

                this.api.ObjectSet(newFvg.name, ObjProp.BORDER, false, true)
                this.time = this.api.Time(index)
            }
        }
        if (this.removeFilledRectangles.value) {
            this.CheckAndRemoveFilledFvgRectangles(index)
        }
    }

    CheckAndRemoveFilledFvgRectangles(index: number): void {
        if (this.fillPriceType.value === 0) {
            this.RemoveFilledByClose(index)
        } else if (this.fillPriceType.value === 1) {
            this.RemoveFilledByHighLow(index)
        }
    }
    RemoveFilledByClose(currentIndex: number): void {
        this.bullRectangles = this.bullRectangles.filter((rect) => {
            let filled = false
            for (let i = currentIndex; i >= 0; i--) {
                const closePrice = this.api.Close(i)
                if (closePrice <= rect.min) {
                    filled = true
                    break
                }
            }
            if (filled) {
                this.api.ObjectDelete(rect.name, true)
            }
            return !filled
        })

        this.bearRectangles = this.bearRectangles.filter((rect) => {
            let filled = false
            for (let i = currentIndex; i >= 0; i--) {
                const closePrice = this.api.Close(i)

                if (closePrice >= rect.max) {
                    filled = true
                    break
                }
            }
            if (filled) {
                this.api.ObjectDelete(rect.name, true)
            }
            return !filled
        })
    }

    RemoveFilledByHighLow(currentIndex: number): void {
        this.bullRectangles = this.bullRectangles.filter((rect) => {
            let filled = false
            for (let i = currentIndex; i >= 0; i--) {
                const lowPrice = this.api.Low(i)

                if (lowPrice <= rect.min) {
                    filled = true
                    break
                }
            }
            if (filled) {
                this.api.ObjectDelete(rect.name, true)
            }
            return !filled
        })

        this.bearRectangles = this.bearRectangles.filter((rect) => {
            let filled = false
            for (let i = currentIndex; i >= 0; i--) {
                const highPrice = this.api.High(i)

                if (highPrice >= rect.max) {
                    filled = true
                    break
                }
            }
            if (filled) {
                this.api.ObjectDelete(rect.name, true)
            }
            return !filled
        })
    }

    OnParamsChange() {
        this.ResetObjects()
    }

    ResetObjects() {
        for (const rect of this.bullRectangles) {
            if (this.api.ObjectExists(rect.name, true)) {
                this.api.ObjectDelete(rect.name, true)
            }
        }

        for (const rect of this.bearRectangles) {
            if (this.api.ObjectExists(rect.name, true)) {
                this.api.ObjectDelete(rect.name, true)
            }
        }

        this.bullRectangles = []
        this.bearRectangles = []

        // Clear buffers (if used in the future)
        // this.bullMinBuffer.programSideBuffer.Clear();
        // this.bullMaxBuffer.programSideBuffer.Clear();
        // this.bearMinBuffer.programSideBuffer.Clear();
        // this.bearMaxBuffer.programSideBuffer.Clear();
    }

    Done() {
        this.ResetObjects()
        FairValueGap.instanceCounter--
    }

    OnHide(): void {
        this.ResetObjects()
        this.isVisible = false
    }

    OnShow(): void {
        this.isVisible = true
    }
}

export default FairValueGap
