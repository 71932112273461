import { ChartControl, chartControlEvent, ChartControlParams } from '@fto/chart_components/ChartControl'
import { TGdiPlusCanvas, TRoundRectType } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { IGPPen, IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'

export class ImageControl extends ChartControl {
    protected image!: HTMLImageElement
    private isNeedDrawBorder: boolean = false
    private pen: IGPPen = new IGPPen('#dadcde', 1)
    private brush: IGPSolidBrush = new IGPSolidBrush('#ffffff')
    private brushHovered: IGPSolidBrush = new IGPSolidBrush('#f4f4f4')

    constructor(chartControlParams: ChartControlParams, image: HTMLImageElement, tooltip: string = '') {
        super(chartControlParams)
        this.image = image
    }

    disableBorder(): void {
        this.isNeedDrawBorder = false
    }

    enableBorder(): void {
        this.isNeedDrawBorder = true
    }
    setToolTip(tooltip: string): void {
        this.tooltip = tooltip
    }

    draw(canvas: TGdiPlusCanvas): void {
        if (!this.IsVisible()) {
            return
        }

        const location = this.getLocation()

        if (this.isNeedDrawBorder) {
            canvas.strokeRect(location, TRoundRectType.BOTH, 5, true, this.pen, this.brush)
        }
        if (this.isMouseInside()) {
            canvas.FillRect(location, this.brushHovered)
            if (this.tooltip) {
                this.showTooltip(canvas)
            }
        }

        const centerX = location.Left + location.Width / 2
        const centerY = location.Top + location.Height / 2
        const imageX = centerX - this.image.width / 2
        const imageY = centerY - this.image.height / 2

        canvas.graphics.Context.drawImage(this.image, imageX, imageY, this.image.width, this.image.height)
    }

    changeImage(image: HTMLImageElement): void {
        this.image = image
    }

    onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        let result = null
        if (super.onMouseDown(event, sender)) {
            result = this
            this.notify(chartControlEvent.BUTTON_PRESSED)
        }
        return result
    }
}
