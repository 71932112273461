import { DASHBOARD_ROUTE, makeRoute } from '@root/constants/routes'
import { FC, useEffect } from 'react'

export const SuccessPage: FC = () => {
    useEffect(() => {
        window.parent.postMessage(
            {
                type: 'REDIRECT',
                url: makeRoute(DASHBOARD_ROUTE + '?action=payment&success=true')
            },
            'https://fto-staging.forextester.com'
        )
    }, [])

    return null
}
