import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { Button } from '@fto/ui'
import { Icon } from '@fto/icons'

import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

import {
    ADAPTIVE_INDICATORS_LIST,
    ALL_INDICATORS_LIST,
    OSCILLATORS_INDICATORS_LIST,
    TREND_INDICATORS_LIST,
    VOLUME_INDICATORS_LIST
} from '@fto/chart_components/constants/indicatorsList'
import { fireMixpanelEvent } from '@root/utils/api'

import IndicatorsStore from '@fto/lib/store/indicatorsStore'

import RibbonDropdown from '../../../RibbonDropdown'
import { RibbonInnerDropdownType } from '../../../types'
import ContentRenderer from './components/ContentRenderer'

import styles from '../../../../index.module.scss'

const IndicatorDropdown: FC = () => {
    const { t } = useTranslation()

    const { favoriteIndicators } = IndicatorsStore

    const addIndicator = useCallback((indicatorName: string) => {
        GlobalChartsController.Instance.createIndicator(indicatorName)
        fireMixpanelEvent('indicator_selected', { indicator_name: indicatorName })
    }, [])

    const getIndicatorOptions = useCallback(
        (indicatorList: { label: string; value: string }[]) => {
            return indicatorList.map((indicator) => {
                return {
                    name: indicator.label,
                    value: indicator.value,
                    action: () => addIndicator(indicator.value)
                } as { name: string; action: () => void }
            })
        },
        [addIndicator]
    )

    const favoriteIndicatorOptions = useMemo(() => {
        const allOIndicators = ALL_INDICATORS_LIST(t)

        return allOIndicators.filter((indicator) => favoriteIndicators.includes(indicator.value))
    }, [favoriteIndicators])

    const indicatorDropdowns = useMemo(() => {
        const list = [
            {
                value: 'trend',
                name: t('ribbon.indicators.trend'),
                icon: 'trend',
                options: getIndicatorOptions(TREND_INDICATORS_LIST(t))
            },
            {
                value: 'volume',
                name: t('ribbon.indicators.volume'),
                icon: 'volume',
                options: getIndicatorOptions(VOLUME_INDICATORS_LIST(t))
            },
            {
                value: 'oscillators',
                name: t('ribbon.indicators.oscillators'),
                icon: 'oscillators',
                options: getIndicatorOptions(OSCILLATORS_INDICATORS_LIST(t))
            },
            {
                value: 'adaptive',
                name: t('ribbon.indicators.adaptive'),
                icon: 'adaptive',
                options: getIndicatorOptions(ADAPTIVE_INDICATORS_LIST(t))
            }

            // {
            //     value: 'volatility',
            //     name: t('ribbon.indicators.volatility'),
            //     icon: 'volatility',
            //     options: getIndicatorOptions(VOLATILITY_INDICATORS_LIST(t))
            // }
        ]

        // NOTE: Here we add favorite list
        // if (favoriteIndicatorOptions.length > 0) {
        //     list.push({
        //         value: 'favorite',
        //         name: t('ribbon.indicators.favorite'),
        //         icon: 'adaptive',
        //         options: getIndicatorOptions(favoriteIndicatorOptions)
        //     })
        // }
        return list as RibbonInnerDropdownType[]
    }, [favoriteIndicatorOptions])

    return (
        <RibbonDropdown
            tooltip={t('ribbon.tooltips.indicators')}
            renderer={(close) => (
                <ContentRenderer
                    dropdowns={indicatorDropdowns}
                    close={close}
                    name={t('ribbon.indicators.indicators')}
                    favoriteIndicators={favoriteIndicators}
                />
            )}
        >
            <Button
                classNames={{ content: styles.smallButton }}
                label={t('ribbon.indicators.indicators')}
                before={<Icon name='indicators' />}
                size='compact'
                type='tetriatry-white'
            />
        </RibbonDropdown>
    )
}
export default observer(IndicatorDropdown)
