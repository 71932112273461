import { TLevelsList } from '../../drawing_interface/GraphicObjects'
import { TVeryBasicDllClass } from '../../extension_modules/DllVeryBasic'
import { TOffsStringList, TVarList } from '../../ft_types/common/OffsStringList'
import { TDLLOptionRec, TDllOptions } from '../DllOptions'
import { StrsConv } from '../../ft_types/common/StrsConv'
import TVisibleBuffersList from './VisibleIndexBufferList'
import { TOptionType } from './api/IndicatorInterfaceUnit'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'

export enum TOutputWindow {
    ow_ChartWindow = 0,
    ow_SeparateWindow = 1
}

export enum TIndFolderType {
    ft_Trend,
    ft_Oscillator,
    ft_Volume,
    ft_Volatility,
    ft_Adaptive,
    ft_Custom
}

export abstract class TIndicator extends TVeryBasicDllClass {
    protected fTimeframe!: number
    protected fOutputWindow!: TOutputWindow
    protected fMinValue!: number
    protected fMaxValue!: number
    protected fDecimals!: number

    protected fVisibleBuffers!: TVisibleBuffersList
    protected fLevels!: TLevelsList

    public DeleteFilesOnDestroy!: boolean
    public ReferenceCount!: number
    public EmptyValue!: number
    public IsSelected = false
    protected fShortName: string

    constructor(shortName: string) {
        super(shortName)
        this.fShortName = shortName

        this.fLevels = new TLevelsList()
        this.fOptions = new TDllOptions()
        this.fVisibleBuffers = new TVisibleBuffersList()

        this.DeleteFilesOnDestroy = false
        this.fOutputWindow = TOutputWindow.ow_ChartWindow
        this.EmptyValue = 0
        this.fMinValue = 0
        this.fMaxValue = 0
        this.ReferenceCount = 0
        this.fDecimals = 4
    }

    abstract ReassignInfo(Symbol: any): void

    get ShortName(): string {
        return this.fShortName
    }

    get Timeframe(): number {
        return this.fTimeframe
    }

    get OutputWindow(): TOutputWindow {
        return this.fOutputWindow
    }

    get VisibleBuffers(): TVisibleBuffersList {
        return this.fVisibleBuffers
    }

    get levels(): TLevelsList {
        return this.fLevels
    }

    get MinValue(): number {
        return this.fMinValue
    }

    get MaxValue(): number {
        return this.fMaxValue
    }

    get decimals(): number {
        return this.fDecimals
    }

    // IndicatorShortName(name: string): void {
    //   this.fShortName = name;
    // }

    public GetImportantOptions(): string {
        let result = ''
        for (let i = 0; i < this.fOptions.count; i++) {
            const rec: TDLLOptionRec = this.fOptions[i]
            if (![TOptionType.ot_LineStyle, TOptionType.ot_Separator].includes(rec.OptionType) && !rec.invisible) {
                result += `${rec.Value};`
            }
        }
        result = StrsConv.DelSymbolsRight(result, 1)
        return result
    }

    public SetImportantOptions(params: string): void {
        let rec: TDLLOptionRec
        let s: string
        let i: number

        for (i = 0; i < this.fOptions.count; i++) {
            rec = this.fOptions[i]
            // In TypeScript, 'in' operator is used to check if a property exists on an object, not to check if a value is in an array.
            // Therefore, we need to use Array.includes() method for the correct behavior.
            if (![TOptionType.ot_LineStyle, TOptionType.ot_Separator].includes(rec.OptionType) && !rec.invisible) {
                ;[s, params] = StrsConv.GetSubStr(params, ';', false)
                try {
                    rec.SetValue(s)
                } catch (error) {
                    if (DebugUtils.DebugMode) {
                        StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
                    }
                    this.Print(`Can not assign value "${s}" to option "${rec.name}" in indicator "${this.ShortName}"`)
                    return
                }
            }
        }
    }

    VisibleBuffersInDataWindow(): number {
        let result = 0
        for (let i = 0; i < this.VisibleBuffers.length; i++) {
            if (this.VisibleBuffers[i].name !== '') {
                result++
            }
        }
        return result
    }

    public SaveToList(list: TOffsStringList): void {
        list.OpenSection('Indicator')

        const vars = new TVarList()
        const list1 = new TOffsStringList()
        try {
            // save info
            vars.AddVarStr('LibName', this.fLibName)
            vars.AddVarStr('ShortName', this.fShortName)
            vars.AddVarStr('NameAndParams', this.GetNameWithParams())
            vars.SaveToList(list, 'Info')

            // save options
            this.fOptions.SaveToList(list1)
            list.AddList('Options', list1)
        } catch (error) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
        }

        list.CloseSection()
    }

    public GetNameWithParams(): string {
        let result = `${this.ShortName} (`
        let cnt = 0
        for (let i = 0; i < this.fOptions.length; i++) {
            const rec: TDLLOptionRec = this.fOptions[i]
            if (![TOptionType.ot_LineStyle, TOptionType.ot_Separator].includes(rec.OptionType) && !rec.invisible) {
                result += `${rec.Value}, `
                cnt++
            }
        }
        result = StrsConv.DelSymbolsRight(result, 2)
        if (cnt > 0) {
            result += ')'
        }
        return result
    }

    ExportData(edit?: boolean): void {}

    ImportData(): void {}
}
