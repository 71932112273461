import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { TBarChunk } from '@fto/lib/ft_types/data/chunks/BarChunk'
import { TChunkStatus } from '@fto/lib/ft_types/data/chunks/ChunkEnums'
import TDownloadableChunk from '@fto/lib/ft_types/data/chunks/DownloadableChunk/DownloadableChunk'
import { TBaseTickChunk } from '@fto/lib/ft_types/data/chunks/TickChunks/BaseTickChunk'
import { WorkerPool } from '@fto/lib/ft_types/data/data_downloading/WorkerPool'
import { WorkerWrapper } from '@fto/lib/ft_types/data/data_downloading/WorkerWrapper'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import InvalidDataError from '../data_errors/InvalidDataError'
import { TDataRecordWithDate } from '../DataClasses/TDataRecordWithDate'

interface IDownloadTask {
    url: string
    chunk: TDownloadableChunk<TDataRecordWithDate>
}

export class DownloadTaskQueue {
    private _maxFutureTicks = 10
    //if we have a task here, then it will definitely be downloaded
    private downloadQueueNotLoadingYet: IDownloadTask[] = []
    private workerPool: WorkerPool
    private static currentId = 0

    private _waitingDataChunksReference: Map<string, TDownloadableChunk<TDataRecordWithDate>> = new Map()

    private _futureTickChunks: TBaseTickChunk[] = []
    private _lastDownloadedChunk: TBaseTickChunk | TBarChunk | null = null

    constructor() {
        this.workerPool = new WorkerPool(8, '/workers/ChunkDownloadWorkerMSGPACK_Timeout.js')
    }

    public isEmpty(): boolean {
        return this._waitingDataChunksReference.size === 0 && this.downloadQueueNotLoadingYet.length === 0
    }

    public getLastLoadedChunk(): TBaseTickChunk | TBarChunk | null {
        return this._lastDownloadedChunk
    }

    //TODO: do we use the return value anywhere?
    public onLastTickTimeChanged(date: TDateTime) {
        const t = this._futureTickChunks.filter((chunk) => {
            return chunk.LastPossibleDate < date
        })
        if (t.length > 0) {
            this._futureTickChunks = this._futureTickChunks.filter((chunk) => {
                return chunk.LastPossibleDate < date
            })
        }

        if (this._futureTickChunks.length < this._maxFutureTicks) {
            const symbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol('EURUSD')
            let nextDate: TDateTime = date
            if (this._futureTickChunks.length > 0) {
                nextDate = this._futureTickChunks[this._futureTickChunks.length - 1].LastPossibleDate
            }

            const nextTickChunk = symbolData?.fTickData.fTicks.GetOrCreateNextChunk(nextDate)
            if (nextTickChunk) {
                this._futureTickChunks.push(nextTickChunk)

                nextTickChunk.EnsureDataIsPresentOrDownloading()
            }
        }
    }

    private getNextBarChunkForLoading(): TBarChunk | null {
        let result: TBarChunk | null = null

        const activeChart = GlobalChartsController.Instance.getActiveChart()
        const visibleBars: TBarChunk[] = []
        for (const chunk of this.downloadQueueNotLoadingYet) {
            if (chunk instanceof TBarChunk && activeChart?.IsVisibleBarChunk(chunk)) {
                visibleBars.push(chunk)
            }
        }

        if (visibleBars.length === 0) {
            const charts = GlobalChartsController.Instance.getAllCharts()
            for (const chart of charts) {
                for (const chunk of this.downloadQueueNotLoadingYet) {
                    if (chunk instanceof TBarChunk && chart.IsVisibleBarChunk(chunk)) {
                        visibleBars.push(chunk)
                    }
                }
            }
        }

        result = this.getRightVisibleBarChunk(visibleBars)

        return result
    }

    private getRightVisibleBarChunk(visibleChunks: TBarChunk[]): TBarChunk | null {
        let result: TBarChunk | null = null
        for (const chunk of visibleChunks) {
            if (result === null) {
                if (chunk.Status === TChunkStatus.cs_InQueue) {
                    result = chunk
                }
            } else {
                if (chunk.Status === TChunkStatus.cs_InQueue && result.FirstDate < chunk.FirstDate) {
                    result = chunk
                }
            }
        }
        return result
    }

    private downloadDataForChunk(chunk: TDownloadableChunk<TDataRecordWithDate>, isImportant: boolean): boolean {
        if (!this.isChunkInQueueOrWaiting(chunk) && chunk.Status === TChunkStatus.cs_Empty) {
            const url: string = chunk.GetChunkUrl()
            if (isImportant) {
                this.downloadQueueNotLoadingYet.unshift({
                    url: url,
                    chunk: chunk
                })
            } else {
                this.downloadQueueNotLoadingYet.push({ url: url, chunk: chunk })
            }
            chunk.Status = TChunkStatus.cs_InQueue
            this._waitingDataChunksReference.set(url, chunk)
            return true
        } else {
            DebugUtils.warnTopic(
                ELoggingTopics.lt_ChunkLoading,
                'Chunk is already in the queue or waiting',
                chunk.FirstDate,
                chunk.DName
            )
            return false
        }
    }

    private isChunkInQueueOrWaiting(chunk: TDownloadableChunk<TDataRecordWithDate>): boolean {
        return this.IsInWaiting(chunk) || this.IsInQueue(chunk)
    }

    private IsInQueue(chunk: TDownloadableChunk<TDataRecordWithDate>): boolean {
        for (let i = 0; i < this.downloadQueueNotLoadingYet.length; i++) {
            const value = this.downloadQueueNotLoadingYet[i]
            const chunkUrl = chunk.GetChunkUrl()
            const taskUrl = value.url
            if (chunkUrl === taskUrl) {
                return true
            }
        }

        return false
    }

    private IsInWaiting(chunk: TDownloadableChunk<TDataRecordWithDate>): boolean {
        for (let [key, value] of this._waitingDataChunksReference) {
            const chunkUrl = chunk.GetChunkUrl()
            const taskUrl = key
            if (chunkUrl === taskUrl) {
                return true
            }
        }

        return false
    }

    public addTaskIfNecessary(chunk: TDownloadableChunk<TDataRecordWithDate>, forceLoading: boolean): void {
        switch (chunk.Status) {
            case TChunkStatus.cs_InQueue:
            case TChunkStatus.cs_Loaded: {
                //do nothing
                return
            }
            case TChunkStatus.cs_Empty: {
                //it is ok, let's go further
                break
            }
            case TChunkStatus.cs_Building:
            case TChunkStatus.cs_PartiallyFilled:
            case TChunkStatus.cs_InvalidDataOnServer: {
                // debugger
                throw new StrangeError(`addTask - invalid status to add task, status: ${chunk.Status}`)
            }
            default: {
                throw new StrangeError('addTask - unknown chunk status')
            }
        }

        if (forceLoading) {
            this.downloadDataForChunk(chunk, true)
        } else {
            if (chunk instanceof TBarChunk) {
                this.downloadDataForChunk(chunk, true)
            } else {
                //this is a tick chunk
                if (chunk.LastPossibleDate > GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true)) {
                    this.downloadDataForChunk(chunk, false)
                } else {
                    const symbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(
                        chunk.DataDescriptor.symbolName
                    )
                    const prevTickChunk = symbolData.fTickData.fTicks.GetOrCreatePrevChunk(
                        GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true)
                    )
                    if (prevTickChunk && prevTickChunk.isEqual(chunk)) {
                        this.downloadDataForChunk(chunk, false)
                    } else {
                        DebugUtils.logTopic(
                            ELoggingTopics.lt_ChunkLoading,
                            'Skip loading previous tick chunk',
                            DateUtils.DF(chunk.FirstDate)
                        )
                    }
                }
            }
        }

        this.tryExecuteNextTask()
    }

    public onVisibleRangeChanged(startVisibleDate: TDateTime, endVisibleDate: TDateTime) {
        // this.startVisibleDate = startVisibleDate
        // this.endVisibleDate = endVisibleDate
    }

    public onTimeframeChanged(newTimeframe: number) {
        // this._currentTimeframe = newTimeframe
    }

    private onLoadComplete(url: string, downloadedData: any) {
        DebugUtils.logTopic(ELoggingTopics.lt_ChunkLoading, 'onLoadComplete for', url)
        const chunk = this._waitingDataChunksReference.get(url)

        if (chunk) {
            DebugUtils.logTopic(ELoggingTopics.lt_ChunkLoading, 'Chunk found:', chunk.DName, chunk.FirstDate)
            if (chunk instanceof TBaseTickChunk) {
                this._lastDownloadedChunk = chunk as TBaseTickChunk
            } else if (chunk instanceof TBarChunk) {
                this._lastDownloadedChunk = chunk as TBarChunk
            } else {
                this._lastDownloadedChunk = null
                throw new StrangeError(`Unknown chunk type - onLoadComplete`)
            }

            chunk.ImportChunkData(downloadedData, chunk.LoadingDataFormat)

            this._waitingDataChunksReference.delete(url)
        } else {
            throw new StrangeError(`Data for chunk was downloaded, but no chunk was found in the queue`)
        }
        //GlobalChartsController.Instance.onChunkDownloaded() - this will be done by event from SymbolList
    }

    private static getNextId(): number {
        return this.currentId++
    }

    public static generateUniqueId(): string {
        const now = performance.now().toString().replace('.', '')
        return `${now}${DownloadTaskQueue.getNextId()}`
    }

    private tryExecuteNextTask(): void {
        if (this.downloadQueueNotLoadingYet.length > 0) {
            const freeWorker: WorkerWrapper | undefined = this.workerPool.getFreeWorker()
            if (freeWorker) {
                let task: IDownloadTask | undefined
                const nextBarForLoading = this.getNextBarChunkForLoading()
                if (nextBarForLoading) {
                    //we need to download right visible bar in priority
                    const rightBarChunkTask = { url: nextBarForLoading.GetChunkUrl(), chunk: nextBarForLoading }
                    //remove it from the download queue if it is there
                    this.downloadQueueNotLoadingYet = this.downloadQueueNotLoadingYet.filter(
                        (t) => t.url !== rightBarChunkTask.url
                    )
                    task = rightBarChunkTask
                } else {
                    task = this.downloadQueueNotLoadingYet.shift()
                }
                if (task) {
                    //we found a waiting task and let's download it
                    freeWorker.executeTask(task.url, (message: MessageEvent, worker: WorkerWrapper) => {
                        try {
                            if (message.data && typeof message.data === 'object') {
                                const { downloadedData } = message.data
                                if (downloadedData) {
                                    this.onLoadComplete(message.data.url, downloadedData)
                                    message.data.downloadedData = null
                                } else {
                                    message.data.downloadedData = null
                                    worker.free()
                                    throw new InvalidDataError('No expected data in the message')
                                }
                            } else {
                                message.data.downloadedData = null
                                worker.free()
                                throw new InvalidDataError('Incorrect data format in the message')
                            }
                            message.data.downloadedData = null
                            worker.free()
                            this.tryExecuteNextTask()
                        } catch (error) {
                            DebugUtils.errorTopic(
                                ELoggingTopics.lt_ChunkLoading,
                                'Error during DOWNLOAD task execution:',
                                error
                            )
                            message.data.downloadedData = null
                            worker.free()
                            // We will try to execute this task once more later since it is still in the queue
                        }
                    })
                }
            }
        }
    }
}
