const textWidthCache: { [key: string]: number } = {}

function normalizeText(text: string): string {
    return text
        .replace(/[a-zA-Z]/g, 'x') // Replace all letters with 'x'
        .replace(/\d/g, '0') // Replace all digits with '0'
        .replace(/[^\w\s.]/g, 'x') // Replace special characters with 'x', keep dots as is
}

export function getTextWidth(
    ctx: CanvasRenderingContext2D,
    text: string,
    font: string,
    needNormalize: boolean = false
): number {
    const normalizedText = needNormalize ? normalizeText(text) : text
    const cacheKey = `${font}_${normalizedText}`
    if (textWidthCache[cacheKey] !== undefined) {
        return textWidthCache[cacheKey]
    }
    ctx.font = font
    const width = ctx.measureText(normalizedText).width
    textWidthCache[cacheKey] = width
    return width
}
