import { FiboChannelJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { IGPPen } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { TOffsStringList } from '../../../ft_types/common/OffsStringList'
import { NotImplementedError } from '../../../utils/common_utils'
import { PaintToolNames } from '../PaintToolNames'
import { TPaintToolType } from '../PaintToolsAuxiliaryClasses'
import { TFiboPaintTool } from './ptFibo'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TPoint } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { TChart } from '../../chart_classes/BasicChart'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TPtFiboChannel extends TFiboPaintTool {
    private fRays: boolean

    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptFiboChannel)
        this.ShortName = 'Fibo Channel'
        this.fToolType = TPaintToolType.tt_Line
        this.fMaxPoints = 3
        this.fClosedPolygon = false
        this.CursorStyle = CustomCursorPointers.crCursorFiboChannel
        this.fRays = true
        this.icon = 55
        const fibo: number[] = [38.2, 61.8, 100, 161.8, 261.8]
        this.SetLevels(fibo, 3)

        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptFiboChannel)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptFiboChannel)
        }

        if (!styles) throw new StrangeError('Default styles for FiboChannel are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
        this.fRays = styles.rays
        this.pen = new IGPPen(this.fLineStyle.color, this.fLineStyle.width)
        this.pen.setPenStyle(this.fLineStyle.style)
        this.fLevels.ImportFromStr(styles.levels)
    }

    clone(): TPtFiboChannel {
        const cloneObj = new TPtFiboChannel(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        cloneObj.fRays = this.fRays
        return cloneObj
    }

    toJson(): FiboChannelJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            fRays: this.fRays,
            pen: {
                color: this.pen.color,
                width: this.pen.width,
                style: this.pen.getPenStyleFromPattern(),
                opacity: this.pen.opacity,
                dashStyle: this.pen.dashStyle
            }
        }
    }

    fromJSON(json: FiboChannelJSON) {
        super.fromJSON(json)
        this.fRays = json.fRays
        this.pen = new IGPPen(json.pen.color, json.pen.width)
        this.pen.setPenStyle(json.pen.style)
    }

    public static LoadFromList(list: TOffsStringList, all: boolean = true): void {
        throw new NotImplementedError()
    }

    public static SaveToList(list: TOffsStringList, all: boolean = true): void {
        throw new NotImplementedError()
    }

    public Paint(): void {
        let i: number
        let x1: number, x2: number, y1: number, y2: number, x3: number, y3: number, p1: number, p2: number
        let price1: number, price2: number, price3: number, dy: number, dp: number

        //----- Paint line --------------------------------------
        const PaintLine = (p1: number, p2: number, dp: number, pen: IGPPen = this.pen): void => {
            ;[x1, y1, x2, y2] = this.GetLineCoords(p1, p2, dp, x1, y1, x2, y2)

            if (!this.fChart.IsLineVisible(x1, y1, x2, y2)) return

            const canvas = this.fChart.GdiCanvas
            canvas.MoveTo(x1, y1)
            canvas.LineTo(x2, y2, pen)
        }

        try {
            if (this.fPoints.Count < 2) return

            p1 = 0
            p2 = 1
            if (this.fPoints.Item(1).time < this.fPoints.Item(0).time) {
                ;[p1, p2] = [p2, p1] // exchange p1 and p2
            }

            // get central lines coords
            ;[x1, y1, price1] = this.GetPointInfo(p1)
            ;[x2, y2, price2] = this.GetPointInfo(p2)

            // distance to the 3rd point
            ;[x3, y3, price3] = this.GetPointInfo(2)
            dy = price3 - price1 - ((price2 - price1) / (x2 - x1)) * (x3 - x1)
            if (this.fHighlighted) {
                const points = [
                    new TPoint(this.fPoints[0].x, this.fPoints[0].y),
                    new TPoint(this.fPoints[1].x, this.fPoints[1].y)
                ]
                this.PaintHoverLine(points)
            }
            PaintLine(p1, p2, 0)

            if (x1 === x2 || this.fPoints.Count < 3) return

            PaintLine(p1, p2, dy)

            // paint levels
            for (i = 0; i < this.fLevels.Count; i++) {
                const level = this.fLevels.Item(i)

                if (level.isActive) {
                    // this.SetPen(this.fLevels.Item(i).style, true)
                    dp = dy * (1 + level.value / 100)
                    PaintLine(p1, p2, dp, level.pen)
                }
            }
        } finally {
            this.PaintMarkers()
        }
    }

    public get visible(): boolean {
        return true
    }

    public assign(tool: TPtFiboChannel, isCopy: boolean = false): void {
        super.assign(tool, isCopy)

        this.fLevels.Assign(tool.fLevels)
        this.fRays = tool.fRays
        this.pen = tool.pen
    }

    getLineStyleParams(): {
        color: {
            value: TLineStyle['color']
            hasDifferentValues: boolean
        }
        style: {
            value: TLineStyle['style']
            hasDifferentValues: boolean
        }
        width: {
            value: TLineStyle['width']
            hasDifferentValues: boolean
        }
    } {
        return {
            color: {
                value: this.pen.color,
                hasDifferentValues: false
            },
            width: {
                value: this.pen.width,
                hasDifferentValues: false
            },
            style: {
                value: this.pen.getPenStyleFromPattern(),
                hasDifferentValues: false
            }
        }
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        this.chart.ChartWindow.saveStateWithNotify()

        const { color, style, width } = styles
        this.pen = new IGPPen(color, width)
        this.pen.setPenStyle(style)

        this.saveToManager()
        this.updatedToolAndLinkedTools()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }
    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            lineStyle: {
                key: 'lineStyle',
                value: {
                    ...this.pen,
                    style: this.pen.getPenStyleFromPattern()
                },
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            shouldShowRays: {
                value: this.fRays,
                type: 'checkbox',
                key: 'shouldShowRays',
                label: 'toolsModal.fields.rays'
            },
            levels: {
                value: this.fLevels.map((level) => ({
                    id: level.id,
                    value: level.value,
                    text: level.text,
                    isActive: level.isActive,
                    style: level.pen.getPenStyleFromPattern(),
                    color: level.pen.color,
                    width: level.pen.width,
                    opacity: level.pen.opacity
                })),
                type: 'levels',
                key: 'levels',
                label: 'levels'
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptFiboChannel,
            toolName: 'fiboChannel'
        })
    }

    ImportFromDialog(): void {
        const { resetToolSettings, getKeyValueData } = GraphToolStore // Use the store/context

        const formattedToolSettings = getKeyValueData()

        //@ts-ignore
        const { description, shouldShowRays, lineStyle, levels } = formattedToolSettings

        this.chart.ChartWindow.saveStateWithNotify()

        this.description = description

        this.fRays = shouldShowRays

        this.pen = new IGPPen(lineStyle.color, lineStyle.width)
        this.pen.setPenStyle(lineStyle.style)

        // SET UP LEVELS

        this.updateLevelsFromModal(levels)

        this.saveToManager()

        resetToolSettings()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptFiboChannel, {
            lineStyle: new TLineStyle(
                this.pen.color,
                this.pen.getPenStyleFromPattern(),
                this.pen.width
            ).getSerialized(),
            rays: this.fRays,
            toolName: PaintToolNames.ptFiboChannel,
            levels: this.fLevels.ExportToStr(5)
        })
    }

    public EdgeUnderMouse(x: number, y: number): number {
        let x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, p1: number, p2: number
        let price1: number, price2: number, price3: number, dp: number

        p1 = 0
        p2 = 1
        if (this.fPoints[1].time < this.fPoints[0].time) {
            ;[p1, p2] = [p2, p1] // exchange(p1, p2);
        }

        ;[x1, y1, price1] = this.GetPointInfo(p1)
        ;[x2, y2, price2] = this.GetPointInfo(p2)
        ;[x3, y3, price3] = this.GetPointInfo(2)

        try {
            dp = price3 - price1 - ((price2 - price1) / (x2 - x1)) * (x3 - x1)
        } catch {
            dp = 0
        }

        this.GetLineCoords(p1, p2, 0, x1, y1, x2, y2)
        if (this.PointAboveTheLine(x, y, x1, y1, x2, y2)) {
            return 0
        }

        this.GetLineCoords(p1, p2, dp, x1, y1, x2, y2)
        if (this.PointAboveTheLine(x, y, x1, y1, x2, y2)) {
            return 1
        }

        return -1
    }

    private GetLineCoords(
        p1: number,
        p2: number,
        dp: number,
        x1: number,
        y1: number,
        x2: number,
        y2: number
    ): [number, number, number, number] {
        let price1: number, price2: number
        ;[x1, y1, price1] = this.GetPointInfo(p1)
        ;[x2, y2, price2] = this.GetPointInfo(p2)
        if (dp !== 0) {
            price1 += dp
            price2 += dp
            y1 = this.fChart.GetY(price1)
            y2 = this.fChart.GetY(price2)
        }

        if (this.fRays) {
            const rayCoords = this.fChart.GetRayCoords(x1, y1, x2, y2, price1, price2)
            ;[x1, y1, x2, y2] = rayCoords as unknown as [number, number, number, number]
        }

        return [x1, y1, x2, y2]
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptFiboChannel, TPtFiboChannel)
