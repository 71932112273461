import { redirect } from 'react-router-dom'
import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY } from '@root/constants/localStorageKeys'
import { AUTH_ROUTE, BASE_ROUTE, CHECKOUT_PAGE, DASHBOARD_ROUTE, LOGIN_ROUTE, makeRoute } from '@root/constants/routes'
import store from '@root/store/index'
import { LOGOUT } from '@root/store/auth/auth.slice'
import { LOOKUP } from '@root/store/auth/auth.actions'

const redirectLogin = (request: Request) => {
    const redirectUrl = new URL(request.url).pathname.substring(1)
    const searchParams = request.url.split('?')[1]

    const loginUrl = `${makeRoute(LOGIN_ROUTE)}${searchParams ? `?${searchParams}` : ''}`

    if (redirectUrl === `${AUTH_ROUTE}/` || redirectUrl === AUTH_ROUTE) {
        return redirect(loginUrl)
    }

    if (redirectUrl.includes(AUTH_ROUTE)) {
        return null
    }
    return redirect(loginUrl)
}

export const secureBaseRouteLoader = async ({ request }: { request: Request }) => {
    const { isAuthenticated, userIsLoaded } = store.getState().auth

    if (!isAuthenticated) {
        return redirectLogin(request)
    }
    try {
        if (!userIsLoaded) {
            await store.dispatch(LOOKUP())
        }

        return secureRouteLoader({ request })
    } catch (e) {
        store.dispatch(LOGOUT())
        return redirectLogin(request)
    }
}

export const secureRouteLoader = ({ request }: { request: Request }) => {
    const { isAuthenticated, user } = store.getState().auth
    if (!isAuthenticated) {
        return redirectLogin(request)
    }
    if (isAuthenticated && user) {
        const loadedUrl = new URL(request.url).pathname.substring(1)
        const isNewUser = user.subscriptionStatus === 'Unknown' && user.subscriptionId === null
        if (isNewUser && loadedUrl !== CHECKOUT_PAGE) {
            return redirect(makeRoute(CHECKOUT_PAGE))
        }

        if (loadedUrl && loadedUrl !== BASE_ROUTE && !loadedUrl.includes(AUTH_ROUTE)) {
            return null
        }

        const searchParams = request.url.split('?')[1]

        return redirect(`${makeRoute(DASHBOARD_ROUTE)}${searchParams ? `?${searchParams}` : ''}`)
    }
    return null
}

export const unSecureRouteLoader = ({ request }: { request: Request }) => {
    const { isAuthenticated, userIsLoaded } = store.getState().auth
    if (!isAuthenticated) {
        return redirectLogin(request)
    }
    if (isAuthenticated && userIsLoaded) {
        const loadedUrl = new URL(request.url).pathname.substring(1)
        if (loadedUrl && loadedUrl !== BASE_ROUTE && !loadedUrl.includes(AUTH_ROUTE)) {
            return null
        }
        const searchParams = request.url.split('?')[1]

        return redirect(`${makeRoute(DASHBOARD_ROUTE)}${searchParams ? `?${searchParams}` : ''}`)
    }
    return null
}

export const saveAuthToken = (result: any) => {
    localStorage.setItem(AUTH_TOKEN_KEY, result.idToken)
    localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, result.refreshToken)
}
