import styles from '@root/pages/Dashboard/components/TableSearchBar/table.search-bar.module.scss'
import { Icon } from '@fto/icons'
import { addModal, Button } from '@fto/ui'
import React, { FC } from 'react'
import classNames from 'classnames'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useTranslation } from 'react-i18next'

type Props = React.HTMLAttributes<HTMLButtonElement>

const CreateNewProjectButton: FC<Props> = ({ className }) => {
    const { t } = useTranslation()
    const handleCreate = () => {
        addModal(MODAL_NAMES.projects.create)
    }

    return (
        <Button
            onClick={handleCreate}
            classNames={{
                root: classNames(className, styles.newProjectButton),
                content: styles.newProjectButtonContent
            }}
            before={<Icon size={14} color={'white'} name={'plus'} />}
            type={'primary'}
            label={t('dashboard.newProject')}
            size='small'
        />
    )
}

export default CreateNewProjectButton
