import { SymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/SymbolCalculationStrategy'
import { TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'

export class USDQouteSymbolCalculationStrategy extends SymbolCalculationStrategy {
    public getPointCostForOneStandardLot(operationType: TTradePositionType): number {
        return this.symbolData.symbolInfo.lot * this.symbolData.symbolInfo.MinPoint
    }

    public calculateMarginRequirementForSymbol(
        operationType: TTradePositionType,
        openPrice: number | undefined
    ): number {
        const priceForMarginCalculation = openPrice || this.symbolData.getCurrentOpenPriceByOrderType(operationType)

        return (priceForMarginCalculation / this.symbolData.symbolInfo.CreditShoulder) * this.symbolData.symbolInfo.lot
    }
}
