import { ChartInfoType } from '@fto/chart_components/types/chartTypes'
import { FilenameTypes } from '@fto/icons'

export const LAYOUTS: {
    label: string
    value: ChartInfoType
    icon: (typeof FilenameTypes)[number]
    localeKey: string
}[] = [
    {
        label: 'one-chart',
        value: {
            count: 1,
            layout: 'one-chart',
            symbol: 'EURUSD'
        },
        icon: 'one-chart',
        localeKey: 'oneChart'
    },
    {
        label: 'two-charts-horizontally',
        value: {
            count: 2,
            layout: 'two-charts-horizontally',
            symbol: 'EURUSD'
        },
        icon: 'two-charts-horizontally',
        localeKey: 'twoChartsHorizontally'
    },
    {
        label: 'two-charts-vertically',
        value: {
            count: 2,
            layout: 'two-charts-vertically',
            symbol: 'EURUSD'
        },
        icon: 'two-charts-vertically',
        localeKey: 'twoChartsVertically'
    },
    {
        label: 'three-charts-horizontally',
        value: {
            count: 3,
            layout: 'three-charts-horizontally',
            symbol: 'EURUSD'
        },
        icon: 'three-charts-horizontally',
        localeKey: 'threeChartsHorizontally'
    },
    {
        label: 'three-charts-vertically',
        value: {
            count: 3,
            layout: 'three-charts-vertically',
            symbol: 'EURUSD'
        },
        icon: 'three-charts-vertically',
        localeKey: 'threeChartsVertically'
    },
    {
        label: 'four-charts-quadrant',
        value: {
            count: 4,
            layout: 'four-charts-quadrant',
            symbol: 'EURUSD'
        },
        icon: 'four-charts-quadrant',
        localeKey: 'fourCharts'
    },
    {
        label: 'one-top-two-bottom',
        value: {
            count: 3,
            layout: 'one-top-two-bottom',
            symbol: 'EURUSD'
        },
        icon: 'one-top-two-bottom',
        localeKey: 'oneTopTwoBottom'
    },
    {
        label: 'two-top-one-bottom',
        value: {
            count: 3,
            layout: 'two-top-one-bottom',
            symbol: 'EURUSD'
        },
        icon: 'two-top-one-bottom',
        localeKey: 'twoTopOneBottom'
    },
    {
        label: 'one-left-two-right',
        value: {
            count: 3,
            layout: 'one-left-two-right',
            symbol: 'EURUSD'
        },
        icon: 'one-left-two-right',
        localeKey: 'oneLeftTwoRight'
    },
    {
        label: 'two-left-one-right',
        value: {
            count: 3,
            layout: 'two-left-one-right',
            symbol: 'EURUSD'
        },
        icon: 'two-left-one-right',
        localeKey: 'twoLeftOneRight'
    },
    {
        label: 'four-charts-horizontal',
        value: {
            count: 4,
            layout: 'four-charts-horizontal',
            symbol: 'EURUSD'
        },
        icon: 'four-charts-horizontal',
        localeKey: 'fourChartsHorizontal'
    },
    {
        label: 'four-charts-vertical',
        value: {
            count: 4,
            layout: 'four-charts-vertical',
            symbol: 'EURUSD'
        },
        icon: 'four-charts-vertical',
        localeKey: 'fourChartsVertical'
    },
    {
        label: 'four-charts-one-top-three-bottom',
        value: {
            count: 4,
            layout: 'four-charts-one-top-three-bottom',
            symbol: 'EURUSD'
        },
        icon: 'four-charts-one-top-three-bottom',
        localeKey: 'fourChartsOneTopThreeBottom'
    },
    {
        label: 'four-charts-three-rows',
        value: {
            count: 4,
            layout: 'four-charts-three-rows',
            symbol: 'EURUSD'
        },
        icon: 'four-charts-three-rows',
        localeKey: 'fourChartsThreeRows'
    }
]
