import { FiboArcJson } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { TOffsStringList, TVarList } from '../../../ft_types/common/OffsStringList'
import { TChartScale } from '../../ChartBasicClasses'
import { TBasicPaintTool } from '../BasicPaintTool'
import { PaintToolNames } from '../PaintToolNames'
import { TPaintToolType } from '../PaintToolsAuxiliaryClasses'
import { TFiboPaintTool } from './ptFibo'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TPoint } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { TChart } from '../../chart_classes/BasicChart'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TPtFiboArc extends TFiboPaintTool {
    fClosedEllipse: boolean

    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptFiboArc)

        this.fToolType = TPaintToolType.tt_Line
        this.fMaxPoints = 2
        this.fClosedPolygon = false
        this.CursorStyle = CustomCursorPointers.crCursorFiboArc
        this.fClosedEllipse = false
        this.icon = 53
        this.SetLevels([38.2, 50, 61.8], 3)

        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptFiboArc)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptFiboArc)
        }

        if (!styles) throw new StrangeError('Default styles for FiboArc are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
        this.fLevels.ImportFromStr(styles.levels)
    }

    clone(): TPtFiboArc {
        const cloneObj = new TPtFiboArc(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        cloneObj.fClosedEllipse = this.fClosedEllipse
        return cloneObj
    }

    toJson(): FiboArcJson {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            ClosedEllipse: this.fClosedEllipse
        }
    }

    fromJSON(json: FiboArcJson) {
        super.fromJSON(json)
        this.fClosedEllipse = json.ClosedEllipse
    }

    public Paint(): void {
        if (this.fHighlighted) {
            const points = [
                new TPoint(this.fPoints[0].x, this.fPoints[0].y),
                new TPoint(this.fPoints[1].x, this.fPoints[1].y)
            ]
            this.PaintHoverLine(points)
        }
        super.Paint()

        if (this.fPoints.length < 2) return

        const canvas = this.fChart.GdiCanvas
        const x1 = this.fPoints[0].x
        const y1 = this.fPoints[0].y
        const x2 = this.fPoints[1].x
        const y2 = this.fPoints[1].y
        const price1 = this.fPoints[0].price
        const price2 = this.fPoints[1].price

        const scale: TChartScale = this.fChart.ChartOptions.GetScaleInfo()
        const MinPoint = 1 / Math.pow(10, this.fChart.ScaleDecimals())
        let dx = x2 - x1
        let dy = Math.round((price2 - price1) / MinPoint)
        let len = dx * dx + dy * dy
        if (len > 0) len = Math.sqrt(len)

        for (let i = 0; i < this.fLevels.length; i++) {
            const level = this.fLevels[i]

            if (!level.isActive) {
                continue
            }

            const flen = (len * level.value) / 100
            dx = Math.round(flen)
            const ex1 = Math.round(x2 - dx)
            const ex2 = Math.round(x2 + dx)
            const ey1 = this.fChart.GetY(price2 - flen * MinPoint)
            const ey2 = this.fChart.GetY(price2 + flen * MinPoint)

            //TODO: check if the color is in BGR or RGB value
            // canvas.setFontColor(this.fLevels[i].style.color & 0xFFFFFF);
            const s = level.text

            if (y1 < y2) {
                if (this.fClosedEllipse) {
                    canvas.arc(ex1, ey1, ex2, ey2, ex1, y2, ex2, y2, false, true, level.pen)
                } else {
                    canvas.arc(ex1, ey1, ex2, ey2, ex1, y2, ex2, y2, false, false, level.pen)
                }
                canvas.textOut(x2 - canvas.TextWidth(s) / 2, ey2 - canvas.TextHeight('0'), s, this.font, level.brush)
            } else {
                if (this.fClosedEllipse) {
                    canvas.arc(ex1, ey1, ex2, ey2, ex1, y2, ex2, y2, false, true, level.pen)
                } else {
                    canvas.arc(ex1, ey1, ex2, ey2, ex1, y2, ex2, y2, true, false, level.pen)
                }
                canvas.textOut(
                    x2 - canvas.TextWidth(s, this.font) / 2,
                    ey1 - canvas.TextHeight('0', this.font),
                    s,
                    this.font,
                    level.brush
                )
            }
        }
    }

    public get visible(): boolean {
        return true
    }

    public assign(tool: TBasicPaintTool, isCopy = false): void {
        super.assign(tool, isCopy)
        // Assuming TPtFiboArc is a subclass of TBasicPaintTool and has fLevels and fClosedEllipse members
        if (tool instanceof TPtFiboArc) {
            this.fLevels.Assign(tool.fLevels)
            this.fClosedEllipse = tool.fClosedEllipse
        } else {
            throw new StrangeError('Invalid tool type. Expected TPtFiboArc.')
        }
    }

    public saveToList(list: TOffsStringList, all = true): void {
        super.SaveToList(list, all)

        const vars = new TVarList()
        vars.AddVarStr('Levels', this.fLevels.ExportToStr(3))
        vars.AddVarBool('ClosedEllipse', this.fClosedEllipse)
        vars.SaveToList(list, '')
        vars.Clear()
    }

    loadFromList(list: TOffsStringList, all = true): void {
        super.LoadFromList(list, all)

        const vars = new TVarList()
        try {
            vars.LoadFromList(list)
            this.fLevels.ImportFromStr(vars.GetValue('Levels'))
            this.fClosedEllipse = vars.GetBool('ClosedEllipse')
        } finally {
            vars.Clear()
        }
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            levels: {
                value: this.fLevels.map((level) => ({
                    id: level.id,
                    value: level.value,
                    text: level.text,
                    isActive: level.isActive,
                    style: level.pen.getPenStyleFromPattern(),
                    color: level.pen.color,
                    width: level.pen.width,
                    opacity: level.pen.opacity
                })),
                type: 'levels',
                key: 'levels',
                label: 'levels'
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptFiboArc,
            toolName: 'fiboArc'
        })
    }

    ImportFromDialog(): void {
        const { resetToolSettings, getKeyValueData } = GraphToolStore // Use the store/context

        const formattedToolSettings = getKeyValueData()

        //@ts-ignore
        const { description, lineStyle, levels } = formattedToolSettings

        this.chart.ChartWindow.saveStateWithNotify()

        this.description = description
        this.fLineStyle = lineStyle

        this.updateLevelsFromModal(levels)

        this.saveToManager()

        resetToolSettings()
    }
    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }
    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptFiboArc, {
            lineStyle: this.fLineStyle.getSerialized(),
            toolName: PaintToolNames.ptFiboArc,
            levels: this.fLevels.ExportToStr(5)
        })
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptFiboArc, TPtFiboArc)
