import { FC, useCallback, useMemo } from 'react'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Icon, IconButton } from '@fto/icons'
import { addModal, Button, Flex, Separator } from '@fto/ui'

import { getSelectableLanguages } from '@root/utils/getSelectableLanguages'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { LOGOUT } from '@root/store/auth/auth.slice'
import { LOGIN_ROUTE, USER_PROFILE_ROUTE, makeRoute } from '@root/constants/routes'
import { $useCrowdin } from '@root/store/appSettings/selectors'
import { $getUser } from '@root/store/auth/selectors'
import RibbonDropdownMenu from '@fto/chart_components/ProjectInterface/components/Ribbon/components/RibbonDropdownMenu'
import { setI18nLang } from '@root/i18n'
import { MODAL_NAMES } from '@root/constants/modalNames'

import { SyncProjectManager } from '@fto/lib/ProjectAdapter/ProjectSync/SyncProjectManager'

import { RibbonInnerDropdownType, RibbonOptionType } from '../types'
import RibbonDropdown from '../RibbonDropdown'

import styles from '../../index.module.scss'
import { LANGUAGES } from '@root/constants/langs'

const ProfileDropdown: FC = () => {
    const { t, i18n } = useTranslation()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const userInfo = useAppSelector($getUser)
    const useCrowdin = useAppSelector($useCrowdin)

    const profileOptions: RibbonOptionType[] = [
        {
            value: 'billing',
            name: t('ribbon.profile.billing'),
            icon: 'billing',
            action: () => navigate(makeRoute(USER_PROFILE_ROUTE))
        },
        {
            value: 'rateUs',
            name: t('ribbon.profile.rateUs'),
            icon: 'star-empty',
            action: () => addModal(MODAL_NAMES.chart.rateUsModal)
        }
        // {
        //     value: 'bugBounty',
        //     name: t('ribbon.profile.bugBounty'),
        //     icon: 'bug',
        //     action: () => {
        //         addModal(MODAL_NAMES.dashboard.bugBounty)
        //     }
        // }
    ]

    const languageOptions: RibbonInnerDropdownType[] = useMemo(
        () => [
            {
                value: 'language',
                name: `${t('ribbon.profile.language')} ${i18n.language === LANGUAGES.en.value ? '' : '(Language)'}`,
                icon: 'language',
                options: getSelectableLanguages(useCrowdin).map((language) => {
                    return {
                        isSelected: i18n.language === language.value,
                        value: language.value,
                        name: language.name,
                        icon: 'one-chart',
                        action: () => {
                            setI18nLang(language.value)

                            // NOTE: here we do page reload and auto save to save data
                            SyncProjectManager.Instance.SaveProject().then(() => {
                                window.location.reload()
                            })
                        }
                    }
                })
            }
        ],
        [useCrowdin, i18n.language]
    )

    const handleLogout = useCallback(() => {
        dispatch(LOGOUT())
        navigate(makeRoute(LOGIN_ROUTE))
    }, [])

    const additionalProfileOptions = (
        <Flex direction='column' gap={4}>
            <Separator width='184px' color='gray-500' />
            <Button
                block
                classNames={{ content: cx(styles.buttonContent, styles.logout) }}
                before={<Icon name='log-out' size={16} color={'red-400'} />}
                type='tetriatry-white'
                size='compact'
                label={t('ribbon.profile.signOut')}
                onClick={handleLogout}
            />
        </Flex>
    )

    return (
        <RibbonDropdown
            tooltip={userInfo.displayName}
            renderer={(close) => (
                <RibbonDropdownMenu
                    close={close}
                    options={profileOptions}
                    name={t('profile.userProfile')}
                    dropdowns={languageOptions}
                    additionalElements={additionalProfileOptions}
                    withDropdownsSearch
                />
            )}
        >
            <IconButton size={24} className={styles.iconButton} name='avatar' />
        </RibbonDropdown>
    )
}
export default ProfileDropdown
