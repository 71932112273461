import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { Command } from './Command'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

class RedoCommand extends Command {
    public execute(): void {
        const activeChart = GlobalChartsController.Instance.getActiveChart()
        if (activeChart) {
            activeChart.redoLastChange()
            activeChart.Repaint()
        } else {
            throw new StrangeError('No active chart')
        }
    }
}

export { RedoCommand }
