import { FC, useCallback, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Flex, Typography, Button } from '@fto/ui'
import { Icon } from '@fto/icons'

import {
    TOptionType,
    TOptValue_Session,
    TOptValue_SessionsArray
} from '@fto/lib/extension_modules/indicators/api/IndicatorInterfaceUnit'
import IndicatorOptionsStore from '@fto/lib/charting/tool_storages/indicators'

import { IndicatorCustomSessionsValue, IndicatorSessionValue, SessionType } from './types'
import { EMPTY_SESSION_TEMPLATE } from './constants'

import Row from './components/Row'

import styles from './index.module.scss'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/DllIndicatorUnit'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

type Props = {
    sessions: [string, IndicatorSessionValue | IndicatorCustomSessionsValue][]
}

type SessionsListType = { ftoSessions: SessionType[]; customSessions: SessionType[] }

const TradingSessions: FC<Props> = ({ sessions }) => {
    const { t } = useTranslation()
    const { setIndicatorOptions } = IndicatorOptionsStore

    const [sessionList, setSessionsList] = useState<SessionsListType | null>(null)

    const updateSessionsList = useCallback(() => {
        setSessionsList(
            sessions.reduce<SessionsListType>(
                (list, [optionKey, item], idx) => {
                    if (item.type === TOptionType.ot_SessionsArray) {
                        return {
                            ...list,
                            customSessions: [
                                ...list.customSessions,
                                ...item.fvalue.sessions.map((item, index: number) => ({
                                    ...item,
                                    id: -index - 1,
                                    alias: 'custom' + index,
                                    optionKey
                                }))
                            ]
                        }
                    }

                    return {
                        ...list,
                        ftoSessions: [
                            ...list.ftoSessions,
                            { ...item.fvalue, optionKey, id: idx, alias: item.fvalue.name }
                        ]
                    }
                },
                {
                    ftoSessions: [],
                    customSessions: []
                }
            )
        )
    }, [sessions])

    useEffect(() => {
        updateSessionsList()
    }, [])

    useEffect(() => {
        updateSessionsList()
    }, [sessions])

    const { ftoSessions = [], customSessions = [] } = sessionList || {}

    const addCustomSession = useCallback(() => {
        setIndicatorOptions((prevData: any) => {
            for (let key in Object.entries(prevData)) {
                const item = prevData[key]
                if (item.type !== TOptionType.ot_SessionsArray) continue

                const newSession = EMPTY_SESSION_TEMPLATE

                return {
                    ...prevData,
                    [key]: {
                        ...item,
                        fvalue: new TOptValue_SessionsArray([
                            ...item.fvalue.sessions,
                            new TOptValue_Session(
                                newSession.isEnabled,
                                newSession.name,
                                newSession.startTime,
                                newSession.endTime,
                                newSession.color
                            )
                        ])
                    }
                }
            }

            throw new StrangeError('Some fatal error while adding custom session')
        })
    }, [])

    const handleParamChange = useCallback(
        (params: { value: boolean; id: SessionType['id']; key: string; isCustom: boolean; alias?: string }) => {
            const isCustom = params.isCustom

            setIndicatorOptions((prevData: any) => {
                return Object.entries(prevData).reduce((acc, item, idx) => {
                    const [opKey, value]: [string, any] = item

                    if (value instanceof TRuntimeIndicator) return { ...acc, [opKey]: value }
                    if (value.type !== TOptionType.ot_Session && value.type !== TOptionType.ot_SessionsArray)
                        return { ...acc, [opKey]: value }

                    if (!isCustom && value.type === TOptionType.ot_Session) {
                        // Update the session
                        if (params.alias !== value.alias) return { ...acc, [opKey]: value }

                        const newSession = {
                            ...value.fvalue,
                            [params.key]: params.value
                        }

                        return {
                            ...acc,
                            [opKey]: {
                                ...value,
                                fvalue: new TOptValue_Session(
                                    newSession.isEnabled,
                                    newSession.name,
                                    newSession.startTime,
                                    newSession.endTime,
                                    newSession.color
                                )
                            }
                        }
                    }

                    if (isCustom && value.type === TOptionType.ot_SessionsArray) {
                        return {
                            ...acc,
                            [opKey]: {
                                ...value,
                                fvalue: new TOptValue_SessionsArray(
                                    value.fvalue.sessions.map((s: any, idx: number) => {
                                        if (idx === -(params.id + 1)) {
                                            let item = {
                                                ...s,
                                                [params.key]: params.value
                                            }

                                            return new TOptValue_Session(
                                                item.isEnabled,
                                                item.name,
                                                item.startTime,
                                                item.endTime,
                                                item.color
                                            )
                                        }
                                        return s
                                    })
                                )
                            }
                        }
                    }

                    return acc
                }, {})
            })
        },
        [sessions]
    )

    const removeSession = useCallback((id: SessionType['id']) => {
        setIndicatorOptions((prevData: any) => {
            for (let key in Object.entries(prevData)) {
                const item = prevData[key]
                if (item.type !== TOptionType.ot_SessionsArray) continue

                return {
                    ...prevData,
                    [key]: {
                        ...item,
                        fvalue: new TOptValue_SessionsArray([
                            ...item.fvalue.sessions.filter((s: any, idx: number) => idx !== -(id + 1))
                        ])
                    }
                }
            }

            throw new StrangeError('Some fatal error while removing custom session')
        })
    }, [])

    const hasCustomSessions = useMemo(() => customSessions.length > 0, [customSessions])

    if (sessionList === null) {
        return null
    }

    return (
        <Flex direction={'column'} gap={16}>
            <Flex direction='column' gap={16}>
                <Flex
                    alignItems='center'
                    className={cx(styles.Labels, { [styles.WithCustomSessions]: hasCustomSessions })}
                    gap={8}
                >
                    <Typography type='subtext-regular' color='gray-600'>
                        {t('tradingSessionIndicator.color')}
                    </Typography>
                    <Typography align='center' type='subtext-regular' color='gray-600' className={styles.SessionsLabel}>
                        {t('tradingSessionIndicator.sessionTimezone')}
                    </Typography>
                </Flex>

                <Flex direction='column' gap={8}>
                    {ftoSessions.map((session) => (
                        <Row
                            key={session.id}
                            session={session}
                            onChange={handleParamChange}
                            hasCustomSessions={hasCustomSessions}
                        />
                    ))}
                </Flex>
            </Flex>
            {hasCustomSessions && (
                <Flex direction='column' gap={16}>
                    <Typography type='tiny-regular' color='gray-600' className={styles.CustomSessionLabel}>
                        {t('tradingSessionIndicator.customSession')}
                    </Typography>
                    <Flex direction='column' gap={8} className={styles.CustomTimezonesContainer}>
                        {customSessions.map((session) => (
                            <Row
                                isCustom
                                key={session.id}
                                session={session}
                                onChange={handleParamChange}
                                onRemove={removeSession}
                            />
                        ))}
                    </Flex>
                </Flex>
            )}
            <Flex alignItems='center' justifyContent='center'>
                <Button
                    type='secondary'
                    label='Add Custom session'
                    before={<Icon size={12} name='plus' color='currentColor' />}
                    onClick={addCustomSession}
                    size='compact'
                />
            </Flex>
        </Flex>
    )
}

export default TradingSessions
