import GlobalChartsController, { ChartControllerEvent } from '../globals/GlobalChartsController'
import GlobalProcessingCore from '../globals/GlobalProcessingCore'
import { ProcessingCoreEvent, TProcessingCore } from '../processing_core/ProcessingCore'
import {
    ControlNames,
    EOperationType,
    LotRecalculationBase,
    MarketValues,
    OrderType,
    OrderValues,
    StoplossRecalculationBase,
    StoplossState,
    StoplossValues,
    TakeprofitRecalculationBase,
    TakeprofitState,
    TakeprofitValues,
    TradeTypeMap
} from './OrderWndStructs'
import { BuyLimitStoplossCalculation } from './StoplossCalculationStrategies/BuyLimitStoplossCalculation'
import { BuyStopStoplossCalculation } from './StoplossCalculationStrategies/BuyStopStoplossCalculation'
import { MarketBuyStoplossCalculation } from './StoplossCalculationStrategies/MarketBuyStoplossCalculation'
import { MarketSellStoplossCalculation } from './StoplossCalculationStrategies/MarketSellStoplossCalculation'
import { SellLimitStoplossCalculation } from './StoplossCalculationStrategies/SellLimitStoplossCalculation'
import { SellStopStoplossCalculation } from './StoplossCalculationStrategies/SellStopStoplossCalculation'
import { StoplossCalculationStrategy } from './StoplossCalculationStrategies/StoplossCalculationStrategy'
import { BuyLimitTakeprofitCalculation } from './TakeProfitCalculationStrategies/BuyLimitTakeprofitCalculation'
import { BuyStopTakeprofitCalculation } from './TakeProfitCalculationStrategies/BuyStopTakeprofitCalculation'
import { MarketBuyTakeprofitCalculation } from './TakeProfitCalculationStrategies/MarketBuyTakeprofitCalculation'
import { MarketSellTakeprofitCalculation } from './TakeProfitCalculationStrategies/MarketSellTakeprofitCalculation'
import { SellLimitTakeprofitCalculation } from './TakeProfitCalculationStrategies/SellLimitTakeprofitCalculation'
import { SellStopTakeprofitCalculation } from './TakeProfitCalculationStrategies/SellStopTakeprofitCalculation'
import { TakeprofitCalculationStrategy } from './TakeProfitCalculationStrategies/TakeprofitCalculationStrategy'
import { LotValidationStrategy } from './Validations/LotValidations/LotValidationStrategy'
import { BuyLimitAtPriceValidation } from './Validations/PendingExecutionPriceValidations/BuyLimitAtPriceValidation'
import { BuyStopAtPriceValidation } from './Validations/PendingExecutionPriceValidations/BuyStopAtPriceValidation'
import { SellLimitAtPriceValidation } from './Validations/PendingExecutionPriceValidations/SellLimitAtPriceValidation'
import { SellStopAtPriceValidation } from './Validations/PendingExecutionPriceValidations/SellStopAtPriceValidation'
import { StoplossValidationBuyLimit } from './Validations/StoplossValidations/StoplossValidationBuyLimit'
import { StoplossValidationBuyStop } from './Validations/StoplossValidations/StoplossValidationBuyStop'
import { StoplossValidationMarketBuy } from './Validations/StoplossValidations/StoplossValidationMarketBuy'
import { StoplossValidationMarketSell } from './Validations/StoplossValidations/StoplossValidationMarketSell'
import { StoplossValidationSellLimit } from './Validations/StoplossValidations/StoplossValidationSellLimit'
import { StoplossValidationSellStop } from './Validations/StoplossValidations/StoplossValidationSellStop'
import { TakeprofitValidationBuyLimit } from './Validations/TakeProfitValidations/TakeProfitValidationBuyLimit'
import { TakeprofitValidationBuyStop } from './Validations/TakeProfitValidations/TakeProfitValidationBuyStop'
import { TakeprofitValidationMarketBuy } from './Validations/TakeProfitValidations/TakeProfitValidationMarketBuy'
import { TakeprofitValidationMarketSell } from './Validations/TakeProfitValidations/TakeProfitValidationMarketSell'
import { TakeprofitValidationSellLimit } from './Validations/TakeProfitValidations/TakeProfitValidationSellLimit'
import { TakeprofitValidationSellStop } from './Validations/TakeProfitValidations/TakeProfitValidationSellStop'
import { ValidationStrategy } from './Validations/ValidationStrategy'
import { ObservableTemplateItem, ObserverTemplate } from '@fto/chart_components/ObserverTemplate'
import { OrderMarker, OrderMarkerEvent, OrderMarkerInfo, OrderMarkerType } from '@fto/lib/OrderModalClasses/OrderMarker'
import { DoubleValidationStrategy } from '@fto/lib/OrderModalClasses/Validations/DoubleValidationStrategy'
import { ErrorManager } from '@fto/lib/OrderModalClasses/Validations/ErrorManager'
import { TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { TChunkStatus } from '@fto/lib/ft_types/data/chunks/ChunkEnums'
import { TBaseTickChunk } from '@fto/lib/ft_types/data/chunks/TickChunks/BaseTickChunk'
import { TDataArrayEvents } from '@fto/lib/ft_types/data/data_downloading/DownloadRelatedEnums'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import CreateOrder from '@fto/lib/store/createOrder'
import { orderModelType, pseudoFocusesType } from '@fto/lib/store/createOrder/types'
import { OrderDataType, terminalOrderPendingPositionType } from '@fto/lib/store/ordersStore/types'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { mapOrderTypeToTradePositionType } from '@fto/lib/utils/ordersUtils'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { throttle } from 'lodash'
import StrangeSituationNotifier from '../common/StrangeSituationNotifier'
import { showErrorToast } from '@root/utils/toasts'
import ProcessingCoreUtils from '../processing_core/ProcessingCoreUtils'
import { TBarArrays } from '@fto/lib/ft_types/data/data_arrays/BarArrays'
import { ValidationErrorType } from './Validations/ValidationErrorType'

export enum OrderModelEvent {
    STOPLOSS_PRICE_CHANGED,
    STOPLOSS_POINTS_CHANGED,
    STOPLOSS_USD_CHANGED,
    STOPLOSS_PERCENT_CHANGED,

    STOPLOSS_VALUES_RECALCULATED,

    LOT_CHANGED,
    RISK_IN_PERCENT_CHANGED,
    RISK_IN_USD_CHANGED,

    TAKEPROFIT_PRICE_CHANGED,
    TAKEPROFIT_POINTS_CHANGED,
    TAKEPROFIT_USD_CHANGED,
    TAKEPROFIT_PERCENT_CHANGED,
    TAKEPROFIT_VALUES_RECALCULATED,

    LOT_RECALC_BASE_CHANGED,

    ON_TICK,
    MODEL_INITIALIZED,
    TAKEPROFIT_DISABLED,
    STOPLOSS_DISABLED,
    STOPLOSS_ENABLED,
    TAKEPROFIT_ENABLED,
    ON_CHART_CHANGED,
    ORDER_TYPE_CHANGED,
    ATPRICE_CHANGED
}

enum DataKeyEnum {
    PRICE = 'price',
    USD = 'usd',
    PERCENT = 'percent',
    POINTS = 'points',
    M_LOT = 'm_lot',
    M_RISK_IN_USD = 'm_riskInUsd',
    M_RISK_IN_PERCENT = 'm_riskInPercent',
    M_AT_PRICE = 'm_atPrice',
    M_OPERATION_TYPE = 'm_operationType',
    M_ORDER_TYPE = 'm_orderType',
    M_TAKEPROFIT_STATE = 'm_takeprofitState',
    M_STOPLOSS_STATE = 'm_stoplossState'
}

class OrderStrategies {
    public pCalucalationStrategy: StoplossCalculationStrategy | null = null
    public pStoplossValidationStarategy: ValidationStrategy | null = null
    public pPendingAtPriceValidationStrategy: ValidationStrategy | null = null
    public pTakeProfitCalculationStrategy: TakeprofitCalculationStrategy | null = null
    public pTakeprofitValidationStrategy: ValidationStrategy | null = null

    constructor(
        stoplossCalculation: StoplossCalculationStrategy,
        stoplossValidation: ValidationStrategy,
        atPriceValidation: ValidationStrategy | null,
        takeprofitCalculation: TakeprofitCalculationStrategy,
        takeprofitValidation: ValidationStrategy
    ) {
        this.pCalucalationStrategy = stoplossCalculation
        this.pStoplossValidationStarategy = stoplossValidation
        this.pPendingAtPriceValidationStrategy = atPriceValidation
        this.pTakeProfitCalculationStrategy = takeprofitCalculation
        this.pTakeprofitValidationStrategy = takeprofitValidation
    }
}

export class OrderModel
    implements
        ObserverTemplate<
            ProcessingCoreEvent | ChartControllerEvent | OrderMarkerEvent,
            TProcessingCore | GlobalChartsController | OrderMarker
        >
{
    public observableItem: ObservableTemplateItem<
        OrderModelEvent,
        OrderModel,
        ObserverTemplate<OrderModelEvent, OrderModel>
    >
    private m_pMarketValues: MarketValues
    private m_ask: number
    private m_bid: number
    private m_pOrderValues: OrderValues
    private m_stoplossStrategies: Map<string, OrderStrategies> // Use appropriate type instead of `any`
    private m_lot = 0.1 // Assuming `m_lot` is defined elsewhere
    private m_isInitialized = false
    private m_stoplossRecalculationBase: StoplossRecalculationBase = StoplossRecalculationBase.POINTS
    private m_takeprofitRecalculationBase: TakeprofitRecalculationBase = TakeprofitRecalculationBase.POINTS
    private m_lotRecalculationBase: LotRecalculationBase = LotRecalculationBase.LOT
    private m_spread = 0
    public m_stoplossValues: StoplossValues = new StoplossValues() // Assuming this type is defined elsewhere
    public m_takeprofitValues: TakeprofitValues = new TakeprofitValues() // Assuming this type is defined elsewhere
    public m_atPrice = 0
    private m_pStoplossCalculationStrategy: StoplossCalculationStrategy | null = null
    private m_pTakeprofitCalculationStrategy: TakeprofitCalculationStrategy | null = null
    private m_orderType: OrderType = OrderType.MARKET
    private m_operationType: EOperationType = EOperationType.SELL
    private m_stoplossState: StoplossState = StoplossState.DISABLED
    private m_takeprofitState: TakeprofitState = TakeprofitState.DISABLED
    private m_riskInPercent = 0
    private m_riskInUsd = 0
    private stopLossPriceValidationStrategy: ValidationStrategy | null = null
    private takeProfitPriceValidationStrategy: ValidationStrategy | null = null
    private lotValidationStrategy: ValidationStrategy | null = null
    private atPriceValidationStrategy: ValidationStrategy | null = null
    private m_riskRewardRatio = 0
    private errorManager: ErrorManager = new ErrorManager()
    private doubleValidationStrategy: ValidationStrategy = new DoubleValidationStrategy()
    private m_priceForLotPoint = 0
    private m_takeprofitLine?: OrderMarker
    private m_stoplossLine?: OrderMarker
    private m_atPriceLine?: OrderMarker
    private pseudoFocuses: pseudoFocusesType = {
        m_lot: true,
        m_riskInUsd: false,
        m_riskInPercent: false,
        stoplossValues: {
            price: false,
            points: true,
            usd: false,
            percent: false
        },
        takeProfitValues: {
            price: false,
            points: true,
            usd: false,
            percent: false
        }
    }
    private controlInFocus: {
        control: ControlNames
        value: string | number
    } | null = null
    public orderOnEditing: OrderDataType | null = null
    private symbolLoadStates: Map<
        string,
        { chunkWaitingForLoading: TBaseTickChunk | null; symbolWaitForLoading: TSymbolData | null }
    > = new Map()
    private _throttledProcessEvent = throttle(
        (
            event: ProcessingCoreEvent | ChartControllerEvent | OrderMarkerEvent,
            item: TProcessingCore | GlobalChartsController | OrderMarker
        ): void => {
            if (event in ProcessingCoreEvent && item instanceof TProcessingCore) {
                this.processProcessingCoreEvent(event as ProcessingCoreEvent, item)
            } else if (event in ChartControllerEvent && item instanceof GlobalChartsController) {
                this.processChartControllerEvent(event as ChartControllerEvent, item)
            }
        },
        1000
    )

    constructor(marketValues: MarketValues) {
        this.m_pMarketValues = marketValues
        this.m_ask = this.m_pMarketValues.ask
        this.m_bid = this.m_pMarketValues.bid
        this.m_pOrderValues = new OrderValues()
        this.m_pOrderValues.lot = this.m_lot // Assuming `m_lot` is defined elsewhere
        this.m_stoplossStrategies = new Map<string, OrderStrategies>()
        this.observableItem = new ObservableTemplateItem<
            OrderModelEvent,
            OrderModel,
            ObserverTemplate<OrderModelEvent, OrderModel>
        >()
        // market buy
        const pMarketBuyStoplossCalculation = new MarketBuyStoplossCalculation()
        const pStoplossPriceValidationStrategy = new StoplossValidationMarketBuy(marketValues)
        const pMarketBuyTakeprofitCalculation = new MarketBuyTakeprofitCalculation()
        const pMarketBuyTakeprofitValidation = new TakeprofitValidationMarketBuy(marketValues)

        const marketBuyStrategies = new OrderStrategies(
            pMarketBuyStoplossCalculation,
            pStoplossPriceValidationStrategy,
            null,
            pMarketBuyTakeprofitCalculation,
            pMarketBuyTakeprofitValidation
        )

        // market sell
        const pMarketSellStoplossCalculation = new MarketSellStoplossCalculation()
        const pStoplossValidationMarketSell = new StoplossValidationMarketSell(marketValues)
        const pMarketSellTakeprofitCalculation = new MarketSellTakeprofitCalculation()
        const pMarketSellTakeprofitValidation = new TakeprofitValidationMarketSell(marketValues)

        const marketSellStrategies = new OrderStrategies(
            pMarketSellStoplossCalculation,
            pStoplossValidationMarketSell,
            null,
            pMarketSellTakeprofitCalculation,
            pMarketSellTakeprofitValidation
        )

        // buy limit
        const pStoplossValidationBuyLimit = new StoplossValidationBuyLimit(marketValues, this.m_pOrderValues)
        const pBuyLimitStoplossCalculation = new BuyLimitStoplossCalculation()
        const pBuyLimitAtPriceValidation = new BuyLimitAtPriceValidation(marketValues)
        const pBuyLimitTakeprofitCalculation = new BuyLimitTakeprofitCalculation()
        const pBuyLimitTakeprofitValidation = new TakeprofitValidationBuyLimit(marketValues, this.m_pOrderValues)

        const buyLimitStrategies = new OrderStrategies(
            pBuyLimitStoplossCalculation,
            pStoplossValidationBuyLimit,
            pBuyLimitAtPriceValidation,
            pBuyLimitTakeprofitCalculation,
            pBuyLimitTakeprofitValidation
        )

        // buy stop
        const pStoplossValidationBuyStop = new StoplossValidationBuyStop(marketValues, this.m_pOrderValues)
        const pBuyStopStoplossCalculation = new BuyStopStoplossCalculation()
        const pBuyStopAtPriceValidation = new BuyStopAtPriceValidation(marketValues)
        const pBuyStopTakeprofitCalculation = new BuyStopTakeprofitCalculation()
        const pBuyStopTakeprofitValidation = new TakeprofitValidationBuyStop(marketValues, this.m_pOrderValues)
        const buyStopStrategies = new OrderStrategies(
            pBuyStopStoplossCalculation,
            pStoplossValidationBuyStop,
            pBuyStopAtPriceValidation,
            pBuyStopTakeprofitCalculation,
            pBuyStopTakeprofitValidation
        )

        // sell limit
        const pStoplossValidationSellLimit = new StoplossValidationSellLimit(marketValues, this.m_pOrderValues)
        const pSellLimitStoplossCalculation = new SellLimitStoplossCalculation()
        const pSellLimitAtPriceValidation = new SellLimitAtPriceValidation(marketValues)
        const pSellLimitTakeprofitCalculation = new SellLimitTakeprofitCalculation()
        const pSellLimitTakeprofitValidation = new TakeprofitValidationSellLimit(marketValues, this.m_pOrderValues)
        const sellLimitStrategies = new OrderStrategies(
            pSellLimitStoplossCalculation,
            pStoplossValidationSellLimit,
            pSellLimitAtPriceValidation,
            pSellLimitTakeprofitCalculation,
            pSellLimitTakeprofitValidation
        )

        // sell stop
        const pStoplossValidationSellStop = new StoplossValidationSellStop(marketValues, this.m_pOrderValues)
        const pSellStopStoplossCalculation = new SellStopStoplossCalculation()
        const pSellStopAtPriceValidation = new SellStopAtPriceValidation(marketValues)
        const pSellStopTakeprofitCalculation = new SellStopTakeprofitCalculation()
        const pSellStopTakeprofitValidation = new TakeprofitValidationSellStop(marketValues, this.m_pOrderValues)

        const sellStopStrategies = new OrderStrategies(
            pSellStopStoplossCalculation,
            pStoplossValidationSellStop,
            pSellStopAtPriceValidation,
            pSellStopTakeprofitCalculation,
            pSellStopTakeprofitValidation
        )

        // Market Buy
        this.m_stoplossStrategies.set(this.serializeKey(OrderType.MARKET, EOperationType.BUY), marketBuyStrategies)

        // Market Sell
        this.m_stoplossStrategies.set(this.serializeKey(OrderType.MARKET, EOperationType.SELL), marketSellStrategies)

        // Buy Limit
        this.m_stoplossStrategies.set(this.serializeKey(OrderType.LIMIT, EOperationType.BUY), buyLimitStrategies)

        // Buy Stop
        this.m_stoplossStrategies.set(this.serializeKey(OrderType.STOP, EOperationType.BUY), buyStopStrategies)

        // Sell Limit
        this.m_stoplossStrategies.set(this.serializeKey(OrderType.LIMIT, EOperationType.SELL), sellLimitStrategies)

        // Sell Stop
        this.m_stoplossStrategies.set(this.serializeKey(OrderType.STOP, EOperationType.SELL), sellStopStrategies)

        this.lotValidationStrategy = new LotValidationStrategy(marketValues, this.m_pOrderValues)
    }

    private serializeKey(orderType: OrderType, operationType: EOperationType): string {
        return `${orderType}_${operationType}`
    }

    public getStrategy(orderType: OrderType, operationType: EOperationType): OrderStrategies | undefined {
        return this.m_stoplossStrategies.get(this.serializeKey(orderType, operationType))
    }

    public resetInitialization(): void {
        this.m_isInitialized = false
        GlobalChartsController.Instance.isForbiddenToChangeActiveChart = false
        this.orderOnEditing = null

        this.cleanUpAllLines()
        GlobalChartsController.Instance.updateCharts()
        GlobalProcessingCore.ProcessingCore.detachObserver(this)
    }

    private boundOnMapDownloaded = this.onMapDownloaded.bind(this)

    private onMapDownloaded(barArrays: TBarArrays): void {
        this.waitForSymbol(barArrays)
        const symbol = GlobalSymbolList.SymbolList.GetExistingSymbol(barArrays.getSymbolName())

        if (symbol) {
            symbol.Events.off(TDataArrayEvents.de_MapDownloaded, this.boundOnMapDownloaded)
        } else {
            throw new StrangeError('Symbol not found on map loaded')
        }
    }

    public addSymbolsToQueue(symbols: string[]): void {
        for (const symbol of symbols) {
            this.symbolLoadStates.set(symbol, {
                chunkWaitingForLoading: null,
                symbolWaitForLoading: null
            })
        }

        for (const symbol of symbols) {
            if (!GlobalSymbolList.SymbolList.IsAmongAvailableSymbols(symbol, '')) {
                continue
            }

            const symbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol)

            this.waitForSymbol(symbolData.BarArrays)
        }
    }

    public initialize(): void {
        CreateOrder.updateLoadingState(true)

        const symbolData = GlobalSymbolList.SymbolList.GetExistingSymbol(this.getSymbol())
        if (!symbolData) {
            throw new StrangeError('Symbol data not found')
        }

        const symbolsNeedToWait = [
            this.getSymbol(),
            ProcessingCoreUtils.GetSymbolForConversionToUSD(symbolData).symbolName
        ]

        this.addSymbolsToQueue(symbolsNeedToWait)
    }

    private initializeOrderValues(): void {
        const { updateData } = CreateOrder

        if (!this.m_isInitialized) {
            this.onChangeOrderType(OrderType.MARKET)
            this.onChangeOperationType(EOperationType.BUY)
            this.onStoplossDisabled()
            this.onTakeprofitDisabled()
            this.m_stoplossRecalculationBase = StoplossRecalculationBase.POINTS
            this.m_takeprofitRecalculationBase = TakeprofitRecalculationBase.POINTS
            this.m_lotRecalculationBase = LotRecalculationBase.LOT
            this.m_lot = 0.1
            this.m_spread = this.m_pMarketValues.spread
            const spreadInPoints = this.m_spread + 1 + this.m_pMarketValues.minimumDistanceToPrice

            if (this.m_pStoplossCalculationStrategy) {
                const tempValues: StoplossValues = new StoplossValues()
                tempValues.points = spreadInPoints + this.m_pMarketValues.minimumDistanceToPrice
                const result = this.m_pStoplossCalculationStrategy.onChangeStoplossPoints(
                    tempValues,
                    this.m_pMarketValues,
                    new OrderValues()
                )
                if (result) {
                    // Assuming onChangeStoplossPoints returns a value directly, not std::optional
                    this.m_stoplossValues = result
                }
            }

            if (this.m_pTakeprofitCalculationStrategy) {
                const tempValues: TakeprofitValues = new TakeprofitValues()
                tempValues.points = spreadInPoints - this.m_pMarketValues.minimumDistanceToPrice
                const result = this.m_pTakeprofitCalculationStrategy.onChangeTakeprofitPoints(
                    tempValues,
                    this.m_pMarketValues,
                    new OrderValues()
                )
                if (result) {
                    // Assuming onChangeTakeprofitPoints returns a value directly, not std::optional
                    this.m_takeprofitValues = result
                }
            }

            this.m_atPrice = 2

            this.m_pOrderValues.lot = this.m_lot
            this.m_pOrderValues.atPrice = this.m_atPrice

            this.m_riskRewardRatio = this.m_takeprofitValues.points / this.m_stoplossValues.points
            this.m_isInitialized = true
        }

        GlobalProcessingCore.ProcessingCore.attachObserver(this)
        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()

        updateData((prevState) => ({
            ...prevState,
            ...this.getOrderModelData()
        }))
    }

    private onChangeStoplossPoints(newStoplossPoint: string): void {
        const validationErrorType = this.isValidValue(
            newStoplossPoint,
            this.doubleValidationStrategy,
            ControlNames.STOPLOSS_POINTS
        )

        const isContinueCalculation =
            validationErrorType !== ValidationErrorType.CRITICAL && validationErrorType !== ValidationErrorType.UNKNOWN

        if (!isContinueCalculation) {
            return
        }

        const tempValues: StoplossValues = { ...this.m_stoplossValues }
        tempValues.points = parseFloat(newStoplossPoint)
        if (this.m_pStoplossCalculationStrategy) {
            const result = this.m_pStoplossCalculationStrategy.onChangeStoplossPoints(
                tempValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result !== null) {
                this.m_stoplossValues = result
                this.recalculateStoplossValues() // TODO: Implement this method
                this.observableItem.notify(OrderModelEvent.STOPLOSS_POINTS_CHANGED, this)
            }
        } else {
            // Log unexpected behavior (m_pStoplossCalculationStrategy should not be null)
        }
    }

    public onChangeOrderType(newOrderType: OrderType): void {
        const strategies = this.getStrategy(newOrderType, this.m_operationType)

        if (strategies) {
            this.m_orderType = newOrderType
            this.m_pStoplossCalculationStrategy = strategies.pCalucalationStrategy
            this.m_pTakeprofitCalculationStrategy = strategies.pTakeProfitCalculationStrategy
            this.stopLossPriceValidationStrategy = strategies.pStoplossValidationStarategy
            this.takeProfitPriceValidationStrategy = strategies.pTakeprofitValidationStrategy
            this.atPriceValidationStrategy = strategies.pPendingAtPriceValidationStrategy

            if (this.getOrderType() === OrderType.MARKET && this.m_atPriceLine) {
                this.m_atPriceLine.deleteMarkerByLinkNumber()
                this.m_atPriceLine.detachObserver(this)
                this.m_atPriceLine = undefined
            }

            if (this.getOrderType() !== OrderType.MARKET) {
                if (this.getOperationType() === EOperationType.BUY) {
                    this.m_atPrice = this.m_pMarketValues.ask
                    this.m_pOrderValues.atPrice = this.m_atPrice
                }

                if (this.getOperationType() === EOperationType.SELL) {
                    this.m_atPrice = this.m_pMarketValues.bid
                    this.m_pOrderValues.atPrice = this.m_atPrice
                }

                if (!this.m_atPriceLine) {
                    const chart = GlobalChartsController.Instance.getActiveChart()
                    if (chart) {
                        const markerInfo: OrderMarkerInfo = {
                            markerName: 'At Price',
                            price: this.m_atPrice,
                            tDateTime: GlobalProcessingCore.ProcessingCore.CurrTime
                        }
                        this.m_atPriceLine = new OrderMarker(
                            chart.MainChart,
                            markerInfo,
                            OrderMarkerType.AT_PRICE,
                            this
                        )
                        this.m_atPriceLine.posType = mapOrderTypeToTradePositionType(
                            this.m_orderType,
                            this.m_operationType
                        )

                        this.m_atPriceLine.attachObserver(this)
                        this.paintOrderMarkers()
                        chart.Repaint()
                    } else {
                        StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                            'No active chart found in OrderModel - onChangeOrderType'
                        )
                    }
                }
            }
            this.recalculateStoplossValues()
            this.recalculateTakeprofitValues()
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('No strategy found for the given order type')
        }
    }

    public onChangeAtPrice(newAtPrice: string): void {
        let validationErrorType = ValidationErrorType.NONE

        if (this.atPriceValidationStrategy) {
            validationErrorType = this.isValidValue(newAtPrice, this.atPriceValidationStrategy, ControlNames.ATPRICE)
        }

        if (this.m_atPriceLine) {
            this.m_atPriceLine.isValid = validationErrorType === ValidationErrorType.NONE
        }

        const isContinueCalculation =
            validationErrorType !== ValidationErrorType.CRITICAL && validationErrorType !== ValidationErrorType.UNKNOWN

        if (!isContinueCalculation) {
            return
        }

        this.m_atPrice = parseFloat(newAtPrice)
        this.m_pOrderValues.atPrice = this.m_atPrice
        this.recalculateStoplossValues()
        this.recalculateStoplossValues()
        this.recalculateTakeprofitValues()

        this.observableItem.notify(OrderModelEvent.ATPRICE_CHANGED, this)

        this.updateOrderMarkers()
    }

    public onChangeTakeprofitPrice(newTakeprofitPrice: string): void {
        let validationErrorType = ValidationErrorType.NONE

        if (this.takeProfitPriceValidationStrategy) {
            validationErrorType = this.isValidValue(
                newTakeprofitPrice,
                this.takeProfitPriceValidationStrategy,
                ControlNames.TAKEPROFIT_PRICE
            )
        }

        if (this.m_takeprofitLine) {
            this.m_takeprofitLine.isValid = validationErrorType === ValidationErrorType.NONE
        }

        const isContinueCalculation =
            validationErrorType !== ValidationErrorType.CRITICAL && validationErrorType !== ValidationErrorType.UNKNOWN

        if (!isContinueCalculation) {
            return
        }

        const tempValues: TakeprofitValues = { ...this.m_takeprofitValues }
        tempValues.price = parseFloat(newTakeprofitPrice)

        if (this.m_pTakeprofitCalculationStrategy) {
            const result = this.m_pTakeprofitCalculationStrategy.onChangeTakeprofitPrice(
                tempValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result) {
                this.m_takeprofitValues = result
                this.observableItem.notify(OrderModelEvent.TAKEPROFIT_PRICE_CHANGED, this)
            }
        } else {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            throw new StrangeError('Unexpected behavior: m_pTakeprofitCalculationStrategy should not be null')
        }

        this.updateOrderMarkers()
    }

    public setStoplossRecalculationBase(stoplossRecalculationBase: StoplossRecalculationBase): void {
        this.m_stoplossRecalculationBase = stoplossRecalculationBase
    }

    public onChangeTakeprofitInUsd(newTakeprofitUsd: string): void {
        const validationErrorType = this.isValidValue(
            newTakeprofitUsd,
            this.doubleValidationStrategy,
            ControlNames.TAKEPROFIT_USD
        )

        const isContinueCalculation =
            validationErrorType !== ValidationErrorType.CRITICAL && validationErrorType !== ValidationErrorType.UNKNOWN

        if (!isContinueCalculation) {
            return
        }

        const tempValues: TakeprofitValues = {
            ...this.m_takeprofitValues,
            usd: parseFloat(newTakeprofitUsd)
        }
        if (this.m_pTakeprofitCalculationStrategy) {
            const result = this.m_pTakeprofitCalculationStrategy.onChangeTakeprofitInUsd(
                tempValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result) {
                this.m_takeprofitValues = result
                this.observableItem.notify(OrderModelEvent.TAKEPROFIT_USD_CHANGED, this)
            }
        } else {
            throw new StrangeError('Unexpected behavior: m_pTakeprofitCalculationStrategy should not be null')
        }
    }

    public onChangeTakeprofitInPercent(newTakeprofitPercent: string): void {
        const validationErrorType = this.isValidValue(
            newTakeprofitPercent,
            this.doubleValidationStrategy,
            ControlNames.TAKEPROFIT_PERCENT
        )

        const isContinueCalculation =
            validationErrorType !== ValidationErrorType.CRITICAL && validationErrorType !== ValidationErrorType.UNKNOWN

        if (!isContinueCalculation) {
            return
        }

        const tempValues: TakeprofitValues = {
            ...this.m_takeprofitValues,
            percent: parseFloat(newTakeprofitPercent)
        }
        if (this.m_pTakeprofitCalculationStrategy) {
            const result = this.m_pTakeprofitCalculationStrategy.onChangeTakeprofitInPercent(
                tempValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result) {
                this.m_takeprofitValues = result
                this.observableItem.notify(OrderModelEvent.TAKEPROFIT_PERCENT_CHANGED, this)
            }
        } else {
            throw new StrangeError('Unexpected behavior: m_pTakeprofitCalculationStrategy should not be null')
        }
    }

    public setTakeprofitRecalculationBase(takeprofitRecalculationBase: TakeprofitRecalculationBase): void {
        this.m_takeprofitRecalculationBase = takeprofitRecalculationBase
    }

    public setLotRecalculationBase(lotRecalculationBase: LotRecalculationBase): void {
        this.m_lotRecalculationBase = lotRecalculationBase
        this.recalculateStoplossValues()
        this.observableItem.notify(OrderModelEvent.LOT_RECALC_BASE_CHANGED, this)
    }

    public onChangeLot(newLotValue: string): void {
        let validationErrorType = ValidationErrorType.NONE

        if (this.lotValidationStrategy) {
            validationErrorType = this.isValidValue(newLotValue, this.lotValidationStrategy, ControlNames.LOT)
        }

        const isContinueCalculation =
            validationErrorType !== ValidationErrorType.CRITICAL && validationErrorType !== ValidationErrorType.UNKNOWN

        if (!isContinueCalculation) {
            return
        }

        const tempLot = parseFloat(newLotValue)
        const scale = Math.pow(10.0, 2)
        this.m_lot = Math.round(tempLot * scale) / scale

        if (!this.m_pMarketValues) {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            throw new StrangeError('m_pMarketValues cannot be null')
        }

        this.m_riskInPercent =
            this.m_lot *
            this.m_stoplossValues.points *
            this.m_pMarketValues.pointValueForOneStandardLot *
            (100.0 / this.m_pMarketValues.equity)
        this.m_riskInUsd = this.m_pMarketValues.equity * (this.m_riskInPercent / 100.0)

        this.m_pOrderValues.lot = this.m_lot
        this.recalculateStoplossValues()
        this.recalculateTakeprofitValues()
        this.observableItem.notify(OrderModelEvent.LOT_CHANGED, this)
    }

    public onChangeRiskInUsd(newRiskInUsdValue: string): void {
        const validationErrorType = this.isValidValue(
            newRiskInUsdValue,
            this.doubleValidationStrategy,
            ControlNames.RISK_IN_USD
        )

        const isContinueCalculation =
            validationErrorType !== ValidationErrorType.CRITICAL && validationErrorType !== ValidationErrorType.UNKNOWN

        if (!isContinueCalculation) {
            return
        }

        if (!this.m_pMarketValues) {
            throw new StrangeError('m_pMarketValues cannot be null')
        }

        const scale = Math.pow(10.0, 2)
        const minimumLotChange = 0.01
        const maxIterations = 50

        const tempRiskInUsd = parseFloat(newRiskInUsdValue)
        let tempLot = tempRiskInUsd / (this.m_stoplossValues.points * this.m_pMarketValues.pointValueForOneStandardLot)
        tempLot = Math.round(tempLot * scale) / scale

        let usdByPoints = this.m_stoplossValues.points * tempLot * this.m_pMarketValues.pointValueForOneStandardLot
        let iterationCount = 0

        while (usdByPoints > tempRiskInUsd && iterationCount < maxIterations) {
            tempLot -= minimumLotChange
            usdByPoints = this.m_stoplossValues.points * tempLot * this.m_pMarketValues.pointValueForOneStandardLot
            iterationCount++
        }

        if (iterationCount === maxIterations) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Failed to find a suitable lot within 50 attempts')
            return
        }

        this.updateRiskAndLotValues(tempRiskInUsd, usdByPoints, tempLot)
        this.observableItem.notify(OrderModelEvent.RISK_IN_USD_CHANGED, this)
    }

    public onChangeRiskInPercent(newRiskInPercentValue: string): void {
        const validationErrorType = this.isValidValue(
            newRiskInPercentValue,
            this.doubleValidationStrategy,
            ControlNames.RISK_IN_PERCENT
        )

        const isContinueCalculation =
            validationErrorType !== ValidationErrorType.CRITICAL && validationErrorType !== ValidationErrorType.UNKNOWN

        if (!isContinueCalculation) {
            return
        }

        if (!this.m_pMarketValues) {
            throw new StrangeError('m_pMarketValues cannot be null')
        }

        const tempRiskInPercent = parseFloat(newRiskInPercentValue)
        const tempRiskInUsd = this.m_pMarketValues.equity * (tempRiskInPercent / 100.0)
        let tempLot = tempRiskInUsd / (this.m_stoplossValues.points * this.m_pMarketValues.pointValueForOneStandardLot)

        const scale = Math.pow(10.0, 2)
        tempLot = Math.round(tempLot * scale) / scale

        this.updateRiskAndLotValues(tempRiskInUsd, tempRiskInUsd, tempLot, tempRiskInPercent)
        this.observableItem.notify(OrderModelEvent.RISK_IN_PERCENT_CHANGED, this)
    }

    private updateRiskAndLotValues(riskInUsd: number, usdByPoints: number, lot: number, riskInPercent?: number): void {
        this.m_riskInPercent = riskInPercent ?? (usdByPoints / this.m_pMarketValues.equity) * 100
        this.m_riskInUsd = riskInUsd
        this.m_lot = lot
        this.m_stoplossValues.percent = this.m_riskInPercent
        this.m_stoplossValues.usd = usdByPoints
        this.m_takeprofitValues.percent =
            this.m_lot *
            this.m_takeprofitValues.points *
            this.m_pMarketValues.pointValueForOneStandardLot *
            (100.0 / this.m_pMarketValues.equity)
        this.m_takeprofitValues.usd = this.m_pMarketValues.equity * (this.m_takeprofitValues.percent / 100.0)
        this.m_pOrderValues.lot = this.m_lot
    }

    private getOrderType(): OrderType {
        return this.m_orderType
    }

    private getOperationType(): EOperationType {
        return this.m_operationType
    }

    private recalculateLotValues(): void {
        switch (this.m_lotRecalculationBase) {
            case LotRecalculationBase.LOT: {
                this.recalculateLotValuesByLot()
                break
            }
            case LotRecalculationBase.RISK_IN_USD: {
                this.recalculateLotValuesByUsdRisk()
                break
            }
            case LotRecalculationBase.RISK_IN_PERCENT: {
                this.recalculateLotValuesByPercentRisk()
                break
            }
            default: {
                throw new StrangeError(
                    `Unknown LotRecalculationBase in recalculateLotValues ${this.m_lotRecalculationBase}`
                )
            }
        }
    }

    private recalculateLotValuesByLot(): void {
        this.m_riskInUsd = this.m_stoplossValues.usd
        this.m_riskInPercent = this.m_stoplossValues.percent
    }

    private recalculateLotValuesByUsdRisk(): void {
        if (!this.m_pMarketValues) {
            // Log: m_pMarketValues cannot be null
            return
        }

        let usdByStoplossPoints = this.calculateUsdByStoplossPoints(this.m_lot)
        const tempLot = this.calculateInitialLot()

        if (usdByStoplossPoints > this.m_riskInUsd) {
            usdByStoplossPoints = this.calculateUsdByStoplossPoints(tempLot)
            this.m_lot = this.adjustLot(tempLot, usdByStoplossPoints, -0.01)
        } else if (usdByStoplossPoints < this.m_riskInUsd) {
            usdByStoplossPoints = this.calculateUsdByStoplossPoints(tempLot)
            const calculatedLot = this.adjustLot(tempLot, usdByStoplossPoints, 0.01)
            this.m_lot = calculatedLot - 0.01
        }

        this.m_stoplossValues.usd = this.calculateUsdByStoplossPoints(this.m_lot)
        this.m_riskInPercent = this.m_stoplossValues.percent =
            (this.m_stoplossValues.usd / this.m_pMarketValues.equity) * 100.0
        // Assuming m_takeprofitValues follows a similar structure to m_stoplossValues
        this.m_takeprofitValues.usd =
            this.m_takeprofitValues.points * this.m_lot * this.m_pMarketValues.pointValueForOneStandardLot
        this.m_takeprofitValues.percent = (this.m_takeprofitValues.usd / this.m_pMarketValues.equity) * 100.0
        if (this.m_pOrderValues) {
            this.m_pOrderValues.lot = this.m_lot
        }
    }

    private recalculateLotValuesByPercentRisk(): void {
        if (this.m_pMarketValues) {
            let tempLot =
                (this.m_pMarketValues.equity * (this.m_riskInPercent / 100)) /
                (this.m_stoplossValues.points * this.m_pMarketValues.pointValueForOneStandardLot)
            const scale = Math.pow(10, 2)
            tempLot = Math.round(tempLot * scale) / scale
            let usdByStoplossPoints = this.calculateUsdByStoplossPoints(tempLot)

            if ((usdByStoplossPoints / this.m_pMarketValues.equity) * 100 > this.m_riskInPercent) {
                const maxIterations = 50
                let iterationCount = 0

                while (
                    (usdByStoplossPoints / this.m_pMarketValues.equity) * 100 > this.m_riskInPercent &&
                    iterationCount < maxIterations
                ) {
                    tempLot -= 0.01
                    usdByStoplossPoints = this.calculateUsdByStoplossPoints(tempLot)
                    iterationCount++
                }

                if (iterationCount === maxIterations) {
                    // Log: Failed to find a suitable lot within 50 attempts
                    return
                }
                this.m_lot = tempLot
            } else if ((usdByStoplossPoints / this.m_pMarketValues.equity) * 100 < this.m_riskInPercent) {
                const maxIterations = 50
                let iterationCount = 0

                while (
                    (usdByStoplossPoints / this.m_pMarketValues.equity) * 100 <= this.m_riskInPercent &&
                    iterationCount < maxIterations
                ) {
                    tempLot += 0.01
                    usdByStoplossPoints = this.calculateUsdByStoplossPoints(tempLot)
                    iterationCount++
                }

                if (iterationCount === maxIterations) {
                    // Log: Failed to find a suitable lot within 50 attempts
                    return
                }
                this.m_lot = tempLot - 0.01
            }

            this.m_stoplossValues.usd = this.m_riskInUsd = this.calculateUsdByStoplossPoints(this.m_lot)
            this.m_stoplossValues.percent = (this.m_stoplossValues.usd / this.m_pMarketValues.equity) * 100
            // Assuming m_takeprofitValues follows a similar structure to m_stoplossValues
            this.m_takeprofitValues.usd =
                this.m_takeprofitValues.points * this.m_lot * this.m_pMarketValues.pointValueForOneStandardLot
            this.m_takeprofitValues.percent = (this.m_takeprofitValues.usd / this.m_pMarketValues.equity) * 100
            if (this.m_pOrderValues) {
                this.m_pOrderValues.lot = this.m_lot
            }
        } else {
            // Log: m_pMarketValues cannot be null
        }
    }

    private calculateUsdByStoplossPoints(lot: number): number {
        if (!this.m_pMarketValues) return 0 // Add a null check for safety
        return this.m_stoplossValues.points * lot * this.m_pMarketValues.pointValueForOneStandardLot
    }

    private calculateInitialLot(): number {
        if (!this.m_pMarketValues) return 0 // Add a null check for safety

        const tempRiskInPercent = (this.m_riskInUsd / this.m_pMarketValues.equity) * 100
        const scale = Math.pow(10.0, 2)
        return (
            Math.round(
                ((this.m_pMarketValues.equity * (tempRiskInPercent / 100)) /
                    (this.m_stoplossValues.points * this.m_pMarketValues.pointValueForOneStandardLot)) *
                    scale
            ) / scale
        )
    }

    private adjustLot(lot: number, usdByStoplossPoints: number, lotChange: number): number {
        const maxIterations = 50
        let iterationCount = 0

        let calculatedLot = lot

        while (
            (lotChange > 0 ? usdByStoplossPoints <= this.m_riskInUsd : usdByStoplossPoints > this.m_riskInUsd) &&
            iterationCount < maxIterations
        ) {
            calculatedLot += lotChange
            usdByStoplossPoints = this.calculateUsdByStoplossPoints(calculatedLot)
            iterationCount++
        }

        if (iterationCount === maxIterations) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Failed to find a suitable lot within 50 attempts')
            return lot
        }
        return calculatedLot
    }

    public onChangeTakeprofitPoints(newTakeprofitPoint: string): void {
        const validationErrorType = this.isValidValue(
            newTakeprofitPoint,
            this.doubleValidationStrategy,
            ControlNames.TAKEPROFIT_POINTS
        )

        const isContinueCalculation =
            validationErrorType !== ValidationErrorType.CRITICAL && validationErrorType !== ValidationErrorType.UNKNOWN

        if (!isContinueCalculation) {
            return
        }

        const tempValues = { ...this.m_takeprofitValues }
        tempValues.points = parseFloat(newTakeprofitPoint)
        if (this.m_pTakeprofitCalculationStrategy && this.m_pMarketValues && this.m_pOrderValues) {
            const result = this.m_pTakeprofitCalculationStrategy.onChangeTakeprofitPoints(
                tempValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result !== null) {
                this.m_takeprofitValues = result
                this.observableItem.notify(OrderModelEvent.TAKEPROFIT_POINTS_CHANGED, this)
            }
        } else {
            throw new StrangeError('Unexpected behavior: m_pTakeprofitCalculationStrategy should not be null')
        }
    }

    private recalculateStoplossValues(): void {
        switch (this.m_stoplossRecalculationBase) {
            case StoplossRecalculationBase.PRICE: {
                this.recalculateStoplossValuesByPrice()

                break
            }
            case StoplossRecalculationBase.POINTS: {
                this.recalculateStoplossValuesByPoints()

                break
            }
            case StoplossRecalculationBase.USD: {
                this.recalculateStoplossValuesByUsd()

                break
            }
            case StoplossRecalculationBase.PERCENTS: {
                this.recalculateStoplossValuesByPercent()

                break
            }
            // No default
        }
    }

    private recalculateTakeprofitValues(): void {
        switch (this.m_takeprofitRecalculationBase) {
            case TakeprofitRecalculationBase.PRICE: {
                this.recalculateTakeprofitValuesByPrice()

                break
            }
            case TakeprofitRecalculationBase.POINTS: {
                this.recalculateTakeprofitValuesByPoints()

                break
            }
            case TakeprofitRecalculationBase.USD: {
                this.recalculateTakeprofitValuesByUsd()

                break
            }
            case TakeprofitRecalculationBase.PERCENTS: {
                this.recalculateTakeprofitValuesByPercent()

                break
            }
            // No default
        }
    }

    private recalculateStoplossValuesByPrice(): void {
        if (this.m_pStoplossCalculationStrategy) {
            const result = this.m_pStoplossCalculationStrategy.recalculateStoplossValuesByPrice(
                this.m_stoplossValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result !== null && result !== undefined) {
                this.m_stoplossValues = result
                this.recalculateLotValues()
                this.observableItem.notify(OrderModelEvent.STOPLOSS_VALUES_RECALCULATED, this)
            }
        } else {
            // Log unexpected behavior (m_pStoplossCalculationStrategy should not be null)
            // eslint-disable-next-line sonarjs/no-duplicate-string
            throw new StrangeError('Unexpected behavior: m_pStoplossCalculationStrategy should not be null.')
        }
    }

    private recalculateStoplossValuesByPercent(): void {
        if (this.m_pStoplossCalculationStrategy) {
            const result = this.m_pStoplossCalculationStrategy.recalculateStoplossValuesByPercent(
                this.m_stoplossValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result !== null && result !== undefined) {
                this.m_stoplossValues = result
                this.recalculateLotValues()
                this.observableItem.notify(OrderModelEvent.STOPLOSS_VALUES_RECALCULATED, this)
            }
        } else {
            throw new StrangeError('Unexpected behavior: m_pStoplossCalculationStrategy should not be null.')
        }
    }

    private recalculateStoplossValuesByUsd(): void {
        if (this.m_pStoplossCalculationStrategy) {
            const result = this.m_pStoplossCalculationStrategy.recalculateStoplossValuesByUsd(
                this.m_stoplossValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result !== null && result !== undefined) {
                this.m_stoplossValues = result
                this.recalculateLotValues()
                this.observableItem.notify(OrderModelEvent.STOPLOSS_VALUES_RECALCULATED, this)
            }
        } else {
            throw new StrangeError('Unexpected behavior: m_pStoplossCalculationStrategy should not be null.')
        }
    }

    private recalculateStoplossValuesByPoints(): void {
        if (this.m_pStoplossCalculationStrategy) {
            const result = this.m_pStoplossCalculationStrategy.recalculateStoplossValuesByPoints(
                this.m_stoplossValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result !== null && result !== undefined) {
                this.m_stoplossValues = result
                this.recalculateLotValues()
                this.observableItem.notify(OrderModelEvent.STOPLOSS_VALUES_RECALCULATED, this)
            }
        } else {
            throw new StrangeError('Unexpected behavior: m_pStoplossCalculationStrategy should not be null.')
        }
    }

    private recalculateTakeprofitValuesByPoints(): void {
        if (this.m_pTakeprofitCalculationStrategy) {
            const result = this.m_pTakeprofitCalculationStrategy.recalculateTakeprofitValuesByPoints(
                this.m_takeprofitValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result !== null && result !== undefined) {
                this.m_takeprofitValues = result
                this.observableItem.notify(OrderModelEvent.TAKEPROFIT_VALUES_RECALCULATED, this)
            }
        } else {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            throw new StrangeError('Unexpected behavior: m_pTakeprofitCalculationStrategy should not be null.')
        }
    }

    private recalculateTakeprofitValuesByPrice(): void {
        if (this.m_pTakeprofitCalculationStrategy) {
            const result = this.m_pTakeprofitCalculationStrategy.recalculateTakeprofitValuesByPrice(
                this.m_takeprofitValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result !== null && result !== undefined) {
                this.m_takeprofitValues = result
                this.observableItem.notify(OrderModelEvent.TAKEPROFIT_VALUES_RECALCULATED, this)
            }
        } else {
            throw new StrangeError('Unexpected behavior: m_pTakeprofitCalculationStrategy should not be null.')
        }
    }

    private recalculateTakeprofitValuesByUsd(): void {
        if (this.m_pTakeprofitCalculationStrategy) {
            const result = this.m_pTakeprofitCalculationStrategy.recalculateTakeprofitValuesByUsd(
                this.m_takeprofitValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result !== null && result !== undefined) {
                this.m_takeprofitValues = result
                this.observableItem.notify(OrderModelEvent.TAKEPROFIT_VALUES_RECALCULATED, this)
            }
        } else {
            throw new StrangeError('Unexpected behavior: m_pTakeprofitCalculationStrategy should not be null.')
        }
    }

    private recalculateTakeprofitValuesByPercent(): void {
        if (this.m_pTakeprofitCalculationStrategy) {
            const result = this.m_pTakeprofitCalculationStrategy.recalculateTakeprofitValuesByPercent(
                this.m_takeprofitValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result !== null && result !== undefined) {
                this.m_takeprofitValues = result
                this.observableItem.notify(OrderModelEvent.TAKEPROFIT_VALUES_RECALCULATED, this)
            }
        } else {
            throw new StrangeError('Unexpected behavior: m_pTakeprofitCalculationStrategy should not be null.')
        }
    }

    public onChangeOperationType(newOperationType: EOperationType): void {
        const strategies = this.getStrategy(this.m_orderType, newOperationType)

        if (strategies) {
            this.m_operationType = newOperationType
            this.m_pStoplossCalculationStrategy = strategies.pCalucalationStrategy
            this.m_pTakeprofitCalculationStrategy = strategies.pTakeProfitCalculationStrategy
            this.stopLossPriceValidationStrategy = strategies.pStoplossValidationStarategy
            this.takeProfitPriceValidationStrategy = strategies.pTakeprofitValidationStrategy
            this.atPriceValidationStrategy = strategies.pPendingAtPriceValidationStrategy
            this.recalculateStoplossValues()
            this.recalculateTakeprofitValues()
            this.reValidatePricePickers()
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('No strategy found for the given operation type')
        }

        if (this.getOrderType() !== OrderType.MARKET) {
            if (this.getOperationType() === EOperationType.BUY) {
                this.m_atPrice = this.m_pMarketValues.ask
            }

            if (this.getOperationType() === EOperationType.SELL) {
                this.m_atPrice = this.m_pMarketValues.bid
            }
        }
    }

    public onTakeprofitEnabled(): void {
        this.m_takeprofitState = TakeprofitState.ENABLED

        const chart = GlobalChartsController.Instance.getActiveChart()
        if (chart) {
            const markerInfo: OrderMarkerInfo = {
                markerName: 'Take Profit',
                price: this.m_takeprofitValues.price,
                tDateTime: GlobalProcessingCore.ProcessingCore.CurrTime
            }
            this.m_takeprofitLine = new OrderMarker(chart.MainChart, markerInfo, OrderMarkerType.TAKE_PROFIT, this)
            this.m_takeprofitLine.attachObserver(this)
            this.m_takeprofitLine.values = this.m_takeprofitValues
            chart.Repaint()
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('No active chart found')
        }

        this.paintOrderMarkers()
        this.reValidateAll()
    }

    private onTakeprofitDisabled(): void {
        this.m_takeprofitState = TakeprofitState.DISABLED
        if (this.m_takeprofitLine) {
            this.m_takeprofitLine.deleteMarkerByLinkNumber()
            this.m_takeprofitLine.detachObserver(this)
            this.m_takeprofitLine = undefined
        }
        const chart = GlobalChartsController.Instance.getActiveChart()
        if (chart) {
            chart.Repaint()
        }
    }

    private getTakeprofitState(): TakeprofitState {
        return this.m_takeprofitState
    }

    public onStoplossEnabled(): void {
        this.m_stoplossState = StoplossState.ENABLED

        const chart = GlobalChartsController.Instance.getActiveChart()
        if (chart) {
            const markerInfo: OrderMarkerInfo = {
                markerName: 'Stop Loss',
                price: this.m_stoplossValues.price,
                tDateTime: GlobalProcessingCore.ProcessingCore.CurrTime
            }
            this.m_stoplossLine = new OrderMarker(chart.MainChart, markerInfo, OrderMarkerType.STOP_LOSS, this)
            this.m_stoplossLine.attachObserver(this)
            this.m_stoplossLine.values = this.m_stoplossValues
            chart.Repaint()
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('No active chart found')
        }
        this.paintOrderMarkers()
        this.reValidateAll()
    }

    private onStoplossDisabled(): void {
        this.m_stoplossState = StoplossState.DISABLED

        if (this.m_stoplossLine) {
            this.m_stoplossLine.deleteMarkerByLinkNumber()
            this.m_stoplossLine.detachObserver(this)
            this.m_stoplossLine = undefined
        }
    }

    private getStoplossState(): StoplossState {
        return this.m_stoplossState
    }

    public get marketValues(): MarketValues {
        return this.m_pMarketValues
    }

    private getStoplossInUsdStr(): string {
        return this.doubleToWstringWithPrecision(this.m_stoplossValues.usd, 2)
    }

    private getSymbol(): string {
        return this.marketValues.symbol || ''
    }

    private getAskStr(): string {
        return this.doubleToWstringWithPrecision(this.m_pMarketValues.ask, this.m_pMarketValues.postDecimalDigits)
    }

    private getBidStr(): string {
        return this.doubleToWstringWithPrecision(this.m_pMarketValues.bid, this.m_pMarketValues.postDecimalDigits)
    }

    private getSpreadStr(): string {
        return this.doubleToWstringWithPrecision(this.m_spread, 0)
    }

    private getAtPriceStr(): string {
        return this.doubleToWstringWithPrecision(this.m_atPrice, this.m_pMarketValues.postDecimalDigits)
    }

    private getStopLossRecalculationBase(): StoplossRecalculationBase {
        return this.m_stoplossRecalculationBase
    }

    private getLotStr(): string {
        return this.doubleToWstringWithPrecision(this.m_lot, 2)
    }

    private getRiskInUsdStr(): string {
        return this.doubleToWstringWithPrecision(this.m_riskInUsd, 2)
    }

    private getRiskInPercentStr(): string {
        return this.doubleToWstringWithPrecision(this.m_riskInPercent, 2)
    }

    private getStoplossPriceStr(): string {
        return this.doubleToWstringWithPrecision(
            this.m_stoplossValues.price,
            this.m_pMarketValues.postDecimalDigits,
            false
        )
    }

    private getStoplossPointsStr(): string {
        return this.doubleToWstringWithPrecision(this.m_stoplossValues.points, 0)
    }

    private getStoplossInPercentStr(): string {
        return this.doubleToWstringWithPrecision(this.m_stoplossValues.percent, 2)
    }

    private getTakeprofitPriceStr(): string {
        return this.doubleToWstringWithPrecision(
            this.m_takeprofitValues.price,
            this.m_pMarketValues.postDecimalDigits,
            false
        )
    }

    private getTakeprofitPointsStr(): string {
        return this.doubleToWstringWithPrecision(this.m_takeprofitValues.points, this.m_pMarketValues.postDecimalDigits)
    }

    public getTakeprofitInUsdStr(): string {
        return this.doubleToWstringWithPrecision(this.m_takeprofitValues.usd, 2)
    }

    public getTakeprofitInPercentStr(): string {
        return this.doubleToWstringWithPrecision(this.m_takeprofitValues.percent, 2)
    }

    private isValidValue(
        value: string,
        validationStrategy: ValidationStrategy,
        controlName: string
    ): ValidationErrorType {
        if (validationStrategy) {
            if (validationStrategy.isValid(value)) {
                this.errorManager.deleteErrorsForControl(controlName)
                return ValidationErrorType.NONE
            } else {
                if (validationStrategy.getLastErrors().type === ValidationErrorType.WARNING) {
                    this.errorManager.addError(
                        validationStrategy.getLastErrors().description,
                        controlName,
                        validationStrategy.getLastErrors().errorValue
                    )
                    return ValidationErrorType.WARNING
                } else {
                    this.errorManager.addError(
                        validationStrategy.getLastErrors().description,
                        controlName,
                        validationStrategy.getLastErrors().errorValue
                    )
                    return ValidationErrorType.CRITICAL
                }
            }
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Validation strategy not set up')
            return ValidationErrorType.UNKNOWN
        }
    }

    public onChangeStoplossPrice(newStoplossPrice: string): void {
        let validationErrorType = ValidationErrorType.NONE

        if (this.stopLossPriceValidationStrategy) {
            validationErrorType = this.isValidValue(
                newStoplossPrice,
                this.stopLossPriceValidationStrategy,
                ControlNames.STOPLOSS_PRICE
            )
        }

        if (this.m_stoplossLine) {
            this.m_stoplossLine.isValid = validationErrorType === ValidationErrorType.NONE
        }

        const isContinueCalculation =
            validationErrorType !== ValidationErrorType.CRITICAL && validationErrorType !== ValidationErrorType.UNKNOWN

        if (!isContinueCalculation) {
            return
        }

        const tempValues: {
            price: number
            points: number
            usd: number
            percent: number
        } = { ...this.m_stoplossValues }
        tempValues.price = parseFloat(newStoplossPrice)
        if (this.m_pStoplossCalculationStrategy) {
            const result = this.m_pStoplossCalculationStrategy.onChangeStoplossPrice(
                tempValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result !== null) {
                this.m_stoplossValues = result
                this.recalculateStoplossValues()
                this.observableItem.notify(OrderModelEvent.STOPLOSS_PRICE_CHANGED, this)
            }
        } else {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('m_pStoplossCalculationStrategy should not be null')
        }

        this.updateOrderMarkers()
    }

    private onChangeStoplossInUsd(newStoplossUsd: string): void {
        const validationErrorType = this.isValidValue(
            newStoplossUsd,
            this.doubleValidationStrategy,
            ControlNames.STOPLOSS_USD
        )

        const isContinueCalculation =
            validationErrorType !== ValidationErrorType.CRITICAL && validationErrorType !== ValidationErrorType.UNKNOWN

        if (!isContinueCalculation) {
            return
        }

        const tempValues: {
            price: number
            points: number
            usd: number
            percent: number
        } = { ...this.m_stoplossValues }
        tempValues.usd = parseFloat(newStoplossUsd)
        if (this.m_pStoplossCalculationStrategy) {
            const result = this.m_pStoplossCalculationStrategy.onChangeStoplossInUsd(
                tempValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result !== null) {
                this.m_stoplossValues = result
                this.recalculateStoplossValues()
                this.observableItem.notify(OrderModelEvent.STOPLOSS_USD_CHANGED, this)
            }
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('m_pStoplossCalculationStrategy should not be null')
        }
    }

    private onChangeStoplossInPercent(newStoplossPercent: string): void {
        const validationErrorType = this.isValidValue(
            newStoplossPercent,
            this.doubleValidationStrategy,
            ControlNames.STOPLOSS_PERCENT
        )

        const isContinueCalculation =
            validationErrorType !== ValidationErrorType.CRITICAL && validationErrorType !== ValidationErrorType.UNKNOWN

        if (!isContinueCalculation) {
            return
        }

        const tempValues: {
            price: number
            points: number
            usd: number
            percent: number
        } = { ...this.m_stoplossValues }
        tempValues.percent = parseFloat(newStoplossPercent)
        if (this.m_pStoplossCalculationStrategy) {
            const result = this.m_pStoplossCalculationStrategy.onChangeStoplossInPercent(
                tempValues,
                this.m_pMarketValues,
                this.m_pOrderValues
            )
            if (result !== null) {
                this.m_stoplossValues = result
                this.recalculateStoplossValues()
                this.observableItem.notify(OrderModelEvent.STOPLOSS_PERCENT_CHANGED, this)
            }
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('m_pStoplossCalculationStrategy should not be null')
        }
    }

    public getRiskRewardRatioStr(): string {
        return this.doubleToWstringWithPrecision(this.m_riskRewardRatio, 2)
    }

    public getOrderModelData(): orderModelType {
        return {
            ...CreateOrder.data,
            m_ask: Number(this.getAskStr()),
            m_bid: Number(this.getBidStr()),
            m_spread: Number(this.getSpreadStr()),
            m_operationType: this.getOperationType(),
            m_orderType: this.getOrderType(),
            m_priceForLotPoint: this.m_priceForLotPoint,
            m_lot: {
                value: Number(this.getLotStr()),
                error: this.errorManager.getErrorForControl(ControlNames.LOT)
            },
            m_atPrice: {
                value: Number(this.getAtPriceStr()),
                error:
                    this.m_orderType === OrderType.MARKET
                        ? { key: '', value: '' }
                        : this.errorManager.getErrorForControl(ControlNames.ATPRICE)
            },
            m_riskInUsd: {
                value: Number(this.getRiskInUsdStr()),
                error:
                    this.m_stoplossState === StoplossState.ENABLED
                        ? this.errorManager.getErrorForControl(ControlNames.RISK_IN_USD)
                        : { key: '', value: '' }
            },
            m_riskInPercent: {
                value: Number(this.getRiskInPercentStr()),
                error:
                    this.m_stoplossState === StoplossState.ENABLED
                        ? this.errorManager.getErrorForControl(ControlNames.RISK_IN_PERCENT)
                        : { key: '', value: '' }
            },
            m_stoplossState: this.getStoplossState(),
            m_takeprofitState: this.getTakeprofitState(),
            m_riskRewardRatio: Number(this.getRiskRewardRatioStr()),
            stoplossValues: {
                price: {
                    value: Number(this.getStoplossPriceStr()),
                    error:
                        this.m_stoplossState === StoplossState.ENABLED
                            ? this.errorManager.getErrorForControl(ControlNames.STOPLOSS_PRICE)
                            : { key: '', value: '' }
                },
                points: {
                    value: Number(this.getStoplossPointsStr()),
                    error:
                        this.m_stoplossState === StoplossState.ENABLED
                            ? this.errorManager.getErrorForControl(ControlNames.STOPLOSS_POINTS)
                            : { key: '', value: '' }
                },
                usd: {
                    value: Number(this.getStoplossInUsdStr()),
                    error:
                        this.m_stoplossState === StoplossState.ENABLED
                            ? this.errorManager.getErrorForControl(ControlNames.STOPLOSS_USD)
                            : { key: '', value: '' }
                },
                percent: {
                    value: Number(this.getStoplossInPercentStr()),
                    error:
                        this.m_stoplossState === StoplossState.ENABLED
                            ? this.errorManager.getErrorForControl(ControlNames.STOPLOSS_PERCENT)
                            : { key: '', value: '' }
                }
            },
            takeProfitValues: {
                price: {
                    value: Number(this.getTakeprofitPriceStr()),
                    error:
                        this.m_takeprofitState === TakeprofitState.ENABLED
                            ? this.errorManager.getErrorForControl(ControlNames.TAKEPROFIT_PRICE)
                            : { key: '', value: '' }
                },
                points: {
                    value: Number(this.getTakeprofitPointsStr()),
                    error:
                        this.m_takeprofitState === TakeprofitState.ENABLED
                            ? this.errorManager.getErrorForControl(ControlNames.TAKEPROFIT_POINTS)
                            : { key: '', value: '' }
                },
                usd: {
                    value: Number(this.getTakeprofitInUsdStr()),
                    error:
                        this.m_takeprofitState === TakeprofitState.ENABLED
                            ? this.errorManager.getErrorForControl(ControlNames.TAKEPROFIT_USD)
                            : { key: '', value: '' }
                },
                percent: {
                    value: Number(this.getTakeprofitInPercentStr()),
                    error:
                        this.m_takeprofitState === TakeprofitState.ENABLED
                            ? this.errorManager.getErrorForControl(ControlNames.TAKEPROFIT_PERCENT)
                            : { key: '', value: '' }
                }
            },
            pseudoFocuses: this.pseudoFocuses,
            minimumPriceChange: this.m_pMarketValues.minimumPriceChange,
            m_symbol: this.getSymbol()
        }
    }

    private updateOrderMarkers(): void {
        if (this.m_takeprofitLine) {
            this.m_takeprofitLine.points.LastItem.price = Number(this.getTakeprofitPriceStr())
            this.m_takeprofitLine.values = this.m_takeprofitValues
        }
        if (this.m_stoplossLine) {
            this.m_stoplossLine.points.LastItem.price = Number(this.getStoplossPriceStr())
            this.m_stoplossLine.values = this.m_stoplossValues
        }
        if (this.m_atPriceLine) {
            this.m_atPriceLine.points.LastItem.price = Number(this.getAtPriceStr())
            this.m_atPriceLine.posType = mapOrderTypeToTradePositionType(this.m_orderType, this.m_operationType)
        }

        const chart = GlobalChartsController.Instance.getActiveChart()
        if (chart) {
            chart.Repaint()
        }
    }

    public onPricePick(oldData: orderModelType, pricePicker: string): orderModelType {
        if (pricePicker === 'stoplossPricePick') {
            this.m_stoplossLine?.deleteMarkerByLinkNumber()
            this.m_stoplossLine?.detachObserver(this)
            this.m_stoplossLine = undefined
            this.setStoplossRecalculationBase(StoplossRecalculationBase.PRICE)
            this.pseudoFocuses = this.getPseudoFocuses(
                oldData.pseudoFocuses,
                1 as never,
                DataKeyEnum.PRICE,
                'stoplossValues'
            )
            this.onStoplossEnabled()

            if (this.m_stoplossLine) {
                ;(this.m_stoplossLine as OrderMarker).OnPicker()
            }
        }
        if (pricePicker === 'takeProfitPricePick') {
            this.m_takeprofitLine?.deleteMarkerByLinkNumber()
            this.m_takeprofitLine?.detachObserver(this)
            this.m_takeprofitLine = undefined
            this.setTakeprofitRecalculationBase(TakeprofitRecalculationBase.PRICE)
            this.pseudoFocuses = this.getPseudoFocuses(
                oldData.pseudoFocuses,
                1 as never,
                DataKeyEnum.PRICE,
                'takeProfitValues'
            )
            this.onTakeprofitEnabled()

            if (this.m_takeprofitLine) {
                ;(this.m_takeprofitLine as OrderMarker).OnPicker()
            }
        }
        if (pricePicker === 'atPricePick') {
            this.m_atPriceLine?.deleteMarkerByLinkNumber()
            const chart = GlobalChartsController.Instance.getActiveChart()
            const markerInfo: OrderMarkerInfo = {
                markerName: 'At Price',
                price: this.m_atPrice,
                tDateTime: GlobalProcessingCore.ProcessingCore.CurrTime
            }
            if (chart) {
                this.m_atPriceLine = new OrderMarker(chart.MainChart, markerInfo, OrderMarkerType.AT_PRICE, this)
                this.m_atPriceLine.posType = mapOrderTypeToTradePositionType(this.m_orderType, this.m_operationType)
                this.m_atPriceLine.attachObserver(this)
                this.m_atPriceLine?.OnPicker()
            }
        }
        return this.getOrderModelData()
    }

    public processInputChanged(
        oldData: orderModelType,
        newValue: number | string | EOperationType | OrderType | TakeprofitState | StoplossState,
        dataKey: DataKeyEnum,
        group: string
    ): orderModelType {
        if (group === 'stoplossValues') {
            //translation-ignore
            if (dataKey === DataKeyEnum.PRICE) {
                this.onChangeStoplossPrice(newValue as string)
            }
            //translation-ignore
            if (dataKey === DataKeyEnum.USD) {
                this.onChangeStoplossInUsd(newValue as string)
            }
            //translation-ignore
            if (dataKey === DataKeyEnum.PERCENT) {
                this.onChangeStoplossInPercent(newValue as string)
            }
            //translation-ignore
            if (dataKey === DataKeyEnum.POINTS) {
                this.onChangeStoplossPoints(newValue as string)
            }
        }
        if (group === 'takeProfitValues') {
            if (dataKey === DataKeyEnum.PRICE) {
                //translation-ignore
                this.onChangeTakeprofitPrice(newValue as string)
            }
            if (dataKey === DataKeyEnum.USD) {
                //translation-ignore
                this.onChangeTakeprofitInUsd(newValue as string)
            }
            if (dataKey === DataKeyEnum.PERCENT) {
                this.onChangeTakeprofitInPercent(newValue as string)
            }
            if (dataKey === DataKeyEnum.POINTS) {
                //translation-ignore
                this.onChangeTakeprofitPoints(newValue as string)
            }
        }
        if (dataKey === DataKeyEnum.M_LOT) {
            this.onChangeLot(newValue as string)
        }
        if (dataKey === DataKeyEnum.M_RISK_IN_USD) {
            this.onChangeRiskInUsd(newValue as string)
        }
        if (dataKey === DataKeyEnum.M_RISK_IN_PERCENT) {
            this.onChangeRiskInPercent(newValue as string)
        }
        if (dataKey === DataKeyEnum.M_AT_PRICE) {
            this.onChangeAtPrice(newValue as string)
        }
        if (dataKey === DataKeyEnum.M_OPERATION_TYPE) {
            this.onChangeOperationType(newValue as EOperationType)
        }
        if (dataKey === DataKeyEnum.M_ORDER_TYPE) {
            this.onChangeOrderType(newValue as OrderType)
        }
        if (dataKey === DataKeyEnum.M_TAKEPROFIT_STATE) {
            if (newValue === TakeprofitState.ENABLED) {
                this.onTakeprofitEnabled()
            } else {
                this.onTakeprofitDisabled()
            }
        }

        if (dataKey === DataKeyEnum.M_STOPLOSS_STATE) {
            if (newValue === StoplossState.ENABLED) {
                this.onStoplossEnabled()
            } else {
                this.onStoplossDisabled()
            }
        }

        this.changePointValueForOneStandartLot()
        this.onChangeSpread()
        this.onChangeRiskRewardRatio()
        this.reValidateAll()

        let newData = this.getOrderModelData()

        if (this.controlInFocus && this.controlInFocus.control) {
            newData = this.replaceValueByControlName(this.controlInFocus.control, newValue as number, newData)
            this.controlInFocus = { control: this.controlInFocus.control, value: newValue }
        }

        this.updateOrderMarkers()

        return newData
    }

    private onChangeSpread(): void {
        this.m_spread = this.m_pMarketValues.spread
    }

    public onChangeRiskRewardRatio(): void {
        if (this.m_pMarketValues) {
            this.m_riskRewardRatio = this.m_takeprofitValues.points / this.m_stoplossValues.points
        }
    }

    private replaceValueByControlName(
        controlName: string,
        value: string | number,
        data: orderModelType
    ): orderModelType {
        switch (controlName) {
            case ControlNames.STOPLOSS_PRICE: {
                data.stoplossValues.price.value = Number(value)
                break
            }
            case ControlNames.STOPLOSS_USD: {
                data.stoplossValues.usd.value = Number(value)
                break
            }
            case ControlNames.STOPLOSS_PERCENT: {
                data.stoplossValues.percent.value = Number(value)
                break
            }
            case ControlNames.STOPLOSS_POINTS: {
                data.stoplossValues.points.value = Number(value)
                break
            }
            case ControlNames.TAKEPROFIT_PRICE: {
                data.takeProfitValues.price.value = Number(value)
                break
            }
            case ControlNames.TAKEPROFIT_USD: {
                data.takeProfitValues.usd.value = Number(value)
                break
            }
            case ControlNames.TAKEPROFIT_PERCENT: {
                data.takeProfitValues.percent.value = Number(value)
                break
            }
            case ControlNames.TAKEPROFIT_POINTS: {
                data.takeProfitValues.points.value = Number(value)
                break
            }
            case ControlNames.LOT: {
                data.m_lot.value = Number(value)
                break
            }
            case ControlNames.RISK_IN_USD: {
                data.m_riskInUsd.value = Number(value)
                break
            }
            case ControlNames.RISK_IN_PERCENT: {
                data.m_riskInPercent.value = Number(value)
                break
            }
            case ControlNames.ATPRICE: {
                data.m_atPrice.value = Number(value)
                break
            }
            default: {
                throw new StrangeError(`Unexpected control name ${controlName}`)
            }
        }

        return data
    }

    public processFocusChanged(
        oldData: orderModelType,
        newValue: string | number,
        dataKey: DataKeyEnum,
        group: string
    ): orderModelType {
        this.controlInFocus = null

        if (group === 'stoplossValues') {
            if (dataKey === DataKeyEnum.PRICE) {
                this.setStoplossRecalculationBase(StoplossRecalculationBase.PRICE)
                this.controlInFocus = { control: ControlNames.STOPLOSS_PRICE, value: newValue }
            }
            if (dataKey === DataKeyEnum.USD) {
                this.setStoplossRecalculationBase(StoplossRecalculationBase.USD)
                this.controlInFocus = { control: ControlNames.STOPLOSS_USD, value: newValue }
            }
            if (dataKey === DataKeyEnum.PERCENT) {
                this.setStoplossRecalculationBase(StoplossRecalculationBase.PERCENTS)
                this.controlInFocus = { control: ControlNames.STOPLOSS_PERCENT, value: newValue }
            }
            if (dataKey === DataKeyEnum.POINTS) {
                this.setStoplossRecalculationBase(StoplossRecalculationBase.POINTS)
                this.controlInFocus = { control: ControlNames.STOPLOSS_POINTS, value: newValue }
            }
        }
        if (group === 'takeProfitValues') {
            if (dataKey === DataKeyEnum.PRICE) {
                this.setTakeprofitRecalculationBase(TakeprofitRecalculationBase.PRICE)
                this.controlInFocus = { control: ControlNames.TAKEPROFIT_PRICE, value: newValue }
            }
            if (dataKey === DataKeyEnum.USD) {
                this.setTakeprofitRecalculationBase(TakeprofitRecalculationBase.USD)
                this.controlInFocus = { control: ControlNames.TAKEPROFIT_USD, value: newValue }
            }
            if (dataKey === DataKeyEnum.PERCENT) {
                this.setTakeprofitRecalculationBase(TakeprofitRecalculationBase.PERCENTS)
                this.controlInFocus = { control: ControlNames.TAKEPROFIT_PERCENT, value: newValue }
            }
            if (dataKey === DataKeyEnum.POINTS) {
                this.setTakeprofitRecalculationBase(TakeprofitRecalculationBase.POINTS)
                this.controlInFocus = { control: ControlNames.TAKEPROFIT_POINTS, value: newValue }
            }
        }

        if (dataKey === DataKeyEnum.M_LOT) {
            this.setLotRecalculationBase(LotRecalculationBase.LOT)
            this.controlInFocus = { control: ControlNames.LOT, value: newValue }
        }
        if (dataKey === DataKeyEnum.M_RISK_IN_USD) {
            this.setLotRecalculationBase(LotRecalculationBase.RISK_IN_USD)
            this.controlInFocus = { control: ControlNames.RISK_IN_USD, value: newValue }
        }
        if (dataKey === DataKeyEnum.M_RISK_IN_PERCENT) {
            this.setLotRecalculationBase(LotRecalculationBase.RISK_IN_PERCENT)
            this.controlInFocus = { control: ControlNames.RISK_IN_PERCENT, value: newValue }
        }

        this.pseudoFocuses = this.getPseudoFocuses(oldData.pseudoFocuses, newValue as never, dataKey, group)

        this.reValidateAll()

        return this.getOrderModelData()
    }

    public getPseudoFocuses(
        oldData: pseudoFocusesType,
        newValue: never,
        dataKey: DataKeyEnum,
        group: string
    ): pseudoFocusesType {
        let result = { ...oldData }

        if (group === 'stoplossValues') {
            if (dataKey === DataKeyEnum.PRICE) {
                result = { ...result, stoplossValues: { price: true, usd: false, percent: false, points: false } }
            }
            if (dataKey === DataKeyEnum.USD) {
                result = {
                    ...result,
                    stoplossValues: { price: false, usd: true, percent: false, points: false },
                    m_lot: true,
                    m_riskInPercent: false,
                    m_riskInUsd: false
                }
                this.setLotRecalculationBase(LotRecalculationBase.LOT)
            }
            if (dataKey === DataKeyEnum.PERCENT) {
                result = {
                    ...result,
                    stoplossValues: { price: false, usd: false, percent: true, points: false },
                    m_lot: true,
                    m_riskInPercent: false,
                    m_riskInUsd: false
                }
                this.setLotRecalculationBase(LotRecalculationBase.LOT)
            }
            if (dataKey === DataKeyEnum.POINTS) {
                result = { ...result, stoplossValues: { price: false, usd: false, percent: false, points: true } }
            }
        }

        if (group === 'takeProfitValues') {
            if (dataKey === DataKeyEnum.PRICE) {
                result = { ...result, takeProfitValues: { price: true, usd: false, percent: false, points: false } }
            }
            if (dataKey === DataKeyEnum.USD) {
                result = { ...result, takeProfitValues: { price: false, usd: true, percent: false, points: false } }
            }
            if (dataKey === DataKeyEnum.PERCENT) {
                result = { ...result, takeProfitValues: { price: false, usd: false, percent: true, points: false } }
            }
            if (dataKey === DataKeyEnum.POINTS) {
                result = { ...result, takeProfitValues: { price: false, usd: false, percent: false, points: true } }
            }
        }

        if (dataKey === DataKeyEnum.M_LOT) {
            result = { ...result, m_lot: true, m_riskInPercent: false, m_riskInUsd: false }
        }
        if (dataKey === DataKeyEnum.M_RISK_IN_USD) {
            result = { ...result, m_lot: false, m_riskInPercent: false, m_riskInUsd: true }
            if (this.getStopLossRecalculationBase() === StoplossRecalculationBase.PERCENTS) {
                this.setStoplossRecalculationBase(StoplossRecalculationBase.POINTS)
                result = { ...result, stoplossValues: { price: false, usd: false, percent: false, points: true } }
            }
            if (this.getStopLossRecalculationBase() === StoplossRecalculationBase.USD) {
                this.setStoplossRecalculationBase(StoplossRecalculationBase.POINTS)
                result = { ...result, stoplossValues: { price: false, usd: false, percent: false, points: true } }
            }
        }
        if (dataKey === DataKeyEnum.M_RISK_IN_PERCENT) {
            result = { ...result, m_lot: false, m_riskInPercent: true, m_riskInUsd: false }
            if (this.getStopLossRecalculationBase() === StoplossRecalculationBase.USD) {
                this.setStoplossRecalculationBase(StoplossRecalculationBase.POINTS)
                result = { ...result, stoplossValues: { price: false, usd: false, percent: false, points: true } }
            }
            if (this.getStopLossRecalculationBase() === StoplossRecalculationBase.PERCENTS) {
                this.setStoplossRecalculationBase(StoplossRecalculationBase.POINTS)
                result = { ...result, stoplossValues: { price: false, usd: false, percent: false, points: true } }
            }
        }

        return result
    }

    public processOnBlur(
        oldData: orderModelType,
        newValue: string | number,
        dataKey: DataKeyEnum,
        group: string
    ): orderModelType {
        const result = { ...oldData }

        this.controlInFocus = null

        if (group === 'stoplossValues') {
            if (dataKey === DataKeyEnum.PRICE) {
                result.stoplossValues.price.value = Number(this.getStoplossPriceStr())
            }
            if (dataKey === DataKeyEnum.USD) {
                result.stoplossValues.usd.value = Number(this.getStoplossInUsdStr())
            }
            if (dataKey === DataKeyEnum.PERCENT) {
                result.stoplossValues.percent.value = Number(this.getStoplossInPercentStr())
            }
            if (dataKey === DataKeyEnum.POINTS) {
                result.stoplossValues.points.value = Number(this.getStoplossPointsStr())
            }
        }
        if (group === 'takeProfitValues') {
            if (dataKey === DataKeyEnum.PRICE) {
                result.takeProfitValues.price.value = Number(this.getTakeprofitPriceStr())
            }
            if (dataKey === DataKeyEnum.USD) {
                result.takeProfitValues.usd.value = Number(this.getTakeprofitInUsdStr())
            }
            if (dataKey === DataKeyEnum.PERCENT) {
                result.takeProfitValues.percent.value = Number(this.getTakeprofitInPercentStr())
            }
            if (dataKey === DataKeyEnum.POINTS) {
                result.takeProfitValues.points.value = Number(this.getTakeprofitPointsStr())
            }
        }
        if (dataKey === DataKeyEnum.M_LOT) {
            if (newValue === '') {
                result.m_lot.value = 0.1
            } else {
                result.m_lot.value = Number(this.getLotStr())
            }
        }
        if (dataKey === DataKeyEnum.M_RISK_IN_USD) {
            result.m_riskInUsd.value = Number(this.getRiskInUsdStr())
        }
        if (dataKey === DataKeyEnum.M_RISK_IN_PERCENT) {
            result.m_riskInPercent.value = Number(this.getRiskInPercentStr())
        }
        if (dataKey === DataKeyEnum.M_AT_PRICE) {
            result.m_atPrice.value = Number(this.getAtPriceStr())
        }

        this.reValidateAll()
        result.m_lot.error = this.errorManager.getErrorForControl(ControlNames.LOT)
        result.m_atPrice.error = this.errorManager.getErrorForControl(ControlNames.ATPRICE)
        result.m_riskInUsd.error = this.errorManager.getErrorForControl(ControlNames.RISK_IN_USD)
        result.m_riskInPercent.error = this.errorManager.getErrorForControl(ControlNames.RISK_IN_PERCENT)
        result.stoplossValues.price.error = this.errorManager.getErrorForControl(ControlNames.STOPLOSS_PRICE)
        result.stoplossValues.points.error = this.errorManager.getErrorForControl(ControlNames.STOPLOSS_POINTS)
        result.stoplossValues.usd.error = this.errorManager.getErrorForControl(ControlNames.STOPLOSS_USD)
        result.stoplossValues.percent.error = this.errorManager.getErrorForControl(ControlNames.STOPLOSS_PERCENT)
        result.takeProfitValues.price.error = this.errorManager.getErrorForControl(ControlNames.TAKEPROFIT_PRICE)
        result.takeProfitValues.points.error = this.errorManager.getErrorForControl(ControlNames.TAKEPROFIT_POINTS)
        result.takeProfitValues.usd.error = this.errorManager.getErrorForControl(ControlNames.TAKEPROFIT_USD)
        result.takeProfitValues.percent.error = this.errorManager.getErrorForControl(ControlNames.TAKEPROFIT_PERCENT)

        return result
    }

    private reValidateAll(): void {
        if (this.lotValidationStrategy) {
            this.isValidValue(this.getLotStr(), this.lotValidationStrategy, ControlNames.LOT)
        }

        if (this.getOrderType() !== OrderType.MARKET && this.atPriceValidationStrategy) {
            this.isValidValue(this.getAtPriceStr(), this.atPriceValidationStrategy, ControlNames.ATPRICE)
        }

        if (this.m_stoplossState === StoplossState.ENABLED) {
            this.isValidValue(this.getRiskInUsdStr(), this.doubleValidationStrategy, ControlNames.RISK_IN_USD)
            this.isValidValue(this.getRiskInPercentStr(), this.doubleValidationStrategy, ControlNames.RISK_IN_PERCENT)
            if (this.stopLossPriceValidationStrategy) {
                this.isValidValue(
                    this.getStoplossPriceStr(),
                    this.stopLossPriceValidationStrategy,
                    ControlNames.STOPLOSS_PRICE
                )
            }
            this.isValidValue(this.getStoplossPointsStr(), this.doubleValidationStrategy, ControlNames.STOPLOSS_POINTS)
            this.isValidValue(this.getStoplossInUsdStr(), this.doubleValidationStrategy, ControlNames.STOPLOSS_USD)
            this.isValidValue(
                this.getStoplossInPercentStr(),
                this.doubleValidationStrategy,
                ControlNames.STOPLOSS_PERCENT
            )
        }

        if (this.m_takeprofitState === TakeprofitState.ENABLED) {
            if (this.takeProfitPriceValidationStrategy) {
                this.isValidValue(
                    this.getTakeprofitPriceStr(),
                    this.takeProfitPriceValidationStrategy,
                    ControlNames.TAKEPROFIT_PRICE
                )
            }

            this.isValidValue(
                this.getTakeprofitPointsStr(),
                this.doubleValidationStrategy,
                ControlNames.TAKEPROFIT_POINTS
            )
            this.isValidValue(this.getTakeprofitInUsdStr(), this.doubleValidationStrategy, ControlNames.TAKEPROFIT_USD)
            this.isValidValue(
                this.getTakeprofitInPercentStr(),
                this.doubleValidationStrategy,
                ControlNames.TAKEPROFIT_PERCENT
            )
        }
    }

    private parseOrderAndOperationType(type: TTradePositionType) {
        switch (type) {
            case TTradePositionType.tp_Buy: {
                return [OrderType.MARKET, EOperationType.BUY]
            }
            case TTradePositionType.tp_Sell: {
                return [OrderType.MARKET, EOperationType.SELL]
            }
            case TTradePositionType.tp_BuyLimit: {
                return [OrderType.LIMIT, EOperationType.BUY]
            }
            case TTradePositionType.tp_SellLimit: {
                return [OrderType.LIMIT, EOperationType.SELL]
            }
            case TTradePositionType.tp_BuyStop: {
                return [OrderType.STOP, EOperationType.BUY]
            }
            case TTradePositionType.tp_SellStop: {
                return [OrderType.STOP, EOperationType.SELL]
            }
            default: {
                return [OrderType.MARKET, EOperationType.BUY]
            }
        }
    }

    public editOrder(order: OrderDataType): orderModelType {
        const symbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol(order.symbol)
        if (!symbol) {
            throw new StrangeError('Symbol not found')
        }

        this.resetInitialization()
        this.initialize()

        this.orderOnEditing = order

        this.m_pOrderValues.lot = order.lots

        this.m_pMarketValues = new MarketValues()

        this.m_pMarketValues.ask = symbol.ask
        this.m_pMarketValues.bid = symbol.bid
        this.m_pMarketValues.spread = symbol.Spread()
        this.m_pMarketValues.symbol = symbol.symbolInfo.SymbolName
        this.m_pMarketValues.minimumPriceChange = symbol.symbolInfo.MinPoint
        this.m_pMarketValues.minimumDistanceToPrice = symbol.symbolInfo.MinDistToPrice
        this.m_pMarketValues.equity = GlobalProcessingCore.ProcessingCore.Equity
        this.m_pMarketValues.postDecimalDigits = symbol.symbolInfo.decimals

        const [orderType, operationType] = this.parseOrderAndOperationType(order.type)
        this.onChangeOrderType(orderType as OrderType)
        this.onChangeOperationType(operationType as EOperationType)

        this.onChangeLot(order.lots.toString())

        if (
            order.type === TTradePositionType.tp_BuyLimit ||
            order.type === TTradePositionType.tp_SellLimit ||
            order.type === TTradePositionType.tp_BuyStop ||
            order.type === TTradePositionType.tp_SellStop
        ) {
            if ('executionPrice' in order) {
                const executionPrice = order.executionPrice
                this.onChangeAtPrice(executionPrice.toString())
            } else {
                throw new StrangeError('execution price not found for pending order, should not happen')
            }
        }
        if (order.tp !== 0) {
            this.onTakeprofitEnabled()
            this.onChangeTakeprofitPrice(order.tp.toString())
        }

        if (order.sl !== 0) {
            this.onStoplossEnabled()
            this.onChangeStoplossPrice(order.sl.toString())
        }

        if (orderType !== OrderType.MARKET) {
            this.onChangeAtPrice((order as terminalOrderPendingPositionType).executionPrice.toString())
        }

        this.changePointValueForOneStandartLot()
        this.recalculateStoplossValues()
        this.recalculateTakeprofitValues()
        this.recalculateLotValues()
        this.onChangeSpread()
        this.onChangeRiskRewardRatio()
        this.updateOrderMarkers()
        this.reValidateAll()

        GlobalProcessingCore.ProcessingCore.makeAllOpenPositionsTransparent(true)
        GlobalChartsController.Instance.setIsModalOpenToControlsManager(true)
        this.paintOrderMarkers()

        return this.getOrderModelData()
    }

    public modifyOrder(comment: string): void {
        let stoploss = 0
        let takeprofit = 0
        let atPrice = 0

        const dataForStatistics = {
            tp_points: this.getTakeprofitPointsStr(),
            tp_amount: this.getTakeprofitInUsdStr(),
            tp_percent: this.getTakeprofitInPercentStr(),
            sl_points: this.getStoplossPointsStr(),
            sl_amount: this.getStoplossInUsdStr(),
            sl_percent: this.getStoplossInPercentStr(),
            risk_amount: this.getRiskInUsdStr(),
            risk_percentage: this.getRiskInPercentStr()
        }

        if (this.getOrderType() !== OrderType.MARKET) {
            atPrice = Number(this.getAtPriceStr())
        }

        if (this.getStoplossState() === StoplossState.ENABLED) {
            stoploss = Number(this.getStoplossPriceStr())
        }
        if (this.getTakeprofitState() === TakeprofitState.ENABLED) {
            takeprofit = Number(this.getTakeprofitPriceStr())
        }

        if (this.orderOnEditing) {
            GlobalProcessingCore.ProcessingCore.ModifyOrder(
                this.orderOnEditing.id,
                Number(this.getLotStr()),
                atPrice,
                stoploss,
                takeprofit,
                comment,
                dataForStatistics
            )
            GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
        }

        GlobalChartsController.Instance.setIsModalOpenToControlsManager(false)
        GlobalChartsController.Instance.updateCharts()

        this.cleanUpAllLines()
    }

    public placeOrder(params: { comment: string; source?: string }): void {
        const symbolName = this.getSymbol()

        const symbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbolName)
        if (!symbolData || !symbolData.isCurrentTestingDateInAvailableRange) {
            //TODO: translate this
            showErrorToast({
                title: 'Cannot place order',
                message: `Data for this symbol is not available for current date`
            })
            return
        }

        const { comment, source } = params
        const operationType = TradeTypeMap[this.getOrderType()][this.getOperationType()]
        const lot = Number(this.getLotStr())
        const atPrice = Number(this.getAtPriceStr())
        let stoploss = 0
        let takeprofit = 0
        if (this.getStoplossState() === StoplossState.ENABLED) {
            stoploss = Number(this.getStoplossPriceStr())
        }
        if (this.getTakeprofitState() === TakeprofitState.ENABLED) {
            takeprofit = Number(this.getTakeprofitPriceStr())
        }

        const dataForStatistics = {
            risk_amount: this.getRiskInUsdStr(),
            risk_percentage: this.getRiskInPercentStr(),
            tp_percentage: this.getTakeprofitInPercentStr(),
            sl_percentage: this.getStoplossInPercentStr()
        }

        if (this.getOrderType() === OrderType.MARKET) {
            GlobalProcessingCore.ProcessingCore.SendMarketOrder({
                SymbolName: symbolName,
                OperationType: operationType,
                lot,
                StopLoss: stoploss,
                TakeProfit: takeprofit,
                price: 0,
                comment,
                MagicNumber: 0,
                AutoCloseTime: 0,
                dataForStatistics,
                source
            })
        } else {
            GlobalProcessingCore.ProcessingCore.SendPendingOrder(
                symbolName,
                operationType,
                lot,
                stoploss,
                takeprofit,
                atPrice,
                comment,
                0,
                0,
                dataForStatistics
            )
        }

        this.resetInitialization()

        this.cleanUpAllLines()
        GlobalChartsController.Instance.setIsModalOpenToControlsManager(false)
        GlobalChartsController.Instance.updateCharts()
    }

    public reverseOrder(order: OrderDataType): void {
        if (order.type === TTradePositionType.tp_Buy || order.type === TTradePositionType.tp_Sell) {
            GlobalProcessingCore.ProcessingCore.reversePositionMarket(order.id)
        }

        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
        GlobalChartsController.Instance.RefreshCharts(false, true)
    }

    public moveToBreakEven(order: OrderDataType): void {
        GlobalProcessingCore.ProcessingCore.MoveStopLossToSafe(order.id)
        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
        GlobalChartsController.Instance.RefreshCharts(false, true)
    }

    public doublePosition(order: OrderDataType): void {
        GlobalProcessingCore.ProcessingCore.DoublePosition(order.id)
        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
        GlobalChartsController.Instance.RefreshCharts(false, true)
    }

    private cleanUpAllLines() {
        if (this.m_takeprofitLine) {
            this.m_takeprofitLine.deleteMarkerByLinkNumber()
            this.m_takeprofitLine.detachObserver(this)
            this.m_takeprofitLine = undefined
        }
        if (this.m_stoplossLine) {
            this.m_stoplossLine.deleteMarkerByLinkNumber()
            this.m_stoplossLine.detachObserver(this)
            this.m_stoplossLine = undefined
        }
        if (this.m_atPriceLine) {
            this.m_atPriceLine.deleteMarkerByLinkNumber()
            this.m_atPriceLine.detachObserver(this)
            this.m_atPriceLine = undefined
        }
    }

    public processEvent(
        event: ProcessingCoreEvent | ChartControllerEvent | OrderMarkerEvent,
        item: TProcessingCore | GlobalChartsController | OrderMarker
    ): void {
        this._throttledProcessEvent(event, item)

        if (event in OrderMarkerEvent && item instanceof OrderMarker) {
            this.processOrderMarkerEvent(event as OrderMarkerEvent, item)
        }
    }

    private processOrderMarkerEvent(event: OrderMarkerEvent, orderMarker: OrderMarker): void {
        const { updateData } = CreateOrder

        switch (event) {
            case OrderMarkerEvent.PICK_PRICE:
            case OrderMarkerEvent.MARKER_MOVING: {
                if (this.m_takeprofitLine && orderMarker === this.m_takeprofitLine) {
                    this.onChangeTakeprofitPrice(this.m_takeprofitLine.points.LastItem.price.toString())
                } else if (this.m_stoplossLine && orderMarker === this.m_stoplossLine) {
                    this.onChangeStoplossPrice(this.m_stoplossLine.points.LastItem.price.toString())
                } else if (this.m_atPriceLine && orderMarker === this.m_atPriceLine) {
                    this.onChangeAtPrice(this.m_atPriceLine.points.LastItem.price.toString())
                }
                this.onChangeSpread()
                this.onChangeRiskRewardRatio()

                this.reValidateAll()

                updateData(this.getOrderModelData())
                break
            }
            default: {
                throw new StrangeError(`Unexpected behavior: unexpected event for order marker ${event}`)
            }
        }
    }

    private boundOnSeekCompleted = this.onSeekCompleted.bind(this)

    private onSeekCompleted(symbolData: TSymbolData) {
        const symbolState = this.symbolLoadStates.get(symbolData.symbolInfo.SymbolName)
        if (!symbolState) return

        const { symbolWaitForLoading } = symbolState
        symbolWaitForLoading?.Events.off(TDataArrayEvents.de_SeekCompleted, this.boundOnSeekCompleted)

        CreateOrder.updateLoadingState(false)
        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()

        const chart = GlobalChartsController.Instance.getActiveChart()
        this.symbolLoadStates.delete(symbolData.symbolInfo.SymbolName)

        if (chart && this.symbolLoadStates.size === 0) {
            this.initializeOrderValues()
        }
    }

    private waitForSymbol(barArrays: TBarArrays): void {
        const symbolName = barArrays.getSymbolName()

        const symbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbolName)

        if (symbol.isCurrentTestingDateInAvailableRange) {
            const chunkWaitingForLoading = symbol.fTickData.fTicks.GetOrCreateChunkByDate(
                GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true)
            )

            this.symbolLoadStates.set(symbolName, { chunkWaitingForLoading, symbolWaitForLoading: symbol })

            if (chunkWaitingForLoading && chunkWaitingForLoading.Status !== TChunkStatus.cs_Loaded) {
                // Add loader
                CreateOrder.updateLoadingState(true)
                symbol.Events.on(TDataArrayEvents.de_SeekCompleted, this.boundOnSeekCompleted)
            } else {
                // Delete loader
                this.symbolLoadStates.delete(symbolName)

                if (this.symbolLoadStates.size === 0) {
                    this.initializeOrderValues()
                }
                CreateOrder.updateLoadingState(false)
            }
        }
    }

    private changePointValueForOneStandartLot(): void {
        const symbolData = GlobalSymbolList.SymbolList.GetExistingSymbol_ThrowErrorIfNull(this.getSymbol())

        const operationTypeInTradePosition = mapOrderTypeToTradePositionType(
            this.getOrderType(),
            this.getOperationType()
        )

        let priceForLotPoint
        const undf = undefined

        if (this.orderOnEditing) {
            priceForLotPoint = GlobalProcessingCore.ProcessingCore.GetOpenPos(this.orderOnEditing.id).tpos.margin
        } else {
            priceForLotPoint =
                symbolData.symbolInfo
                    .getSymbolCalculationStrategy()
                    .calculateMarginRequirementForSymbol(operationTypeInTradePosition, undf) * this.m_lot
        }

        if ([OrderType.LIMIT, OrderType.STOP].includes(this.m_orderType)) {
            priceForLotPoint =
                symbolData.symbolInfo
                    .getSymbolCalculationStrategy()
                    .calculateMarginRequirementForSymbol(operationTypeInTradePosition, this.m_atPrice) * this.m_lot
        }

        const valueForOneStandartLot = symbolData.symbolInfo
            .getSymbolCalculationStrategy()
            .getPointCostForOneStandardLot(operationTypeInTradePosition)

        if (valueForOneStandartLot) {
            this.m_pMarketValues.pointValueForOneStandardLot = valueForOneStandartLot
            this.m_priceForLotPoint = Number(this.doubleToWstringWithPrecision(priceForLotPoint, 2))
        } else {
            throw new StrangeError('Unexpected behavior: point value for one standard lot should not be null')
        }
    }

    private processProcessingCoreEvent(event: ProcessingCoreEvent, item: TProcessingCore): void {
        const chartWin = GlobalChartsController.Instance.getActiveChart()
        const { updateData } = CreateOrder

        if (event === ProcessingCoreEvent.UPDATE_HISTORY && chartWin) {
            this.m_pMarketValues.symbol = chartWin.SymbolData.symbolInfo.SymbolName
            this.m_pMarketValues.minimumPriceChange = chartWin.SymbolData.symbolInfo.MinPoint
            this.m_pMarketValues.minimumDistanceToPrice = chartWin.SymbolData.symbolInfo.MinDistToPrice

            if (chartWin.SymbolData.IsSeeked) {
                this.m_pMarketValues.ask = chartWin.SymbolData.ask
                this.m_pMarketValues.bid = chartWin.SymbolData.bid
                this.m_pMarketValues.spread = chartWin.SymbolData.Spread()

                this.m_pMarketValues.equity = item.Equity
                this.observableItem.notify(OrderModelEvent.ON_TICK, this)

                this.changePointValueForOneStandartLot()
                this.recalculateStoplossValues()
                this.recalculateTakeprofitValues()
                this.recalculateLotValues()
                this.onChangeSpread()
                this.onChangeRiskRewardRatio()
                this.updateOrderMarkers()

                let newData = this.getOrderModelData()

                if (this.controlInFocus) {
                    //TODO: is this a good fix for null in this.controlInFocus?
                    newData = this.replaceValueByControlName(
                        this.controlInFocus.control,
                        this.controlInFocus.value,
                        newData
                    )
                }

                updateData((prevSettings) => ({
                    ...prevSettings,
                    ...newData
                }))
            }
        }
    }

    private processChartControllerEvent(event: ChartControllerEvent, item: GlobalChartsController) {
        const chartWin = item.getActiveChart()

        switch (event) {
            case ChartControllerEvent.ACTIVE_CHART_CHANGED: {
                if (chartWin) {
                    this.m_pMarketValues.ask = chartWin.SymbolData.ask
                    this.m_pMarketValues.bid = chartWin.SymbolData.bid
                    this.m_pMarketValues.spread = chartWin.SymbolData.Spread()
                    this.m_pMarketValues.symbol = chartWin.SymbolData.symbolInfo.SymbolName
                    this.m_pMarketValues.minimumPriceChange = chartWin.SymbolData.symbolInfo.MinPoint
                    this.m_pMarketValues.minimumDistanceToPrice = chartWin.SymbolData.symbolInfo.MinDistToPrice
                    this.m_pMarketValues.equity = GlobalProcessingCore.ProcessingCore.Equity
                    this.observableItem.notify(OrderModelEvent.ON_TICK, this)
                } else {
                    throw new StrangeError(
                        'Unexpected behavior: chart should not be null - procaeesChartControllerEvent'
                    )
                }
                break
            }
            case ChartControllerEvent.SYMBOL_CHANGED_ON_CHART: {
                this.updateOrderModal()
                break
            }
            default: {
                throw new StrangeError(`Unexpected behavior: unexpected event for chart controller ${event}`)
            }
        }
    }

    private doubleToWstringWithPrecision(value: number, precision: number, trimTrailingNull = true): string {
        if (Number.isNaN(value)) {
            return 'NaN'
        }

        if (!Number.isFinite(value)) {
            return value < 0 ? '-inf' : 'inf'
        }

        let str: string = value.toFixed(precision)

        if (trimTrailingNull) {
            const dotPos: number = str.indexOf('.')
            if (dotPos !== -1) {
                str = str.replace(/0+$/, '')
                if (str.endsWith('.')) {
                    str = str.slice(0, -1)
                }
            }
        }

        return str
    }

    public static getSymbolListForDropDown(): string[] {
        // use it like: OrderModel.getSymbolListForDropDown()
        const symbolsSet = new Set(
            GlobalChartsController.Instance.getAllCharts()
                .filter((chartWin) => chartWin.isShown && chartWin.SymbolData.isCurrentTestingDateInAvailableRange)
                .map((chartWin) => chartWin.SymbolData.symbolInfo.SymbolName)
        )

        return [...symbolsSet]
    }

    public setNewSymbol(symbol: string): void {
        const chart = GlobalChartsController.Instance.getChartBySymbol(symbol)
        if (chart) {
            GlobalChartsController.Instance.forceActivateChart(chart)
            this.changeMarketValues(symbol, chart)
            this.resetInitialization()
            this.initialize()
        }
    }

    public changeMarketValues(symbol: string, chartWin: TChartWindow): void {
        this.m_pMarketValues.symbol = symbol
        this.m_pMarketValues.ask = chartWin.SymbolData.ask
        this.m_pMarketValues.bid = chartWin.SymbolData.bid
        this.m_pMarketValues.spread = chartWin.SymbolData.Spread()
        this.m_pMarketValues.minimumPriceChange = chartWin.SymbolData.symbolInfo.MinPoint
        this.m_pMarketValues.minimumDistanceToPrice = chartWin.SymbolData.symbolInfo.MinDistToPrice
        this.m_pMarketValues.equity = GlobalProcessingCore.ProcessingCore.Equity
        this.m_pMarketValues.postDecimalDigits = chartWin.SymbolData.symbolInfo.decimals
        this.changePointValueForOneStandartLot()
    }

    public updateOrderModal(): void {
        const chartWin = GlobalChartsController.Instance.getActiveChart()
        if (chartWin && chartWin.SymbolData.isCurrentTestingDateInAvailableRange) {
            this.changeMarketValues(chartWin.SymbolData.symbolInfo.SymbolName, chartWin)

            this.resetInitialization()
            this.initialize()
        }
    }

    public onOrderModalClose(): void {
        if (this.orderOnEditing) {
            try {
                const tpos = GlobalProcessingCore.ProcessingCore.GetOpenPos(this.orderOnEditing.id)
                tpos.onEditFinished()
            } catch {
                /* empty */
            }
        }
        GlobalChartsController.Instance.setIsModalOpenToControlsManager(false)
    }

    public paintOrderMarkers(): void {
        if (this.m_takeprofitLine) {
            this.m_takeprofitLine.OnPicker(true)
            this.m_takeprofitLine.Paint()
        }
        if (this.m_stoplossLine) {
            this.m_stoplossLine.OnPicker(true)
            this.m_stoplossLine.Paint()
        }
        if (this.m_atPriceLine) {
            this.m_atPriceLine.OnPicker(true)
            this.m_atPriceLine.Paint()
        }
    }

    reValidatePricePickers() {
        this.reValidateAll()

        if (this.m_takeprofitLine) {
            this.m_takeprofitLine.isValid =
                this.errorManager.getErrorForControl(ControlNames.TAKEPROFIT_PRICE).key === ''
        }
        if (this.m_stoplossLine) {
            this.m_stoplossLine.isValid = this.errorManager.getErrorForControl(ControlNames.STOPLOSS_PRICE).key === ''
        }
        if (this.m_atPriceLine) {
            this.m_atPriceLine.isValid = this.errorManager.getErrorForControl(ControlNames.ATPRICE).key === ''
        }
    }
}
