import { ParamsType } from '@fto/lib/store/news/types'
import { ALL_COUNTRIES } from '@root/constants/countries'

export const initialData: ParamsType = {
    isNewsTabOpen: false,
    news: {
        previous: [],
        upcoming: []
    },
    filters: {
        priority: ['high'],
        selectedCountries: ALL_COUNTRIES.map((country) => country.name),
        interval: 'month'
    },
    lastBarTime: null
}
