import { useState, FC, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography, Checkbox } from '@fto/ui'

import { TBufferStyle } from '@fto/lib/drawing_interface/vclCanvas'
import LineStylePicker from '@root/pages/ChartPage/components/lineStylePicker'
import { TColor, TPenStyle } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'

type BufferStyle = {
    color: TColor
    style: TPenStyle
    width: number
}

interface BufferStylePickerProps {
    data: { [key: string]: TBufferStyle }
    attribute: string
    name: string
    optionKey?: string | number
    setIndicatorOptions: (value: any, shouldTemplateReset: boolean) => void
}

const BufferStylePicker: FC<BufferStylePickerProps> = ({ data, attribute, name, optionKey, setIndicatorOptions }) => {
    const [isVisible, setIsVisible] = useState(data[attribute].isVisible)

    const onBufferStylesChange = useCallback(
        (value: BufferStyle, isVisible: boolean, shouldTemplateReset = true) => {
            const { color, width, style } = value

            if (optionKey) {
                setIndicatorOptions(
                    (prevData: any) => ({
                        ...prevData,
                        [optionKey]: {
                            ...prevData[optionKey],
                            fvalue: new TBufferStyle(color, style, width, isVisible)
                        }
                    }),
                    shouldTemplateReset
                )
                return
            }

            setIndicatorOptions((prevData: any) => {
                return {
                    ...prevData,
                    ['fValue']: new TBufferStyle(color, style, width, isVisible)
                }
            }, shouldTemplateReset)
        },
        [optionKey, setIndicatorOptions, isVisible]
    )

    const setNewStyleValue = useCallback(
        (value: BufferStyle) => {
            onBufferStylesChange(value, isVisible, true)
        },
        [onBufferStylesChange, data[attribute]]
    )

    const setIsVisibleValue = useCallback(
        (value: boolean) => {
            setIsVisible(value)
            onBufferStylesChange(data[attribute], value, true)
        },
        [onBufferStylesChange, data[attribute]]
    )

    useEffect(() => {
        if (data[attribute].isVisible !== isVisible) setIsVisible(data[attribute].isVisible)
    }, [data[attribute]])

    const lineStylesData = useMemo(() => {
        return {
            [attribute]: {
                width: data[attribute].width,
                style: data[attribute].style,
                color: data[attribute].color
            }
        }
    }, [attribute, data])

    return (
        <Flex alignItems='center' justifyContent='space-between' gap={8}>
            <Flex gap={8} alignItems='center'>
                <Checkbox type='checkbox' checked={isVisible} onChange={setIsVisibleValue} />
                <Typography type='interface-regular' color='gray-1000'>
                    {name}
                </Typography>
            </Flex>
            <LineStylePicker data={lineStylesData} attribute={attribute} onChange={setNewStyleValue} />
        </Flex>
    )
}

export default BufferStylePicker
