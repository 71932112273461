import { TextJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { TPaintToolType } from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { TPoint, TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { IGPFont, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { StylingHelper } from '@fto/lib/drawing_interface/StylingHelper'
import { TLineStyle, TMkFontStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { CustomCursorPointers } from '@fto/lib/ft_types/common/CursorPointers'

import { TChart } from '../../chart_classes/BasicChart'
import { TBasicPaintTool } from '../BasicPaintTool'
import { PaintToolNames } from '../PaintToolNames'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export enum TScrHAlignment {
    ha_Left,
    ha_Right
}

export enum TScrVAlignment {
    va_Top,
    va_Bottom
}

export enum TAlignment {
    taLeftJustify,
    taRightJustify,
    taCenter
}

export enum TTextLayout {
    tlTop,
    tlCenter,
    tlBottom
}

export class TPtText extends TBasicPaintTool {
    private fVAlignment: TTextLayout
    private fHAlignment: TAlignment
    private fScrVAlignment: TScrVAlignment
    private fScrHAlignment: TScrHAlignment

    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptText)
        this.ShortName = 'Text'
        this.fToolType = TPaintToolType.tt_Text
        this.fMaxPoints = 1
        this.fClosedPolygon = false
        this.fShouldFillInside = false
        this.CursorStyle = CustomCursorPointers.crCross
        // Assuming fFontStyle is an object with a size property
        this.fFontStyle.size = 20
        this.icon = 43
        this.fVAlignment = TTextLayout.tlTop
        this.fHAlignment = TAlignment.taLeftJustify
        this.fScrVAlignment = TScrVAlignment.va_Top
        this.fScrHAlignment = TScrHAlignment.ha_Left

        this.fText = 'Text'
        this.hasText = true
        this.hasLines = false

        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            this.fFontStyle.size,
            StylingHelper.ConvertFontStyle(this.fFontStyle.style)
        )
        this.brush = new IGPSolidBrush(this.fFontStyle.color)
        //TODO: Implement TextFrm equivalent in TypeScript or find a workaround
        // this.fEditDialog = new NotImplementedError('TextFrm equivalent is not implemented')

        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptText)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptText)
        }

        if (!styles) throw new StrangeError('Default styles for Text are not found')

        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            styles.text.size,
            StylingHelper.getTGpFontStyle({
                style: styles.text.style,
                weight: styles.text.weight
            })
        )
        this.brush.setColor(styles.text.color)
    }

    clone(): TPtText {
        const cloneObj = new TPtText(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        cloneObj.fVAlignment = this.fVAlignment
        cloneObj.fHAlignment = this.fHAlignment
        cloneObj.fScrVAlignment = this.fScrVAlignment
        cloneObj.fScrHAlignment = this.fScrHAlignment
        cloneObj.fText = this.fText

        return cloneObj
    }

    assign(tool: TBasicPaintTool, isCopy = false): void {
        super.assign(tool, isCopy)

        if (tool instanceof TPtText) {
            this.fVAlignment = tool.fVAlignment
            this.fHAlignment = tool.fHAlignment
            this.fScrVAlignment = tool.fScrVAlignment
            this.fScrHAlignment = tool.fScrHAlignment
            this.fText = tool.fText
            this.font = tool.font
        }
    }

    public toJson(): TextJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            VAlignment: this.fVAlignment,
            HAlignment: this.fHAlignment,
            ScrVAlignment: this.fScrVAlignment,
            ScrHAlignment: this.fScrHAlignment,
            Text: this.fText
        }
    }

    fromJSON(json: TextJSON) {
        super.fromJSON(json)

        this.fVAlignment = json.VAlignment
        this.fHAlignment = json.HAlignment
        this.fScrVAlignment = json.ScrVAlignment
        this.fScrHAlignment = json.ScrHAlignment
        this.fText = json.Text
        this.font = IGPFont.fromString(json.font)
    }

    EdgeUnderMouse(x: number, y: number): number {
        if (this.GetFrameRect().PtInRect(new TPoint(x, y))) {
            return 0
        } else {
            return -1
        }
    }

    GetFrameRect(): TRect {
        let w = 0,
            h = 0,
            x: number,
            y: number
        let R: TRect
        let s: string, s1: string

        R = new TRect(0, 0, 0, 0)

        x = this.fPoints[0].x
        y = this.fPoints[0].y

        if (this.fScrHAlignment === TScrHAlignment.ha_Right) {
            x = R.Right - x
        }

        if (this.fScrVAlignment === TScrVAlignment.va_Bottom) {
            y = R.Bottom - y
        }

        // get text width/height
        s = this.fText
        // s = StrsConv.GetQuotedStr(s, '{', '}') // Assuming getQuotedStr is implemented
        w = Math.max(w, this.fChart.GdiCanvas.TextWidth(s, this.font))
        h++

        h = this.fChart.GdiCanvas.TextHeight('0', this.font) * h

        // Adjust x, y based on alignment
        switch (this.fVAlignment) {
            case TTextLayout.tlCenter: {
                y -= h / 2
                break
            }
            case TTextLayout.tlBottom: {
                y -= h
                break
            }
        }

        switch (this.fHAlignment) {
            case TAlignment.taCenter: {
                x -= w / 2
                break
            }
            case TAlignment.taRightJustify: {
                x -= w
                break
            }
        }

        const result = new TRect(x, y, x + w, y + h)

        result.Inflate(2, 2) // add some padding for the text

        return result
    }

    Paint(): void {
        if (this.fPoints.length === 0) return

        const R: TRect = this.GetFrameRect()
        const w: number = R.Right - R.Left
        const h: number = this.fChart.GdiCanvas.TextHeight('0', this.font)
        const s: string = this.fText
        let ws: number

        // s = StrsConv.GetQuotedStr(s, '{', '}') // Assuming GetQuotedStr is implemented
        ws = this.fChart.GdiCanvas.TextWidth(s, this.font)
        switch (this.fHAlignment) {
            case TAlignment.taLeftJustify: {
                this.fChart.GdiCanvas.textOut(R.Left, R.Bottom, s, this.font, this.brush)
                break
            }
            case TAlignment.taCenter: {
                this.fChart.GdiCanvas.textOut(R.Left + (w - ws) / 2, R.Bottom, s, this.font, this.brush)
                break
            }
            case TAlignment.taRightJustify: {
                this.fChart.GdiCanvas.textOut(R.Right - ws, R.Bottom, s, this.font, this.brush)
                break
            }
        }

        R.Top += h
        if (this.fSelected) this.paintFrame()
        this.PaintMarkers()
    }

    set Text(value: string) {
        this.fText = value
    }

    set FontStyle(value: TMkFontStyle) {
        this.fFontStyle = value

        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            this.fFontStyle.size,
            StylingHelper.ConvertFontStyle(this.fFontStyle.style)
        )

        this.brush = new IGPSolidBrush(this.fFontStyle.color)
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context
        const fontParams = this.font.getFontParams()

        const fontStyleParams = StylingHelper.getFontStyleParams(fontParams.fontStyles)

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            textValue: {
                value: this.fText,
                label: 'toolsModal.fields.text',
                type: 'textArea',
                key: 'textValue',
                disabled: false
            },
            textStyle: {
                key: 'textStyle',
                type: 'textStyle',
                label: 'toolsModal.fields.textStyle',
                value: {
                    color: ColorHelperFunctions.BasicColor(this.brush.getColor()),
                    size: fontParams.size,
                    style: fontStyleParams.style,
                    weight: fontStyleParams.weight
                }
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)
        if (!this.ExportToDialogBool) {
            return
        }
        addModal(MODAL_NAMES.chart.graphTools, { toolType: PaintToolNames.ptText, toolName: 'text' })
    }

    ImportFromDialog(): void {
        const { getKeyValueData, resetToolSettings } = GraphToolStore // Use the store/context

        const formattedToolSettings = getKeyValueData()

        this.chart.ChartWindow.saveStateWithNotify()

        this.description = formattedToolSettings.description

        this.fText = formattedToolSettings.textValue

        // this.fFontStyle.size = formattedToolSettings.textStyle.size
        let fontName = this.fFontStyle.name

        if (!fontName) {
            fontName = 'Roboto Flex'
        }

        this.font = new IGPFont(
            new TGPFontFamily(fontName),
            formattedToolSettings.textStyle.size,
            StylingHelper.getTGpFontStyle({
                style: formattedToolSettings.textStyle.style,
                weight: formattedToolSettings.textStyle.weight
            })
        )
        this.brush.setColor(formattedToolSettings.textStyle.color)

        this.saveToManager()

        resetToolSettings()
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }
    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptText, {
            text: {
                color: this.brush.getColor(),
                size: this.font.getFontParams().size,
                style: StylingHelper.getFontStyleParams(this.font.getFontParams().fontStyles).style,
                weight: StylingHelper.getFontStyleParams(this.font.getFontParams().fontStyles).weight
            },
            toolName: PaintToolNames.ptText
        })
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptText, TPtText)
