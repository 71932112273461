import { OptionType } from '../types'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { ChartEvent } from '@fto/lib/charting/auxiliary_classes_charting/ChartingEnums'

export const tools = (t: (v: string) => string) =>
    [
        { name: t('graphicTools.toolNames.measure'), icon: 'ruler', toolKey: 'Measure' },
        { name: t('graphicTools.toolNames.text'), icon: 'text', toolKey: 'Text' },
        { name: t('graphicTools.toolNames.andrewsPitchfork'), icon: 'pitchfork', toolKey: 'Andrews Pitchfork' }
    ] as OptionType[]

export const cursorModeDropdownOptions = (t: (v: string) => string) => [
    {
        option: {
            name: t('graphicTools.toolNames.cursor'),
            icon: 'cursor',
            toolKey: 'cursor'
        } as OptionType,
        action: () => {
            const chart = GlobalChartsController.Instance.getActiveChart()
            chart?.notifyFromThisChartWindow(ChartEvent.CROSS_HAIR_MODE_END)
        }
    },
    {
        option: {
            name: t('graphicTools.toolNames.crosshair'),
            icon: 'crosshair',
            toolKey: 'crosshair'
        } as OptionType,
        action: () => {
            const chart = GlobalChartsController.Instance.getActiveChart()
            chart?.notifyFromThisChartWindow(ChartEvent.CROSS_HAIR_MODE_START_PERMANENT)
        }
    }
]
export const deleteItemsDropdownOptions = (t: (v: string) => string) => [
    {
        option: { name: t('graphicTools.deleteAll'), icon: 'delete', toolKey: 'All' } as OptionType,
        action: () => {
            const chart = GlobalChartsController.Instance.getActiveChart()
            chart?.DeleteAllTools()
        }
    },
    {
        option: {
            name: t('graphicTools.deleteRecent'),
            icon: 'delete-recent',
            toolKey: 'Recent'
        } as OptionType,
        action: () => {
            const chart = GlobalChartsController.Instance.getActiveChart()
            chart?.DeleteLastTool()
        }
    },
    {
        option: {
            name: t('graphicTools.deleteSelected'),
            icon: 'delete-selected',
            toolKey: 'Selected'
        } as OptionType,
        action: () => {
            const chart = GlobalChartsController.Instance.getActiveChart()
            chart?.DeleteSelectedItems()
        }
    }
]
