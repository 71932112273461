import { SelectableControl } from '@fto/lib/processing_core/TradePositionClasses/SelectableControl'
import { TPoint, TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { ChartControl, chartControlEvent, ChartControlParams, LocationParams } from '@fto/chart_components/ChartControl'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import { ValidationStrategy } from '@fto/lib/OrderModalClasses/Validations/ValidationStrategy'
import { TTradePos } from '@fto/lib/processing_core/TradePositionClasses/TradePosition'
import { StoplossCalculationStrategy } from '@fto/lib/OrderModalClasses/StoplossCalculationStrategies/StoplossCalculationStrategy'
import { TakeprofitCalculationStrategy } from '@fto/lib/OrderModalClasses/TakeProfitCalculationStrategies/TakeprofitCalculationStrategy'
import { CloseBtn } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/CloseBtn'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { TCursor } from '@fto/lib/ft_types/common/CursorPointers'
import { getTextWidth } from '@fto/lib/globals/GlobalTextSizeCache'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { t } from 'i18next'
import { getOrderTypeLabel } from '@root/pages/ChartPage/components/Terminal/components/Table/components/Body/components/Row/components/CellRenderer/utils'
import {
    OrderDataType,
    terminalOrderBaseType,
    terminalOrderPendingPositionType
} from '@fto/lib/store/ordersStore/types'
import { TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import CreateOrderStore from '@fto/lib/store/createOrder'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { isCtrlEvent } from '@fto/lib/utils/eventsUtils'
import { CanvasLayer, LayerType } from '@fto/lib/charting/auxiliary_classes_charting/Layers/Layers'

export type OrderLevelType = 'Stoploss' | 'Takeprofit' | 'OpenPrice'

export class OrderLevel extends SelectableControl {
    public invalidColor = '#98A2B3'
    protected readonly validationStrategy: ValidationStrategy | null
    public isValid = true
    public owner: TTradePos
    protected calculationStrategy: StoplossCalculationStrategy | TakeprofitCalculationStrategy | null
    protected readonly type: OrderLevelType
    public closeBtn: CloseBtn | null = null
    public infoBoxLocation: TRect = new TRect(0, 0, 0, 0)
    public chartWindow: TChartWindow
    public isNeedToValidate = true

    constructor(
        chartControlParams: ChartControlParams,
        validationStrategy: ValidationStrategy | null = null,
        owner: TTradePos,
        calculationStrategy: StoplossCalculationStrategy | TakeprofitCalculationStrategy | null = null,
        type: OrderLevelType,
        chartWindow: TChartWindow
    ) {
        super(chartControlParams)

        this.attachObserver(chartWindow)

        this.validationStrategy = validationStrategy
        this.calculationStrategy = calculationStrategy
        this.owner = owner
        this.type = type
        this.chartWindow = chartWindow
    }

    protected drawCommonElements(
        canvas: TGdiPlusCanvas,
        x: number,
        y: number,
        labelText: string,
        valueText: string,
        pointsText: string,
        ticketText: string,
        isActive: boolean,
        baseColor: string,
        boxHeight: number,
        font: string,
        isNeedPaintCloseBtn = true,
        isNeedDragAndDrop = true
    ): number {
        const ctx = canvas.graphics.Context
        ctx.font = font

        // Measure text widths
        const ticketTextWidth = getTextWidth(ctx, ticketText, font)
        const labelTextWidth = getTextWidth(ctx, labelText, font)
        const valueTextWidth = getTextWidth(ctx, `${valueText} `, font, true)
        const pointsTextWidth = getTextWidth(ctx, pointsText, font)

        // Calculate box width
        let textWidth = labelTextWidth + valueTextWidth
        let boxWidth = textWidth + 24 // boxPadding * 3
        const borderRadius = 2

        if (isActive) {
            textWidth += pointsTextWidth + ticketTextWidth + 24 // boxPadding * 3
            boxWidth = textWidth + 24 // boxPadding * 3
        }

        const boxX = x - boxWidth / 2
        const boxY = y - boxHeight / 2

        if (!isNeedDragAndDrop) {
            boxWidth -= 5
        }

        // Draw elements
        this.drawDashedLine(ctx, 0, y, ctx.canvas.width, y, baseColor, isActive)
        this.drawRoundedRect(ctx, boxX, boxY, boxWidth, boxHeight, borderRadius, baseColor)

        // Initial positions for texts
        let labelX = boxX + 10
        let separatorX1 = boxX + 10 + labelTextWidth + 5
        let separatorBetweenTicketAndLabel = boxX + 15 + ticketTextWidth + 7.5

        if (!isNeedDragAndDrop) {
            labelX = boxX + 5
            separatorX1 = boxX + 5 + labelTextWidth + 5
            separatorBetweenTicketAndLabel = boxX + 5 + ticketTextWidth + 7.5
        }

        if (isActive) {
            labelX = separatorBetweenTicketAndLabel + 5
            separatorX1 += ticketTextWidth + 17 // boxPadding * 1.5 + 5
            this.fillBackground(ctx, boxX, boxY, separatorX1 - boxX, boxHeight, baseColor)
        }

        if (isNeedDragAndDrop) {
            this.drawDragDots(ctx, boxX + 2.5, y - 3, baseColor, isActive)
        }

        if (isActive) {
            this.drawTicketText(ctx, ticketText, boxX, y, baseColor, font, isNeedDragAndDrop)
            this.drawSeparatorLine(ctx, separatorBetweenTicketAndLabel, boxY + 1, 14, '#00000033')
        }

        this.drawText(ctx, labelText, labelX, y, baseColor, font, isActive)
        this.drawSeparatorLine(ctx, separatorX1, boxY + 1, 14, baseColor)

        const valueTextX = separatorX1 + 5
        this.drawText(ctx, valueText, valueTextX, y, baseColor, font)

        let endX = valueTextX + valueTextWidth + 15

        if (isActive) {
            const separatorX2 = valueTextX + valueTextWidth
            this.drawSeparatorLine(ctx, separatorX2, boxY + 1, 14, baseColor)
            this.drawText(ctx, pointsText, separatorX2 + 5, y, baseColor, font)
            endX = separatorX2 + pointsTextWidth + 17.5

            if (isNeedPaintCloseBtn) {
                this.handleCloseButton(endX, y, baseColor, canvas)
            }
        }

        this.infoBoxLocation = new TRect(boxX, boxY, boxX + boxWidth, boxY + boxHeight)
        return endX
    }

    private drawTicketText(
        ctx: CanvasRenderingContext2D,
        ticketText: string,
        boxX: number,
        y: number,
        baseColor: string,
        font: string,
        isNeedDragAndDrop: boolean
    ) {
        const ticketX = isNeedDragAndDrop ? boxX + 15 : boxX + 5
        this.drawText(ctx, ticketText, ticketX, y, '#FFFFFF', font)
    }

    private handleCloseButton(endX: number, y: number, baseColor: string, canvas: TGdiPlusCanvas) {
        if (this.closeBtn) {
            this.closeBtn.setLocation(new TRect(endX, y - 8, endX + 40, y + 8))
            this.closeBtn.mainColor = baseColor
        }
        this.drawCloseBtn(canvas)
    }

    public draw(canvas: TGdiPlusCanvas) {
        // This method should be implemented in derived classes
    }

    public recalculateLocationByY(price: number): void {
        const location = this.getLocation()

        const y = this.chartWindow.MainChart.GetY(price)
        const paintRect = this.chartWindow.MainChart.GetPaintRect()

        location.Top = y
        location.Bottom = y + 1
        location.Left = paintRect.Left
        location.Right = paintRect.Right

        if (this.validationStrategy && this.isNeedToValidate) {
            this.isValid = this.validationStrategy.isValid(price.toString())
        }

        this.setLocation(location)
    }

    onMouseMove(event: MouseEvent, sender: TMainChart): ChartControl | null {
        this.closeBtn?.onMouseMove(event, sender)
        if (this.IsCaptured() && this.IsDraggable()) {
            for (const levels of this.owner.orderLevels) {
                if (levels[1].takeProfitOrderLevel && this.type === 'Takeprofit') {
                    levels[1].takeProfitOrderLevel?.capturedMouseMove(event, sender)
                }
                if (levels[1].stopLossOrderLevel && this.type === 'Stoploss') {
                    levels[1].stopLossOrderLevel?.capturedMouseMove(event, sender)
                }
                if (levels[1].openPriceOrderLevel && this.type === 'OpenPrice') {
                    levels[1].openPriceOrderLevel?.capturedMouseMove(event, sender)
                }
            }

            return this
        } else {
            if (this.isMouseInsideInfoBox(new TPoint(event.clientX, event.clientY))) {
                this.onMouseEnterControl()
                return this
            }

            return super.onMouseMove(event, sender)
        }
    }

    capturedMouseMove(event: MouseEvent, sender: TChart): ChartControl | null {
        const y = event.clientY

        if (this.IsCaptured()) {
            this.recalculateLocationByY(sender.GetPriceFromY(y))
        }

        return this
    }

    protected isPointInside(x: number, y: number): boolean {
        const location = this.getLocation()

        const mouseSensitiveArea = 2
        return (
            x >= location.Left - mouseSensitiveArea &&
            x <= location.Right + mouseSensitiveArea &&
            y >= location.Top - mouseSensitiveArea &&
            y <= location.Bottom + mouseSensitiveArea
        )
    }

    protected fillBackground(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        color: string
    ): void {
        ctx.save()
        ctx.fillStyle = color
        ctx.globalAlpha = this.owner.isTransparent ? 0.5 : 1

        ctx.fillRect(x, y, width, height)
        ctx.restore()
    }

    protected drawDashedLine(
        ctx: CanvasRenderingContext2D,
        startX: number,
        startY: number,
        endX: number,
        endY: number,
        color: string,
        isActive: boolean
    ): void {
        ctx.save()
        ctx.strokeStyle = color
        ctx.setLineDash([5, 5])
        ctx.lineWidth = 1
        ctx.globalAlpha = isActive ? 1 : 0.3
        ctx.beginPath()
        ctx.moveTo(startX, startY)
        ctx.lineTo(endX, endY)
        ctx.stroke()
        ctx.restore()
    }

    protected drawRoundedRect(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        borderRadius: number,
        color: string
    ): void {
        ctx.save()
        ctx.fillStyle = '#FFFFFF'
        ctx.strokeStyle = color
        ctx.lineWidth = 1
        ctx.shadowColor = 'rgba(0, 29, 67, 0.05)'
        ctx.shadowBlur = 2
        ctx.shadowOffsetY = 1
        ctx.globalAlpha = this.owner.isTransparent ? 0.5 : 1

        ctx.beginPath()
        ctx.moveTo(x + borderRadius, y)
        ctx.lineTo(x + width - borderRadius, y)
        ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius)
        ctx.lineTo(x + width, y + height - borderRadius)
        ctx.quadraticCurveTo(x + width, y + height, x + width - borderRadius, y + height)
        ctx.lineTo(x + borderRadius, y + height)
        ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius)
        ctx.lineTo(x, y + borderRadius)
        ctx.quadraticCurveTo(x, y, x + borderRadius, y)
        ctx.closePath()
        ctx.fill()
        ctx.stroke()
        ctx.restore()
    }

    protected drawDragDots(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        color: string,
        isActive: boolean
    ): void {
        const dotColor = isActive ? '#FFFFFF' : color

        ctx.save()
        ctx.fillStyle = dotColor
        ctx.globalAlpha = this.owner.isTransparent ? 0.5 : 1
        const dotRadius = 1
        const dotSpacing = 3
        for (let i = 0; i < 6; i++) {
            const currentX = x + (i % 2) * dotSpacing
            const currentY = y + Math.floor(i / 2) * dotSpacing
            ctx.beginPath()
            ctx.arc(currentX, currentY, dotRadius, 0, Math.PI * 2)
            ctx.fill()
        }
        ctx.restore()
    }

    protected drawText(
        ctx: CanvasRenderingContext2D,
        text: string,
        x: number,
        y: number,
        color: string,
        font: string,
        isActive = false
    ): void {
        const textColor = isActive ? '#FFFFFF' : color

        ctx.save()
        ctx.fillStyle = textColor
        ctx.font = font
        ctx.textAlign = 'left'
        ctx.textBaseline = 'middle'
        ctx.globalAlpha = this.owner.isTransparent ? 0.5 : 1
        ctx.fillText(text, x, y)
        ctx.restore()
    }

    protected drawSeparatorLine(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        height: number,
        color: string
    ): void {
        ctx.save()
        ctx.fillStyle = color
        ctx.globalAlpha = this.owner.isTransparent ? 0.5 : 1
        ctx.fillRect(x, y, 1, height)
        ctx.restore()
    }

    public syncLevelsInsideOwner(isHover: true, multiSelect: false): void
    public syncLevelsInsideOwner(isHover: false, multiSelect: boolean): void
    public syncLevelsInsideOwner(isHover: boolean, multiSelect: boolean): void {
        if (!isHover) {
            for (const levels of this.owner.orderLevels) {
                levels[1].takeProfitOrderLevel?.onSelect(multiSelect)
                levels[1].stopLossOrderLevel?.onSelect(multiSelect)
                levels[1].openPriceOrderLevel?.onSelect(multiSelect)
            }
        }

        for (const levels of this.owner.orderLevels) {
            levels[1].takeProfitOrderLevel?.onMouseEnterControl()
            levels[1].stopLossOrderLevel?.onMouseEnterControl()
            levels[1].openPriceOrderLevel?.onMouseEnterControl()
        }
    }

    drawCloseBtn(canvas: TGdiPlusCanvas): void {
        if (this.closeBtn) {
            this.closeBtn.draw(canvas)
        } else {
            this.closeBtn = new CloseBtn(
                new ChartControlParams(
                    new CanvasLayer(
                        this.chartWindow.MainChart.HTML_Canvas,
                        this.chartWindow,
                        LayerType.ENTIRE_CHART_BLOCKING,
                        'CloseBtn'
                    ),
                    new LocationParams(0, 0, 0, 0)
                ),
                this
            )
            this.closeBtn.draw(canvas)
        }
    }

    close(): void {}

    isMouseInsideInfoBox(point: TPoint): boolean {
        const mouseSensitiveArea = GlobalOptions.Options.MouseSensitivity
        const infoBoxLocation = this.infoBoxLocation

        return (
            point.x >= infoBoxLocation.Left - mouseSensitiveArea &&
            point.x <= infoBoxLocation.Right + mouseSensitiveArea &&
            point.y >= infoBoxLocation.Top - mouseSensitiveArea &&
            point.y <= infoBoxLocation.Bottom + mouseSensitiveArea
        )
    }

    protected onMouseEnterControl() {
        for (const levels of this.owner.orderLevels) {
            if (levels[1].takeProfitOrderLevel && this.type === 'Takeprofit') {
                levels[1].takeProfitOrderLevel.setIsMouseInside(true)
                this.chartWindow.controlsManager.HoverControl = this
            }
            if (levels[1].stopLossOrderLevel && this.type === 'Stoploss') {
                levels[1].stopLossOrderLevel.setIsMouseInside(true)
                this.chartWindow.controlsManager.HoverControl = this
            }
            if (levels[1].openPriceOrderLevel && this.type === 'OpenPrice') {
                levels[1].openPriceOrderLevel.setIsMouseInside(true)
                this.chartWindow.controlsManager.HoverControl = this
            }
        }

        const chart = GlobalChartsController.Instance.getActiveChart()
        if (chart) {
            const { x, y } = chart.MainChart.getLocalMousePos()

            if (this.IsDraggable() && !this.IsCaptured() && this.isMouseInsideInfoBox(new TPoint(x, y))) {
                chart.SetCursor(TCursor.crDrag)
            }
        }
    }

    onMouseDown(event: MouseEvent, sender: TMainChart): ChartControl | null {
        this.closeBtn?.onMouseDown(event, sender)

        if (this.isMouseInsideInfoBox(new TPoint(event.clientX, event.clientY))) {
            this.Capture()
            this.onSelect(isCtrlEvent(event))

            if (this.IsDraggable() && this.IsCaptured()) {
                const chart = GlobalChartsController.Instance.getActiveChart()

                if (chart) {
                    chart.SetCursor(TCursor.crGrabbing)
                }
            }
            return this
        }

        if (super.onMouseDown(event, sender)) {
            this.onSelect(isCtrlEvent(event))

            return this
        }
        if (!isCtrlEvent(event)) {
            this.ReleaseCapture()
            this.onDeselect()
        }
        return null
    }

    public onSelect(mutltiSelect: boolean): void {
        for (const levels of this.owner.orderLevels) {
            if (levels[1].takeProfitOrderLevel && this.type === 'Takeprofit') {
                levels[1].takeProfitOrderLevel.isSelected = true
            }
            if (levels[1].stopLossOrderLevel && this.type === 'Stoploss') {
                levels[1].stopLossOrderLevel.isSelected = true
            }
            if (levels[1].openPriceOrderLevel && this.type === 'OpenPrice') {
                levels[1].openPriceOrderLevel.isSelected = true
            }
        }

        if (!mutltiSelect) {
            this.deselectOtherPositions()
        }
        this.observableItem.notify(chartControlEvent.CONTROL_SELECTED, this)
    }

    private deselectOtherPositions() {
        for (const pos of GlobalProcessingCore.ProcessingCore.OpenPositions) {
            if (pos.tpos.ticket !== this.owner.tpos.ticket) {
                pos.deselectAllRelatedOrderLevels()
            }
        }
    }

    onDeselect() {
        for (const levels of this.owner.orderLevels) {
            if (levels[1].takeProfitOrderLevel && this.type === 'Takeprofit') {
                levels[1].takeProfitOrderLevel.isSelected = false
            }
            if (levels[1].stopLossOrderLevel && this.type === 'Stoploss') {
                levels[1].stopLossOrderLevel.isSelected = false
            }
            if (levels[1].openPriceOrderLevel && this.type === 'OpenPrice') {
                levels[1].openPriceOrderLevel.isSelected = false
            }
        }
        this.observableItem.notify(chartControlEvent.CONTROL_DESELECTED, this)
    }

    Capture() {
        for (const levels of this.owner.orderLevels) {
            if (levels[1].takeProfitOrderLevel && this.type === 'Takeprofit') {
                levels[1].takeProfitOrderLevel.setCapture(true)
            }
            if (levels[1].stopLossOrderLevel && this.type === 'Stoploss') {
                levels[1].stopLossOrderLevel.setCapture(true)
            }
            if (levels[1].openPriceOrderLevel && this.type === 'OpenPrice') {
                levels[1].openPriceOrderLevel.setCapture(true)
            }
        }
    }

    ReleaseCapture() {
        for (const levels of this.owner.orderLevels) {
            if (levels[1].takeProfitOrderLevel && this.type === 'Takeprofit') {
                levels[1].takeProfitOrderLevel.setCapture(false)
            }
            if (levels[1].stopLossOrderLevel && this.type === 'Stoploss') {
                levels[1].stopLossOrderLevel.setCapture(false)
            }
            if (levels[1].openPriceOrderLevel && this.type === 'OpenPrice') {
                levels[1].openPriceOrderLevel.setCapture(false)
            }
        }
    }

    protected onMouseLeaveControl() {
        for (const levels of this.owner.orderLevels) {
            if (levels[1].takeProfitOrderLevel && this.type === 'Takeprofit') {
                levels[1].takeProfitOrderLevel.setIsMouseInside(false)
            }
            if (levels[1].stopLossOrderLevel && this.type === 'Stoploss') {
                levels[1].stopLossOrderLevel.setIsMouseInside(false)
            }
            if (levels[1].openPriceOrderLevel && this.type === 'OpenPrice') {
                levels[1].openPriceOrderLevel.setIsMouseInside(false)
            }
        }
    }

    onDblClick(event: MouseEvent, sender: TMainChart): ChartControl | null {
        if (super.onDblClick(event, sender) || this.isMouseInsideInfoBox(new TPoint(event.clientX, event.clientY))) {
            this.modify()
            return this
        }
        return null
    }

    modify(): void {
        const orderData = this.owner.tpos

        addModal(MODAL_NAMES.chart.orderModal, {
            isEdit: true,
            headerLabel: t('orders.modal.editHeader', {
                id: orderData.ticket,
                orderType: t(getOrderTypeLabel(orderData.PosType))
            })
        })

        let order: terminalOrderBaseType = {
            id: orderData.ticket,
            symbol: orderData.SymbolName,
            type: orderData.PosType,
            lots: orderData.lot,
            openTime: orderData.OpenTime,
            sl: orderData.StopLoss,
            tp: orderData.TakeProfit,
            comment: orderData.Comments
        }

        if (
            orderData.PosType === TTradePositionType.tp_BuyLimit ||
            orderData.PosType === TTradePositionType.tp_SellLimit ||
            orderData.PosType === TTradePositionType.tp_BuyStop ||
            orderData.PosType === TTradePositionType.tp_SellStop
        ) {
            order = {
                ...order,
                executionPrice: orderData.OpenPrice,
                marketPrice: orderData.OpenPrice
            } as terminalOrderPendingPositionType
        } else {
            order = order as terminalOrderBaseType & {
                openPrice: number
                marketPrice: number
                swap: number
                commision: number
                points: number
                profit: number
            }
        }

        const { editOrder } = CreateOrderStore
        editOrder(order as OrderDataType)

        this.owner.onEdit()
    }

    protected getXCoordinateToDrawLevel(ctx: CanvasRenderingContext2D): number {
        const canvasWidth = ctx.canvas.width

        if (canvasWidth < 768) {
            return ctx.canvas.width / 2
        } else if (canvasWidth < 1024) {
            return ctx.canvas.width / 3
        } else if (canvasWidth < 1440) {
            return ctx.canvas.width / 4
        } else {
            return ctx.canvas.width / 5
        }
    }
}
