import React, { FC, useEffect, useState, useCallback } from 'react'
import classNames from 'classnames'

import { Checkbox, Flex, InputField, Typography } from '@fto/ui'

import { FormErrors } from '@root/hooks/useSubmitForm'
import { CreateProjectModalFormData } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/create-project-form.schema'
import RequiredLabel from '@root/pages/Dashboard/components/Modals/CreateProjectModal/RequiredLabel'
import CreateProjectCalendar, {
    CalendarPositionOptions
} from '@root/pages/Dashboard/components/Modals/CreateProjectModal/CreateProjectCalendar/CreateProjectCalendar'
import {
    calculateDateRange,
    initCalendarDates
} from '@root/pages/Dashboard/components/Modals/CreateProjectModal/create-modal.functions'

import style from '@root/pages/Dashboard/components/Modals/CreateProjectModal/create-project-modal.module.scss'
import { useTranslation } from 'react-i18next'

const calendarSettings: {
    transformOrigin: CalendarPositionOptions
    anchorOrigin: CalendarPositionOptions
} = {
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
    },
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
    }
}

type Props = {
    formData: CreateProjectModalFormData
    setFormData: (value: React.SetStateAction<CreateProjectModalFormData>) => void
    errors: FormErrors[]
} & React.HTMLAttributes<HTMLDivElement>

const CreateProjectModalForm: FC<Props> = ({
    formData: { ProjectName, Deposit, Symbols, StartDate, EndDate },
    className,
    setFormData,
    errors
}) => {
    const { t } = useTranslation()
    const [calendarDates, setCalendarDates] = useState(initCalendarDates)
    const [endDate, setEndDate] = useState(false)

    const onChange = useCallback(
        (e: string, inputName: string) => {
            setFormData((prevData) => ({
                ...prevData,
                [inputName]: e
            }))
        },
        [setFormData]
    )

    const isError = useCallback((inputName: string): boolean => !!errors?.find((e) => e[inputName]), [errors])

    const isMessageError = useCallback(
        (inputName: string): string => {
            const error = errors?.find((e) => e[inputName])?.[inputName]
            return error ? t(error) : ''
        },
        [errors]
    )

    const handleSetEndDateCheckbox = useCallback(
        (e: boolean) => {
            setEndDate(e)
            if (!e) {
                setFormData((prevData) => ({
                    ...prevData,
                    EndDate: null
                }))
            }
        },
        [setFormData]
    )

    useEffect(() => {
        const result = calculateDateRange(Symbols)
        setCalendarDates(result)
    }, [Symbols])

    return (
        <Flex className={classNames(style.generalBody, className)} direction='column' gap={16}>
            <Typography type='text-semibold'>{t('dashboard.modals.projects.general')}</Typography>
            <Flex className={style.formElement} direction='column' gap={16}>
                <InputField
                    reset
                    onChange={(e) => onChange(e, 'ProjectName')}
                    name='ProjectName'
                    value={ProjectName}
                    label={<RequiredLabel text={t('dashboard.modals.projects.projectName')} />}
                    placeholder={t('dashboard.modals.projects.projectName')}
                    error={isError('ProjectName')}
                    helperText={isMessageError('ProjectName')}
                    isAbsoluteHelperText={true}
                />
                <InputField
                    reset
                    onChange={(e) => onChange(e, 'Deposit')}
                    name='Deposit'
                    type='number'
                    value={Deposit}
                    error={isError('Deposit')}
                    helperText={isMessageError('Deposit')}
                    label={
                        <Flex flexGrow={1} justifyContent={'space-between'}>
                            <RequiredLabel text={t('dashboard.modals.projects.deposit')} />
                            <Typography type={'interface-regular'} color={'gray-600'}>
                                ({t('global.currency.usd')})
                            </Typography>
                        </Flex>
                    }
                    placeholder={t('dashboard.modals.projects.deposit')}
                />
                <CreateProjectCalendar
                    {...calendarSettings}
                    disabled={Symbols?.length === 0}
                    name={'StartDate'}
                    initialDate={calendarDates.initialStartDate}
                    minDate={calendarDates.StartDate}
                    maxDate={EndDate ? EndDate : calendarDates.EndDate}
                    setFormData={setFormData}
                    label={<RequiredLabel text={t('dashboard.modals.projects.startDate')} />}
                />
                <Checkbox
                    label={<Typography type={'text-regular'}>{t('dashboard.modals.projects.setEndDate')}</Typography>}
                    variant={'default'}
                    block
                    checked={endDate}
                    onChange={handleSetEndDateCheckbox}
                    className={style.Checkbox}
                />
                <CreateProjectCalendar
                    {...calendarSettings}
                    disabled={!endDate || Symbols?.length === 0}
                    name={'EndDate'}
                    initialDate={calendarDates.EndDate}
                    maxDate={calendarDates.EndDate}
                    minDate={StartDate}
                    setFormData={setFormData}
                    label={<Typography type={'text-regular'}>{t('dashboard.modals.projects.endDate')}</Typography>}
                />
            </Flex>
        </Flex>
    )
}

export default CreateProjectModalForm
