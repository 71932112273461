import { FiboPaintToolJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { TBasicPaintTool } from '../../../charting/paint_tools/BasicPaintTool'
import { TLineStyle, TMkFontStyle } from '../../../drawing_interface/vclCanvas'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { TOffsStringList, TVarList } from '../../../ft_types/common/OffsStringList'
import { PaintToolNames } from '../PaintToolNames'
import { TPaintToolType } from '../PaintToolsAuxiliaryClasses'
import { TFiboPaintTool } from './ptFibo'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TPoint } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TChart } from '../../chart_classes/BasicChart'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TPtFiboFan extends TFiboPaintTool {
    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptFiboFan)

        this.fToolType = TPaintToolType.tt_Line
        this.fMaxPoints = 2
        this.fClosedPolygon = false
        this.CursorStyle = CustomCursorPointers.crCursorFiboFan
        this.icon = 52
        // SetLevels method and fLevels property are assumed to be part of the inherited TFiboPaintTool class
        this.SetLevels([38.2, 50, 61.8], 3)
        //TODO: Verify the correct import location or implementation for FiboFanFrm
        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptFiboFan)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptFiboFan)
        }

        if (!styles) throw new StrangeError('Default styles for FiboFan are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
        this.fLevels.ImportFromStr(styles.levels)
    }

    clone(): TPtFiboFan {
        const cloneObj = new TPtFiboFan(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        return cloneObj
    }

    toJson(): FiboPaintToolJSON {
        return super.toJson()
    }

    fromJSON(json: FiboPaintToolJSON) {
        super.fromJSON(json)
    }

    LoadFromList(list: TOffsStringList, all = true): void {
        super.LoadFromList(list, all)

        const vars = new TVarList()
        try {
            vars.LoadFromList(list)
            this.fLevels.ImportFromStr(vars.GetValue('Levels'))
        } finally {
            vars.Clear()
        }
    }

    SaveToList(list: TOffsStringList, all = true): void {
        super.SaveToList(list, all)

        let vars = new TVarList()
        vars.AddVarStr('Levels', this.fLevels.ExportToStr(3)) // Replace exportToStr with the equivalent TypeScript method
        vars.SaveToList(list, '')
        vars.Clear()
    }

    Paint(): void {
        if (this.fHighlighted) {
            const points = [
                new TPoint(this.fPoints[0].x, this.fPoints[0].y),
                new TPoint(this.fPoints[1].x, this.fPoints[1].y)
            ]
            this.PaintHoverLine(points)
        }

        if (this.fPoints.length < 2) {
            return
        }

        const x1 = this.fPoints[0].x
        const y1 = this.fPoints[0].y
        const x2 = this.fPoints[1].x
        const price1 = this.fPoints[0].price
        const price2 = this.fPoints[1].price
        const canvas = this.fChart.GdiCanvas

        for (let i = 0; i < this.fLevels.length; i++) {
            const level = this.fLevels[i]

            if (!level.isActive) {
                continue
            }

            let price: number
            if (price2 > price1) {
                price = price2 - ((price2 - price1) * level.value) / 100
            } else {
                price = price2 + ((price1 - price2) * level.value) / 100
            }

            const y2 = this.fChart.GetY(price)
            if (!this.fChart.IsRayVisible(x1, y1, x2, y2)) {
                continue
            }

            // paint ray
            let rx2 = x2
            let ry2 = y2
            const rayCoords: number[] = this.fChart.GetRayCoords(x1, y1, rx2, ry2, price1, price) as unknown as number[]
            rx2 = rayCoords[2]
            ry2 = rayCoords[3]

            canvas.MoveTo(x1, y1)
            canvas.LineTo(rx2, ry2, this.fLevels.Item(i).pen)

            // paint label
            const style = new TMkFontStyle('Roboto Flex', 0, 20, '#3c8f2b')

            const s = level.text
            const textWidth = 50 //TODO: Replace with the equivalent TypeScript method
            const textHeight = 10 //TODO: Replace with the equivalent TypeScript method
            canvas.textOut(x2 - textWidth - 5, y2 - textHeight, s, this.font, level.brush)
        }
        super.Paint()
    }

    get visible(): boolean {
        return this.fChart.IsRayVisible(this.fPoints[0].x, this.fPoints[0].y, this.fPoints[1].x, this.fPoints[1].y)
    }

    assign(tool: TBasicPaintTool, isCopy = false): void {
        super.assign(tool, isCopy)
        // Assuming fLevels is a TLevelsList and has an assign method
        if (tool instanceof TPtFiboFan) {
            this.fLevels.Assign(tool.fLevels)
        } else {
            throw new StrangeError('Assigned tool must be of type TPtFiboFan')
        }
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            levels: {
                value: this.fLevels.map((level) => ({
                    id: level.id,
                    value: level.value,
                    text: level.text,
                    isActive: level.isActive,
                    style: level.pen.getPenStyleFromPattern(),
                    color: level.pen.color,
                    width: level.pen.width,
                    opacity: level.pen.opacity
                })),
                type: 'levels',
                key: 'levels',
                label: 'levels'
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptFiboFan,
            toolName: 'fiboFan'
        })
    }

    ImportFromDialog(): void {
        const { resetToolSettings, getKeyValueData } = GraphToolStore // Use the store/context

        const formattedToolSettings = getKeyValueData()

        //@ts-ignore
        const { description, lineStyle, levels } = formattedToolSettings

        this.chart.ChartWindow.saveStateWithNotify()

        this.description = description
        this.fLineStyle = lineStyle
        this.updateLevelsFromModal(levels)

        this.saveToManager()
        resetToolSettings()
    }
    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }
    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptFiboFan, {
            lineStyle: this.fLineStyle.getSerialized(),
            toolName: PaintToolNames.ptFiboFan,
            levels: this.fLevels.ExportToStr(5)
        })
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptFiboFan, TPtFiboFan)
