import { BasicPaintToolJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { TBasicPaintTool } from '../../../charting/paint_tools/BasicPaintTool'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { TChart } from '../../chart_classes/BasicChart'
import { PaintToolNames } from '../PaintToolNames'
import { TPaintToolType } from '../PaintToolsAuxiliaryClasses'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { addModal } from '@fto/ui'
import { TPoint } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TPtTrendLine extends TBasicPaintTool {
    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptTrendLine)

        this.fToolType = TPaintToolType.tt_Line
        this.fMaxPoints = 2
        this.fClosedPolygon = false
        this.CursorStyle = CustomCursorPointers.crCursorTrendLine
        this.icon = 40
        // this.fEditDialog = TrendLineFrm;
        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptTrendLine)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptTrendLine)
        }

        if (!styles) throw new StrangeError('Default styles for TrendLine are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
    }

    clone(): TPtTrendLine {
        const cloneObj = new TPtTrendLine(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        return cloneObj
    }

    public toJson(): BasicPaintToolJSON {
        return super.toJson()
    }

    public fromJSON(json: BasicPaintToolJSON) {
        super.fromJSON(json)
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },

            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            extendLeft: {
                key: 'extendLeft',
                value: this.fExtendsRay.left,
                label: 'Extend to the left',
                type: 'checkbox',
                disabled: false,
                isOptional: true
            },
            extendRight: {
                key: 'extendRight',
                value: this.fExtendsRay.right,
                label: 'Extend to the right',
                type: 'checkbox',
                disabled: false,
                isOptional: true
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, { toolType: PaintToolNames.ptTrendLine, toolName: 'trendLine' })
    }

    ImportFromDialog(): void {
        const { getKeyValueData, resetToolSettings } = GraphToolStore // Use the store/context
        this.chart.ChartWindow.saveStateWithNotify()

        const formattedToolSettings = getKeyValueData()
        this.fExtendsRay.left = formattedToolSettings.extendLeft
        this.fExtendsRay.right = formattedToolSettings.extendRight
        this.description = formattedToolSettings.description
        this.fLineStyle = formattedToolSettings.lineStyle

        this.saveToManager()
        resetToolSettings()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }

    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptTrendLine, {
            lineStyle: this.fLineStyle.getSerialized(),
            toolName: PaintToolNames.ptTrendLine
        })
    }

    Paint(): void {
        if (this.fPoints.length < 2) return
        this.fExtendsRay.leftfPoints = [{ 0: [this.fPoints[1], this.fPoints[0], null] }]
        this.fExtendsRay.rightfPoints = [{ 0: [this.fPoints[0], this.fPoints[1], null] }]
        this.PaintExtendsRay()
        if (this.fHighlighted) {
            const points = [
                new TPoint(this.fPoints[0].x, this.fPoints[0].y),
                new TPoint(this.fPoints[1].x, this.fPoints[1].y)
            ]
            this.PaintHoverLine(points)
        }
        this.PaintLineWithText(this.fPoints[0], this.fPoints[1], this.fText)

        this.PaintMarkers()
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptTrendLine, TPtTrendLine)
