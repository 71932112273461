import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import IEventEmitter from '../../common/IEventEmitter'
import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import { TRuntimeIndicatorsList } from '../../extension_modules/indicators/RuntimeIndicatorList'
import TEventsFunctionality from '../../utils/EventsFunctionality'
import { TSymbolInfo } from '../common/BasicClasses/SymbolInfo'
import { TChunkMapStatus } from './TChunkMapStatus'
import ISymbolData from './ISymbolData'
import { TTickData } from './TickData'
import { TBarArrays } from './data_arrays/BarArrays'
import IFMBarsArray from './data_arrays/chunked_arrays/IFMBarsArray'
import { TDataArrayEvents } from './data_downloading/DownloadRelatedEnums'
import DataNotDownloadedYetError from './data_errors/DataUnavailableError'
import { TimeframeUtils } from '../common/TimeframeUtils'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { TBasicChunk } from './chunks/BasicChunk'
import { TFMBarsArray } from './data_arrays/chunked_arrays/BarsArray/BarsArray'
import { INamed } from '@fto/lib/utils/INamed'
import { TTickRecord } from './DataClasses/TTickRecord'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import { TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import GlobalServerSymbolInfo from '@fto/lib/globals/GlobalServerSymbolInfo'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'

export class TSymbolData implements ISymbolData, IEventEmitter, INamed {
    private _barArrays: TBarArrays
    //TODO: make it private and change all external usages
    public fTickData: TTickData

    private fIndicators: TRuntimeIndicatorsList | null
    private fGapFlag: boolean

    public symbolInfo: TSymbolInfo
    private _ask = -1
    private _bid = -1

    public get bid(): number {
        if (!this.TickData.IsSeeked) {
            throw new DataNotDownloadedYetError('Cannot get bid, the symbol is not seeked yet')
        }
        if (this._bid <= 0) {
            throw new StrangeError('Bid is not initialized')
        }
        return this._bid
    }

    public get ask(): number {
        if (!this.TickData.IsSeeked) {
            throw new DataNotDownloadedYetError('Cannot get ask, the symbol is not seeked yet')
        }
        if (this._ask <= 0) {
            throw new StrangeError('Ask is not initialized')
        }
        return this._ask
    }

    public priceWentUp(tf: number): boolean {
        const arr = this._barArrays.GetExistingBarsArrayByTimeframe(tf)
        if (!arr) {
            throw new Error('No bars array')
        }
        const lastBar = arr.GetItemByGlobalIndex(arr.LastItemInTestingIndex)

        if (!lastBar) {
            throw new Error('No last bar')
        }

        return lastBar.open < lastBar.close
    }

    public setBid(value: number): void {
        if (value <= 0) {
            throw new StrangeError('Setting bid to non-positive value')
        }
        this._bid = value
    }

    public setAsk(value: number): void {
        if (value <= 0) {
            throw new StrangeError('Setting ask to non-positive value')
        }
        this._ask = value
    }

    public Events: TEventsFunctionality

    public get IsSeeked(): boolean {
        return this.fTickData.IsSeeked && this._barArrays.IsSeeked
    }

    public get DName(): string {
        return `TSymbolData  ${this.symbolInfo.SymbolName}`
    }

    public toString(): string {
        return this.DName
    }

    constructor(symbolInfo: TSymbolInfo) {
        if (!symbolInfo.SymbolName) {
            throw new StrangeError('No symbol name')
        }

        symbolInfo.SymbolName = symbolInfo.SymbolName.toUpperCase()
        this.symbolInfo = symbolInfo
        this.Events = new TEventsFunctionality(this.DName)

        this._barArrays = new TBarArrays(symbolInfo.SymbolName)
        this._barArrays.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundHandleChunkDownloaded)
        this._barArrays.Events.on(TDataArrayEvents.de_MapDownloaded, this.boundHandleMapDownloaded)

        // Initialize the indicators list
        this.fIndicators = new TRuntimeIndicatorsList()

        // Initialize tick data with the symbol name
        this.fTickData = new TTickData(symbolInfo.SymbolName, symbolInfo.Broker)
        this.fTickData.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundHandleChunkDownloaded)

        this.fGapFlag = false
    }

    public get VeryLastDateInHistory(): TDateTime {
        return GlobalServerSymbolInfo.Instance.getServerSymbolInfo(this.symbolInfo.SymbolName).EndDate
    }

    public get VeryFirstDateInHistory(): TDateTime {
        return GlobalServerSymbolInfo.Instance.getServerSymbolInfo(this.symbolInfo.SymbolName).StartDate
    }

    public get LastProcessedTickTime(): TDateTime {
        return this.fTickData.LastProcessedTickTime
    }

    private boundHandleChunkDownloaded = this.PassForwardChunkDownloadEvent_BarOrTick.bind(this)

    private PassForwardChunkDownloadEvent_BarOrTick(chunk: TBasicChunk): void {
        this.Events.EmitEvent(TDataArrayEvents.de_ChunkLoaded, chunk)
    }

    private boundHandleMapDownloaded = this.HandleAndPassForwardMapDownloadEvent.bind(this)

    private HandleAndPassForwardMapDownloadEvent(barsArray: TFMBarsArray): void {
        if (!this.symbolInfo.isSymbolCalculationStrategyInitialized) {
            this.symbolInfo.initializeSymbolCalculationStrategy()
        }
        this.Events.EmitEvent(TDataArrayEvents.de_MapDownloaded, barsArray)
    }

    public get Indicators(): TRuntimeIndicatorsList | null {
        return this.fIndicators
    }

    public get IsGap(): boolean {
        return this.fGapFlag
    }

    public get TickData(): TTickData {
        return this.fTickData
    }

    public GetOrCreateBarArray(period: number): IFMBarsArray {
        if (!this._barArrays) {
            throw new StrangeError('BarArrays is not initialized')
        }
        return this._barArrays.GetOrCreateActiveBarArray(period)
    }

    public FormatPriceToStr(value: number): string {
        return value.toFixed(this.symbolInfo.decimals)
    }

    public RoundPrice(value: number): number {
        return parseFloat(value.toFixed(this.symbolInfo.decimals))
    }

    public Spread(): number {
        return Math.round(Math.pow(10, this.symbolInfo.decimals) * (this.ask - this.bid))
    }

    public LastPrice(): number {
        return this.bid
    }

    public DayPriceChange(): {
        value: number
        percent: number
    } {
        let resultValue = 0
        let resultPercent = 0

        const prevTick = this.fTickData.getStartDayTickRecord()
        if (prevTick) {
            const lastPrice = this.LastPrice()
            const previousPrice = prevTick.bid
            if (previousPrice !== 0) {
                resultValue = lastPrice - previousPrice
                resultPercent = (resultValue / previousPrice) * 100
            }
        }

        return { value: resultValue, percent: resultPercent }
    }

    public static CreateEmpty(): TSymbolData {
        const pseudoSymbolName = 'PROFIT'
        const emptySymbolInfo = new TSymbolInfo(pseudoSymbolName)
        emptySymbolInfo.SetDecimals(0)
        emptySymbolInfo.BaseCurrency = 'USD'
        emptySymbolInfo.LotCurrency = 'USD'
        emptySymbolInfo.MarginCurrency = 'USD'

        const symbolData = new TSymbolData(emptySymbolInfo)

        symbolData._barArrays = new TBarArrays(pseudoSymbolName)
        symbolData.fIndicators = null

        return symbolData
    }

    public AddSingleTick(tick: TTickRecord): void {
        this._barArrays.AddSingleTick(tick)

        this.setBid(tick.bid)
        this.setAsk(tick.ask)

        // check for gap in data ( > 1 day)
        this.fGapFlag = tick.DateTime - this.LastProcessedTickTime > 1
    }

    public GetTimeframeWithLastBar(): IFMBarsArray | null {
        let maxKnownDate = DateUtils.EmptyDate
        let timeframeWithLastKnownDate: IFMBarsArray | null = null

        for (let i = 0; i < this._barArrays.length; i++) {
            const barArray = this._barArrays[i]
            if (
                barArray.ChunkMapStatus === TChunkMapStatus.cms_Loaded &&
                barArray.LastItemInTestingIndexAvailable &&
                barArray.LastItemInTesting &&
                barArray.LastItemInTesting.DateTime > maxKnownDate
            ) {
                maxKnownDate = barArray.LastItemInTesting.DateTime
                timeframeWithLastKnownDate = barArray
            }
        }

        return timeframeWithLastKnownDate
    }

    private UpdateBidAsk(): void {
        const lastProcessedTick = this.TickData.GetLastProcessedTick()

        if (lastProcessedTick) {
            this.setBid(lastProcessedTick.bid)
            this.setAsk(lastProcessedTick.ask)
        } else {
            throw new StrangeError('UpdateBidAsk - lastProcessedTick is null')
        }
    }

    public PreloadDataIfNecessary(startDate: TDateTime, endDate: TDateTime): void {
        if (startDate >= this.VeryFirstDateInHistory) {
            this.fTickData.PreloadDataIfNecessary(startDate, endDate)
            this._barArrays.PreloadDataIfNecessary(startDate, endDate)
        }
    }

    public Seek(requestedDateForSeek: TDateTime): TDateTime | null {
        DebugUtils.logTopic(
            ELoggingTopics.lt_Seek,
            `Seeking ${this.symbolInfo.SymbolName} symbol to date:`,
            requestedDateForSeek
        )

        let dateToSeekTo = requestedDateForSeek

        if (requestedDateForSeek < this.VeryFirstDateInHistory) {
            dateToSeekTo = this.VeryFirstDateInHistory
        }

        const lastProcessedTickTime = this.TickData.Seek(dateToSeekTo)
        this.UpdateBidAsk() //this should be before _barArrays.Seek, because BarArrays.Seek will trigger redraw
        this._barArrays.Seek(lastProcessedTickTime) //update the position of the "current" bars in all timeframes
        DebugUtils.logTopic(
            ELoggingTopics.lt_Seek,
            `seeking bars completed, doing UpdateChunksInfo in ${this.symbolInfo.SymbolName} symbolData`
        )

        if (this.isDateInAvailableRange(requestedDateForSeek)) {
            this.UpdateChunksInfo(requestedDateForSeek)
        }

        this.Events.EmitEvent(TDataArrayEvents.de_SeekCompleted, this)

        if (DebugUtils.DebugMode) {
            this.__debugCheckSeekedStatuses()
        }

        return lastProcessedTickTime
    }

    private __debugCheckSeekedStatuses() {
        if (!this.fTickData.IsSeeked) {
            throw new StrangeError(
                `Seeked status is not correct. SymbolData ${this.symbolInfo.SymbolName} is seeked but tickData is not`
            )
        }

        for (const barArray of this.GetActiveBarArrays()) {
            if (!barArray.IsSeeked) {
                throw new StrangeError(
                    `Seeked status is not correct. SymbolData ${this.symbolInfo.SymbolName} is seeked but ${barArray.DName} is not`
                )
            }
        }
    }

    private MakeSureAtLeastOneMapIsLoading() {
        let isAtLeastOneMapLoadedOrLoading = false
        for (const barArray of this._barArrays) {
            if (
                barArray.ChunkMapStatus === TChunkMapStatus.cms_Loading ||
                barArray.ChunkMapStatus === TChunkMapStatus.cms_Loaded
            ) {
                isAtLeastOneMapLoadedOrLoading = true
            }
        }

        if (!isAtLeastOneMapLoadedOrLoading) {
            //download the map for M15 TF (we need it for faster testing anyway). Furthermore, it is a default chart TF
            this.EnsureTimeframeIsActive(TimeframeUtils.M15_Timeframe)
        }
    }

    public EnsureTimeframeIsActive(timeframe: number): IFMBarsArray {
        return this._barArrays.EnsureTimeframeIsActive(timeframe)
    }

    public isVisibleBarDataReady(): boolean {
        return this._barArrays.isVisibleDataReady()
    }

    public isDateInAvailableRange(date: TDateTime): boolean {
        return this.fTickData.isDateInAvailableRange(date)
    }

    public get isCurrentTestingDateInAvailableRange(): boolean {
        return this.isDateInAvailableRange(GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true))
    }

    public UpdateChunksInfo(projectLastTickTime: TDateTime): void {
        DebugUtils.logTopic(
            [ELoggingTopics.lt_ChunkLoading],
            `symbolData - UpdateChunksInfo - updating chunks info for ${this.symbolInfo.SymbolName} symbol to date: ${projectLastTickTime}`
        )

        if (!this.isDateInAvailableRange(projectLastTickTime)) {
            DebugUtils.logTopic([ELoggingTopics.lt_ChunkLoading], `UpdateChunksInfo skipped, we are out of date range`)
            //no need to do anything while we are out of range
            return
        }
        this.fTickData.UpdateChunksInfo(projectLastTickTime)
        this._barArrays.UpdateChunksInfo(projectLastTickTime)
    }

    public onTimezoneOrDSTChanged(): void {
        this._barArrays.onTimezoneOrDSTChanged()
    }

    public ClearInvisibleBars(): void {
        for (const barArray of this._barArrays) {
            if (!GlobalChartsController.Instance.IsTimeframeVisible(barArray.DataDescriptor)) {
                DebugUtils.log('Clearing invisible timeframe', barArray.DataDescriptor)
                barArray.ClearDataInChunks()
            }
        }
    }

    public IsTimeframeActive(timeframe: number): boolean {
        return this._barArrays.IsTimeframeActive(timeframe)
    }

    public GetActiveBarArrays(): IFMBarsArray[] {
        return this._barArrays.GetActiveBarArrays()
    }

    public GetExistingBarsArrayByTimeframe(timeframe: number): IFMBarsArray | null {
        return this._barArrays.GetExistingBarsArrayByTimeframe(timeframe)
    }

    public GetExistingBarsArrayByTimeframe_throwErrorIfNull(timeframe: number): IFMBarsArray {
        const result = this.GetExistingBarsArrayByTimeframe(timeframe)
        if (!result) {
            throw new StrangeError(`BarsArray for timeframe ${timeframe} does not exist`)
        }
        return result
    }

    public ResetSeekStatus(): void {
        this.fTickData.ResetSeekStatus()
        this._barArrays.ResetSeekStatus()
    }

    public get BarArrays(): TBarArrays {
        return this._barArrays
    }

    public getCurrentOpenPriceByOrderType(orderType: TTradePositionType): number {
        switch (orderType) {
            case TTradePositionType.tp_Buy:
            case TTradePositionType.tp_BuyStop:
            case TTradePositionType.tp_BuyLimit: {
                return this.bid
            }
            default: {
                return this.ask
            }
        }
    }

    public markLastTickAsProcessed(tickDateTime: TDateTime): void {
        this.fTickData.markLastTickAsProcessed(tickDateTime)
    }
}
