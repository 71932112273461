import { OrderLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/OrderLevel'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { MarketValues, OrderValues, TakeprofitValues } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import { TPoint, TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { ChartControl } from '@fto/chart_components/ChartControl'
import { TakeprofitCalculationStrategy } from '@fto/lib/OrderModalClasses/TakeProfitCalculationStrategies/TakeprofitCalculationStrategy'
import { MarketLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/MarketLevel'
import { getTextWidth } from '@fto/lib/globals/GlobalTextSizeCache'
import { formatCurrency } from '@fto/lib/utils/ordersUtils'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import { PendingLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/PendingLevel'
import { showErrorToast, showSuccessToast } from '@root/utils/toasts'
import { t } from 'i18next'

export class TakeProfitLevel extends OrderLevel {
    public takeProfitValues: TakeprofitValues | null = null
    private lastCorrectValue: TakeprofitValues | null = null

    draw(canvas: TGdiPlusCanvas): void {
        const isActive = this.IsCaptured() || this.isMouseInside() || this.isSelected

        const levels = this.owner.orderLevels.get(this.chartWindow)

        const openPriceOrderLevel = levels?.openPriceOrderLevel as MarketLevel

        if (
            this.owner.tpos.TakeProfit === 0 &&
            (openPriceOrderLevel.isSelected ||
                openPriceOrderLevel.IsCaptured() ||
                openPriceOrderLevel.isMouseInside()) &&
            !this.IsCaptured()
        ) {
            this.tooltip = null
            this.drawEmptyState(canvas)
            return
        }

        const ctx = canvas.graphics.Context
        const { Top } = this.getLocation()
        const baseColor = this.isValid ? this.chartWindow.ChartOptions.ColorScheme.TakeProfitColor : this.invalidColor
        const labelText = 'TP'
        let valueText = Number(this.owner.getTakeProfitUSD().toFixed(2))
        let pointsText = `${formatCurrency(this.owner.getTakeProfitPoints(), '', 0)} points`

        if ((this.IsCaptured() || openPriceOrderLevel.IsCaptured()) && this.takeProfitValues) {
            valueText = Number(
                this.owner.getTakeProfitUSD(
                    this.takeProfitValues.price,
                    (openPriceOrderLevel as PendingLevel).currentOpenPrice!
                )
            )
            pointsText = `${formatCurrency(this.takeProfitValues.points, '', 0)} points`
        }

        const ticketText = `#${this.owner.tpos.ticket}`
        const font = '12px Roboto Flex'

        this.drawCommonElements(
            canvas,
            this.getXCoordinateToDrawLevel(ctx),
            Top,
            labelText,
            formatCurrency(Number(valueText)),
            pointsText,
            ticketText,
            isActive,
            baseColor,
            16,
            font
        )

        const percentFromBalance =
            ((this.takeProfitValues?.usd || this.owner.getTakeProfitUSD()) * 100) /
            GlobalProcessingCore.ProcessingCore.Balance

        this.tooltip = `Take Profit +${percentFromBalance.toFixed(2)}%`
    }

    public recalculateLocationByY(price: number, atPrice: number | null = null): void {
        super.recalculateLocationByY(price)

        let newValues: TakeprofitValues | null = null
        const mainChart = this.chartWindow.MainChart

        if (this.calculationStrategy) {
            if (this.calculationStrategy instanceof TakeprofitCalculationStrategy) {
                const takeProfitValues: TakeprofitValues = new TakeprofitValues()
                takeProfitValues.price = price
                takeProfitValues.points = this.owner.getTakeProfitPoints()

                const orderValues: OrderValues = new OrderValues()
                orderValues.lot = this.owner.tpos.lot
                orderValues.atPrice = atPrice || this.owner.tpos.OpenPrice

                const marketValues = new MarketValues()
                marketValues.minimumDistanceToPrice = mainChart.marketValues.minimumDistanceToPrice
                marketValues.minimumPriceChange = mainChart.marketValues.minimumPriceChange
                marketValues.pointValueForOneStandardLot = mainChart.marketValues.pointValueForOneStandardLot
                marketValues.equity = mainChart.marketValues.equity

                if (
                    [TTradePositionType.tp_Buy, TTradePositionType.tp_BuyStop, TTradePositionType.tp_BuyLimit].includes(
                        this.owner.PosType
                    )
                ) {
                    marketValues.ask = atPrice || this.owner.tpos.OpenPrice
                } else {
                    marketValues.bid = atPrice || this.owner.tpos.OpenPrice
                }

                // values that should be shown when dragging the stoploss line
                newValues = this.calculationStrategy.onChangeTakeprofitPrice(
                    takeProfitValues,
                    marketValues,
                    orderValues
                )

                if (newValues) {
                    this.owner.orderLevels.forEach((levels) => {
                        if (
                            levels.takeProfitOrderLevel &&
                            this.type === 'Takeprofit' &&
                            levels.takeProfitOrderLevel instanceof TakeProfitLevel
                        ) {
                            levels.takeProfitOrderLevel.takeProfitValues = newValues
                        }
                    })
                }
            }
        }
    }

    onMouseDown(event: MouseEvent, sender: TMainChart): ChartControl | null {
        this.lastCorrectValue = this.takeProfitValues
            ? { ...this.takeProfitValues }
            : {
                  price: this.owner.tpos.TakeProfit,
                  points: this.owner.getTakeProfitPoints(),
                  usd: this.owner.getTakeProfitUSD(),
                  percent: 0
              }
        return super.onMouseDown(event, sender)
    }

    close(): void {
        this.owner.resetTakeProfit()
    }

    drawEmptyState(canvas: TGdiPlusCanvas): void {
        // paint only drag dots and label under the line if owner has buy pos and над the line if owner has sell pos
        const ctx = canvas.graphics.Context
        const levels = this.owner.orderLevels.get(this.chartWindow)
        if (!levels) {
            return
        }
        const { Left, Top } = (levels.openPriceOrderLevel as MarketLevel).infoBoxLocation

        let x = Left - 20
        const y = Top + 7.5
        if (this.owner.tpos.StopLoss === 0) {
            x -= 35
        }

        const labelText = 'TP'
        const baseColor = this.chartWindow.ChartOptions.ColorScheme.TakeProfitColor
        const font = "12px 'Roboto Flex'"
        ctx.font = font

        // Measure text widths
        const labelTextWidth = getTextWidth(ctx, labelText, font)
        const boxPadding = 8

        // Calculate box width
        const boxWidth = labelTextWidth + boxPadding * 2
        const boxHeight = 16

        const boxX = x - boxWidth / 2

        // Draw rounded rect
        this.drawRoundedRect(ctx, boxX, y - boxHeight / 2, boxWidth, boxHeight, 2, baseColor)

        // Draw dots for drag
        this.drawDragDots(ctx, boxX + 2.5, y - 3, baseColor, false)

        // Draw title inside the box
        const labelX = boxX + 10
        this.drawText(ctx, labelText, labelX, y, baseColor, font, false)

        this.infoBoxLocation = new TRect(boxX, y - boxHeight / 2, boxX + boxWidth, y + boxHeight / 2)

        this.setLocation(this.infoBoxLocation)
    }

    changePoints(newPoints: number, atPrice: number | null): void {
        const mainChart = this.chartWindow.MainChart

        if (this.calculationStrategy) {
            if (this.calculationStrategy instanceof TakeprofitCalculationStrategy) {
                const takeProfitValues: TakeprofitValues = new TakeprofitValues()
                takeProfitValues.points = newPoints

                const orderValues: OrderValues = new OrderValues()
                orderValues.lot = this.owner.tpos.lot
                orderValues.atPrice = atPrice || this.owner.tpos.OpenPrice
                const marketValues = new MarketValues()
                marketValues.minimumDistanceToPrice = mainChart.marketValues.minimumDistanceToPrice
                marketValues.minimumPriceChange = mainChart.marketValues.minimumPriceChange
                marketValues.pointValueForOneStandardLot = mainChart.marketValues.pointValueForOneStandardLot
                marketValues.equity = mainChart.marketValues.equity

                if (
                    [TTradePositionType.tp_Buy, TTradePositionType.tp_BuyStop, TTradePositionType.tp_BuyLimit].includes(
                        this.owner.PosType
                    )
                ) {
                    marketValues.ask = atPrice || this.owner.tpos.OpenPrice
                } else {
                    marketValues.bid = atPrice || this.owner.tpos.OpenPrice
                }

                const newValues = this.calculationStrategy.onChangeTakeprofitPoints(
                    takeProfitValues,
                    marketValues,
                    orderValues
                )

                if (newValues) {
                    this.takeProfitValues = newValues
                    this.owner.tpos.TakeProfit = newValues.price
                }
            }
        }

        this.recalculateLocationByY(this.owner.tpos.TakeProfit, atPrice)
    }

    onMouseUp(event: MouseEvent, sender: TMainChart): void {
        const x = event.offsetX
        const y = event.offsetY
        const isCurrentControl = this.isMouseInsideInfoBox(new TPoint(x, y)) || this.isPointInside(x, y)
        if (!this.isValid) {
            this.takeProfitValues = this.lastCorrectValue
            this.owner.tpos.TakeProfit = this.lastCorrectValue?.price || 0
            if (this.owner.tpos.PosType === TTradePositionType.tp_Buy && isCurrentControl) {
                showErrorToast({
                    title: t('orders.modal.toasts.invalidTakeProfitBuyTitle'),
                    message: t('orders.modal.toasts.invalidTakeProfitBuyText')
                })
            } else if (this.owner.tpos.PosType === TTradePositionType.tp_Sell && isCurrentControl) {
                showErrorToast({
                    title: t('orders.modal.toasts.invalidTakeProfitSellTitle'),
                    message: t('orders.modal.toasts.invalidTakeProfitSellText')
                })
            }
        } else {
            if (this.takeProfitValues && this.IsCaptured()) {
                if (isCurrentControl && this.lastCorrectValue?.price !== this.takeProfitValues.price) {
                    const decimals = this.owner.symbol?.symbolInfo.decimals
                    showSuccessToast({
                        title: t('orders.modal.toasts.takeProfitUpdated', {
                            value: this.takeProfitValues.price.toFixed(decimals || 5)
                        })
                    })
                }
                this.owner.tpos.TakeProfit = this.takeProfitValues.price
            }
        }

        this.recalculateLocationByY(this.owner.tpos.TakeProfit)

        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
    }
}
