import { ChartControl, chartControlEvent, ChartControlParams } from '@fto/chart_components/ChartControl'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { IGPFont, IGPPen, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { TGdiPlusCanvas, TRoundRectType } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { SizeMode } from './ChartLayersEnums'

export class ButtonSimple extends ChartControl {
    private text = ''
    private isNeedDrawBorder = true
    private font = new IGPFont(new TGPFontFamily('Roboto Flex'), 14, [])
    private borderRadius = 4
    private colors: {
        borderNormal: IGPPen
        borderHovered: IGPPen
        backNormal: IGPSolidBrush
        backHovered: IGPSolidBrush
        textNormal: IGPSolidBrush
        textHovered: IGPSolidBrush
    } = {
        borderNormal: new IGPPen('#667085', 1),
        borderHovered: new IGPPen('#5e6270', 1),
        backNormal: new IGPSolidBrush('#ffffff'),
        backHovered: new IGPSolidBrush('#f4f4f4'),
        textNormal: new IGPSolidBrush('#101828'),
        textHovered: new IGPSolidBrush('#2F80ED')
    }

    private textWidth = 0
    private textHeight = 0

    private sizeMode: SizeMode
    private padding: number
    private textXOffset: number
    private textYOffset: number

    constructor(chartControlParams: ChartControlParams) {
        super(chartControlParams)

        this.sizeMode = SizeMode.Manual // Default size mode
        this.padding = 6 // Default padding
        this.textXOffset = 0 // Default X offset for text
        this.textYOffset = 0 // Default Y offset for text
    }

    draw(canvas: TGdiPlusCanvas): void {
        if (!this.IsVisible()) {
            return
        }

        const location = this.getLocation()

        let buttonWidth = location.Width
        let buttonHeight = location.Height

        if (this.sizeMode === SizeMode.Auto) {
            if (!this.textWidth) {
                this.textWidth = canvas.TextWidth(this.text, this.font)
            }
            if (!this.textHeight) {
                this.textHeight = canvas.TextHeight(this.text, this.font)
            }

            buttonWidth = this.textWidth + this.padding * 2
            buttonHeight = this.textHeight + this.padding * 2
        }

        location.Right = buttonWidth + location.Left
        location.Bottom = buttonHeight + location.Top
        this.setLocation(location)

        if (this.isNeedDrawBorder) {
            canvas.strokeRect(
                location,
                TRoundRectType.BOTH,
                this.borderRadius,
                true,
                this.colors.borderNormal,
                this.colors.backNormal
            )
        }

        if (this.isMouseInside()) {
            canvas.FillRectRounded(location, this.colors.backHovered, 4)
        }

        // todo draw text
        let textX = location.Left + this.textXOffset
        let textY = location.Top + this.textYOffset
        canvas.graphics.Context.textAlign = 'left'
        canvas.graphics.Context.textBaseline = 'top'

        canvas.graphics.Context.fillStyle = this.colors.textNormal.getColor()

        if (this.sizeMode === SizeMode.Auto) {
            textX = location.Left + buttonWidth / 2 + this.textXOffset
            textY = location.Top + buttonHeight / 2 + this.textYOffset
            canvas.graphics.Context.textAlign = 'center'
            canvas.graphics.Context.textBaseline = 'middle'
        }
        canvas.graphics.Context.font = this.font.toString()
        canvas.graphics.Context.fillText(this.text, textX, textY)
    }

    onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        let result = null
        if (super.onMouseDown(event, sender)) {
            result = this
            this.notify(chartControlEvent.BUTTON_PRESSED)
        }
        return result
    }

    setTextOffset(x: number, y: number): void {
        this.textXOffset = x
        this.textYOffset = y
    }
    setSizeMode(mode: SizeMode): void {
        this.sizeMode = mode
    }
    setBorderRadius(radius: number): void {
        this.borderRadius = radius
    }

    setBorderColor(color: string): void {
        this.colors.borderNormal = new IGPPen(color, 1)
    }

    setBorderColorHovered(color: string): void {
        this.colors.borderHovered = new IGPPen(color, 1)
    }

    setBackgroundColor(color: string): void {
        this.colors.backNormal = new IGPSolidBrush(color)
    }

    setBackgroundColorHovered(color: string): void {
        this.colors.backHovered = new IGPSolidBrush(color)
    }

    setTextColor(color: string): void {
        this.colors.textNormal = new IGPSolidBrush(color)
    }

    setTextColorHovered(color: string): void {
        this.colors.textHovered = new IGPSolidBrush(color)
    }

    setBorderVisible(visible: boolean): void {
        this.isNeedDrawBorder = visible
    }

    getText(): string {
        return this.text
    }

    setText(text: string): void {
        this.text = text
    }

    getFont(): IGPFont {
        return this.font
    }

    setFont(font: IGPFont): void {
        this.font = font
    }
}
