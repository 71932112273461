import { FC, Fragment, useEffect, useMemo, useState } from 'react'

import { CHECKOUT_PAGE, DASHBOARD_ROUTE, makeRoute } from '@root/constants/routes'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser, $getUserSubscriptionStatus } from '@root/store/auth/selectors'

import styles from './index.module.scss'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { PaymentFailedModal } from './components/Modals/PaymentFailedModal'
import { useNavigate } from 'react-router-dom'
import { AppHeader } from '@root/components/AppHeader'
import { fireMixpanelEvent } from '@root/utils/api'

const meta = import.meta.env
const PAYPRO_PAGE_TEMPLATE = '17714'
const PAYPRO_PAGE_TEMPLATE_NON_TRIAL = '17846'

const CheckoutPage: FC = () => {
    const { userId, email, displayName } = useAppSelector($getUser)

    const { isTrial, isActive } = useAppSelector($getUserSubscriptionStatus)
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search)
    const { productId, pageTemplate } = useMemo(() => {
        const trial = params.get('trial')
        return {
            productId: trial && trial === 'false' ? meta.VITE_PAYPRO_PRODUCT_ID_NON_TRIAL : meta.VITE_PAYPRO_PRODUCT_ID,
            pageTemplate: trial && trial === 'false' ? PAYPRO_PAGE_TEMPLATE_NON_TRIAL : PAYPRO_PAGE_TEMPLATE
        }
    }, [params])

    const encodedEmail = useMemo(() => encodeURIComponent(email), [email])

    const encodedFullName = useMemo(() => {
        const fullName = displayName.split(' ')

        return [encodeURIComponent(fullName[0]), encodeURIComponent(fullName[1])]
    }, [displayName])

    useEffect(() => {
        if (isTrial || isActive) {
            navigate(makeRoute(DASHBOARD_ROUTE))
        }
        const actionType = params.get('action')
        if (actionType && actionType === 'payment') {
            addModal(MODAL_NAMES.payment.failed)
            navigate(makeRoute(CHECKOUT_PAGE))
        }
    }, [isTrial, isActive])

    useEffect(() => {
        fireMixpanelEvent('pageview', { pagename: 'checkout' })

        const callback = (event: { origin: string; data: { type: string; url: string } }) => {
            if (event.origin !== 'https://fto-staging.forextester.com') {
                return // Ignore messages from untrusted origins
            }

            if (event.data.type === 'REDIRECT') {
                window.location.href = makeRoute(DASHBOARD_ROUTE + '?action=payment&success=true')
            }
        }
        window.addEventListener('message', callback, false)

        return () => {
            window.removeEventListener('message', callback)
        }
    }, [])

    const paymentSrc = useMemo(() => {
        // NOTE: this is real payment link
        if (meta.VITE_ENV_TYPE === 'staging') {
            return `https://store.payproglobal.com/checkout?&exfo=${meta.VITE_PAY_PRO_EXFO}&products[1][id]=${meta.VITE_PAYPRO_PRODUCT_ID}&page-template=17714&x-user=${userId}&billing-email=${encodedEmail}`
        }

        return `https://store.payproglobal.com/checkout?&exfo=${meta.VITE_PAY_PRO_EXFO}&products[1][id]=${productId}&page-template=${pageTemplate}&x-user=${userId}&billing-email=${encodedEmail}&billing-first-name=${encodedFullName[0]}&billing-last-name=${encodedFullName[1]}`
    }, [productId, pageTemplate, userId, encodedEmail, encodedFullName])

    return (
        <Fragment>
            <AppHeader isCheckout />
            <iframe
                className={styles.frame}
                src={paymentSrc}
                // NOTE: that is production link
                // src={`https://store.payproglobal.com/checkout?&exfo=${meta.VITE_PAY_PRO_EXFO}&products[1][id]=${meta.VITE_PAYPRO_PRODUCT_ID}&page-template=17714&use-test-mode=true&secret-key=${meta.VITE_PAY_PRO_SECRET_KEY}&x-user=${userId}&billing-email=${email}`}
                // NOTE: that is temp link
                // src={`https://store.payproglobal.com/checkout?&exfo=${meta.VITE_PAY_PRO_EXFO}&products[1][id]=${productId}&page-template=${pageTemplate}&x-user=${userId}&billing-email=${email}&billing-first-name=${fullName[0]}&billing-last-name=${fullName[1]}`}
            />
            <PaymentFailedModal />
        </Fragment>
    )
}

export default CheckoutPage
