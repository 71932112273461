import { forwardRef, isValidElement, MouseEvent, ReactElement, ReactNode } from 'react'
import cx from 'classnames'

import { Icon } from '@fto/icons'
import { Tooltip, tooltipColors, TooltipProps, placements } from '@fto/ui'

import styles from './index.module.scss'

export const ButtonVariants = [
    'primary',
    'secondary',
    'tetriatry-white',
    'tetriatry-gray',
    'marketing',
    'gold',
    'red'
] as const

export const ButtonSizes = ['default', 'small', 'compact', 'tiny'] as const
const isIcon = (element: ReactElement): boolean => element.type === Icon

export type Props = {
    label: string | ReactElement
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void
    type?: (typeof ButtonVariants)[number]
    block?: boolean
    size?: (typeof ButtonSizes)[number]
    buttonType?: 'button' | 'submit'
    withTooltip?: boolean
    tooltipText?: TooltipProps['content']
    tooltipPlacement?: (typeof placements)[number]
    tooltipColor?: keyof typeof tooltipColors
    shouldShowTooltipArrow?: boolean
    disabled?: boolean
    loading?: boolean
    before?: ReactNode
    after?: ReactNode
    classNames?: {
        root?: string
        content?: string
        label?: string
    }
    tooltipClassNames?: TooltipProps['classNames']
    disableFixedSize?: boolean
}

export const Button = forwardRef<HTMLButtonElement, Props>(
    (
        {
            buttonType = 'button',
            onClick,
            loading,
            type = 'primary',
            size = 'default',
            block = false,
            disabled,
            before,
            after,
            label,
            classNames,
            tooltipText = '',
            tooltipPlacement,
            tooltipColor,
            shouldShowTooltipArrow = true,
            withTooltip = false,
            tooltipClassNames = '',
            disableFixedSize = false,
            ...rest
        },
        ref
    ) => {
        const isIconLabel = isValidElement(label) && isIcon(label)

        const btnJSX = (
            <button
                {...rest}
                ref={ref}
                type={buttonType}
                onClick={onClick}
                disabled={disabled || loading}
                className={cx(styles.root, classNames?.root, { [styles.block]: block })}
            >
                {/* WARNING!!! THIS div is intentional, because we're willing to support safari 13 (read more in link below) */}
                {/* SAFARI BUG: https://github.com/philipwalton/flexbugs#9-some-html-elements-cant-be-flex-containers */}
                <div
                    className={cx(styles.content, classNames?.content, {
                        [styles[`size-${size}`]]: size,
                        [styles[`type-${type}`]]: type,
                        [styles.isIconLabel]: isIconLabel,
                        [styles.loading]: loading,
                        [styles.disabled]: disabled,
                        [styles.block]: block,
                        [styles.fitContent]: disableFixedSize
                    })}
                >
                    {before}
                    <span className={cx(styles.label, classNames?.label)}>{label}</span>
                    {after}
                </div>
            </button>
        )

        return withTooltip ? (
            <Tooltip
                content={tooltipText}
                placement={tooltipPlacement}
                arrow={shouldShowTooltipArrow}
                color={tooltipColor}
                classNames={tooltipClassNames as TooltipProps['classNames']}
            >
                {btnJSX}
            </Tooltip>
        ) : (
            btnJSX
        )
    }
)

Button.displayName = 'Button'

export default Button
