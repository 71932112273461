import { makeObservable, action, observable } from 'mobx'

import { FavoriteIndicatorListType } from './types'

class IndicatorsStore {
    public favoriteIndicators: FavoriteIndicatorListType = []

    constructor() {
        makeObservable(this, {
            favoriteIndicators: observable,
            updateFavoriteIndicators: action,
            resetStore: action
        })
        this.updateFavoriteIndicators = this.updateFavoriteIndicators.bind(this)
        this.resetStore = this.resetStore.bind(this)
    }

    public updateFavoriteIndicators(
        updater: ((prevSettings: FavoriteIndicatorListType) => FavoriteIndicatorListType) | FavoriteIndicatorListType
    ) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.favoriteIndicators = updater(this.favoriteIndicators)
        } else {
            // If updater is a value, directly apply the updates
            this.favoriteIndicators = updater
        }
    }

    public resetStore() {
        this.favoriteIndicators = []
    }
}

export default new IndicatorsStore()
