import { FC, useCallback, useState } from 'react'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'
import { TIME_TEMPLATES } from '@root/constants/timeTemplates'
import { formatMoney, timeToMessageFilters } from '@root/utils/filters'
import TableBodyItem from '@root/pages/Dashboard/components/ProjectsTable/components/TableBodyItem'
import TablePairs from '@root/pages/Dashboard/components/ProjectsTable/components/TablePairs'
import ProfitAndLoseTag from '@root/pages/Dashboard/components/ProdilAndLoseTag/ProdilAndLoseTag'
import TableItemContextMenu from '@root/pages/Dashboard/components/TableItemContextMenu/TableItemContextMenu'
import { ProjectType } from '@root/types/projects'
import { TableItemAction } from '@root/pages/Dashboard/components/ProjectsTable/ProjectsTable'
import styles from '@root/pages/Dashboard/components/ProjectsTable/projects-table.module.scss'

type Props = {
    projectData: ProjectType
    handleContextMenuAction: (type: TableItemAction, id: string) => void
    openTester: (id: ProjectType['Id']) => void
    onClick: () => void
}
const ProjectRow: FC<Props> = ({ projectData, handleContextMenuAction, openTester, onClick }) => {
    const { ProjectName, Created, LastUse, StartDate, EndDate, Deposit, Symbols, TotalProfitAndLoss, Id } = projectData
    const [isRowOverlayOpened, setIsRowOverlayOpened] = useState(false)

    const { t } = useTranslation()

    const startTesting = useCallback(() => {
        openTester(Id)
    }, [Id, openTester])
    return (
        <tr
            onMouseEnter={() => setIsRowOverlayOpened(true)}
            onMouseLeave={() => setIsRowOverlayOpened(false)}
            className={styles.tableBodyRow}
            key={ProjectName}
            onClick={onClick}
        >
            <td>
                <Flex direction={'column'}>
                    <Typography title={ProjectName} type={'text-semibold'} truncate className={styles.Name}>
                        {ProjectName}
                    </Typography>
                    <Typography color={'gray-700'} type={'subtext-regular'}>
                        {t('dashboard.table.row.timeInfo', {
                            createdDate: dayjs(Created).format(TIME_TEMPLATES.DASHBOARD_NAME),
                            testedDate: timeToMessageFilters(LastUse)
                        })}
                    </Typography>
                </Flex>
            </td>
            <TableBodyItem className={styles.onSmallTabletHide}>
                <Typography color={'gray-900'} type={'subtext-regular'}>
                    {dayjs(StartDate).format(TIME_TEMPLATES.DASHBOARD_PERIOD)} - {''}
                    {dayjs(EndDate).format(TIME_TEMPLATES.DASHBOARD_PERIOD)}
                </Typography>
            </TableBodyItem>
            <TableBodyItem className={styles.onSmallTabletHide}>
                <Typography color={'gray-900'} type={'subtext-regular'}>
                    {formatMoney(Deposit)}
                </Typography>
            </TableBodyItem>
            <TableBodyItem className={styles.onLargeTabletHide}>
                <TablePairs pairs={Symbols} />
            </TableBodyItem>
            <TableBodyItem width={'170px'} className={styles.onMobileHide}>
                <ProfitAndLoseTag profitLoss={TotalProfitAndLoss} deposit={Deposit} />
            </TableBodyItem>
            <TableBodyItem
                max-width={'100px'}
                className={classNames(styles.button, { [styles.opened]: isRowOverlayOpened })}
            >
                <TableItemContextMenu
                    setIsOpened={setIsRowOverlayOpened}
                    projectId={Id}
                    action={handleContextMenuAction}
                    openTester={startTesting}
                />
                <Button
                    classNames={{
                        content: styles.playButton,
                        root: styles.playButton
                    }}
                    label={<Icon color={'white'} name={'play'} />}
                    type={'primary'}
                    onClick={startTesting}
                />
            </TableBodyItem>
        </tr>
    )
}

export default ProjectRow
