import { useEffect } from 'react'
import { fireMixpanelEvent } from '@root/utils/api'

const useUrlParamsForMixpanel = () => {
    useEffect(() => {
        // GET url params from email link

        const params = new URLSearchParams(window.location.search)

        let paramList: { [key: string]: string } = {}

        params.forEach((value, key) => {
            paramList = { ...paramList, [key]: value }
        })

        const cameFromEmail = paramList['utm_source'] && paramList['utm_source'] === 'email'

        if (cameFromEmail) {
            fireMixpanelEvent('fto_email_conversion', { ...paramList })
        }
    }, [])
}

export default useUrlParamsForMixpanel
