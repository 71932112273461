import { t } from 'i18next'
import { TIndexBuffer } from '../api/IIndicatorApi'
import Constants, {
    TDrawStyle,
    TMAType,
    TOptValue_number,
    TOptionType,
    TOutputWindow,
    TPenStyle,
    TPriceType
} from '../api/IndicatorInterfaceUnit'
import { UserIndicator } from '../api/UserIndicator'

export default class BollingerBands extends UserIndicator {
    period: TOptValue_number = new TOptValue_number(8)
    Shift: TOptValue_number = new TOptValue_number(0)
    Deviation: TOptValue_number = new TOptValue_number(2.0)
    MAType: TOptValue_number = new TOptValue_number(TMAType.ma_SMA)
    ApplyToPrice: TOptValue_number = new TOptValue_number(TPriceType.pt_Close)

    // Buffers
    MA!: TIndexBuffer
    UpBand!: TIndexBuffer
    DownBand!: TIndexBuffer

    Init(): void {
        this.api.IndicatorShortName(t('indicators.bollingerBands'))
        this.api.SetOutputWindow(TOutputWindow.ow_ChartWindow)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.general.generalFields.period'), TOptionType.ot_Integer, this.period)
        this.api.SetOptionRange(t('indicatorModal.general.generalFields.period'), 1, Constants.MAX_INT)

        this.api.RegOption(t('indicatorModal.general.generalFields.deviation'), TOptionType.ot_double, this.Deviation)
        this.api.SetOptionRange(t('indicatorModal.general.generalFields.deviation'), 0.1, 200)

        this.api.RegOption(t('indicatorModal.general.generalFields.shift'), TOptionType.ot_Integer, this.Shift)
        this.api.RegMATypeOption(this.MAType)
        this.api.RegApplyToPriceOption(this.ApplyToPrice, t('indicatorModal.general.applyToPrice'))

        this.MA = this.api.CreateIndexBuffer()
        this.UpBand = this.api.CreateIndexBuffer()
        this.DownBand = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(3)
        this.api.SetIndexBuffer(0, this.UpBand)
        this.api.SetIndexLabel(0, t('indicatorModal.bollingerBands.fields.upBand'))
        this.api.SetIndexBuffer(1, this.MA)
        this.api.SetIndexLabel(1, t('indicatorModal.general.generalFields.ma'))
        this.api.SetIndexBuffer(2, this.DownBand)
        this.api.SetIndexLabel(2, t('indicatorModal.bollingerBands.fields.downBand'))

        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#008080')
        this.api.SetIndexStyle(1, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#008080')
        this.api.SetIndexStyle(2, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#008080')

        this.api.SetIndexDrawBegin(0, this.period.value - 1 + this.Shift.value)
        this.api.SetIndexDrawBegin(1, this.period.value - 1 + this.Shift.value)
        this.api.SetIndexDrawBegin(2, this.period.value - 1 + this.Shift.value)
    }

    Calculate(index: number): void {
        if (index + this.period.value >= this.api.Bars()) {
            return
        }

        const value = this.api.GetMA(
            index,
            0,
            this.period.value,
            this.MAType.value,
            this.ApplyToPrice.value,
            this.MA.getValue(index + 1)
        )
        this.MA.setValue(index, value)

        let sum = 0
        for (let i = index; i <= index + this.period.value - 1; i++) {
            sum += Math.pow(Math.abs(this.api.GetPrice(i, this.ApplyToPrice.value) - value), 2)
        }
        const sd = Math.sqrt(sum / this.period.value) * this.Deviation.value
        this.UpBand.setValue(index, value + sd)
        this.DownBand.setValue(index, value - sd)
    }

    OnParamsChange() {
        this.api.SetBufferShift(0, this.Shift.value)
        this.api.SetBufferShift(1, this.Shift.value)
        this.api.SetBufferShift(2, this.Shift.value)
    }

    toJSON(): object {
        return {
            period: this.period.value,
            Shift: this.Shift.value,
            Deviation: this.Deviation.value,
            MAType: this.MAType.value,
            ApplyToPrice: this.ApplyToPrice.value
        }
    }
}
