import StrangeError from '../common/common_errors/StrangeError'
import {
    TOperationType,
    TPendingOpType,
    TSymbolType,
    TTradePositionType
} from '../ft_types/common/BasicClasses/BasicEnums'
import { TSymbolData } from '../ft_types/data/SymbolData'
import { TSymbolCalcType } from '../ft_types/data/DataEnums'
import { NotImplementedError } from '../utils/common_utils'
import GlobalSymbolList from '../globals/GlobalSymbolList'

export default class ProcessingCoreUtils {
    public static StrPosType(OpType: TOperationType | TPendingOpType | TTradePositionType): string {
        if (this.isOperationType(OpType)) {
            switch (OpType) {
                case TOperationType.ot_Sell: {
                    return 'sell'
                }
                default: {
                    return 'buy'
                }
            }
        } else if (this.isPendingOpType(OpType)) {
            switch (OpType) {
                case TPendingOpType.pt_BuyLimit: {
                    return 'buy limit'
                }
                case TPendingOpType.pt_SellLimit: {
                    return 'sell limit'
                }
                case TPendingOpType.pt_BuyStop: {
                    return 'buy stop'
                }
                default: {
                    return 'sell stop'
                }
            }
        } else if (this.isTradePositionType(OpType)) {
            switch (OpType) {
                case TTradePositionType.tp_Sell: {
                    return 'sell'
                }
                case TTradePositionType.tp_Buy: {
                    return 'buy'
                }
                case TTradePositionType.tp_BuyLimit: {
                    return 'buy limit'
                }
                case TTradePositionType.tp_SellLimit: {
                    return 'sell limit'
                }
                case TTradePositionType.tp_BuyStop: {
                    return 'buy stop'
                }
                case TTradePositionType.tp_SellStop: {
                    return 'sell stop'
                }
                case TTradePositionType.tp_Deposit: {
                    return 'deposit'
                }
                case TTradePositionType.tp_Withdrawal: {
                    return 'withdrawal'
                }
                case TTradePositionType.tp_Credit: {
                    return 'credit'
                }
                default: {
                    return ''
                }
            }
        } else {
            throw new StrangeError('Unknown operation type')
        }
    }

    // Improved type guard functions using exhaustive checks
    private static isOperationType(opType: any): opType is TOperationType {
        return Object.values(TOperationType).includes(opType)
    }

    private static isPendingOpType(opType: any): opType is TPendingOpType {
        return Object.values(TPendingOpType).includes(opType)
    }

    private static isTradePositionType(opType: any): opType is TTradePositionType {
        return Object.values(TTradePositionType).includes(opType)
    }

    public static GetCalculationType(symbol: TSymbolData): TSymbolCalcType {
        switch (symbol.symbolInfo.s_type) {
            case TSymbolType.st_CurrencyPair: {
                return this.GetCalculationTypeForForex(symbol)
            }
            case TSymbolType.st_Crypto: {
                throw new NotImplementedError('Index calculation type not implemented.')
            }
            case TSymbolType.st_Stock: {
                throw new NotImplementedError('Stock calculation type not implemented.')
            }
            default: {
                throw new StrangeError('Unknown symbol type')
            }
        }
    }

    public static GetCalculationTypeForForex(symbol: TSymbolData): TSymbolCalcType {
        if (symbol.symbolInfo.SymbolName.startsWith('USD')) {
            return TSymbolCalcType.sc_InvertedUSD_USDxxx
        } else if (symbol.symbolInfo.SymbolName.endsWith('USD')) {
            return TSymbolCalcType.sc_Normal_xxxUSD
        } else {
            return TSymbolCalcType.sc_Cross
        }
    }

    public static GetSymbolForConversionToUSD(
        symbol: TSymbolData,
        force = false
    ): {
        symbolName: string
        isUSDBaseCurrency: boolean
        isNeedConversionToSymbolQuoteCurrency: boolean
    } {
        const firstCurrency = symbol.symbolInfo.SymbolName.slice(0, 3)
        const secondCurrency = symbol.symbolInfo.SymbolName.slice(3, 6)

        if (symbol.symbolInfo.group === 'Crosses' || symbol.symbolInfo.group === 'Exotic' || force) {
            const secondCurrencyParams = GlobalSymbolList.SymbolList.CheckPossibleCrossPairs(secondCurrency)
            if (secondCurrencyParams) {
                return {
                    symbolName: secondCurrencyParams.symbolName,
                    isUSDBaseCurrency: secondCurrencyParams.isUSDBaseCurrency,
                    isNeedConversionToSymbolQuoteCurrency: true
                }
            }

            const firstCurrencyParams = GlobalSymbolList.SymbolList.CheckPossibleCrossPairs(firstCurrency)
            if (firstCurrencyParams) {
                return {
                    symbolName: firstCurrencyParams.symbolName,
                    isUSDBaseCurrency: firstCurrencyParams.isUSDBaseCurrency,
                    isNeedConversionToSymbolQuoteCurrency: false
                }
            }

            throw new StrangeError(`Symbol ${symbol.symbolInfo.SymbolName} is not correct for conversion to USD.`)
        }

        return {
            symbolName: symbol.symbolInfo.SymbolName,
            isUSDBaseCurrency: false,
            isNeedConversionToSymbolQuoteCurrency: false
        }
    }

    public static isPendingOrder(posType: TTradePositionType): boolean {
        return [
            TTradePositionType.tp_BuyLimit,
            TTradePositionType.tp_BuyStop,
            TTradePositionType.tp_SellLimit,
            TTradePositionType.tp_SellStop
        ].includes(posType)
    }

    public static isMarketOrder(posType: TTradePositionType): boolean {
        return [TTradePositionType.tp_Sell, TTradePositionType.tp_Buy].includes(posType)
    }
}
