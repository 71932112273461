import { TCursor, TCustomCursor } from '@fto/lib/ft_types/common/CursorPointers'
import { TChart } from '../../charting/chart_classes/BasicChart'
import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import { TColor, TPoint, TRect } from '../../delphi_compatibility/DelphiBasicTypes'
import { ColorHelperFunctions } from '../../drawing_interface/ColorHelperFunctions'
import { TChartWindow } from '../chart_windows/ChartWindow'
import { TPaintToolType } from '../paint_tools/PaintToolsAuxiliaryClasses'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import { NotImplementedError } from '@fto/lib/utils/common_utils'
import { t } from 'i18next'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'

dayjs.extend(utc)

export class TDateTimeBar extends TChart {
    private _mainChart: TMainChart | null = null
    private get MainChart(): TMainChart {
        if (!this._mainChart) {
            throw new StrangeError('MainChart is not defined.')
        }
        return this._mainChart
    }

    public chartWin: TChartWindow
    private isCaptured = false
    private resizeDateTimeCallback: (() => void) | null = null

    constructor(parent_window: TChartWindow) {
        super(parent_window)

        this.chartWin = parent_window
        this.SetName(`TimeBar of ${this.chartWin.DName}`)
        this.align = 'alBottom'
        this.fMargins = new TRect(0, 0, 0, 0)
    }

    private get SymbolData(): TSymbolData {
        return this.MainChart.SymbolData
    }

    public setMainChart(mainChart: TMainChart): void {
        this._mainChart = mainChart
    }

    public Paint(): void {
        if (!this.isDataLoaded || !this.isChartOptionsInitialized || !this.is_HTML_Canvas_initialized()) {
            DebugUtils.logTopic(
                ELoggingTopics.lt_Painting,
                `TDateTimeBar ${this.DName} - Paint - Data is not loaded or chart options are not initialized or canvas is not ready`
            )
            return
        }
        try {
            if (!this.SymbolData.isCurrentTestingDateInAvailableRange) {
                DebugUtils.logTopic(
                    ELoggingTopics.lt_Painting,
                    'TDateTimeBar - Paint - Current testing date is not in available range'
                )
                return
            }

            this.PaintCounter++
            this.CalcDimensions()
            this.InitPaintContextCache()

            const context = this.PaintContextCache.canvasContext
            const paintRect = this.PaintContextCache.PaintRect

            context.fillStyle = this.ChartOptions.ColorScheme.BackgroundColor
            context.fillRect(paintRect.Left, paintRect.Top, paintRect.Width, paintRect.Height)

            if (!this._bars || !this._bars.LastItemInTestingIndexAvailable || this.ChartOptions.ProfitChartOldStyle) {
                return
            }

            context.font = GlobalOptions.Options.DateScaleFont
            context.fillStyle = this.ChartOptions.ColorScheme.FrameAndTextColor
            context.strokeStyle = this.ChartOptions.ColorScheme.FrameAndTextColor

            const lines = this.GetGridLines()

            for (const line of lines) {
                if (line.visible) {
                    const x = line.x

                    context.beginPath()
                    context.moveTo(x, paintRect.Top)
                    context.lineTo(x, paintRect.Top + 2)
                    context.stroke()

                    if (line.bold) {
                        context.font = `bold ${context.font}`
                    } else {
                        context.font = GlobalOptions.Options.DateScaleFont
                    }

                    const textWidth = context.measureText(line.text).width
                    context.fillText(line.text, x - textWidth / 2, GlobalOptions.Options.DateScaleFontSize)
                    context.closePath()
                }
            }
            const borderCoords = this.chartWin.MainChart.getPriceBorderMargins()
            context.fillStyle = this.ChartOptions.ColorScheme.BackgroundColor
            context.fillRect(
                paintRect.Width - borderCoords.Width,
                paintRect.Top,
                borderCoords.Right - borderCoords.Left,
                paintRect.Height
            )

            this.PaintMarkers()
            this.PaintBorders()

            if (this.fPosMarker.DateTime !== -1) {
                this.PaintMarker(this.fPosMarker.DateTime, this.ChartOptions.ColorScheme.FrameAndTextColor, true)
            }
            if (this.chartWin.mouseOverChart || this.chartWin.focused) {
                this.PaintTimeZone()
            }
        } catch (error) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Error during painting in TDateTimeBar:', error)
        } finally {
            this.FreePaintContextCache()
        }
    }
    private PaintTimeZone(): void {
        const timeZoneString = GlobalTimezoneDSTController.Instance.getTimezoneString()

        const context = this.PaintContextCache.canvasContext

        const x: number = context.canvas.width - (this.MainChart as TMainChart).getPriceBorderWidth()
        const y: number = context.canvas.height - 5
        context.font = `${GlobalOptions.Options.PriceAxisFontSize}px Roboto Flex`

        context.fillStyle = this.ChartOptions.ColorScheme.FrameAndTextColor
        const textHeight = 2

        context.fillText(timeZoneString, x, y - textHeight / 2 + 2)
    }

    private PaintBorders(): void {
        const context = this.PaintContextCache.canvasContext
        const paintRect = this.PaintContextCache.PaintRect

        context.fillStyle = this.ChartOptions.ColorScheme.BackgroundColor
        // paint only top border
        context.moveTo(paintRect.Left, paintRect.Top + 1)
        context.lineTo(paintRect.Right, paintRect.Top + 1)
        context.stroke()
    }
    protected PaintMarkers(): void {
        this.MainChart.CalcDimensions()

        for (let i = 0; i < this.MainChart.PaintTools.Count; i++) {
            const tool = this.MainChart.PaintTools[i]
            // Check if the tool is a vertical line and is visible on the current timeframe
            if (tool.ToolType === TPaintToolType.tt_VLine && tool.IsVisibleOnCurrTimeframe()) {
                if (!tool.IsVisible()) continue
                tool.RecountScreenCoords()
                if (tool.visible) {
                    this.PaintMarker(tool.points[0].time, tool.LineStyle.color)
                }
            }
        }
    }

    protected PaintMarker(DateTime: TDateTime, color: TColor, isCrossHairMark = false): void {
        // Convert the color to a basic color
        color = ColorHelperFunctions.BasicColor(color)

        // Get the X position from the date
        const x: number = this.GetXFromDate(DateTime)

        const context = this.PaintContextCache.canvasContext
        const paintRect = this.PaintContextCache.PaintRect

        // Draw the marker line
        context.beginPath()
        context.strokeStyle = color
        context.lineWidth = 1
        context.moveTo(x, paintRect.Top)
        context.lineTo(x, paintRect.Top + 3)
        context.stroke()

        const textColor = ColorHelperFunctions.GetAntiColor(color)
        const textFont = GlobalOptions.Options.DateScaleFont
        context.font = textFont
        context.fillStyle = textColor

        let timeInMilliseconds = DateUtils.toUnixTimeMilliseconds(
            GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(DateTime)
        )
        if (this.chartWin && this.chartWin.Bars && this.chartWin.Bars.DataDescriptor.timeframe >= 1440) {
            timeInMilliseconds = DateUtils.toUnixTimeMilliseconds(
                Math.trunc(GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(DateTime))
            )
        }

        let dateFormat = t('charting.dateTimeBar.markerDateTimeFormat')
        if (this.chartWin && this.chartWin.Bars && this.chartWin.Bars.DataDescriptor.timeframe >= 1440) {
            dateFormat = t('charting.dateTimeBar.markerDateTimeFormatShort')
        }
        const s = dayjs.utc(timeInMilliseconds).format(t(dateFormat))

        const w: number = context.measureText(s).width

        const rectForFillWidth = w + 4
        context.fillStyle = color
        const left: number = x - rectForFillWidth / 2
        const top: number = paintRect.Top + 3
        const right: number = left + rectForFillWidth
        const bottom: number = top + paintRect.Bottom - paintRect.Top - 3
        const markRc: TRect = new TRect(left, top, right, bottom)

        if (isCrossHairMark) {
            if (x >= this.MainChart.GetPaintRect().Left && x <= this.MainChart.GetPaintRect().Right) {
                //TODO: should we actually use MainChart.PaintRect instead of paintRect?

                if (markRc.Left < paintRect.Left) {
                    const diff = Math.abs(paintRect.Left - markRc.Left)
                    markRc.Left = markRc.Left + diff
                    markRc.Right = markRc.Right + diff
                }

                context.fillRect(markRc.Left, markRc.Top, markRc.Width, markRc.Height)

                context.font = textFont
                context.fillStyle = textColor
                context.fillText(s, markRc.Left + 2, paintRect.Bottom - 2)
            }
        } else {
            context.fillRect(markRc.Left, markRc.Top, markRc.Width, markRc.Height)

            context.font = textFont
            context.fillStyle = textColor
            context.fillText(s, markRc.Left + 2, paintRect.Top + 14)
        }
    }

    public ChartUnderMouse(event: MouseEvent): TChart | null {
        const relativeCoordinates = this.MouseToLocal(event)

        const relativeX = relativeCoordinates.x // X relative to chartElement
        const relativeY = relativeCoordinates.y // Y relative to chartElement

        if (this.IsMouseInsideXY(relativeX, relativeY)) {
            // console.log('Mouse is inside the main chart')
            return this.MainChart
        }

        return null
    }

    public SetCursor(CursorStyle: TCursor | TCustomCursor): void {
        this.Cursor = CursorStyle
    }

    public get IsCaptured(): boolean {
        return this.isCaptured
    }

    public OnMouseDown(event: MouseEvent, sender: TChart): void {
        this.isCaptured = true

        const timezoneControlRc = new TRect(0, 0, 0, 0)
        timezoneControlRc.Left =
            sender.CanvasContext.canvas.width - (this.MainChart as TMainChart).getPriceBorderWidth()
        timezoneControlRc.Top = 0
        timezoneControlRc.Right = timezoneControlRc.Left + (this.MainChart as TMainChart).getPriceBorderWidth()
        timezoneControlRc.Bottom = sender.CanvasContext.canvas.height

        const relativeCoordinates = this.MouseToLocal(event)

        let pt: TPoint = new TPoint(relativeCoordinates.x, relativeCoordinates.y)
        if (timezoneControlRc.PtInRect(pt)) {
            //menu for change timezone
        }
    }

    public OnMouseUp(event: MouseEvent, sender: TChart): void {
        this.isCaptured = false

        this.SetCursor(TCursor.crDefault)
        this.chartWin.Repaint()
    }

    //TODO: refactor this so we won't have to override it in TDateTimeBar
    public GetY(value: number): number {
        //TODO: maybe inherit TDateTimeBar from TBasicChart and have GetY only in TChart?
        throw new NotImplementedError('Method not implemented. GetY does not make sense in TDateTimeBar.')
    }
    //TODO: refactor this so we won't have to override it in TDateTimeBar
    public GetPriceFromY(y: number): number {
        //TODO: maybe inherit TDateTimeBar from TBasicChart and have GetY only in TChart?
        throw new NotImplementedError('Method not implemented. GetPriceFromY does not make sense in TDateTimeBar.')
    }
    //TODO: refactor this so we won't have to override it in TDateTimeBar
    public GetIndicatorUnderMouse(event: MouseEvent): any {
        throw new NotImplementedError(
            'Method not implemented. GetIndicatorUnderMouse does not make sense in TDateTimeBar.'
        )
    }

    setResizeCallback(callback: () => void): void {
        this.resizeDateTimeCallback = callback
    }

    resizeDateTimeBar(): void {
        if (this.resizeDateTimeCallback) {
            this.resizeDateTimeCallback()
        }
    }
}
