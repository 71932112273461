import { FC, ReactNode } from 'react'

import cx from 'classnames'

import { Icon } from '@fto/icons'
import { Button } from '@fto/ui'

import { useModalContext } from '..'

import styles from '../index.module.scss'

export type HeaderProps = {
    align?: 'left' | 'center' | 'right'
    className?: string
    children?: ReactNode
    withBorderBottom?: boolean
}

const Header: FC<HeaderProps> = ({ align = 'left', children, className, withBorderBottom }) => {
    const context = useModalContext()

    return (
        <div
            className={cx(styles.header, className, { [styles.BorderBottom]: withBorderBottom })}
            onMouseDown={context?.onDragStart}
            onMouseMove={context?.onDrag}
            onMouseUp={context?.onDragEnd}
        >
            <div
                className={cx(styles.title, styles[`align-${align}`], {
                    [styles.draggableCursor]: context?.isDraggable
                })}
            >
                {children}
            </div>
            {context?.withCloseIcon && (
                <div>
                    {context?.withCloseIcon && (
                        <Button
                            type='tetriatry-white'
                            size='compact'
                            disableFixedSize
                            label={
                                <Icon
                                    name='close'
                                    color={'var(--color-gray-1000)'}
                                    className={styles.icon}
                                    onClick={context?.onClose}
                                    size={16}
                                />
                            }
                        />
                    )}
                </div>
            )}
        </div>
    )
}

Header.displayName = 'Modal.Header'

export default Header
