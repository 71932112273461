import React, { FC, useState, useCallback } from 'react'
import ReactSlider from 'react-slider'
import { Typography } from '@fto/ui'

import styles from './timezone-range.module.scss'

type Props = {
    setTimezone: (value: number) => void
    value: number
}

const rangeValues = Array.from({ length: 13 }, (_, index) => index - 12)

const TimezoneRange: FC<Props> = ({ setTimezone, value = 0 }) => {
    const handleChange = useCallback(
        (tzValue: number) => {
            setTimezone(tzValue)
        },
        [setTimezone]
    )

    return (
        <div className={styles.rangeWrap}>
            <Typography type={'subtext-medium'}>
                UTC {value > 0 ? '+' : ''} {value}
            </Typography>
            <div className={styles.sliderWrap}>
                <ReactSlider
                    value={value}
                    max={rangeValues.length - 1}
                    min={rangeValues[0]}
                    onChange={handleChange}
                    className={styles.slider}
                    thumbClassName={styles.thumb}
                    trackClassName={styles.track}
                    renderThumb={(props) => <div {...props} />}
                />
                <div className={styles.rangeMarks}>
                    {rangeValues.map((index) => (
                        <span key={index}>|</span>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TimezoneRange
