import { TChart } from '../../chart_classes/BasicChart'
import { TBasicPaintTool } from '@fto/lib/charting/paint_tools/BasicPaintTool'
import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'
import { TPaintToolStatus, TPaintToolType, TPointInfo } from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { TPoint, TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { IGPFont, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { StylingHelper } from '@fto/lib/drawing_interface/StylingHelper'
import { TLineStyle, TMkFontStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { CustomCursorPointers } from '@fto/lib/ft_types/common/CursorPointers'
import { TOffsStringList, TVarList } from '@fto/lib/ft_types/common/OffsStringList'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { SignJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { FillColorParamsType } from '@fto/chart_components/ProjectInterface/components/GraphToolPanel/types'
import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import MouseTracker from '@fto/lib/utils/MouseTracker'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TPtSign extends TBasicPaintTool {
    private fSymbol: number

    public name: string

    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptSign)
        // ShortName := 'Sign'; // Assuming ShortName is a property of a parent class
        this.ShortName = PaintToolNames.ptSign
        this.name = PaintToolNames.ptSign
        // fToolType := tt_Text; // Assuming fToolType is a protected member of a parent class
        this.fToolType = TPaintToolType.tt_Text
        // fMaxPoints := 1; // Assuming fMaxPoints is a protected member of a parent class
        this.fMaxPoints = 1
        // fClosedPolygon := false; // Assuming fClosedPolygon is a protected member of a parent class
        this.fClosedPolygon = false
        // fFillInside := false; // Assuming fFillInside is a protected member of a parent class
        this.fShouldFillInside = false
        // CursorStyle := crCross; // Assuming CursorStyle is a property of a parent class
        this.CursorStyle = CustomCursorPointers.crCross
        // icon := 47; // Assuming icon is a property of a parent class
        this.icon = 47
        this.fSymbol = 241
        // fFontStyle.create('Wingdings', [], 32, clRed); // Assuming fFontStyle is a property of a parent class and create is a method
        this.fFontStyle = new TMkFontStyle('Wingdings', 0, 54, '#2F80ED')
        // fEditDialog := SignFrm; // Assuming fEditDialog is a property of a parent class
        // this.fEditDialog = SignFrm
        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            this.fFontStyle.size,
            StylingHelper.ConvertFontStyle(this.fFontStyle.style)
        )
        this.brush = new IGPSolidBrush(this.fFontStyle.color)
        this.fClosedPolygon = true
        this.hasLines = false

        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptSign)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptSign)
        }

        if (!styles) throw new StrangeError('Default styles for Sign are not found')

        this.fFontStyle.size = styles.size
        this.font.setFontSize(styles.size)
        this.brush = IGPSolidBrush.fromSerialized(styles.fill)
    }

    clone(): TPtSign {
        const cloneObj = new TPtSign(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)

        cloneObj.fSymbol = this.fSymbol
        cloneObj.name = this.name

        return cloneObj
    }

    toJson(): SignJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            symbol: this.fSymbol,
            name: this.name
        }
    }
    RecountScreenCoords() {
        if (this.fPoints.Count === 1 && this.fStatus === TPaintToolStatus.ts_Completed) {
            this.AdjustTopMarker()
        }
        super.RecountScreenCoords()
    }

    fromJSON(json: SignJSON) {
        super.fromJSON(json)

        this.fSymbol = json.symbol
        this.name = json.name
    }

    public OnComplete(): void {
        this.fMaxPoints = 2
        this.AddTopMarker()
    }

    public OnMovePoint(index: number, time: TDateTime, price: number): void {
        let sizeChange = 4

        if (MouseTracker.getInstance().getMouseDiffY() < 0) {
            this.fFontStyle.size += sizeChange
        } else {
            if (this.fFontStyle.size >= 15) {
                this.fFontStyle.size -= sizeChange
            }
        }
        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            this.fFontStyle.size,
            StylingHelper.ConvertFontStyle(this.fFontStyle.style)
        )
        this.AdjustTopMarker()
        this.invalidate()
    }

    public AddTopMarker(): void {
        const R1 = this.GetFrameRect()
        this.addPoint(this.fPoints[0].x, R1.Top)
    }

    private addPoint(x: TDateTime, y: number): void {
        const point = new TPointInfo(x, y)
        point.time = this.chart.GetBarDateFromX(x)
        point.price = this.chart.GetPriceFromY(y)
        this.fPoints.add(point)
    }

    public AdjustTopMarker(): void {
        const R1 = this.GetFrameRect()
        this.fPoints[1].x = this.fPoints[0].x
        this.fPoints[1].y = R1.Top
        this.fPoints[1].time = this.chart.GetBarDateFromX(this.fPoints[0].x)
        this.fPoints[1].price = this.chart.GetPriceFromY(R1.Top)
    }

    LoadFromList(list: TOffsStringList, all: boolean = true): void {
        super.LoadFromList(list, all)
        const vars = new TVarList()
        vars.LoadFromList(list)

        if (all) {
            this.fSymbol = vars.GetInt('Symbol')
        }
    }

    SaveToList(list: TOffsStringList, all: boolean = true): void {
        super.SaveToList(list, all)

        const vars = new TVarList()
        if (all) {
            vars.AddVarInt('Symbol', this.fSymbol)
        }

        vars.SaveToList(list, '')
        vars.Clear()
    }

    Paint(): void {
        if (this.fPoints.count === 0) return

        const canvas = this.chart.GdiCanvas
        const R = this.GetFrameRect()

        const s = String.fromCharCode(this.fSymbol)

        canvas.textOut(R.Left, R.Bottom, s, this.font, this.brush)

        if (this.fSelected) {
            this.paintFrame()
        }
        if (this.fStatus === TPaintToolStatus.ts_Completed) {
            this.AdjustTopMarker()
        }
        this.PaintMarkers()
    }
    paintFrame(): void {
        const R = this.GetFrameRect()
        this.chart.GdiCanvas.rectangle(R)
    }

    assign(tool: TPtSign, isCopy: boolean = false): void {
        super.assign(tool, isCopy)

        const ptSignTool = tool as TPtSign
        this.fSymbol = ptSignTool.fSymbol
        this.name = ptSignTool.name
        this.font = ptSignTool.font
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            size: {
                value: this.fFontStyle.size,
                label: 'toolsModal.fields.size',
                type: 'number',
                min: 10,
                max: 9999,
                step: 1,
                key: 'size'
            },
            fillInsideColor: {
                key: 'fillInsideColor',
                value: ColorHelperFunctions.BasicColor(this.brush.getColor()),
                label: 'toolsModal.fields.color',
                type: 'color',
                disabled: false,
                withOpacity: false
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, { toolType: this.ShortName, toolName: this.name })
    }

    ImportFromDialog(): void {
        const { getKeyValueData, resetToolSettings } = GraphToolStore

        const formattedToolSettings = getKeyValueData()

        this.chart.ChartWindow.saveStateWithNotify()

        this.description = formattedToolSettings.description
        this.fFontStyle.size = formattedToolSettings.size
        this.brush.setColor(formattedToolSettings.fillInsideColor)

        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            formattedToolSettings.size,
            StylingHelper.ConvertFontStyle(this.fFontStyle.style)
        )
        this.AdjustTopMarker()

        this.saveToManager()

        resetToolSettings()
    }
    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptSign, {
            size: this.fFontStyle.size,
            fill: this.brush.getSerialized(),
            toolName: PaintToolNames.ptSign
        })
    }

    getFillColorParams(): FillColorParamsType {
        return {
            color: {
                value: ColorHelperFunctions.BasicColor(this.brush.getColor()),
                hasDifferentValues: false
            },
            opacity: null,
            shouldFillInside: true
        }
    }

    setFillColorParams(color: string, opacity: number) {
        this.chart.ChartWindow.saveStateWithNotify()

        this.brush.setColor(color)
        this.brush.setOpacity(opacity)
        this.saveToManager()
        this.updatedToolAndLinkedTools()
    }

    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    public EdgeUnderMouse(x: number, y: number): number {
        // Check if the point (x, y) is within the frame rectangle
        if (this.GetFrameRect().PtInRect(new TPoint(x, y))) {
            return 0
        } else {
            return -1
        }
    }

    public GetFrameRect(): TRect {
        const s: string = String.fromCharCode(this.fSymbol)
        const x = this.fPoints[0].x
        const y = this.fPoints[0].y
        const w = this.fChart.GdiCanvas.TextWidth(s, this.font)
        const h = this.fChart.GdiCanvas.TextHeight(s, this.font)

        return new TRect(x - w / 2, y - h, x - w / 2 + w, y)
    }

    public get symbol(): number {
        return this.fSymbol
    }

    public set symbol(value: number) {
        this.fSymbol = value
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptSign, TPtSign)
