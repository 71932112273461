import dayjs from 'dayjs'
import { COLOR_NAMES } from '@fto/ui'
import { TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'

export const getValuesParams = (
    type: string,
    value: any
): {
    color: (typeof COLOR_NAMES)[number]
    value: any
} => {
    if (type === 'profit') {
        const isLowerThatZero = value < 0

        const formattedValue = `${isLowerThatZero ? '-' : '+'}${Math.abs(value)}`

        return {
            value: formattedValue,
            color: !isLowerThatZero ? 'green-600' : 'red-600'
        }
    }

    if (type === 'points') {
        const isLowerThatZero = value < 0

        const formattedValue = `${isLowerThatZero ? '-' : '+'}${Math.abs(value)}`

        return {
            value: formattedValue,
            color: 'gray-1000'
        }
    }

    if (['closeTime', 'openTime'].includes(type)) {
        const formattedDate = dayjs.utc(value).format('ddd DD MMM ’YY HH:mm')

        return {
            value: formattedDate,
            color: 'gray-1000'
        }
    }

    return {
        value,
        color: 'gray-1000'
    }
}

export const getOrderTypeLabel = (type: TTradePositionType): string => {
    if (type === TTradePositionType.tp_Buy) {
        return 'terminal.orderType.buy'
    }

    if (type === TTradePositionType.tp_Sell) {
        return 'terminal.orderType.sell'
    }

    if (type === TTradePositionType.tp_BuyLimit) {
        return 'terminal.orderType.buyLimit'
    }

    if (type === TTradePositionType.tp_SellLimit) {
        return 'terminal.orderType.sellLimit'
    }

    if (type === TTradePositionType.tp_BuyStop) {
        return 'terminal.orderType.buyStop'
    }

    if (type === TTradePositionType.tp_SellStop) {
        return 'terminal.orderType.sellStop'
    }

    if (type === TTradePositionType.tp_Deposit) {
        return 'terminal.orderType.deposit'
    }

    if (type === TTradePositionType.tp_Withdrawal) {
        return 'terminal.orderType.withdrawal'
    }

    return ''
}
