import StrangeSituationNotifier from '../common/StrangeSituationNotifier'
import TStringList from '../delphi_compatibility/StringList'
import { StrsConv } from '../ft_types/common/StrsConv'
import { TimeframeUtils } from './common/TimeframeUtils'

export class TTimeframes extends TStringList {
    constructor() {
        super()

        //do NOT do something like this here in constructor: this.LoadFromStr('1,5,15,30,60,240,1440,10080,43200'); // the array is not properly initialized yet
    }

    public tframes_GetTimeframeByIndex(index: number): number {
        return this.GetTimeframeByIndex(index)
    }

    LoadFromStr(s: string): void {
        this.Clear()

        try {
            while (s !== '') {
                let i: number
                ;[i, s] = StrsConv.GetStrInt(s, ',') // Assuming GetStrInt returns a tuple [number, string]
                if (i <= 0) {
                    continue
                }

                this.AddTimeframe(i)
                s = s.trim() // Trim the string to remove any leading whitespace after the comma
                if (s === '') {
                    break // If the string is empty after trimming, exit the loop
                }
            }
        } catch (error) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
            //TODO: Implement specific exception handling if necessary. It was empty in the original code
        }

        if (this.Count === 0) {
            this.AddTimeframe(1)
        }
    }

    AddTimeframe(min: number): void {
        let index: number = this.Count
        for (let i = 0; i < this.Count; i++) {
            if (this.GetTimeframeByIndex(i) > min) {
                index = i
                break
            }
        }

        this.InsertObject(index, TimeframeUtils.GetLongTimeframeName(min), min)
    }

    GetTimeframeByIndex(index: number): number {
        if (index < 0 || index >= this.Count) {
            throw new RangeError(`GetTimeframeByIndex - Index out of bounds ${index}`)
        }

        return this.GetObject(index) as number
    }
}
