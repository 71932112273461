import ProcRec from './IIndicatorApi'

export abstract class UserIndicator {
    protected api!: ProcRec

    OnAttach(api: ProcRec) {
        this.api = api
    }

    abstract Init(): void
    abstract Calculate(index: number): void

    public OnParamsChange(): void {
        // Do nothing
    }

    public Done(): void {
        // Do nothing
    }

    public OnHide(): void {
        // Do nothing
    }

    public OnShow(): void {
        // Do nothing
    }
}
