import { TEducationTask } from '@fto/lib/Education/Types'
import EducationStore from '@fto/lib/store/educationStore'

import { TutorialTask } from '@fto/lib/Education/TutorialTasks/TutorialTask'
import { TutorialTasksProcessor } from '@fto/lib/Education/TutorialTasks/TutorialTasksProcessor'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import GlobalProjectInfo from '../globals/GlobalProjectInfo'
import GlobalChartsController from '../globals/GlobalChartsController'
import { TBasicPaintTool } from '../charting/paint_tools/BasicPaintTool'
import { TRuntimeIndicator } from '../extension_modules/indicators/DllIndicatorUnit'

export class EducationProcessor {
    private static instance: EducationProcessor
    public taskEntity: TEducationTask | null = null
    tutorialTasks: TutorialTask[] = []
    public tutorialTasksProcessor: TutorialTasksProcessor | null = null
    public staticTools: TBasicPaintTool[] = []
    public staticIndicators: TRuntimeIndicator[] = []

    public static get Instance(): EducationProcessor {
        if (!EducationProcessor.instance) {
            EducationProcessor.instance = new EducationProcessor()
        }
        return EducationProcessor.instance
    }

    public parseTask(task: TEducationTask): void {
        const { updateData } = EducationStore
        this.Reset()
        task.checks.forEach((check) => {
            const newCheck = new TutorialTask(
                check.name,
                check.criteria,
                check.trueResult,
                check.falseResult,
                check.description
            )
            this.tutorialTasks.push(newCheck)
        })
        this.taskEntity = task

        GlobalChartsController.Instance.getAllCharts().forEach((chart) => {
            this.staticTools.push(...chart.MainChart.PaintTools)
            this.staticIndicators.push(...chart.MainChart.indicators)
            this.staticIndicators.push(...chart.OscWins.map((osc) => osc.chart.indicators[0]))
        })

        GlobalProjectInfo.ProjectInfo.ForwardTestingOnly = true
        this.tutorialTasksProcessor = new TutorialTasksProcessor(this.tutorialTasks)
        updateData({ lesson: task, isShown: true })
    }

    public logAllChecks(): void {
        for (const checkName in this.tutorialTasks) {
        }
    }

    public getAllChecks(): TutorialTask[] {
        return this.tutorialTasks
    }

    public processChecks(): void {
        const { updateData } = EducationStore

        if (this.tutorialTasksProcessor) {
            this.tutorialTasksProcessor.process()
            updateData({
                lesson: { ...this.taskEntity!, checks: this.tutorialTasksProcessor.getJSONArray() },
                isShown: true
            })
        }
    }

    public startHomework(task: TEducationTask): void {
        this.parseTask(task)
        addModal(MODAL_NAMES.messages.educationMessage, { message: task.intro })
    }

    public restartHomework(): void {
        const { updateData } = EducationStore
        GlobalChartsController.Instance.RestartProject()
        this.tutorialTasks.map((task) => task.reset())
        this.tutorialTasksProcessor = new TutorialTasksProcessor(this.tutorialTasks)
        updateData({ lesson: this.taskEntity!, isShown: true })
    }

    public Reset(): void {
        const { resetStore } = EducationStore
        this.tutorialTasks = []
        this.taskEntity = null
        this.tutorialTasksProcessor = null
        resetStore()
    }
}
