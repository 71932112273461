import { RiskRewardJSON } from '@fto/lib/ProjectAdapter/Types'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { TChart } from '../../chart_classes/BasicChart'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TBasicPaintTool } from '@fto/lib/charting/paint_tools/BasicPaintTool'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'
import {
    TPaintToolStatus,
    TPaintToolType,
    TPointsArray
} from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { DelphiColors, TPenStyle, TPoint, TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { IGPFont, IGPPen, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { TGdiPlusCanvas, TRoundRectType } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { StylingHelper } from '@fto/lib/drawing_interface/StylingHelper'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { TChartType, TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import { CustomCursorPointers, TCursor } from '@fto/lib/ft_types/common/CursorPointers'
import { TOffsStringList } from '@fto/lib/ft_types/common/OffsStringList'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import { TTradePos } from '@fto/lib/processing_core/TradePositionClasses/TradePosition'
import { NotImplementedError } from '@fto/lib/utils/common_utils'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import {
    FillColorParamsType,
    FontStylesType
} from '@fto/chart_components/ProjectInterface/components/GraphToolPanel/types'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { t } from 'i18next'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'

export enum TRiskToolType {
    rt_Buy,
    rt_Sell
}

export enum TCalcFrom {
    cf_Balance,
    cf_Equity,
    cf_Custom
}

export enum TRiskType {
    rt_Percentage,
    rt_Amount
}

export class TPtRiskReward extends TBasicPaintTool {
    protected fRiskToolType: TRiskToolType
    protected fCalcFrom: TCalcFrom
    protected fRiskType: TRiskType
    protected fRiskValue: number
    protected fCustomSize: number
    protected fPrice: number
    protected fStopLoss: number
    protected fTakeProfit: number
    protected fSLpoints: number
    protected fTPpoints: number
    protected fLot: number
    protected fProfit: number
    protected fLoss: number
    protected fTPLineStyle: TLineStyle
    protected fTPBrush: IGPSolidBrush
    protected fSLLineStyle: TLineStyle
    protected fSLBrush: IGPSolidBrush
    protected fAlwaysShowInfo: boolean
    protected PosType: TTradePositionType
    protected fontBrush: IGPSolidBrush
    protected backgroundBrushTP: IGPSolidBrush
    protected backgroundBrushSL: IGPSolidBrush
    protected rectPadding = 5
    protected penForMiddleInfoBox: IGPPen
    protected PnL: number
    protected penForMiddleLine = new IGPPen(ColorHelperFunctions.MakeColor('#2F80ED', 125), 1)
    protected looseBrush = new IGPSolidBrush(ColorHelperFunctions.MakeColor('#FF6462'), 1)
    protected profitBrush = new IGPSolidBrush(ColorHelperFunctions.MakeColor('#01B291'), 1)

    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptRiskReward)
        this.ShortName = 'Risk/Reward'
        this.fToolType = TPaintToolType.tt_Polygon
        this.fMaxPoints = 1
        this.fClosedPolygon = false
        this.CursorStyle = CustomCursorPointers.crCursorRectangle
        this.icon = 100
        this.fRiskToolType = TRiskToolType.rt_Buy
        this.fCalcFrom = TCalcFrom.cf_Balance
        this.fRiskType = TRiskType.rt_Percentage
        this.fRiskValue = 2
        this.fCustomSize = 1000
        this.fAlwaysShowInfo = false
        this.fFontStyle.color = DelphiColors.clWhite
        this.fFontStyle.size = 12
        this.fTPLineStyle = new TLineStyle(ColorHelperFunctions.MakeColor('#01B291', 76), TPenStyle.psSolid, 1)
        this.fSLLineStyle = new TLineStyle(ColorHelperFunctions.MakeColor('#FF4846', 76), TPenStyle.psSolid, 1)
        this.fTPBrush = new IGPSolidBrush(ColorHelperFunctions.MakeColor('#99FF99', 76), 0.3)
        this.fSLBrush = new IGPSolidBrush(ColorHelperFunctions.MakeColor('#FF8B89', 76), 0.3)
        this.PosType = TTradePositionType.tp_Sell
        this.fPrice = 0
        this.fStopLoss = 0
        this.fTakeProfit = 0
        this.fSLpoints = 0
        this.fTPpoints = 0
        this.fLot = 0
        this.fProfit = 0
        this.fLoss = 0
        this.PnL = 0
        this.fontBrush = new IGPSolidBrush(DelphiColors.clWhite, 1, 1)
        this.backgroundBrushTP = new IGPSolidBrush('#01B291')
        this.backgroundBrushSL = new IGPSolidBrush('#FF6462')
        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            this.fFontStyle.size,
            StylingHelper.ConvertFontStyle(this.fFontStyle.style)
        )
        this.penForMiddleInfoBox = new IGPPen(DelphiColors.clWhite, 3)
        this.fClosedPolygon = true
        this.hasText = true

        // fEditDialog := RiskRewardFrm2;
        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptRiskReward)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptRiskReward)
        }

        if (!styles) throw new StrangeError('Default styles for RiskReward are not found')

        this.fTPLineStyle = TLineStyle.fromSerialized(styles.profit.line)
        this.fSLLineStyle = TLineStyle.fromSerialized(styles.loss.line)
        this.fTPBrush = IGPSolidBrush.fromSerialized(styles.profit.fill)
        this.fSLBrush = IGPSolidBrush.fromSerialized(styles.loss.fill)
        this.fAlwaysShowInfo = styles.showText
        this.fCalcFrom = styles.calculateFrom
        this.fRiskValue = styles.risk

        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            styles.text.size,
            StylingHelper.getTGpFontStyle({
                style: styles.text.style,
                weight: styles.text.weight
            })
        )
        this.fontBrush.setColor(styles.text.color)
    }

    clone(): TBasicPaintTool {
        const cloneObj = new TPtRiskReward(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)

        cloneObj.fRiskToolType = this.fRiskToolType
        cloneObj.fCalcFrom = this.fCalcFrom
        cloneObj.fRiskType = this.fRiskType
        cloneObj.fRiskValue = this.fRiskValue
        cloneObj.fCustomSize = this.fCustomSize
        cloneObj.fPrice = this.fPrice
        cloneObj.fStopLoss = this.fStopLoss
        cloneObj.fTakeProfit = this.fTakeProfit
        cloneObj.fSLpoints = this.fSLpoints
        cloneObj.fTPpoints = this.fTPpoints
        cloneObj.fLot = this.fLot
        cloneObj.fProfit = this.fProfit
        cloneObj.fLoss = this.fLoss
        cloneObj.fTPLineStyle = this.fTPLineStyle
        cloneObj.fTPBrush = this.fTPBrush
        cloneObj.fSLBrush = this.fSLBrush
        cloneObj.fAlwaysShowInfo = this.fAlwaysShowInfo
        cloneObj.PosType = this.PosType
        cloneObj.fontBrush = this.fontBrush
        cloneObj.backgroundBrushTP = this.backgroundBrushTP
        cloneObj.backgroundBrushSL = this.backgroundBrushSL
        cloneObj.rectPadding = this.rectPadding
        cloneObj.penForMiddleInfoBox = this.penForMiddleInfoBox
        cloneObj.PnL = this.PnL

        return cloneObj
    }

    public toJson(): RiskRewardJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            RiskToolType: this.fRiskToolType,
            CalcFrom: this.fCalcFrom,
            RiskType: this.fRiskType,
            RiskValue: this.fRiskValue,
            CustomSize: this.fCustomSize,
            Price: this.fPrice,
            StopLoss: this.fStopLoss,
            TakeProfit: this.fTakeProfit,
            SLpoints: this.fSLpoints,
            TPpoints: this.fTPpoints,
            Lot: this.fLot,
            Profit: this.fProfit,
            Loss: this.fLoss,
            TPLineStyle: {
                color: this.fTPLineStyle.color,
                style: this.fTPLineStyle.style,
                width: this.fTPLineStyle.width
            },
            TPBrush: {
                color: this.fTPBrush.getColor(),
                opacity: this.fTPBrush.getOpacity(),
                style: this.fTPBrush.getStyle()
            },
            SLBrush: {
                color: this.fSLBrush.getColor(),
                opacity: this.fSLBrush.getOpacity(),
                style: this.fSLBrush.getStyle()
            },
            AlwaysShowInfo: this.fAlwaysShowInfo,
            PosType: this.PosType,
            fontBrush: {
                color: this.fontBrush.getColor(),
                opacity: this.fontBrush.getOpacity(),
                style: this.fontBrush.getStyle()
            },
            backgroundBrushTP: {
                color: this.backgroundBrushTP.getColor(),
                opacity: this.backgroundBrushTP.getOpacity(),
                style: this.backgroundBrushTP.getStyle()
            },
            backgroundBrushSL: {
                color: this.backgroundBrushSL.getColor(),
                opacity: this.backgroundBrushSL.getOpacity(),
                style: this.backgroundBrushSL.getStyle()
            },
            rectPadding: this.rectPadding,
            penForMiddleInfoBox: {
                color: this.penForMiddleInfoBox.color,
                width: this.penForMiddleInfoBox.width,
                style: this.penForMiddleInfoBox.dashStyle,
                opacity: this.penForMiddleInfoBox.opacity
            },
            PnL: this.PnL,
            font: this.font.toString()
        }
    }

    fromJSON(json: RiskRewardJSON) {
        super.fromJSON(json)

        this.fRiskToolType = json.RiskToolType
        this.fCalcFrom = json.CalcFrom
        this.fRiskType = json.RiskType
        this.fRiskValue = json.RiskValue
        this.fCustomSize = json.CustomSize
        this.fPrice = json.Price
        this.fStopLoss = json.StopLoss
        this.fTakeProfit = json.TakeProfit
        this.fSLpoints = json.SLpoints
        this.fTPpoints = json.TPpoints
        this.fLot = json.Lot
        this.fProfit = json.Profit
        this.fLoss = json.Loss
        this.fTPLineStyle = new TLineStyle(json.TPLineStyle.color, json.TPLineStyle.style, json.TPLineStyle.width)
        this.fTPBrush = new IGPSolidBrush(json.TPBrush.color, json.TPBrush.opacity, json.TPBrush.style)
        this.fSLBrush = new IGPSolidBrush(json.SLBrush.color, json.SLBrush.opacity, json.SLBrush.style)
        this.fAlwaysShowInfo = json.AlwaysShowInfo
        this.PosType = json.PosType
        this.fontBrush = new IGPSolidBrush(json.fontBrush.color, json.fontBrush.opacity, json.fontBrush.style)
        this.backgroundBrushTP = new IGPSolidBrush(
            json.backgroundBrushTP.color,
            json.backgroundBrushTP.opacity,
            json.backgroundBrushTP.style
        )
        this.backgroundBrushSL = new IGPSolidBrush(
            json.backgroundBrushSL.color,
            json.backgroundBrushSL.opacity,
            json.backgroundBrushSL.style
        )
        this.rectPadding = json.rectPadding
        this.penForMiddleInfoBox = new IGPPen(json.penForMiddleInfoBox.color, json.penForMiddleInfoBox.width)
        this.penForMiddleInfoBox.opacity = json.penForMiddleInfoBox.opacity
        this.penForMiddleInfoBox.setDashStyle(json.penForMiddleInfoBox.style)
        this.PnL = json.PnL
        this.font = IGPFont.fromString(json.font)
    }

    FillInside(): void {
        if (this.fPoints.length === 0) return

        const points = this.GetPoints() // Assuming GetPoints method is implemented and returns an array of points
        const gdiCanvas = this.chart.GdiCanvas // Assuming chart has a GdiCanvas property of type TGdiPlusCanvas

        if (this.fRiskToolType === TRiskToolType.rt_Buy) {
            const SLRect = new TRect(points[4].x, points[4].y, points[2].x, points[2].y)
            const TPRect = new TRect(points[0].x, points[0].y, points[5].x, points[5].y)

            gdiCanvas.FillRect(SLRect, this.fSLBrush)

            gdiCanvas.FillRect(TPRect, this.fTPBrush)
        } else {
            const SLRect = new TRect(points[0].x, points[0].y, points[5].x, points[5].y)
            const TPRect = new TRect(points[4].x, points[4].y, points[2].x, points[2].y)

            gdiCanvas.FillRect(TPRect, this.fTPBrush)

            gdiCanvas.FillRect(SLRect, this.fSLBrush)
        }
    }

    GetPoints(): TPointsArray {
        let x, y, x1, y1, x2, y2, index: number
        let dp: number
        const result: TPointsArray = []
        result.length = 6
        if (this.fPoints.length === 1) {
            // tool is going to be placed and has only 1 point
            x = this.fPoints[0].x
            y = this.fPoints[0].y
            dp = this.chart.GetPriceFromY(0) - this.chart.GetPriceFromY(70)

            index = this.chart.GetIndexFromX(x)
            x1 = this.chart.GetX(index - 8)
            x2 = this.chart.GetX(index + 8)
            y1 = this.chart.GetY(this.fPoints[0].price + dp)
            y2 = this.chart.GetY(this.fPoints[0].price - dp)

            result[0] = new TPoint(x1, y1)
            result[1] = new TPoint(x2, y1)
            result[2] = new TPoint(x2, y2)
            result[3] = new TPoint(x1, y2)
            result[4] = new TPoint(x1, y)
            result[5] = new TPoint(x2, y)
        } else {
            for (let i = 0; i < this.fPoints.length; i++) {
                result[i] = new TPoint(this.fPoints[i].x, this.fPoints[i].y)
            }
        }
        return result
    }

    PointCanMove(index: number, time: TDateTime, price: number): boolean {
        switch (index) {
            case 0: {
                return time < this.fPoints[1].time && price > this.fPoints[4].price
            }
            case 1: {
                return time > this.fPoints[0].time && price > this.fPoints[4].price
            }
            case 2: {
                return time > this.fPoints[0].time && price < this.fPoints[4].price
            }
            case 3: {
                return time < this.fPoints[1].time && price < this.fPoints[4].price
            }
            case 4: {
                return time < this.fPoints[1].time && price < this.fPoints[0].price && price > this.fPoints[2].price
            }
            case 5: {
                return time > this.fPoints[0].time && price < this.fPoints[0].price && price > this.fPoints[2].price
            }
            default: {
                return true
            }
        }
    }

    EdgeUnderMouse(x: number, y: number): number {
        let result = super.EdgeUnderMouse(x, y) // Calls the inherited method
        if (
            result === -1 && // Check if the point is above the line formed by the first and fourth points
            this.PointAboveTheLine(x, y, this.fPoints[0].x, this.fPoints[0].y, this.fPoints[3].x, this.fPoints[3].y)
        ) {
            result = 3 // Assigns the edge index if the condition is true
        }
        return result
    }

    get MinPoint(): number {
        let result = 0.0001

        if (!(this.chart instanceof TMainChart) || this.fPoints.Count < 6) {
            return result
        }

        const symbol = (this.chart as TMainChart).SymbolData

        if (symbol === null) {
            return result
        }

        result = symbol.symbolInfo.MinPoint

        return result
    }

    LoadFromList(list: TOffsStringList, all = true): void {
        throw new NotImplementedError()
    }

    SaveToList(list: TOffsStringList, all = true): void {
        throw new NotImplementedError()
    }

    getLineStyleParams(): {
        color: {
            value: TLineStyle['color']
            hasDifferentValues: boolean
        }
        style: {
            value: TLineStyle['style']
            hasDifferentValues: boolean
        }
        width: {
            value: TLineStyle['width']
            hasDifferentValues: boolean
        }
    } {
        const colorsAreEqual = this.fTPLineStyle.color === this.fSLLineStyle.color
        const stylesAreEqual = this.fTPLineStyle.style === this.fSLLineStyle.style
        const widthsAreEqual = this.fTPLineStyle.width === this.fSLLineStyle.width

        return {
            color: {
                value: colorsAreEqual ? this.fTPLineStyle.color : '',
                hasDifferentValues: !colorsAreEqual
            },
            style: {
                value: stylesAreEqual ? this.fTPLineStyle.style : 0,
                hasDifferentValues: !stylesAreEqual
            },
            width: {
                value: widthsAreEqual ? this.fTPLineStyle.width : 0,
                hasDifferentValues: !widthsAreEqual
            }
        }
    }

    setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'width' | 'style'
    }) {
        this.chart.ChartWindow.saveStateWithNotify()

        const { color, style, width, byKey } = styles
        const isColorChange = byKey === 'color'

        const updatedProfitLineStyles = new TLineStyle(isColorChange ? color : this.fTPLineStyle.color, style, width)
        const updatedLossLineStyles = new TLineStyle(isColorChange ? color : this.fSLLineStyle.color, style, width)

        this.fTPLineStyle = updatedProfitLineStyles.clone()
        this.fSLLineStyle = updatedLossLineStyles.clone()

        this.saveToManager()
        this.updatedToolAndLinkedTools()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const fontParams = this.font.getFontParams()

        const fontStyleParams = StylingHelper.getFontStyleParams(fontParams.fontStyles)

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            profitLineStyle: {
                key: 'profitLineStyle',
                value: this.fTPLineStyle,
                label: 'toolsModal.fields.rrProfitLine',
                type: 'style',
                disabled: false
            },
            profitFillColor: {
                key: 'profitFillColor',
                value: {
                    color: this.fTPBrush.getColor(),
                    opacity: this.fTPBrush.getOpacity()
                },
                withOpacity: true,
                isOptional: false,
                label: 'riskReward.profitFillColor',
                type: 'fillColor',
                disabled: false
            },
            lossLineStyle: {
                key: 'lossLineStyle',
                value: this.fSLLineStyle,
                label: 'toolsModal.fields.rrLossLine',
                type: 'style',
                disabled: false
            },
            lossFillColor: {
                key: 'lossFillColor',
                value: {
                    color: this.fSLBrush.getColor(),
                    opacity: this.fSLBrush.getOpacity()
                },
                withOpacity: true,
                isOptional: false,
                label: 'riskReward.lossFillColor',
                type: 'fillColor',
                disabled: false
            },
            textStyle: {
                key: 'textStyle',
                type: 'textStyle',
                label: 'toolsModal.fields.textStyle',
                value: {
                    color: this.fontBrush.getColor(),
                    size: fontParams.size,
                    style: fontStyleParams.style,
                    weight: fontStyleParams.weight
                }
            },
            riskPercent: {
                key: 'riskPercent',
                value: this.fRiskValue,
                label: 'toolsModal.fields.riskPercent',
                type: 'number',
                min: 0,
                max: 100,
                step: 0.1
            },
            calculateFrom: {
                key: 'calculateFrom',
                value: this.fCalcFrom,
                label: 'toolsModal.fields.calculateLotFrom',
                type: 'calculateLot'
            },
            alwaysShowTextLabel: {
                key: 'alwaysShowTextLabel',
                value: this.fAlwaysShowInfo,
                type: 'checkbox',
                label: 'toolsModal.fields.alwaysShowText'
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptRiskReward,
            toolName: this.fRiskToolType === TRiskToolType.rt_Buy ? 'rrBuy' : 'rrSell'
        })
    }

    ImportFromDialog(): void {
        const { resetToolSettings, getKeyValueData } = GraphToolStore // Use the store/context

        const formattedToolSettings = getKeyValueData()

        //@ts-ignore
        const {
            description,
            profitLineStyle,
            lossLineStyle,
            profitFillColor,
            lossFillColor,
            riskPercent,
            calculateFrom,
            alwaysShowTextLabel
        } = formattedToolSettings

        this.chart.ChartWindow.saveStateWithNotify()

        this.description = description

        this.fTPLineStyle = profitLineStyle.clone()
        this.fSLLineStyle = lossLineStyle.clone()
        this.fTPBrush.setColor(profitFillColor.color)
        this.fSLBrush.setColor(lossFillColor.color)
        this.fTPBrush.setOpacity(profitFillColor.opacity)
        this.fSLBrush.setOpacity(lossFillColor.opacity)

        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            formattedToolSettings.textStyle.size,
            StylingHelper.getTGpFontStyle({
                style: formattedToolSettings.textStyle.style,
                weight: formattedToolSettings.textStyle.weight
            })
        )

        this.fFontStyle.color = formattedToolSettings.textStyle.color
        this.fFontStyle.size = formattedToolSettings.textStyle.size

        this.fontBrush.setColor(formattedToolSettings.textStyle.color)

        this.fRiskValue = riskPercent
        this.fCalcFrom = calculateFrom
        this.fAlwaysShowInfo = alwaysShowTextLabel

        this.saveToManager()
        resetToolSettings()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptRiskReward, {
            toolName: PaintToolNames.ptRiskReward,
            text: {
                color: this.fFontStyle.color,
                size: this.fFontStyle.size,
                style: StylingHelper.getFontStyleParams(this.font.getFontParams().fontStyles).style,
                weight: StylingHelper.getFontStyleParams(this.font.getFontParams().fontStyles).weight
            },
            profit: { line: this.fTPLineStyle.getSerialized(), fill: this.fTPBrush.getSerialized() },
            loss: { line: this.fSLLineStyle.getSerialized(), fill: this.fSLBrush.getSerialized() },
            showText: this.fAlwaysShowInfo,
            calculateFrom: this.fCalcFrom,
            risk: this.fRiskValue
        })
    }

    getProfitFillColorParams(): FillColorParamsType {
        return {
            color: {
                value: this.fTPBrush.getColor(),
                hasDifferentValues: false
            },
            opacity: this.fTPBrush.getOpacity(),
            shouldFillInside: true
        }
    }

    setProfitFillColorParams(color: string, opacity: number) {
        this.fTPBrush.setColor(color)
        this.fTPBrush.setOpacity(opacity)
        GlobalChartsController.Instance.updateCharts()
    }

    getLossFillColorParams(): FillColorParamsType {
        return {
            color: {
                value: this.fSLBrush.getColor(),
                hasDifferentValues: false
            },
            opacity: this.fSLBrush.getOpacity(),
            shouldFillInside: true
        }
    }

    setLossFillColorParams(color: string, opacity: number) {
        this.fSLBrush.setColor(color)
        this.fSLBrush.setOpacity(opacity)
        GlobalChartsController.Instance.updateCharts()
    }

    getFontStyles(): FontStylesType {
        return {
            color: {
                value: this.fontBrush.getColor(),
                hasDifferentValues: false
            },
            fontSize: this.font.getFontParams().size
        }
    }

    override setFontStyles(color: string, fontSize: number) {
        // NOTE: method defined as default, will not call from THIS class such as hasText flag is always false.
        // Define hasText in selected tool to start use override method
        const fontParams = this.font.getFontParams()

        const fontStyleParams = StylingHelper.getFontStyleParams(fontParams.fontStyles)

        this.font = new IGPFont(
            new TGPFontFamily(this.fFontStyle.name),
            fontSize,
            StylingHelper.getTGpFontStyle({
                style: fontStyleParams.style,
                weight: fontStyleParams.weight
            })
        )

        this.fFontStyle.color = color
        this.fFontStyle.size = fontSize
        this.fontBrush.setColor(color)
        this.saveToManager()
        this.updatedToolAndLinkedTools()
    }

    Paint(): void {
        // Depending on the risk tool type, it calls either PaintBuyPos or PaintSellPos method

        if (this.fStatus !== TPaintToolStatus.ts_Completed) {
            const chartWindow = this.chart.ChartWindow as TChartWindow

            chartWindow.SetCursor(TCursor.crCross)
        }

        if (this.fRiskToolType === TRiskToolType.rt_Buy) {
            this.PaintBuyPos()
        } else {
            this.PaintSellPos()
        }
    }

    private PaintPos(isBuy: boolean): void {
        if (this.fPoints.length === 0) return

        const points = this.GetPoints()
        const gdiCanvas = this.chart.GdiCanvas

        gdiCanvas.rectangle(new TRect(points[4].x, points[4].y, points[2].x, points[2].y), this.fTPLineStyle.getPen())
        gdiCanvas.rectangle(new TRect(points[0].x, points[0].y, points[5].x, points[5].y), this.fSLLineStyle.getPen())

        gdiCanvas.MoveTo(points[4].x, points[4].y)
        gdiCanvas.LineTo(points[5].x, points[5].y, this.penForMiddleLine)

        if (!(this.chart instanceof TMainChart)) return

        const symbol = (this.chart as TMainChart).SymbolData

        if (this.fAlwaysShowInfo || (this.MarkersVisible() && this.fPoints.length === 6)) {
            this.Calculate()

            const canvas = this.chart.GdiCanvas
            const textHeight = canvas.TextHeight('0', this.font)
            const textWidth = points[1].x - points[0].x

            this.drawTextAndRect(canvas, points, textHeight, textWidth, isBuy, symbol)

            const ratio = this.calculateRatio()

            const displayText = `  P&L: ${this.PnL.toFixed(5)} / Lot: ${this.fLot.toFixed(
                GlobalProcessingCore.ProcessingCore.LotDigits
            )} / ${symbol.FormatPriceToStr(this.fPrice)}  `
            const RR_ratio_str = t('charting.graphTools.ptRiskReward.ratio', { ratio: ratio.toFixed(2) })

            this.drawMiddleText(canvas, points, textHeight, textWidth, displayText, RR_ratio_str)

            const finalDisplayText = isBuy
                ? `  SL: $${this.fLoss.toFixed(2)} / ${this.fSLpoints} points / ${symbol.FormatPriceToStr(
                      this.fStopLoss
                  )}  `
                : `  TP: $${this.fProfit.toFixed(2)} / ${this.fTPpoints} points / ${symbol.FormatPriceToStr(
                      this.fTakeProfit
                  )}    `

            this.drawBottomText(canvas, points, textHeight, textWidth, finalDisplayText, isBuy)
        }

        const cornerPoints = this.GetCornerPointsIndexes()
        this.PaintMarkers(true, cornerPoints)
    }

    private drawTextAndRect(
        canvas: TGdiPlusCanvas,
        points: TPointsArray,
        textHeight: number,
        textWidth: number,
        isBuy: boolean,
        symbol: TSymbolData
    ): void {
        const displayText = isBuy
            ? `  TP: $${this.fProfit.toFixed(2)} / ${this.fTPpoints} points / ${symbol.FormatPriceToStr(
                  this.fTakeProfit
              )}  `
            : `  SL: $${this.fLoss.toFixed(2)} / ${this.fSLpoints} points / ${symbol.FormatPriceToStr(
                  this.fStopLoss
              )}  `

        const textWidthWithSpacing = canvas.TextWidth(displayText, this.font)
        const textPosX = points[0].x + (textWidth - textWidthWithSpacing) / 2
        let textPosY = points[0].y
        let rectTypeTop = TRoundRectType.TOP

        const marginThreshold = 10
        if (Math.abs(textPosX - points[0].x) < marginThreshold || points[0].x > textPosX) {
            textPosY -= textHeight
            rectTypeTop = TRoundRectType.BOTH
        }

        canvas.strokeRect(
            new TRect(
                textPosX - this.rectPadding,
                textPosY - textHeight - this.rectPadding - 5,
                textPosX + textWidthWithSpacing + this.rectPadding,
                textPosY
            ),
            rectTypeTop,
            5,
            true,
            isBuy ? this.fTPLineStyle.getPen() : this.fSLLineStyle.getPen(),
            isBuy ? this.backgroundBrushTP : this.backgroundBrushSL
        )

        canvas.textOut(textPosX, textPosY - this.rectPadding, displayText, this.font, this.fontBrush)
    }

    private calculateRatio(): number {
        let ratio = 1
        try {
            ratio = this.fTPpoints / this.fSLpoints
        } catch (error) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
            ratio = 1 // In case of division by zero
        }
        return ratio
    }

    private drawMiddleText(
        canvas: TGdiPlusCanvas,
        points: TPointsArray,
        textHeight: number,
        textWidth: number,
        displayText: string,
        RR_ratio_str: string
    ): void {
        const displayTextWidth = canvas.TextWidth(displayText, this.font)
        const paddedRR_ratio_str = this.getPadded_RR_ratio_str(canvas, RR_ratio_str, displayTextWidth)

        const textWidthWithSpacing = Math.max(displayTextWidth, canvas.TextWidth(paddedRR_ratio_str, this.font))
        const textPosX = points[0].x + (textWidth - textWidthWithSpacing) / 2
        let textPosY = points[4].y + 10

        const upperLimit = points[0].y // Define an upper limit
        const lowerLimit = points[3].y // Define a lower limit
        const totalHeight = textHeight * 2

        const marginThreshold = 10
        if (Math.abs(textPosX - points[0].x) < marginThreshold || points[0].x > textPosX) {
            textPosY -= totalHeight
        }
        // Check if the middle part is too close to the top or bottom
        if (textPosY - totalHeight - 10 < upperLimit) {
            textPosY = points[4].y + 30 + textHeight
        } else if (textPosY + 10 > lowerLimit) {
            textPosY = lowerLimit - totalHeight + 4
        }

        canvas.strokeRect(
            new TRect(
                textPosX - this.rectPadding,
                textPosY - totalHeight - this.rectPadding,
                textPosX + textWidthWithSpacing + this.rectPadding,
                textPosY + this.rectPadding
            ),
            TRoundRectType.BOTH,
            5,
            true,
            this.penForMiddleInfoBox,
            this.PnL > 0 ? this.profitBrush : this.looseBrush
        )

        const rectCenterX = textPosX + textWidthWithSpacing / 2
        const rectCenterY = textPosY - totalHeight / 2

        const textWidth1 = canvas.TextWidth(RR_ratio_str, this.font)
        const textHeight1 = canvas.TextHeight(RR_ratio_str, this.font)

        const textPosXCentered = rectCenterX - textWidth1 / 2
        const textPosYCentered = rectCenterY - textHeight1 / 2

        canvas.textOut(
            textPosX,
            textPosY - textHeight * 1.75 + this.rectPadding,
            displayText,
            this.font,
            this.fontBrush
        )
        canvas.textOut(textPosXCentered, textPosYCentered + 15, RR_ratio_str, this.font, this.fontBrush)
    }

    private drawBottomText(
        canvas: TGdiPlusCanvas,
        points: TPointsArray,
        textHeight: number,
        textWidth: number,
        displayText: string,
        isBuy: boolean
    ): void {
        const textWidthWithSpacing = canvas.TextWidth(displayText, this.font)
        const textPosX = points[3].x + (textWidth - textWidthWithSpacing) / 2
        let textPosY = points[3].y
        let rectTypeBot = TRoundRectType.BOTTOM

        const marginThreshold = 10
        if (Math.abs(textPosX - points[0].x) < marginThreshold || points[0].x > textPosX) {
            textPosY += textHeight
            rectTypeBot = TRoundRectType.BOTH
        }

        canvas.strokeRect(
            new TRect(
                textPosX - this.rectPadding,
                textPosY,
                textPosX + textWidthWithSpacing + this.rectPadding,
                textPosY + this.rectPadding + textHeight + 5
            ),
            rectTypeBot,
            5,
            true,
            isBuy ? this.fSLLineStyle.getPen() : this.fTPLineStyle.getPen(),
            isBuy ? this.backgroundBrushSL : this.backgroundBrushTP
        )

        canvas.textOut(textPosX, textPosY + this.rectPadding * 3, displayText, this.font, this.fontBrush)
    }

    private PaintBuyPos(): void {
        this.PaintPos(true)
    }

    private PaintSellPos(): void {
        this.PaintPos(false)
    }

    private getPadded_RR_ratio_str(canvas: TGdiPlusCanvas, RR_ratio_str: string, displayTextWidth: number) {
        const RR_ratio_strWidth = canvas.TextWidth(RR_ratio_str, this.font)

        const padding = Math.max((displayTextWidth - RR_ratio_strWidth) / 2, 0)

        return (
            ' '.repeat(Math.round(padding / canvas.TextWidth(' ', this.font))) +
            RR_ratio_str +
            ' '.repeat(Math.round(padding / canvas.TextWidth(' ', this.font)))
        )
    }

    private GetCornerPointsIndexes(): number[] {
        const result: number[] = []

        if (this.fPoints.length === 6) {
            result.push(0, 1, 2, 3)
        }

        return result
    }

    OnComplete(): void {
        const price = this.fPoints[0].price
        const index = this.chart.GetIndexFromX(this.fPoints[0].x)
        const index1 = Math.max(0, index - 8)
        const index2 = Math.max(index1, index + 8)
        const dp = this.chart.GetPriceFromY(0) - this.chart.GetPriceFromY(70)
        const time1 = this.chart.GetDateByIndex(index1)
        const time2 = this.chart.GetDateByIndex(index2)
        const price1 = price + dp
        const price2 = price - dp

        this.fPoints.clear()
        this.AddPoint(time1, price1)
        this.AddPoint(time2, price1)
        this.AddPoint(time2, price2)
        this.AddPoint(time1, price2)
        this.AddPoint(time1, price)
        this.AddPoint(time2, price)
    }

    OnMovePoint(index: number, time: TDateTime, price: number): void {
        super.OnMovePoint(index, time, price) // Calls the inherited method

        const price_x = this.fPoints[index].price
        const time_x = this.fPoints[index].time

        switch (index) {
            case 0: {
                this.fPoints[1].price = price_x
                this.fPoints[3].time = time_x
                this.fPoints[4].time = time_x
                break
            }

            case 1: {
                this.fPoints[0].price = price_x
                this.fPoints[5].time = time_x
                this.fPoints[2].time = time_x
                break
            }

            case 2: {
                this.fPoints[3].price = price_x
                this.fPoints[5].time = time_x
                this.fPoints[1].time = time_x
                break
            }

            case 3: {
                this.fPoints[2].price = price_x
                this.fPoints[4].time = time_x
                this.fPoints[0].time = time_x
                break
            }

            case 4: {
                this.fPoints[5].price = price_x
                this.fPoints[0].time = time_x
                this.fPoints[3].time = time_x
                break
            }

            case 5: {
                this.fPoints[4].price = price_x
                this.fPoints[1].time = time_x
                this.fPoints[2].time = time_x
                break
            }
        }
    }

    CanBePlaced(chart: TChart, ChartType: TChartType): boolean {
        // result := (chart <> nil) and (chart is TMainChart) and (ChartType = ct_Normal);
        return chart !== null && chart instanceof TMainChart && ChartType === TChartType.ct_Normal
    }

    assign(tool: TBasicPaintTool, isCopy = false): void {
        super.assign(tool, isCopy)

        if (tool instanceof TPtRiskReward) {
            this.fRiskToolType = tool.fRiskToolType
            this.fCalcFrom = tool.fCalcFrom
            this.fRiskType = tool.fRiskType
            this.fRiskValue = tool.fRiskValue
            this.fCustomSize = tool.fCustomSize
            this.fPrice = tool.fPrice
            this.fStopLoss = tool.fStopLoss
            this.fTakeProfit = tool.fTakeProfit
            this.fSLpoints = tool.fSLpoints
            this.fTPpoints = tool.fTPpoints
            this.fLot = tool.fLot
            this.fProfit = tool.fProfit
            this.fLoss = tool.fLoss
            this.fTPLineStyle = tool.fTPLineStyle
            this.fSLLineStyle = tool.fSLLineStyle
            this.fTPBrush = tool.fTPBrush
            this.fSLBrush = tool.fSLBrush
            this.fAlwaysShowInfo = tool.fAlwaysShowInfo
            this.font = tool.font
            this.fontBrush = tool.fontBrush

            this.Calculate()
        } else {
            throw new StrangeError('Tool is not of type TPtRiskReward.')
        }
    }

    Calculate(): void {
        if (!(this.chart instanceof TMainChart) || this.fPoints.length < 6) {
            return
        }

        const symbol = (this.chart as TMainChart).SymbolData
        if (symbol === null) {
            return
        }

        const point = symbol.symbolInfo.MinPoint

        if (this.fRiskToolType === TRiskToolType.rt_Sell) {
            this.fStopLoss = this.fPoints[0].price
            this.fTakeProfit = this.fPoints[3].price
            this.PosType = TTradePositionType.tp_Sell
        } else {
            this.fStopLoss = this.fPoints[3].price
            this.fTakeProfit = this.fPoints[0].price
            this.PosType = TTradePositionType.tp_Buy
        }

        this.fPrice = this.fPoints[4].price
        this.fSLpoints = Math.round(Math.abs(this.fPrice - this.fStopLoss) / point)
        this.fTPpoints = Math.round(Math.abs(this.fPrice - this.fTakeProfit) / point)

        let tpos = new TTradePos(
            GlobalSymbolList.SymbolList,
            0,
            symbol.symbolInfo.SymbolName,
            GlobalProcessingCore.ProcessingCore.CurrTime,
            this.PosType,
            1,
            this.fPrice,
            0,
            0,
            '',
            0
        )

        let PointCost =
            symbol.symbolInfo.getSymbolCalculationStrategy().getPointCostForOneStandardLot(this.PosType) * tpos.tpos.lot

        const value = (Math.abs(this.fPrice - this.fStopLoss) * PointCost) / symbol.symbolInfo.MinPoint
        let RiskValue
        switch (this.fCalcFrom) {
            case TCalcFrom.cf_Balance: {
                RiskValue = (GlobalProcessingCore.ProcessingCore.Balance * this.fRiskValue) / 100
                break
            }
            case TCalcFrom.cf_Equity: {
                RiskValue = (GlobalProcessingCore.ProcessingCore.Equity * this.fRiskValue) / 100
                break
            }
            default: {
                RiskValue = (this.fCustomSize * this.fRiskValue) / 100
            }
        }

        this.fLot = Math.max(
            parseFloat((RiskValue / value).toFixed(GlobalProcessingCore.ProcessingCore.LotDigits)),
            Math.pow(10, -GlobalProcessingCore.ProcessingCore.LotDigits)
        )

        tpos = new TTradePos(
            GlobalSymbolList.SymbolList,
            0,
            symbol.symbolInfo.SymbolName,
            GlobalProcessingCore.ProcessingCore.CurrTime,
            this.PosType,
            this.fLot,
            this.fPrice,
            0,
            0,
            '',
            0
        )

        PointCost =
            symbol.symbolInfo.getSymbolCalculationStrategy().getPointCostForOneStandardLot(this.PosType) * tpos.tpos.lot

        const currentPriceOnTool = this.fPoints[4].price

        this.fProfit = (Math.abs(this.fPrice - this.fTakeProfit) * PointCost) / symbol.symbolInfo.MinPoint
        this.fLoss = (Math.abs(this.fPrice - this.fStopLoss) * PointCost) / symbol.symbolInfo.MinPoint
        if (this.fRiskToolType === TRiskToolType.rt_Buy) {
            this.PnL = symbol.bid - currentPriceOnTool
        } else {
            this.PnL = currentPriceOnTool - symbol.bid
        }
    }

    get RiskToolType(): TRiskToolType {
        return this.fRiskToolType
    }

    get lot(): number {
        return this.fLot
    }

    get OpenPrice(): number {
        return this.fPrice
    }

    get StopLoss(): number {
        return this.fStopLoss
    }

    get TakeProfit(): number {
        return this.fTakeProfit
    }

    public set setRiskToolType(value: TRiskToolType) {
        this.fRiskToolType = value
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptRiskReward, TPtRiskReward)
