import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TDataFormat } from '../../DataEnums'
import { TChunkStatus, TNoExactMatchBehavior } from '../ChunkEnums'
import { TBaseTickChunk } from './BaseTickChunk'
import { TBarChunk } from '../BarChunk'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import { TDataArrayEvents } from '../../data_downloading/DownloadRelatedEnums'
import { TChunkMapStatus } from '../../TChunkMapStatus'
import DataNotDownloadedYetError from '../../data_errors/DataUnavailableError'
import { TDataDescriptor } from '../../data_arrays/DataDescriptionTypes'
import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import MathUtils from '@fto/lib/utils/MathUtils'
import { DateIsAfterChunkEnd } from '../DateOutOfChunkBoundsErrors'
import { TBarRecord } from '../../DataClasses/TBarRecord'
import { TimeframeUtils } from '@fto/lib/ft_types/common/TimeframeUtils'
import { TTickRecord } from '../../DataClasses/TTickRecord'
import CommonConstants from '@fto/lib/ft_types/common/CommonConstants'
import IFMBarsArray from '../../data_arrays/chunked_arrays/IFMBarsArray'

const FIRST_PSEUDO_TICK_TIME_SEC = DateUtils.OneSecond * 12
const SECOND_PSEUDO_TICK_TIME_SEC = DateUtils.OneSecond * 24
const THIRD_PSEUDO_TICK_TIME_SEC = DateUtils.OneSecond * 36
const FOURTH_PSEUDO_TICK_TIME_SEC = DateUtils.OneSecond * 48

export class TPseudoTickChunk extends TBaseTickChunk {
    constructor(aDataDescriptor: TDataDescriptor, aFirstDate: TDateTime, aLastPossibleDate: TDateTime) {
        super(aDataDescriptor, aFirstDate, aLastPossibleDate)
        this.SetName(
            `PseudoTickChunk_${aDataDescriptor.symbolName}_${aFirstDate}-${MathUtils.roundTo(
                aLastPossibleDate,
                4
            )}_${DateUtils.DF(aFirstDate)}`
        )
    }

    public ImportChunkData(sortedMinuteChunks: TBarChunk[], dataFormat: TDataFormat): TChunkStatus {
        this.__validateDataForImport(dataFormat, sortedMinuteChunks)

        const symbolData = GlobalSymbolList.SymbolList.GetExistingSymbol_ThrowErrorIfNull(
            this.DataDescriptor.symbolName
        )
        const symbolSpread = symbolData.symbolInfo.spread

        for (const minuteChunk of sortedMinuteChunks) {
            this.importTicksFromM1Chunk(minuteChunk, symbolSpread)
        }

        this.Status = TChunkStatus.cs_Loaded
        this.saferEmitChunkLoadedEvent()
        return this.Status
    }

    private importTicksFromM1Chunk(minuteChunk: TBarChunk, symbolSpread: number): void {
        try {
            let alreadyBuiltToDate
            if (this._data.length === 0) {
                alreadyBuiltToDate = this.FirstDate
            } else {
                alreadyBuiltToDate = this._data[this._data.length - 1].DateTime
            }

            const firstDateToSearchInChunk = Math.max(alreadyBuiltToDate, minuteChunk.FirstDate)
            const firstRelevantBarIndex = minuteChunk.GetGlobalIndexByDate(
                firstDateToSearchInChunk,
                false,
                TNoExactMatchBehavior.nemb_ReturnNearestHigher //if there is a gap, then let's find one of the next bars
            )

            if (firstRelevantBarIndex < 0) {
                throw new StrangeError('firstRelevantBarIndex < 0, unexpected behavior')
            }

            for (let barIndex = firstRelevantBarIndex; barIndex <= minuteChunk.LastGlobalIndex; barIndex++) {
                const bar = minuteChunk.GetItemByGlobalIndex(barIndex)
                if (bar) {
                    if (DateUtils.MoreOrEqual(bar.DateTime, this.LastPossibleDate)) {
                        break
                    }
                    this.importTicksFromM1Bar(bar, minuteChunk, symbolSpread)
                } else {
                    throw new StrangeError('TPseudoTickChunk.ImportChunkData bar is null, unexpected behavior')
                }
            }
        } catch (error) {
            if (error instanceof DateIsAfterChunkEnd) {
                //this is ok because we can try to find a first bar at a gap at the end of the chunk. That still means that we have processed this chunk
                this._data = []
            } else {
                throw error
            }
        }
    }

    private importTicksFromM1Bar(bar: TBarRecord, minuteChunk: TBarChunk, symbolSpread: number): void {
        this.__validateBarDates(bar, minuteChunk.DataDescriptor)

        const quarterOfVolume = Math.round(bar.volume / 4)

        this._data.push(
            new TTickRecord(
                bar.DateTime + FIRST_PSEUDO_TICK_TIME_SEC,
                bar.open,
                bar.open + symbolSpread,
                quarterOfVolume
            ),
            new TTickRecord(
                bar.DateTime + SECOND_PSEUDO_TICK_TIME_SEC,
                bar.high,
                bar.high + symbolSpread,
                quarterOfVolume
            ),
            new TTickRecord(
                bar.DateTime + THIRD_PSEUDO_TICK_TIME_SEC,
                bar.low,
                bar.low + symbolSpread,
                quarterOfVolume
            ),
            new TTickRecord(
                bar.DateTime + FOURTH_PSEUDO_TICK_TIME_SEC,
                bar.close,
                bar.close + symbolSpread,
                quarterOfVolume
            )
        )
    }

    private __validateDataForImport(dataFormat: TDataFormat, minuteChunks: TBarChunk[]) {
        if (dataFormat !== TDataFormat.df_MinutesForTicks) {
            throw new StrangeError(`Wrong data format for TPseudoTickChunk ${dataFormat}`)
        }
        if (minuteChunks.length === 0) {
            throw new StrangeError('TPseudoTickChunk.ImportChunkData - minuteChunks.length === 0, unexpected behavior')
        }

        //FIXME: handle end of history case
        if (
            this.FirstDate < minuteChunks[0].FirstDate ||
            this.LastPossibleDate > minuteChunks[minuteChunks.length - 1].LastPossibleDate
        ) {
            //this may be ok if the chunk ends at the gap, we just need to check if the next chunk starts after this 'tick' chunk's end date
            throw new StrangeError(
                'TPseudoTickChunk.ImportChunkData - there is not enough coverage to build this tick chunk. this.FirstDate < minuteChunk.FirstDate || this.LastPossibleDate > minuteChunk.LastPossibleDate, unexpected behavior'
            )
        }
    }

    private __validateBarDates(bar: TBarRecord, barDataDescriptor: TDataDescriptor) {
        if (bar.DateTime < this.FirstDate) {
            //we found a bar that starts before the current bar, this is unexpected
            throw new StrangeError(
                'addBARChunkDataToBarUnderConstruction - bar.DateTime < barUnderConstruction.barStartDate, unexpected behavior'
            )
        }
        const barEndDate = TimeframeUtils.GetPeriodEnd(bar.DateTime, barDataDescriptor.timeframe)
        if (barEndDate > this.LastPossibleDate + CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_DATETIME * 2) {
            throw new StrangeError(
                `addBARChunkDataToBarUnderConstruction - barEndDate > this.LastPossibleDate, unexpected behavior`,
                barEndDate,
                this.LastPossibleDate
            )
        }
    }

    public GetChunkUrl(): string {
        throw new StrangeError('This method does not make sense for TPseudoTickChunk.')
    }

    protected StartLoadingData(): void {
        if (this.Status === TChunkStatus.cs_Empty) {
            if (this.IsEmptyOnServer()) {
                this.loadAsEmpty()
            } else {
                this.loadThisChunkFromMinutes()
            }
        }
    }

    private loadAsEmpty() {
        this._data = []
        this.Status = TChunkStatus.cs_Loaded
        this.saferEmitChunkLoadedEvent()
    }

    private loadThisChunkFromMinutes() {
        this.Status = TChunkStatus.cs_Building
        const correspondingMinuteChunks = this.getLoadedMinuteChunks()
        //sort chunks by date smallest to largest
        correspondingMinuteChunks.sort((a, b) => a.FirstDate - b.FirstDate)
        this.ImportChunkData(correspondingMinuteChunks, TDataFormat.df_MinutesForTicks)
    }

    private getLoadedMinuteChunks(): TBarChunk[] {
        const symbolData = GlobalSymbolList.SymbolList.GetExistingSymbol_ThrowErrorIfNull(
            this.DataDescriptor.symbolName
        )
        const minuteBarsArray = symbolData.GetOrCreateBarArray(1)
        this.ensureM1MapIsLoaded(minuteBarsArray)

        const chunks = minuteBarsArray.GetChunksForRangeDates(this.FirstDate, this.LastPossibleDate)
        this.ensureAllChunksAreLoaded(chunks)

        return chunks
    }

    private ensureAllChunksAreLoaded(chunks: TBarChunk[]) {
        let areAllChunksLoaded = true
        for (const chunk of chunks) {
            areAllChunksLoaded = this.isDataLoaded_makeSureToLoad(chunk) && areAllChunksLoaded
        }
        if (!areAllChunksLoaded) {
            //throw error here when all the necessary chunks got the command to load
            throw new DataNotDownloadedYetError(
                `getCorrespondingMinuteChunk - Minute chunks are not loaded yet to form tick chunk ${this.DName}`
            )
        }
    }

    private isDataLoaded_makeSureToLoad(chunk: TBarChunk | null): boolean {
        if (!chunk) {
            throw new StrangeError(`getCorrespondingMinuteChunk - No minute chunk found for ${this.DName}`)
        }

        if (chunk.Status !== TChunkStatus.cs_Loaded) {
            chunk.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundDoDeferredImport)
            chunk.EnsureDataIsPresentOrDownloading()
            return false
        }
        return true
    }

    private ensureM1MapIsLoaded(minuteBarsArray: IFMBarsArray) {
        if (minuteBarsArray.ChunkMapStatus !== TChunkMapStatus.cms_Loaded) {
            minuteBarsArray.Events.on(TDataArrayEvents.de_MapDownloaded, this.boundDoDeferredImport)
            minuteBarsArray.InitMapIfNecessary()
            throw new DataNotDownloadedYetError(
                `getCorrespondingMinuteChunk - Minute bars array is not loaded yet to form tick chunk ${this.DName}`
            )
        }
    }

    private boundDoDeferredImport = this.doDeferredImport.bind(this)
    private doDeferredImport() {
        try {
            this.loadThisChunkFromMinutes()
        } catch (error) {
            if (error instanceof DataNotDownloadedYetError) {
                //this is ok, let's wait
            } else {
                throw error
            }
        }
    }
}
