import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'

import { Button, Flex, TriggerOverlay } from '@fto/ui'
import { IconButton } from '@fto/icons'

import { setLocalStorage, getLocalStorage } from '@root/utils/localStorage'
import { FAVORITE_TIMEFRAMES } from '@root/constants/localStorageKeys'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'
import { fireMixpanelEvent } from '@root/utils/api'

import TimeframeStore from '@fto/lib/store/timeframe'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { CustomTimeFrame } from '@fto/lib/store/timeframe/types'
import { TimeframeUtils } from '@fto/lib/ft_types/common/TimeframeUtils'

import { TIME_FRAMES } from '@fto/chart_components/constants/timeframes'

import Selector from './components/Selector'

import styles from './index.module.scss'

type Props = {
    activeChartSymbol: string
    timeframe?: number
    preselectedTimeFrames: number[]
    customTimeframes: CustomTimeFrame[]
}

const TimeFramesBlock: FC<Props> = ({ activeChartSymbol, timeframe, preselectedTimeFrames, customTimeframes }) => {
    const [isSelectOpen, setIsSelectOpen] = useState(false)

    const { updatePreselectedTimeFrames } = TimeframeStore

    const params = new URLSearchParams(window.location.search)
    const projectId = params.get('projectId')

    const changeTimeFrame = useCallback(
        (timeFrame: number) => {
            fireMixpanelEvent('timeframe_changed', {
                from: timeframe,
                to: timeFrame,
                currency: activeChartSymbol
            })
            GlobalChartsController.Instance.setNewTimeFrame(timeFrame)
            TimeframeStore.setTimeframe(timeFrame)
        },
        [timeframe, activeChartSymbol]
    )

    const formattedPresetsList = useMemo(() => {
        if (preselectedTimeFrames.length === 0) {
            return [timeframe]
        }

        const copyOfPresets = [...preselectedTimeFrames]

        if (timeframe !== undefined && !copyOfPresets.includes(timeframe)) {
            copyOfPresets.push(timeframe)
        }
        return copyOfPresets.sort((a, b) => a - b)
    }, [preselectedTimeFrames, timeframe])

    const { userId } = useAppSelector($getUser)

    useEffect(() => {
        const key = `${userId}_${projectId}_${FAVORITE_TIMEFRAMES}`

        const presets: number[] | null = getLocalStorage(key)

        if (presets) {
            const allTimeframes = [...TIME_FRAMES, ...customTimeframes.map((tf) => tf.value)]

            // NOTE: here we check only valid TFs and remove invalid from favorite list
            const filteredTimeframes = presets.filter((tf) => allTimeframes.includes(tf))

            updatePreselectedTimeFrames(filteredTimeframes)
        }
    }, [])

    useEffect(() => {
        const key = `${userId}_${projectId}_${FAVORITE_TIMEFRAMES}`

        setLocalStorage(key, preselectedTimeFrames)
    }, [preselectedTimeFrames])

    const SelectJSX = useCallback(
        (handleClose: () => void) => {
            return (
                <Selector
                    customTimeframes={customTimeframes}
                    preselectedTimeFrames={preselectedTimeFrames}
                    selectedTimeFrame={timeframe}
                    changeTimeFrame={changeTimeFrame}
                    onClose={handleClose}
                />
            )
        },
        [customTimeframes, preselectedTimeFrames, timeframe, changeTimeFrame]
    )

    return (
        <TriggerOverlay
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            optionsRenderer={SelectJSX}
            setIsOpened={setIsSelectOpen}
            isOpen={isSelectOpen}
            disableBackdropChartActivity
        >
            <Flex alignItems='center' gap={4}>
                {formattedPresetsList.map((timeframeFromArray) => (
                    <Button
                        withTooltip
                        tooltipText={TimeframeUtils.GetTimeframeNameLabel(timeframeFromArray as number).label}
                        classNames={{
                            root: styles.timeframeButton,
                            content: cx(styles.timeframeButton, {
                                [styles.selected]: timeframe === timeframeFromArray
                            })
                        }}
                        key={timeframeFromArray}
                        onClick={() => changeTimeFrame(timeframeFromArray as number)}
                        label={TimeframeUtils.GetShortTimeframeName(timeframeFromArray as number)}
                        size='small'
                        type='tetriatry-white'
                    />
                ))}

                <IconButton name='chevron-down' size={16} onClick={() => setIsSelectOpen(true)} />
            </Flex>
        </TriggerOverlay>
    )
}

export default TimeFramesBlock
