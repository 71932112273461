import { TChartOptions, TColorScheme } from '@fto/lib/charting/ChartBasicClasses'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TOptions } from '@fto/lib/ft_types/common/OptionsUnit'

type StringPropertyNames<T> = {
    [K in keyof T]: T[K] extends string ? K : never
}[keyof T]
export type StringProperties<T> = Pick<T, StringPropertyNames<T>>

type NumberPropertyNames<T> = {
    [K in keyof T]: T[K] extends number ? K : never
}[keyof T]
export type NumberProperties<T> = Pick<T, NumberPropertyNames<T>>

type BooleanPropertyNames<T> = {
    [K in keyof T]: T[K] extends boolean ? K : never
}[keyof T]
export type BooleanProperties<T> = Pick<T, BooleanPropertyNames<T>>

type FunctionPropertyNames<T> = {
    [K in keyof T]: T[K] extends (...args: any[]) => any ? K : never
}[keyof T]
export type FunctionProperties<T> = Pick<T, FunctionPropertyNames<T>>

export type OptionsType = keyof TChartOptions | keyof TOptions
export type Option = { [key: string]: any }

export interface SettingsTabRef {
    loadOptionsSetting: (data: any, shouldUpdateConfig: boolean) => any
}
export interface OptionSettingRef {
    name: string
    loadOptionSetting: (data: any, shouldUpdateConfig: boolean) => any
}

export enum ConfigType {
    ColorScheme = 'ColorScheme',
    ChartOptions = 'ChartOptions',
    GlobalOptions = 'GlobalOptions'
}
export const ConfigTypes = {
    [ConfigType.ColorScheme]: TColorScheme,
    [ConfigType.ChartOptions]: TChartOptions,
    [ConfigType.GlobalOptions]: TOptions
}

export type SliderSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    onDifferentSettingsUpdate: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type InputSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    maxValue?: number
    minValue?: number
    onDifferentSettingsUpdate: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type DropdownSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    initialData: Partial<TOptions & TChartOptions>
    onDifferentSettingsUpdate: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type ColorSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    onDifferentSettingsUpdate: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type CheckboxSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    onDifferentSettingsUpdate: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type CheckboxAndColorSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    onDifferentSettingsUpdate: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type Tabs = 'canvas' | 'views' | 'general'

type OnDifferentSettingsUpdateType = (tab: string, newValue: any, prevValue: any, key: string) => void

export type CanvasSettingsProps = {
    chart: TChartWindow
    applyToAll: boolean
    onDifferentSettingsUpdate: OnDifferentSettingsUpdateType
    initialData: Partial<TOptions & TChartOptions>
}

export type TradingSettingsProps = {
    chart: TChartWindow
    applyToAll: boolean
    onDifferentSettingsUpdate: OnDifferentSettingsUpdateType
    initialData: Partial<TOptions & TChartOptions>
}

export type ViewsSettingsProps = {
    chart: TChartWindow
    applyToAll: boolean
    onDifferentSettingsUpdate: OnDifferentSettingsUpdateType
    initialData: Partial<TOptions & TChartOptions>
}

export type GeneralSettingsProps = {
    chart: TChartWindow
    globalOptions: TOptions
    applyToAll: boolean
    onDifferentSettingsUpdate: OnDifferentSettingsUpdateType
    initialData: Partial<TOptions & TChartOptions>
}
