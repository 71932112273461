import { TColor } from '../../../delphi_compatibility/DelphiBasicTypes'
import {
    MacdMode,
    TChartInfo,
    TDateTime,
    TDrawStyle,
    TMAType,
    TOptValue,
    TOptionType,
    TOutputWindow,
    TPenStyle,
    TPriceType,
    TValueType,
    TObjectType
} from '../api/IndicatorInterfaceUnit'

export interface IIndexBuffer {
    GetMax(index1: number, index2: number, EmptyValue?: number): number
    GetMin(index1: number, index2: number, EmptyValue?: number): number
    GetValue(globalIndex: number): number
    GetDateTime(globalIndex: number): TDateTime
    GetValue_reversedIndex(lastItemEquals_0_based_index: number): number
    IsEmpty(globalIndex: number): boolean
    SetValue_reversedIndex(lastItemEquals_0_based_index: number, value: number | null): void
    Clear(): void
    HasSomeValues(): boolean
    GetMaxPossibleGlobalIndex(): number
    LastItemInTestingIndex: number
}

export class TIndexBuffer {
    private programSideBuffer: IIndexBuffer

    constructor(programSideBuffer: IIndexBuffer) {
        this.programSideBuffer = programSideBuffer
    }

    public getBuffer(): IIndexBuffer {
        return this.programSideBuffer
    }

    public setValue(index: number, price: number) {
        this.programSideBuffer.SetValue_reversedIndex(index, price)
    }

    public getValue(index: number) {
        return this.programSideBuffer.GetValue_reversedIndex(index)
    }
}

export default interface ProcRec {
    RecalculateMeAlways: () => void
    SetBackOffsetForCalculation: (offset: number) => void

    iMACD: (
        symbol: string | null,
        timeframe: number | null,
        fastEmaPeriod: number,
        slowEmaPeriod: number,
        signalPeriod: number,
        appliedPrice: TPriceType,
        mode: MacdMode,
        index: number
    ) => number

    // Get symbol information
    // Symbol - symbol name
    // info - pointer to TSymbolInfo record
    // result: true if success
    //   function  GeTSymbolInfo(Symbol: AnsiString; var info: PSymbolInfo): boolean;
    // GetSymbolInfo: (symbol: string) => { digits: number; point: number }

    // Register option
    // OptionName - name of option
    // OptionType - see TOptionType enumeration
    // option - pointer to first byte
    //   procedure RegOption(OptionName: string; OptionType: TOptionType; var option); overload;
    //   procedure RegOption(OptionName: string; var option: longword; MinValue: longword = 0; MaxValue: longword = 0); overload;
    //   procedure RegOption(OptionName: string; var option: integer; MinValue: integer = 0; MaxValue: integer = 0); overload;
    //   procedure RegOption(OptionName: string; var option: double; decimals: integer = 2; MinValue: double = 0; MaxValue: double = 0); overload;
    //   procedure RegOption(OptionName: string; var option: boolean); overload;
    //   procedure RegOption(OptionName: string; var option: PAnsiChar; value: AnsiString = ''); overload;
    //   procedure RegOption(OptionName: string; var option: integer; values: array of string; index: integer =-1); overload;
    //   procedure RegOption(OptionName: string; var option: TColor); overload;
    RegOption: (optionName: string, optionType: TOptionType, optionPtr: TOptValue) => void

    // Add separator to options dialog
    // text - separator caption
    //   procedure AddSeparator(text: string);
    AddSeparator: (separatorName: string) => void

    // Add option value (for drop down box options)
    // OptionName - name of previously registered option
    // value - new value in drop down box
    //   procedure AddOptionValue(OptionName, value: string);
    AddOptionValue: (optionName: string, value: string) => void

    // Set option range (for integer, longword and double types)
    // OptionName - name of previously registered option
    // LowValue - lowest value in range
    // HighValue - highest value in range
    // note: in dialog box after entering option value it will be checked
    //       to be in range LowValue <= value <= HighValue
    //   procedure SetOptionRange(OptionName: string; LowValue, HighValue: double);
    SetOptionRange: (optionName: string, minValue: number, maxValue: number) => void

    // Set option digits (for double options) defines number of digits after point
    // OptionName - name of option
    // digits - number of digits after point
    //   procedure SetOptionDigits(OptionName: string; digits: word);

    // Not used in any indicators
    // SetOptionDigits: (optionName: string, digits: number) => void

    // Print text in "Journal" window in ForexTester
    // s - text
    //   procedure Print(s: string);
    // Print: (s: string) => void

    // Set indicator short name
    // name - short name
    //   procedure IndicatorShortName(name: string);
    IndicatorShortName: (shortName: string) => void

    // Set number of index buffers
    // count - number of buffers
    //   procedure IndicatorBuffers(count: integer);
    IndicatorBuffers: (bufferCount: number) => void

    // Create index buffer
    // result - buffer id
    //   function  CreateIndexBuffer: TIndexBuffer; overload;
    CreateIndexBuffer: () => TIndexBuffer

    // Create and set index buffer
    //   function CreateIndexBuffer(index: integer; aLabel: string; DrawStyle: TDrawStyle;
    //   style: TPenStyle; width: integer; color: TColor) => TIndexBuffer; overload;
    // CreateIndexBufferWithArgs: (
    //     index: number,
    //     aLabel: string,
    //     DrawStyle: TDrawStyle,
    //     style: TPenStyle,
    //     width: number,
    //     color: TColor
    // ) => TIndexBuffer

    // Create loopback index buffer
    // MaxSize - maximum buffer size
    // result - buffer id
    //   function  CreateLoopbackIndexBuffer(MaxSize: integer) => TIndexBuffer;
    // Not used in any indicators in FT6
    // CreateLoopbackIndexBuffer: (maxSize: number) => TIndexBuffer

    // Set index buffer
    // index - number of index buffer slot
    // buffer - buffer id
    //   procedure SetIndexBuffer(index: integer; buffer: TIndexBuffer);
    SetIndexBuffer: (bufferIndex: number, buffer: TIndexBuffer) => void

    // Set index buffer style
    // index - number of index buffer slot
    // _type - paint type
    // style - line style
    // width - line width
    // color - line color
    //   procedure SetIndexStyle(index: integer; DrawStyle: TDrawStyle; style: TPenStyle;
    //   width: integer; color: TColor);
    SetIndexStyle: (bufferIndex: number, type: TDrawStyle, style: TPenStyle, width: number, clr: TColor) => void

    // Set index symbol
    // index - number of an index buffer slot
    // symbol - number of symbol in Wingdings font
    // xoffs - x offset from center point of the symbol in pixels
    // yoffs - y offset from center point of the symbol in pixels
    //   procedure SetIndexSymbol(index, symbol: integer; xoffs: integer = 0;
    //   yoffs: integer = 0);
    SetIndexSymbol: (bufferIndex: number, symbol: number, xoffs: number, yoffs: number) => void

    // Set index label
    // index - number of an index buffer slot
    // text - text label
    //   procedure SetIndexLabel(index: integer; text: AnsiString);
    SetIndexLabel: (bufferIndex: number, bufferName: string) => void

    // Set indicator output window
    // aWindow - where to paint indicator (ow_ChartWindow, ow_SeparateWindow)
    //   procedure SetOutputWindow(aWindow: TOutputWindow);
    SetOutputWindow: (outputWindow: TOutputWindow) => void

    // Set fixed maximum/minimum for indicator window
    // aMin - minimum value
    // aMax - maximum value
    //   procedure SetFixedMinMaxValues(aMin, aMax: double);
    SetFixedMinMaxValues: (aMin: number, aMax: number) => void

    // Add level line to indicator window
    // value - level value
    // style - line style
    // width - line width
    // color - line color
    //   procedure AddLevel(value: double; style: TPenStyle; width: integer; color: TColor);
    AddLevel: (value: number, style: TPenStyle, width: number, color: TColor, opacity: number) => void

    // Set empty value that will not be painted
    // value - new empty value
    //   procedure SetEmptyValue(value: double);
    SetEmptyValue: (emptyValue: number) => void

    // symbol name
    //   function  Symbol: AnsiString;
    Symbol: () => string

    // symbol digits after point
    //   function  Digits: integer;
    Digits: () => number

    // Minimal symbol point
    //   function  Point: double;
    Point: () => number

    // Get open value
    // Symbol - requested symbol
    // TimeFrame - requested timeframe
    // index - shift in bars from last bar
    //   function  iOpen(Symbol: AnsiString; TimeFrame: integer; index: integer): double;
    iOpen: (symbol: string, timeframe: number, index: number) => number

    // Get close value
    // Symbol - requested symbol
    // TimeFrame - requested timeframe
    // index - shift in bars from last bar
    //   function  iClose(Symbol: AnsiString; TimeFrame: integer; index: integer): double;
    iClose: (symbol: string, timeframe: number, index: number) => number

    // Get high value
    // Symbol - requested symbol
    // TimeFrame - requested timeframe
    // index - shift in bars from last bar
    //   function  iHigh(Symbol: AnsiString; TimeFrame: integer; index: integer): double;
    iHigh: (symbol: string, timeframe: number, index: number) => number

    // Get low value
    // Symbol - requested symbol
    // TimeFrame - requested timeframe
    // index - shift in bars from last bar
    //   function  iLow(Symbol: AnsiString; TimeFrame: integer; index: integer): double;
    iLow: (symbol: string, timeframe: number, index: number) => number

    // Get volume
    // Symbol - requested symbol
    // TimeFrame - requested timeframe
    // index - shift in bars from last bar
    //   function  iVolume(Symbol: AnsiString; TimeFrame: integer; index: integer): double;
    iVolume: (symbol: string, timeframe: number, index: number) => number

    // Get time of bar
    // Symbol - requested symbol
    // TimeFrame - requested timeframe
    // index - shift in bars from last bar
    //   function  iTime(Symbol: AnsiString; TimeFrame: integer; index: integer): TDateTime;
    iTime: (symbol: string, timeframe: number, index: number) => TDateTime

    // Get number of bars
    // Symbol - requested symbol
    // TimeFrame - requested timeframe
    //   function  iBars(Symbol: AnsiString; TimeFrame: integer): integer;
    iBars: (symbol: string, timeframe: number) => number

    // Get open value in default symbol and timeframe
    // index - shift in bars from last bar
    //   function  Open(index: integer): double;
    Open: (index: number) => number

    // Get close value in default symbol and timeframe
    // index - shift in bars from last bar
    //   function  Close(index: integer): double;
    Close: (index: number) => number

    // Get high value in default symbol and timeframe
    // index - shift in bars from last bar
    //   function  High(index: integer): double;
    High: (index: number) => number

    // Get low value in default symbol and timeframe
    // index - shift in bars from last bar
    //   function  Low(index: integer): double;
    Low: (index: number) => number

    // Get volume in default symbol and timeframe
    // index - shift in bars from last bar
    //   function  Volume(index: integer): double;
    Volume: (index: number) => number

    // Get time of bar in default symbol and timeframe
    // index - shift in bars from last bar
    //   function  Time(index: integer): TDateTime;
    Time: (index: number) => TDateTime

    // Get number of bars in default symbol and timeframe
    //   function  Bars: integer;
    Bars: () => number

    // Set indicator precision (number of digits after .)
    // digits - number of digits after point
    //   procedure IndicatorDigits(digits: integer);
    // IndicatorDigits: (digits: number) => void

    // Get indicator timeframe
    //   function  Timeframe: integer;
    Timeframe: () => number

    // Get interface version
    //   procedure GetInterfaceVersion(var MajorValue, MinorValue: integer);

    // Not used in any indicators in FT6
    // GetInterfaceVersion: () => { MajorValue: number; MinorValue: number }

    // Create object
    // name - object name (must be unique)
    // ObjType - type of object (see TObjectType)
    // window - in what window to show object (ignored)
    // time1, price1 .. time3, price3 - time and price coordinates of object
    // function returns true if successful
    //   function  ObjectCreate(name: AnsiString; ObjType: TObjectType; window: integer;
    //   time1: TDateTime; price1: double; time2: TDateTime = 0; price2: double = 0;
    //   time3: TDateTime = 0; price3: double = 0): boolean;
    ObjectCreate: (
        name: string,
        objType: TObjectType,
        window: number,
        time1: TDateTime,
        price1: number,
        time2?: TDateTime,
        price2?: number,
        time3?: TDateTime,
        price3?: number,
        isStatic?: boolean
    ) => boolean

    // Delete object by name
    // name - name of the object
    //   procedure ObjectDelete(name: AnsiString);
    ObjectDelete: (name: string, isStatic?: boolean) => void

    // Check if object already exists
    // name  - name of the object
    //   function  ObjectExists(name: AnsiString): boolean;
    ObjectExists: (uniqueObjectname: string, isStatic?: boolean) => boolean

    // Get object type
    // name - name of the object
    //   function  ObjectType(name: AnsiString): TObjectType;
    // ObjectType: (name: string) => TObjectType

    // Set object property
    // name - name of the object
    // index - property index
    // value - new value
    // function returns true if successful
    //   function  ObjectSet(name: AnsiString; index: integer; value: double): boolean;
    ObjectSet: (name: string, index: number, value: any, isStatic?: boolean) => boolean

    // Get object property
    // name - name of the object
    // index - property index
    //   function  ObjectGet(name: AnsiString; index: integer): double;
    // ObjectGet: (name: string, index: number) => number

    // Delete all objects
    // window - window where to delete
    // ObjType - type of objects
    //   procedure ObjectsDeleteAll(window: integer = 0; ObjType: TObjectType = obj_AnyObject);
    // ObjectsDeleteAll: (window: number, objType: TObjectType) => void

    // set text/description for object
    // name - name of the object
    // text - text to set
    // FontSize - font size
    // FontName - font name
    // Color - font color
    // function returns true if successful
    //   function  ObjectSetText(name, text: AnsiString; FontSize: integer = 12;
    //   FontName: AnsiString = 'Arial'; Color: TColor = clRed): boolean;
    ObjectSetText: (
        name: string,
        text: string,
        fontSize?: number,
        fontName?: string,
        color?: TColor,
        isStatic?: boolean
    ) => boolean

    // get text/description of the object
    // name - name of the object
    // function returns text or empty string if failed
    //   function  ObjectGetText(name: AnsiString): AnsiString;
    // ObjectGetText: (name: string) => string

    // set index buffer shift
    // BuffIndex - index of the buffer
    // shift - buffer shift in bars
    //   procedure SetBufferShift(BuffIndex, shift: integer);
    SetBufferShift: (bufferIndex: number, shift: number) => void

    // get buffer value
    //   function  GetIndicatorBufferValue(indicator: TIndicatorOption; index: integer): double;

    // Marked in delphi as deprecated
    // GetIndicatorBufferValue: (indicator: TOptValue, index: number) => number

    // get number of graphical objects on associated chart
    //   function  ObjectsTotal: integer;
    // ObjectsTotal: () => number

    // get object's name by its index
    // index - object's index
    //   function  ObjectName(index: integer): AnsiString;
    // ObjectName: (index: number) => string

    // get bar index by its time
    // Symbol - requested symbol
    // TimeFrame - requested timeframe
    // time - time of the bar
    // Exact - if this parameter is true then time should be exactly the same
    //         otherwise will be returned index of the bar where time is
    //         between time[index] and time[index + 1]
    // function returns index of the bar if successful, and -1 if failed
    //   function  iBarShift(Symbol: AnsiString; TimeFrame: integer; time: TDateTime; Exact: boolean): integer;
    iBarShift: (symbol: string, timeframe: number, time: TDateTime, exact: boolean) => number

    // get highest value in array
    // Symbol - requested symbol
    // TimeFrame - requested timeframe
    // _type - type of value (see constants MODE_OPEN .. MODE_TIME)
    // count - number of bars to search
    // index - first index to start searching
    // function returns index of the bar if successful, and -1 if failed
    //   function  iHighest(Symbol: AnsiString; TimeFrame: integer; _type, count, index: integer): integer;
    iHighest: (symbol: string, timeframe: number, type: TValueType, count: number, index: number) => number

    // get lowest value in array
    // Symbol - requested symbol
    // TimeFrame - requested timeframe
    // _type - type of value (see constants MODE_OPEN .. MODE_TIME)
    // count - number of bars to search
    // index - first index to start searching
    // function returns index of the bar if successful, and -1 if failed
    //   function  iLowest(Symbol: AnsiString; TimeFrame: integer; _type, count, index: integer): integer;
    iLowest: (symbol: string, timeframe: number, type: number, count: number, index: number) => number

    // convert chart index to screen x coordinate
    // index - index of a bar
    // function returns x coordinate if succesful, and 0 if failed
    //   function  ChartToScrX(index: integer): integer;
    ChartToScrX: (index: number) => number

    // convert chart price to screen y coordinate
    // price - price value
    // function returns y coordinate if succesful, and 0 if failed
    //   function  ChartToScrY(price: double): integer;
    ChartToScrY: (price: number) => number

    // convert screen x coordinate to chart bar's index
    // x - screen x coordinate
    // function returns index if succesful, and -1 if failed
    //   function  ScrToChartX(x: integer): integer;

    // Not used in any indicators
    // ScrToChartX: (x: number) => number

    // convert screen y coordinate to chart price
    // y - screen y coordinate
    // function returns price value if succesful, and -1 if failed
    //   function  ScrToChartY(y: integer): double;

    // Not used in any indicators
    // ScrToChartY: (y: number) => number

    // get information about chart
    // function returns true and filled ChartInfo structure if succesful, and
    // false if faled
    //   function  GetChartInfo(var ChartInfo: TChartInfo): boolean;
    GetChartInfo: () => TChartInfo | null

    // get current Bid price
    //   function Bid: double;
    Bid: () => number

    // get current Ask price
    //   function Ask: double;
    Ask: () => number

    // get current time
    //   function TimeCurrent: TDateTime;
    // Not used in any indicators
    TimeCurrent: () => TDateTime

    // replace string value
    //   procedure ReplaceStr(var dest: PAnsiChar; source: PAnsiChar); stdcall;

    // built-in function of delphi that replaces a substring within a string, no need to implement (i think)
    // ReplaceStr: (dest: string, source: string) => void

    //----------------------------------------
    // Helper functions
    //----------------------------------------

    // Get price by index and type
    // index - index in bar array
    // PriceType - type of price or combination
    //   function  GetPrice(index: integer; PriceType: TPriceType): double;
    GetPrice: (index: number, priceType: TPriceType) => number

    // Register option "Apply to price" and fill array of its
    // text values
    //   procedure RegApplyToPriceOption(var option: integer; name: string = '');
    RegApplyToPriceOption: (optionPtr: TOptValue, name: string) => void

    // Register option "MA type" and fill array of its text values
    //   procedure RegMATypeOption(var option: integer; name: string = '');
    RegMATypeOption: (optionPtr: TOptValue, name?: string) => void

    // Get linear regression channel parameters
    // shift - shift value
    // period - channel period
    // PriceType - type of price
    // StartValue - start price of center line
    // EndValue - end price of center line
    // Height - channel height
    // top - channel top
    // bottom - channel bottom
    //   procedure LRCChannelParams(shift, period: integer; PriceType: TPriceType;
    //   var StartValue, EndValue, Height, Top, Bottom: double);
    // LRCChannelParams: (
    //     shift: number,
    //     period: number,
    //     priceType: TPriceType
    // ) => { StartValue: number; EndValue: number; Height: number; Top: number; Bottom: number }

    // Get moving average value
    // index - index in bars array
    // shift - shift value
    // maType - moving average type (SMA, EMA, WMA)
    // ApplyTo - price type
    // prev - previous value for EMA
    //   function  GetMA(index, shift, period: integer; maType: TMAType;
    //   ApplyTo: TPriceType; prev: double = 0): double;
    GetMA: (index: number, shift: number, period: number, maType: TMAType, applyTo: TPriceType, prev?: number) => number

    // Get highest value in array
    // ValueType - type of the value (vt_Open..vt_Volume)
    // StartIndex - index from which we start search
    // count - number of bars to search
    //   function  GetHighestValue(ValueType: TValueType; StartIndex, count: integer): double;
    GetHighestValue: (ValueType: TValueType, StartIndex: number, count: number) => number

    // Get lovest value in array
    // ValueType - type of the value (vt_Open..vt_Volume)
    // StartIndex - index from which we start search
    // count - number of bars to search
    //   function  GetLowestValue(ValueType: TValueType; StartIndex, count: integer): double;
    GetLowestValue: (ValueType: TValueType, StartIndex: number, count: number) => number

    //   procedure SetCalcModeMT4;
    //   function  mql4_Counted_bars: integer;
    // mql4_Counted_bars: () => number

    // set empty value for indicator buffer
    //   procedure SetIndexEmptyValue(index: integer; value: double);

    // Not used in any indicators
    // SetIndexEmptyValue: (bufferIndex: number, value: number) => void

    // set first index from which indicator line will be painted
    //   procedure SetIndexDrawBegin(index, DrawBegin: integer);
    SetIndexDrawBegin: (bufferIndex: number, PaintFrom: number) => void

    GetDayOfMonthByDateTime: (time: TDateTime) => number
    GetWeekByDateTime: (time: TDateTime) => number
    GetMonthByDateTime: (time: TDateTime) => number
    GetYearByDateTime: (time: TDateTime) => number
    setIndicatorIdKey: (key: string) => void
}
