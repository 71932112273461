import { IGPPen } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'

import { TCanvas, TColor, TFontStyles, TPenStyle } from '../delphi_compatibility/DelphiBasicTypes'
import { TPoint, TRect } from '../delphi_compatibility/DelphiBasicTypes'
import { TStringDynArray } from '../ft_types/common/VeryBasicTypes'
import { NotImplementedError } from '../utils/common_utils'
import { TPointInfo } from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'

export interface TLineStyleJSON {
    color: TColor
    style: TPenStyle
    width: number
}

export class TLineStyle {
    color: TColor // Representing TColor as a string (e.g., "#FF0000")
    style: TPenStyle
    width: number
    private pen: IGPPen | null = null

    public static fromSerialized(json: TLineStyleJSON): TLineStyle {
        return new TLineStyle(json.color, json.style, json.width)
    }

    constructor(aColor: TColor, aStyle: TPenStyle, aWidth: number) {
        this.color = aColor
        this.style = aStyle
        this.width = aWidth
    }

    clone(): TLineStyle {
        return new TLineStyle(this.color, this.style, this.width)
    }

    SaveToStr(): string {
        // Convert the line style to a string representation
        // This is a placeholder implementation, adjust as needed
        return JSON.stringify({
            color: this.color,
            style: this.style,
            width: this.width
        })
    }

    LoadFromStr(s: string): void {
        // Load the line style from a string representation
        // This is a placeholder implementation, adjust as needed
        const obj = JSON.parse(s)
        this.color = obj.color
        this.style = obj.style as TPenStyle
        this.width = obj.width
    }

    getSerialized(): TLineStyleJSON {
        return {
            color: this.color,
            style: this.style,
            width: this.width
        }
    }

    getPen() {
        if (this.pen === null) {
            this.pen = new IGPPen(this.color, this.width)
        }

        this.pen.setColor(this.color)
        this.pen.setWidth(this.width)
        this.pen.setPenStyle(this.style)
        this.pen.opacity = ColorHelperFunctions.GetOpacity(this.color)

        return this.pen
    }
}

export class TBufferStyle extends TLineStyle {
    isVisible = true

    constructor(aColor: TColor, aStyle: TPenStyle, aWidth: number, isVisible = true) {
        super(aColor, aStyle, aWidth)
        this.isVisible = isVisible
    }

    clone(): TBufferStyle {
        return new TBufferStyle(this.color, this.style, this.width, this.isVisible)
    }

    SaveToStr(): string {
        return JSON.stringify({
            color: this.color,
            style: this.style,
            width: this.width,
            isVisible: this.isVisible
        })
    }

    LoadFromStr(s: string): void {
        const obj = JSON.parse(s)
        this.color = obj.color
        this.style = obj.style as TPenStyle
        this.width = obj.width
        this.isVisible = obj.isVisible
    }
}
export class TExtendsHorizontal {
    left: boolean
    right: boolean
    leftfPointsIndexes: number[]
    rightfPointsIndexes: number[]

    constructor() {
        this.left = false
        this.right = false
        this.leftfPointsIndexes = []
        this.rightfPointsIndexes = []
    }
}

export class TExtendsRay {
    left: boolean
    right: boolean
    fillLeft: boolean
    fillRight: boolean
    leftfPoints: { [key: number]: [TPointInfo, TPointInfo, IGPPen | null] }[]
    rightfPoints: { [key: number]: [TPointInfo, TPointInfo, IGPPen | null] }[]
    fillLeftfPoints: { [key: number]: [TPointInfo, TPointInfo, IGPPen | null] }[] | null
    fillRightfPoints: { [key: number]: [TPointInfo, TPointInfo, IGPPen | null] }[] | null

    constructor() {
        this.left = false
        this.right = false
        this.leftfPoints = []
        this.rightfPoints = []
        this.fillLeft = false
        this.fillRight = false
        this.fillLeftfPoints = null
        this.fillRightfPoints = null
    }
}

export class TMkFontStyle {
    name: string
    style: TFontStyles
    size: number
    color: TColor

    constructor(aName: string, aStyle: TFontStyles, aSize: number, aColor: TColor) {
        this.name = aName
        this.style = aStyle
        this.size = aSize
        this.color = aColor
    }

    clone(): TMkFontStyle {
        return new TMkFontStyle(this.name, this.style, this.size, this.color)
    }

    SaveToStr(): string {
        throw new NotImplementedError('TMkFontStyle.SaveToStr')
    }

    LoadFromStr(s: string): void {
        if (s === '') {
            return
        }

        let remainingString
        ;[this.name, remainingString] = StrsConv.GetSubStr(s, ',')
        ;[this.style, remainingString] = StrsConv.GetStrFontStyle(remainingString, ',')
        ;[this.size, remainingString] = StrsConv.GetStrInt(remainingString, ',')
        ;[this.color, remainingString] = StrsConv.GetStrColor(remainingString, ',')
    }
}

// export class TPngImageList extends Array<TPngImage> {
//   AddImageRes(ResName: string): void {
//     throw new NotImplementedError('TPngImageList.AddImageRes');
//   }

//   Draw(canvas: TCanvas, index: number, x: number, y: number): void {
//     throw new NotImplementedError('TPngImageList.Draw');
//   }
// }

export class vclCanvas {
    public static ParseText(canvas: TCanvas, text: string, width: number): TStringDynArray {
        throw new NotImplementedError('vclCanvas.ParseText')
    }

    public static TextHeight(canvas: TCanvas, s: string, width: number): number {
        throw new NotImplementedError('vclCanvas.TextHeight')
    }

    public static TextHeightLines(canvas: TCanvas, s: string, width: number): number {
        throw new NotImplementedError('vclCanvas.TextHeightLines')
    }

    public static InRect(p: TPoint, R: TRect): boolean {
        throw new NotImplementedError('vclCanvas.InRect')
    }

    public static SetFontAngle(canvas: TCanvas, angle: number): void {
        throw new NotImplementedError('vclCanvas.SetFontAngle')
    }

    public static PaintHorzGradient(canvas: TCanvas, R: TRect, c1: TColor, c2: TColor): void {
        throw new NotImplementedError('vclCanvas.PaintHorzGradient')
    }

    public static PaintVertGradient(canvas: TCanvas, R: TRect, c1: TColor, c2: TColor): void {
        throw new NotImplementedError('vclCanvas.PaintVertGradient')
    }

    public static GetAntiColor(color: TColor): TColor {
        throw new NotImplementedError('vclCanvas.GetAntiColor')
    }

    public static AdjustTextWidth(canvas: TCanvas, s: string, width: number): string {
        throw new NotImplementedError('vclCanvas.AdjustTextWidth')
    }

    // public static PaintTextRect(canvas: TCanvas, R: TRect, alignment: TAlignment, text: string): void {
    //   throw new NotImplementedError('vclCanvas.PaintTextRect');
    // }

    // public static PaintMenuItem(Sender: TObject, ACanvas: TCanvas, ARect: TRect, State: TOwnerDrawState, MainMenu: TMainMenu, CbColor: TColor, PassiveToolBtns: TImageList, ActiveToolBtns: TImageList): void {
    //   throw new NotImplementedError('vclCanvas.PaintMenuItem');
    // }

    // public static PaintCellText(grid: TStringGrid, R: TRect, aCol: number, aRow: number, alignment: TAlignment): void {
    //   throw new NotImplementedError('vclCanvas.PaintCellText');
    // }

    // public static PaintCell(grid: TStringGrid, R: TRect, aCol: number, aRow: number, alignment: TAlignment, BackColor: TColor, TextColor: TColor): void {
    //   throw new NotImplementedError('vclCanvas.PaintCell');
    // }

    // public static PaintWideCell(grid: TStringGrid, R: TRect, left: number, s: string, BackColor: TColor, TextColor: TColor): void {
    //   throw new NotImplementedError('vclCanvas.PaintWideCell');
    // }

    public static GetColorName(color: TColor): string {
        throw new NotImplementedError('vclCanvas.GetColorName')
    }

    public static GetColorByName(s: string): TColor {
        if (s === 'Transparent') {
            return 'clNone' // Assuming 'clNone' is the equivalent of Delphi's clNone in TypeScript
        }

        for (const ColorName of ColorNames) {
            if (ColorName.name === s) {
                return ColorName.color.toString() // Assuming the color is stored as a number and needs to be converted to a string
            }
        }

        return 'clNone' // Default color if not found
    }

    // public static LoadGifRes(image: TImage, FileName: string, start: boolean, loop: boolean = true): void {
    //   throw new NotImplementedError('vclCanvas.LoadGifRes');
    // }

    // public static LoadPngRes(image: TImage, ResName: string): void {
    //   throw new NotImplementedError('vclCanvas.LoadPngRes');
    // }

    // public static LoadPngRes(image: TPngImage, ResName: string): void {
    //   throw new NotImplementedError('vclCanvas.LoadPngRes');
    // }

    // public static animate(image: TImage, flag: boolean, loop: boolean = true): void {
    //   throw new NotImplementedError('vclCanvas.animate');
    // }

    // public static ClearControls(control: TWinControl): void {
    //   throw new NotImplementedError('vclCanvas.ClearControls');
    // }
}

type ColorNameRecord = {
    name: string
    color: string
}

const ColorNames: ColorNameRecord[] = [
    { name: 'Black', color: '#000000' },
    { name: 'DarkGreen', color: '#006400' },
    { name: 'Navy', color: '#000080' },
    { name: 'DarkBlue', color: '#00008B' },
    { name: 'Green', color: '#008000' },
    { name: 'MidnightBlue', color: '#191970' },
    { name: 'Maroon', color: '#800000' },
    { name: 'DarkRed', color: '#8B0000' },
    { name: 'MediumBlue', color: '#0000CD' },
    { name: 'Indigo', color: '#4B0082' },
    { name: 'DarkSlateGray', color: '#2F4F4F' },
    { name: 'ForestGreen', color: '#228B22' },
    { name: 'Blue', color: '#0000FF' },
    { name: 'Teal', color: '#008080' },
    { name: 'DarkOliveGreen', color: '#556B2F' },
    { name: 'SaddleBrown', color: '#8B4513' },
    { name: 'DarkCyan', color: '#008B8B' },
    { name: 'Lime', color: '#00FF00' },
    { name: 'Purple', color: '#800080' },
    { name: 'DarkSlateBlue', color: '#483D8B' },
    { name: 'SeaGreen', color: '#2E8B57' },
    { name: 'Brown', color: '#A52A2A' },
    { name: 'FireBrick', color: '#B22222' },
    { name: 'Olive', color: '#808000' },
    { name: 'DarkMagenta', color: '#8B008B' },
    { name: 'OlivedRab', color: '#6B8E23' },
    { name: 'Red', color: '#FF0000' },
    { name: 'LimeGreen', color: '#32CD32' },
    { name: 'Sienna', color: '#A0522D' },
    { name: 'DimGray', color: '#696969' },
    { name: 'Crimson', color: '#DC143C' },
    { name: 'MediumSeaGreen', color: '#3CB371' },
    { name: 'DarkViolet', color: '#9400D3' },
    { name: 'LightSeaGreen', color: '#20B2AA' },
    { name: 'DarkGoldenRod', color: '#B8860B' },
    { name: 'SpringGreen', color: '#00FF7F' },
    { name: 'SteelBlue', color: '#4682B4' },
    { name: 'MediumVioletRed', color: '#C71585' },
    { name: 'SlateGray', color: '#708090' },
    { name: 'OrangeRed', color: '#FF4500' },
    { name: 'RoyalBlue', color: '#4169E1' },
    { name: 'Chocolate', color: '#D2691E' },
    { name: 'MediumSpringGreen', color: '#00FA9A' },
    { name: 'DarkTurquoise', color: '#00CED1' },
    { name: 'Gray', color: '#808080' },
    { name: 'SlateBlue', color: '#6A5ACD' },
    { name: 'DodgerBlue', color: '#1E90FF' },
    { name: 'BlueViolet', color: '#8A2BE2' },
    { name: 'LawnGreen', color: '#7CFC00' },
    { name: 'DarkOrchid', color: '#9932CC' },
    { name: 'CadetBlue', color: '#5F9EA0' },
    { name: 'LightSlateGray', color: '#778899' },
    { name: 'Chartreuse', color: '#7FFF00' },
    { name: 'DeepSkyBlue', color: '#00BFFF' },
    { name: 'IndianRed', color: '#CD5C5C' },
    { name: 'Peru', color: '#CD853F' },
    { name: 'YellowGreen', color: '#9ACD32' },
    { name: 'DarkOrange', color: '#FF8C00' },
    { name: 'DeepPink', color: '#FF1493' },
    { name: 'MediumSlateBlue', color: '#7B68EE' },
    { name: 'GoldenRod', color: '#DAA520' },
    { name: 'Tomato', color: '#FF6347' },
    { name: 'Orange', color: '#FFA500' },
    { name: 'MediumTurquoise', color: '#48D1CC' },
    { name: 'CornflowerBlue', color: '#6495ED' },
    { name: 'MediumAquamarine', color: '#66CDAA' },
    { name: 'MediumPurple', color: '#9370DB' },
    { name: 'Aqua', color: '#00FFFF' },
    { name: 'Cyan', color: '#00FFFF' },
    { name: 'Turquose', color: '#40E0D0' },
    { name: 'DarkSeaGreen', color: '#8FBC8F' },
    { name: 'MediumOrchid', color: '#BA55D3' },
    { name: 'RosyBrown', color: '#BC8F8F' },
    { name: 'PaleVioletRed', color: '#DB7093' },
    { name: 'Coral', color: '#FF7F50' },
    { name: 'DarkKhaki', color: '#BDB76B' },
    { name: 'GreenYellow', color: '#ADFF2F' },
    { name: 'DarkGray', color: '#A9A9A9' },
    { name: 'Magenta', color: '#FF00FF' },
    { name: 'Fuchsia', color: '#FF00FF' },
    { name: 'Gold', color: '#FFD700' },
    { name: 'LightCoral', color: '#F08080' },
    { name: 'Salmon', color: '#FA8072' },
    { name: 'DarkSalmon', color: '#E9967A' },
    { name: 'LightGreen', color: '#90EE90' },
    { name: 'SandyBrown', color: '#F4A460' },
    { name: 'Tan', color: '#D2B48C' },
    { name: 'Orchid', color: '#DA70D6' },
    { name: 'Yellow', color: '#FFFF00' },
    { name: 'HotPink', color: '#FF69B4' },
    { name: 'PaleGreen', color: '#98FB98' },
    { name: 'BurlyWood', color: '#DEB887' },
    { name: 'LightSalmon', color: '#FFA07A' },
    { name: 'SkyBlue', color: '#87CEEB' },
    { name: 'LightScyBlue', color: '#87CEFA' },
    { name: 'Silver', color: '#C0C0C0' },
    { name: 'Aquamarine', color: '#7FFFD4' },
    { name: 'LightSteelBlue', color: '#B0C4DE' },
    { name: 'Plum', color: '#DDA0DD' },
    { name: 'Violet', color: '#EE82EE' },
    { name: 'LigtBlue', color: '#ADD8E6' },
    { name: 'PowderBlue', color: '#B0E0E6' },
    { name: 'Thistle', color: '#D8BFD8' },
    { name: 'Khaki', color: '#F0E68C' },
    { name: 'LightGrey', color: '#D3D3D3' },
    { name: 'LightPink', color: '#FFB6C1' },
    { name: 'PaleTurquose', color: '#AFEEEE' },
    { name: 'PaleGoldenRod', color: '#EEE8AA' },
    { name: 'Wheat', color: '#F5DEB3' },
    { name: 'Pink', color: '#FFC0CB' },
    { name: 'Gainsboro', color: '#DCDCDC' },
    { name: 'NavajoWhite', color: '#FFDEAD' },
    { name: 'Peachpuff', color: '#FFDAB9' },
    { name: 'Moccasin', color: '#FFE4B5' },
    { name: 'Bisque', color: '#FFE4C4' },
    { name: 'BlanchedAlmond', color: '#FFEBCD' },
    { name: 'AntiqueWhite', color: '#FAEBD7' },
    { name: 'Lavender', color: '#E6E6FA' },
    { name: 'Mistyrose', color: '#FFE4E1' },
    { name: 'Papayawhop', color: '#FFEFD5' },
    { name: 'Beige', color: '#F5F5DC' },
    { name: 'LightGoldenRodYellow', color: '#FAFAD2' },
    { name: 'LemonChiffon', color: '#FFFACD' },
    { name: 'Linen', color: '#FAF0E6' },
    { name: 'LightCyan', color: '#E0FFFF' },
    { name: 'Cornsilk', color: '#FFF8DC' },
    { name: 'Oldlace', color: '#FDF5E6' },
    { name: 'HoneyDew', color: '#F0FFF0' },
    { name: 'WhiteSmoke', color: '#F5F5F5' },
    { name: 'LightYellow', color: '#FFFFE0' },
    { name: 'AlicemBlue', color: '#F0F8FF' },
    { name: 'SeaShell', color: '#FFF5EE' },
    { name: 'LavenderBlush', color: '#FFF0F5' },
    { name: 'FloralWhite', color: '#FFFAF0' },
    { name: 'Azure', color: '#F0FFFF' },
    { name: 'MintCream', color: '#F5FFFA' },
    { name: 'GhostWhite', color: '#FFF8F8' },
    { name: 'Snow', color: '#FFFAFA' },
    { name: 'Ivory', color: '#FFFFF0' },
    { name: 'White', color: '#FFFFFF' }
]
