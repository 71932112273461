import { FC, ReactNode } from 'react'

import { Flex } from '@fto/ui'

import styles from './index.module.scss'

type Props = {
    children: ReactNode
    onClick?: () => void
}

const HoverElement: FC<Props> = ({ children, onClick }) => {
    return (
        <Flex onClick={onClick} alignItems='center' justifyContent='center' className={styles.HoverElement}>
            {children}
        </Flex>
    )
}

export default HoverElement
