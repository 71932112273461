import { FC, MouseEvent, ReactNode } from 'react'
import cx from 'classnames'

import styles from './index.module.scss'

type HoverElementProps = {
    children: ReactNode
    onClick?: (e: MouseEvent<HTMLDivElement>) => void
    isSelected?: boolean
    classNames?: {
        root?: string
        selected?: string
    }
    size?: number
    withSelectedState?: boolean
}

export const HoverElement: FC<HoverElementProps> = ({
    children,
    onClick,
    isSelected,
    classNames = {},
    size = 24,
    withSelectedState = true
}) => {
    return (
        <div
            className={cx(styles.HoverIconElement, classNames.root, {
                [classNames.selected || styles.SelectedOptionState]: isSelected && withSelectedState
            })}
            style={{ width: size, height: size }}
            onClick={onClick}
        >
            {children}
        </div>
    )
}
