import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'

import { ColorPicker, Flex, Typography } from '@fto/ui'

import { ColorSettingType, OptionSettingRef } from '../../../types'
import { t } from 'i18next'
import { getStringValue, setStringValue } from '../../../utils'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

const ColorSetting = forwardRef<OptionSettingRef, ColorSettingType>(
    ({ tab, chart, option, applyToAll, onDifferentSettingsUpdate }, ref) => {
        const [selectedColor, setSelectedColor] = useState<string>(() =>
            getStringValue(chart, option.colorConfigType, option.colorKey)
        )

        const setColorValue = useCallback(
            (value: string, redraw = true, shouldUpdateConfig = true) => {
                setSelectedColor(value)

                shouldUpdateConfig && setStringValue(chart, option.colorConfigType, option.colorKey, value, applyToAll)

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [option, chart, applyToAll]
        )

        const handleColorChange = useCallback(
            (value: string) => {
                onDifferentSettingsUpdate(tab, value, selectedColor, option.colorKey)
                setColorValue(value)
            },
            [tab, option, selectedColor, setColorValue, onDifferentSettingsUpdate]
        )

        const loadOptionSetting = useCallback(
            (data: any, shouldUpdateConfig = true) => {
                setColorValue(data.color.value, false, shouldUpdateConfig)
            },
            [setColorValue]
        )

        useImperativeHandle(ref, () => ({ name: option.key, loadOptionSetting }), [option.key, loadOptionSetting])

        return (
            <Flex gap={4} justifyContent='space-between' alignItems='center'>
                <Typography>{t(option.name)}</Typography>
                <ColorPicker color={selectedColor} onColorChange={handleColorChange} withOpacity={false} />
            </Flex>
        )
    }
)

export default ColorSetting
