import { BasicPaintToolJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { PaintToolNames } from '../PaintToolNames'
import { TRectPaintTool } from './ptRect'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TChart } from '../../chart_classes/BasicChart'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TPtEllipse extends TRectPaintTool {
    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptEllipse)

        this.fClosedPolygon = true
        this.CursorStyle = CustomCursorPointers.crCursorEllipse
        this.icon = 59

        // this.fEditDialog = DelphiFormsBuiltIns.EllipseFrm; // Replace with actual form type
        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptEllipse)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptEllipse)
        }

        if (!styles) throw new StrangeError('Default styles for Ellipse are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
        this.fShouldFillInside = styles.fShouldFillInside
        this.brush = IGPSolidBrush.fromSerialized(styles.fillingStyle)
    }

    toJson(): BasicPaintToolJSON {
        return super.toJson()
    }

    fromJSON(json: BasicPaintToolJSON) {
        super.fromJSON(json)
    }

    public Paint(): void {
        if (this.fHighlighted) {
            this.PaintHoverLine()
        }
        if (this.fSelected && this.fPoints.Count === 4) {
            this.paintFrame()
        }
        super.Paint()
    }

    protected PaintLines(): void {
        // if the number of points is less than 2, then exit the function
        if (this.fPoints.Count < 2) return

        // Draw the ellipse
        this.fChart.GdiCanvas.ellipse(this.GetFrameRect(), this.fLineStyle.getPen())
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },

            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            fillInsideColor: {
                key: 'fillInsideColor',
                value: {
                    color: ColorHelperFunctions.BasicColor(this.brush.getColor()),
                    isActive: this.fShouldFillInside,
                    opacity: this.brush.getOpacity()
                },
                label: 'background',
                withOpacity: true,
                type: 'fillColor',
                isOptional: true
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, { toolType: PaintToolNames.ptEllipse, toolName: 'ellipse' })
    }

    ImportFromDialog(): void {
        const { resetToolSettings, getKeyValueData } = GraphToolStore // Use the store/context

        const formattedToolSettings = getKeyValueData()

        //@ts-ignore
        const { description, lineStyle, fillInsideColor } = formattedToolSettings

        this.chart.ChartWindow.saveStateWithNotify()

        this.description = description

        this.fLineStyle = lineStyle

        this.fShouldFillInside = fillInsideColor.isActive

        // Set color and opacity
        this.brush.setColor(fillInsideColor.color)
        this.brush.setOpacity(fillInsideColor.opacity)

        this.saveToManager()
        resetToolSettings()
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }
    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptEllipse, {
            toolName: PaintToolNames.ptEllipse,
            lineStyle: this.fLineStyle.getSerialized(),
            fillingStyle: this.brush.getSerialized(),
            fShouldFillInside: this.fShouldFillInside
        })
    }

    protected FillInside(): void {
        // if the number of points is less than 2 or fFillInside is false, then exit the function
        if (this.fPoints.Count < 2 || !this.fShouldFillInside) return
        // Fill the ellipse with the current brush
        this.fChart.GdiCanvas.FillEllipse(this.GetFrameRect(), this.brush)
    }

    public PaintHoverLine(): void {
        const context = this.fChart.GdiCanvas.graphics.Context
        const R: TRect = this.GetFrameRect()
        if (context) {
            // Original ellipse parameters
            const centerX = R.Left + R.Width / 2
            const centerY = R.Top + R.Height / 2
            const radiusX = R.Width / 2
            const radiusY = R.Height / 2

            // Set style for highlight
            context.strokeStyle = this.fHighlightLinesColor
            context.lineWidth = 10 // Set constant line width

            // Draw ellipse
            context.beginPath()
            context.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI)
            context.stroke()
        }
    }

    clone(): TPtEllipse {
        const cloneObj = new TPtEllipse(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        return cloneObj
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptEllipse, TPtEllipse)
