import { TBasicPaintTool } from '../../../charting/paint_tools/BasicPaintTool'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { TOffsStringList } from '../../../ft_types/common/OffsStringList'
import { TNoExactMatchBehavior } from '../../../ft_types/data/chunks/ChunkEnums'
import { TSearchMode } from '../../../ft_types/data/data_arrays/FXDataArrays'
import { NotImplementedError } from '../../../utils/common_utils'
import { TChart } from '../../chart_classes/BasicChart'
import { PaintToolNames } from '../PaintToolNames'
import { TPaintToolStatus, TPaintToolType, TPointInfo, TPointsArr } from '../PaintToolsAuxiliaryClasses'
import { RegressionChannelJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TPoint } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TPtRegressionChannel extends TBasicPaintTool {
    private fRays: boolean
    private pts: TPointsArr

    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptRegressionChannel)
        this.ShortName = 'Regression Channel'
        this.fToolType = TPaintToolType.tt_Line
        this.fMaxPoints = 2
        this.fClosedPolygon = false
        this.CursorStyle = CustomCursorPointers.crCursorFiboChannel
        this.fRays = false
        this.icon = 56
        // this.fEditDialog = RegressionChannelFrm;

        this.pts = new TPointsArr()
        for (let i = 0; i <= 5; i++) {
            this.pts.add(new TPointInfo(0, 0))
        }

        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptRegressionChannel)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptRegressionChannel)
        }

        if (!styles) throw new StrangeError('Default styles for Regression Channel are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
        this.fRays = styles.rays
    }

    clone(): TPtRegressionChannel {
        const cloneObj = new TPtRegressionChannel(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        cloneObj.fRays = this.fRays
        cloneObj.pts = this.pts.clone()
        return cloneObj
    }

    toJson(): RegressionChannelJSON {
        const baseJson = super.toJson()

        return {
            ...baseJson,
            fRays: this.fRays,
            pts: this.pts.map((point) => ({ x: point.x, y: point.y, price: point.price, time: point.time }))
        }
    }

    fromJSON(json: RegressionChannelJSON): void {
        super.fromJSON(json)
        this.fRays = json.fRays
        this.pts = new TPointsArr()
        for (const point of json.pts) {
            const newPoint = new TPointInfo(point.time, point.price)
            newPoint.price = point.price
            newPoint.time = point.time

            this.pts.add(newPoint)
        }
    }

    public GetChannelParams(): boolean {
        let i: number, x: number
        let y: number, z: number, sum_x: number, sum_y: number, max: number, sum_xy: number, sum_x2: number
        let result = false

        this.pts[0].time = Math.min(this.fPoints[0].time, this.fPoints[1].time)
        this.pts[1].time = Math.max(this.fPoints[0].time, this.fPoints[1].time)

        const fChart = this.chart as TChart

        sum_x = 0
        sum_y = 0
        sum_xy = 0
        sum_x2 = 0

        const index1 = fChart.GetGlobalIndexByDate(
            this.pts[0].time,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            true
        )
        const index2 = fChart.GetGlobalIndexByDate(
            this.pts[1].time,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            true
        )
        if (index1 >= index2) {
            return result
        }

        i = index2
        const period = index2 - index1 + 1
        for (x = 0; x < period; x++) {
            const barCloseValue = fChart.Bars.GetValue(i, TSearchMode.sm_Close)
            if (!barCloseValue) {
                i--
                continue
            }

            y = barCloseValue
            sum_x += x
            sum_y += y
            sum_xy += x * y
            sum_x2 += x * x
            i--
        }

        // Calculating regression line
        const b = (period * sum_xy - sum_x * sum_y) / (period * sum_x2 - sum_x * sum_x)
        const a = (sum_y - b * sum_x) / period

        // Calculating channel height
        i = index2
        max = 0
        for (x = 0; x < period; x++) {
            y = a + b * x
            if (!fChart.Bars) throw new StrangeError('Bars not defined')
            const barCloseValue = fChart.Bars.GetValue(i, TSearchMode.sm_Close)
            if (!barCloseValue) {
                i--
                continue
            }
            z = Math.abs(barCloseValue - y)
            if (z > max) {
                max = z
            }
            i--
        }

        const x1 = fChart.GetX(index1)
        const x2 = fChart.GetX(index2)

        // Calculate channel points
        this.pts[0].price = a + b * (period - 1)
        this.pts[0].x = x1

        this.pts[1].price = a
        this.pts[1].x = x2

        this.pts[2].time = this.pts[0].time
        this.pts[2].price = this.pts[0].price + max
        this.pts[2].x = x1

        this.pts[3].time = this.pts[1].time
        this.pts[3].price = this.pts[1].price + max
        this.pts[3].x = x2

        this.pts[4].time = this.pts[0].time
        this.pts[4].price = this.pts[0].price - max
        this.pts[4].x = x1

        this.pts[5].time = this.pts[1].time
        this.pts[5].price = this.pts[1].price - max
        this.pts[5].x = x2

        for (i = 0; i <= 5; i++) {
            this.pts[i].y = fChart.GetY(this.pts[i].price)
        }

        this.fPoints[0].assign(this.pts[0])
        this.fPoints[1].assign(this.pts[1])

        result = true
        return result
    }

    private AdjustRays(): void {
        const firstCoords = this.fChart.GetRayCoords(
            this.pts[0].x,
            this.pts[0].y,
            this.pts[1].x,
            this.pts[1].y,
            this.pts[0].price,
            this.pts[1].price
        ) as unknown as [number, number, number, number]
        const secondCoords = this.fChart.GetRayCoords(
            this.pts[2].x,
            this.pts[2].y,
            this.pts[3].x,
            this.pts[3].y,
            this.pts[2].price,
            this.pts[3].price
        ) as unknown as [number, number, number, number]
        const thirdCoords = this.fChart.GetRayCoords(
            this.pts[4].x,
            this.pts[4].y,
            this.pts[5].x,
            this.pts[5].y,
            this.pts[4].price,
            this.pts[5].price
        ) as unknown as [number, number, number, number]

        this.pts[0].x = firstCoords[0]
        this.pts[0].y = firstCoords[1]
        this.pts[1].x = firstCoords[2]
        this.pts[1].y = firstCoords[3]

        this.pts[2].x = secondCoords[0]
        this.pts[2].y = secondCoords[1]
        this.pts[3].x = secondCoords[2]
        this.pts[3].y = secondCoords[3]

        this.pts[4].x = thirdCoords[0]
        this.pts[4].y = thirdCoords[1]
        this.pts[5].x = thirdCoords[2]
        this.pts[5].y = thirdCoords[3]
    }

    public LoadFromList(list: TOffsStringList, all = true): void {
        throw new NotImplementedError()
    }

    public SaveToList(list: TOffsStringList, all = true): void {
        throw new NotImplementedError()
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            shouldShowRays: {
                value: this.fRays,
                type: 'checkbox',
                key: 'shouldShowRays',
                label: 'toolsModal.fields.rays'
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptRegressionChannel,
            toolName: 'regressionChannel'
        })
    }

    ImportFromDialog(): void {
        const { resetToolSettings, getKeyValueData } = GraphToolStore // Use the store/context

        const formattedToolSettings = getKeyValueData()

        //@ts-ignore
        const { description, shouldShowRays, lineStyle, levels } = formattedToolSettings

        this.chart.ChartWindow.saveStateWithNotify()

        this.description = description

        this.fRays = shouldShowRays

        this.fLineStyle = lineStyle

        this.saveToManager()
        resetToolSettings()
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }
    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptRegressionChannel, {
            lineStyle: this.fLineStyle.getSerialized(),
            rays: this.fRays,
            toolName: PaintToolNames.ptRegressionChannel
        })
    }

    public Paint(): void {
        if (this.fPoints.length < 2) return

        const canvas = this.fChart.GdiCanvas
        const R = this.chartPaintRect
        if (this.fHighlighted) {
            const points1 = [new TPoint(this.pts[0].x, this.pts[0].y), new TPoint(this.pts[1].x, this.pts[1].y)]
            const points2 = [new TPoint(this.pts[2].x, this.pts[2].y), new TPoint(this.pts[3].x, this.pts[3].y)]
            const points3 = [new TPoint(this.pts[4].x, this.pts[4].y), new TPoint(this.pts[5].x, this.pts[5].y)]
            this.PaintHoverLine(points1)
            this.PaintHoverLine(points2)
            this.PaintHoverLine(points3)
        }
        if (!this.GetChannelParams()) {
            canvas.MoveTo(this.fPoints[0].x, R.Top)
            canvas.LineTo(this.fPoints[0].x, R.Bottom, this.fLineStyle.getPen())
            this.PaintMarkers()
            return
        }

        if (this.fStatus !== TPaintToolStatus.ts_Completed || this.fSelected) {
            // paint vertical lines
            canvas.MoveTo(this.pts[0].x, R.Top)
            canvas.LineTo(this.pts[0].x, R.Bottom, this.fLineStyle.getPen())
            canvas.MoveTo(this.pts[1].x, R.Top)
            canvas.LineTo(this.pts[1].x, R.Bottom, this.fLineStyle.getPen())
        }

        if (this.fRays) this.AdjustRays()

        // paint channel lines
        canvas.MoveTo(this.pts[0].x, this.pts[0].y)
        canvas.LineTo(this.pts[1].x, this.pts[1].y, this.fLineStyle.getPen())
        canvas.MoveTo(this.pts[2].x, this.pts[2].y)
        canvas.LineTo(this.pts[3].x, this.pts[3].y, this.fLineStyle.getPen())
        canvas.MoveTo(this.pts[4].x, this.pts[4].y)
        canvas.LineTo(this.pts[5].x, this.pts[5].y, this.fLineStyle.getPen())

        this.PaintMarkers()
    }

    public get visible(): boolean {
        if (this.fPoints.length < 2) {
            return false
        }

        // if not(GetChannelParams) then
        if (!this.GetChannelParams()) {
            return this.fPoints[0].x >= this.chartPaintRect.Left && this.fPoints[0].x <= this.chartPaintRect.Right
        }

        // if fRays then
        if (this.fRays) {
            this.AdjustRays()
        }

        return (
            this.fChart.IsLineVisible(this.pts[0].x, this.pts[0].y, this.pts[1].x, this.pts[1].y) ||
            this.fChart.IsLineVisible(this.pts[2].x, this.pts[2].y, this.pts[3].x, this.pts[3].y) ||
            this.fChart.IsLineVisible(this.pts[4].x, this.pts[4].y, this.pts[5].x, this.pts[5].y)
        )
    }

    public assign(tool: TBasicPaintTool, isCopy = false): void {
        super.assign(tool, isCopy)
        if (tool instanceof TPtRegressionChannel) {
            this.fRays = tool.fRays
        }
    }

    public EdgeUnderMouse(x: number, y: number): number {
        // Check if the channel parameters can be obtained
        if (!this.GetChannelParams()) {
            return -1
        }

        // Adjust the rays if the fRays property is true
        if (this.fRays) {
            this.AdjustRays()
        }

        // TODO: Implement or verify existence of PointAboveTheLine
        if (this.PointAboveTheLine(x, y, this.pts[0].x, this.pts[0].y, this.pts[1].x, this.pts[1].y)) {
            return 0
        } else {
            return -1
        }
    }
}

export class ptRegressionChannel {
    public static someFunction(): void {
        throw new NotImplementedError()
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptRegressionChannel, TPtRegressionChannel)
