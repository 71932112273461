import React, { ChangeEvent, ComponentPropsWithoutRef, forwardRef, useCallback, useId, useRef } from 'react'

import cx from 'classnames'

import { Flex, InputControl, InputControlProps } from '@fto/ui'
import { Icon } from '@fto/icons'
import { mergeRefs } from '@fto/ui/lib/utils/mergeRefs'

import styles from './index.module.scss'

export const checkboxVariants = ['default'] as const

const getIconName = (
    variant: (typeof checkboxVariants)[number],
    checked: boolean,
    error: boolean,
    disabled: boolean
) => {
    if (error) {
        return ICON_NAME_MAP['unselected']
    }
    if (checked && !disabled) {
        return ICON_NAME_MAP[variant]
    }
    if (disabled && checked) {
        return ICON_NAME_MAP['disabled']
    }

    return ICON_NAME_MAP['unselected']
}

interface Props {
    variant?: (typeof checkboxVariants)[number]
    checked?: boolean
    onChange?: (value: boolean, e: ChangeEvent<HTMLInputElement>) => void | undefined
    disabled?: boolean
    error?: boolean
    label?: string | React.ReactNode
    size?: number
    inputControlVariant?: InputControlProps['variant']
}

type OtherNativeInputProps = Omit<ComponentPropsWithoutRef<'input'>, keyof Props>
export type CheckboxProps = Omit<InputControlProps, 'variant'> & OtherNativeInputProps & Props

const ICON_NAME_MAP = {
    // Selected States
    default: 'checkbox-selected',
    unselected: 'checkbox-unselected',
    disabled: 'checkbox-disabled'
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            id,
            variant = 'default',
            checked = false,
            onChange,

            disabled = false,

            inputControlVariant = 'column',
            label,
            helperText,
            success,
            error = false,
            required,
            block,

            children,
            size = 18,
            className,
            ...rest
        },
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement>(null)
        const _id = useId()

        const localId = id || _id
        const handleChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                e.stopPropagation()
                onChange?.(!checked, e)
            },
            [checked, onChange]
        )

        return (
            <InputControl
                id={localId}
                variant={inputControlVariant}
                success={success}
                error={error}
                required={required}
                label={label}
                block={block}
                helperText={helperText}
                flexGrow={0}
                flexShrink={0}
                className={cx(styles.label, className)}
            >
                <Flex direction='row' alignItems='center' gap={8}>
                    <span className={styles.iconBlock} tabIndex={disabled ? -1 : 1}>
                        <input
                            {...rest}
                            ref={mergeRefs(ref, inputRef)}
                            id={localId}
                            type='checkbox'
                            checked={checked}
                            disabled={disabled}
                            className={styles.input}
                            onChange={handleChange}
                            readOnly
                        />
                        <Icon
                            //@ts-ignore
                            name={getIconName(variant, checked, error, disabled)}
                            className={cx(styles.icon, {
                                [styles[`icon-${variant}`]]: checked && !error,
                                [styles.disabled]: disabled
                            })}
                            size={size}
                            onClick={() => {
                                onChange && inputRef.current?.click()
                            }}
                        />
                    </span>
                    {children && (
                        <span className={cx(styles.textBlock, { [styles.disabledColor]: disabled })}>{children}</span>
                    )}
                </Flex>
            </InputControl>
        )
    }
)

Checkbox.displayName = 'Checkbox'

export default Checkbox
