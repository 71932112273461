import { OrderLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/OrderLevel'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import { TPoint, TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { ChartControl, ChartControlParams, LocationParams } from '@fto/chart_components/ChartControl'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'

import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TCursor } from '@fto/lib/ft_types/common/CursorPointers'
import { ModifyBtn } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/ModifyBtn'
import { formatCurrency } from '@fto/lib/utils/ordersUtils'
import { CanvasLayer, LayerType } from '@fto/lib/charting/auxiliary_classes_charting/Layers/Layers'

export class MarketLevel extends OrderLevel {
    public infoBoxLocation: TRect = new TRect(0, 0, 0, 0)
    public modifyBtn: ChartControl | null = null

    draw(canvas: TGdiPlusCanvas): void {
        const ctx = canvas.graphics.Context
        const { Top } = this.getLocation()
        const isActive = this.IsCaptured() || this.isMouseInside() || this.isSelected
        let baseColor = this.isValid ? this.chartWindow.ChartOptions.ColorScheme.SellMarkerColor : this.invalidColor

        if (this.owner.PosType === TTradePositionType.tp_Buy) {
            baseColor = this.isValid ? this.chartWindow.ChartOptions.ColorScheme.BuyMarkerColor : this.invalidColor
        }

        let labelText = ''

        switch (this.owner.PosType) {
            case TTradePositionType.tp_Buy: {
                labelText = isActive ? 'Buy' : 'B'
                break
            }
            case TTradePositionType.tp_Sell: {
                labelText = isActive ? 'Sell' : 'S'
                break
            }
        }

        const valueText = this.owner.tpos.profit?.toFixed(this.owner.symbol?.symbolInfo.fDecimals) || ''
        const pointsText = `${formatCurrency(this.owner.tpos.ProfitPips, '', 0) || ''} points`
        const ticketText = `#${this.owner.tpos.ticket}`
        const font = '12px Roboto Flex'

        const endX = this.drawCommonElements(
            canvas,
            this.getXCoordinateToDrawLevel(ctx),
            Top,
            labelText,
            formatCurrency(Number(valueText)),
            pointsText,
            ticketText,
            isActive,
            baseColor,
            16,
            font,
            false,
            false
        )

        this.drawActionsButtons(canvas, isActive, endX, Top, baseColor)
    }

    drawActionsButtons(canvas: TGdiPlusCanvas, isActive: boolean, endX: number, Top: number, baseColor: string): void {
        if (isActive) {
            if (this.modifyBtn && this.modifyBtn instanceof ModifyBtn) {
                this.modifyBtn.setLocation(new TRect(endX, Top - 8, endX + 20, Top + 8))
                this.modifyBtn.mainColor = baseColor
            }
            this.drawModifyBtn(canvas)

            if (this.closeBtn) {
                this.closeBtn.setLocation(new TRect(endX + 22, Top - 8, endX + 40, Top + 8))
                this.closeBtn.mainColor = baseColor
            }

            this.drawCloseBtn(canvas)
        }
    }

    drawModifyBtn(canvas: TGdiPlusCanvas): void {
        if (this.modifyBtn) {
            this.modifyBtn.draw(canvas)
        } else {
            this.modifyBtn = new ModifyBtn(
                new ChartControlParams(
                    new CanvasLayer(
                        this.chartWindow.MainChart.HTML_Canvas,
                        this.chartWindow,
                        LayerType.ENTIRE_CHART_BLOCKING,
                        'ModifyBtn'
                    ),
                    new LocationParams(0, 0, 0, 0)
                ),
                this
            )
            this.modifyBtn.draw(canvas)
        }
    }

    onMouseDown(event: MouseEvent, sender: TMainChart): ChartControl | null {
        this.modifyBtn?.onMouseDown(event, sender)
        //TODO: should we remove this? super has the same
        this.closeBtn?.onMouseDown(event, sender)
        return super.onMouseDown(event, sender)
    }

    close(): void {
        GlobalProcessingCore.ProcessingCore.CloseOrder(this.owner.tpos.ticket)
        this.owner.reset()
        GlobalChartsController.Instance.updateCharts()
    }

    onMouseMove(event: MouseEvent, sender: TMainChart): ChartControl | null {
        this.modifyBtn?.onMouseMove(event, sender)

        if (this.isMouseInsideInfoBox(new TPoint(event.clientX, event.clientY)) && !this.IsCaptured()) {
            ;(sender.ChartWindow as TChartWindow).SetCursor(TCursor.crHandPoint)
            return this
        }

        return super.onMouseMove(event, sender)
    }
}
