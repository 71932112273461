import { UNIX_DATE_MIN, UNIX_DATE_SEC_OVER_MAX } from '../../DataConstants'
import { TChunkMapStatus } from '../../TChunkMapStatus'
import { TBasicChunkedArray } from './BasicChunkedArray'
import { DateUtils, TDateTime } from '../../../../delphi_compatibility/DateUtils'
import GlobalProjectInfo from '../../../../globals/GlobalProjectInfo'
import TDownloadableChunk from '../../chunks/DownloadableChunk/DownloadableChunk'
import { TDataArrayEvents } from '../../data_downloading/DownloadRelatedEnums'
import { TNoExactMatchBehavior } from '../../chunks/ChunkEnums'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { NotImplementedError } from '@fto/lib/utils/common_utils'
import DataNotDownloadedYetError from '../../data_errors/DataUnavailableError'
import CommonConstants from '@fto/lib/ft_types/common/CommonConstants'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { TDataRecordWithDate } from '../../DataClasses/TDataRecordWithDate'

interface TChunkRange {
    firstDate: TDateTime
    lastDate: TDateTime
}

class TListOfQueuedChunkRanges extends Array<TChunkRange> {
    constructor() {
        super()
    }

    public ContainsChunkRange(firstDate: TDateTime, lastDate: TDateTime): boolean {
        for (const range of this) {
            if (range.firstDate === firstDate && range.lastDate >= lastDate) {
                return true
            }
        }
        return false
    }
}

//TODO: merge with BarsArray??
export abstract class TMappedChunkedArray<
    T extends TDataRecordWithDate,
    C extends TDownloadableChunk<T>
> extends TBasicChunkedArray<T, C> {
    private fQueuedChunkRanges = new TListOfQueuedChunkRanges()

    protected fChunkMapStatus: TChunkMapStatus = TChunkMapStatus.cms_Empty
    private fLastItemGlobalIndex = 0

    protected abstract StartDownloadingMap(aStartIndex: number, aEndIndex: number): void

    public get ChunkMapStatus(): TChunkMapStatus {
        return this.fChunkMapStatus
    }

    public get FullMapDownloaded(): boolean {
        return this.fChunkMapStatus === TChunkMapStatus.cms_Loaded
    }

    public GetDateByGlobalIndex(
        globalIndex: number,
        approximationAllowed: boolean,
        downloadChunkIfEmpty = true
    ): TDateTime {
        if (globalIndex >= 0 && globalIndex <= this.LastPossibleIndexInHistory) {
            const item = this.GetItemByGlobalIndex(globalIndex, downloadChunkIfEmpty)
            if (item) {
                return item.DateTime
            }
        }
        //we did not find the item, so let's try to approximate

        if (approximationAllowed) {
            return this.ApproximateDateByGlobalIndex(globalIndex)
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `empty date returned from GetDateByGlobalIndex ${this.DName} ${globalIndex}`
            )
            return DateUtils.EmptyDate
        }
    }

    public ItemExists(index: number): boolean {
        return this.IsIndexValid(index) && this.GetItemByGlobalIndex(index, false) !== null
    }

    public get LastItemInTesting(): T | null {
        const lastIndex = this.GetGlobalIndexByDate(
            GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true), //allow approximate? probably yes since we will be looking for nemb_ReturnNearestLower
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            false
        )
        return this.GetItemByGlobalIndex(lastIndex)
    }

    public GetMaxGlobalIndex(): number {
        if (this.ChunkMapStatus !== TChunkMapStatus.cms_Loaded) {
            throw new DataNotDownloadedYetError(
                `GetChunksForRangeDates - Chunk map is not loaded yet for ${this.DName}`
            )
        }

        if (this.fChunks.Count === 0) {
            return 0
        }

        return this.fChunks.LastItem.FirstGlobalIndex + this.fChunks.LastItem.Count
    }

    //do not confuse with LastDate in testing
    public LastPossibleDate(): TDateTime {
        if (this.ChunkMapStatus !== TChunkMapStatus.cms_Loaded) {
            throw new DataNotDownloadedYetError(
                `GetChunksForRangeDates - Chunk map is not loaded yet for ${this.DName}`
            )
        }

        if (
            this.fChunkMapStatus === TChunkMapStatus.cms_Empty ||
            this.fChunkMapStatus === TChunkMapStatus.cms_Loading ||
            this.GetMaxGlobalIndex() === 0
        ) {
            return DateUtils.EmptyDate
        }

        const lastChunk = this.fChunks.LastItem
        if (!lastChunk) {
            return DateUtils.EmptyDate
        }

        return lastChunk.LastPossibleDate
    }

    public GetChunksForRangeDates(firstDate: TDateTime, lastDate: TDateTime): C[] {
        if (this.ChunkMapStatus !== TChunkMapStatus.cms_Loaded) {
            throw new DataNotDownloadedYetError(
                `GetChunksForRangeDates - Chunk map is not loaded yet for ${this.DName}`
            )
        }

        if (DateUtils.IsEmpty(firstDate) || DateUtils.IsEmpty(lastDate)) {
            throw new NotImplementedError('GetChunksForRangeDates - case with empty dates is not implemented yet')
        }

        //TODO: optimize: use GetChunkIndexByDate instead of GetChunkByDate and then IndexOf
        let firstChunkIndex = this.GetChunkIndexByDate(firstDate)

        if (firstChunkIndex === CommonConstants.EMPTY_INDEX) {
            //probably we are trying to get chunks before the start of available data, so let's start from first chunk ever
            firstChunkIndex = 0
        }

        const result = []
        for (let i = firstChunkIndex; i < this.fChunks.Count; i++) {
            const chunk = this.fChunks[i]

            if (chunk.FirstDate <= lastDate) {
                result.push(chunk)
            } else {
                break
            }
        }
        return result
    }

    public GetChunksForRangeIndexes(firstDataIndex_param: number, lastDataIndex_param: number, fitToRange = true): C[] {
        if (this.ChunkMapStatus !== TChunkMapStatus.cms_Loaded) {
            throw new DataNotDownloadedYetError(
                `GetChunksForRangeIndexes - Chunk map is not loaded yet for ${this.DName}`
            )
        }

        if (
            !fitToRange &&
            (firstDataIndex_param < 0 ||
                lastDataIndex_param < 0 ||
                firstDataIndex_param > this.GetMaxGlobalIndex() ||
                lastDataIndex_param > this.GetMaxGlobalIndex())
        ) {
            throw new StrangeError(
                `GetChunksForRangeIndexes - invalid input data ${firstDataIndex_param}-${lastDataIndex_param}`
            )
        }

        const firstDataIndex = Math.max(0, firstDataIndex_param)
        const lastDataIndex = Math.min(lastDataIndex_param, this.GetMaxGlobalIndex())

        const result = []
        const indexOfChunkContainingFirstDataIndex = this.GetChunkIndexByDataIndex(firstDataIndex)
        if (indexOfChunkContainingFirstDataIndex === CommonConstants.EMPTY_INDEX) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `First chunk was not found. GetChunksForRangeIndexes ${firstDataIndex}-${lastDataIndex} ${this.DName}`
            )
        } else {
            for (let i = indexOfChunkContainingFirstDataIndex; i < this.fChunks.Count; i++) {
                const chunk = this.fChunks[i]
                if (chunk.FirstGlobalIndex <= lastDataIndex) {
                    result.push(chunk)
                } else {
                    break
                }
            }
        }
        return result
    }

    public EnsureChunksForRangeLoadedOrLoading(firstDate: TDateTime, lastDate: TDateTime): void {
        if (this.fChunkMapStatus === TChunkMapStatus.cms_Loaded) {
            const chunks = this.GetChunksForRangeDates(firstDate, lastDate)
            for (const chunk of chunks) {
                chunk.EnsureDataIsPresentOrDownloading()
            }
        } else {
            this.QueueDownloadingChunksForRange(firstDate, lastDate)
        }
    }

    public StartDownloadingFullMap(): void {
        this.StartDownloadingMap(UNIX_DATE_MIN, UNIX_DATE_SEC_OVER_MAX)
    }

    protected QueueDownloadingChunksForRange(firstDate: TDateTime, lastDate: TDateTime): void {
        if (!this.fQueuedChunkRanges.ContainsChunkRange(firstDate, lastDate)) {
            this.Events.on(TDataArrayEvents.de_MapDownloaded, this.boundDownloadQueuedChunks)
            this.fQueuedChunkRanges.push({
                firstDate: firstDate,
                lastDate: lastDate
            })
        }
    }

    private boundDownloadQueuedChunks = this.DownloadQueuedChunks.bind(this)
    private DownloadQueuedChunks(): void {
        for (const range of this.fQueuedChunkRanges) {
            if (DateUtils.IsValidDateRange(range.firstDate, range.lastDate)) {
                this.EnsureChunksForRangeLoadedOrLoading(range.firstDate, range.lastDate)
            }
        }
    }

    public InitMapIfNecessary(): void {
        switch (this.fChunkMapStatus) {
            case TChunkMapStatus.cms_Empty: {
                this.fChunkMapStatus = TChunkMapStatus.cms_Loading
                this.StartDownloadingFullMap() //download full map in any case
                break
            }
            case TChunkMapStatus.cms_Loading: {
                // do not need to do anything, just wait for the map to be loaded
                break
            }
            case TChunkMapStatus.cms_Loaded: {
                //we already have the map
                break
            }
            default: {
                throw new StrangeError('Unknown chunk map status')
            }
        }
    }
}
