import { TBasicPaintTool } from '../BasicPaintTool'

class PaintToolsListMemento {
    private tools: TBasicPaintTool[]

    constructor(tools: TBasicPaintTool[]) {
        this.tools = tools
    }

    getTools(): TBasicPaintTool[] {
        return [...this.tools]
    }
}

export default PaintToolsListMemento
