import React, { FC, useCallback, useState, useMemo } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { Button, Flex, InputField, Separator, Typography, EmptyState } from '@fto/ui'

import { RibbonOptionType } from '../types'

import styles from '../../../../common.module.scss'

type Props = { options: RibbonOptionType[]; close: () => void; name: string; withDropdownsSearch?: boolean }

const InnerDropdownMenu: FC<Props> = ({ options, close, name, withDropdownsSearch }) => {
    const [searchValue, setSearchValue] = useState('')

    const { t } = useTranslation()

    const handleAction = useCallback(
        (action: (() => void) | null) => {
            action?.()
            close()
        },
        [close]
    )

    const filteredOptions = useMemo(() => {
        if (!withDropdownsSearch || searchValue === '') {
            return options
        }

        return options.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
    }, [options, searchValue, withDropdownsSearch])

    return (
        <Flex direction='column' className={styles.dropdown} gap={4}>
            <Typography className={styles.text} color='gray-600' type='tiny-regular'>
                {name}
            </Typography>
            {withDropdownsSearch && (
                <InputField
                    id='dd-search-input'
                    value={searchValue}
                    onChange={setSearchValue}
                    reset
                    suffix={
                        <Flex alignItems='center' gap={8}>
                            {searchValue && (
                                <>
                                    <Separator width='1px' height='24px' color='gray-500' />
                                </>
                            )}
                            <Icon name='search' size={18} />
                        </Flex>
                    }
                    placeholder={t('general.search')}
                />
            )}
            <div className={styles.OptionsWrapper}>
                {filteredOptions.map((option) => {
                    return (
                        <Button
                            block
                            classNames={{ content: cx(styles.buttonContent, { [styles.selected]: option.isSelected }) }}
                            key={option.value}
                            type='tetriatry-white'
                            size='compact'
                            label={option.name}
                            onClick={() => handleAction(option.action)}
                        />
                    )
                })}
                {searchValue !== '' && filteredOptions.length === 0 && (
                    <EmptyState
                        classNames={{ root: styles.EmptyStateWrapper }}
                        title={t('general.noResultsFound')}
                        description={t('general.anyStringMatching')}
                    />
                )}
            </div>
        </Flex>
    )
}

export default InnerDropdownMenu
