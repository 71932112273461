import { FC } from 'react'
import cx from 'classnames'

import { Flex } from '@fto/ui'

import styles from './common.module.scss'

type Props = {
    active: boolean
    onClick: () => void
    name: string
}

const Tab: FC<Props> = ({ active, onClick, name }) => {
    return (
        <Flex
            block
            alignItems='center'
            justifyContent='center'
            className={cx(styles.tab, {
                [styles.activeTab]: active
            })}
            onClick={onClick}
        >
            {name}
        </Flex>
    )
}
export default Tab
