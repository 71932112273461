import { SymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/SymbolCalculationStrategy'
import { TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class IndexSymbolCalculationStrategy extends SymbolCalculationStrategy {
    public getPointCostForOneStandardLot(operationType: TTradePositionType): number {
        return this.symbolData.symbolInfo.lot * this.symbolData.symbolInfo.MinPoint
    }

    public calculateMarginRequirementForSymbol(
        operationType: TTradePositionType,
        openPrice: number | undefined
    ): number {
        const symbolContractSize = this.symbolData.symbolInfo.lot
        const leverage = this.symbolData.symbolInfo.CreditShoulder
        const price = openPrice || this.symbolData.getCurrentOpenPriceByOrderType(operationType)

        if (!leverage || leverage <= 0) {
            throw new StrangeError('Leverage is not set or invalid.')
        }

        return (symbolContractSize * price) / leverage
    }
}
