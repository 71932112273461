import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Tooltip, TriggerOverlay, Typography, Toggle, InfoTooltip } from '@fto/ui'

import styles from './index.module.scss'
import { IconButton } from '@fto/icons'

import { showSuccessToast } from '@root/utils/toasts'

const ShareProjectButton: FC = () => {
    const { t } = useTranslation()

    const [isToggled, setIsToggled] = useState(true)

    const url = useMemo(() => {
        return window.location.href + (isToggled ? '&homework=true' : '')
    }, [isToggled])

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(url)
        showSuccessToast({ title: t('ribbon.share.success'), message: t('ribbon.share.successMesage') })
    }, [isToggled])

    return (
        <TriggerOverlay
            optionsRenderer={() => (
                <Flex direction='column' className={styles.container}>
                    <Flex justifyContent='space-between' alignItems='center' className={styles.headerRow}>
                        <Typography type='h5-semibold'>{t('ribbon.share.header')}</Typography>
                        <InfoTooltip tooltipContent={t('ribbon.share.description')} />
                    </Flex>

                    <Flex
                        direction='row'
                        gap={4}
                        alignItems='center'
                        justifyContent='space-between'
                        className={styles.linkContainer}
                    >
                        <Typography type='interface-medium' truncate>
                            {url}
                        </Typography>
                        <IconButton name='copy' size={18} onClick={handleCopy} />
                    </Flex>

                    <Flex alignItems='center' gap={8}>
                        <Toggle
                            checked={isToggled}
                            onChange={() => {
                                setIsToggled(!isToggled)
                            }}
                        />
                        <Typography type='interface-medium'>{t('ribbon.share.toggleText')}</Typography>
                    </Flex>
                </Flex>
            )}
        >
            <Tooltip content={t('ribbon.tooltips.share')} placement='bottom-start'>
                <Flex className={styles.buttonContainer}>
                    <IconButton name='share' size={24} />
                </Flex>
            </Tooltip>
        </TriggerOverlay>
    )
}

export default ShareProjectButton
