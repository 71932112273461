import { FC } from 'react'

import { Flex, Typography } from '@fto/ui'

import { FilenameTypes, Icon } from '@fto/icons'

import styles from './index.module.scss'

type Props = {
    name: string
    icon: (typeof FilenameTypes)[number]
}

const InnerDropdownButton: FC<Props> = ({ name, icon }) => {
    return (
        <Flex className={styles.indicatorDropdown} justifyContent='space-between' alignItems='center'>
            <Flex gap={12} alignItems='center'>
                <Icon size={18} name={icon} />
                <Typography type='interface-regular'>{name}</Typography>
            </Flex>
            <Icon size={16} name='arrow-right'></Icon>
        </Flex>
    )
}

export default InnerDropdownButton
