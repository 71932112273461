import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { BooleanProperties, ConfigType, NumberProperties, StringProperties, FunctionProperties } from '../types'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChartOptions, TColorScheme } from '@fto/lib/charting/ChartBasicClasses'
import { TOptions } from '@fto/lib/ft_types/common/OptionsUnit'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

export function getBooleanValue(chart: TChartWindow, configType: string, key: string) {
    if (configType === ConfigType['ColorScheme']) {
        return chart.ChartOptions.ColorScheme[key as keyof BooleanProperties<TColorScheme>]
    } else if (configType === ConfigType['ChartOptions']) {
        return chart.ChartOptions[key as keyof BooleanProperties<TChartOptions>]
    } else if (configType === ConfigType['GlobalOptions']) {
        return GlobalOptions.Options[key as keyof BooleanProperties<TOptions>]
    }
    throw new StrangeError('Unknown configType')
}

export function getStringValue(chart: TChartWindow, configType: string, key: string) {
    if (configType === ConfigType['ColorScheme']) {
        return chart.ChartOptions.ColorScheme[key as keyof StringProperties<TColorScheme>]
    } else if (configType === ConfigType['ChartOptions']) {
        return chart.ChartOptions[key as keyof StringProperties<TChartOptions>]
    } else if (configType === ConfigType['GlobalOptions']) {
        return GlobalOptions.Options[key as keyof StringProperties<TOptions>]
    }
    throw new StrangeError('Unknown configType')
}

export function getNumberValue(chart: TChartWindow, configType: string, key: string) {
    if (configType === ConfigType['ColorScheme']) {
        return chart.ChartOptions.ColorScheme[key as keyof NumberProperties<TColorScheme>]
    } else if (configType === ConfigType['ChartOptions']) {
        return chart.ChartOptions[key as keyof NumberProperties<TChartOptions>]
    } else if (configType === ConfigType['GlobalOptions']) {
        return GlobalOptions.Options[key as keyof NumberProperties<TOptions>]
    }
    throw new StrangeError('Unknown configType')
}

export function setBooleanValue(
    chart: TChartWindow,
    configType: string,
    key: string,
    value: boolean,
    applyToAllCharts = false
) {
    const charts = applyToAllCharts ? GlobalChartsController.Instance.getAllCharts() : [chart]

    if (configType === ConfigType['ColorScheme']) {
        charts.forEach((chart) => {
            if (key in chart.ChartOptions.ColorScheme) {
                // TODO code better
                ;(chart.ChartOptions.ColorScheme as any)[key as keyof BooleanProperties<TColorScheme>] = value
            }
        })
        return
    } else if (configType === ConfigType['ChartOptions']) {
        charts.forEach((chart) => {
            chart.ChartOptions[key as keyof BooleanProperties<TChartOptions>] = value
        })
        return
    } else if (configType === ConfigType['GlobalOptions']) {
        GlobalOptions.Options[key as keyof BooleanProperties<TOptions>] = value
        return
    }
    throw new StrangeError('Unknown configType')
}

export function setStringValue(
    chart: TChartWindow,
    configType: string,
    key: string,
    value: string,
    applyToAllCharts = false
) {
    const charts = applyToAllCharts ? GlobalChartsController.Instance.getAllCharts() : [chart]

    if (configType === ConfigType['ColorScheme']) {
        charts.forEach((chart) => {
            chart.ChartOptions.ColorScheme[key as keyof StringProperties<TColorScheme>] = value
        })
        return
    } else if (configType === ConfigType['ChartOptions']) {
        charts.forEach((chart) => {
            if (key in chart.ChartOptions) {
                // TODO code better
                ;(chart.ChartOptions as any)[key as keyof StringProperties<TChartOptions>] = value
            }
        })
        return
    } else if (configType === ConfigType['GlobalOptions']) {
        GlobalOptions.Options[key as keyof StringProperties<TOptions>] = value
        return
    }
    throw new StrangeError('Unknown configType')
}

export function setNumberValue(
    chart: TChartWindow,
    configType: string,
    key: string,
    value: number,
    applyToAllCharts = false
) {
    const charts = applyToAllCharts ? GlobalChartsController.Instance.getAllCharts() : [chart]

    if (configType === ConfigType['ColorScheme']) {
        charts.forEach((chart) => {
            // TODO code better
            if (key in chart.ChartOptions.ColorScheme) {
                ;(chart.ChartOptions.ColorScheme as any)[key as keyof NumberProperties<TColorScheme>] = value
            }
        })
        return
    } else if (configType === ConfigType['ChartOptions']) {
        charts.forEach((chart) => {
            chart.ChartOptions[key as keyof NumberProperties<TChartOptions>] = value
        })
        return
    } else if (configType === ConfigType['GlobalOptions']) {
        GlobalOptions.Options[key as keyof NumberProperties<TOptions>] = value
        return
    }
    throw new StrangeError('Unknown configType')
}

export function executeMethod(
    chart: TChartWindow,
    configType: string,
    methodName: string,
    value: any,
    applyToAllCharts = false
) {
    const charts = applyToAllCharts ? GlobalChartsController.Instance.getAllCharts() : [chart]

    if (configType === ConfigType['ColorScheme']) {
        charts.forEach((chart) => {
            let object = chart.ChartOptions.ColorScheme
            object[methodName as keyof FunctionProperties<TColorScheme>] &&
                object[methodName as keyof FunctionProperties<TColorScheme>](value)
        })
        return
    } else if (configType === ConfigType['ChartOptions']) {
        charts.forEach((chart) => {
            let object = chart.ChartOptions
            object[methodName as keyof FunctionProperties<TChartOptions>] &&
                object[methodName as keyof FunctionProperties<TChartOptions>](value as never)
        })
        return
    } else if (configType === ConfigType['GlobalOptions']) {
        let object = GlobalOptions.Options
        object[methodName as keyof FunctionProperties<TOptions>] &&
            object[methodName as keyof FunctionProperties<TOptions>](value)
        return
    }
    throw new StrangeError('Unknown configType')
}
