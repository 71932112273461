import { makeAutoObservable } from 'mobx'

import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/DllIndicatorUnit'
import { Level } from '@fto/lib/drawing_interface/GraphicObjects'
import {
    TOptValue_Session,
    TOptValue_SessionsArray
} from '@fto/lib/extension_modules/indicators/api/IndicatorInterfaceUnit'

export type TIndicatorOption = {
    alias: string
    fvalue: string | TLineStyle | Level[] | TOptValue_Session | TOptValue_SessionsArray
    type: number
    values: string[]
}

export type IIndicatorOptionsStore = {
    [key: number]: TIndicatorOption
    indicatorInstance?: TRuntimeIndicator
}

const initialSettings = {}

class IndicatorOptionsStore {
    indicatorOptions: IIndicatorOptionsStore

    constructor() {
        makeAutoObservable(this)

        this.indicatorOptions = initialSettings
        this.setIndicatorOptions = this.setIndicatorOptions.bind(this)
        this.resetIndicatorOptions = this.resetIndicatorOptions.bind(this)
    }

    // The argument can be either an updater function or a PassedSettingsType object
    setIndicatorOptions(
        updater: ((prevSettings: IIndicatorOptionsStore) => IIndicatorOptionsStore) | IIndicatorOptionsStore
    ) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.indicatorOptions = {
                ...this.indicatorOptions,
                ...updater(this.indicatorOptions)
            }
        } else {
            // If updater is an object, directly apply the updates
            this.indicatorOptions = {
                ...this.indicatorOptions,
                ...updater
            }
        }
    }

    resetIndicatorOptions() {
        this.indicatorOptions = initialSettings
    }
}

export default new IndicatorOptionsStore()
