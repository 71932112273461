import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Typography, Separator } from '@fto/ui'

import { IconButton } from '@fto/icons'

import { AnalyticsInfoRow } from '../AnalyticsInfoRow'

import styles from './index.module.scss'

const AnalyticsContent: FC<Props> = ({ setShowAnalytics, statistics }) => {
    const { t } = useTranslation()

    const handleClose = useCallback(() => {
        setShowAnalytics(false)
    }, [setShowAnalytics])

    const generateFields = useCallback((data: object) => {
        return Object.entries(data).map(([key, value], i) => (
            <Flex key={key} direction='column'>
                <AnalyticsInfoRow
                    title={t('analytics.' + key)}
                    value={value.toFixed(2)}
                    tooltip={t('analytics.tooltips.' + key)}
                />
                {Object.entries(data).length - 1 !== i && <Separator width='100%' />}
            </Flex>
        ))
    }, [])

    return (
        <Flex className={styles.analytics} direction='column'>
            <Flex className={styles.header} justifyContent='space-between' alignItems='center'>
                <Typography type='h4-semibold'>{t('analytics.projectAnalytics')}</Typography>
                <IconButton size={14} name='close' color={'var(--color-gray-900)'} onClick={handleClose} />
            </Flex>
            <Separator width='100%' />
            <Flex direction='column' className={styles.info} gap={16}>
                <Flex direction='column'>
                    <Typography type='text-semibold'>{t('analytics.time')}</Typography>
                    {generateFields(statistics.TimeStatistics)}
                </Flex>
                <Flex direction='column'>
                    <Typography type='text-semibold'>{t('analytics.trades')}</Typography>
                    {generateFields(statistics.TradeStatistics)}
                </Flex>
                <Flex direction='column'>
                    <Typography type='text-semibold'>{t('analytics.income')}</Typography>
                    {generateFields(statistics.IncomeStatistics)}
                </Flex>
                <Flex direction='column'>
                    <Typography type='text-semibold'>{t('analytics.other')}</Typography>
                    {generateFields(statistics.OtherStatistics)}
                </Flex>
            </Flex>
        </Flex>
    )
}

export default AnalyticsContent
