import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { TMyObjectList } from '../../ft_types/common/Common'
import { TOffsStringList } from '../../ft_types/common/OffsStringList'
import { TIndicator } from './IndicatorUnit'

export class TIndicatorsList<TI extends TIndicator> extends TMyObjectList<TI> {
    AddIndicator(indicator: TI): TIndicator {
        this.Add(indicator)
        return indicator
    }

    DeleteIndicator(indicator: TI): void {
        try {
            indicator.DeleteFilesOnDestroy = true
            this.Delete(this.IndexOf(indicator))
        } catch (error) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
            //TODO: Handle exception
        }
    }

    DeleteByName(name: string): void {
        for (let i = this.Count - 1; i >= 0; i--) {
            if (this[i].ShortName.toLowerCase() === name.toLowerCase()) {
                this.DeleteIndicator(this[i])
            }
        }
    }

    GetByLibName(LibName: string): TI | null {
        for (let i = 0; i < this.Count; i++) {
            if (this[i].LibName.toLowerCase() === LibName.toLowerCase()) {
                return this[i]
            }
        }
        return null
    }

    ClearRefCounts(): void {
        for (let i = 0; i < this.Count; i++) {
            this[i].ReferenceCount = 0
        }
    }

    DeleteWithRefCounts(): void {
        for (let i = this.Count - 1; i >= 0; i--) {
            if (this[i].ReferenceCount > 0) {
                this.DeleteIndicator(this[i])
            }
        }
    }

    SaveToList(SectionName: string, list: TOffsStringList): void {
        list.OpenSection(SectionName)
        for (let i = 0; i < this.Count; i++) {
            this[i].SaveToList(list)
        }
        list.CloseSection()
    }

    // DeleteIndicatorsNotInList(list: TStringList): void {
    //   for (let i = this.Count - 1; i >= 0; i--) {
    //     if (this[i].ReferenceCount === 0 &&
    //       list.IndexOf(this[i].LibName) === -1) {
    //       this.DeleteIndicator(this[i]);
    //     }
    //   }
    // }

    DeleteIndicatorsByTimeframe(tf: number): void {
        for (let i = this.Count - 1; i >= 0; i--) {
            if (this[i].Timeframe === tf) {
                this.DeleteIndicator(this[i])
            }
        }
    }

    public GetIndicatorByShortName(name: string): TIndicator | null {
        let lowerName = name.toLocaleLowerCase()

        for (let i = 0; i < this.Count; i++) {
            const indicator = this[i]
            if (indicator.ShortName.toLocaleLowerCase() === lowerName) {
                return indicator
            }
        }

        // some names were changed - search for new ones
        if (lowerName === 'ishimoku') {
            return this.GetIndicatorByShortName('Ichimoku')
        } else if (lowerName === 'heiken ashi') {
            return this.GetIndicatorByShortName('Heikin Ashi')
        }

        return null
    }
}
