import { TPtRiskReward, TRiskToolType } from '@fto/lib/charting/paint_tools/SpecificTools/ptRiskReward'
import { MarketValues, OrderType } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'

export function determineOrderTypeFromRR(tool: TPtRiskReward, marketValues: MarketValues): OrderType {
    const marketAsk = marketValues.ask
    const marketBid = marketValues.bid

    const openPrice = tool.OpenPrice
    const directionOfTool = tool.RiskToolType

    if (directionOfTool === TRiskToolType.rt_Buy) {
        if (openPrice < marketAsk) {
            return OrderType.LIMIT
        } else {
            return OrderType.STOP
        }
    } else if (directionOfTool === TRiskToolType.rt_Sell) {
        if (openPrice > marketBid) {
            return OrderType.LIMIT
        } else {
            return OrderType.STOP
        }
    } else {
        throw new Error('Unexpected scenario')
    }
}

export function getMarketValuesFromActiveChart(marketValues: MarketValues): MarketValues {
    const activeChartWin = GlobalChartsController.Instance.getActiveChart()
    const processingCore = GlobalProcessingCore.ProcessingCore

    if (activeChartWin && activeChartWin.SymbolData.IsSeeked) {
        marketValues.symbol = activeChartWin.SymbolData.symbolInfo.SymbolName
        marketValues.ask = activeChartWin.SymbolData.ask
        marketValues.bid = activeChartWin.SymbolData.bid
        marketValues.spread = activeChartWin.SymbolData.Spread()
        marketValues.equity = processingCore.Equity
        marketValues.minimumPriceChange = activeChartWin.SymbolData.symbolInfo.MinPoint
        marketValues.minimumDistanceToPrice = activeChartWin.SymbolData.symbolInfo.MinDistToPrice
        marketValues.postDecimalDigits = activeChartWin.SymbolData.symbolInfo.decimals
    }
    return marketValues
}

export function getMarketValuesFromChart(chartWin: TChartWindow, marketValues?: MarketValues): MarketValues {
    const processingCore = GlobalProcessingCore.ProcessingCore

    const mValues = marketValues ?? new MarketValues()

    if (chartWin) {
        mValues.symbol = chartWin.SymbolData.symbolInfo.SymbolName
        mValues.ask = chartWin.SymbolData.ask
        mValues.bid = chartWin.SymbolData.bid
        mValues.spread = chartWin.SymbolData.Spread()
        mValues.equity = processingCore.Equity
        mValues.minimumPriceChange = chartWin.SymbolData.symbolInfo.MinPoint
        mValues.minimumDistanceToPrice = chartWin.SymbolData.symbolInfo.MinDistToPrice
        mValues.postDecimalDigits = chartWin.SymbolData.symbolInfo.decimals
    }
    return mValues
}
