import { FC, useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import StatisticsStore from '@fto/lib/store/statistics'

import AnalyticsContent from '@root/pages/Dashboard/components/ProjectsAnalytics/components/AnalyticsContent'
import { fireMixpanelEvent } from '@root/utils/api'

type Props = {
    setShowAnalytics: (show: boolean) => void
}

const Analytics: FC<Props> = observer(({ setShowAnalytics }) => {
    const { getGroupedStatistics, statistics } = StatisticsStore

    useEffect(() => {
        fireMixpanelEvent('project_analytics_opened', statistics)
    }, [])
    return <AnalyticsContent setShowAnalytics={setShowAnalytics} statistics={getGroupedStatistics()} />
})

export default Analytics
