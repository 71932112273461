import { FC, useCallback, useMemo, useState } from 'react'

import { Button } from '@fto/ui'

import { Icon } from '@fto/icons'

import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'

import { LAYOUTS } from '@fto/chart_components/constants/layouts'

import RibbonDropdown from '../RibbonDropdown'
import { RibbonOptionType } from '../types'

import styles from '../../index.module.scss'
import { fireMixpanelEvent } from '@root/utils/api'
import { useTranslation } from 'react-i18next'
import LayoutDropdownMenu from '@fto/chart_components/ProjectInterface/components/Ribbon/components/LayoutDropdownMenu'

type Props = {
    setSelectedChartInfo: (value: any) => void
}

const LayoutDropdown: FC<Props> = ({ setSelectedChartInfo }) => {
    const { t } = useTranslation()

    const [activeOption, setActiveOption] = useState(
        GlobalProjectJSONAdapter.Instance.getLayoutSettings().layout as string
    )

    const layoutOptions = useMemo<Map<number, RibbonOptionType[]>>(() => {
        const map = new Map<number, RibbonOptionType[]>()

        LAYOUTS.forEach((layout) => {
            if (!map.has(layout.value.count)) {
                map.set(layout.value.count, [])
            }
            map.get(layout.value.count)?.push({
                name: t(`ribbon.chartLayouts.${layout.label}`),
                value: layout.label,
                icon: layout.icon,
                action: () => handleLayoutChange(layout.value, layout.label)
            })
        })

        return map
    }, [LAYOUTS])

    const handleLayoutChange = useCallback((value: any, label: string) => {
        value.symbol = GlobalProjectJSONAdapter.Instance.getBaseSymbolName()

        setSelectedChartInfo(value)
        GlobalProjectJSONAdapter.Instance.setLayoutSettings(value)
        setActiveOption(label)
        fireMixpanelEvent('multichart_added', {
            layout: value.layout,
            total_chart: value.count
        })
    }, [])

    return (
        <RibbonDropdown
            tooltip={t('ribbon.tooltips.layouts')}
            renderer={(close) => (
                <LayoutDropdownMenu
                    close={close}
                    options={layoutOptions}
                    activeOption={activeOption}
                    name={t('ribbon.chartLayouts.chartLayout')}
                />
            )}
        >
            <Button
                classNames={{ content: styles.smallButton }}
                label={t('ribbon.chartLayouts.layouts')}
                before={<Icon name='chart-layouts' />}
                size='compact'
                type='tetriatry-white'
            />
        </RibbonDropdown>
    )
}
export default LayoutDropdown
