import React, { FC, useState, useMemo, useCallback } from 'react'
import cx from 'classnames'

import { Checkbox, Flex, Tooltip, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'

import { SymbolsCategoryType } from '@root/mapper/symbols.mapper'
import { SymbolItemType } from '@root/types/symbols.types'
import { CreateProjectModalFormData } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/create-project-form.schema'

import style from './create-project-modal-symbols.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
    symbolsGroup: SymbolsCategoryType
    formData: CreateProjectModalFormData
    setFormData: (value: React.SetStateAction<CreateProjectModalFormData>) => void
}

const CreateProjectModalSymbolsGroup: FC<Props> = ({ symbolsGroup, formData, setFormData }) => {
    const { t } = useTranslation()

    const [isOpened, setIsOpened] = useState(true)

    const isSymbolsLimitReached = useCallback(() => {
        return formData.Symbols ? formData.Symbols.length === 4 : false
    }, [formData.Symbols])

    const toggleSelect = useCallback(
        (symbol: SymbolItemType) => {
            const symbols = formData.Symbols
            if (!symbols) return
            const isExist = symbols.find((item: SymbolItemType) => item.Symbol === symbol.Symbol)

            if (isExist) {
                const updatedSymbols = symbols.filter((item: SymbolItemType) => item.Symbol !== symbol.Symbol)
                setFormData((prev) => ({ ...prev, Symbols: updatedSymbols }))
            } else {
                setFormData((prev) => ({ ...prev, Symbols: [...(symbols || []), symbol] }))
            }
        },
        [formData.Symbols, setFormData]
    )

    const isInputChecked = useCallback(
        (symbol: SymbolItemType) => formData.Symbols?.find((s) => s.Symbol === symbol.Symbol),
        [formData.Symbols]
    )

    const toggleGroupOpenState = useCallback(() => setIsOpened((prev) => !prev), [])

    if (symbolsGroup.symbols.length === 0) return null

    return (
        <div>
            <Flex className={style.categoryTitle} alignItems={'center'} onClick={toggleGroupOpenState} gap={4}>
                <Icon name={isOpened ? 'minus' : 'plus'} size={24} />
                <Typography type='text-semibold' className={style.symbolsGroup}>
                    {symbolsGroup.category}
                </Typography>
            </Flex>

            <ul className={cx(style.symbolListGroup, { [style.opened]: isOpened })}>
                {symbolsGroup.symbols.map((symbol) => {
                    const isSelected = !!isInputChecked(symbol)
                    const isDisabled = isSymbolsLimitReached() && !isSelected

                    const JSX = (
                        <li className={style.symbolCheckboxWrap} key={symbol.Symbol}>
                            <Checkbox
                                disabled={isDisabled}
                                className={style.symbolCheckbox}
                                checked={isSelected}
                                onChange={() => toggleSelect(symbol)}
                                key={symbol.Symbol}
                                inputControlVariant={'row-reverse'}
                                label={<Typography type={'text-light'}>{symbol.Symbol}</Typography>}
                            />
                        </li>
                    )

                    return (
                        <Tooltip
                            placement='top'
                            classNames={{ content: style.Tooltip }}
                            content={isDisabled ? t('dashboard.modals.projects.toasts.maxSymbols') : false}
                        >
                            {JSX}
                        </Tooltip>
                    )
                })}
            </ul>
        </div>
    )
}

export default CreateProjectModalSymbolsGroup
