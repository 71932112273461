import { Checkbox, Flex, Typography } from '@fto/ui'
import { FC } from 'react'
import styles from './modals.module.scss'

type SurveyOptionProps = {
    text: string
    onChange: () => void
    value: string
    checked: boolean
}

export const SurveyOption: FC<SurveyOptionProps> = ({ text, onChange, value, checked }) => {
    return (
        <Flex alignItems='center' gap={8} className={styles.option}>
            <Checkbox id={value} variant={'default'} block checked={checked} value={value} onChange={onChange}>
                <label htmlFor={value}>
                    <Typography type='interface-regular' color='gray-1000'>
                        {text}
                    </Typography>
                </label>
            </Checkbox>
        </Flex>
    )
}
