import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TPosMarker } from '../../charting/ChartBasicClasses'
import { TDateTime } from '../../delphi_compatibility/DateUtils'
import { TForm } from '../../delphi_compatibility/TForm'
import { OscInfo } from './Oscillators/OscInfo'
import { TOscWindow } from '@fto/lib/charting/OscWinsListUnit'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { INamed } from '@fto/lib/utils/INamed'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'

//TODO: is there any sense of having this TBasicChartWindow class? it seems that there is only one possible implementation of it, so they can be merged
export abstract class TBasicChartWindow extends TForm implements INamed {
    private static instanceNumber = 0

    private idContainer: number

    protected needsRedraw = false
    protected PosMarker: TPosMarker = new TPosMarker() // position marker

    public _leftPosition = 0
    private _left_X_offset = 0
    public get left_X_offset(): number {
        return this._left_X_offset
    }

    public set left_X_offset(value: number) {
        if (isNaN(value)) {
            throw new StrangeError('left_X_offset is NaN')
        }
        this._left_X_offset = value
    }

    protected createCanvasCallback?: (
        chartWindow: TBasicChartWindow,
        oscCanvasOwnerWindow: TOscWindow
    ) => OscInfo | null
    protected deleteCanvasCallback?: (oscWindowId: number) => void
    protected deleteAllCanvasCallback?: () => void

    constructor() {
        super()
        this.idContainer = TBasicChartWindow.instanceNumber++
    }

    public abstract CorrectLeftPos(): void
    public abstract ScrollRight(): void
    public abstract OnToolCancelledWork(): void
    public abstract OnToolCompletedWork(): void

    public abstract get DName(): string

    toString(): string {
        return this.DName
    }

    public get LeftPosition(): number {
        return this._leftPosition
    }

    public set LeftPosition(value: number) {
        this._leftPosition = value
        DebugUtils.logTopic(ELoggingTopics.lt_ScrollAndZoom, `${this.DName}: LeftPosition set to ${value}`)
    }

    public invalidate(): void {
        this.needsRedraw = true
    }

    public getID(): number {
        return this.idContainer
    }

    public setCreateCanvasCallback(
        callback: (chartWindow: TBasicChartWindow, oscCanvasOwnerWindow: TOscWindow) => OscInfo | null
    ): void {
        this.createCanvasCallback = callback
    }
    public setDeleteCanvasCallback(callback: (oscWindowId: number) => void): void {
        this.deleteCanvasCallback = callback
    }

    public setDeleteAllCanvasCallback(callback: () => void): void {
        this.deleteAllCanvasCallback = callback
    }

    public requestNewCanvas(oscCanvasOwnerWindow: TOscWindow): OscInfo | null {
        if (!this.createCanvasCallback) {
            throw new StrangeError('Create canvas callback is not set.')
        }
        return this.createCanvasCallback(this, oscCanvasOwnerWindow)
    }
    public deleteOscCanvas(oscWindowId: number): void {
        if (!this.deleteCanvasCallback) {
            throw new StrangeError('Delete canvas callback is not set.')
        }
        this.deleteCanvasCallback(oscWindowId)
    }

    public deleteAllOscCanvas(): void {
        if (!this.deleteAllCanvasCallback) {
            throw new StrangeError('Delete all canvas callback is not set.')
        }
        this.deleteAllCanvasCallback()
    }

    public SetPosMarker(DateTime: TDateTime, price: number): void {
        this.PosMarker.DateTime = DateTime
        this.PosMarker.price = price
        this.invalidate()
    }

    public ClearPosMarker(): void {
        this.PosMarker.DateTime = -1
        this.invalidate()
    }
}
