import { TDateTime } from '../../delphi_compatibility/DateUtils'

export enum TFocusType {
    ft_Edge = 0,
    ft_Point = 1,
    ft_None = 2
}

// export type TSaveLayoutSettings = Set<TSLSettings>;
export enum TSaveLayoutSettings {
    sl_Colors,
    sl_Tools,
    sl_Indicators
    // ... (other values)
}

// export enum TSLSettings {
//   sl_Colors,
//   sl_Tools,
//   sl_Indicators
// }

export enum TChartStyle {
    cs_Line,
    cs_Stick,
    cs_Bar
    // ... (other values)
}

export enum ChartEvent {
    CROSS_HAIR_MODE_START_PERMANENT,
    CROSS_HAIR_MODE_START_ROOLER,
    UPDATE_LINKED_CROSS_HAIRS,
    CROSS_HAIR_MODE_END,
    UPDATE_CROSS_HAIR_ACTIVE_CHART,
    ACTIVATE,
    SCROLL_OTHER_CHARTS,
    PAINT_COMPLETE,
    PAINT_TOOL_WINDOW_SELECTED,
    CHARTS_NEED_REDRAW,
    UPDATE_LINKED_TOOLS,
    COPY_PAINT_TOOLS_TO_OTHER_CHARTS,
    COPY_PAINT_CURRENT_TOOL,
    DELETE_LINKED_PAINT_TOOLS,
    UPDATE_SINGLE_CHART,
    SAVE_STATE_ON_CHART,
    UNDO_LAST_STATE_ON_CHART,
    REDO_LAST_STATE_ON_CHART,
    UPDATE_ALL_LAYERS,
    ON_SEEK_COMPLETED,
    ENABLE_AUTOSCALE_ON_ALL_CHARTS,
    UPDATE_CHARTS_WITHOUT_THROTTLE
}

export enum ThrottleForRepaint {
    Enabled,
    Disabled
}

export enum TGridLinePeriod {
    glp_Year,
    glp_Month,
    glp_Day,
    glp_Minute
}

export class TGridLine {
    dt!: TDateTime
    globalIndex!: number
    x!: number
    visible!: boolean
    text!: string
    bold!: boolean
    period!: TGridLinePeriod
}

export enum TLevelType {
    lt_StopLoss,
    lt_TakeProfit,
    lt_Price
}
