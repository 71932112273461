import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { InputField, Checkbox, ColorPicker, SimpleDropdown } from '@fto/ui'

import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import { OptionType } from '@fto/lib/charting/tool_storages/graphToolStore'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import LineStylePicker from '@root/pages/ChartPage/components/lineStylePicker'
import { getBasePointsOptions, getLotOptions } from './constants'

import FieldWrapper from './components/FieldWrapper'
import TextStyle from './components/TextStyle'
import FillColor from './components/FillColor'

import styles from './index.module.scss'

type FieldCreatorProps = {
    option: OptionType
    setData: (value: string | number | boolean | TLineStyle, option: OptionType, isOpacity?: boolean) => void
}

const FieldCreator: FC<FieldCreatorProps> = ({ option, setData }) => {
    const { t } = useTranslation()

    const basePointOptions = useMemo(() => {
        return getBasePointsOptions(t)
    }, [])

    const lotOptions = useMemo(() => {
        return getLotOptions(t)
    }, [])

    const updateData = useCallback(
        (value: any) => {
            setData(value, option)
        },
        [option.key, setData]
    )

    const updateOpacity = useCallback(
        (value: any) => {
            setData(value, option, true)
        },
        [option.key, setData]
    )

    const updateNumberField = useCallback(
        (value: string) => {
            const numberValue = Number(value)

            if (option.min || option.max) {
                const { min, max } = option

                let formattedValue = numberValue

                if ((min || min === 0) && min > numberValue) {
                    formattedValue = min
                }

                if ((max || max === 0) && max < numberValue) {
                    formattedValue = max
                }

                return updateData(formattedValue)
            }

            return updateData(numberValue)
        },
        [option, updateData]
    )

    switch (option.type) {
        case 'text':
            return (
                <FieldWrapper label={option.label}>
                    <InputField
                        type='text'
                        placeholder={t('toolsModal.inputPlaceholder')}
                        value={option.value}
                        onChange={updateData}
                    />
                </FieldWrapper>
            )
        case 'textArea':
            return (
                <FieldWrapper label={option.label}>
                    <textarea
                        className={styles.TextArea}
                        placeholder={t('toolsModal.inputPlaceholder')}
                        value={option.value}
                        onChange={(e) => updateData(e.target.value)}
                    />
                </FieldWrapper>
            )
        case 'date':
            return (
                <FieldWrapper label={option.label}>
                    <InputField type='text' value={DateUtils.DF(option.value)} onChange={updateData} />
                </FieldWrapper>
            )
        case 'number':
            return (
                <FieldWrapper label={option.label}>
                    <div className={styles.InputTypeNumber}>
                        <InputField
                            min={option.min}
                            max={option.max}
                            step={option.step}
                            type='number'
                            value={option.value}
                            onChange={updateNumberField}
                        />
                    </div>
                </FieldWrapper>
            )
        case 'checkbox':
            return (
                <FieldWrapper label={option.label}>
                    <Checkbox checked={option.value} onChange={updateData} />
                </FieldWrapper>
            )
        // NOTE: better use fillColor type
        case 'color':
            return (
                <FieldWrapper label={option.label}>
                    <ColorPicker
                        color={option.value}
                        onColorChange={updateData}
                        withOpacity={option.withOpacity}
                        onOpacityChange={updateOpacity}
                        opacity={option.opacityValue || 0}
                    />
                </FieldWrapper>
            )
        case 'style':
            return (
                <FieldWrapper label={option.label} alignItems='flex-end' className={styles.Label}>
                    <LineStylePicker
                        withLabel={option.withLabel}
                        data={{ fValue: option.value }}
                        onChange={updateData}
                        attribute='fValue'
                    />
                </FieldWrapper>
            )
        case 'basePoint':
            return (
                <FieldWrapper label={option.label}>
                    <SimpleDropdown onChange={updateData} value={option.value} options={basePointOptions} />
                </FieldWrapper>
            )

        case 'calculateLot':
            return (
                <FieldWrapper label={option.label}>
                    <SimpleDropdown onChange={updateData} value={option.value} options={lotOptions} />
                </FieldWrapper>
            )

        case 'textStyle':
            return (
                <FieldWrapper label={option.label} alignItems='flex-end' className={styles.Label}>
                    <TextStyle data={option.value} onChange={updateData} />
                </FieldWrapper>
            )

        case 'fillColor':
            return (
                <FillColor
                    onChange={updateData}
                    isOptional={option.isOptional}
                    label={option.label}
                    value={option.value}
                    withOpacity={option.withOpacity}
                />
            )

        default:
            return null
    }
}

export default FieldCreator
