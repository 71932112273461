import { FC, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import ReactSlider from 'react-slider'
import { Flex, InputField, Typography } from '@fto/ui'
import { SliderSettingType, OptionSettingRef } from '../../../types'

import styles from './index.module.scss'
import { getNumberValue, setNumberValue } from '../../../utils'
import { t } from 'i18next'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

const SliderSetting = forwardRef<OptionSettingRef, SliderSettingType>(
    ({ tab, chart, option, applyToAll, onDifferentSettingsUpdate }, ref) => {
        const [value, setValue] = useState<number>(
            () => getNumberValue(chart, option.valueConfigType, option.valueKey) * (option.divisionFactor || 1)
        )

        const renderThumb = useCallback((props: any) => <div {...props} />, [])

        const updateValue = useCallback(
            (value: number, redraw = true, shouldUpdateConfig = true) => {
                setValue(value)

                shouldUpdateConfig &&
                    setNumberValue(
                        chart,
                        option.valueConfigType,
                        option.valueKey,
                        value / (option.divisionFactor || 1),
                        applyToAll
                    )

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [option, chart, applyToAll]
        )

        const handleChange = useCallback(
            (value: number) => {
                onDifferentSettingsUpdate(tab, value, value, option.valueKey)
                updateValue(value)
            },
            [tab, option, updateValue, onDifferentSettingsUpdate]
        )

        useEffect(() => {
            handleChange(getNumberValue(chart, option.valueConfigType, option.valueKey) * (option.divisionFactor || 1))
            // TODO CHECK DEPS
        }, [option, chart.ChartOptions, GlobalOptions.Options])

        const loadOptionSetting = useCallback(
            (data: any, shouldUpdateConfig = true) => {
                updateValue(data.value * (option.divisionFactor || 1), false, shouldUpdateConfig)
            },
            [option, updateValue]
        )

        useImperativeHandle(ref, () => ({ name: option.key, loadOptionSetting }), [option.key, loadOptionSetting])

        return (
            <>
                <Flex block>
                    <Typography color='gray-600' type='tiny-regular'>
                        {t(option.name)}
                    </Typography>
                </Flex>
                <Flex alignItems='center' justifyContent='space-between' className={styles.container} gap={4}>
                    <div className={styles.sliderWrap}>
                        <ReactSlider
                            max={option.maxValue}
                            min={1}
                            onChange={handleChange}
                            className={styles.slider}
                            thumbClassName={styles.thumb}
                            trackClassName={styles.track}
                            renderThumb={renderThumb}
                            value={value}
                        />
                        <div className={styles.rangeMarks}>
                            {Array.from({ length: 10 }, (_, index) => (
                                <Typography color='gray-600' key={index}>
                                    |
                                </Typography>
                            ))}
                        </div>
                    </div>
                    {option.withInputField ? (
                        <Flex alignItems='center'>
                            <InputField
                                className={styles.input}
                                step={1}
                                max={option.maxValue}
                                value={value}
                                onChange={(value) => handleChange(Number(value))}
                                type={'number'}
                            />
                        </Flex>
                    ) : (
                        <div className={styles.textbox}>x{value}</div>
                    )}
                </Flex>
            </>
        )
    }
)

export default SliderSetting
