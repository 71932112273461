import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { Flex, SimpleDropdown, Typography } from '@fto/ui'
import { DropdownSettingType, OptionSettingRef } from '../../../types'

import styles from './index.module.scss'
import { t } from 'i18next'
import { getNumberValue, setNumberValue, executeMethod } from '../../../utils'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

const DropdownSettings = forwardRef<OptionSettingRef, DropdownSettingType>(
    ({ tab, chart, option, applyToAll, initialData, onDifferentSettingsUpdate }, ref) => {
        const [value, setValue] = useState<number>(() => getNumberValue(chart, option.valueConfigType, option.valueKey))

        const updateValue = useCallback(
            (value: number, redraw = true, shouldUpdateConfig = true) => {
                setValue(value)

                if (shouldUpdateConfig) {
                    if (option.valueKeyMethod) {
                        executeMethod(chart, option.valueConfigType, option.valueKeyMethod, value)
                    } else {
                        setNumberValue(chart, option.valueConfigType, option.valueKey, value, applyToAll)
                    }
                }

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [chart, option, applyToAll]
        )

        const handleChange = useCallback(
            (newValue: number) => {
                let prevValueLabel

                if (initialData) {
                    const prevValueKey = getNumberValue(chart, option.valueConfigType, option.valueKey)
                    prevValueLabel =
                        option.options.find((opt: { [key: string]: any }) => opt.value === prevValueKey)?.labelValue ||
                        ''
                } else {
                    prevValueLabel =
                        option.options.find((opt: { [key: string]: any }) => opt.value === value)?.labelValue || ''
                }

                const newValueLabel =
                    option.options.find((opt: { [key: string]: any }) => opt.value === newValue)?.labelValue || ''

                onDifferentSettingsUpdate(tab, newValueLabel, prevValueLabel, option.valueKey)
                updateValue(newValue)
            },
            [tab, option, value, updateValue, onDifferentSettingsUpdate]
        )

        const loadOptionSetting = useCallback(
            (data: any, shouldUpdateConfig = true) => {
                updateValue(data.value, false, shouldUpdateConfig)
            },
            [updateValue]
        )

        useImperativeHandle(ref, () => ({ name: option.key, loadOptionSetting }), [option.key, loadOptionSetting])

        return (
            <Flex gap={4} alignItems='center' justifyContent='space-between'>
                <Typography type='text-regular'>{t(option.name)}</Typography>
                <Flex alignItems='center'>
                    <SimpleDropdown
                        classNames={{ header: styles.dropdown }}
                        options={option.options}
                        value={value}
                        onChange={(value) => handleChange(Number(value))}
                    />
                </Flex>
            </Flex>
        )
    }
)

export default DropdownSettings
