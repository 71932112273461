import { CHECKOUT_PAGE, makeRoute } from '@root/constants/routes'
import { FC, useEffect } from 'react'

const FailedPayment: FC = () => {
    useEffect(() => {
        window.parent.postMessage(
            {
                type: 'REDIRECT',
                url: makeRoute(CHECKOUT_PAGE + '?action=payment&success=false')
            },
            'https://fto-staging.forextester.com'
        )
    }, [])

    return <div>Failed payment page</div>
}

export default FailedPayment
