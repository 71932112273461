import { TIndicator } from './IndicatorUnit'

export class TIndicatorDescriptor extends TIndicator {
    ReassignInfo(Symbol: any): void {
        //FIXME: throw new Error("Method not implemented. TIndicatorDescriptor.ReassignInfo");
    }
    SetCount(count: number): void {
        //FIXME:  throw new Error("Method not implemented. TIndicatorDescriptor.SetCount");
    }

    constructor(shortName: string) {
        super(shortName)
    }
}
