import { HLineJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { TBasicPaintTool } from '../../../charting/paint_tools/BasicPaintTool'
import { TPoint, TRect } from '../../../delphi_compatibility/DelphiBasicTypes'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { PaintToolNames } from '../PaintToolNames'
import { TPaintToolStatus, TPaintToolType, TPointInfo } from '../PaintToolsAuxiliaryClasses'
import GlobalOptions from '../../../globals/GlobalOptions'
import DelphiBuiltInFunctions from '../../../delphi_compatibility/DelphiBuiltInFunctions'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { TChart } from '../../chart_classes/BasicChart'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TPtHLine extends TBasicPaintTool {
    protected RText!: string
    public mainColor: string

    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptHLine)
        this.RText = ''
        this.fToolType = TPaintToolType.tt_HLine
        this.fMaxPoints = 1
        this.fClosedPolygon = false
        this.CursorStyle = CustomCursorPointers.crCursorHLine
        this.icon = 55
        this.mainColor = '#2F80ED'

        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptHLine)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptHLine)
        }

        if (!styles) throw new StrangeError('Default styles for HLine are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
        this.mainColor = this.fLineStyle.color
    }

    clone(): TPtHLine {
        const cloneObj = new TPtHLine(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        cloneObj.RText = this.RText
        cloneObj.mainColor = this.mainColor
        return cloneObj
    }

    public toJson(): HLineJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            RText: this.RText,
            mainColor: this.mainColor
        }
    }

    public fromJSON(json: HLineJSON) {
        super.fromJSON(json)

        this.RText = json.RText
        this.mainColor = json.mainColor
    }

    public EdgeUnderMouse(x: number, y: number): number {
        if (
            DelphiBuiltInFunctions.InRange(
                y,
                this.fPoints[0].y - GlobalOptions.Options.MouseSensitivity,
                this.fPoints[0].y + GlobalOptions.Options.MouseSensitivity
            )
        ) {
            return 0
        } else {
            return -1
        }
    }

    public Paint(): void {
        const R: TRect = this.chartPaintRect
        const p1: TPointInfo = new TPointInfo(R.Left, this.fPoints[0].y)
        const p2: TPointInfo = new TPointInfo(R.Right, this.fPoints[0].y)

        if (this.fStatus === TPaintToolStatus.ts_Completed) {
            this.fLineStyle.color = this.mainColor
        } else {
            this.fLineStyle.color = '#D0D5DD'
        }

        if (this.Highlighted) {
            const points = [new TPoint(R.Left, this.fPoints[0].y), new TPoint(R.Right, this.fPoints[0].y)]
            this.PaintHoverLine(points)
        }
        this.PaintLineWithText(p1, p2, this.fText)

        if (this.RText !== '') {
            const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas

            const textWidth = gdiPlusCanvas.TextWidth(this.RText, this.font)
            const textHeight = gdiPlusCanvas.TextHeight('0', this.font)
            gdiPlusCanvas.textOut(
                R.Right - textWidth - 2,
                this.fPoints[0].y - textHeight - 1,
                this.RText,
                this.font,
                this.brush
            )
        }

        if (this.fSelected || this.fHighlighted) {
            this.PaintMarkers()
        }

        this.fPoints[0].x = R.Width / 2
        this.fPoints[0].time = this.chart.GetBarDateFromX(this.fPoints[0].x)
    }

    public PaintMarkers(): void {
        let icon: number
        if (this.Selected) {
            icon = 5
        } else {
            icon = 4
        }

        if (this.Highlighted && this.HighlightedPoint === 0) {
            icon++
        }

        const R: TRect = this.chartPaintRect
        this.PaintMarker(R.Width / 2, this.fPoints[0].y, icon)
    }

    get visible(): boolean {
        const R: TRect = this.chartPaintRect
        return this.fPoints[0].y >= R.Top && this.fPoints[0].y <= R.Bottom
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },

            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, { toolType: PaintToolNames.ptHLine, toolName: 'hLine' })
    }

    ImportFromDialog(): void {
        const { getKeyValueData, resetToolSettings } = GraphToolStore // Use the store/context

        const formattedToolSettings = getKeyValueData()

        this.chart.ChartWindow.saveStateWithNotify()

        this.description = formattedToolSettings.description
        this.fLineStyle = formattedToolSettings.lineStyle
        this.mainColor = this.fLineStyle.color

        this.saveToManager()

        resetToolSettings()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptHLine, {
            lineStyle: this.fLineStyle.getSerialized(),
            toolName: PaintToolNames.ptHLine
        })
    }

    public set rText(value: string) {
        this.RText = value
    }

    setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        this.chart.ChartWindow.saveStateWithNotify()

        this.mainColor = styles.color
        super.setLineStylesParams(styles)

        this.saveToManager()

        this.updatedToolAndLinkedTools()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }
    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    public assign(tool: TPtHLine, isCopy: boolean = false): void {
        super.assign(tool, isCopy)

        this.mainColor = tool.mainColor
    }
}

// Registration of the paint tool will be handled differently in a web application

PaintToolManager.RegisterPaintTool(PaintToolNames.ptHLine, TPtHLine)
