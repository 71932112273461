import { FC, ReactNode, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import cx from 'classnames'
import { observer } from 'mobx-react-lite'

import { Button, Flex, Tooltip, addModal, Separator } from '@fto/ui'

import { Icon, IconButton } from '@fto/icons'

import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import TimeframeStore from '@fto/lib/store/timeframe'

// import Notifications from '@root/components/AppHeader/components/Notifications'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { DASHBOARD_ROUTE, makeRoute } from '@root/constants/routes'

import ChartModeDropdown from './components/Dropdowns/ChartModeDropdown'
import HelpDropdown from './components/Dropdowns/HelpDropdown'
import IndicatorDropdown from './components/Dropdowns/components/IndicatorDropdown/IndicatorDropdown'
import LayoutDropdown from './components/Dropdowns/LayoutDropdown'
import ProfileDropdown from './components/Dropdowns/ProfileDropdown'
import ProjectOptionsDropdown from './components/Dropdowns/ProjectOptionsDropdown'
import { showErrorToast } from '@root/utils/toasts'
import { ChartInfoType } from '@fto/chart_components/types/chartTypes'
import globalChartsStore from '@fto/lib/store/globalChartsStore'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import TasksButton from './components/TasksButton'
import SaveProjectButton from './components/SaveProjectButton'
import educationStore from '@fto/lib/store/educationStore'
import TimeFramesBlock from './components/TimeFramesBlock'
import ShareProjectButton from './components/ShareProjectButton'
import Slider from './components/Slider'

type RibbonProps = {
    selectedChartInfo: ChartInfoType
    setSelectedChartInfo: (value: ChartInfoType) => void
    setShowSettings: (value: boolean) => void
    setShowKeyboardShortcuts: (value: boolean) => void
}

const WithSeparator: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Flex alignItems='center'>
            {children}
            <Separator margin='4px' height='24px' color='gray-500' />
        </Flex>
    )
}

const Ribbon: FC<RibbonProps> = observer(
    ({ selectedChartInfo, setSelectedChartInfo, setShowSettings, setShowKeyboardShortcuts }) => {
        const { t } = useTranslation()

        const { timeframe, preselectedTimeFrames, customTimeframes } = TimeframeStore
        const { data: educationData } = educationStore
        const {
            data: { activeChartSymbol }
        } = globalChartsStore
        const isFourCharts = selectedChartInfo.count === 4
        const navigate = useNavigate()

        const redirectToDashboard = useCallback(() => {
            navigate(makeRoute(DASHBOARD_ROUTE))
        }, [])

        const addChart = useCallback(() => {
            isFourCharts
                ? showErrorToast({ title: t('ribbon.maxChartsHeader'), message: t('ribbon.maxChartsMessage') })
                : addModal(MODAL_NAMES.chart.changeSymbol, { modalType: 'addChart' })
        }, [isFourCharts])

        const handleJumpTo = useCallback(() => {
            addModal(MODAL_NAMES.chart.jumpToDate)
        }, [])

        const handleAutoScale = useCallback(() => {
            const chart = GlobalChartsController.Instance.getActiveChart()
            if (!chart) return
            chart.AutoScaleOn()
        }, [])

        return (
            <Flex className={styles.root}>
                <WithSeparator>
                    <Tooltip content={t('ribbon.tooltips.fto')}>
                        <IconButton
                            className={styles.logoButton}
                            size={28}
                            name='logo-small'
                            onClick={redirectToDashboard}
                        />
                    </Tooltip>
                </WithSeparator>
                <Slider dependencyListToCheckScroll={[customTimeframes]}>
                    <Flex gap={4} className={styles.ribbon} justifyContent='space-between' flexGrow={1} block>
                        <Flex alignItems='center'>
                            <WithSeparator>
                                <ProjectOptionsDropdown />
                            </WithSeparator>
                            <WithSeparator>
                                <Button
                                    withTooltip
                                    tooltipText={t('ribbon.tooltips.' + (isFourCharts ? 'chartLimit' : 'addChart'))}
                                    onClick={addChart}
                                    size='compact'
                                    type='tetriatry-white'
                                    label={t('ribbon.addChart')}
                                    before={<Icon name='circled-plus' />}
                                    classNames={{ content: cx({ [styles.disabled]: isFourCharts }) }}
                                />
                            </WithSeparator>
                            <WithSeparator>
                                <ChartModeDropdown />
                            </WithSeparator>
                            <WithSeparator>
                                <TimeFramesBlock
                                    activeChartSymbol={activeChartSymbol}
                                    timeframe={timeframe}
                                    preselectedTimeFrames={preselectedTimeFrames}
                                    customTimeframes={customTimeframes}
                                />
                            </WithSeparator>
                            <WithSeparator>
                                <IndicatorDropdown />
                            </WithSeparator>
                            {!educationData.isShown && (
                                <WithSeparator>
                                    <Button
                                        withTooltip
                                        tooltipText={t('ribbon.tooltips.jumpTo')}
                                        classNames={{ content: cx(styles.orderButton, styles.smallButton) }}
                                        onClick={handleJumpTo}
                                        before={<Icon name='jump-to' />}
                                        size='compact'
                                        type='tetriatry-white'
                                        label={t('ribbon.jumpTo')}
                                    />
                                </WithSeparator>
                            )}
                            <Flex gap={8}>
                                <LayoutDropdown setSelectedChartInfo={setSelectedChartInfo} />
                                <Button
                                    withTooltip
                                    tooltipText={t('ribbon.tooltips.newOrder')}
                                    classNames={{ content: cx(styles.orderButton, styles.smallButton) }}
                                    before={<Icon color='var(--color-white)' name='menu-new-order' size={16} />}
                                    onClick={() => addModal(MODAL_NAMES.chart.orderModal, { source: 'ribbon' })}
                                    label={t('ribbon.newOrder')}
                                    size='compact'
                                />
                            </Flex>
                        </Flex>
                        <Flex>
                            <TasksButton />
                            <Button
                                before={<Icon name='contact' />}
                                data-featurebase-feedback-portal='true'
                                classNames={{ content: cx(styles.opinionButton) }}
                                label={t('ribbon.shareOpinion')}
                                size='compact'
                            ></Button>
                            <WithSeparator>
                                <Tooltip content={t('ribbon.tooltips.autoScale')}>
                                    <IconButton
                                        className={styles.iconButton}
                                        size={24}
                                        name='autoscale'
                                        onClick={handleAutoScale}
                                    />
                                </Tooltip>
                            </WithSeparator>
                            <WithSeparator>
                                <SaveProjectButton />
                            </WithSeparator>
                            <WithSeparator>
                                <ShareProjectButton />
                            </WithSeparator>
                            <WithSeparator>
                                <Tooltip content={t('ribbon.tooltips.chartSettings')}>
                                    <IconButton
                                        className={styles.iconButton}
                                        size={24}
                                        name='settings'
                                        onClick={() => setShowSettings(true)}
                                    />
                                </Tooltip>
                            </WithSeparator>
                            {/* <WithSeparator>
                    <TriggerOverlay optionsRenderer={<Notifications />} outStyledList>
                        <CounterBadge
                            classNames={{ root: styles.iconButton }}
                            value={1}
                            size={16}
                            backgroundColor='red-500'
                            color='white'
                        >
                            <Icon size={24} name='bell' color='var(--color-gray-1000)' />
                        </CounterBadge>
                    </TriggerOverlay>
                </WithSeparator> */}
                            <WithSeparator>
                                <HelpDropdown setShowKeyboardShortcuts={setShowKeyboardShortcuts} />
                            </WithSeparator>
                            <Flex alignItems='center'>
                                <ProfileDropdown />
                            </Flex>
                            {/* <Button
                    disabled
                    classNames={{ root: styles.upgradeButtonRoot, content: styles.upgradeButton }}
                    before={<Icon name='lightning' color='var(--color-orange-400)' />}
                    label={t('ribbon.upgrade')}
                    size='compact'
                /> */}
                        </Flex>
                    </Flex>
                </Slider>
            </Flex>
        )
    }
)

export default Ribbon
