import React, { FC, useMemo } from 'react'
import dayjs from 'dayjs'

import { Button, Flex, Typography, addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'

import styles from '../../profile.module.scss'
import { Trans, useTranslation } from 'react-i18next'

export const SubscriptionInfoActive: FC = () => {
    const { trialPeriodEnd, isTrialExpired } = useAppSelector($getUser)
    const { t } = useTranslation()

    const date = useMemo(() => {
        return dayjs(trialPeriodEnd, 'MM/DD/YYYY h:mm A').locale('en').format('MMMM DD, YYYY')
    }, [trialPeriodEnd])

    return (
        <Flex direction='column' className={styles.subscriptionInfo}>
            <Flex justifyContent='space-between' className={styles.infoContent}>
                <Flex alignItems='flex-start' direction='column' gap={4}>
                    <Typography type='h4-bold'>
                        {isTrialExpired ? t('profile.subscriptionPlan') : t('profile.trial')}
                    </Typography>
                    <span>
                        <Typography type='interface-regular'>
                            {isTrialExpired ? (
                                t('profile.subscriptionInfoActive')
                            ) : (
                                <Trans
                                    i18nKey='profile.subscriptionInfoTrial'
                                    components={{ date: <Typography type='interface-regular'>{date}</Typography> }}
                                />
                            )}
                        </Typography>
                    </span>
                </Flex>
                {!isTrialExpired && (
                    <Flex alignItems='flex-end' className={styles.infoButton}>
                        <Button
                            label={t('profile.suspend')}
                            type='tetriatry-gray'
                            onClick={() => {
                                addModal(MODAL_NAMES.userProfile.suspendSubscription)
                            }}
                        />
                    </Flex>
                )}
            </Flex>
        </Flex>
    )
}
