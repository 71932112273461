import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { Modal, removeModal, Typography, Flex, InputField, TriggerOverlay } from '@fto/ui'
import { Icon } from '@fto/icons'

import MessageInformer from '@root/components/MessageInformer'
import AppCalendar, { CalendarValue } from '@root/components/Common/AppCalendar/AppCalendar'
import { TIME_TEMPLATES } from '@root/constants/timeTemplates'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { fireMixpanelEvent } from '@root/utils/api'

import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import DateConversionError from '@fto/lib/delphi_compatibility/DateTimeAuxiliary/DateConversionError'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'

enum EActionType {
    rollTesting = 'rollTesting',
    scrollCharts = 'scrollCharts'
}

function fireMixpanelEventAboutJumpTo(date: TDateTime) {
    const currentTestingDate = GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTimeWithTimezoneAndDST()
    const jumpFrom = dayjs(DateUtils.toUnixTimeMillisecondsUTC(currentTestingDate))
    const jumpTo = dayjs(DateUtils.toUnixTimeMillisecondsUTC(date))

    fireMixpanelEvent('jump_to', { time_skipped: Math.abs(jumpFrom.diff(jumpTo, 'second')) })
}

export function JumpToDateModal() {
    const { t } = useTranslation()

    const getCurrentDateIfSeeked = () => {
        if (GlobalSymbolList.SymbolList.IsSeeked) {
            return GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true)
        } else {
            return DateUtils.EncodeDate(2021, 1, 1)
        }
    }

    const [date, setDate] = useState(getCurrentDateIfSeeked())
    const [inputValue, setInputValue] = useState(
        DateUtils.FormatDateTime(TIME_TEMPLATES.FULL_DATE, getCurrentDateIfSeeked())
    )
    const [jumpBlocked, setJumpBlocked] = useState(false)
    const [valueIsInvalid, setValueIsInvalid] = useState(false)
    const [selectedAction, setSelectedAction] = useState(EActionType.rollTesting)
    const [minDate, setMinDate] = useState(new Date('2020-01-01')) // Example initial minimum date
    const [maxDate, setMaxDate] = useState(new Date()) // Example initial maximum date (current date)

    useEffect(() => {
        const recalculateDates = () => {
            const newMinDate = DateUtils.ToDate(GlobalSymbolList.SymbolList.VeryFirstDateInHistoryAmongCurrentSymbols)
            const newMaxDate = DateUtils.ToDate(GlobalSymbolList.SymbolList.VeryLastDateInHistoryAmongCurrentSymbols)
            setMinDate(newMinDate)
            setMaxDate(newMaxDate)
        }

        recalculateDates()

        setDate(getCurrentDateIfSeeked())
    }, [])

    useEffect(() => {
        switch (selectedAction) {
            case EActionType.rollTesting: {
                setJumpBlocked(false)
                break
            }
            case EActionType.scrollCharts: {
                const currentDate = GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true)
                const dateToJump = DateUtils.FromDate(getJSDateWithTimezoneOffset(date))

                if (dateToJump > currentDate) {
                    setJumpBlocked(true)
                } else {
                    setJumpBlocked(false)
                }
                break
            }
            default: {
                throw new StrangeError('Unknown action type')
            }
        }
    }, [date, selectedAction])

    const handleJumpToDate = useCallback(() => {
        const convertedToInnerLibDateByTimezoneAndDST =
            GlobalTimezoneDSTController.Instance.convertToInnerlibDateTimeByTimezoneAndDst(date)
        if (selectedAction === EActionType.rollTesting) {
            GlobalTestingManager.TestingManager.RollTestingTo(convertedToInnerLibDateByTimezoneAndDST)
        } else {
            GlobalChartsController.Instance.ScrollAllChartsToDate(convertedToInnerLibDateByTimezoneAndDST)
        }

        fireMixpanelEventAboutJumpTo(convertedToInnerLibDateByTimezoneAndDST)

        removeModal(MODAL_NAMES.chart.jumpToDate)
    }, [date])

    const setDateBasedOnInputValue = (e: string) => {
        try {
            const parsedDate = DateUtils.FromString(e)
            setDate(parsedDate)
            setValueIsInvalid(false)
        } catch (error) {
            if (error instanceof DateConversionError) {
                setValueIsInvalid(true)
            }
        }
    }

    const handleDateChange = useCallback((newDate: CalendarValue) => {
        const jsDate = newDate as Date

        // const offset = jsDate.getTimezoneOffset()

        // const dateTime = DateUtils.IncMinute(DateUtils.FromDate(jsDate), -offset)
        const dateTime = DateUtils.FromDate(jsDate)

        setDate(dateTime)
        const newDateString = DateUtils.FormatDateTime(TIME_TEMPLATES.FULL_DATE, dateTime)
        setInputValue(newDateString)
    }, [])

    const getJSDateWithTimezoneOffset = (dateTime: TDateTime) => {
        const jsDate = DateUtils.ToDate(dateTime)
        const offset = jsDate.getTimezoneOffset()
        return new Date(jsDate.setMinutes(jsDate.getMinutes() + offset))
    }

    return (
        <Modal name={MODAL_NAMES.chart.jumpToDate} withCloseIcon size='sm'>
            <Modal.Header withBorderBottom>{t('jumpToModal.title')}</Modal.Header>
            <Modal.Content>
                <Flex direction='column' gap={12}>
                    <Flex direction='column' gap={4}>
                        <Typography type='interface-regular' color='gray-900'>
                            {t('jumpToModal.dateLabel')}
                        </Typography>

                        <InputField
                            prefix={
                                <TriggerOverlay
                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    optionsRenderer={
                                        <AppCalendar
                                            className={styles.calendar}
                                            key='calendar'
                                            initialDate={getJSDateWithTimezoneOffset(date)}
                                            setCalendarValue={handleDateChange}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            showTime={true}
                                        />
                                    }
                                >
                                    <Icon name='date' size={18} color='var(--color-gray-700)' />
                                </TriggerOverlay>
                            }
                            type='text'
                            value={inputValue} // Use appropriate template to include time
                            onChange={(e) => setDateBasedOnInputValue(e)} // Make it editable
                        />
                    </Flex>
                    <Flex direction='row' gap={12} alignItems='center'>
                        <input
                            type='radio'
                            id='rollTesting'
                            name='action'
                            value={EActionType.rollTesting}
                            checked={selectedAction === 'rollTesting'}
                            onChange={() => setSelectedAction(EActionType.rollTesting)}
                        />
                        <label htmlFor='rollTesting'>{t('jumpToModal.rollTesting')}</label>
                        <input
                            type='radio'
                            id='scrollCharts'
                            name='action'
                            value={EActionType.scrollCharts}
                            checked={selectedAction === 'scrollCharts'}
                            onChange={() => setSelectedAction(EActionType.scrollCharts)}
                        />
                        <label htmlFor='scrollCharts'>{t('jumpToModal.scrollCharts')}</label>
                    </Flex>
                    <MessageInformer disappearing={false} type='warning' isShown={jumpBlocked}>
                        <Typography color='orange-700' type='interface-regular'>
                            {t(`jumpToModal.warningScrollToFuture`)}
                        </Typography>
                    </MessageInformer>
                    <MessageInformer disappearing={false} type='warning' isShown={valueIsInvalid}>
                        <Typography color='orange-700' type='interface-regular'>
                            {t(`jumpToModal.invalidValue`, { format: TIME_TEMPLATES.FULL_DATE })}
                        </Typography>
                    </MessageInformer>
                </Flex>
            </Modal.Content>
            <Modal.Controls
                submitText={t(`jumpToModal.cta`)}
                disabled={jumpBlocked && !valueIsInvalid}
                onSubmit={handleJumpToDate}
            />
        </Modal>
    )
}
