import TStringList from '../delphi_compatibility/StringList'
import { TLineStyleRec } from '../drawing_interface/GraphicObjects'
import { NotImplementedError } from '../utils/common_utils'
import { Common, TMyObjectList } from '../ft_types/common/Common'
import { StrsConv } from '../ft_types/common/StrsConv'
import { TOffsStringList } from '../ft_types/common/OffsStringList'
import GlobalTimeframes from '../globals/GlobalTimeframes'
import GlobalSymbolList from '../globals/GlobalSymbolList'
import {
    TDrawStyle,
    TOptionType,
    TOptValue,
    TOptValue_bool,
    TOptValue_DateTime,
    TOptValue_number,
    TOptValue_Session,
    TOptValue_SessionsArray,
    TOptValue_str
} from '../extension_modules/indicators/api/IndicatorInterfaceUnit'
import StrangeError from '../common/common_errors/StrangeError'

export type TReplaceStrProc = (dest: string, source: string) => void

export class TDLLOptionRec {
    private fSelfValue!: TOptValue // self value memory
    private fValue: TOptValue // pointer to value memory (to self or to dll)
    private fReplaceStrProc: TReplaceStrProc // replacement string procedure

    name: string
    alias: string
    OptionType: number
    values!: TStringList
    LowValue!: number
    HighValue!: number
    digits!: number
    invisible!: boolean

    constructor(
        aName: string,
        aType: number,
        aPtr: TOptValue,
        inv: boolean,
        ReplStrProc: TReplaceStrProc | null = null
    ) {
        this.name = aName
        this.alias = aName
        this.OptionType = aType
        this.fValue = aPtr
        this.fReplaceStrProc = ReplStrProc || Common.SelfReplaceStrProc
        this.invisible = inv

        //TODO: set min and max values based on the type of the option (meaning C++ type that comes from WASM)

        this.values = new TStringList()
        this.RefreshValues()
    }

    public get Value(): string {
        return this.GetValue()
    }

    public set Value(value: string) {
        this.SetValue(value)
    }

    GetValue(): string {
        switch (this.OptionType) {
            case TOptionType.at_Levels: {
                const str_optValue = this.fValue as TOptValue_str
                return str_optValue.value
            }
            case TOptionType.ot_Longword:
            case TOptionType.ot_Integer:
            case TOptionType.ot_Timeframe: {
                const num_optValue = this.fValue as TOptValue_number
                return num_optValue.value.toString()
            }

            case TOptionType.ot_double: {
                const double_optValue = this.fValue as TOptValue_number
                return double_optValue.value.toFixed(this.digits)
            }

            case TOptionType.ot_String:
            // eslint-disable-next-line sonarjs/no-duplicated-branches, no-fallthrough
            case TOptionType.ot_Currency: {
                // Assuming Utf8ToString is a function that converts a UTF-8 encoded string to a regular string.
                const str_optValue = this.fValue as TOptValue_str
                return str_optValue.value
            }
            case TOptionType.ot_Boolean: {
                // Simplified boolean representation to match Delphi's ternary-like behavior.
                const bool_optValue = this.fValue as TOptValue_bool
                return bool_optValue.value ? 'Yes' : 'No'
            }
            case TOptionType.ot_EnumType: {
                // Check for undefined to handle illegal values.
                const enum_optValue = this.fValue as TOptValue_number
                const enumValue = this.values[enum_optValue.value]
                return enumValue ?? 'unsupported value'
            }
            case TOptionType.ot_LineStyle: {
                let isNeedToShowLineProperties = true
                const lineStyle_optValue = this.fValue as TLineStyleRec
                if (lineStyle_optValue.DrawingStyle === TDrawStyle.ds_Histogram) {
                    isNeedToShowLineProperties = false
                }
                // Assuming GetShortStr is a method of the LineStyle object that returns a string representation.
                return lineStyle_optValue.GetShortStr(isNeedToShowLineProperties)
            }
            case TOptionType.ot_Color: {
                // Assuming StrColor is a function that converts a color value to a string representation.
                const color_optValue = this.fValue as TOptValue_str
                return color_optValue.value
            }
            // case TDLLOptionType.ot_HotKey:
            //   throw new NotImplementedError("TDLLOptionType.ot_HotKey is not implemented yet");
            // return this.fValue.HotKey.toString();
            case TOptionType.ot_DateTime: {
                // Assuming StrDateTime is a function that formats a TDateTime value to a string.
                const datetime_optValue = this.fValue as TOptValue_DateTime
                return StrsConv.StrDateTime(datetime_optValue.value, true)
            }
            case TOptionType.ot_Separator: {
                return ''
            }
            case TOptionType.ot_Session: {
                const session_optValue = this.fValue as TOptValue_Session
                return session_optValue.toString()
            }
            case TOptionType.ot_SessionsArray: {
                const sessionArray_optValue = this.fValue as TOptValue_SessionsArray
                return sessionArray_optValue.toString()
            }
            default: {
                throw new NotImplementedError(`GetValue is not implemented for OptionType: ${this.OptionType}`)
            }
        }
    }

    // Improved the implementation of SetValue method by ensuring that string to number conversions
    // handle potential NaN results and by using the Utf8Encode function for string conversions
    // to maintain consistency with the Delphi code. Also, added error handling for parseInt
    // to ensure that non-numeric strings do not cause runtime errors.
    SetValue(s: string): void {
        switch (this.OptionType) {
            case TOptionType.ot_Session: {
                let session_optValue = this.fValue as TOptValue_Session
                TOptValue_Session.updateFromString(s, session_optValue)
                break
            }
            case TOptionType.ot_SessionsArray: {
                let sessionArray_optValue = this.fValue as TOptValue_SessionsArray
                TOptValue_SessionsArray.updateFromString(s, sessionArray_optValue)
                break
            }
            case TOptionType.at_Levels: {
                const str_optValue = this.fValue as TOptValue_str
                str_optValue.value = s
                break
            }
            case TOptionType.ot_Longword:
            case TOptionType.ot_Integer: {
                const tOptNum = this.fValue as TOptValue_number
                const numberValue = parseInt(s, 10)
                tOptNum.value = numberValue
                break
            }
            case TOptionType.ot_Timeframe: {
                //TODO: maybe check Timeframe value here?
                // Using parseInt with a radix of 10 to ensure proper number parsing
                const num_optValue = this.fValue as TOptValue_number
                const numValue = parseInt(s, 10)
                if (isNaN(numValue)) throw new StrangeError('Invalid number format')

                num_optValue.value = Math.abs(numValue)
                break
            }

            case TOptionType.ot_double: {
                // Assuming StrsConv.GetDouble handles NaN internally or throws an error
                const double_optValue = this.fValue as TOptValue_number
                double_optValue.value = StrsConv.GetDouble(s, this.digits)
                break
            }
            case TOptionType.ot_String:
            case TOptionType.ot_Currency: {
                {
                    //TODO: maybe check Currency value here?
                    // Using Utf8Encode to ensure the string is properly encoded
                    const str_optValue = this.fValue as TOptValue_str
                    str_optValue.value = s
                }
                break
            }
            case TOptionType.ot_Boolean: {
                const bool_optValue = this.fValue as TOptValue_bool
                bool_optValue.value = s === 'Yes'
                break
            }
            case TOptionType.ot_EnumType: {
                // Assuming values.IndexOf handles case sensitivity and returns -1 for not found
                const enum_optValue = this.fValue as TOptValue_number
                enum_optValue.value = this.values.IndexOf(s)
                break
            }
            case TOptionType.ot_LineStyle: {
                // Assuming SetShortStr is implemented and handles the string conversion
                const lineStyle_optValue = this.fValue as TLineStyleRec
                lineStyle_optValue.SetShortStr(s)
                break
            }
            case TOptionType.ot_Color: {
                // Assuming StrsConv.GetColor handles color conversion or throws an error
                const color_optValue = this.fValue as TOptValue_str
                color_optValue.value = StrsConv.GetColor(s, true)
                break
            }
            case TOptionType.ot_Separator: {
                //do not need to do anything here
                break
            }
            //TODO: case TDLLOptionType.ot_HotKey:
            //   throw new NotImplementedError("TDLLOptionType.ot_HotKey is not implemented yet");
            // const hotKeyValue = parseInt(s, 10);
            // if (isNaN(hotKeyValue)) throw new StrangeError('Invalid number format');
            // this.fValue.HotKey = hotKeyValue;
            // break;
            case TOptionType.ot_DateTime: {
                // Assuming DateUtils.GetDateTime handles date conversion or throws an error
                const datetime_optValue = this.fValue as TOptValue_DateTime
                datetime_optValue.value = StrsConv.GetDateTime(s)
                break
            }
            default: {
                throw new NotImplementedError(`SetValue is not implemented for OptionType: ${this.OptionType}`)
            }
        }
    }

    // Improved the implementation by using a switch statement for better readability and potential future extensibility.
    // Also, ensured that the 'timeframes' and 'SymbolList' are accessed through 'this' to maintain proper scope.
    RefreshValues(): void {
        this.values.Clear() // Clear the values once, to avoid repetition in each case

        switch (this.OptionType) {
            case TOptionType.ot_Timeframe: {
                // Update timeframes
                for (let i = 0; i < GlobalTimeframes.Timeframes.count; i++) {
                    this.values.Add(GlobalTimeframes.Timeframes[i])
                }
                break
            }

            case TOptionType.ot_Currency: {
                // Update symbols
                for (let i = 0; i < GlobalSymbolList.SymbolList.Count; i++) {
                    this.values.Add(GlobalSymbolList.SymbolList.GetItem(i).symbolInfo.SymbolName)
                }
                break
            }

            //TODO: Consider adding default case if there are more OptionTypes or if there's a need to handle unexpected values.
        }
    }
}

export class TDllOptions extends TMyObjectList<TDLLOptionRec> {
    DeleteByMask(mask: string): void {
        // Iterate backwards through the items array to safely remove items without affecting the loop indexing
        for (let i = this.count - 1; i >= 0; i--) {
            if (this.GetItem(i).name.startsWith(mask)) {
                this.Delete(i)
            }
        }
    }

    Assign(options: TDllOptions): void {
        for (let i = 0; i < options.Count; i++) {
            const option = options[i] // Assuming items is the property that gives access to the option by index
            const rec = this.GetOptionRec(option.name)
            if (rec) {
                rec.Value = option.Value
            }
        }
    }

    AddOptionValue(name: string, value: string): void {
        const rec = this.GetOptionRec(name)
        if (rec != null) {
            rec.values.push(value)
        }
    }

    SetOptionRange(name: string, LowValue: number, HighValue: number): void {
        const rec = this.GetOptionRec(name)
        // Check for both null and undefined to ensure rec is a valid object.
        if (rec != null) {
            // Using != to check for both null and undefined.
            rec.LowValue = LowValue
            rec.HighValue = HighValue
        }
    }

    GetOptionRec(name: string): TDLLOptionRec | undefined {
        for (let i = 0; i < this.Count; i++) {
            const item = this.GetItem(i)
            if (item.name === name) {
                return item
            }
        }
        return undefined
    }

    RegOption(
        aName: string,
        aType: number,
        pointer: TOptValue,
        inv = false,
        ReplStrProc: TReplaceStrProc | null = null
    ): void {
        // Define a set of valid option types for quick lookup
        const validOptionTypes: Set<number> = new Set([
            TOptionType.ot_Longword,
            TOptionType.ot_Integer,
            TOptionType.ot_double,
            TOptionType.ot_String,
            TOptionType.ot_Boolean,
            TOptionType.ot_EnumType,
            TOptionType.ot_Timeframe,
            TOptionType.ot_Currency,
            TOptionType.ot_LineStyle,
            TOptionType.ot_Separator,
            TOptionType.ot_Color,
            TOptionType.ot_DateTime,
            TOptionType.at_Levels,
            TOptionType.ot_Session,
            TOptionType.ot_SessionsArray
            //TODO: TDLLOptionType.ot_HotKey,
        ])

        // Check if the provided type is valid
        if (!validOptionTypes.has(aType)) {
            throw new StrangeError(`Invalid option type: ${aType}`)
        }

        // Create a new option record
        const opt: TDLLOptionRec = new TDLLOptionRec(aName, aType, pointer, inv, ReplStrProc)

        // Initialize the option values based on the type
        switch (aType) {
            case TOptionType.ot_Boolean: {
                opt.values = TStringList.CreateFromArray(['Yes', 'No'])
                break
            }
            // Add cases for other types if they have specific initialization logic
        }

        // Add the new option record to the collection
        this.Add(opt)
    }

    LoadFromList(list: TOffsStringList): void {
        list.Reindex()
        for (let i = 0; i < list.Count; i++) {
            const s = list.GetItem(i)
            const rec = this.GetOptionRec(StrsConv.GetSubStr(s, '=')[0])
            if (rec) {
                try {
                    rec.Value = StrsConv.GetSubStr(s, '=')[1]
                } catch (error) {
                    throw new StrangeError(`Dll options LoadFromList - ${(error as Error).message}`)
                    // value assign failed
                }
            }
        }
    }

    SaveToList(list: TOffsStringList): void {
        list.Clear()
        for (let i = 0; i < this.count; i++) {
            if (this[i].OptionType !== TOptionType.ot_Separator) {
                list.Add(`${this[i].name}=${this[i].Value}`)
            }
        }
    }
}
