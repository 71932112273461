import { FC, useCallback, useEffect } from 'react'

import { Flex } from '@fto/ui'
import Header from './components/Header'

import NewsStore from '@fto/lib/store/news'
import { fireMixpanelEvent } from '@root/utils/api'
import { GlobalNewsController } from '@fto/lib/News/GlobalNewsController'

import List from './components/List'

import styles from './index.module.scss'

type Props = {
    handleOpenState: (val: boolean) => void
}

const News: FC<Props> = ({ handleOpenState }) => {
    const onClose = useCallback(() => {
        handleOpenState(false)
    }, [handleOpenState])

    useEffect(() => {
        const {
            params: {
                filters: { interval, priority, selectedCountries }
            }
        } = NewsStore

        const { previous, upcoming } = GlobalNewsController.Instance.getNewsGroupByPreviousUpcoming()

        fireMixpanelEvent('news_sidebar_opened', {
            number_of_upcoming_news_shown: upcoming.length,
            number_of_previous_news_shown: previous.length,
            range_selected: interval,
            priority_selected: priority,
            selected_country_list: selectedCountries,
            selected_countries_total: selectedCountries.length
        })
    }, [])

    return (
        <Flex direction='column' gap={8} className={styles.root}>
            <Header onClose={onClose} />
            <List />
        </Flex>
    )
}

export default News
