import { FC, useEffect, useRef, useState, useCallback } from 'react'

import { Flex, useModalState } from '@fto/ui'

import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'

import { MODAL_NAMES } from '@root/constants/modalNames'
import { ChangeSymbolModal } from '@root/pages/ChartPage/components/ChangeSymbolModal'
import Terminal from '@root/pages/ChartPage/components/Terminal'
import { SetBarForwardBarBackEventsTotalCountType } from '@fto/chart_components/ProjectInterface/types'
import NewsStore from '@fto/lib/store/news'

import MultiChartComponent from '../MultiCharting'
import { ChartInfoType } from '../types/chartTypes'
import Analytics from './components/Analytics'
import Ribbon from './components/Ribbon'
import TestingPanel from './components/TestingPanel'
import GraphToolPanelWrapper from './components/GraphToolPanelWrapper'
import { ToolsSidebar } from './components/ToolsSidebar'

import News from '@fto/chart_components/ProjectInterface/components/News'
import ContextMenuWrapper from './components/ContextMenus/ContextMenuWrapper'
import useChartInitialization from '@root/pages/ChartPage/hooks/useChartInitialization'

import styles from './index.module.scss'
import { getLocalStorage } from '@root/utils/localStorage'
import { PROJECT_STARTED_KEY } from '@root/constants/localStorageKeys'
import { fireMixpanelEvent } from '@root/utils/api'
import { ProjectType } from '@root/types/projects'
import ChartSettingsTab from '@root/pages/ChartPage/components/ChartSettingsTab'
import KeyboardShortcutsTab from '@root/pages/ChartPage/components/KeyboardShortcutsTab'

type Props = {
    setBarForwardBarBackEventsTotalCount: SetBarForwardBarBackEventsTotalCountType
}

const Project: FC<Props> = ({ setBarForwardBarBackEventsTotalCount }) => {
    const [selectedChartInfo, setSelectedChartInfo] = useState<ChartInfoType>(
        GlobalProjectJSONAdapter.Instance.getLayoutSettings()
    )
    const terminalWindowRef = useRef<HTMLDivElement>(null)
    const [showAnalytics, setShowAnalytics] = useState(false)
    const [isNewsOpen, setIsNewsOpen] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const [showKeyboardShortcuts, setShowKeyboardShortcuts] = useState(false)

    const { open: isChangeSymbolModalOpen, props: changeSymbolModalProps } = useModalState(
        MODAL_NAMES.chart.changeSymbol
    )

    useChartInitialization()

    useEffect(() => {
        setSelectedChartInfo(GlobalProjectJSONAdapter.Instance.getLayoutSettings())

        // Fire mixpanel event if there is localstorage key to calculate time of load

        const projectStartedPreselectedEventData: { projectStartedTime: number; projectData: ProjectType } | null =
            getLocalStorage(PROJECT_STARTED_KEY)

        if (projectStartedPreselectedEventData) {
            fireMixpanelEvent('project_started', {
                ...projectStartedPreselectedEventData.projectData,
                project_load_time: (+new Date() - projectStartedPreselectedEventData.projectStartedTime) / 1000,
                total_symbols: projectStartedPreselectedEventData.projectData.Symbols.length
            }).finally(() => {
                localStorage.removeItem(PROJECT_STARTED_KEY)
            })
        }
    }, [])

    useEffect(() => {
        NewsStore.updateParams((prevSettings) => ({
            ...prevSettings,
            isNewsTabOpen: isNewsOpen
        }))
    }, [isNewsOpen])

    return (
        <Flex direction='column' flexGrow={1} className={styles.project} gap={4}>
            <div className={styles.PanelWrapper}>
                <TestingPanel />
                <GraphToolPanelWrapper />
            </div>
            <Ribbon
                selectedChartInfo={selectedChartInfo}
                setSelectedChartInfo={setSelectedChartInfo}
                setShowSettings={setShowSettings}
                setShowKeyboardShortcuts={setShowKeyboardShortcuts}
            />
            <Flex>
                <ToolsSidebar />
                <Flex
                    ref={terminalWindowRef}
                    flexGrow={1}
                    direction='column'
                    className={styles.workspace}
                    justifyContent='space-between'
                >
                    <MultiChartComponent
                        selectedChartInfo={selectedChartInfo}
                        setBarForwardBarBackEventsTotalCount={setBarForwardBarBackEventsTotalCount}
                    />
                    <Terminal
                        terminalWindowRef={terminalWindowRef}
                        setShowAnalytics={setShowAnalytics}
                        setIsNewsOpen={setIsNewsOpen}
                    />
                </Flex>
                {showAnalytics && (
                    <Flex className={styles.wrapper}>
                        <Analytics setShowAnalytics={setShowAnalytics} />
                    </Flex>
                )}
                {isNewsOpen && <News handleOpenState={setIsNewsOpen} />}
                {showSettings && <ChartSettingsTab setShowSettings={setShowSettings} />}
                {showKeyboardShortcuts && (
                    <KeyboardShortcutsTab setShowKeyboardShortcitsTab={setShowKeyboardShortcuts} />
                )}
                {isChangeSymbolModalOpen && (
                    <ChangeSymbolModal {...changeSymbolModalProps} setSelectedChartInfo={setSelectedChartInfo} />
                )}
                <ContextMenuWrapper setIsNewsOpen={setIsNewsOpen} setShowSettings={setShowSettings} />
            </Flex>
        </Flex>
    )
}

export default Project
