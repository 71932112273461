import { t } from 'i18next'

import { TIndexBuffer } from '../api/IIndicatorApi'
import Constants, {
    TDrawStyle,
    TOptValue_number,
    TOptionType,
    TOutputWindow,
    TPenStyle,
    TPriceType
} from '../api/IndicatorInterfaceUnit'
import { UserIndicator } from '../api/UserIndicator'

export default class MFI extends UserIndicator {
    MFIPeriod: TOptValue_number = new TOptValue_number(14)

    // Buffers
    MFIBuffer!: TIndexBuffer
    PositiveMFBuffer!: TIndexBuffer
    NegativeMFBuffer!: TIndexBuffer
    Init(): void {
        this.api.RecalculateMeAlways()
        this.api.IndicatorShortName(t('indicators.mfi'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.AddLevel(0, TPenStyle.psDot, 1, '#ada9a9', 1)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.general.generalFields.period'), TOptionType.ot_Integer, this.MFIPeriod)
        this.api.SetOptionRange(t('indicatorModal.general.generalFields.period'), 1, Constants.MAX_INT)

        this.MFIBuffer = this.api.CreateIndexBuffer()
        this.PositiveMFBuffer = this.api.CreateIndexBuffer()
        this.NegativeMFBuffer = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(1)
        this.api.SetIndexBuffer(0, this.MFIBuffer)

        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#0000FF')
        this.api.SetIndexLabel(0, t('indicatorModal.mfi.fields.mfi'))
    }

    Calculate(index: number): void {
        if (index + this.MFIPeriod.value >= this.api.Bars()) {
            //TODO:IN remove this when SetEmptyValue is added
            this.MFIBuffer.setValue(index, 2147483647)
            return
        }

        let PositiveMF = 0.0
        let NegativeMF = 0.0

        let CurrentTP = this.api.GetPrice(index, TPriceType.pt_HLC3)

        for (let j = 0; j < this.MFIPeriod.value; j++) {
            let PreviousTP = this.api.GetPrice(index + j + 1, TPriceType.pt_HLC3)
            if (CurrentTP > PreviousTP) {
                PositiveMF += this.api.Volume(index + j) * CurrentTP
            } else if (CurrentTP < PreviousTP) {
                NegativeMF += this.api.Volume(index + j) * CurrentTP
            }
            CurrentTP = PreviousTP
        }

        if (NegativeMF !== 0.0) {
            let value = 1 + PositiveMF / NegativeMF
            this.MFIBuffer.setValue(index, 100 - 100 / (1 + PositiveMF / NegativeMF))
        } else {
            this.MFIBuffer.setValue(index, 100)
        }
    }
}
