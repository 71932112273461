import React, { FC, useMemo } from 'react'

import { Flex, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'
import { CellType } from '@root/pages/ChartPage/components/Terminal/types'
import { TTradePositionStatus, TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import {
    getOrderTypeLabel,
    getValuesParams
} from '@root/pages/ChartPage/components/Terminal/components/Table/components/Body/components/Row/components/CellRenderer/utils'
import { useTranslation } from 'react-i18next'

type Props = {
    type: CellType
    value?: any
}

const CellRenderer: FC<Props> = ({ type, value }) => {
    const { t } = useTranslation()

    const cellComponent = useMemo(() => {
        const params = getValuesParams(type, value)
        const renderStatus = (value: TTradePositionStatus) => {
            switch (value) {
                case TTradePositionStatus.tps_Cancelled: {
                    return <Icon name='close-rounded' size={12} color='var(--color-red-500)' />
                }
                case TTradePositionStatus.tps_Filled: {
                    return <Icon name='check-rounded' size={12} color='var(--color-green-500)' />
                }
                default: {
                    return null
                }
            }
        }

        switch (type) {
            case 'orderStatus': {
                return (
                    <Typography type='interface-regular' color={params.color} truncate title={params.value} block>
                        {renderStatus(value)}
                    </Typography>
                )
            }
            case 'type': {
                const isSell = [
                    TTradePositionType.tp_Sell,
                    TTradePositionType.tp_SellLimit,
                    TTradePositionType.tp_SellStop
                ].includes(value)

                const isBalanceChange = [TTradePositionType.tp_Deposit, TTradePositionType.tp_Withdrawal].includes(
                    value
                )

                return (
                    <Flex justifyContent='center' alignItems='center' gap={4}>
                        {isBalanceChange ? (
                            <Icon name='deposit' size={16} />
                        ) : (
                            <Icon name={isSell ? 'sell-order' : 'buy-order'} size={16} />
                        )}
                        <Typography type='interface-regular' color='gray-1000'>
                            {t(getOrderTypeLabel(value))}
                        </Typography>
                    </Flex>
                )
            }
            default: {
                return (
                    <Typography type='interface-regular' color={params.color} truncate title={params.value} block>
                        {params.value}
                    </Typography>
                )
            }
        }
    }, [type, value])

    return (
        <Flex justifyContent='center' alignItems='center' block>
            {!!value || value === 0 ? (
                cellComponent
            ) : (
                <Typography type='interface-regular' color='gray-1000'>
                    -
                </Typography>
            )}
        </Flex>
    )
}

export default CellRenderer
