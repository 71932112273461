import { FC } from 'react'
import cx from 'classnames'
import noop from 'lodash/noop'

import { Flex, Tooltip } from '@fto/ui'
import { FilenameTypes, Icon } from '@fto/icons'

import styles from './index.module.scss'

type Props = {
    type: 'lineColor' | 'fillColor' | 'textColor'
    isMultiColoring?: boolean
    color?: string
    tooltipLabel?: string
    disabled?: boolean
    openPalette?: () => void
}

const ICON_BY_TYPE = {
    lineColor: 'pen',
    fillColor: 'paint-bucket',
    textColor: 'text'
}

const getColorValue = (isMultiColoring: boolean, color: string, disabled: boolean = false) => {
    if (disabled) {
        return '#D0D5DD'
    }

    if (isMultiColoring) {
        return 'linear-gradient(90deg, #FF0008, #FF00EB, #0E00FF, #00EDFF, #00FF00, #FFFE00, #FF0800)'
    }

    return color
}

const TriggerElement: FC<Props> = ({
    type,
    color,
    isMultiColoring = false,
    disabled,
    tooltipLabel = '',
    openPalette = noop
}) => {
    return (
        <Tooltip color='dark' placement='top' content={tooltipLabel}>
            <Flex
                direction='column'
                gap={4}
                alignItems='center'
                justifyContent='center'
                className={cx(styles.TriggerElement, { [styles.Disabled]: disabled })}
                onClick={openPalette}
            >
                <Icon
                    name={ICON_BY_TYPE[type] as (typeof FilenameTypes)[number]}
                    size={16}
                    color={disabled ? 'var(--color-gray-500)' : 'var(--color-gray-1000)'}
                />
                <div
                    className={styles.ColorLine}
                    style={{ background: getColorValue(isMultiColoring, color as string, disabled) }}
                />
            </Flex>
        </Tooltip>
    )
}

export default TriggerElement
