import { FC, useCallback, useEffect } from 'react'

import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/DllIndicatorUnit'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { ContextMenu, removeContextMenu } from '@fto/ui'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'

import IndicatorMenuContent from '../components/IndicatorMenuContent'

type Props = {
    anchorX: number
    anchorY: number
    additionalProps: {
        chart: TChartWindow
        indicator: TRuntimeIndicator
    }
}

const IndicatorContextMenu: FC<Props> = ({ anchorX, anchorY, additionalProps }) => {
    const removeMenu = useCallback(() => {
        removeContextMenu(CONTEXT_MENU_NAMES.indicator)
        GlobalChartsController.Instance.enableMouseEvents()
    }, [])

    useEffect(() => {
        return () => {
            removeMenu()
        }
    }, [])

    return (
        <ContextMenu name={CONTEXT_MENU_NAMES.indicator} anchorX={anchorX} anchorY={anchorY}>
            <IndicatorMenuContent
                chart={additionalProps.chart}
                indicator={additionalProps.indicator}
                removeMenu={removeMenu}
            />
        </ContextMenu>
    )
}

export default IndicatorContextMenu
