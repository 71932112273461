import { t } from 'i18next'

import { TIndexBuffer } from '../api/IIndicatorApi'
import Constants, {
    TDrawStyle,
    TOptValue_number,
    TOptionType,
    TOutputWindow,
    TPenStyle,
    TPriceType
} from '../api/IndicatorInterfaceUnit'
import { UserIndicator } from '../api/UserIndicator'

export default class RSI extends UserIndicator {
    period: TOptValue_number = new TOptValue_number(14)
    ApplyToPrice: TOptValue_number = new TOptValue_number(TPriceType.pt_Close)

    // Buffers
    _RSI!: TIndexBuffer
    AvGain!: TIndexBuffer
    AvLoss!: TIndexBuffer

    Init(): void {
        // TODO:EX RSI.dpr, without this line, is needed?
        this.api.RecalculateMeAlways()

        this.api.IndicatorShortName(t('indicators.rsi'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.SetFixedMinMaxValues(0, 100)
        this.api.AddLevel(30, TPenStyle.psDot, 1, '#000000', 1)
        this.api.AddLevel(70, TPenStyle.psDot, 1, '#000000', 1)
        this.api.SetEmptyValue(105)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.general.generalFields.period'), TOptionType.ot_Integer, this.period)
        this.api.SetOptionRange(t('indicatorModal.general.generalFields.period'), 1, Constants.MAX_INT)

        this.api.RegApplyToPriceOption(this.ApplyToPrice, '')

        this._RSI = this.api.CreateIndexBuffer()
        this.AvGain = this.api.CreateIndexBuffer()
        this.AvLoss = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(1)
        this.api.SetIndexBuffer(0, this._RSI)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#87CEEB')
        this.api.SetIndexLabel(0, t('indicatorModal.rsi.fields.rsi'))

        this.api.SetIndexDrawBegin(this.period.value, this.period.value)
    }

    Calculate(index: number): void {
        let i: number
        let diff: number = 0
        let gain: number = 0
        let loss: number = 0

        if (this.api.Bars() - index <= this.period.value) {
            //TODO:IN these 3 lines should be removed when SetEmptyValue is implemented
            this._RSI.setValue(index, 105)
            this.AvGain.setValue(index, 105)
            this.AvLoss.setValue(index, 105)
            return
        }

        if (this.api.Bars() - index === this.period.value + 1) {
            for (i = index; i <= index + this.period.value - 1; i++) {
                diff = this.api.GetPrice(i, this.ApplyToPrice.value) - this.api.GetPrice(i + 1, this.ApplyToPrice.value)
                if (diff > 0) {
                    gain += diff
                } else {
                    loss += Math.abs(diff)
                }
            }
            this.AvGain.setValue(index, gain / this.period.value)
            this.AvLoss.setValue(index, loss / this.period.value)
            //TODO:IN this line should be removed when SetEmptyValue is implemented
            this._RSI.setValue(index, 105)
        } else {
            diff =
                this.api.GetPrice(index, this.ApplyToPrice.value) -
                this.api.GetPrice(index + 1, this.ApplyToPrice.value)
            if (diff > 0) {
                gain = diff
            } else {
                loss = Math.abs(diff)
            }
            gain = (this.AvGain.getValue(index + 1) * (this.period.value - 1) + gain) / this.period.value
            loss = (this.AvLoss.getValue(index + 1) * (this.period.value - 1) + loss) / this.period.value

            this.AvGain.setValue(index, gain)
            this.AvLoss.setValue(index, loss)

            if (loss === 0) {
                this._RSI.setValue(index, 105)
            } else {
                this._RSI.setValue(index, 100 - 100 / (1 + gain / loss))
            }
        }
    }
}
