import { FC } from 'react'
import { t } from 'i18next'

import { Flex, Typography, TriggerOverlay } from '@fto/ui'
import { Icon } from '@fto/icons'

import HoverElement from './components/HoverElement'
import Filter from './components/Filter'

import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'

import styles from './index.module.scss'

type Props = {
    onClose: () => void
}

const Header: FC<Props> = ({ onClose }) => {
    const { userId } = useAppSelector($getUser)

    return (
        <Flex alignItems='center' gap={8} justifyContent='space-between' className={styles.Header}>
            <Typography type='h4-semibold' color='gray-1000' block>
                {t('news.header.title')}
            </Typography>
            <Flex alignItems='center'>
                <TriggerOverlay optionsRenderer={<Filter userId={userId} />}>
                    <HoverElement>
                        <Icon name='filter' size={18} color='var(--color-gray-1000)' />
                    </HoverElement>
                </TriggerOverlay>

                <HoverElement onClick={onClose}>
                    <Icon name='close' size={16} color='var(--color-gray-1000)' />
                </HoverElement>
            </Flex>
        </Flex>
    )
}

export default Header
