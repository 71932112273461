import { FC, useCallback } from 'react'
import { TriggerOverlay, Typography } from '@fto/ui'
import { FilenameTypes, IconButton } from '@fto/icons'
import styles from './table-item-context-menu.module.scss'
import { TableItemAction } from '@root/pages/Dashboard/components/ProjectsTable/ProjectsTable'
import { useTranslation } from 'react-i18next'

type Props = {
    action: (type: TableItemAction, projectId: string) => void
    projectId: string
    openTester: () => void
    setIsOpened?: (value: boolean) => void
}

const options: {
    label: TableItemAction
    icon: (typeof FilenameTypes)[number]
}[] = [
    {
        label: 'open',
        icon: 'play-line'
    },
    {
        label: 'rename',
        icon: 'rename'
    },
    {
        label: 'duplicate',
        icon: 'duplicate'
    },
    {
        label: 'delete',
        icon: 'trash-line'
    }
]

const TableItemContextMenu: FC<Props> = ({ action, projectId, openTester, setIsOpened }) => {
    const { t } = useTranslation()

    const renderOptions = useCallback(
        (onClose: () => void) => {
            return (
                <ul className={styles.list}>
                    {options.map(({ label, icon }) => (
                        <li
                            className={styles.option}
                            onClick={(e) => {
                                label === 'open' ? openTester() : action(label, projectId)
                                onClose()
                                e.stopPropagation()
                            }}
                            key={label}
                        >
                            <IconButton
                                className={styles.icon}
                                size={16}
                                name={icon}
                                color={icon === 'trash-line' ? 'var(--color-red-500)' : 'var(--color-gray-1000)'}
                            />
                            <Typography
                                type='interface-regular'
                                color={icon === 'trash-line' ? 'red-500' : 'gray-1000'}
                            >
                                {t(`dashboard.table.contextMenu.${label}`)}
                            </Typography>
                        </li>
                    ))}
                </ul>
            )
        },
        [options, projectId]
    )

    return (
        <TriggerOverlay
            setIsOpened={setIsOpened}
            className={styles.trigger}
            outStyledList
            optionsRenderer={renderOptions}
        >
            <IconButton name={'ellipsis-vertical'} className={styles.iconButton} />
        </TriggerOverlay>
    )
}

export default TableItemContextMenu
