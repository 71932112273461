import { MarketLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/MarketLevel'
import { TPoint, TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import { ModifyBtn } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/ModifyBtn'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { StopLossLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/StopLossLevel'
import { TakeProfitLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/TakeProfitLevel'
import { ChartControl } from '@fto/chart_components/ChartControl'

export class PendingLevel extends MarketLevel {
    public currentOpenPrice: number | null = null
    private lastCorrectValue: number | null = null
    private prevTPpoints: number | null = null
    private prevSLpoints: number | null = null
    private isNeedSyncLevels: boolean = false

    draw(canvas: TGdiPlusCanvas): void {
        const ctx = canvas.graphics.Context

        const { Left, Right, Top, Bottom } = this.getLocation()
        const isActive = this.IsCaptured() || this.isMouseInside() || this.isSelected
        let baseColor = this.isValid ? this.chartWindow.ChartOptions.ColorScheme.SellMarkerColor : this.invalidColor

        // Define positions and sizes
        const canvasWidth = ctx.canvas.width
        const x = this.getXCoordinateToDrawLevel(ctx) // Position at 1/5 of the canvas width
        const y = Top
        let labelText = ''
        const separatorColor = '#00000033'
        let secondWord = ''

        if (this.owner.PosType === TTradePositionType.tp_BuyStop) {
            labelText = isActive ? 'Buy Stop' : 'B'
            secondWord = 'Stop'
            baseColor = this.isValid ? this.chartWindow.ChartOptions.ColorScheme.BuyMarkerColor : this.invalidColor
        } else if (this.owner.PosType === TTradePositionType.tp_SellStop) {
            labelText = isActive ? 'Sell Stop' : 'S'
            secondWord = 'Stop'
            baseColor = this.isValid ? this.chartWindow.ChartOptions.ColorScheme.SellMarkerColor : this.invalidColor
        } else if (this.owner.PosType === TTradePositionType.tp_BuyLimit) {
            labelText = isActive ? 'Buy Limit' : 'B'
            secondWord = 'Limit'
            baseColor = this.isValid ? this.chartWindow.ChartOptions.ColorScheme.BuyMarkerColor : this.invalidColor
        } else if (this.owner.PosType === TTradePositionType.tp_SellLimit) {
            labelText = isActive ? 'Sell Limit' : 'S'
            secondWord = 'Limit'
            baseColor = this.isValid ? this.chartWindow.ChartOptions.ColorScheme.SellMarkerColor : this.invalidColor
        }

        const ticketText = `#${this.owner.tpos.ticket}`
        const boxPadding = 8
        const font = '12px Roboto Flex'

        ctx.font = font

        // Measure text widths
        const ticketTextWidth = ctx.measureText(ticketText).width
        const labelTextWidth = ctx.measureText(labelText).width
        const secondWordWidth = ctx.measureText(secondWord).width

        // Calculate box width
        let textWidth = labelTextWidth + secondWordWidth
        let boxWidth = textWidth + boxPadding * 3
        const boxHeight = 16

        if (isActive) {
            textWidth += ticketTextWidth - secondWordWidth + boxPadding * 3
            boxWidth = textWidth
        }

        const lineLength = canvasWidth
        const borderRadius = 2

        let boxX = x - boxWidth / 2
        const boxY = y - boxHeight / 2

        // Draw dashed line
        this.drawDashedLine(ctx, 0, y, lineLength, y, baseColor, isActive)

        // Draw the order label box with rounded corners
        this.drawRoundedRect(ctx, boxX, boxY, boxWidth, boxHeight, borderRadius, baseColor)

        // Initial positions for texts
        let labelX = boxX + 3
        let separatorX1 = boxX + 3 + labelTextWidth + 5
        const separatorBetweenTicketAndLabel = boxX + 5 + ticketTextWidth + 12.5

        if (isActive) {
            // Adjust positions if active
            labelX = separatorBetweenTicketAndLabel + 5
            separatorX1 += ticketTextWidth + boxPadding * 1.5 + 5
        }

        // Fill background of the box to first separator line
        this.fillBackground(ctx, boxX, boxY, separatorX1 - boxX, boxHeight, baseColor)

        if (this.IsDraggable() && isActive) {
            this.drawDragDots(ctx, boxX + 2.5, y - 3, baseColor, isActive)
        }

        // Draw ticket text inside the box if active
        if (isActive) {
            this.drawText(ctx, ticketText, boxX + 12, y, '#FFFFFF', font)
            this.drawSeparatorLine(ctx, separatorBetweenTicketAndLabel, boxY + 1, 14, separatorColor)
        }

        // Draw title inside the box
        this.drawText(ctx, labelText, labelX, y, baseColor, font, true)

        // Draw separator line inside the box
        this.drawSeparatorLine(ctx, separatorX1, boxY + 1, 14, baseColor)

        if (!isActive) {
            this.drawText(ctx, secondWord, separatorX1 + 5, y, baseColor, font, false)
        }

        // Draw value text inside the box
        const valueTextX = separatorX1 + 5

        // Draw additional info if active
        if (isActive) {
            const separatorX2 = valueTextX
            const endX = separatorX2 + 2

            if (this.modifyBtn && this.modifyBtn instanceof ModifyBtn) {
                this.modifyBtn.setLocation(new TRect(endX, y - 8, endX + 20, y + 8))
                this.modifyBtn.mainColor = baseColor
            }
            this.drawModifyBtn(canvas)

            if (this.closeBtn) {
                this.closeBtn.setLocation(new TRect(endX + 20, y - 8, endX + 40, y + 8))
                this.closeBtn.mainColor = baseColor
            }

            this.drawCloseBtn(canvas)
        }

        this.infoBoxLocation = new TRect(boxX - 2, y - 8, boxX + boxWidth + 2, y + 8)
    }

    public recalculateLocationByY(price: number): void {
        const location = this.getLocation()

        const y = this.chartWindow.MainChart.GetY(price)
        const paintRect = this.chartWindow.MainChart.GetPaintRect()

        location.Top = y
        location.Bottom = y + 1
        location.Left = paintRect.Left
        location.Right = paintRect.Right

        const prevSLPoints = this.owner.getStopLossPoints()
        const prevTPPoints = this.owner.getTakeProfitPoints()

        if (this.validationStrategy) {
            this.isValid = this.validationStrategy.isValid(price.toString())

            this.currentOpenPrice = Number(price.toFixed(this.chartWindow.MainChart.ScaleDecimals()))
        }

        this.setLocation(location)
        if (this.IsCaptured() || this.isNeedSyncLevels) {
            this.owner.orderLevels.forEach((levels) => {
                if (this.owner.tpos.StopLoss !== 0 && levels.stopLossOrderLevel) {
                    ;(levels.stopLossOrderLevel as StopLossLevel).changePoints(this.prevSLpoints || prevSLPoints, price)
                    levels.stopLossOrderLevel.isNeedToValidate = false
                }
                if (this.owner.tpos.TakeProfit !== 0 && levels.takeProfitOrderLevel) {
                    ;(levels.takeProfitOrderLevel as TakeProfitLevel).changePoints(
                        this.prevTPpoints || prevTPPoints,
                        price
                    )
                    levels.takeProfitOrderLevel.isNeedToValidate = false
                }
            })

            if (this.isNeedSyncLevels) {
                this.isNeedSyncLevels = false
            }
        }
    }

    close() {
        GlobalProcessingCore.ProcessingCore.CancelOrder(this.owner.tpos.ticket)
        this.owner.reset()
        GlobalChartsController.Instance.updateCharts()
        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
    }

    onMouseDown(event: MouseEvent, sender: TMainChart): ChartControl | null {
        this.modifyBtn?.onMouseDown(event, sender)
        this.lastCorrectValue = this.owner.tpos.OpenPrice
        this.prevTPpoints = this.owner.getTakeProfitPoints()
        this.prevSLpoints = this.owner.getStopLossPoints()

        this.owner.orderLevels.forEach((levels) => {
            if (levels.openPriceOrderLevel instanceof PendingLevel) {
                levels.openPriceOrderLevel.prevTPpoints = this.prevTPpoints
                levels.openPriceOrderLevel.prevSLpoints = this.prevSLpoints
            }
        })

        return super.onMouseDown(event, sender)
    }

    onMouseUp() {
        if (this.isValid) {
            if (this.isValid && this.currentOpenPrice) {
                this.owner.tpos.OpenPrice = this.currentOpenPrice
                this.owner.m_pOrderValues.atPrice = this.currentOpenPrice
            }
        } else if (this.lastCorrectValue) {
            this.owner.tpos.OpenPrice = this.lastCorrectValue
            this.owner.m_pOrderValues.atPrice = this.lastCorrectValue
            this.isNeedSyncLevels = true
        }

        this.owner.orderLevels.forEach((levels) => {
            levels.stopLossOrderLevel?.ReleaseCapture()
            levels.takeProfitOrderLevel?.ReleaseCapture()

            if (this.owner.tpos.StopLoss !== 0 && levels.stopLossOrderLevel) {
                levels.stopLossOrderLevel.isNeedToValidate = true
            }
            if (this.owner.tpos.TakeProfit !== 0 && levels.takeProfitOrderLevel) {
                levels.takeProfitOrderLevel.isNeedToValidate = true
            }
        })

        this.recalculateLocationByY(this.owner.tpos.OpenPrice)
        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
    }
}
