import { FiboExtensionJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { TBasicPaintTool } from '../../../charting/paint_tools/BasicPaintTool'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { TOffsStringList } from '../../../ft_types/common/OffsStringList'
import { StrsConv } from '../../../ft_types/common/StrsConv'
import { NotImplementedError } from '../../../utils/common_utils'
import { PaintToolNames } from '../PaintToolNames'
import { TPaintToolType, TPointInfo } from '../PaintToolsAuxiliaryClasses'
import { TFiboPaintTool } from './ptFibo'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TPoint } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TChart } from '../../chart_classes/BasicChart'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TPtFiboExtension extends TFiboPaintTool {
    private fClosedEnds: boolean
    private fExtraPixels: number
    private fShowPrice: boolean

    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptFiboExtension)
        // ShortName is equivalent to setting the name of the tool
        this.ShortName = 'Fibo Extension'
        // fToolType is equivalent to setting the type of the tool
        this.fToolType = TPaintToolType.tt_Line
        // fMaxPoints is equivalent to setting the maximum number of points for the tool
        this.fMaxPoints = 3
        // fClosedPolygon is equivalent to setting whether the polygon is closed
        this.fClosedPolygon = false
        // CursorStyle is equivalent to setting the cursor style for the tool
        this.CursorStyle = CustomCursorPointers.crCursorFiboExtension
        this.fClosedEnds = true
        this.fExtraPixels = 100
        this.fShowPrice = true
        // icon is equivalent to setting the icon index for the tool
        this.icon = 54
        // SetLevels is a method to set the levels for the Fibonacci tool
        const fibo: number[] = [0, 23.6, 38.2, 50, 61.8, 78.6, 100, 127.2, 161.8, 261.8]
        this.SetLevels(fibo, 3)
        // // fEditDialog is equivalent to setting the edit dialog for the tool
        // //TODO: Assign the correct form to fEditDialog once the form is implemented
        // this.fEditDialog = FiboExtensionFrm;

        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptFiboExtension)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptFiboExtension)
        }

        if (!styles) throw new StrangeError('Default styles for FiboExtension are not found')
        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
        this.fLevels.ImportFromStr(styles.levels)
    }

    clone(): TPtFiboExtension {
        const cloneObj = new TPtFiboExtension(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        cloneObj.fClosedEnds = this.fClosedEnds
        cloneObj.fExtraPixels = this.fExtraPixels
        cloneObj.fShowPrice = this.fShowPrice
        return cloneObj
    }

    toJson(): FiboExtensionJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            ClosedEnds: this.fClosedEnds,
            ExtraPixels: this.fExtraPixels,
            ShowPrice: this.fShowPrice
        }
    }

    fromJSON(json: FiboExtensionJSON) {
        super.fromJSON(json)
        this.fClosedEnds = json.ClosedEnds
        this.fExtraPixels = json.ExtraPixels
        this.fShowPrice = json.ShowPrice
    }

    public LoadFromList(list: TOffsStringList, all = true): void {
        throw new NotImplementedError()
    }

    public SaveToList(list: TOffsStringList, all = true): void {
        throw new NotImplementedError()
    }

    public Paint(): void {
        if (this.fHighlighted) {
            const points = [
                new TPoint(this.fPoints[0].x, this.fPoints[0].y),
                new TPoint(this.fPoints[1].x, this.fPoints[1].y),
                new TPoint(this.fPoints[2].x, this.fPoints[2].y)
            ]
            this.PaintHoverLine(points)
        }
        super.Paint()

        if (this.fPoints.Count < 3) {
            return
        }
        this.fExtendsRay.leftfPoints.length = 0
        this.fExtendsRay.rightfPoints.length = 0
        const canvas = this.fChart.GdiCanvas
        const x1 = Math.min(this.fPoints[1].x, this.fPoints[2].x)
        const price1 = this.fPoints[0].price
        const price2 = this.fPoints[1].price
        const price3 = this.fPoints[2].price

        let x2 = this.chartPaintRect.Right

        if (this.fClosedEnds) {
            x2 = Math.min(x2, Math.max(this.fPoints[1].x, this.fPoints[2].x) + this.fExtraPixels)
        }

        for (let i = 0; i < this.fLevels.Count; i++) {
            const level = this.fLevels[i]

            if (!level.isActive) {
                continue
            }

            const price = price3 + ((price2 - price1) * level.value) / 100
            const y = this.fChart.GetY(price)

            canvas.MoveTo(x1, y)
            canvas.LineTo(x2, y, level.pen)
            const startLeftRay = new TPointInfo(x1, y)
            startLeftRay.price = price
            const endLeftRay = new TPointInfo(x2, y)
            endLeftRay.price = price

            this.fExtendsRay.leftfPoints.push({ [i]: [endLeftRay, startLeftRay, level.pen] })
            this.fExtendsRay.rightfPoints.push({ [i]: [startLeftRay, endLeftRay, level.pen] })
            this.PaintExtendsRay()

            // Paint labels
            const fontColor = level.style.color //TODO: check if the color is in BGR or RGB value
            let s = level.text
            if (this.fShowPrice) {
                s = `${s} (${StrsConv.StrDouble(price, this.fChart.ScaleDecimals())})`
            }

            const textWidth = this.fChart.GdiCanvas.TextWidth(s)
            const textHeight = this.fChart.GdiCanvas.TextHeight('0')
            canvas.textOut(x2 - textWidth - 5, y - textHeight, s, this.font, level.brush)
        }
    }

    public assign(tool: TBasicPaintTool, isCopy = false): void {
        super.assign(tool, isCopy)
        if (tool instanceof TPtFiboExtension) {
            this.fLevels.Assign(tool.fLevels)
            this.fClosedEnds = tool.fClosedEnds
            this.fExtraPixels = tool.fExtraPixels
            this.fShowPrice = tool.fShowPrice
        }
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            levels: {
                value: this.fLevels.map((level) => ({
                    id: level.id,
                    value: level.value,
                    text: level.text,
                    isActive: level.isActive,
                    style: level.pen.getPenStyleFromPattern(),
                    color: level.pen.color,
                    width: level.pen.width,
                    opacity: level.pen.opacity
                })),
                type: 'levels',
                key: 'levels',
                label: 'levels'
            },
            extendLeft: {
                key: 'extendLeft',
                value: this.fExtendsRay.left,
                label: 'Extend to the left',
                type: 'checkbox',
                disabled: false,
                isOptional: true
            },
            extendRight: {
                key: 'extendRight',
                value: this.fExtendsRay.right,
                label: 'Extend to the right',
                type: 'checkbox',
                disabled: false,
                isOptional: true
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptFiboExtension,
            toolName: 'fiboExtension'
        })
    }

    ImportFromDialog(): void {
        const { resetToolSettings, getKeyValueData } = GraphToolStore // Use the store/context

        const formattedToolSettings = getKeyValueData()

        const { description, lineStyle, levels } = formattedToolSettings
        this.chart.ChartWindow.saveStateWithNotify()

        this.description = description

        this.fExtendsRay.left = formattedToolSettings.extendLeft
        this.fExtendsRay.right = formattedToolSettings.extendRight

        this.fLineStyle = lineStyle

        this.updateLevelsFromModal(levels)

        this.saveToManager()
        resetToolSettings()
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }
    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }
    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptFiboExtension, {
            lineStyle: this.fLineStyle.getSerialized(),
            toolName: PaintToolNames.ptFiboExtension,
            levels: this.fLevels.ExportToStr(5)
        })
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptFiboExtension, TPtFiboExtension)
