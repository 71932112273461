import { ConfigType } from '../../../types'

const dropdownOptions = [
    { value: 0, localeKey: 'settingsModal.leftBar', labelValue: 'Left Bar' },
    { value: 1, localeKey: 'settingsModal.rightBar', labelValue: 'Right Bar' },
    { value: 2, localeKey: 'settingsModal.center', labelValue: 'Center' }
]

const fontSizeOptions = [
    { value: 10, label: '10', labelValue: '10' },
    { value: 12, label: '12', labelValue: '12' },
    { value: 14, label: '14', labelValue: '14' },
    { value: 16, label: '16', labelValue: '16' },
    { value: 18, label: '18', labelValue: '18' },
    { value: 20, label: '20', labelValue: '20' },
    { value: 22, label: '22', labelValue: '22' },
    { value: 26, label: '26', labelValue: '26' },
    { value: 30, label: '30', labelValue: '30' }
]

const GeneralConfig: { [key: string]: any } = {
    ShowCloseOrderMarker: {
        key: 'ShowCloseOrderMarker',
        type: 'checkboxSetting',
        name: 'settingsModal.showCloseOrderMarker',
        booleanKey: 'ShowCloseOrderMarker',
        booleanConfigType: ConfigType.GlobalOptions
    },
    ShowOpenOrderMarker: {
        key: 'ShowOpenOrderMarker',
        type: 'checkboxSetting',
        name: 'settingsModal.showOpenOrderMarker',
        booleanKey: 'ShowOpenOrderMarker',
        booleanConfigType: ConfigType.GlobalOptions
    },
    ScrollingSpeed: {
        key: 'ScrollingSpeed',
        type: 'sliderSetting',
        name: 'settingsModal.scrollSpeed',
        valueConfigType: ConfigType.GlobalOptions,
        valueKey: 'ScrollingSpeed',
        maxValue: 10
    },
    MouseSensitivity: {
        key: 'MouseSensitivity',
        type: 'inputSetting',
        name: 'settingsModal.mouseSensitivity',
        valueKey: 'MouseSensitivity',
        valueConfigType: ConfigType.GlobalOptions
    },
    MagnetSensitivity: {
        key: 'MagnetSensitivity',
        type: 'inputSetting',
        name: 'settingsModal.magnetSensitivity',
        valueKey: 'MagnetSensitivity',
        valueConfigType: ConfigType.GlobalOptions
    },
    MagnetCrossHair: {
        key: 'MagnetCrossHair',
        type: 'checkboxSetting',
        name: 'settingsModal.magnetCrosshair',
        booleanKey: 'MagnetCrossHair',
        booleanConfigType: ConfigType.GlobalOptions
    },
    LockTo: {
        key: 'LockTo',
        type: 'dropdownSetting',
        name: 'settingsModal.lockTo',
        valueKey: 'OnTFChangeLockTo',
        valueConfigType: ConfigType.GlobalOptions,
        options: dropdownOptions
    },
    MultichartLockTo: {
        key: 'MultichartLockTo',
        type: 'dropdownSetting',
        name: 'settingsModal.multichartLockTo',
        valueKey: 'OnScrollLockTo',
        valueConfigType: ConfigType.GlobalOptions,
        options: dropdownOptions
    },
    AxisFontSize: {
        key: 'AxisFontSize',
        type: 'dropdownSetting',
        name: 'settingsModal.axisFontSize',
        valueKey: 'PriceAxisFontSize',
        valueKeyMethod: 'setAxisFontSize',
        valueConfigType: ConfigType.GlobalOptions,
        options: fontSizeOptions
    }
}

export default GeneralConfig
