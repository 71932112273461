import { t } from 'i18next'

import { UserIndicator } from '@fto/lib/extension_modules/indicators/api/UserIndicator'
import {
    TimeValue,
    TimeValues,
    TObjectType,
    TOptionType,
    TOptValue_Session,
    TOptValue_SessionsArray,
    TOutputWindow,
    TValueType
} from '@fto/lib/extension_modules/indicators/api/IndicatorInterfaceUnit'
import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { ObjProp } from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'

class Range {
    _start: number
    _end: number

    constructor(start: number, end: number) {
        this._start = start
        this._end = end
    }

    getStart(): number {
        return this._start
    }

    getEnd(): number {
        return this._end
    }
}

class Session {
    _name: string
    _openTimeInSecondsOfCurrDay: number
    _closeTimeInSecondsOfCurrDay: number
    private _fillColor: string
    private _opacity = '1A'
    private _isVisible = true

    constructor(name: string, open: number, close: number, fillColor: string, isVisible: boolean) {
        this._name = name
        this._openTimeInSecondsOfCurrDay = open
        this._closeTimeInSecondsOfCurrDay = close
        this._fillColor = fillColor
        this._isVisible = isVisible
    }

    getFillColor(): string {
        return this._fillColor + this._opacity
    }

    getLabelColor(): string {
        return ColorHelperFunctions.MakeColor(this._fillColor, 120)
    }

    isVisible(): boolean {
        return this._isVisible
    }
}

class SessionGraphElement {
    _sessionLow: number
    _sessionHigh: number
    _sessionOpenInUnixSeconds: number
    _sessionCloseInUnixSeconds: number
    _sessionOpen: TDateTime
    _sessionClose: TDateTime
    _openDate: string
    _closeDate: string

    _isUpToDate = false

    constructor(sessionLow: number, sessionHigh: number, sessionOpen: number, sessionClose: number) {
        this._sessionLow = sessionLow
        this._sessionHigh = sessionHigh
        this._sessionOpen = DateUtils.fromUnixTimeSeconds(sessionOpen)
        this._sessionClose = DateUtils.fromUnixTimeSeconds(sessionClose)
        this._sessionOpenInUnixSeconds = sessionOpen
        this._sessionCloseInUnixSeconds = sessionClose
        this._openDate = DateUtils.DF(this._sessionOpen)
        this._closeDate = DateUtils.DF(this._sessionClose)
    }

    isUpToDate(): boolean {
        return this._isUpToDate
    }

    setUpToDate(value: boolean) {
        this._isUpToDate = value
    }

    getId(): string {
        return `${this._openDate}_${this._closeDate}`
    }
}

class SessionManager {
    private _sessionInfos: Session[] = []
    private _sessions: Map<Session, SessionGraphElement[]> = new Map<Session, SessionGraphElement[]>()
    private _grafElementsToCreate: Map<Session, SessionGraphElement[]> = new Map<Session, SessionGraphElement[]>()
    private _grafElementsToUpdate: Map<Session, SessionGraphElement[]> = new Map<Session, SessionGraphElement[]>()
    private _grafElementsToDelete: Map<Session, SessionGraphElement[]> = new Map<Session, SessionGraphElement[]>()
    private _lastProcessedRange: Range | null = null

    Clear() {
        for (const [session, sessionGraphElements] of this._sessions.entries()) {
            sessionGraphElements.length = 0
        }
    }

    initSessions(sessions: Session[]) {
        this._sessionInfos = sessions
        this._sessions.clear()

        for (const session of sessions) {
            this._sessions.set(session, [])
        }
    }

    getCurrentSessions(): Map<Session, SessionGraphElement[]> {
        return this._sessions
    }

    onElementsDeleted() {
        for (const [session, sessionGraphElements] of this._grafElementsToDelete.entries()) {
            const elements = this._sessions.get(session)
            if (elements) {
                this._sessions.set(
                    session,
                    elements.filter(
                        (element) =>
                            !sessionGraphElements.some(
                                (sessionGraphElement) =>
                                    sessionGraphElement._sessionOpenInUnixSeconds ===
                                        element._sessionOpenInUnixSeconds &&
                                    sessionGraphElement._sessionCloseInUnixSeconds ===
                                        element._sessionCloseInUnixSeconds
                            )
                    )
                )
            }
        }

        this._grafElementsToDelete.clear()
    }

    onElementsCreated(currentTestingTimeInSeconds: TDateTime) {
        for (const [session, sessionGraphElements] of this._grafElementsToCreate.entries()) {
            for (const element of sessionGraphElements) {
                if (
                    element._sessionCloseInUnixSeconds < currentTestingTimeInSeconds &&
                    element._sessionLow > 0 &&
                    element._sessionHigh > 0
                ) {
                    element.setUpToDate(true)
                } else {
                    element.setUpToDate(false)
                }
            }

            const elements = this._sessions.get(session)
            if (elements) {
                this._sessions.set(session, elements.concat(sessionGraphElements))
            } else {
                this._sessions.set(session, sessionGraphElements)
            }
        }

        this._grafElementsToCreate.clear()
    }

    onElementsUpdated(currentTestingTimeInSeconds: TDateTime) {
        for (const [session, sessionGraphElements] of this._grafElementsToUpdate.entries()) {
            const elements = this._sessions.get(session)
            if (elements) {
                for (const element of sessionGraphElements) {
                    const realElement = this.findElementBySessionTimes(
                        element._sessionOpenInUnixSeconds,
                        element._sessionCloseInUnixSeconds,
                        elements
                    )
                    if (realElement) {
                        if (
                            element._sessionCloseInUnixSeconds < currentTestingTimeInSeconds &&
                            element._sessionLow > 0 &&
                            element._sessionHigh > 0
                        ) {
                            realElement.setUpToDate(true)
                        } else {
                            realElement.setUpToDate(false)
                        }
                    }
                }
            }
        }

        this._grafElementsToUpdate.clear()
    }

    getSessionsToCreate(): Map<Session, SessionGraphElement[]> {
        return this._grafElementsToCreate
    }

    getSessionsToUpdate(): Map<Session, SessionGraphElement[]> {
        return this._grafElementsToUpdate
    }

    getSessionsToDelete(): Map<Session, SessionGraphElement[]> {
        return this._grafElementsToDelete
    }

    findElementBySessionTimes(
        sessionOpen: number,
        sessionClose: number,
        sessionGraphElements: SessionGraphElement[]
    ): SessionGraphElement | undefined {
        return sessionGraphElements.find(
            (element) =>
                element._sessionOpenInUnixSeconds === sessionOpen && element._sessionCloseInUnixSeconds === sessionClose
        )
    }

    updateSessions(
        currentTestingTime: TDateTime,
        leftVisibleDateInUnixSeconds: number,
        rightVisibleDateInUnixSeconds: number
    ) {
        const currentTestingTimeInUnixSeconds = DateUtils.toUnixTimeSeconds(currentTestingTime)
        const startDayFromLeftVisibleDate = this.getStartOfDay(leftVisibleDateInUnixSeconds)
        const startDayFromRightVisibleDate = this.nextDay(rightVisibleDateInUnixSeconds)

        this._grafElementsToCreate = new Map<Session, SessionGraphElement[]>()
        this._grafElementsToUpdate = new Map<Session, SessionGraphElement[]>()
        this._grafElementsToDelete = new Map<Session, SessionGraphElement[]>()

        for (const [session, sessionGraphElements] of this._sessions.entries()) {
            const elementsToDelete = new Set<SessionGraphElement>()

            for (const element of sessionGraphElements) {
                if (
                    element._sessionOpenInUnixSeconds > currentTestingTimeInUnixSeconds ||
                    (element._sessionCloseInUnixSeconds < leftVisibleDateInUnixSeconds &&
                        element._sessionOpenInUnixSeconds > rightVisibleDateInUnixSeconds)
                ) {
                    elementsToDelete.add(element)
                }
            }

            if (elementsToDelete.size > 0) {
                this._grafElementsToDelete.set(session, Array.from(elementsToDelete))
            }

            if (session.isVisible()) {
                let rangesToProcess: Range[] = []
                rangesToProcess.push(new Range(startDayFromLeftVisibleDate, startDayFromRightVisibleDate))
                if (this._lastProcessedRange) {
                    rangesToProcess = []
                    if (startDayFromLeftVisibleDate < this._lastProcessedRange.getEnd()) {
                        rangesToProcess.push(new Range(startDayFromLeftVisibleDate, this._lastProcessedRange.getEnd()))
                    }
                    if (startDayFromRightVisibleDate > this._lastProcessedRange.getStart()) {
                        rangesToProcess.push(
                            new Range(this._lastProcessedRange.getStart(), startDayFromRightVisibleDate)
                        )
                    }
                }

                const sessionGraphElementsToCreate: SessionGraphElement[] = []
                for (const range of rangesToProcess) {
                    let startDay = range.getStart()

                    while (startDay <= range.getEnd()) {
                        const sessionClose = startDay + session._closeTimeInSecondsOfCurrDay
                        let sessionOpen = startDay + session._openTimeInSecondsOfCurrDay
                        if (session._openTimeInSecondsOfCurrDay > session._closeTimeInSecondsOfCurrDay) {
                            sessionOpen =
                                sessionClose -
                                86400 +
                                (session._openTimeInSecondsOfCurrDay - session._closeTimeInSecondsOfCurrDay)
                        }
                        if (sessionOpen <= currentTestingTimeInUnixSeconds) {
                            const element = this.findElementBySessionTimes(
                                sessionOpen,
                                sessionClose,
                                sessionGraphElements
                            )
                            if (element) {
                                if (
                                    !element.isUpToDate() ||
                                    element._sessionCloseInUnixSeconds > currentTestingTimeInUnixSeconds
                                ) {
                                    element.setUpToDate(false)
                                    if (!this._grafElementsToUpdate.has(session)) {
                                        this._grafElementsToUpdate.set(session, [])
                                    }
                                    this._grafElementsToUpdate.get(session)?.push(element)
                                }
                            } else {
                                sessionGraphElementsToCreate.push(
                                    new SessionGraphElement(0, 0, sessionOpen, sessionClose)
                                )
                            }
                        }
                        startDay = this.nextDay(startDay)
                    }
                }

                if (sessionGraphElementsToCreate.length > 0) {
                    this._grafElementsToCreate.set(session, sessionGraphElementsToCreate)
                }
            }
        }
    }

    private getStartOfDay(unixTime: number): number {
        const date = new Date(unixTime * 1000)
        date.setUTCHours(0, 0, 0, 0)
        return Math.floor(date.getTime() / 1000)
    }

    private nextDay(unixTime: number): number {
        const startOfDay = this.getStartOfDay(unixTime)
        const SECONDS_IN_A_DAY = 86400 // 60 * 60 * 24
        return startOfDay + SECONDS_IN_A_DAY
    }
}

export default class iSession extends UserIndicator {
    static instaceCounter = 0
    static instancesToReset = 0

    asianSession = new TOptValue_Session(
        true,
        t('indicators.sessions.asian'),
        TimeValue['22:00'],
        TimeValue['08:00'],
        '#FF5252'
    )
    londonSession = new TOptValue_Session(
        true,
        t('indicators.sessions.london'),
        TimeValue['08:00'],
        TimeValue['16:00'],
        '#2962FF'
    )
    newyorkSession = new TOptValue_Session(
        true,
        t('indicators.sessions.newYork'),
        TimeValue['13:00'],
        TimeValue['21:00'],
        '#4CAF50'
    )

    customSessions: TOptValue_SessionsArray = new TOptValue_SessionsArray([])
    indicatorSessions: Session[] = []

    _sessionManager: SessionManager = new SessionManager()
    _isVisible = true

    private initSessions() {
        const asianSession = new Session(
            this.asianSession.name,
            TimeValues.getTimeInSeconds(this.asianSession.startTime),
            TimeValues.getTimeInSeconds(this.asianSession.endTime),
            this.asianSession.color,
            this.asianSession.isEnabled
        )

        const londonSession = new Session(
            this.londonSession.name,
            TimeValues.getTimeInSeconds(this.londonSession.startTime),
            TimeValues.getTimeInSeconds(this.londonSession.endTime),
            this.londonSession.color,
            this.londonSession.isEnabled
        )

        const newyorkSession = new Session(
            this.newyorkSession.name,
            TimeValues.getTimeInSeconds(this.newyorkSession.startTime),
            TimeValues.getTimeInSeconds(this.newyorkSession.endTime),
            this.newyorkSession.color,
            this.newyorkSession.isEnabled
        )

        this.indicatorSessions.push(asianSession, londonSession, newyorkSession)

        const customSessions = this.customSessions.getSessions()
        for (const customSession of customSessions) {
            const session = new Session(
                customSession.name,
                TimeValues.getTimeInSeconds(customSession.startTime),
                TimeValues.getTimeInSeconds(customSession.endTime),
                customSession.color,
                customSession.isEnabled
            )
            this.indicatorSessions.push(session)
        }

        if (this._sessionManager) {
            this._sessionManager.initSessions(this.indicatorSessions)
        }
    }

    Init(): void {
        iSession.instaceCounter++
        this.api.IndicatorShortName(t('indicators.tradingSessions'))
        this.api.SetOutputWindow(TOutputWindow.ow_ChartWindow)

        this.api.RegOption(this.asianSession.name, TOptionType.ot_Session, this.asianSession)
        this.api.RegOption(this.londonSession.name, TOptionType.ot_Session, this.londonSession)
        this.api.RegOption(this.newyorkSession.name, TOptionType.ot_Session, this.newyorkSession)
        this.api.RegOption('CustomSessions', TOptionType.ot_SessionsArray, this.customSessions)

        this.initSessions()
    }

    Calculate(index: number): void {
        const chartInfo = this.api.GetChartInfo()
        if (chartInfo && chartInfo.FirstIndex >= 0 && chartInfo.LastIndex >= 0) {
            if (index !== chartInfo.LastIndex || this.api.Timeframe() >= 1440) return
            if (this.api.Timeframe() >= 240 && chartInfo.currZoom <= 2) {
                this.ResetObjects()
                return
            }

            const firstIndexTime = this.api.Time(chartInfo.FirstIndex)
            const lastIndexTime = this.api.Time(chartInfo.LastIndex)
            if (firstIndexTime > 0 && lastIndexTime > 0) {
                const leftVisibleDateInUnixSeconds = DateUtils.toUnixTimeSeconds(this.api.Time(chartInfo.FirstIndex))
                const rightVisibleDateInUnixSeconds = DateUtils.toUnixTimeSeconds(this.api.Time(chartInfo.LastIndex))
                if (iSession.instancesToReset > 0) {
                    this.ResetObjects()
                    iSession.instancesToReset--
                }
                if (this._isVisible) {
                    this._sessionManager.updateSessions(
                        this.api.Time(index),
                        leftVisibleDateInUnixSeconds,
                        rightVisibleDateInUnixSeconds
                    )

                    const elementsToDelete = this._sessionManager.getSessionsToDelete()
                    elementsToDelete.forEach((sessionGraphElements, session) => {
                        sessionGraphElements.forEach((element) => {
                            const objectName = `${session._name}_${element.getId()}`
                            if (this.api.ObjectExists(objectName, true)) {
                                this.api.ObjectDelete(objectName, true)
                            }
                        })
                    })
                    this._sessionManager.onElementsDeleted()

                    const elementsToCreate = this._sessionManager.getSessionsToCreate()

                    elementsToCreate.forEach((sessionGraphElements, session) => {
                        for (const element of sessionGraphElements) {
                            const objectName = `${session._name}_${element.getId()}`
                            if (this.api.ObjectExists(objectName, true)) {
                                this.api.ObjectDelete(objectName, true)
                            }
                            const indexOpen = this.api.iBarShift(
                                this.api.Symbol(),
                                this.api.Timeframe(),
                                element._sessionOpen,
                                false
                            )
                            const indexClose = this.api.iBarShift(
                                this.api.Symbol(),
                                this.api.Timeframe(),
                                element._sessionClose,
                                false
                            )
                            if (indexOpen === indexClose) {
                                continue
                            }
                            if (indexOpen >= 0 && indexClose >= 0) {
                                const highestPrice = this.api.GetHighestValue(
                                    TValueType.vt_High,
                                    indexClose,
                                    indexOpen - indexClose
                                )
                                const lowestPrice = this.api.GetLowestValue(
                                    TValueType.vt_Low,
                                    indexClose,
                                    indexOpen - indexClose
                                )

                                element._sessionLow = lowestPrice
                                element._sessionHigh = highestPrice

                                const res = this.api.ObjectCreate(
                                    objectName,
                                    TObjectType.obj_Rectangle,
                                    0,
                                    element._sessionOpen,
                                    lowestPrice,
                                    element._sessionClose,
                                    highestPrice,
                                    undefined,
                                    undefined,
                                    true
                                )

                                this.api.ObjectSet(objectName, ObjProp.OBJPROP_FILLCOLOR, session.getFillColor(), true)
                                this.api.ObjectSet(objectName, ObjProp.BORDER, false, true)
                                this.api.ObjectSetText(
                                    objectName,
                                    session._name,
                                    14,
                                    'Roboto Flex',
                                    session.getLabelColor(),
                                    true
                                )
                            }
                        }
                    })

                    this._sessionManager.onElementsCreated(DateUtils.toUnixTimeSeconds(this.api.Time(index)))

                    const elementsToUpdate = this._sessionManager.getSessionsToUpdate()
                    elementsToUpdate.forEach((sessionGraphElements, session) => {
                        for (const element of sessionGraphElements) {
                            const objectId = `${session._name}_${element.getId()}`
                            if (this.api.ObjectExists(objectId, true)) {
                                this.api.ObjectDelete(objectId, true)
                            }
                            const indexOpen = this.api.iBarShift(
                                this.api.Symbol(),
                                this.api.Timeframe(),
                                element._sessionOpen,
                                false
                            )
                            const indexClose = this.api.iBarShift(
                                this.api.Symbol(),
                                this.api.Timeframe(),
                                element._sessionClose,
                                false
                            )

                            if (indexOpen >= 0 && indexClose >= 0) {
                                const lowestPrice = this.api.GetLowestValue(
                                    TValueType.vt_Low,
                                    indexClose,
                                    indexOpen - indexClose
                                )

                                const highestPrice = this.api.GetHighestValue(
                                    TValueType.vt_High,
                                    indexClose,
                                    indexOpen - indexClose
                                )
                                element._sessionLow = lowestPrice
                                element._sessionHigh = highestPrice

                                this.api.ObjectCreate(
                                    objectId,
                                    TObjectType.obj_Rectangle,
                                    0,
                                    element._sessionOpen,
                                    lowestPrice,
                                    element._sessionClose,
                                    highestPrice,
                                    undefined,
                                    undefined,
                                    true
                                )
                                this.api.ObjectSet(objectId, ObjProp.OBJPROP_FILLCOLOR, session.getFillColor(), true)
                                this.api.ObjectSet(objectId, ObjProp.BORDER, false, true)
                                this.api.ObjectSetText(
                                    objectId,
                                    session._name,
                                    14,
                                    'Roboto Flex',
                                    session.getLabelColor(),
                                    true
                                )
                            }
                        }
                    })

                    this._sessionManager.onElementsUpdated(DateUtils.toUnixTimeSeconds(this.api.Time(index)))
                }
            }
        }
    }

    OnParamsChange() {
        this.ResetObjects()
        iSession.instancesToReset = iSession.instaceCounter - 1
        this.indicatorSessions = []
        this.initSessions()
    }

    ResetObjects() {
        const currSession = this._sessionManager.getCurrentSessions()
        currSession.forEach((sessionGraphElements, session) => {
            for (const element of sessionGraphElements) {
                const objectId = `${session._name}_${element.getId()}`
                if (this.api.ObjectExists(objectId, true)) {
                    this.api.ObjectDelete(objectId, true)
                }
            }
        })
        this._sessionManager.Clear()
    }

    Done() {
        this.ResetObjects()
        iSession.instaceCounter--
        iSession.instancesToReset = iSession.instaceCounter
    }

    OnHide(): void {
        this.ResetObjects()
        this._isVisible = false
        iSession.instancesToReset = iSession.instaceCounter - 1
    }

    OnShow(): void {
        this.ResetObjects()
        this._isVisible = true
        iSession.instancesToReset = iSession.instaceCounter - 1
    }
}
