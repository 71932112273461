import { Link } from '@fto/ui'
import { showErrorToast, showSuccessToast } from '@root/utils/toasts'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

export const errorToast = (status: string) => {
    showErrorToast({
        title: t(`dashboard.modals.projects.toasts.errorHeaders.${status}`),
        message: getErrorText(status)
    })
}

export const successToast = (type: string) => {
    showSuccessToast({
        title: t(`dashboard.modals.projects.toasts.successHeaders.${type}`)
    })
}

const getErrorText = (status: string) => {
    const SUPPORT_LINK = 'support@forextester.com'
    switch (status) {
        case 'project_name_already_exists':
            return t('dashboard.modals.projects.toasts.duplicateNameText')
        case 'project_not_found':
            return (
                <Trans
                    i18nKey='dashboard.modals.projects.toasts.projectNotFoundText'
                    components={{
                        supportEmailLink: <Link href={`mailto:${SUPPORT_LINK}`}>{SUPPORT_LINK}</Link>
                    }}
                />
            )
        case 'project_deletion_failed':
            return (
                <Trans
                    i18nKey='dashboard.modals.projects.toasts.projectNotDeletedText'
                    components={{
                        supportEmailLink: <Link href={`mailto:${SUPPORT_LINK}`}>{SUPPORT_LINK}</Link>
                    }}
                />
            )
        case 'project_creation_failed':
            return (
                <Trans
                    i18nKey='dashboard.modals.projects.toasts.projectNotCreatedText'
                    components={{
                        supportEmailLink: <Link href={`mailto:${SUPPORT_LINK}`}>{SUPPORT_LINK}</Link>
                    }}
                />
            )
        case 'project_update_failed':
            return (
                <Trans
                    i18nKey='dashboard.modals.projects.toasts.projectNotUpdatedText'
                    components={{
                        supportEmailLink: <Link href={`mailto:${SUPPORT_LINK}`}>{SUPPORT_LINK}</Link>
                    }}
                />
            )
        default:
            return (
                <Trans
                    i18nKey='dashboard.modals.projects.toasts.unexpectedErrorText'
                    components={{
                        supportEmailLink: <Link href={`mailto:${SUPPORT_LINK}`}>{SUPPORT_LINK}</Link>
                    }}
                />
            )
    }
}
