import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { addModal, Flex, ModalTabs, Separator, Typography } from '@fto/ui'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'

import styles from './index.module.scss'
import CanvasSettings from './components/SettingsTabs/CanvasSettings'
import ViewsSettings from './components/SettingsTabs/ViewsSettings'
import { SettingsTabRef } from './types'
import { useTranslation } from 'react-i18next'
import { fireMixpanelEvent } from '@root/utils/api'
import { IconButton } from '@fto/icons'
import GeneralSettings from './components/SettingsTabs/GeneralSettings'
import { observer } from 'mobx-react-lite'
import globalChartsStore from '@fto/lib/store/globalChartsStore'
import { TemplateManager } from '../TemplateManager'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'

import CanvasConfig from './components/SettingsTabs/CanvasSettings/config'
import ViewsConfig from './components/SettingsTabs/ViewsSettings/config'
import GeneralConfig from './components/SettingsTabs/GeneralSettings/config'

import { getOptionState, setOptionState } from './utils/getOptionSettingByConfig'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TabType } from '@fto/ui/lib/components/ModalTabs/types'
import { parseNestedJSON } from '@fto/lib/utils/common_utils'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'
import { DONT_SHOW_APPLY_TO_ALL_WARNING } from '@root/constants/localStorageKeys'
import { usePersistedState } from '@root/hooks/usePersistedState'

const TABS: TabType[] = [
    { value: 'canvas', localeKey: 'settingsModal.canvas' },
    { value: 'views', localeKey: 'settingsModal.views' },
    { value: 'general', localeKey: 'settingsModal.general' }
]

type Props = {
    setShowSettings: (close: boolean) => void
}

const ChartSettingsTab: FC<Props> = observer(({ setShowSettings }) => {
    const { t } = useTranslation()

    const { data } = globalChartsStore
    const chart = data.activeChart
    if (!chart) return <></>

    const { userId } = useAppSelector($getUser)

    const globalOptions = GlobalOptions.Options
    const [activeTab, setActiveTab] = useState(TABS[0].value)
    const initialChartSettings = chart.ChartOptions.Clone()
    const initialGlobalOptions = globalOptions.Clone()

    const [templates, setTemplates] = useState<string[]>([])
    const [selectedTemplate, setSelectedTemplate] = useState<string>('')
    const [applyToAll, setApplyToAll] = useState(false)

    const differentSettings = useRef({})

    const localStorageKey = useMemo(() => {
        const params = new URLSearchParams(window.location.search)
        const projectId = params.get('projectId')

        return `${userId}_${projectId}_${DONT_SHOW_APPLY_TO_ALL_WARNING}`
    }, [userId])

    const [dontShowApplyToAllWarning, setDontShowApplyToAllWarning] = usePersistedState(localStorageKey, false)

    useEffect(() => {
        if (!applyToAll) return
        if (dontShowApplyToAllWarning) return

        addModal(MODAL_NAMES.chart.applyToAllWarning, {
            withDontShowAgainCheckbox: true,
            onCancel: () => {
                setApplyToAll(false)
            },
            onApply: (dontShowAgain: boolean) => {
                setDontShowApplyToAllWarning(dontShowAgain)
            }
        })
    }, [applyToAll])

    const onDifferentSettingsUpdate = useCallback((tab: string, newValue: any, prevValue: any, key: string) => {
        const newValueKey = `${tab}_new_${key}`
        const prevValueKey = `${tab}_old_${key}`

        if (newValue !== prevValue) {
            differentSettings.current = {
                ...differentSettings.current,
                [newValueKey]: newValue,
                [prevValueKey]: prevValue
            }
        }
        setSelectedTemplate('')
    }, [])

    const initialCollectedData = useRef({ ...initialGlobalOptions, ...initialChartSettings })

    const canvasRef = useRef<SettingsTabRef>(null)
    const viewsRef = useRef<SettingsTabRef>(null)
    const generalRef = useRef<SettingsTabRef>(null)

    const content = useMemo<{ [key: string]: JSX.Element }>(
        () => ({
            canvas: (
                <CanvasSettings
                    chart={chart}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    initialData={initialCollectedData.current}
                    applyToAll={applyToAll}
                    ref={canvasRef}
                />
            ),
            views: (
                <ViewsSettings
                    chart={chart}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    initialData={initialCollectedData.current}
                    applyToAll={applyToAll}
                    ref={viewsRef}
                />
            ),
            general: (
                <GeneralSettings
                    chart={chart}
                    globalOptions={globalOptions}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    initialData={initialCollectedData.current}
                    applyToAll={applyToAll}
                    ref={generalRef}
                />
            )
        }),
        [chart, globalOptions, applyToAll, onDifferentSettingsUpdate]
    )

    const activeTabContent = useMemo(() => {
        return content[activeTab as string]
    }, [content, activeTab])

    const handleClose = useCallback(() => {
        fireMixpanelEvent('chart_settings_updated', { ...differentSettings.current })
        setShowSettings(false)
    }, [setShowSettings])

    const updateTemplates = useCallback(() => {
        setTemplates(GlobalTemplatesManager.Instance.getChartTemplatesNames())
    }, [])

    const loadTemplateIntoTabs = useCallback((template: { [key: string]: any }, shouldUpdateConfig = true) => {
        canvasRef.current && canvasRef.current.loadOptionsSetting(template.canvas, shouldUpdateConfig)
        viewsRef.current && viewsRef.current.loadOptionsSetting(template.views, shouldUpdateConfig)
        generalRef.current && generalRef.current.loadOptionsSetting(template.general, shouldUpdateConfig)
    }, [])

    const selectTemplateForChart = useCallback((chart: TChartWindow, template: { [key: string]: any }) => {
        for (const optionKey in template.canvas) {
            setOptionState(chart, CanvasConfig[optionKey], template.canvas[optionKey])
        }

        for (const optionKey in template.views) {
            setOptionState(chart, ViewsConfig[optionKey], template.views[optionKey])
        }

        for (const optionKey in template.general) {
            setOptionState(chart, GeneralConfig[optionKey], template.general[optionKey])
        }

        loadTemplateIntoTabs(template, false)

        chart.Repaint()
    }, [])

    const selectTemplate = useCallback(
        (template: { [key: string]: any }) => {
            const chartsToApply = applyToAll ? GlobalChartsController.Instance.getAllCharts() : [chart]
            chartsToApply.forEach((chart) => selectTemplateForChart(chart, template))
        },
        [chart, applyToAll, selectTemplateForChart]
    )

    const selectTemplateByName = useCallback(
        (templateName: string) => {
            let template = GlobalTemplatesManager.Instance.getChartTemplate(templateName)
            if (!template) throw new StrangeError('Template not found')

            template = parseNestedJSON(JSON.parse(template.templateJSON))

            if (!template) throw new StrangeError('Template format is incorrect')

            selectTemplate(template)
            setSelectedTemplate(templateName)
        },
        [chart, selectTemplate]
    )

    const calcCurrentTemplate = useCallback((): { [key: string]: any } => {
        const template = {
            canvas: {},
            views: {},
            general: {}
        }

        for (const configUnit of Object.values(CanvasConfig)) {
            template.canvas = {
                ...template.canvas,
                [configUnit.key]: getOptionState(configUnit, chart)
            }
        }

        for (const configUnit of Object.values(ViewsConfig)) {
            template.views = {
                ...template.views,
                [configUnit.key]: getOptionState(configUnit, chart)
            }
        }

        for (const configUnit of Object.values(GeneralConfig)) {
            template.general = {
                ...template.general,
                [configUnit.key]: getOptionState(configUnit, chart)
            }
        }

        return template
    }, [chart])

    const saveTemplate = useCallback(
        async (templateName: string, isRewrite: boolean = false) => {
            const template = calcCurrentTemplate()
            await GlobalTemplatesManager.Instance.addChartTemplate(templateName, JSON.stringify(template), isRewrite)

            updateTemplates()
            setSelectedTemplate(templateName)
        },
        [updateTemplates, calcCurrentTemplate]
    )

    const deleteTemplate = useCallback(
        async (templateName: string) => {
            await GlobalTemplatesManager.Instance.removeChartTemplate(templateName)
            updateTemplates()
        },
        [updateTemplates]
    )

    const applyDefault = useCallback(() => {
        chart.ChartOptions.reset()
        GlobalOptions.Options.reset()

        const template = calcCurrentTemplate()
        selectTemplate(template)

        chart.Repaint()
        setSelectedTemplate('')
    }, [chart, selectTemplate])

    useEffect(() => {
        const template = calcCurrentTemplate()
        loadTemplateIntoTabs(template, false)
        setSelectedTemplate('')
    }, [chart, loadTemplateIntoTabs])

    useEffect(() => {
        updateTemplates()
    }, [])

    return (
        <Flex direction='column' className={styles.container} gap={12}>
            <Flex direction='column' gap={12}>
                <Flex className={styles.header} justifyContent='space-between' alignItems='center'>
                    <Typography type='h4-semibold'>{t('settingsModal.chartSettings')}</Typography>
                    <IconButton size={14} name='close' color={'var(--color-gray-900)'} onClick={handleClose} />
                </Flex>
                <Separator width='100%' />
                <Flex className={styles.tabsWrapper} alignItems='center' gap={4}>
                    <ModalTabs tabsList={TABS} handleTabChange={setActiveTab} activeTab={activeTab} />
                </Flex>
            </Flex>
            <Flex className={styles.settingsWrapper}>{activeTabContent}</Flex>
            <Flex className={styles.templateManagerContainer} block>
                <TemplateManager
                    changeMainModalVisibleState={() => {}}
                    onSaveTemplate={saveTemplate}
                    onSelectTemplate={selectTemplateByName}
                    onDeleteTemplate={deleteTemplate}
                    onApplyDefault={applyDefault}
                    templates={templates}
                    selectedTemplate={selectedTemplate}
                    applyToAll={applyToAll}
                    setApplyToAll={setApplyToAll}
                />
            </Flex>
        </Flex>
    )
})

export default ChartSettingsTab
