import { ImageControl } from '@fto/chart_components/ImageControl'

export class IndicatorCollapseButton extends ImageControl {
    _isCollapsed: boolean = false
    imageCollapsed!: HTMLImageElement
    imageExpanded!: HTMLImageElement

    constructor(chartControlParams: any, collapsedImage: HTMLImageElement, expandedImage: HTMLImageElement) {
        super(chartControlParams, collapsedImage)
        this.imageExpanded = expandedImage
        this.imageCollapsed = collapsedImage
    }

    collapse() {
        this._isCollapsed = true
        this.image = this.imageExpanded
    }

    expand() {
        this._isCollapsed = false
        this.image = this.imageCollapsed
    }

    switch(): boolean {
        if (this._isCollapsed) {
            this.expand()
        } else {
            this.collapse()
        }
        return this._isCollapsed
    }

    isCollapsed(): boolean {
        return this._isCollapsed
    }
}
