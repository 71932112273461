export const FilenameTypes = [
'adaptive',
'area',
'arrow-down',
'arrow-left',
'arrow-long-left',
'arrow-path',
'arrow-right',
'arrow-steps',
'arrow-trend-down',
'arrow-trend-up',
'arrow-turn-down',
'arrow-up',
'attention-colored',
'attention',
'autoscale',
'avatar',
'barback',
'barforward',
'bars',
'bell',
'bernard-6',
'billing',
'bring-to-front',
'bug',
'buy-order',
'candle',
'change-balance',
'chart-layouts',
'chart-mode',
'check-lined',
'check-rounded',
'check',
'checkbox-disabled',
'checkbox-selected',
'checkbox-unselected',
'chevron-bottom',
'chevron-down',
'circled-plus',
'close-colored',
'close-rounded',
'close',
'cloud-error',
'cloud-ok',
'colored-check',
'contact',
'copy',
'country-argentina',
'country-australia',
'country-austria',
'country-belarus',
'country-belgium',
'country-brazil',
'country-canada',
'country-china',
'country-cyprus',
'country-czech',
'country-denmark',
'country-egypt',
'country-entire-world',
'country-estonia',
'country-europe',
'country-finland',
'country-france',
'country-germany',
'country-greece',
'country-hong-kong',
'country-hungary',
'country-india',
'country-ireland',
'country-israel',
'country-italy',
'country-japan',
'country-latvia',
'country-lithuania',
'country-mexico',
'country-netherlands',
'country-new-zealand',
'country-norway',
'country-philippines',
'country-poland',
'country-portugal',
'country-romania',
'country-russia',
'country-singapore',
'country-south-africa',
'country-south-korea',
'country-spain',
'country-sweden',
'country-switzerland',
'country-taiwan',
'country-thailand',
'country-turkey',
'country-united-kingdom',
'country-usa',
'crosshair',
'cursor',
'customize',
'dashed-line',
'date',
'delete-recent',
'delete-selected',
'delete',
'deposit',
'dotDashed-line',
'double-arrow',
'double-chevron',
'double-star',
'doubleDotDashed-line',
'drag-n-drop',
'dropdown',
'duplicate',
'edit',
'ellipse',
'ellipsis-vertical',
'envelope',
'error',
'eye-off',
'eye',
'face-frown',
'face-smile',
'fibo-arc',
'fibo-channel',
'fibo-extension',
'fibo-fan',
'fibo-retracement',
'fibo-timezones',
'filter',
'folder',
'four-charts-horizontal',
'four-charts-one-top-three-bottom',
'four-charts-quadrant',
'four-charts-three-rows',
'four-charts-vertical',
'google',
'greet',
'help',
'hline',
'hong-kong',
'importance',
'indicators',
'info-filled',
'info-static',
'info',
'jump-to',
'kagi',
'keyboard',
'language',
'left-price-label',
'lightning',
'line-style-select',
'line-width-select',
'lines',
'lock-closed',
'locked',
'log-out',
'logo-small',
'logo',
'magnet',
'menu-new-order',
'menu-note',
'microsoft',
'minus',
'move-sl-to-breakeven',
'new-order',
'new-project',
'news',
'no-internet-connection',
'no-project-loaded',
'notes',
'one-chart',
'one-left-two-right',
'one-top-two-bottom',
'oscillators',
'page-failed',
'paint-bucket',
'pause',
'pen',
'picker',
'pitchfork',
'pl',
'play-line',
'play',
'player-play',
'plus',
'polyline',
'price-label',
'question',
'radio-button-empty-outline',
'radio-button-radio-outline',
'ray',
'rectangle',
'regression-channel',
'rename',
'restart-colored',
'restart',
'right-price-label',
'rr-buy',
'rr-sell',
'ruler',
'scale',
'search',
'sell-order',
'send-to-back',
'settings',
'share',
'shortDashed-line',
'sidebar-eye-notification',
'sidebar-eye',
'solid-line',
'squares',
'star-empty',
'star',
'statistics',
'stop',
'success-colored',
'success',
'symbol-100',
'symbol-btcusd',
'symbol-dji',
'symbol-eth',
'symbol-gas',
'symbol-light',
'symbol-nas',
'symbol-ng',
'symbol-not-found',
'symbol-silver',
'symbol-spx500',
'symbol-xagusd',
'symbol-xauusd',
'symbol-xpdusd',
'symbol-xptusd',
'text-size-select',
'text',
'three-charts-horizontally',
'three-charts-vertically',
'thumbs-down',
'thumbs-up',
'tick',
'time',
'tline',
'trash-line',
'trash',
'trend',
'triangle',
'two-charts-horizontally',
'two-charts-vertically',
'two-left-one-right',
'two-top-one-bottom',
'unlocked',
'user-circle',
'vline',
'volatility',
'volume',
'wallet',
'warning',
'watchlist',
] as const;