import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { Button, Flex, Modal, Typography, removeModal, SimpleDropdown, Tooltip } from '@fto/ui'

import { OrderType } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import CreateOrderStore from '@fto/lib/store/createOrder'
import { OrderModel } from '@fto/lib/OrderModalClasses/OrderModel'

import { MODAL_NAMES } from '@root/constants/modalNames'
import { showErrorToast } from '@root/utils/toasts'

import ContentBlock from './components/ContentBlock'

import styles from './index.module.scss'
import CircularProgress from '@mui/material/CircularProgress'
import SymbolPair from '@root/components/SymbolPair'
import { useTranslation } from 'react-i18next'
import { fireMixpanelEvent } from '@root/utils/api'

type Props = {
    isEdit?: boolean
    headerLabel?: string | ReactNode
    source?: string
}

const OrderModal: FC<Props> = observer(({ isEdit = false, headerLabel, source }) => {
    const { t } = useTranslation()

    const {
        data: orderData,
        initializeModel,
        resetStore,
        handleOrderCreate,
        modifyOrder,
        setNewSymbol,
        onModalClose
    } = CreateOrderStore
    const [comment, setComment] = useState('')

    useEffect(() => {
        initializeModel()
    }, [initializeModel])

    const handleModalClose = useCallback(
        (isPricePick: boolean = false) => {
            if (!isPricePick) {
                onModalClose()
                resetStore()
                return
            }

            removeModal(MODAL_NAMES.chart.orderModal)
        },
        [resetStore, onModalClose]
    )

    const handleSubmit = useCallback(() => {
        try {
            isEdit ? modifyOrder(comment) : handleOrderCreate({ comment, source })

            removeModal(MODAL_NAMES.chart.orderModal)
        } catch (e) {
            showErrorToast({
                message: t('orders.modal.toasts.oops'),
                title: ''
            })
        }

        handleModalClose()
    }, [comment, handleOrderCreate, isEdit, handleModalClose, source])

    const isCreateOrderDisabled = useMemo(() => {
        const {
            m_lot: lot,
            m_riskInUsd: riskInUsd,
            m_riskInPercent: riskInPercent,
            stoplossValues,
            takeProfitValues,
            m_atPrice: atPrice,
            m_orderType: activeTab,
            isLoading
        } = orderData

        return Boolean(
            (atPrice.error.key && activeTab !== OrderType.MARKET) ||
                lot.error.key ||
                riskInUsd.error.key ||
                riskInPercent.error.key ||
                stoplossValues.price.error.key ||
                stoplossValues.points.error.key ||
                stoplossValues.usd.error.key ||
                stoplossValues.percent.error.key ||
                takeProfitValues.price.error.key ||
                takeProfitValues.points.error.key ||
                takeProfitValues.usd.error.key ||
                takeProfitValues.percent.error.key ||
                isLoading
        )
    }, [orderData])

    const symbolsOptions = useMemo(() => {
        const symbols = OrderModel.getSymbolListForDropDown().map((symbol) => {
            return { value: symbol, label: symbol, before: <SymbolPair symbol={symbol} /> }
        })

        return symbols
    }, [OrderModel.getSymbolListForDropDown()])

    const symbolChange = useCallback(
        (newSymbolValue: string | number) => {
            if (newSymbolValue !== orderData.m_symbol) {
                setNewSymbol(newSymbolValue as string)
            }
        },
        [orderData.m_symbol]
    )

    return (
        <Modal
            name={MODAL_NAMES.chart.orderModal}
            size='sm'
            withCloseIcon
            additionalClassNames={{ container: styles.OrderModalContainer }}
            isDraggable
            onClose={handleModalClose}
        >
            <Modal.Header>
                <Flex gap={8} alignItems='center'>
                    <Typography type='h4-semibold' color='gray-1000'>
                        {headerLabel && isEdit ? headerLabel : t('orders.modal.header')}
                    </Typography>
                    <Tooltip content={t('orders.modal.tooltips.changeSymbol')}>
                        <SimpleDropdown
                            disabled={isEdit}
                            disableBackdropChartActivity
                            classNames={{
                                content: styles.SymbolDropdownContent,
                                header: styles.SymbolDropdownHeader
                            }}
                            onChange={symbolChange}
                            options={symbolsOptions}
                            value={orderData.m_symbol}
                            title={t('orders.modal.chaneSymbolTitle')}
                        />
                    </Tooltip>
                </Flex>
            </Modal.Header>
            <Modal.Content className={styles.Content}>
                {!orderData.isLoading ? (
                    <ContentBlock
                        orderData={orderData}
                        comment={comment}
                        setComment={setComment}
                        isEdit={isEdit}
                        handleModalClose={handleModalClose}
                    />
                ) : (
                    <Flex alignItems='center' justifyContent='center' className={styles.LoadingState}>
                        <CircularProgress size={48} />
                    </Flex>
                )}
            </Modal.Content>
            <Modal.Controls className={styles.ControlsWrapper}>
                <Button
                    tooltipClassNames={{ content: styles.CTA }}
                    label={t(`orders.modal.footer.${isEdit ? 'editButtonCTA' : 'buttonCTA'}`)}
                    classNames={{ root: styles.SubmitButton, content: styles.SubmitButton }}
                    disabled={isCreateOrderDisabled}
                    onClick={handleSubmit}
                    withTooltip={!orderData.isLoading && isCreateOrderDisabled}
                    tooltipText={t('orders.modal.footer.buttonCTADisabledMessage')}
                    tooltipPlacement='top'
                    loading={orderData.isLoading}
                />
            </Modal.Controls>
        </Modal>
    )
})

export default OrderModal
