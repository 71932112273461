import GlobalImageManager from '@fto/lib/globals/GlobalImageManager'

export class NotImplementedError extends Error {
    constructor(message = 'Method not implemented - generic error') {
        super(message)
        this.name = 'NotImplementedError'
    }
}

export class UtilityFunctions {
    static isMethodInCallStack(methodName: string): boolean {
        const error = new Error('not an error, just for stack trace')
        const stack = error.stack || ''
        const stackLines = stack.split('\n')
        return stackLines.some((line) => line.includes(methodName))
    }

    static GetRandomInRange(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    static GetMessageFromError(e: unknown): string {
        if (e instanceof Error) {
            return e.message
        }
        return 'Unknown error'
    }
}

export function changeImageColor(image: HTMLImageElement, color: string): HTMLCanvasElement {
    const keyForImageInGlobalMap = `${image.src}_${color}`
    const cachedImage = GlobalImageManager.Instance.imageColoredMap.get(keyForImageInGlobalMap)

    if (cachedImage) {
        return cachedImage
    } else {
        const offscreenCanvas = document.createElement('canvas')
        const offscreenCtx = offscreenCanvas.getContext('2d')!

        offscreenCanvas.width = image.width
        offscreenCanvas.height = image.height

        offscreenCtx.drawImage(image, 0, 0, image.width, image.height)

        const imageData = offscreenCtx.getImageData(0, 0, image.width, image.height)
        const data = imageData.data

        const r = parseInt(color.slice(1, 3), 16)
        const g = parseInt(color.slice(3, 5), 16)
        const b = parseInt(color.slice(5, 7), 16)

        for (let i = 0; i < data.length; i += 4) {
            if (data[i + 3] > 0) {
                // Change only non-transparent pixels
                data[i] = r // Red
                data[i + 1] = g // Green
                data[i + 2] = b // Blue
            }
        }

        offscreenCtx.putImageData(imageData, 0, 0)

        GlobalImageManager.Instance.imageColoredMap.set(keyForImageInGlobalMap, offscreenCanvas)

        return offscreenCanvas
    }
}

export function parseNestedJSON(obj: any): any {
    for (const key in obj) {
        if (typeof obj[key] === 'string') {
            try {
                obj[key] = JSON.parse(obj[key])
                obj[key] = parseNestedJSON(obj[key])
            } catch (e) {
                continue
            }
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            obj[key] = parseNestedJSON(obj[key])
        }
    }
    return obj
}
