import { TOscWinSplitter } from '../charting/OscWinsListUnit'
import { ChartEvent, ThrottleForRepaint } from '../charting/auxiliary_classes_charting/ChartingEnums'
import { TChart } from '../charting/chart_classes/BasicChart'
import { TMainChart } from '../charting/chart_classes/MainChartUnit'
import { TChartWindow } from '../charting/chart_windows/ChartWindow'
import { TBasicPaintTool } from '../charting/paint_tools/BasicPaintTool'
import { PaintToolManager, TPaintToolClass } from '../charting/paint_tools/PaintToolManager'
import { TPaintToolStatus } from '../charting/paint_tools/PaintToolsAuxiliaryClasses'
import { TPaintToolsList } from '../charting/paint_tools/PaintToolsList'
import { DateUtils, TDateTime } from '../delphi_compatibility/DateUtils'
import { TRuntimeIndicator } from '../extension_modules/indicators/DllIndicatorUnit'
import CommonConstants from '../ft_types/common/CommonConstants'
import GlobalSymbolList from '../globals/GlobalSymbolList'
import GlobalIndicatorDescriptors from './GlobalIndicatorDescriptors'
import GlobalOptions from './GlobalOptions'
import { ObservableTemplateItem, ObserverTemplate } from '@fto/chart_components/ObserverTemplate'
import PaintToolsRegister from '@fto/chart_components/PaintTools'
import { TDateTimeBar } from '@fto/lib/charting/chart_classes/DateTimeBarUnit'
import { TCursor } from '@fto/lib/ft_types/common/CursorPointers'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import globalChartsStore from '@fto/lib/store/globalChartsStore'
import ToolInfoStore from '@fto/lib/store/tools'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import ChartSettingsStore from '@fto/lib/store/chartSettings'
import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'
import { TOscChart } from '../charting/chart_classes/OscChartUnit'
import { addContextMenu } from '@fto/ui'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'
import { ChartControl } from '@fto/chart_components/ChartControl'
import { OrderMarker } from '@fto/lib/OrderModalClasses/OrderMarker'
import { TDataDescriptor } from '@fto/lib/ft_types/data/data_arrays/DataDescriptionTypes'
import CommonDataUtils from '@fto/lib/ft_types/data/DataUtils/CommonDataUtils'
import StrangeError from '../common/common_errors/StrangeError'
import { DebugUtils } from '../utils/DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import { TDataArrayEvents } from '../ft_types/data/data_downloading/DownloadRelatedEnums'
import StrangeSituationNotifier from '../common/StrangeSituationNotifier'
import { OrderDataType } from '../store/ordersStore/types'
import { ELockToOption } from '../ft_types/common/OptionsEnums'
import { showErrorToast } from '@root/utils/toasts'
import { t } from 'i18next'
import { Direction } from '../ft_types/data/TickData'
import ProcessingCoreUtils from '@fto/lib/processing_core/ProcessingCoreUtils'
import GlobalServerSymbolInfo from '@fto/lib/globals/GlobalServerSymbolInfo'
import { crosshairManager } from '@fto/lib/globals/CrosshairManager'
import KeyboardTracker from '@fto/lib/utils/KeyboardTracker'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'

export enum ChartControllerEvent {
    ACTIVE_CHART_CHANGED,
    SYMBOL_CHANGED_ON_CHART
}

class GlobalChartsController implements ObserverTemplate<ChartEvent, TChartWindow> {
    //singleton instance
    private static instance: GlobalChartsController
    private observableItem: ObservableTemplateItem<
        ChartControllerEvent,
        GlobalChartsController,
        ObserverTemplate<ChartControllerEvent, GlobalChartsController>
    >

    public static get Instance(): GlobalChartsController {
        if (!GlobalChartsController.instance) {
            GlobalChartsController.instance = new GlobalChartsController()
        }

        return GlobalChartsController.instance
    }

    public isMouseLeave = false

    private chartWindows: TChartWindow[] = []
    public chartWindowsLoadedFromProject: TChartWindow[] = []

    private activeTimeBar: TDateTimeBar | null = null
    private activeChart: TChartWindow | null = null
    public capturedChart: TChartWindow | null = null
    private sender: TChart | null = null
    private capturedSplitter: TOscWinSplitter | null = null

    private isDisableDT = true
    private isDisableGrid = true
    private isMouseEventEnabled = true
    public isForbiddenToChangeActiveChart = false
    public isInControlledNode = false
    public isChartsFromJSONCreated = false

    private _lastMouseDownTime = 0 //in ms

    private constructor() {
        this.observableItem = new ObservableTemplateItem<
            ChartControllerEvent,
            GlobalChartsController,
            ObserverTemplate<ChartControllerEvent, GlobalChartsController>
        >()
        GlobalSymbolList.SymbolList.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundOnChunkDownloaded)
        GlobalSymbolList.SymbolList.Events.on(TDataArrayEvents.de_SeekCompleted, this.boundOnSeekCompleted)
    }

    public attachObserver(observer: ObserverTemplate<ChartControllerEvent, GlobalChartsController>): void {
        this.observableItem.attachObserver(observer)
    }

    public detachObserver(observer: ObserverTemplate<ChartControllerEvent, GlobalChartsController>): void {
        this.observableItem.detachObserver(observer)
    }

    public createEmptyNewChart(symbolName: string): TChartWindow {
        DebugUtils.log('GlobalChartsController.createNewChart', symbolName)
        const symbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbolName)

        const isCrosses = GlobalServerSymbolInfo.Instance.getSymbolCategory(symbolName) === 'Crosses'

        if (isCrosses) {
            const crossSymbol = ProcessingCoreUtils.GetSymbolForConversionToUSD(symbolData, true).symbolName
            GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(crossSymbol)
        }

        return new TChartWindow(symbolName)
    }

    public clearAllChartWindows(): void {
        this.chartWindows = []
        this.activeChart = null
        this.capturedChart = null
    }

    public updateChartList(chartsCount: number, newSymbolName: string): TChartWindow[] {
        DebugUtils.logTopic(
            ELoggingTopics.lt_Loading,
            'GlobalChartsController.updateChartList',
            chartsCount,
            newSymbolName
        )

        let result = []

        for (const chart of this.chartWindows) {
            chart.detachObserver(this)
            chart.isShown = false
        }

        if (this.isChartsFromJSONCreated) {
            result = this.changeLayoutByUser(newSymbolName, chartsCount)
        } else {
            result = this.createChartsByJSON()
        }

        //save old charts to temp array
        const oldChartThatAreStillNeeded = []
        for (const chart of this.chartWindows) {
            if (result.includes(chart)) {
                oldChartThatAreStillNeeded.push(chart)
            }
        }

        //leave old charts that are still necessary back to chartWindow and remove all the rest.
        //New charts that did not exist before will be pushed here from components
        this.chartWindows = oldChartThatAreStillNeeded

        for (const chart of result) {
            chart.isShown = true

            this.toggleActiveFrame(chart, result.length)
        }

        return result
    }

    private syncHorizScrollPositionByActiveChart(chartWindows: TChartWindow[]): void {
        if (!this.activeChart) {
            return
        }

        this.syncHorizScrollPositionForAll(chartWindows, this.activeChart)
    }

    private syncHorizScrollPositionForAll(chartWindows: TChartWindow[], baseChart: TChartWindow): void {
        if (!baseChart.isDataLoaded) {
            return
        }

        const baseChartScrollPosition = baseChart.getHorizScrollAnchorDate(false)

        for (const chartWin of chartWindows) {
            if (chartWin !== baseChart) {
                if (chartWin.MainChart.is_HTML_Canvas_initialized()) {
                    DebugUtils.logTopic(ELoggingTopics.lt_ScrollAndZoom, `Syncing ${chartWin.DName}`)
                    chartWin.ScrollToDate(baseChartScrollPosition)
                } else {
                    //FIXME: finish implementing deferral of scroll position setting
                    // chartWin.MainChart.Events.on(EvChartLoadingEvents.ce_OnCanvasInitialized, () => {
                    //     chartWin.ScrollToScrollPosition(baseChartScrollPosition)
                    // })
                }
            }
        }
    }

    private createChartsByJSON(): TChartWindow[] {
        if (this.chartWindowsLoadedFromProject.length > 0) {
            const result = this.chartWindowsLoadedFromProject
            this.chartWindowsLoadedFromProject = []
            this.isChartsFromJSONCreated = true
            return result
        } else {
            throw new StrangeError('No chart windows loaded from project')
        }
    }

    private changeLayoutByUser(newSymbolName: string, requestedChartsCount: number): TChartWindow[] {
        const updatedChartWindows: TChartWindow[] = []

        // Ensure active chart is always first in the new list if it exists
        if (this.activeChart && this.chartWindows.includes(this.activeChart)) {
            updatedChartWindows.push(this.activeChart)
        }

        // Copy existing chart windows to the new list, excluding the active chart if it's already added
        updatedChartWindows.push(...this.chartWindows.filter((chart) => chart !== this.activeChart))

        this.throwErrorIfArraysNotValid(updatedChartWindows)

        if (requestedChartsCount > updatedChartWindows.length) {
            //case when user creates a new chart (like changes layout from 1 to 2)
            const extraChartsCount = requestedChartsCount - updatedChartWindows.length
            updatedChartWindows.push(...this.GetRequestedExtraCharts(extraChartsCount, newSymbolName))
        } else {
            //cut extra charts if necessary
            updatedChartWindows.length = requestedChartsCount
        }

        this.syncHorizScrollPositionByActiveChart(updatedChartWindows)

        return updatedChartWindows
    }

    private throwErrorIfArraysNotValid(updatedChartWindows: TChartWindow[]) {
        if (updatedChartWindows.length > CommonConstants.MAX_NUMBER_OF_CHARTS) {
            throw new StrangeError('The number of charts exceeds the maximum allowed number of charts')
        }

        if (this.chartWindows.length > CommonConstants.MAX_NUMBER_OF_CHARTS) {
            throw new StrangeError('The number of charts exceeds the maximum allowed number of charts')
        }
    }

    private GetRequestedExtraCharts(extraChartsCount: number, newSymbolName: string): TChartWindow[] {
        const newRequestedCharts = []
        while (newRequestedCharts.length < extraChartsCount) {
            if (newSymbolName) {
                const newChart = this.createEmptyNewChart(newSymbolName)
                newRequestedCharts.push(newChart)
                const symbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(newSymbolName)
                newChart.InitWithDefaultOptions()
                newChart.setSymbol(symbolData)
            } else {
                throw new StrangeError('Symbol name is not provided when creating a new chart')
            }
        }
        return newRequestedCharts
    }

    private toggleActiveFrame(chart: TChartWindow, chartsCount: number) {
        if (chartsCount > 1) {
            chart.EnableActiveFrame()
        } else {
            chart.DisableActiveFrame()
        }
    }

    public setNewTimeFrame(timeframe: number): void {
        if (this.activeChart) {
            this.activeChart.SetTimeframe(timeframe)
        }
    }

    public setNewSymbol(symbolName: string, chartWindow: TChartWindow): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Loading, 'GlobalChartsController.setNewSymbol', symbolName)
        const newSymbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol(symbolName, true)
        if (newSymbol) {
            chartWindow.SelectedSymbolName = symbolName
            chartWindow.setSymbol(newSymbol)
            chartWindow.controlsManager.clear()

            const { updateData: updateGlobalChartData } = globalChartsStore

            updateGlobalChartData((previous) => ({
                ...previous,
                activeChartSymbol: newSymbol.symbolInfo.SymbolName
            }))

            this.observableItem.notify(ChartControllerEvent.SYMBOL_CHANGED_ON_CHART, this)
        }
    }

    public registerPaintTool(toolName: string): void {
        if (this.activeChart) {
            this.disableCrossHairMode(this.activeChart)
            this.activeChart.SetNormalMode()
            const { setInfo } = ToolInfoStore
            setInfo((previousState) => ({ ...previousState, isDrawing: true }))
            PaintToolsRegister(this.activeChart, toolName)
        }
    }

    public setDTStatus(status: boolean): void {
        this.isDisableDT = status
    }

    public setGridStatus(status: boolean): void {
        this.isDisableGrid = status
        if (this.activeChart) {
            this.activeChart.ChartOptions.ShowGrid = status
            this.activeChart.Repaint()
        }
    }

    public getGridAndDTStatus() {
        return {
            isDisableDT: this.isDisableDT,
            isDisableGrid: this.isDisableGrid
        }
    }

    public addChart(chart: TChartWindow): void {
        if (!this.activeChart) {
            this.activeChart = chart
            this.activeChart.focused = true
        }

        const { updateData: updateGlobalChartData } = globalChartsStore

        updateGlobalChartData((prev) => ({
            ...prev,
            activeChartSymbol: this.activeChart?.SelectedSymbolName || '',
            activeChart: this.activeChart
        }))

        // chart.attachObserver(this);
        this.chartWindows.push(chart)
    }

    public attachChart(chart: TChartWindow): void {
        chart.attachObserver(this)
    }

    public onMouseMove(event: MouseEvent): void {
        if (this.capturedSplitter) {
            this.capturedSplitter.MouseMove(event)
            return
        }

        const chart = this.capturedChart || this.getChartUnderMouse(event)

        if (chart && chart.isDataLoaded && this.sender) {
            chart.OnMouseMove(event, this.sender)
        }

        for (const chWin of this.chartWindows) {
            for (const oscWin of chWin.OscWins) {
                oscWin.splitter.MouseMove(event)
                oscWin.splitter.Paint()
            }
        }

        if (this.activeTimeBar && this.activeTimeBar.IsCaptured && this.activeTimeBar.chartWin) {
            this.activeTimeBar.SetCursor(TCursor.crSizeWE)

            if (event.movementX > 0) {
                this.ZoomOutExecute(this.activeTimeBar.chartWin, 0.5)
            }
            if (event.movementX < 0) {
                this.ZoomInExecute(this.activeTimeBar.chartWin, 0.5)
            }

            this.activeTimeBar.chartWin.Repaint()
        }
    }

    private getTimeBarUnderMouse(event: MouseEvent): TDateTimeBar | null {
        let result: TDateTimeBar | null = null

        for (const chart of this.chartWindows) {
            if (chart instanceof TChartWindow) {
                this.sender = chart.TimeBar.ChartUnderMouse(event)
                if (this.sender) {
                    result = chart.TimeBar
                    break
                }
            }
        }

        return result
    }

    public onMouseUp(event: MouseEvent): void {
        DebugUtils.logTopic(ELoggingTopics.lt_MouseEvents, 'GlobalChartsController.onMouseUp', event)
        if (this.capturedSplitter) {
            this.capturedSplitter.MouseUp(event)
            this.capturedSplitter = null
            return
        }

        const chart = this.capturedChart || this.getChartUnderMouse(event)

        if (chart && this.sender) {
            chart.OnMouseUp(event, this.sender)
        } else {
            for (const chartWin of this.chartWindows) {
                const splitter = chartWin.getSplitterUnderMouse(event)
                if (splitter) {
                    splitter.MouseUp(event)
                }

                chartWin.TimeBar.OnMouseUp(event, chartWin.TimeBar)
            }
        }

        if (event.button === 0) {
            this.capturedChart = null
        }

        if (this.activeTimeBar) {
            this.activeTimeBar.OnMouseUp(event, this.activeTimeBar)
            this.activeTimeBar = null
        }
    }

    public onContextMenu(event: MouseEvent) {
        const chart = this.getChartUnderMouse(event)
        if (!chart) {
            return
        }
        if (chart.toolCancelDraw) {
            chart.toolCancelDraw = false
            return
        }

        const { x: relativeX, y: relativeY } =
            event.target === chart.MainChart.HTML_Canvas
                ? chart.MainChart.MouseToLocal(event)
                : (chart['_ChartUnderMouse'] as TOscChart).MouseToLocal(event)

        const toolUnderMouse =
            chart.MainChart.PaintTools.ToolUnderMouse(relativeX, relativeY) ??
            (chart['_ChartUnderMouse'] as TOscChart).PaintTools.ToolUnderMouse(relativeX, relativeY)
        const indicator = chart.MainChart.GetIndicatorUnderMouse(event)[2]

        if (toolUnderMouse) {
            if (!toolUnderMouse.Selected) {
                chart.MainChart.PaintTools.DeselectAllTools()
                chart.MainChart.PaintTools.selectTool(toolUnderMouse)
            }

            chart.onContextMenu(event)
            addContextMenu(CONTEXT_MENU_NAMES.tool, {
                anchorX: event.clientX,
                anchorY: event.clientY,
                additionalProps: {
                    tools: chart.MainChart.PaintTools.getAllSelectedTools(),
                    chart: chart
                }
            })
            return
        }
        if (indicator) {
            chart.onContextMenu(event)
            addContextMenu(CONTEXT_MENU_NAMES.indicator, {
                anchorX: event.clientX,
                anchorY: event.clientY,
                additionalProps: {
                    indicator: indicator,
                    chart: chart
                }
            })
            return
        }
        if (chart['_ChartUnderMouse'] instanceof TMainChart && event.target === chart.MainChart.HTML_Canvas) {
            const activeTool = this.activeChart?.getActiveTool()

            if (activeTool instanceof OrderMarker) {
                activeTool.onCancelPricePick()
                return
            }
            GlobalChartsController.Instance.disableMouseEvents()
            addContextMenu(CONTEXT_MENU_NAMES.chart, {
                anchorX: event.clientX,
                anchorY: event.clientY,
                additionalProps: {
                    chart: chart
                }
            })
            return
        }
        if (chart['_ChartUnderMouse'] instanceof TOscChart && event.target === chart['_ChartUnderMouse'].HTML_Canvas) {
            chart.onContextMenu(event)
            addContextMenu(CONTEXT_MENU_NAMES.indicator, {
                anchorX: event.clientX,
                anchorY: event.clientY,
                additionalProps: {
                    indicator: chart['_ChartUnderMouse'].indicators[0],
                    chart: chart
                }
            })
        }
    }

    public onMouseDown(event: MouseEvent): void {
        DebugUtils.logTopic(ELoggingTopics.lt_MouseEvents, 'GlobalChartsController.onMouseDown', event)

        this._lastMouseDownTime = performance.now()

        if (!this.isMouseEventEnabled) {
            return
        }

        if (this.isForbiddenToChangeActiveChart) {
            this.activeChart?.OnMouseDown(event, this.activeChart?.MainChart)
            return
        }

        for (const chart of this.chartWindows) {
            const splitter = chart.getSplitterUnderMouse(event)
            if (splitter) {
                this.capturedSplitter = splitter
                splitter.MouseDown(event)
                break
            }
        }

        if (!this.capturedSplitter) {
            const chart = this.capturedChart || this.getChartUnderMouse(event)

            if (chart && this.sender) {
                if (event.button === 0) {
                    this.capturedChart = chart
                }
                chart.OnMouseDown(event, this.sender)
            } else {
                const timeBar = this.getTimeBarUnderMouse(event)
                if (timeBar) {
                    this.activeTimeBar = timeBar
                    timeBar.OnMouseDown(event, timeBar)
                }
            }
        }
        this.updateCharts()
    }

    public disableMouseEvents(): void {
        this.isMouseEventEnabled = false
    }

    public enableMouseEvents(): void {
        this.isMouseEventEnabled = true
    }

    public onMouseWheel(event: WheelEvent): boolean {
        if (KeyboardTracker.getInstance().isShiftPressed && KeyboardTracker.getInstance().isCtrlPressed) {
            if (event.deltaY < 0) {
                GlobalTimezoneDSTController.Instance.upTimeZone()
                this.updateCharts()
                return true
            }
            if (event.deltaY > 0) {
                GlobalTimezoneDSTController.Instance.downTimeZone()
                this.updateCharts()
                return true
            }
        }

        const chart = this.getChartUnderMouse(event)

        if (chart) {
            if (event.deltaY < 0) {
                this.ZoomInExecute(chart)
                this.updateCharts()
            } else if (event.deltaY > 0) {
                this.ZoomOutExecute(chart)
                this.updateCharts()
            }
        } else {
            return false
        }

        return true
    }

    public onMouseLeave(event: MouseEvent): void {
        for (const chart of this.chartWindows) {
            chart.OnMouseLeave(event)
        }
    }

    public processEvent(event: ChartEvent, chartWindow: TChartWindow): void {
        switch (event) {
            case ChartEvent.SAVE_STATE_ON_CHART: {
                for (const chart of this.chartWindows) {
                    if (chart.getID() !== chartWindow.getID()) {
                        chart.saveState()
                    }
                }
                this.updateCharts()
                break
            }
            case ChartEvent.UNDO_LAST_STATE_ON_CHART: {
                for (const chart of this.chartWindows) {
                    if (chart.getID() !== chartWindow.getID()) {
                        chart.undoLastChange(false)
                    }
                }
                this.updateCharts()
                break
            }
            case ChartEvent.REDO_LAST_STATE_ON_CHART: {
                for (const chart of this.chartWindows) {
                    if (chart.getID() !== chartWindow.getID()) {
                        chart.redoLastChange(false)
                    }
                }
                this.updateCharts()
                break
            }
            case ChartEvent.CROSS_HAIR_MODE_START_PERMANENT: {
                this.processStartCrossHairMode(chartWindow, false)
                break
            }
            case ChartEvent.CROSS_HAIR_MODE_START_ROOLER: {
                this.processStartCrossHairMode(chartWindow, true)
                break
            }
            case ChartEvent.UPDATE_LINKED_CROSS_HAIRS: {
                this.updAllLayersOnCharts()
                break
            }
            case ChartEvent.CROSS_HAIR_MODE_END: {
                this.disableCrossHairMode(chartWindow)
                break
            }
            case ChartEvent.ACTIVATE: {
                if (!this.isForbiddenToChangeActiveChart) {
                    this.activateChart(chartWindow)
                }
                break
            }
            case ChartEvent.UPDATE_CROSS_HAIR_ACTIVE_CHART: {
                if (!this.isForbiddenToChangeActiveChart) {
                    this.updateCrossHairActiveChart(chartWindow)
                }
                break
            }
            case ChartEvent.SCROLL_OTHER_CHARTS: {
                this.OnScrollOtherCharts(chartWindow)
                this.updateCharts()
                break
            }
            case ChartEvent.PAINT_COMPLETE: {
                DebugUtils.log('paint ended')
                break
            }
            case ChartEvent.PAINT_TOOL_WINDOW_SELECTED: {
                DebugUtils.log('ChartEvent.PAINT_TOOL_WINDOW_SELECTED', event)
                break
            }
            case ChartEvent.CHARTS_NEED_REDRAW: {
                this.updateCharts()
                break
            }
            case ChartEvent.UPDATE_LINKED_TOOLS: {
                this.OnUpdateLinkedTools(chartWindow)
                break
            }
            case ChartEvent.COPY_PAINT_CURRENT_TOOL: {
                const toolToCopy = chartWindow.currentToolToCopy

                if (!toolToCopy) {
                    return
                }

                this.OnCopyPaintTool(chartWindow, toolToCopy)

                break
            }
            case ChartEvent.DELETE_LINKED_PAINT_TOOLS: {
                if (chartWindow.currentToolToDeleteLinkNumber !== CommonConstants.EMPTY_INDEX) {
                    this.OnDeleteLinkedTool(chartWindow.currentToolToDeleteLinkNumber)
                }
                break
            }
            case ChartEvent.COPY_PAINT_TOOLS_TO_OTHER_CHARTS: {
                const selectedTools = chartWindow.preparedToolsForCopyList
                if (selectedTools) {
                    for (const tool of selectedTools) {
                        this.OnCopyPaintTool(chartWindow, tool)
                    }
                }
                break
            }
            case ChartEvent.UPDATE_SINGLE_CHART: {
                chartWindow.Repaint()
                break
            }
            case ChartEvent.UPDATE_ALL_LAYERS: {
                for (const chart of this.chartWindows) {
                    chart.UpdateLayers()
                }
                break
            }
            case ChartEvent.ON_SEEK_COMPLETED: {
                this.observableItem.notify(ChartControllerEvent.SYMBOL_CHANGED_ON_CHART, this)
                break
            }
            case ChartEvent.ENABLE_AUTOSCALE_ON_ALL_CHARTS: {
                for (const chart of this.chartWindows) {
                    chart.AutoScaleOn()
                }
                break
            }
            case ChartEvent.UPDATE_CHARTS_WITHOUT_THROTTLE: {
                this.updateCharts(ThrottleForRepaint.Disabled)
                break
            }
            default: {
                throw new StrangeError('Unhandled chart event:', event)
            }
        }
    }

    public getChartUnderMouse(event?: MouseEvent): TChartWindow | null {
        let result: TChartWindow | null = null

        for (const chart of this.chartWindows) {
            this.sender = chart.ChartUnderMouse()
            if (this.sender && chart.isShown) {
                result = chart
                break
            }
        }

        return result
    }

    public updAllLayersOnCharts() {
        for (const chart of this.chartWindows) {
            chart.getChartWindowLayers().getManagerLayers().reDrawAll()
        }
    }

    public updateCharts(useThrottle: ThrottleForRepaint = ThrottleForRepaint.Enabled): void {
        for (const chart of this.chartWindows) {
            if (useThrottle === ThrottleForRepaint.Enabled) {
                chart.Repaint()
            } else {
                chart.RepaintWithoutThrottle()
            }
        }
    }

    public applyStrongMagnetMode() {
        for (const chart of this.chartWindows) {
            chart.applyStrongMagnetMode()
        }
        this.updateCharts()
    }

    public doFakeMouseMove(): void {
        for (const chart of this.chartWindows) {
            chart.updateToolDeegree()
        }
        this.updateCharts()
    }

    private processStartCrossHairMode(chartWindow: TChartWindow, rooler: boolean) {
        crosshairManager.enableCrosshairMode(chartWindow, rooler)

        if (rooler) {
            for (const chart of this.chartWindows) {
                chart.getChartWindowLayers().getCrosshairControl().enableCrosshairWithRooler()
                chart.SetCursor(TCursor.crCross)
            }
        }

        chartWindow.getChartWindowLayers().getCrosshairControl().updCrosshair()

        ToolInfoStore.setInfo((prev) => ({
            ...prev,
            isCrosshairMode: true
        }))
    }

    private disableCrossHairMode(chartWindow: TChartWindow): void {
        ToolInfoStore.setInfo((prev) => ({
            ...prev,
            isCrosshairMode: false
        }))
        crosshairManager.resetCrosshairState()
        for (const chart of this.chartWindows) {
            chart.getChartWindowLayers().getCrosshairControl().dispose()
            chart.SetNormalMode()
            chart.Repaint()
        }

        crosshairManager.restorePermCrosshairIfNeeded(chartWindow)
    }

    private updateCrossHairActiveChart(chartWindow: TChartWindow): void {
        for (const chart of this.chartWindows) {
            chart.SetNormalMode()
        }
        crosshairManager.enableCrosshairMode(chartWindow)
        this.updateCharts()
    }

    private activateChart(chartWindow: TChartWindow): void {
        if (this.activeChart !== chartWindow) {
            if (this.activeChart) {
                this.activeChart.focused = false
                this.activeChart.Repaint()
            }
            this.activeChart = chartWindow
            this.activeChart.focused = true
            this.activeChart.ExportSettings()

            for (const chart of this.getAllCharts()) {
                if (chart !== this.activeChart) {
                    chart.DeselectAllTools()
                }
            }

            this.activeChart.Repaint()
            const { updateData: updateGlobalChartData } = globalChartsStore

            updateGlobalChartData((prev) => ({
                ...prev,
                activeChartSymbol: chartWindow.SelectedSymbolName,
                activeChart: this.activeChart
            }))
        }
    }
    public enableTimeZone() {
        for (const chart of this.chartWindows) {
            const flagBefore = chart.mouseOverChart

            if (chart.MainChart === chart.ChartUnderMouse()) {
                chart.mouseOverChart = true
            } else {
                chart.mouseOverChart = false
            }

            if (flagBefore !== chart.mouseOverChart) {
                chart.Repaint()
            }
        }
    }

    private OnScrollOtherCharts(chartWindow: TChartWindow): void {
        if (GlobalOptions.Options.ScrollAllCharts) {
            this.updateHorizScrollPositionForOtherChartWins(chartWindow)
        }
    }

    public getActiveChartBarTime(): TDateTime {
        if (!this.activeChart) {
            throw new StrangeError(
                'Abnormal behavior. At the time of calling the function getActiveChartBarTime, activeChart was null or undefined.'
            )
        }

        return this.activeChart.getChartBarTime()
    }

    private ZoomOutExecute(chartWin: TChartWindow, zoomSpeedCoefficient = 1): void {
        if (!chartWin.isDataLoaded) {
            return
        }
        if (chartWin.ChartOptions.IsHScaleDownPossible()) {
            const anchor = chartWin.getHorizScrollAnchorDate()

            if (DateUtils.IsEmpty(anchor.date)) {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Anchor date is empty in zoom out')
                return
            }
            DebugUtils.logTopic(ELoggingTopics.lt_ScrollAndZoom, `anchorDate: ${anchor}`)

            chartWin.ZoomOutExecute(anchor, zoomSpeedCoefficient)
            this.updateHorizScrollPositionForOtherChartWins(chartWin)
        }
    }

    private updateHorizScrollPositionForOtherChartWins(baseChartWin: TChartWindow) {
        this.syncHorizScrollPositionForAll(this.chartWindows, baseChartWin)
    }

    private ZoomInExecute(chartWin: TChartWindow, zoomSpeedCoefficient = 1): void {
        if (!chartWin.isDataLoaded) {
            return
        }
        if (chartWin.ChartOptions.IsHScaleUpPossible()) {
            const anchor = chartWin.getHorizScrollAnchorDate()

            if (DateUtils.IsEmpty(anchor.date)) {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Anchor date is empty in zoom in')
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const tempForDebug = chartWin.getHorizScrollAnchorDate()
                return
            }
            DebugUtils.logTopic(ELoggingTopics.lt_ScrollAndZoom, `anchorDate: ${anchor}`)

            chartWin.ZoomInExecute(anchor, zoomSpeedCoefficient)
            this.updateHorizScrollPositionForOtherChartWins(chartWin)
        }
    }

    public getAllCharts(): TChartWindow[] {
        return this.chartWindows
    }

    public getActiveChart(): TChartWindow | null {
        return this.activeChart
    }

    RestoreAllIndicators() {
        for (const chartWindow of this.chartWindows) {
            if (chartWindow instanceof TChartWindow) {
                chartWindow.RestoreAllIndicators()
            }
        }
    }

    OnUpdateLinkedTools(chartWindow: TChartWindow): void {
        const sender: TChartWindow = chartWindow

        if (sender !== null) {
            const tools: TPaintToolsList = sender.MainChart.PaintTools // Access PaintTools

            for (const chart of this.chartWindows) {
                // Skip the sender chart
                if (chart === sender) {
                    continue
                }

                // If linked tools are updated, repaint the chart
                if (chart.MainChart.PaintTools.UpdateLinkedTools(tools) > 0) {
                    chart.Repaint()
                }
            }
        }
    }

    CloseAllWindows() {
        console.error('CloseAllWindows is not implemented yet.')
    }

    public RefreshCharts(forceScroll: boolean, forceRepaint = false, isAutoScaling = false): void {
        for (let i = 0; i < this.chartWindows.length; i++) {
            const chartWindow = this.chartWindows[i]

            if (forceScroll && chartWindow.ChartOptions.AutoScroll) {
                chartWindow.ScrollRight()
            }

            chartWindow.CorrectLeftPos()

            if (forceRepaint) {
                if (isAutoScaling) {
                    if (chartWindow.ChartOptions.AutoScroll) {
                        chartWindow.CenterBarsVertically()
                    }
                } else {
                    chartWindow.Repaint()
                }
            } else {
                chartWindow.invalidate()
            }

            if (this.isInControlledNode) {
                chartWindow.getChartWindowLayers().updateBarInfoLabel(false)
            } else {
                chartWindow.getChartWindowLayers().updateBarInfoLabel(true)
            }
        }
    }

    public onEnterControlledNode(): void {
        this.isInControlledNode = true
    }

    public onLeaveControlledNode(): void {
        this.isInControlledNode = false
    }

    OnDeleteLinkedTool(linkNumber: number): void {
        if (linkNumber !== null) {
            for (const chart of this.chartWindows) {
                // Delete the tool linked by the number in message.WParam
                chart.MainChart.PaintTools.DeleteToolByLinkNumber(linkNumber)
            }
        }

        // Refresh charts without saving state and with repainting
        this.updateCharts()
    }

    OnHideLinkedTool(linkNumber: number): void {
        if (linkNumber !== null) {
            for (const chart of this.chartWindows) {
                chart.MainChart.PaintTools.HideToolByLinkNumber(linkNumber)
            }
        }

        this.updateCharts()
    }

    OnShowLinkedTool(linkNumber: number): void {
        if (linkNumber !== null) {
            for (const chart of this.chartWindows) {
                chart.MainChart.PaintTools.ShowToolByLinkNumber(linkNumber)
            }
        }

        this.updateCharts()
    }

    OnCopyPaintTool(chartWindow: TChartWindow, tool: TBasicPaintTool): void {
        if (!GlobalOptions.Options.CopyPaintToolsOnCharts) {
            return
        }

        if (!(tool.chart instanceof TMainChart)) {
            return
        }

        const ToolClass: TPaintToolClass | null = PaintToolManager.GetPaintToolClass(tool.ShortName)
        if (ToolClass === null) {
            return
        }

        GlobalOptions.Options.ToolsLinkNumber++
        // GlobalOptions.Options.Save();

        const LinkNumber: number = GlobalOptions.Options.ToolsLinkNumber
        tool.LinkNumber = LinkNumber

        for (const chart of this.chartWindows) {
            if (
                chart !== this.activeChart &&
                //TODO: refactor this
                chart.SelectedSymbolName === this.activeChart?.SelectedSymbolName
            ) {
                // Copy tool
                const NewTool: TBasicPaintTool = new ToolClass(chart.MainChart)
                chart.MainChart.PaintTools.AddTool(NewTool)
                NewTool.assign(tool)
                NewTool.LinkNumber = LinkNumber
                NewTool.status = TPaintToolStatus.ts_Completed
                NewTool.RecountScreenCoords()
            }
        }

        this.updateCharts()
    }

    public onDblClick(event: MouseEvent): void {
        DebugUtils.logTopic(ELoggingTopics.lt_MouseEvents, 'GlobalChartsController.onDblClick', event)

        if (!this.isRealDblClick()) {
            DebugUtils.logTopic(
                ELoggingTopics.lt_MouseEvents,
                'GlobalChartsController.onDblClick',
                'not real dbl click'
            )
            return
        }
        // if (!this.isRealDblClick()) {
        //     for (const chart of this.chartWindows) {
        //         const splitter = chart.getSplitterUnderMouse(event)
        //         if (splitter) {
        //             this.capturedSplitter = splitter
        //             break
        //         }
        //     }
        // }

        if (!this.capturedSplitter) {
            const chart = this.capturedChart || this.getChartUnderMouse(event)

            if (chart && this.sender) {
                chart.onDblClick(event)
            }
        }
    }

    private isRealDblClick() {
        const now = performance.now()
        const timeDiff = now - this._lastMouseDownTime
        DebugUtils.logTopic(ELoggingTopics.lt_MouseEvents, 'isRealDblClick', timeDiff)
        return timeDiff < 500
    }

    public clearToolToEdit(): void {
        if (this.activeChart) {
            this.activeChart.clearToolToEdit()
            this.activeChart.Update()
        }
    }

    public applyNewSettingsOnSelectedPaintTool(): void {
        if (this.activeChart) {
            this.activeChart.applyNewSettingsOnSelectedPaintTool()
            this.activeChart.Update()
        }
    }

    public createIndicator(indicatorName: string): TRuntimeIndicator {
        const newIndicatorDescriptor = GlobalIndicatorDescriptors.BuiltInIndicators.findByName(indicatorName)

        if (this.activeChart) {
            const indicatorInstance: TRuntimeIndicator = this.activeChart.CreateIndicator(
                newIndicatorDescriptor,
                this.activeChart?.ChartOptions.Timeframe
            )
            indicatorInstance.ExportData()
            return indicatorInstance
        } else {
            throw new StrangeError(`Indicator ${indicatorName} could not be created because active chart not found.`)
        }
    }

    public setUpIndicator(indicator: TRuntimeIndicator, edit: boolean = false, setupForAllCharts: boolean = false) {
        if (!this.activeChart) throw new StrangeError('Active chart is not defined')

        if (setupForAllCharts) {
            this.chartWindows.forEach((chart) => {
                chart.copySettingsToAllSameIndicators(indicator)
            })
        }

        if (edit) {
            this.activeChart.DoEditIndicator(indicator)
        } else {
            this.activeChart.CreateIndicatorAndRecount(indicator, this.activeChart.ChartOptions.Timeframe, true)
        }
    }

    public IsOscUnderMouse(): boolean {
        for (let chartIndex = 0; chartIndex < this.chartWindows.length; chartIndex++) {
            const chart = this.chartWindows[chartIndex]
            for (let oscWinIndex = 0; oscWinIndex < chart.OscWins.length; oscWinIndex++) {
                if (chart.OscWins[oscWinIndex].chart.IsMouseInside()) {
                    return true
                }
            }
        }
        return false
    }

    public IsTimeframeVisible(timeframeDescriptor: TDataDescriptor): boolean {
        return this.chartWindows.some((chart) =>
            CommonDataUtils.isDataDescriptorEqual(chart.Bars.DataDescriptor, timeframeDescriptor)
        )
    }

    private lastTimeEnableLoaderCalled = DateUtils.EmptyDate

    public enableLoader(): void {
        if (DateUtils.DifferenceInMilliseconds(DateUtils.Now(), this.lastTimeEnableLoaderCalled) > 1000) {
            for (const chartWin of this.chartWindows) {
                chartWin.MainChart.startLoaderForDownloadableChunk()
            }

            this.updateCharts()
            this.lastTimeEnableLoaderCalled = DateUtils.Now()
        }
    }

    public disableLoader(): void {
        for (const chartWin of this.chartWindows) {
            chartWin.MainChart.stopLoaderForDownloadableChunk()
        }

        this.updateCharts()
        this.lastTimeEnableLoaderCalled = DateUtils.Now()
    }

    public setPreparingChartBlockingLayers(): void {
        for (const chartWin of this.chartWindows) {
            chartWin.setPreparingChartBlockingLayer()
        }

        this.updateCharts()
    }

    private boundOnChunkDownloaded = this.onChunkDownloaded.bind(this)

    private onChunkDownloaded(): void {
        this.DisableLoaderIfPossible()
    }

    private boundOnSeekCompleted = this.onSeekCompleted.bind(this)

    private onSeekCompleted(): void {
        this.DisableLoaderIfPossible()
    }

    private DisableLoaderIfPossible() {
        DebugUtils.logTopic(ELoggingTopics.lt_Seek, 'GlobalChartsController.DisableLoaderIfPossible')
        if (GlobalSymbolList.SymbolList.isReadyToTick()) {
            DebugUtils.logTopic(ELoggingTopics.lt_Seek, 'disabling the loader')
            this.disableLoader()
        }
    }

    public onTestingStart(): void {
        for (const chart of this.chartWindows) {
            chart.lastBarIndexOnStartTesting = chart.Bars.LastItemInTestingIndex
        }
    }

    public onTestingStop() {
        if (this.activeChart) {
            const differenceBetweenLastBarIndexInTesting =
                this.activeChart.Bars.LastItemInTestingIndex - this.activeChart.lastBarIndexOnStartTesting

            return {
                barsTested: differenceBetweenLastBarIndexInTesting
            }
        }
    }

    public hasHiddenTools(): boolean {
        for (const chart of this.chartWindows) {
            if (chart.hasHiddenTools()) {
                return true
            }
        }
        return false
    }

    public hasHiddenIndicators(): boolean {
        for (const chart of this.chartWindows) {
            if (chart.hasHiddenIndicators()) {
                return true
            }
        }
        return false
    }

    private ClearAllGraphToolsFromAllCharts(): void {
        for (const chart of this.chartWindows) {
            chart.MainChart.PaintTools.DeleteAllTools()
            chart.Repaint()
        }

        for (const chart of this.chartWindows) {
            for (const oscWin of chart.OscWins) {
                oscWin.chart.PaintTools.DeleteAllTools()
            }
            chart.Repaint()
        }
    }

    private SetCurrentTimeToProjectStartDate(): void {
        const innerLibTime = GlobalTimezoneDSTController.Instance.convertToInnerlibDateTimeByTimezoneAndDst(
            GlobalProjectInfo.ProjectInfo.StartDate
        )
        const diff = GlobalProjectInfo.ProjectInfo.StartDate - innerLibTime
        const projectStartDate = GlobalProjectInfo.ProjectInfo.StartDate - diff

        GlobalSymbolList.SymbolList.Seek(projectStartDate)
    }

    public RestartProject(): void {
        this.ClearAllGraphToolsFromAllCharts()
        this.SetCurrentTimeToProjectStartDate()
        GlobalProcessingCore.ProcessingCore.Reset()
        for (const chart of this.chartWindows) {
            chart.controlsManager.clear()
        }
        GlobalProcessingCore.ProcessingCore.InitialDeposit(
            GlobalProjectInfo.ProjectInfo.StartDate,
            GlobalProjectInfo.ProjectInfo.deposit
        )
        GlobalProcessingCore.ProcessingCore.UpdateStatistics()
        const { setSettings } = ChartSettingsStore

        setSettings((settings) => ({
            ...settings,
            isPlaying: false
        }))

        GlobalTestingManager.TestingManager.stopTheTimer()
    }

    public getChartBySymbol(symbol: string): TChartWindow | undefined {
        return this.chartWindows.find((chart) => chart.SelectedSymbolName === symbol)
    }

    public forceActivateChart(chart: TChartWindow): void {
        this.activateChart(chart)
    }

    public getMaxVisibleDateRange(): [TDateTime, TDateTime] {
        let maxStartDate = 0
        let maxEndDate = 0

        for (const chart of this.chartWindows) {
            const [startDate, endDate] = chart.MainChart.getDateRange()
            if (startDate > maxStartDate) {
                maxStartDate = startDate
            }
            if (endDate > maxEndDate) {
                maxEndDate = endDate
            }
        }

        return [maxStartDate, maxEndDate]
    }

    public onFontsLoaded(): void {
        for (const chart of this.chartWindows) {
            chart.onFontsLoaded()
        }
    }

    public recalculateRightMargins(): void {
        for (const chart of this.chartWindows) {
            chart.MainChart.recalculateRightMargin()
        }
        this.updateCharts()
    }

    public recalculateDateTimeBarSize(): void {
        for (const chart of this.chartWindows) {
            chart.TimeBar.resizeDateTimeBar()
        }
    }

    updateMarketValues() {
        for (const chart of this.chartWindows) {
            chart.MainChart.updateMarketValues()
        }
    }

    removeControlFromControlsManager(control: ChartControl) {
        for (const chart of this.chartWindows) {
            chart.controlsManager.removeControl(control)
        }
    }

    clearControlsManager() {
        for (const chart of this.chartWindows) {
            chart.controlsManager.clear()
        }
    }

    setIsModalOpenToControlsManager(isOpen: boolean) {
        for (const chart of this.chartWindows) {
            chart.controlsManager.isModalOpened = isOpen
        }
    }

    clearHoveredControls() {
        for (const chart of this.chartWindows) {
            chart.controlsManager.HoverControl = null
        }
    }

    clearSelectedControls() {
        for (const chart of this.chartWindows) {
            chart.controlsManager.deselectAllControls()
        }
    }

    ClearDateCache() {
        for (const chart of this.chartWindows) {
            chart.ClearDateCache()
        }
    }

    public reset(): void {
        this.clearControlsManager()
        this.isChartsFromJSONCreated = false
    }

    public jumpToOpenPrice(order: OrderDataType): void {
        for (const chartWin of this.chartWindows) {
            if (chartWin.SymbolData.symbolInfo.SymbolName === order.symbol) {
                chartWin.ScrollToDate({
                    date: DateUtils.fromUnixTimeMilliseconds(order.openTime),
                    lockTo: ELockToOption.lt_Center
                })
                chartWin.Repaint()
                GlobalTestingManager.TestingManager.stopTesting()
            }
        }
    }

    public ScrollAllChartsToDate(jumpToDate: TDateTime): void {
        if (!GlobalSymbolList.SymbolList.isDateInAvailableRange(jumpToDate)) {
            showErrorToast({
                title: t('testingManager.toasts.dateOutOfRangeTitle'),
                message: t('testingManager.toasts.dateOutOfRangeMessage')
            })
        }
        GlobalSymbolList.SymbolList.SetLoadingPriority(Direction.NEXT)

        for (const chart of this.chartWindows) {
            chart.ScrollToDate({ date: jumpToDate, lockTo: ELockToOption.lt_GetFromSettings })
            chart.setPreparingChartBlockingLayer()
        }
        GlobalChartsController.Instance.RefreshCharts(false, true)
    }

    public updateOneClickTradingInfo() {
        for (const chart of this.chartWindows) {
            chart.updateOneClickTradingInfo()
        }
    }
}

export default GlobalChartsController
