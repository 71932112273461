import { Icon } from '@fto/icons'
import { Flex, Modal, Typography, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { suspendSubscription } from '@root/utils/api'
import { FC, useMemo, useState } from 'react'
import styles from './components/modals.module.scss'
import { SurveyOption } from './components/SurveyOption'
import { LOOKUP } from '@root/store/auth/auth.actions'
import store from '@root/store'
import { useTranslation } from 'react-i18next'

type SurveyModalProps = {
    subscriptionId: number
}

export const SurveyModal: FC<SurveyModalProps> = ({ subscriptionId }) => {
    const { t } = useTranslation()

    const [reasonId, setReasonId] = useState<number | null>(null)
    const REFUND_OPTIONS = useMemo(
        () => [
            { id: 1, text: t('profile.modals.suspendReasons.price'), key: 'price' },
            { id: 2, text: t('profile.modals.suspendReasons.noNeed'), key: 'noNeed' },
            { id: 3, text: t('profile.modals.suspendReasons.noSatisfaction'), key: 'noSatisfaction' },
            { id: 4, text: t('profile.modals.suspendReasons.switch'), key: 'switch' }
        ],
        []
    )

    const suspend = () => {
        suspendSubscription({ subscriptionId: subscriptionId, cancellationReasonId: reasonId as number }).then(() => {
            store.dispatch(LOOKUP())
        })

        handleCloseModal()
    }

    const handleCloseModal = () => {
        removeModal(MODAL_NAMES.userProfile.survey)
        setReasonId(null)
    }

    const handleOptionChange = (id: number) => {
        setReasonId(id)
    }
    return (
        <Modal name={MODAL_NAMES.userProfile.survey} size='sm' onClose={handleCloseModal}>
            <Modal.Header>
                <Flex direction='column' alignItems='center' gap={24}>
                    <Icon name='question' size={72} />
                    <Typography type='h3-bold'>{t('profile.modals.surveyHeader')}</Typography>
                </Flex>
            </Modal.Header>
            <Modal.Content className={styles.line}>
                <Flex direction='column' gap={24}>
                    <Typography color='gray-900' className={styles.modalText} type='text-regular'>
                        {t('profile.modals.surveyText')}
                    </Typography>
                    <Flex direction='column' gap={8} className={styles.survey}>
                        {REFUND_OPTIONS.map(({ key, id, text }) => (
                            <SurveyOption
                                key={key}
                                checked={reasonId === id}
                                value={key}
                                onChange={() => handleOptionChange(id)}
                                text={text}
                            />
                        ))}
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Controls
                disabled={reasonId === null}
                submitText={t('global.continue')}
                cancelText={t('global.cancel')}
                onClose={handleCloseModal}
                onSubmit={suspend}
            />
        </Modal>
    )
}
