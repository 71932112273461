import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'

type PaintToolLocalizationKeys = {
    [key: string]: string
}
export const PAINT_TOOLS_LOCALIZATION_KEY: PaintToolLocalizationKeys = {
    [PaintToolNames.ptRectangle]: 'rectangle',
    [PaintToolNames.ptEllipse]: 'ellipse',
    [PaintToolNames.ptTriangle]: 'triangle',
    [PaintToolNames.ptVLine]: 'vLine',
    [PaintToolNames.ptHLine]: 'hLine',
    [PaintToolNames.ptTrendLine]: 'trendLine',
    [PaintToolNames.ptRay]: 'ray',
    [PaintToolNames.ptPolyline]: 'polyline',
    [PaintToolNames.ptArrowUp]: 'arrowUp',
    [PaintToolNames.ptArrowDown]: 'arrowDown',
    [PaintToolNames.ptThumbUp]: 'thumbUp',
    [PaintToolNames.ptThumbDown]: 'thumbDown',
    [PaintToolNames.ptCheck]: 'check',
    [PaintToolNames.ptStop]: 'stop',
    [PaintToolNames.ptPriceLabelRight]: 'priceLabelRight',
    [PaintToolNames.ptPriceLabelLeft]: 'priceLabelLeft',
    [PaintToolNames.ptExtPriceLabel]: 'priceLabel',
    [PaintToolNames.ptText]: 'text',
    [PaintToolNames.ptFiboChannel]: 'fiboChannel',
    [PaintToolNames.ptFiboRetracement]: 'fiboRetracement',
    [PaintToolNames.ptFiboExtension]: 'fiboExtension',
    [PaintToolNames.ptFiboTimeZones]: 'fiboTimezones',
    [PaintToolNames.ptFiboArc]: 'fiboArc',
    [PaintToolNames.ptFiboFan]: 'fiboFan',
    [PaintToolNames.ptRegressionChannel]: 'regressionChannel',
    [PaintToolNames.ptMeasure]: 'measure',
    [PaintToolNames.ptAndrewsPitchfork]: 'andrewsPitchfork',
    [PaintToolNames.ptRiskRewardBuy]: 'rrBuy',
    [PaintToolNames.ptRiskRewardSell]: 'rrSell',
    [PaintToolNames.ptRiskReward]: 'rrTool'
}
