import React, { FC, useEffect, useState } from 'react'

import { Button, Checkbox, Flex, Typography } from '@fto/ui'

import { Icon } from '@fto/icons'
import { SymbolItemType } from '@root/types/symbols.types'
import { SymbolsCategoryType } from '@root/mapper/symbols.mapper'
import style from './options-symbol-group.module.scss'
import classNames from 'classnames'

type Props = {
    symbolsGroup: SymbolsCategoryType
    selectedSymbols: string[]
    selectSymbol: (value: SymbolItemType['Symbol']) => void
    isOpened: boolean
}

const CreateProjectModalSymbolsGroup: FC<Props> = ({
    symbolsGroup,
    selectedSymbols,
    selectSymbol,
    isOpened = false
}) => {
    const [isOpen, setIsOpened] = useState(isOpened)

    if (!symbolsGroup.symbols.length) return null

    const select = (symbol: SymbolItemType) => {
        selectSymbol(symbol.Symbol)
    }

    const isInputChecked = (symbol: SymbolItemType) => selectedSymbols?.find((s) => s === symbol.Symbol)

    useEffect(() => {
        setIsOpened(isOpened)
    }, [isOpened])

    return (
        <div className={style.symbolGroup}>
            <Flex
                className={style.symbolGroupHeader}
                alignItems={'center'}
                onClick={() => setIsOpened((prev) => !prev)}
                gap={8}
            >
                <Icon name={isOpen ? 'minus' : 'plus'} size={22} />
                <Typography type='interface-medium'>{symbolsGroup.category}</Typography>
            </Flex>

            <ul className={classNames(style.symbolListGroup, { [style.opened]: isOpen })}>
                {symbolsGroup.symbols.map((symbol) => (
                    <li className={style.symbolCheckboxWrap} key={symbol.Symbol}>
                        <Checkbox
                            id={symbol.Symbol}
                            className={style.symbolCheckbox}
                            checked={!!isInputChecked(symbol)}
                            onChange={() => select(symbol)}
                            key={symbol.Symbol}
                            inputControlVariant={'row-reverse'}
                            // @TODO: some props type problem
                            // @ts-ignore
                            label={<Typography type={'text-light'}>{symbol.Symbol}</Typography>}
                        />
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default CreateProjectModalSymbolsGroup
