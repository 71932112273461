import { chartControlEvent, ChartControl, ChartControlParams } from './ChartControl'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { TOscChart } from '@fto/lib/charting/chart_classes/OscChartUnit'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { IGPFont, TGPFontFamily, TGpFontStyle } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import GlobalImageManager from '@fto/lib/globals/GlobalImageManager'
import { addContextMenu, removeContextMenu } from '@fto/ui'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'
import ProjectStore from '@fto/lib/store/projectStore'
import { TimeframeUtils } from '@fto/lib/ft_types/common/TimeframeUtils'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class ChangeSymbolButton extends ChartControl {
    private symbolRect!: TRect
    private chartWindow: TChartWindow
    private canvas!: TGdiPlusCanvas
    private timeFrameControl!: ChartControl

    private image: HTMLImageElement | null = null
    private borderRadius = 4
    private padding = 4
    private x = 5
    private y = 5
    private symbolControlColor = '#ffffff'
    private timeFrameControlColor = '#ffffff'
    private font = new IGPFont(new TGPFontFamily('Arial'), 14, [TGpFontStyle.FontStyleRegular])

    constructor(
        chartWindow: TChartWindow,
        chartControlParams: ChartControlParams,
        image: HTMLImageElement | null = null
    ) {
        super(chartControlParams)
        this.chartWindow = chartWindow
        this.image = image

        const chartControlParamsImage = chartControlParams.clone()
        const chartControlParamsImageRect = chartControlParamsImage.getLocation()
        chartControlParamsImageRect.Right = chartControlParamsImageRect.Left + 24
        chartControlParamsImageRect.Bottom = chartControlParamsImageRect.Top + 24
        chartControlParamsImage.setLocation(chartControlParamsImageRect)

        this.timeFrameControl = new ChartControl(chartControlParamsImage)
        this.timeFrameControl.attachObserver(chartWindow)
    }

    private get timeFrame(): number {
        return this.chartWindow.getCurrentTimeframe()
    }

    private get symbolName(): string {
        return this.chartWindow.SelectedSymbolName
    }

    public draw(canvas: TGdiPlusCanvas): void {
        this.canvas = canvas
        this.drawSymbolButton(canvas)
        this.drawTimeFrameButton(canvas)
        this.updateSelfLocation()
    }

    private drawTimeFrameButton(canvas: TGdiPlusCanvas): void {
        const timeFrameRect = canvas.drawButton(
            TimeframeUtils.GetShortTimeframeName(this.timeFrame),
            this.padding,
            this.borderRadius,
            this.symbolRect.Right + 4,
            this.symbolRect.Top,
            this.timeFrameControlColor,
            this.font,
            GlobalImageManager.Instance.chevronDownBold!.width + this.padding
        )

        if (this.image) {
            canvas.graphics.Context.drawImage(
                this.image,
                timeFrameRect.Left + timeFrameRect.Width - this.image.width - this.padding,
                timeFrameRect.Top + timeFrameRect.Height / 2 - 3,
                this.image.width,
                this.image.height
            )
            this.timeFrameControl.setLocation(timeFrameRect)
        }
    }

    private drawSymbolButton(canvas: TGdiPlusCanvas): void {
        const symbolRect = canvas.drawButton(
            this.symbolName,
            this.padding,
            this.borderRadius,
            this.x,
            this.y,
            this.symbolControlColor,
            this.font
        )

        this.setLocation(symbolRect)
        this.symbolRect = symbolRect
    }

    private updateSelfLocation(): void {
        let newLocation = new TRect(
            this.symbolRect.Left,
            this.symbolRect.Top,
            this.x + this.symbolRect.Width + this.timeFrameControl.getWidth(),
            this.y + this.symbolRect.Height
        )
        if(newLocation.Width !== this.getWidth()){
            this.setLocation(newLocation)
            let oneclickTrading = this.chartWindow.getChartWindowLayers().getOneClickTradingControl()
            if(oneclickTrading && oneclickTrading.IsVisible()){
                oneclickTrading.setStartLeftCoordinate(newLocation.Right + 10)
            }
        }

    }

    onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        const x = event.clientX
        const y = event.clientY

        if (!this.symbolRect || sender instanceof TOscChart) {
            return null
        }

        if (this.symbolRect.PtInRect_numbers(x, y)) {
            this.notify(chartControlEvent.SYMBOL_CHANGE_MODAL)
            return this
        }

        if ((this.timeFrameControl as ChangeSymbolButton).isPointInside(x, y)) {
            const { data, updateData } = ProjectStore

            if (data.isTimeframeDropdownOpen) {
                removeContextMenu(CONTEXT_MENU_NAMES.timeframeChange)
                updateData({ ...data, isTimeframeDropdownOpen: false })

                return this
            }

            const { x: relativeX, y: relativeY } = this.chartWindow.MainChart.getBoundingClientRect()
            this.notify(chartControlEvent.BUTTON_PRESSED)
            addContextMenu(CONTEXT_MENU_NAMES.timeframeChange, {
                anchorX: relativeX + this.padding * 2 + this.symbolRect.Width,
                anchorY: relativeY + this.padding + this.symbolRect.Height,
                additionalProps: { chartWindow: this.chartWindow }
            })
            updateData({ ...data, isTimeframeDropdownOpen: true })
            return this
        }

        return null
    }

    onMouseMove(event: MouseEvent, sender: TChart): ChartControl | null {
        // //TODO: is it ok to return this as a type???
        // const localCoordinatesCanvas = this.chartWindow.MainChart.MouseToLocal(event)

        const x = event.clientX
        const y = event.clientY

        if (!this.symbolRect || sender instanceof TOscChart) {
            return null
        }

        if (this.isPointInside(x, y)) {
            this.symbolControlColor = '#e9f5f9'
            return this
        } else {
            this.symbolControlColor = '#ffffff'
        }

        if ((this.timeFrameControl as ChangeSymbolButton).isPointInside(x, y)) {
            this.timeFrameControlColor = '#e9f5f9'
            return this
        } else {
            this.timeFrameControlColor = '#ffffff'
        }

        return null
    }

    onMouseLeave(event: MouseEvent): ChartControl | null {
        this.symbolControlColor = '#ffffff'
        this.timeFrameControlColor = '#ffffff'

        return null
    }
}
