import { FiboPaintToolJSON } from '@fto/lib/ProjectAdapter/Types'
import { DelphiColors, TPenStyle } from '../../../delphi_compatibility/DelphiBasicTypes'
import { ColorHelperFunctions } from '../../../drawing_interface/ColorHelperFunctions'
import { LevelActiveType, LevelType, TLevelData, TLevelsList } from '../../../drawing_interface/GraphicObjects'
import { TLineStyle } from '../../../drawing_interface/vclCanvas'
import { Common } from '../../../ft_types/common/Common'
import { StrsConv } from '../../../ft_types/common/StrsConv'
import { TBasicPaintTool } from '../BasicPaintTool'
import { ObjProp } from '../PaintToolsAuxiliaryClasses'
import { TChart } from '../../chart_classes/BasicChart'

export abstract class TFiboPaintTool extends TBasicPaintTool {
    fLevels!: TLevelsList
    fLevelNo!: number

    constructor(aChart: TChart, aToolName: string) {
        super(aChart, aToolName)
        this.fLevels = new TLevelsList()
        this.fLevelNo = 0
    }

    clone(): TFiboPaintTool {
        const cloned = new (this.constructor as any)(this.fChart, this.ShortName) as TFiboPaintTool

        const baseClone = super.clone()
        Object.assign(cloned, baseClone)

        cloned.fLevels = this.fLevels.clone()
        cloned.fLevelNo = this.fLevelNo
        return cloned
    }

    public toJson(): FiboPaintToolJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            Levels: this.fLevels.ExportToStr(5),
            LevelNo: this.fLevelNo
        }
    }

    public fromJSON(json: FiboPaintToolJSON) {
        super.fromJSON(json)

        this.fLevels.Clear()
        this.fLevels.ImportFromStr(json.Levels)
        this.fLevelNo = json.LevelNo
    }

    public SetLevels(levels: number[], decimals: number): void {
        this.fLevels.Clear()

        // Create a single TLineStyle object to be used for all levels.
        const lineStyle = new TLineStyle(ColorHelperFunctions.MakeColor(DelphiColors.clGreen), TPenStyle.psSolid, 1)

        // Use forEach for more idiomatic iteration over the array elements.
        levels.forEach((levelValue) => {
            const levelText = StrsConv.StrDouble(levelValue, decimals)
            const levelData = TLevelData.Create_TLevelData_Style(
                levelValue,
                levelText,
                lineStyle,
                LevelActiveType.active
            )
            this.fLevels.Add(levelData)
        })
    }

    public updateLevelsFromModal(levels: LevelType[]) {
        this.fLevels.Clear()

        levels.forEach((level: LevelType) => {
            const lineStyle = new TLineStyle(
                ColorHelperFunctions.MakeColor(level.color as string),
                level.style as TPenStyle,
                level.width as number
            )

            const levelText = StrsConv.StrDouble(Number(level.value), 3)
            const levelData = TLevelData.Create_TLevelData_Style(
                Number(level.value),
                String(levelText),
                lineStyle,
                level.isActive ? LevelActiveType.active : LevelActiveType.inactive,
                level.opacity as number
            )
            this.fLevels.Add(levelData)
        })
    }

    public SetProperty(index: number, value: any): void {
        switch (index) {
            case ObjProp.OBJPROP_FIBOLEVELS:
                this.fLevels.Clear()
                for (let i = 0; i < Number(value); i++) {
                    const tLevelData = new TLevelData('0')
                    tLevelData.text = '0.00'
                    tLevelData.style = new TLineStyle(
                        ColorHelperFunctions.MakeColor(DelphiColors.clGreen),
                        TPenStyle.psSolid,
                        1
                    )
                    this.fLevels.Add(tLevelData)
                }
                break

            case ObjProp.OBJPROP_FIBOLEVELN:
                this.fLevelNo =
                    this.fLevels.Count > 0 ? Common.fitNumberIntoRange(Number(value), 0, this.fLevels.Count - 1) : 0
                break

            case ObjProp.OBJPROP_LEVELCOLOR:
                if (this.fLevels.IndexValid(this.fLevelNo)) {
                    this.fLevels[this.fLevelNo].style.color = ColorHelperFunctions.MakeColor(Number(value).toString())
                }
                break

            case ObjProp.OBJPROP_LEVELSTYLE:
                if (this.fLevels.IndexValid(this.fLevelNo)) {
                    this.fLevels[this.fLevelNo].style.style = Number(value) as TPenStyle
                }
                break

            case ObjProp.OBJPROP_LEVELWIDTH:
                if (this.fLevels.IndexValid(this.fLevelNo)) {
                    this.fLevels[this.fLevelNo].style.width = Number(value)
                }
                break

            case ObjProp.OBJPROP_LEVELVALUE:
                if (this.fLevels.IndexValid(this.fLevelNo)) {
                    const levelValue = Number(value)
                    this.fLevels[this.fLevelNo].value = levelValue
                    this.fLevels[this.fLevelNo].text = StrsConv.StrDouble(levelValue, 3)
                }
                break

            default:
                super.SetProperty(index, value)
        }
    }
}
