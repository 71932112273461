import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChartType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import GlobalIndicatorDescriptors from '@fto/lib/globals/GlobalIndicatorDescriptors'
import { IIndicatorOptionsStore, TIndicatorOption } from '@fto/lib/charting/tool_storages/indicators'
import { useCallback, useEffect, useState } from 'react'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import { TOptionType } from '@fto/lib/extension_modules/indicators/api/IndicatorInterfaceUnit'
import { findOption, updateOption } from '../utils'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/DllIndicatorUnit'

type Props = {
    options: any
    indicatorOptions: IIndicatorOptionsStore
    setSelectedTemplate: (value: string) => void
    setIndicatorOptionsWrapper: (cb: any, shouldTemplateReset: boolean) => void
}

const useIndicatorTemplate = ({
    options,
    indicatorOptions,
    setSelectedTemplate,
    setIndicatorOptionsWrapper
}: Props) => {
    const [templates, setTemplates] = useState<string[]>([])

    const updateTemplates = useCallback(() => {
        setTemplates(
            GlobalTemplatesManager.Instance.getIndicatorTemplatesNames(indicatorOptions.indicatorInstance!.ShortName)
        )
    }, [indicatorOptions])

    const applyDefault = useCallback(() => {
        const { indicatorInstance } = indicatorOptions
        if (!indicatorInstance) throw new StrangeError('Indicator instance not found')

        const indicatorDescriptor = GlobalIndicatorDescriptors.BuiltInIndicators.findByName(indicatorInstance.ShortName)
        if (indicatorDescriptor === null)
            throw new StrangeError(`Indicator descriptor not found for ${indicatorInstance.ShortName}`)

        const runtimeIndicator = GlobalIndicatorDescriptors.BuiltInIndicators.GetOrCreateRuntimeIndicator(
            indicatorDescriptor,
            indicatorInstance.SymbolData as TSymbolData,
            indicatorInstance?.Timeframe,
            TChartType.ct_Normal
        )

        runtimeIndicator.Init()
        runtimeIndicator.GetImportantOptions()

        const saveIndicator = indicatorInstance
        runtimeIndicator.ExportData(true)

        setIndicatorOptionsWrapper((prevData: IIndicatorOptionsStore) => {
            return {
                ...prevData,
                indicatorInstance: saveIndicator
            }
        }, true)
    }, [indicatorOptions])

    const saveTemplate = useCallback(
        async (templateName = 'Template 1', isRewrite: boolean = false) => {
            let indicatorInstance = indicatorOptions.indicatorInstance?.ShortName
            if (!indicatorInstance) return

            const template: any = {
                style: {},
                levels: {}
            }

            if (options.levels) {
                const levels = Object.values(options.levels[1].fvalue) || {}
                for (let k in levels) {
                    const level: any = levels[k]
                    template.levels[k] = {
                        id: level.id,
                        checked: level.isActive,
                        color: level.color,
                        opacity: level.opacity,
                        style: level.style,
                        width: level.width,
                        value: level.value,
                        text: level.text
                    }
                }
            }

            for (let k in options.style) {
                const [key, option] = options.style[k]

                if (key === 'indicatorInstance') continue

                switch (parseInt(option.type)) {
                    case TOptionType.ot_Integer:
                    case TOptionType.ot_double:
                        template.style[key] = {
                            alias: option.alias,
                            type: option.type,
                            value: option.fvalue,
                            min: option.LowValue,
                            max: option.HighValue
                        }
                        break

                    case TOptionType.ot_EnumType:
                        template.style[key] = {
                            alias: option.alias,
                            type: option.type,
                            value: option.fvalue,
                            values: option.values.map((value: any) => value)
                        }
                        break

                    case TOptionType.ot_LineStyle:
                        template.style[key] = {
                            alias: option.alias,
                            type: option.type,
                            value: {
                                checked: option.fvalue.isVisible,
                                color: option.fvalue.color,
                                pen: option.fvalue.pen,
                                style: option.fvalue.style,
                                width: option.fvalue.width
                            }
                        }
                        break

                    case TOptionType.ot_String:
                    case TOptionType.ot_Longword:
                        template.style[key] = {
                            alias: option.alias,
                            type: option.type,
                            value: option.fvalue
                        }
                        break

                    case TOptionType.ot_Timeframe:
                    case TOptionType.ot_Currency:
                    case TOptionType.ot_Indicator:
                        template.style[key] = {
                            alias: option.alias,
                            type: option.type,
                            value: option.fvalue
                        }
                        break

                    case TOptionType.ot_Color:
                        template.style[key] = {
                            alias: option.alias,
                            type: option.type,
                            value: {
                                color: option.fvalue
                            }
                        }

                    case TOptionType.ot_DateTime:
                        template.style[key] = {
                            alias: option.alias,
                            type: option.type,
                            value: option.fvalue
                        }
                        break
                }
            }

            await GlobalTemplatesManager.Instance.addIndicatorTemplate(
                templateName,
                indicatorOptions.indicatorInstance!.ShortName,
                JSON.stringify(template),
                isRewrite
            )

            updateTemplates()
            setSelectedTemplate(templateName)
        },
        [indicatorOptions, options, updateTemplates]
    )

    const deleteTemplate = useCallback(
        async (templateName: string) => {
            await GlobalTemplatesManager.Instance.removeIndicatorTemplate(
                indicatorOptions.indicatorInstance!.ShortName,
                templateName
            )
            updateTemplates()
        },
        [indicatorOptions, updateTemplates]
    )

    const selectTemplate = useCallback(
        (templateName: string) => {
            const template = GlobalTemplatesManager.Instance.getIndicatorTemplate(
                indicatorOptions.indicatorInstance!.ShortName,
                templateName
            )
            if (!template) throw new StrangeError('Template not found')

            const parsedTemplate = JSON.parse(template.templateJSON)

            if (!parsedTemplate) throw new StrangeError('Template format is incorrect')

            setIndicatorOptionsWrapper((prevData: IIndicatorOptionsStore) => {
                let newOptions = { ...prevData }

                for (let k in Object.entries(prevData)) {
                    const [key, value] = Object.entries(prevData)[k]

                    if (key === 'indicatorInstance') continue

                    let newOption = findOption(parsedTemplate.style, (value as TIndicatorOption).alias)

                    if (!newOption) continue

                    newOptions = {
                        ...newOptions,
                        [key]: updateOption(value as TIndicatorOption, newOption)
                    }
                }

                for (let k in Object.entries(newOptions)) {
                    const [key, value] = Object.entries(prevData)[k]

                    if (value instanceof TRuntimeIndicator) continue
                    if (value.alias !== 'Levels') continue

                    const levels = Object.values(parsedTemplate.levels).reduce((acc: any, item: any, index: number) => {
                        acc.push({
                            id: item.id,
                            isActive: item.checked,
                            opacity: item.opacity,
                            color: item.color,
                            style: item.style,
                            width: item.width,
                            value: item.value,
                            text: item.text
                        })
                        return acc
                    }, [])

                    newOptions = {
                        ...newOptions,
                        [key]: {
                            ...value,
                            fvalue: levels
                        }
                    }
                }

                return newOptions
            }, false)

            setSelectedTemplate(templateName)
        },
        [indicatorOptions]
    )

    useEffect(() => {
        updateTemplates()
    }, [])

    return {
        templates,
        applyDefault,
        saveTemplate,
        deleteTemplate,
        selectTemplate
    }
}

export default useIndicatorTemplate
