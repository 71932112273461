import { FC } from 'react'

import { MODAL_NAMES } from '@root/constants/modalNames'
import { Button, Flex, Typography, addModal } from '@fto/ui'

import styles from '../profile.module.scss'
import { useTranslation } from 'react-i18next'

type AccountInfoProps = {
    name?: string
    email?: string
    customAuth?: boolean
}

export const AccountInfo: FC<AccountInfoProps> = ({ name, email, customAuth }) => {
    const { t } = useTranslation()

    const handleEmailChange = () => {
        addModal(MODAL_NAMES.userProfile.changeEmail)
    }

    const handlePasswordChange = () => {
        addModal(MODAL_NAMES.userProfile.changePassword)
    }
    if (!name && !email) return <></>
    return (
        <Flex direction='column' gap={24} className={styles.accountInfo}>
            <Flex className={styles.title}>
                <Typography type='h3-bold'>{t('profile.settings')}</Typography>
            </Flex>
            <Flex direction='column' gap={16}>
                {name && (
                    <Flex direction='column' gap={4}>
                        <Typography type='interface-semibold'>{t('profile.name')}</Typography>
                        <Typography type='interface-regular'>{name}</Typography>
                    </Flex>
                )}
                {email && (
                    <Flex alignItems='center' justifyContent='space-between' className={styles.infoContent}>
                        <Flex direction='column' gap={4}>
                            <Typography type='interface-semibold'>{t('profile.email')}</Typography>
                            <Typography type='interface-regular'>{email}</Typography>
                        </Flex>
                        <Button
                            classNames={{ root: styles.infoButton }}
                            label={t('profile.changeEmail')}
                            type='tetriatry-gray'
                            onClick={handleEmailChange}
                        />
                    </Flex>
                )}
                {!customAuth && (
                    <Flex alignItems='center' justifyContent='space-between' className={styles.infoContent}>
                        <Typography type='interface-semibold'>{t('profile.password')}</Typography>
                        <Button
                            classNames={{ root: styles.infoButton }}
                            label={t('profile.changePassword')}
                            type='tetriatry-gray'
                            onClick={handlePasswordChange}
                        />
                    </Flex>
                )}
            </Flex>
        </Flex>
    )
}
