import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { Flex, InputField, Typography } from '@fto/ui'
import { InputSettingType, OptionSettingRef } from '../../../types'

import styles from './index.module.scss'

import { t } from 'i18next'
import { getNumberValue, setNumberValue } from '../../../utils'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

const InputSetting = forwardRef<OptionSettingRef, InputSettingType>(
    ({ tab, chart, option, maxValue, minValue, applyToAll, onDifferentSettingsUpdate }, ref) => {
        const [value, setValue] = useState<number>(getNumberValue(chart, option.valueConfigType, option.valueKey))

        const setNewValue = useCallback(
            (value: number, redraw = true, shouldUpdateConfig = true) => {
                setValue(value)

                shouldUpdateConfig && setNumberValue(chart, option.valueConfigType, option.valueKey, value, applyToAll)

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [chart, option, applyToAll]
        )

        const changeValue = useCallback(
            (value: number) => {
                onDifferentSettingsUpdate(tab, value, value, option.valueKey)
                setNewValue(value)
            },
            [tab, option, setNewValue, onDifferentSettingsUpdate]
        )

        const loadOptionSetting = useCallback(
            (data: any, shouldUpdateConfig = true) => {
                setNewValue(data.value, false, shouldUpdateConfig)
            },
            [setNewValue]
        )

        useImperativeHandle(ref, () => ({ name: option.key, loadOptionSetting }), [option.key, loadOptionSetting])

        return (
            <Flex gap={4} alignItems='center' justifyContent='space-between'>
                <Typography type='text-regular'>{t(option.name)}</Typography>
                <Flex alignItems='center'>
                    <InputField
                        className={styles.input}
                        step={1}
                        value={value}
                        onChange={(value) => changeValue(Number(value))}
                        type={'number'}
                        max={maxValue}
                        min={minValue}
                    />
                </Flex>
            </Flex>
        )
    }
)

export default InputSetting
