import { FC, useCallback, useState } from 'react'
import { OptionType } from '../../types'
import { IconButton } from '@fto/icons'
import { Tooltip } from '@fto/ui'

type Props = {
    value: boolean
    action: () => void
    option: OptionType
}

const ToolToggle: FC<Props> = ({ value, action, option }) => {
    const [active, setActive] = useState(value)

    const handleToggle = useCallback(() => {
        setActive((active) => !active)
        action()
    }, [active, value])

    return (
        <Tooltip content={option.name} placement='right' key={option.toolKey}>
            <IconButton
                name={option.icon!}
                onClick={handleToggle}
                color={active ? 'var(--color-primary-500)' : 'var(--color-gray-1000)'}
            />
        </Tooltip>
    )
}

export default ToolToggle
