import { DateUtils, TDateTime } from '../../../delphi_compatibility/DateUtils'
import IFMBarsArray from '../../../ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import { TChunkStatus } from '../../../ft_types/data/chunks/ChunkEnums'
import { TGridLine, TGridLinePeriod } from '../ChartingEnums'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DateTransitionInfo, TBarChunk } from '@fto/lib/ft_types/data/chunks/BarChunk'
import { ChunkBuilder } from '@fto/lib/ft_types/data/BarBuilding/chunk_building/ChunkBuilder'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'

export class TGridLineList {
    private fTimeframe: number
    private fBars: IFMBarsArray
    private fVisibleDateStart: number
    private fVisibleDateEnd: number
    private static readonly MIN_DISTANCE_MONTH = 125
    private static readonly MIN_DISTANCE_YEAR = 125
    private static readonly MIN_DISTANCE_DAY = 125
    private fLines: TGridLine[]
    private fPosition: number
    private fBarSpace: number
    private _isYearVisibleOnChart = false
    private _isMonthVisibleOnChart = false

    constructor(
        aBars: IFMBarsArray,
        aVisibleDateStart: TDateTime,
        aVisibleDateEnd: TDateTime,
        aPosition: number,
        aBarSpace: number
    ) {
        if (aVisibleDateEnd < aVisibleDateStart || aVisibleDateStart <= 0 || aVisibleDateEnd <= 0) {
            throw new StrangeError('Invalid date range for grid lines')
        }

        this.fTimeframe = aBars.DataDescriptor.timeframe
        this.fBars = aBars
        this.fVisibleDateStart = aVisibleDateStart
        this.fVisibleDateEnd = aVisibleDateEnd
        this.fPosition = aPosition
        this.fBarSpace = aBarSpace
        this.fLines = []

        this.CalculateGridLines()
    }

    public get Lines(): TGridLine[] {
        return this.fLines
    }

    private GetRelativeX(index: number): number {
        return Math.round((index - this.fPosition) * this.fBarSpace)
    }

    private IsEnoughSpace(
        line1: TGridLine | null,
        line2: TGridLine | null,
        pt: TGridLinePeriod,
        indexToCreate?: number
    ): boolean {
        let result = false
        if (indexToCreate) {
            let minDistance = TGridLineList.MIN_DISTANCE_MONTH
            if (pt === TGridLinePeriod.glp_Year) {
                minDistance = TGridLineList.MIN_DISTANCE_YEAR
            } else if (pt === TGridLinePeriod.glp_Day) {
                minDistance = TGridLineList.MIN_DISTANCE_DAY
            }
            const newLineLeft = this.GetRelativeX(indexToCreate) - minDistance / 2
            const newLineRight = this.GetRelativeX(indexToCreate) + minDistance / 2
            const line1Right: number | undefined = line1
                ? this.GetRelativeX(line1.globalIndex) + minDistance / 2
                : undefined
            const line2Left: number | undefined = line2
                ? this.GetRelativeX(line2.globalIndex) - minDistance / 2
                : undefined
            if (line1Right && line2Left) {
                result = newLineLeft >= line1Right && newLineRight <= line2Left
            } else if (line1Right && !line2Left) {
                result = newLineLeft >= line1Right
            } else if (!line1Right && line2Left) {
                result = newLineRight <= line2Left
            }
        }
        return result
    }

    private getNeighbouringLines(index: number): [TGridLine | null, TGridLine | null] {
        if (index < 0) {
            throw new StrangeError('Invalid index for getNeighbouringLines')
        }
        let leftLine: TGridLine | null = null
        let rightLine: TGridLine | null = null
        this.fLines.forEach((line, i) => {
            if (line.globalIndex <= index) {
                if (leftLine && leftLine.globalIndex < line.globalIndex) {
                    leftLine = line
                }
                if (!leftLine) {
                    leftLine = line
                }
            }
            if (line.globalIndex >= index) {
                if (rightLine && rightLine.globalIndex > line.globalIndex) {
                    rightLine = line
                }
                if (!rightLine) {
                    rightLine = line
                }
            }
        })

        return [leftLine, rightLine]
    }

    private addLine(type: TGridLinePeriod, transitionItem: DateTransitionInfo) {
        const result = new TGridLine()
        result.dt = transitionItem.dt
        result.globalIndex = transitionItem.index
        result.visible = true
        result.period = type
        result.x = this.GetRelativeX(result.globalIndex)
        result.bold = false
        if (!DateUtils.InRange(result.dt, this.fVisibleDateStart, this.fVisibleDateEnd)) {
            result.visible = false
        } else {
            if (type === TGridLinePeriod.glp_Year) {
                this._isYearVisibleOnChart = true
            } else if (type === TGridLinePeriod.glp_Month) {
                this._isMonthVisibleOnChart = true
            }
        }
        this.fLines.push(result)
    }

    private CalculateGridLines() {
        const visibleChunks = this.fBars.GetChunksForRangeDates(
            GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(this.fVisibleDateStart),
            GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(this.fVisibleDateEnd)
        )

        this.processTransitionItems(
            visibleChunks,
            (chunk: TBarChunk) => chunk.getYearTransitionItems(),
            TGridLinePeriod.glp_Year
        )
        this.processTransitionItems(
            visibleChunks,
            (chunk: TBarChunk) => chunk.getMonthTransitionItems(),
            TGridLinePeriod.glp_Month
        )
        this.processTransitionItems(
            visibleChunks,
            (chunk: TBarChunk) => chunk.getDayTransitionItems(),
            TGridLinePeriod.glp_Day
        )
        this.processTransitionItems(
            visibleChunks,
            (chunk: TBarChunk) => chunk.getTimeTransitionItems(),
            TGridLinePeriod.glp_Minute
        )
        this.SetGridLinesText()
    }

    private processTransitionItems(
        visibleChunks: TBarChunk[],
        getTransitionItemsMethod: (chunk: TBarChunk) => DateTransitionInfo[],
        periodType: TGridLinePeriod
    ) {
        for (const chunk of visibleChunks) {
            if (chunk.Status !== TChunkStatus.cs_Loaded) {
                ChunkBuilder.Instance.BuildChunk(chunk)
                continue
            }
            const transitionItems = getTransitionItemsMethod(chunk)
            const toDraw = transitionItems.length
            for (let i = 0; i < toDraw; i++) {
                const neighbourLines = this.getNeighbouringLines(transitionItems[i].index)
                if (!neighbourLines[0] && !neighbourLines[1]) {
                    this.addLine(periodType, transitionItems[i])
                } else {
                    if (
                        this.IsEnoughSpace(neighbourLines[0], neighbourLines[1], periodType, transitionItems[i].index)
                    ) {
                        this.addLine(periodType, transitionItems[i])
                    }
                }
            }
        }
    }

    private SetGridLinesText() {
        for (let i = 0; i < this.fLines.length; i++) {
            const line = this.fLines[i]
            if (line.visible) {
                line.text = this.GetTextForTheLine(line)
            }
        }
    }

    private GetTextForTheLine(line: TGridLine): string {
        switch (line.period) {
            case TGridLinePeriod.glp_Year: {
                line.bold = true
                return DateUtils.YearOf(line.dt).toString()
            }
            case TGridLinePeriod.glp_Month: {
                if (!this._isYearVisibleOnChart) {
                    line.bold = true
                }
                return DateUtils.FormatDateTime('mmm', line.dt)
            }
            case TGridLinePeriod.glp_Day: {
                if (!this._isMonthVisibleOnChart && !this._isYearVisibleOnChart) {
                    line.bold = true
                }
                return DateUtils.FormatDateTime('d', line.dt)
            }
            case TGridLinePeriod.glp_Minute: {
                return DateUtils.FormatDateTime('HH:nn', line.dt)
            }
            default: {
                throw new StrangeError('Invalid period - GetTextForTheLine')
            }
        }
    }
}
