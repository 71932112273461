import { ChartInfoType } from '@fto/chart_components/types/chartTypes'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import globalChartsStore from '@fto/lib/store/globalChartsStore'
import { removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { fireMixpanelEvent } from '@root/utils/api'

export function selectSymbol(
    symbol: string,
    modalType: 'symbolChange' | 'addChart',
    chartWindow: TChartWindow | undefined,
    addSymbolToProject: (symbol: string) => void,
    setSelectedChartInfo: (chartInfo: any) => void
) {
    if (modalType === 'symbolChange' && chartWindow) {
        fireMixpanelEvent('currency_changed', {
            from: globalChartsStore.data.activeChartSymbol,
            to: symbol
        })

        GlobalChartsController.Instance.setNewSymbol(symbol, chartWindow)
    } else {
        CreateNewChart(symbol, setSelectedChartInfo)
    }

    addSymbolToProject(symbol)

    removeModal(MODAL_NAMES.chart.changeSymbol)
}

function CreateNewChart(symbolName: string, setSelectedChartInfo: (chartInfo: any) => void) {
    let chartsLength = GlobalChartsController.Instance.getAllCharts().length

    const getLayout = () => {
        switch (chartsLength + 1) {
            case 1:
                return 'one-chart'
            case 2:
                return 'two-charts-vertically'
            case 3:
                return 'three-charts-vertically'
            case 4:
                return 'four-charts'
        }
    }

    const chartInfo = {
        count: chartsLength + 1,
        layout: getLayout(),
        symbol: symbolName
    }

    setSelectedChartInfo(chartInfo)
    GlobalProjectJSONAdapter.Instance.setLayoutSettings(chartInfo as ChartInfoType)

    fireMixpanelEvent('multichart_added', { layout: chartInfo.layout, total_charts: chartInfo.count })
}
