import { makeAutoObservable } from 'mobx'

import { News } from '@fto/lib/News/News'

import { ParamsType } from './types'
import { initialData } from './constants/initialData'
import { GlobalNewsController } from '@fto/lib/News/GlobalNewsController'

class NewsStore {
    params: ParamsType

    constructor() {
        makeAutoObservable(this)

        this.params = initialData

        this.updateParams = this.updateParams.bind(this)
        this.resetStore = this.resetStore.bind(this)
        this.jumpToNew = this.jumpToNew.bind(this)
        this.updateFilters = this.updateFilters.bind(this)
        this.getFiltersToShow = this.getFiltersToShow.bind(this)
        this.getSelectedCountries = this.getSelectedCountries.bind(this)
    }

    updateParams(updater: ((prevSettings: ParamsType) => ParamsType) | ParamsType) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.params = updater(this.params)
        } else {
            // If updater is a value, directly apply the updates
            this.params = updater
        }
    }

    resetStore() {
        this.updateParams(initialData)
    }

    jumpToNew(entity: News): void {
        GlobalNewsController.Instance.jumpToNewsItem(entity)
    }

    updateFilters(forced = false) {
        GlobalNewsController.Instance.setFilterByImportance(this.params.filters.priority)
        GlobalNewsController.Instance.setFilterByCountries(this.params.filters.selectedCountries)
        GlobalNewsController.Instance.setFilterByInterval(this.params.filters.interval)

        GlobalNewsController.Instance.throttledFilterNews(forced)
    }

    getFiltersToShow() {
        return {
            priority: this.params.filters.priority,
            interval: this.params.filters.interval
        }
    }

    setFilters(updatedFilters: ParamsType['filters'], forced = false) {
        this.updateParams((prev) => ({
            ...prev,
            filters: updatedFilters
        }))

        this.updateFilters(forced)
    }

    getSelectedCountries() {
        return this.params.filters.selectedCountries
    }

    get allFilters() {
        return this.params.filters
    }
}

export default new NewsStore()
