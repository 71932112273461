import GlobalImageManager from '@fto/lib/globals/GlobalImageManager'
import { ManagerLayers } from '@fto/lib/charting/auxiliary_classes_charting/Layers/ManagerLayers'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { BarInfoControl } from '@fto/chart_components/BarInfo'
import { OneClickTrading } from '@fto/chart_components/OneClickTrading'
import { IndicatorCollapseButton } from '@fto/chart_components/IndicatorCollapseButton'
import { ImageControl } from '@fto/chart_components/ImageControl'
import { GroupChartControls } from '@fto/chart_components/GroupChartControls'
import { ChangeSymbolButton } from '@fto/chart_components/ChangeSymbolButton'
import { ChartControl, ChartControlId, ChartControlParams, LocationParams } from '@fto/chart_components/ChartControl'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/DllIndicatorUnit'
import { TMkFontStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { TFontStyle } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { IndicatorConfigurationControl } from '@fto/chart_components/IndicatorConfigurationControl'
import { TDataAvailability } from '@fto/lib/ft_types/data/data_downloading/DownloadRelatedEnums'
import { TChartOptions } from '@fto/lib/charting/ChartBasicClasses'
import { TBarRecord } from '@fto/lib/ft_types/data/DataClasses/TBarRecord'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DebugLabelControl } from '@fto/chart_components/DebugLabel'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { EBlockingLayerState, EDirections } from './ChartLayersEnums'
import { LayerBlockingChart } from './LayerBlockingChart'
import { CanvasLayer } from './Layers'
import { t } from 'i18next'
import { CrosshairControl } from '@fto/chart_components/crosshair/CrosshairControl'

export class ChartWindowLayers {
    private readonly chartWindow: TChartWindow
    private _managerLayers: ManagerLayers | undefined = undefined

    public get isManagerLayersInitialized(): boolean {
        return !!this._managerLayers
    }

    private layerChartControls!: CanvasLayer

    private _changeSymbolControl: ChangeSymbolButton | null = null
    private _oneClickTradingControl: OneClickTrading | null = null

    private get changeSymbolControl(): ChangeSymbolButton {
        if (!this._changeSymbolControl) {
            throw new StrangeError('ChangeSymbolButton is not initialized')
        }
        return this._changeSymbolControl
    }

    private barInfoLabel: BarInfoControl | null = null
    private indicatorCollapseControl!: IndicatorCollapseButton
    private buttonMoveToLastBar: ImageControl | null = null

    private groupsChartControl: GroupChartControls[] = []
    private groupChartControlsTopLeftCorner!: GroupChartControls
    private groupChartControlsBottomRightCorner!: GroupChartControls

    private crosshairControl: CrosshairControl | null = null

    private _debugLabel: DebugLabelControl | null = null

    private _isReady = false

    constructor(chartWindow: TChartWindow) {
        this.chartWindow = chartWindow
    }

    public getManagerLayers(): ManagerLayers {
        if (!this._managerLayers) {
            throw new StrangeError('ManagerLayers is not initialized')
        }
        return this._managerLayers
    }

    public isReady(): boolean {
        return this._isReady
    }

    public init(): void {
        this._managerLayers = new ManagerLayers(this.chartWindow.MainChart.HTML_Canvas, this.chartWindow)
        const layer = this._managerLayers.addLayerSizeFull()

        layer.isActive = false

        this.layerChartControls = layer

        // create top left corner group
        this.createGroupControlsTopLeft(layer)

        // create bottom right corner group
        this.createGroupControlsBottomRight(layer)

        // create symbol change button
        this.createChangeSymbolButton(layer)

        // create one click trading button
        this.createOneClickTradingButton(layer)

        // create bar info label
        this.createBarInfoLabel(layer)

        // create Collapse Button
        this.createCollapseIndicatorsButton(layer)

        // create Move to last bar button
        this.createButtonMoveToLastBar(layer)

        if (DebugUtils.DebugMode) {
            this.createDebugLabel(layer)
        }

        this.createCrosshair(layer)

        this._isReady = true

        if (this.chartWindow.isDataLoaded) {
            this.changeSymbolControl.draw(this.layerChartControls.tGdiPlusCanvas)
            this.updateBarInfoLabel()
        } else {
            this.chartWindow
                .getChartWindowLayers()
                .manageVisibilityControlsOnLayerByData(TDataAvailability.da_NoMapYet, this.chartWindow.ChartOptions)
        }
    }

    public updateBarInfoLabel(isNeedCheckMouseInside = true): void {
        if (!this.layerChartControls.isActive) {
            return
        }
        let barRecord: TBarRecord | null = null

        if (isNeedCheckMouseInside && this.chartWindow.MainChart.IsMouseInside()) {
            barRecord = this.chartWindow.MainChart.GetBarUnderMouse()
        }

        if (!barRecord) {
            barRecord = this.chartWindow.MainChart.GetLastBarInTesting()
        }

        if (barRecord && this.barInfoLabel) {
            this.barInfoLabel.setBarInfo(barRecord, this.chartWindow.SymbolData.symbolInfo.decimals)
        }
        if (this._managerLayers && this.barInfoLabel && this.layerChartControls) {
            this.barInfoLabel.draw(this.layerChartControls.tGdiPlusCanvas)
        }

        if (DebugUtils.DebugMode && this._debugLabel) {
            const debugBar = this.chartWindow.MainChart.GetBarFromX(0)
            if (debugBar) {
                this._debugLabel.setBarInfo(debugBar)
            } else {
                this._debugLabel.setBarInfo(TBarRecord.GetEmptyBar())
            }
            this._debugLabel.draw(this.layerChartControls.tGdiPlusCanvas)
        }
    }

    public showButtonMoveToLastBar():void {
        if (this.buttonMoveToLastBar && !this.buttonMoveToLastBar.IsVisible()) {
            this.buttonMoveToLastBar.Show()
            this.groupChartControlsBottomRightCorner.repositionControls()
            this._managerLayers?.reDrawAll()
        }
    }

    public hideButtonMoveToLastBar(): void {
        if (this.buttonMoveToLastBar && this.buttonMoveToLastBar.IsVisible()) {
            this.buttonMoveToLastBar?.Hide()
            this.groupChartControlsBottomRightCorner.repositionControls()
            this._managerLayers?.reDrawAll()
        }
    }

    public createChangeSymbolButton(layer: CanvasLayer): void {
        const location = new LocationParams(5, 5, 100, 24)
        const chartControlParams = new ChartControlParams(layer, location)
        this._changeSymbolControl = new ChangeSymbolButton(
            this.chartWindow,
            chartControlParams,
            GlobalImageManager.Instance.chevronDownBold
        )
        // this.groupChartControlsTopLeftCorner.addControl(this.changeSymbolControl)
        layer.addControl(this.changeSymbolControl)
    }

    public getCrosshairControl(): CrosshairControl {
        if (!this.crosshairControl) {
            throw new StrangeError('CrosshairControl is not initialized')
        }
        return this.crosshairControl
    }
    private createCrosshair(layer: CanvasLayer): void {
        const location = new LocationParams(0, 0, 0, 0)
        const chartControlParams = new ChartControlParams(layer, location)
        const crosshair = new CrosshairControl(chartControlParams)
        this.groupChartControlsTopLeftCorner.addControl(crosshair)
        this.crosshairControl = crosshair
    }

    public createOneClickTradingButton(layer: CanvasLayer): void {
        const location = new LocationParams(130, 5, 202, 24)
        const chartControlParams = new ChartControlParams(layer, location)
        this._oneClickTradingControl = new OneClickTrading(
            chartControlParams,
            this.chartWindow.ChartOptions.OneClickTradingMode
        )
        layer.addControl(this._oneClickTradingControl)
        if(this.chartWindow.isDataLoaded){
            this._oneClickTradingControl.updateLabels(this.chartWindow.SymbolData)
        }
    }

    private createBarInfoLabel(layer: CanvasLayer): void {
        const location = new LocationParams(0, 0, 500, 24)
        const chartControlParamsBarInfo = new ChartControlParams(layer, location, ChartControlId.BAR_INFO)
        this.barInfoLabel = new BarInfoControl(chartControlParamsBarInfo, null) //pass null here because the bars may not be loaded yet
        this.groupChartControlsTopLeftCorner.addControl(this.barInfoLabel)
    }

    public createDebugLabel(layer: CanvasLayer): void {
        const location = new LocationParams(5, 70, 300, 24)
        const chartControlParamsBarInfo = new ChartControlParams(layer, location, ChartControlId.BAR_INFO)
        this._debugLabel = new DebugLabelControl(chartControlParamsBarInfo, null, this.chartWindow) //pass null here because the bars may not be loaded yet
        this.groupChartControlsTopLeftCorner.addControl(this._debugLabel)
    }

    private createCollapseIndicatorsButton(layer: CanvasLayer): void {
        if (GlobalImageManager.Instance.chevronUpBold && GlobalImageManager.Instance.chevronDownBold) {
            const chartControlParamsButton = new ChartControlParams(
                layer,
                new LocationParams(this.groupChartControlsTopLeftCorner.getLocation().Left, 5, 24, 24),
                ChartControlId.INDICATOR_COLLAPSE_CONTROL
            )
            this.indicatorCollapseControl = new IndicatorCollapseButton(
                chartControlParamsButton,
                GlobalImageManager.Instance.chevronUpBold,
                GlobalImageManager.Instance.chevronDownBold
            )
            layer.addControl(this.indicatorCollapseControl)
            this.indicatorCollapseControl.Hide()
            this.indicatorCollapseControl.enableBorder()
        }
    }

    private createButtonMoveToLastBar(layer: CanvasLayer): void {
        if (GlobalImageManager.Instance.moveToLastBar) {
            this.buttonMoveToLastBar = new ImageControl(
                new ChartControlParams(
                    layer,
                    new LocationParams(0, 0, 24, 24),
                    ChartControlId.INDICATOR_MOVE_TO_LAST_BAR
                ),
                GlobalImageManager.Instance.moveToLastBar
            )
            // this.buttonMoveToLastBar.attachObserver(this)
            this.buttonMoveToLastBar.enableBorder()
            this.buttonMoveToLastBar.setToolTip(t('layers.buttons.move-to-last-bar-tooltip'))
            this.groupChartControlsBottomRightCorner.addControl(this.buttonMoveToLastBar)
            this.buttonMoveToLastBar.setParentControl(this.groupChartControlsBottomRightCorner)
        }
    }

    private createGroupControlsTopLeft(layer: CanvasLayer): void {
        const group = new GroupChartControls(
            new ChartControlParams(layer, new LocationParams(5, 40, 5, 5)),
            layer.tGdiPlusCanvas,
            EDirections.bottom,
            EDirections.left
        )
        layer.addControl(group)
        this.groupsChartControl.push(group)
        this.groupChartControlsTopLeftCorner = group
    }

    private createGroupControlsBottomRight(layer: CanvasLayer): void {
        const group = new GroupChartControls(
            new ChartControlParams(layer, new LocationParams(5, 5, 5, 5)),
            layer.tGdiPlusCanvas,
            EDirections.left,
            EDirections.bottom,
            70,
            10
        )
        layer.addControl(group)
        this.groupsChartControl.push(group)
        this.groupChartControlsBottomRightCorner = group
    }

    public createIndicatorControl(layer: CanvasLayer, indicator: TRuntimeIndicator): void {
        const indicatorFontStyle = new TMkFontStyle('Roboto Flex', TFontStyle.fsNone, 12, '#101828')
        const indicatorLastValueFont = indicatorFontStyle.clone()
        indicatorLastValueFont.color = '#2F80ED'

        const locationParam = new LocationParams(5, 5, 100, 24)
        const chartControlParams = new ChartControlParams(layer, locationParam)

        const indicatorConfigurationControl = new IndicatorConfigurationControl(
            indicator,
            this.chartWindow,
            layer,
            indicatorFontStyle,
            indicatorLastValueFont,
            chartControlParams
        )
        indicatorConfigurationControl.adjustControlWidth()
        // indicatorConfigurationControl.attachObserver(this)
        indicator.configurationControl = indicatorConfigurationControl

        if (this.indicatorCollapseControl.isCollapsed()) {
            indicatorConfigurationControl.Hide()
        } else {
            indicatorConfigurationControl.Show()
        }

        this.groupChartControlsTopLeftCorner.addControl(indicatorConfigurationControl)

        this.indicatorCollapseControl.Show()
        this.repositionCollapseButton4Indicators()
    }

    private repositionCollapseButton4Indicators() {
        const indicatorConfigurationControls = this.getIndicatorControls()
        if (indicatorConfigurationControls && indicatorConfigurationControls.length > 0) {
            const lastControl = this.groupChartControlsTopLeftCorner.getLastControlByLocation()
            if (lastControl) {
                const rect = lastControl.getLocation()
                rect.Top = rect.Bottom + 5
                rect.Bottom = rect.Top + this.indicatorCollapseControl.getHeight()
                rect.Left = this.indicatorCollapseControl.getLocation().Left
                rect.Right = this.indicatorCollapseControl.getLocation().Right
                this.indicatorCollapseControl.setLocation(rect)
            }
        } else {
            this.indicatorCollapseControl.Hide()
        }
    }

    private getIndicatorControls(): ChartControl[] | null {
        const controls = this.groupChartControlsTopLeftCorner.getControls()
        return controls.filter((control) => control instanceof IndicatorConfigurationControl)
    }

    public getLayerChartControls(): CanvasLayer {
        return this.layerChartControls
    }

    public getLayerBlockingChart(): LayerBlockingChart | null {
        return this.getManagerLayers().getLayerBlockingChart()
    }

    public getChangeSymbolControl(): ChangeSymbolButton | null {
        return this.changeSymbolControl
    }

    public deleteIndicatorConfigurationControl(indicator: TRuntimeIndicator): void {
        if (indicator.configurationControl && this._managerLayers) {
            this._managerLayers.deleteControl(indicator.configurationControl)
            this.groupChartControlsTopLeftCorner.deleteControl(indicator.configurationControl)
            indicator.configurationControl = null
            this.repositionCollapseButton4Indicators()
            // this.repositionCollapseButton4Indicators()
            // this.managerLayers.reDrawAll()
        }
    }

    public repositionAllGroups(): void {
        for (let i = 0; i < this.groupsChartControl.length; i++) {
            this.groupsChartControl[i].repositionControls()
        }
    }

    public updateAllLocationOnCanvasResize(): void {
        for (let i = 0; i < this.groupsChartControl.length; i++) {
            this.groupsChartControl[i].updateLocationOnCanvasResize()
        }
    }

    public onBrowserWndSizing(): void {
        this.getManagerLayers().resize(false)
        this.updateAllLocationOnCanvasResize()
    }

    public onMouseLeave(event: MouseEvent): void {
        const barInfoData = this.chartWindow.MainChart.GetLastBarInTesting()
        if (this.barInfoLabel && barInfoData) {
            this.barInfoLabel.setBarInfo(barInfoData, this.chartWindow.SymbolData.symbolInfo.decimals)
        }
        if (this.getManagerLayers()) {
            this.getManagerLayers().onMouseLeave(event)
            this.getManagerLayers().reDrawAll()
        }
    }

    public pressedCollapseControl(): void {
        const needCollapse = this.indicatorCollapseControl.switch()
        const indicatorConfControls = this.groupChartControlsTopLeftCorner
            .getControls()
            .filter((control) => control instanceof IndicatorConfigurationControl)

        if (indicatorConfControls) {
            for (const control of indicatorConfControls) {
                if (control) {
                    const indicatorConfigurationControl = control as IndicatorConfigurationControl
                    if (needCollapse) {
                        indicatorConfigurationControl.Hide()
                    } else {
                        indicatorConfigurationControl.Show()
                    }
                }
            }
        }

        if (this.getLayerChartControls()) {
            this.groupChartControlsTopLeftCorner.repositionControls()
            this.repositionCollapseButton4Indicators()
            this.layerChartControls.draw()
        }
    }

    public setSymbol(symbol: string): void {
        if (this.isReady()) {
            this.getManagerLayers().getLayerBlockingChart()?.setSymbolName(symbol)
            this.getChangeSymbolControl()?.getOwnerLayer().draw()
        }
    }

    public manageVisibilityControlsOnLayerByData(
        dataAvailability: TDataAvailability,
        chartOptions: TChartOptions
    ): void {
        if (this.isReady()) {
            switch (dataAvailability) {
                case TDataAvailability.da_NoMapYet:
                case TDataAvailability.da_NotSeekedYet: {
                    this.getLayerChartControls().isActive = false

                    if (
                        !this.getLayerBlockingChart()?.isActive ||
                        (this.getLayerBlockingChart()?.getState() !== EBlockingLayerState.loader &&
                            this.getLayerBlockingChart()?.getState() !== EBlockingLayerState.try)
                    ) {
                        this.getLayerBlockingChart()?.setColors({
                            background: chartOptions.ColorScheme.BackgroundColor
                        })
                        this.getLayerBlockingChart()?.switchStateTo(EBlockingLayerState.loader)
                    }
                    break
                }

                case TDataAvailability.da_MissingForThisDateRange:
                case TDataAvailability.da_SomeAvailable: {
                    // this.getLayerBlockingChart()?.switchStateTo('goto')
                    break
                }
                case TDataAvailability.da_AllAvailable: {
                    this.getLayerChartControls().isActive = true
                    if (this.getLayerBlockingChart()?.isActive) {
                        this.getLayerBlockingChart()?.stopLayer()
                    }
                    break
                }
                default:
            }
        }
    }

    public getOneClickTradingControl(): OneClickTrading | null {
        return this._oneClickTradingControl
    }
}
