import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { TRuntimeIndicator } from './DllIndicatorUnit'
import { TIndicatorsList } from './IndicatorsList'
import { IndicatorsJSON } from '@fto/lib/ProjectAdapter/Types'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TIndicator } from '@fto/lib/extension_modules/indicators/IndicatorUnit'
import { TOffsStringList } from '@fto/lib/ft_types/common/OffsStringList'
import GlobalIndicatorDescriptors from '@fto/lib/globals/GlobalIndicatorDescriptors'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'

export class TRuntimeIndicatorsList extends TIndicatorsList<TRuntimeIndicator> {
    constructor() {
        super()
    }

    public toJSON(): IndicatorsJSON {
        const result: IndicatorsJSON = []

        for (const indicator of this) {
            result.push(indicator.toJSON())
        }

        return result
    }

    public fromJSON(indicatorsList: any, chartWindow: TChartWindow): void {
        this.Clear()
        for (const indicator of indicatorsList) {
            const ShortName = indicator.ShortName
            let list1: TOffsStringList

            let indicatorDescriptor: TIndicator | null = null

            if (indicatorDescriptor === null) {
                try {
                    // if not loaded - create and load new one
                    indicatorDescriptor = GlobalIndicatorDescriptors.BuiltInIndicators.findByName(ShortName)
                    if (indicatorDescriptor === null) {
                        throw new StrangeError(`Indicator ${ShortName} not found`)
                    }

                    // create indicator and load options
                    const runtimeIndicator = chartWindow.CreateIndicator(
                        indicatorDescriptor,
                        chartWindow.ChartOptions.Timeframe
                    )
                    list1 = new TOffsStringList()
                    list1.LoadFromString(indicator.Options)
                    try {
                        runtimeIndicator.options.LoadFromList(list1)
                    } catch (error) {
                        StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
                    }

                    if (runtimeIndicator) {
                        chartWindow.CreateIndicatorAndRecount(
                            runtimeIndicator,
                            chartWindow.ChartOptions.Timeframe,
                            true
                        )
                    }

                    if (!indicator.IsVisible) runtimeIndicator.Hide() // indicator.IsVisible is visible by default

                    for (const [index, buffer] of runtimeIndicator.VisibleBuffers.entries()) {
                        if (indicator.BufferVisibility[index]) {
                            buffer.show()
                        } else {
                            buffer.hide()
                        }
                    }

                    runtimeIndicator.RefreshLevels() // must be before SaveOptions or levels will be lost
                    runtimeIndicator.SaveOptions()
                    runtimeIndicator.RefreshOptions()
                } catch (error) {
                    StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
                }
            }
        }
    }

    ProcessChartEvent(id: number, lparam: number, dparam: number, sparam: string): void {
        for (let i = 0; i < this.Count; i++) {
            try {
                this[i].ProcessChartEvent(id, lparam, dparam, sparam)
            } catch (error) {
                DebugUtils.error(error)
                //TODO: Handle exception
            }
        }
    }

    ReountIndicatorsForRange(rangeStart: number, rangeEnd: number): void {
        for (let i = 0; i < this.Count; i++) {
            this[i].RecountValuesForTheRange(rangeStart, rangeEnd)
        }
    }

    GetEqual(IndicatorName: string, Timeframe: number, parameters: string): TRuntimeIndicator | null {
        for (let i = 0; i < this.Count; i++) {
            if (
                this[i].ShortName.toLowerCase() === IndicatorName.toLowerCase() &&
                this[i].GetImportantOptions() === parameters &&
                this[i].Timeframe === Timeframe
                //&& !(this[i].BarsArray instanceof TFMRenkoBarsArray) TODO: do not remove this line yet, we will use it when Range/Renko bars are implemented
            ) {
                return this[i]
            }
        }
        return null
    }
}
