import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export type TimeZoneDesc = {
    id: number //index in array
    value: string
    offsetDT: number // Offset in delphi time from UTC
    offsetSeconds: number // Offset in seconds from UTC
    description: string // Description of the time zone, including countries and regions
}

export class TimeZoneManager {
    private timeZones: TimeZoneDesc[] = []

    constructor() {
        this.initializeTimeZones()
    }

    // Method to initialize the list of time zones
    private initializeTimeZones() {
        this.timeZones = [
            {
                id: 0,
                value: 'UTC-12',
                offsetDT: -12 * DateUtils.OneHour,
                offsetSeconds: -12 * 60 * 60,
                description: 'Baker Island, Howland Island (U.S., uninhabited)'
            },
            {
                id: 1,
                value: 'UTC-11',
                offsetDT: -11 * DateUtils.OneHour,
                offsetSeconds: -11 * 60 * 60,
                description: 'American Samoa, Niue'
            },
            {
                id: 2,
                value: 'UTC-10',
                offsetDT: -10 * DateUtils.OneHour,
                offsetSeconds: -10 * 60 * 60,
                description: 'Hawaii, Tahiti, Cook Islands'
            },
            {
                id: 3,
                value: 'UTC-9',
                offsetDT: -9.5 * DateUtils.OneHour,
                offsetSeconds: -9.5 * 60 * 60,
                description: 'Marquesas Islands'
            },
            {
                id: 4,
                value: 'UTC-9',
                offsetDT: -9 * DateUtils.OneHour,
                offsetSeconds: -9 * 60 * 60,
                description: 'Alaska, Gambier Islands'
            },
            {
                id: 5,
                value: 'UTC-8',
                offsetDT: -8 * DateUtils.OneHour,
                offsetSeconds: -8 * 60 * 60,
                description: 'Pacific Time (U.S. and Canada), Pitcairn Islands'
            },
            {
                id: 6,
                value: 'UTC-7',
                offsetDT: -7 * DateUtils.OneHour,
                offsetSeconds: -7 * 60 * 60,
                description: 'Mountain Time (U.S. and Canada)'
            },
            {
                id: 7,
                value: 'UTC-6',
                offsetDT: -6 * DateUtils.OneHour,
                offsetSeconds: -6 * 60 * 60,
                description: 'Central Time (U.S. and Canada), Mexico, Central America'
            },
            {
                id: 8,
                value: 'UTC-5',
                offsetDT: -5 * DateUtils.OneHour,
                offsetSeconds: -5 * 60 * 60,
                description: 'Eastern Time (U.S. and Canada), Cuba, Colombia, Peru'
            },
            {
                id: 9,
                value: 'UTC-4:30',
                offsetDT: -4.5 * DateUtils.OneHour,
                offsetSeconds: -4.5 * 60 * 60,
                description: 'Venezuela'
            },
            {
                id: 10,
                value: 'UTC-4',
                offsetDT: -4 * DateUtils.OneHour,
                offsetSeconds: -4 * 60 * 60,
                description: 'Atlantic Time (Canada), Bolivia, Paraguay, Caribbean'
            },
            {
                id: 11,
                value: 'UTC-3:30',
                offsetDT: -3.5 * DateUtils.OneHour,
                offsetSeconds: -3.5 * 60 * 60,
                description: 'Newfoundland (Canada)'
            },
            {
                id: 12,
                value: 'UTC-3',
                offsetDT: -3 * DateUtils.OneHour,
                offsetSeconds: -3 * 60 * 60,
                description: 'Argentina, Brazil, Uruguay, Greenland'
            },
            {
                id: 13,
                value: 'UTC-2',
                offsetDT: -2 * DateUtils.OneHour,
                offsetSeconds: -2 * 60 * 60,
                description: 'South Georgia and the South Sandwich Islands'
            },
            {
                id: 14,
                value: 'UTC-1',
                offsetDT: -1 * DateUtils.OneHour,
                offsetSeconds: -1 * 60 * 60,
                description: 'Azores, Cape Verde'
            },
            {
                id: 15,
                value: 'UTC+0',
                offsetDT: 0,
                offsetSeconds: 0,
                description: 'Greenwich Mean Time (GMT), United Kingdom, Iceland, Portugal'
            },
            {
                id: 16,
                value: 'UTC+1',
                offsetDT: 1 * DateUtils.OneHour,
                offsetSeconds: 1 * 60 * 60,
                description: 'Central European Time (CET), West Africa'
            },
            {
                id: 17,
                value: 'UTC+2',
                offsetDT: 2 * DateUtils.OneHour,
                offsetSeconds: 2 * 60 * 60,
                description: 'Eastern European Time (EET), Egypt, South Africa, Israel'
            },
            {
                id: 18,
                value: 'UTC+3',
                offsetDT: 3 * DateUtils.OneHour,
                offsetSeconds: 3 * 60 * 60,
                description: 'Moscow Time, Saudi Arabia, Kenya, Turkey'
            },
            {
                id: 19,
                value: 'UTC+3:30',
                offsetDT: 3.5 * DateUtils.OneHour,
                offsetSeconds: 3.5 * 60 * 60,
                description: 'Iran'
            },
            {
                id: 20,
                value: 'UTC+4',
                offsetDT: 4 * DateUtils.OneHour,
                offsetSeconds: 4 * 60 * 60,
                description: 'United Arab Emirates, Oman, Azerbaijan'
            },
            {
                id: 21,
                value: 'UTC+4:30',
                offsetDT: 4.5 * DateUtils.OneHour,
                offsetSeconds: 4.5 * 60 * 60,
                description: 'Afghanistan'
            },
            {
                id: 22,
                value: 'UTC+5',
                offsetDT: 5 * DateUtils.OneHour,
                offsetSeconds: 5 * 60 * 60,
                description: 'Pakistan, Kazakhstan, Uzbekistan'
            },
            {
                id: 23,
                value: 'UTC+5:30',
                offsetDT: 5.5 * DateUtils.OneHour,
                offsetSeconds: 5.5 * 60 * 60,
                description: 'India, Sri Lanka'
            },
            {
                id: 24,
                value: 'UTC+5:45',
                offsetDT: 5.75 * DateUtils.OneHour,
                offsetSeconds: 5.75 * 60 * 60,
                description: 'Nepal'
            },
            {
                id: 25,
                value: 'UTC+6',
                offsetDT: 6 * DateUtils.OneHour,
                offsetSeconds: 6 * 60 * 60,
                description: 'Bangladesh, Bhutan, Kyrgyzstan'
            },
            {
                id: 26,
                value: 'UTC+6:30',
                offsetDT: 6.5 * DateUtils.OneHour,
                offsetSeconds: 6.5 * 60 * 60,
                description: 'Myanmar, Cocos Islands'
            },
            {
                id: 27,
                value: 'UTC+7',
                offsetDT: 7 * DateUtils.OneHour,
                offsetSeconds: 7 * 60 * 60,
                description: 'Thailand, Vietnam, Cambodia, Laos'
            },
            {
                id: 28,
                value: 'UTC+8',
                offsetDT: 8 * DateUtils.OneHour,
                offsetSeconds: 8 * 60 * 60,
                description: 'China, Western Australia, Singapore, Malaysia'
            },
            {
                id: 29,
                value: 'UTC+8:45',
                offsetDT: 8.75 * DateUtils.OneHour,
                offsetSeconds: 8.75 * 60 * 60,
                description: 'Western Australia (Eucla)'
            },
            {
                id: 30,
                value: 'UTC+9',
                offsetDT: 9 * DateUtils.OneHour,
                offsetSeconds: 9 * 60 * 60,
                description: 'Japan, South Korea, Palau'
            },
            {
                id: 31,
                value: 'UTC+9:30',
                offsetDT: 9.5 * DateUtils.OneHour,
                offsetSeconds: 9.5 * 60 * 60,
                description: 'Central Australia (Northern Territory)'
            },
            {
                id: 32,
                value: 'UTC+10',
                offsetDT: 10 * DateUtils.OneHour,
                offsetSeconds: 10 * 60 * 60,
                description: 'Eastern Australia, Guam, Vladivostok'
            },
            {
                id: 33,
                value: 'UTC+10:30',
                offsetDT: 10.5 * DateUtils.OneHour,
                offsetSeconds: 10.5 * 60 * 60,
                description: 'Lord Howe Island (Australia)'
            },
            {
                id: 34,
                value: 'UTC+11',
                offsetDT: 11 * DateUtils.OneHour,
                offsetSeconds: 11 * 60 * 60,
                description: 'Solomon Islands, Vanuatu, New Caledonia'
            },
            {
                id: 35,
                value: 'UTC+12',
                offsetDT: 12 * DateUtils.OneHour,
                offsetSeconds: 12 * 60 * 60,
                description: 'Fiji, Marshall Islands, New Zealand'
            },
            {
                id: 36,
                value: 'UTC+12:45',
                offsetDT: 12.75 * DateUtils.OneHour,
                offsetSeconds: 12.75 * 60 * 60,
                description: 'Chatham Islands (New Zealand)'
            },
            {
                id: 37,
                value: 'UTC+13',
                offsetDT: 13 * DateUtils.OneHour,
                offsetSeconds: 13 * 60 * 60,
                description: 'Tonga, Samoa, Kiribati (Phoenix Islands)'
            },
            {
                id: 38,
                value: 'UTC+14',
                offsetDT: 14 * DateUtils.OneHour,
                offsetSeconds: 14 * 60 * 60,
                description: 'Line Islands (Kiribati)'
            }
        ]
    }

    public getTimezoneDescByTimezoneOffsetInHours(timezoneOffsetInHours: number): TimeZoneDesc {
        const secondsInHour = 60 * 60
        const index = this.timeZones.findIndex((tz) => tz.offsetSeconds === timezoneOffsetInHours * secondsInHour)
        if (index >= 0) {
            return this.timeZones[index]
        } else {
            throw new StrangeError('Timezone in hours not found', timezoneOffsetInHours)
        }
    }

    public getDefaulTimeZone(): TimeZoneDesc {
        return this.timeZones[15]
    }

    public zoneUp(currentTimeZoneDesc: TimeZoneDesc): TimeZoneDesc {
        const index = currentTimeZoneDesc.id + 1
        if (index < this.timeZones.length) {
            return this.timeZones[index]
        }
        return currentTimeZoneDesc
    }

    public zoneDown(currentTimeZoneDesc: TimeZoneDesc): TimeZoneDesc {
        const index = currentTimeZoneDesc.id - 1
        if (index >= 0) {
            return this.timeZones[index]
        }
        return currentTimeZoneDesc
    }

    public getAllTimeZones(): TimeZoneDesc[] {
        return this.timeZones
    }

    public getTimeZoneByName(name: string): TimeZoneDesc | undefined {
        return this.timeZones.find((tz) => tz.value === name)
    }

    // Method to get the current time in a specific time zone
    public getCurrentTimeInTimeZone(name: string): Date | undefined {
        const timeZone = this.getTimeZoneByName(name)
        if (!timeZone) {
            return undefined
        }
        const utcTime = new Date().getTime()
        const localTime = new Date(utcTime + timeZone.offsetDT)
        return localTime
    }
}

export default TimeZoneManager
