import { DateUtils, TDateTime } from '../../../delphi_compatibility/DateUtils'
import { TProjSymInfo } from './ProjSymbolInfo'
import { TTestingMethod, TTickGenerationMethod } from './BasicEnums'
import { THistoryFilters } from './HistoryFilters'
import { TNewsFilters } from './NewsFilters'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'

export class TProjectInfo {
    //TODO: get rid of !
    name!: string
    symbols!: TProjSymInfo[]
    FromDate!: TDateTime
    ToDate!: TDateTime
    GenerationMethod!: TTickGenerationMethod
    UseTickData!: boolean
    UseFloatingSpread!: boolean
    TestingMethod!: TTestingMethod
    StartDate!: TDateTime
    timeframes!: string
    deposit!: number
    reset!: boolean
    ForwardTestingOnly!: boolean
    HistoryFilters!: THistoryFilters
    NewsFilters!: TNewsFilters
    //DST: TDstType = TDstType.dst_European

    private _approximateLastTickTime: TDateTime | undefined = undefined //used while the Seek is still in progress
    // public get ApproximateLastTickTime(): TDateTime {
    //     if (this._approximateLastTickTime === null || DateUtils.IsEmpty(this._approximateLastTickTime)) {
    //         throw new StrangeError('ApproximateLastTickTime is empty in getter')
    //     }
    //     return this._approximateLastTickTime
    // }

    public SetApproximateLastTickTime(value: TDateTime): void {
        this._approximateLastTickTime = value
    }

    private _lastTickTime: TDateTime | undefined = undefined

    GetLastProcessedTickTimeWithTimezoneAndDST(): TDateTime {
        return GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(
            this.GetLastProcessedTickTime(true)
        )
    }

    public GetLastProcessedTickTime(allowApproximateIfSeekIsInProgress = false): TDateTime {
        if (this._lastTickTime === undefined || DateUtils.IsEmpty(this._lastTickTime)) {
            if (allowApproximateIfSeekIsInProgress && this._approximateLastTickTime !== undefined) {
                return this._approximateLastTickTime
            }
            throw new StrangeError('LastTickTime is empty in getter')
        }
        return this._lastTickTime
    }

    public SetPreciseLastTickTime(value: TDateTime): void {
        if (DateUtils.IsEmpty(value)) {
            throw new StrangeError('LastTickTime is empty in setter')
        }
        this._lastTickTime = value
    }
}
