import React, { ChangeEvent, ComponentPropsWithoutRef, forwardRef, MutableRefObject, useCallback, useRef } from 'react'

import cx from 'classnames'

import { InputContainer, OwnInputContainerProps as InputContainerProps } from '@fto/ui'
import { useAutofocus } from '@fto/ui/lib/hooks/useAutofocus'
import styles from './index.module.scss'
import { mergeRefs } from '@fto/ui/lib/utils/mergeRefs'
import { IconButton } from '@fto/icons'

const noop = () => {}
export const InputSizes = ['default', 'medium'] as const

type Props = Pick<InputContainerProps, 'prefix' | 'suffix' | 'success' | 'error' | 'disabled' | 'block'> & {
    value?: string | number
    onChange?: (value: string, e: ChangeEvent<HTMLInputElement>) => void
    pattern?: string | RegExp
    align?: 'left' | 'center' | 'right'
    style?: any
    reset?: boolean
    size?: (typeof InputSizes)[number]
}

type OtherNativeInputProps = Omit<ComponentPropsWithoutRef<'input'>, keyof Props>

export type InputProps = Props & OtherNativeInputProps

export const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            align = 'left',

            value = '',
            onChange = noop,

            success,
            error,
            prefix,
            suffix,
            disabled = false,
            block,

            pattern = null,
            autoFocus = false,
            className,
            reset = false,
            size = 'default',
            ...rest
        },
        ref
    ) => {
        const innerRef: MutableRefObject<any> = useRef(null)

        const handleChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                e.preventDefault()
                const newValue = e.target.value
                if (e.target.type === 'number' && !e.target.validity.valid && isNaN(e.target.valueAsNumber)) {
                    return
                }

                if (pattern) {
                    if (newValue.match(pattern)) {
                        onChange(newValue, e)
                    }
                } else {
                    onChange(newValue, e)
                }
            },
            [pattern, onChange]
        )

        const resetInput = useCallback(() => {
            if (innerRef.current) {
                innerRef.current.value = ''
            }
            onChange('', innerRef.current)
        }, [innerRef])

        useAutofocus({ ref: innerRef, enabled: autoFocus })

        const onContainerFocus = useCallback(() => {
            if (innerRef.current) {
                innerRef.current.focus()
            }
        }, [])

        return (
            <InputContainer
                variant='outline'
                prefix={prefix}
                suffix={suffix}
                success={success}
                error={error}
                disabled={disabled}
                className={className}
                block={block}
                onFocus={onContainerFocus}
                size={size}
            >
                <input
                    {...rest}
                    ref={mergeRefs(ref, innerRef)}
                    value={value}
                    disabled={disabled}
                    className={cx(styles.input, styles[`align-${align}`])}
                    onChange={handleChange}
                    autoFocus={autoFocus}
                    autoComplete='new-password'
                />
                {reset && value && (
                    <IconButton
                        onClick={resetInput}
                        size={12}
                        color={'var(--color-gray-600)'}
                        className={styles.reset}
                        name='close'
                    />
                )}
            </InputContainer>
        )
    }
)

Input.displayName = 'Input'

export default Input
