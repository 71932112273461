import { FiboRetracementJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { TBasicPaintTool } from '../../../charting/paint_tools/BasicPaintTool'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { TOffsStringList, TVarList } from '../../../ft_types/common/OffsStringList'
import { StrsConv } from '../../../ft_types/common/StrsConv'
import { PaintToolNames } from '../PaintToolNames'
import { TPaintToolType, TPointInfo } from '../PaintToolsAuxiliaryClasses'
import { TFiboPaintTool } from './ptFibo'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TPoint } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TChart } from '../../chart_classes/BasicChart'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TPtFiboRetracement extends TFiboPaintTool {
    private fClosedEnds: boolean
    private fExtraPixels: number
    private fShowPrice: boolean

    constructor(aChart: TChart) {
        // Call the parent constructor to initialize the base class part of this instance
        super(aChart, PaintToolNames.ptFiboRetracement)

        // Assign the tool type using the TypeScript equivalent of the Delphi enumeration
        this.fToolType = TPaintToolType.tt_Line

        // Define the maximum number of points for the Fibonacci Retracement tool
        this.fMaxPoints = 2

        // Indicate that this tool does not form a closed polygon
        this.fClosedPolygon = false

        // Set the cursor style to the appropriate custom cursor for this tool
        this.CursorStyle = CustomCursorPointers.crCursorFiboRetr

        // Set whether the ends of the tool are closed
        this.fClosedEnds = true

        // Define the number of extra pixels for the drawing, which may be used for extending lines
        this.fExtraPixels = 100

        // Indicate whether the price should be shown on the tool
        this.fShowPrice = true

        // Set the icon index for the tool's representation in the UI
        this.icon = 50

        // Initialize the Fibonacci levels array based on the Delphi constant
        // The levels are defined as a constant in Delphi, so we replicate them here
        const fibo: number[] = [0, 23.6, 38.2, 50, 61.8, 78.6, 100, 127.2, 161.8, 261.8]

        // Set the Fibonacci levels for the tool
        // Assuming SetLevels is a method that exists in the TypeScript class or its parent
        this.SetLevels(fibo, 3)

        // Assign the edit dialog form for the tool
        // Assuming FiboRetracementFrm is a form that has been implemented in TypeScript
        // this.fEditDialog = FiboRetracementFrm;
        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptFiboRetracement)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptFiboRetracement)
        }

        if (!styles) throw new StrangeError('Default styles for FiboRetracement are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
        this.fLevels.ImportFromStr(styles.levels)
    }

    clone(): TPtFiboRetracement {
        const cloneObj = new TPtFiboRetracement(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        cloneObj.fClosedEnds = this.fClosedEnds
        cloneObj.fExtraPixels = this.fExtraPixels
        cloneObj.fShowPrice = this.fShowPrice
        return cloneObj
    }

    public toJson(): FiboRetracementJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            ClosedEnds: this.fClosedEnds,
            ExtraPixels: this.fExtraPixels,
            ShowPrice: this.fShowPrice
        }
    }

    public fromJSON(json: FiboRetracementJSON) {
        super.fromJSON(json)

        this.fClosedEnds = json.ClosedEnds
        this.fExtraPixels = json.ExtraPixels
        this.fShowPrice = json.ShowPrice
    }

    LoadFromList(list: TOffsStringList, all = true): void {
        super.LoadFromList(list, all)
        const vars = new TVarList()
        try {
            vars.LoadFromList(list)
            this.fLevels.ImportFromStr(vars.GetValue('Levels'))
            this.fClosedEnds = vars.GetBool('ClosedEnds')
            this.fExtraPixels = vars.GetInt('ExtraPixels')
            this.fShowPrice = vars.GetBool('ShowPrice')
        } finally {
            vars.Clear()
        }
    }

    SaveToList(list: TOffsStringList, all = true): void {
        super.SaveToList(list, all)

        const vars = new TVarList()
        vars.AddVarStr('Levels', this.fLevels.ExportToStr(3))
        vars.AddVarBool('ClosedEnds', this.fClosedEnds)
        vars.AddVarInt('ExtraPixels', this.fExtraPixels)
        vars.AddVarBool('ShowPrice', this.fShowPrice)
        vars.SaveToList(list, '')
        vars.Clear()
    }

    Paint(): void {
        if (this.fHighlighted) {
            const points = [
                new TPoint(this.fPoints[0].x, this.fPoints[0].y),
                new TPoint(this.fPoints[1].x, this.fPoints[1].y)
            ]
            this.PaintHoverLine(points)
        }
        super.Paint()

        if (this.fPoints.Count < 2) {
            return
        }
        this.fExtendsRay.leftfPoints.length = 0
        this.fExtendsRay.rightfPoints.length = 0

        const x1 = Math.min(this.fPoints[0].x, this.fPoints[1].x)
        const price1 = this.fPoints[0].price
        const price2 = this.fPoints[1].price
        const canvas = this.fChart.GdiCanvas

        let x2 = this.chartPaintRect.Right
        if (this.fClosedEnds) {
            x2 = Math.min(x2, Math.max(this.fPoints[0].x, this.fPoints[1].x) + this.fExtraPixels)
        }

        for (let i = 0; i < this.fLevels.Count; i++) {
            const level = this.fLevels[i]

            if (!level.isActive) {
                continue
            }

            this.fBrushColor = level.style.color

            const price = price2 + ((price1 - price2) * level.value) / 100
            const y = this.fChart.GetY(price)

            canvas.MoveTo(x1, y)
            canvas.LineTo(x2, y, level.pen)

            const startLeftRay = new TPointInfo(x1, y)
            startLeftRay.price = price
            const endLeftRay = new TPointInfo(x2, y)
            endLeftRay.price = price

            this.fExtendsRay.leftfPoints.push({ [i]: [endLeftRay, startLeftRay, level.pen] })
            this.fExtendsRay.rightfPoints.push({ [i]: [startLeftRay, endLeftRay, level.pen] })
            this.PaintExtendsRay()

            // paint labels
            let s = level.text
            if (this.fShowPrice) {
                s = `${s} (${StrsConv.StrDouble(price, this.fChart.ScaleDecimals())})`
            }

            const textWidth = this.fChart.GdiCanvas.TextWidth(s)
            const textHeight = this.fChart.GdiCanvas.TextHeight('0')
            canvas.textOut(x2 - textWidth - 5, y - textHeight, s, this.font, level.brush)
        }
    }

    assign(tool: TBasicPaintTool, isCopy = false): void {
        super.assign(tool, isCopy)

        if (tool instanceof TPtFiboRetracement) {
            // Assuming fLevels is a property of TPtFiboRetracement that has an assign method
            this.fLevels.Assign(tool.fLevels)
            this.fClosedEnds = tool.fClosedEnds
            this.fExtraPixels = tool.fExtraPixels
            this.fShowPrice = tool.fShowPrice
        } else {
            //TODO: Handle the case where the tool is not an instance of TPtFiboRetracement
            throw new TypeError('The tool assigned is not an instance of TPtFiboRetracement.')
        }
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            levels: {
                value: this.fLevels.map((level) => ({
                    id: level.id,
                    value: level.value,
                    text: level.text,
                    isActive: level.isActive,
                    style: level.pen.getPenStyleFromPattern(),
                    color: level.pen.color,
                    width: level.pen.width,
                    opacity: level.pen.opacity
                })),
                type: 'levels',
                key: 'levels',
                label: 'levels'
            },
            extendLeft: {
                key: 'extendLeft',
                value: this.fExtendsRay.left,
                label: 'Extend to the left',
                type: 'checkbox',
                disabled: false,
                isOptional: true
            },
            extendRight: {
                key: 'extendRight',
                value: this.fExtendsRay.right,
                label: 'Extend to the right',
                type: 'checkbox',
                disabled: false,
                isOptional: true
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, {
            toolType: PaintToolNames.ptFiboRetracement,
            toolName: 'fiboRetracement'
        })
    }

    ImportFromDialog(): void {
        const { resetToolSettings, getKeyValueData } = GraphToolStore // Use the store/context

        const formattedToolSettings = getKeyValueData()

        const { description, lineStyle, levels } = formattedToolSettings

        this.chart.ChartWindow.saveStateWithNotify()

        this.description = description
        this.fExtendsRay.left = formattedToolSettings.extendLeft
        this.fExtendsRay.right = formattedToolSettings.extendRight

        this.fLineStyle = lineStyle
        this.updateLevelsFromModal(levels)

        this.saveToManager()
        resetToolSettings()
    }
    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }
    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }
    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptFiboRetracement, {
            lineStyle: this.fLineStyle.getSerialized(),
            toolName: PaintToolNames.ptFiboRetracement,
            levels: this.fLevels.ExportToStr(5)
        })
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptFiboRetracement, TPtFiboRetracement)
