import { TDateTime } from '../../../delphi_compatibility/DateUtils'
import { TDataDescriptor } from './DataDescriptionTypes'
import IEventEmitter from '../../../common/IEventEmitter'
import TEventsFunctionality from '../../../utils/EventsFunctionality'
import { TNoExactMatchBehavior } from '../chunks/ChunkEnums'
import CommonConstants from '../../common/CommonConstants'
import { INamed } from '@fto/lib/utils/INamed'
import { TDataRecordWithDate } from '../DataClasses/TDataRecordWithDate'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export default abstract class TBasicDataArray<T extends TDataRecordWithDate> implements IEventEmitter, INamed {
    protected fDataDescriptor: TDataDescriptor

    //for debug and diagnostics purposes
    private _name = ''
    public get DName(): string {
        return this._name
    }

    public toString(): string {
        return this.DName
    }

    public SetName(value: string): void {
        this._name = `${value}_${this.fDataDescriptor.symbolName}_${this.fDataDescriptor.timeframe}`
        this.Events.SetName(this._name)
    }

    public Events: TEventsFunctionality = new TEventsFunctionality('TBasicDataArray')

    constructor(aDataDescriptor: TDataDescriptor) {
        this.fDataDescriptor = aDataDescriptor
        this.SetName(`${this.fDataDescriptor.symbolName}_${this.fDataDescriptor.timeframe}`)
    }

    public abstract get LastItemInTestingIndex(): number
    public abstract get LastItemInTesting(): T | null
    public abstract get LastPossibleIndexInHistory(): number
    public abstract GetGlobalIndexByDate(
        DateTime: TDateTime,
        noExactMatchBehavior: TNoExactMatchBehavior,
        approximationAllowed: boolean
    ): number
    public abstract GetItemByGlobalIndex(globalIndex: number): T | null

    public get DataDescriptor(): TDataDescriptor {
        return this.fDataDescriptor
    }

    public get LastItemInTestingIndexAvailable(): boolean {
        return this.LastItemInTestingIndex !== CommonConstants.EMPTY_INDEX
    }

    // This method ensures that the given range (FirstPos to LastPos) is within the bounds of the array.
    // It corrects the range if it's outside the valid index range or if LastPos is less than FirstPos.
    // The method returns the corrected range as a tuple.
    public CorrectRange(firstPos: number, lastPos: number): [number, number] {
        // If the array is empty, set both positions to 0 to indicate an empty range.
        if (this.LastItemInTestingIndexAvailable) {
            // Use FitIndex to ensure the positions are within the valid index range of the array.
            firstPos = this.FitIndex(firstPos)
            lastPos = this.FitIndex(lastPos)
            // If LastPos is less than FirstPos, correct LastPos to be equal to FirstPos.
            if (lastPos < firstPos) {
                lastPos = firstPos
            }
        } else {
            firstPos = 0
            lastPos = 0
        }
        // Return the corrected range as a tuple.
        return [firstPos, lastPos]
    }

    // This method ensures that the given index is within the bounds of the array.
    // It takes an index and adjusts it to be within the range of 0 to LastItemInTestingIndex.
    public FitIndex(index: number): number {
        return Math.max(0, Math.min(this.LastPossibleIndexInHistory, index))
    }

    public IsIndexValid(index: number): boolean {
        //TODO: shouldn't we check for LastItemInTestingIndexAvailable here and include this.LastItemInTtestingIndex in bounds?
        return index >= 0 && index < this.LastItemInTestingIndex
    }
}
