import { TVarList } from '../OffsStringList'
import BasicConstants from './BasicConstants'
import { TCommApplyType, TSymbolType } from './BasicEnums'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { USDBaseSymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/USDBaseSymbolCalculationStrategy'
import { USDQouteSymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/USDQouteSymbolCalculationStrategy'
import { CrossSymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/CrossSymbolCalculationStrategy'
import { IndexSymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/IndexSymbolCalculationStrategy'
import { SymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/SymbolCalculationStrategy'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import ProcessingCoreUtils from '@fto/lib/processing_core/ProcessingCoreUtils'
import { FuturesSymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/FuturesSymbolCalculationStrategy'
import CommonUtils from '@fto/lib/ft_types/common/BasicClasses/CommonUtils'

export class TSymbolInfo {
    fDecimals: number
    spread: number
    MinPoint: number
    lot: number
    SwapLong: number
    SwapShort: number
    s_type: TSymbolType
    private _symbolName: string
    fLotCurrency: string
    BaseCurrency: string
    MarginCurrency: string
    MinDistToPrice: number
    Margin: number
    HedgedMargin: number
    Broker: string
    CreditShoulder: number
    UseCreditShoulder: boolean
    CommPerLot: number
    ComissionApplyType: TCommApplyType
    group!: string
    isLoadedFromServer = false

    private _symbolCalculationStrategy: SymbolCalculationStrategy | null = null

    constructor(symbolName: string) {
        this._symbolName = symbolName
        this.s_type = BasicConstants.st_CurrencyPair // Assuming st_CurrencyPair is a predefined value or constant
        this.fDecimals = 4
        this.spread = 3
        this.MinPoint = 0.00001
        this.lot = 100000
        this.fLotCurrency = symbolName.slice(0, 3) // Equivalent to Delphi's 'copy' function
        this.BaseCurrency = this.LotCurrency
        this.MarginCurrency = this.LotCurrency
        this.MinDistToPrice = this.spread
        this.Margin = 1000
        this.HedgedMargin = 500
        this.Broker = 'Advanced'
        this.SwapLong = 0
        this.SwapShort = 0
        this.CreditShoulder = 100
        this.UseCreditShoulder = true
        this.CommPerLot = 0
        this.ComissionApplyType = TCommApplyType.ca_OpenClose
    }

    public get SymbolName(): string {
        return this._symbolName
    }

    public set SymbolName(value: string) {
        this.SetName(value)
    }

    public SetName(value: string): void {
        this._symbolName = value
    }

    public get decimals(): number {
        return this.fDecimals
    }

    public set decimals(value: number) {
        this.SetDecimals(value)
    }

    public SetDecimals(value: number): void {
        this.fDecimals = value
        this.MinPoint = parseFloat((1 / Math.pow(10, value)).toFixed(value)) //similar to RoundTo(1/power(10, value), -value);
    }

    public get LotCurrency(): string {
        return this.fLotCurrency
    }

    public set LotCurrency(value: string) {
        this.SetLotCurrency(value)
    }

    public SetLotCurrency(value: string): void {
        this.fLotCurrency = value
    }

    private ConvertNumberToTCommApplyType(value: number): TCommApplyType {
        //TODO: add bounds checking
        return value as TCommApplyType
    }

    public LoadFromList(vars: TVarList): void {
        this.SymbolName = vars.GetValue('name')
        this.s_type = vars.GetInt('type')
        this.decimals = vars.GetInt('decimals')
        this.spread = vars.GetInt('spread')
        this.lot = vars.GetInt('lot')
        this.LotCurrency = vars.GetValue('LotCurrency')
        this.BaseCurrency = vars.GetValue('BaseCurrency')
        this.SwapLong = vars.GetDouble('SwapLong')
        this.SwapShort = vars.GetDouble('SwapShort')
        this.MinDistToPrice = vars.GetInt('MinDistToPrice')
        this.Margin = vars.GetDouble('Margin')
        this.HedgedMargin = vars.GetDouble('HedgedMargin')
        this.MarginCurrency = vars.GetValue('MarginCurrency')
        this.Broker = vars.GetValue('Broker')
        this.CreditShoulder = vars.GetInt('CreditShoulder')
        this.UseCreditShoulder = vars.GetBool('UseCreditShoulder')
        this.CommPerLot = vars.GetDouble('CommPerLot')
        this.ComissionApplyType = this.ConvertNumberToTCommApplyType(vars.GetInt('ApplyCommission'))
        this.group = vars.GetValue('group')
    }

    public setSymbolInfoFromServer(data: any): void {
        this.decimals = data.Decimals
        this.BaseCurrency = data.BaseCurrency
        this.LotCurrency = data.LotCurrency
        this.MarginCurrency = data.MarginCurrency
        this.spread = data.Spread
        this.MinPoint = data.Point
        this.CreditShoulder = data.CreditShoulder
        this.UseCreditShoulder = data.UseCreditShoulder
        this.SwapLong = data.SwapLong
        this.SwapShort = data.SwapShort
        this.Margin = data.Margin
        this.HedgedMargin = data.HedgedMargin
        this.Broker = data.Broker
        this.CommPerLot = data.CommPerLot
        this.ComissionApplyType = data.ApplyCommission
        this.MinDistToPrice = data.MinDistToPrice
        this.lot = data.Lot
        this.isLoadedFromServer = true
        this.group = data.Group
        if (data.Group === 'Indexes') {
            this.s_type = TSymbolType.st_Index
        }
        if (data.Group === 'Futures') {
            this.s_type = TSymbolType.st_Stock
        }
    }

    public initializeSymbolCalculationStrategy(): void {
        if (CommonUtils.IsInUnitTest && this._symbolName === 'EURUSD') {
            this.group = 'Majors'
        } else if (CommonUtils.IsInUnitTest) {
            throw new StrangeError('Symbol group is not set')
        }

        if (this._symbolCalculationStrategy) {
            throw new StrangeError('SymbolCalculationStrategy is already initialized')
        }
        const symbolData = GlobalSymbolList.SymbolList.GetExistingSymbol_ThrowErrorIfNull(this.SymbolName)

        switch (this.group) {
            case 'Crypto':
            case 'Majors': {
                if (this.SymbolName.endsWith('USD')) {
                    this._symbolCalculationStrategy = new USDQouteSymbolCalculationStrategy(symbolData)
                } else if (this.SymbolName.startsWith('USD')) {
                    this._symbolCalculationStrategy = new USDBaseSymbolCalculationStrategy(symbolData)
                } else {
                    throw new StrangeError('Not found USD base or quote symbol for majors group')
                }

                break
            }
            case 'Exotic':
            case 'Crosses': {
                const USDConversationSymbol = ProcessingCoreUtils.GetSymbolForConversionToUSD(symbolData)
                if (!USDConversationSymbol || USDConversationSymbol.symbolName === this.SymbolName) {
                    throw new StrangeError('USD conversation symbol is not found!')
                }

                const conversationSymbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(
                    USDConversationSymbol.symbolName
                )

                this._symbolCalculationStrategy = new CrossSymbolCalculationStrategy(
                    symbolData,
                    conversationSymbolData,
                    USDConversationSymbol.isUSDBaseCurrency,
                    USDConversationSymbol.isNeedConversionToSymbolQuoteCurrency
                )

                break
            }
            case 'Futures': {
                this._symbolCalculationStrategy = new FuturesSymbolCalculationStrategy(symbolData)
                break
            }
            case 'Indexes': {
                this._symbolCalculationStrategy = new IndexSymbolCalculationStrategy(symbolData)

                break
            }
            case 'Commodities':
            case 'Metals': {
                this._symbolCalculationStrategy = new USDQouteSymbolCalculationStrategy(symbolData)
                break
            }

            default: {
                throw new StrangeError('We do not have a symbol calculation strategy for this group')
            }
        }
    }

    public get isSymbolCalculationStrategyInitialized(): boolean {
        return !!this._symbolCalculationStrategy
    }

    public getSymbolCalculationStrategy(): SymbolCalculationStrategy {
        if (!this._symbolCalculationStrategy) {
            throw new StrangeError('SymbolCalculationStrategy is not initialized')
        }

        return this._symbolCalculationStrategy
    }
}
