import { Button, Flex, Typography, addModal } from '@fto/ui'
import styles from '../../profile.module.scss'
import { Icon } from '@fto/icons'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { FC, useMemo } from 'react'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'
import dayjs from 'dayjs'
import { Trans, useTranslation } from 'react-i18next'

export const SubscriptionInfoSuspended: FC = () => {
    const { t } = useTranslation()

    const { trialPeriodEnd } = useAppSelector($getUser)

    const date = useMemo(() => {
        return dayjs(trialPeriodEnd, 'MM/DD/YYYY h:mm A').locale('en').format('MMMM DD, YYYY')
    }, [trialPeriodEnd])
    return (
        <Flex direction='column' className={styles.subscriptionInfo}>
            <Flex justifyContent='space-between' alignItems='flex-end' className={styles.infoContent}>
                <Flex alignItems='flex-start' direction='column' gap={4}>
                    <Typography type='h4-bold'>{t('profile.trial')}</Typography>
                    <Flex className={styles.warningWrapper} gap={4}>
                        <Icon name='attention' size={18} color='var(--color-orange-500)' />
                        <Typography type='interface-regular'>
                            <Trans
                                i18nKey='profile.subscriptionInfoSuspended'
                                components={{ date: <Typography type='interface-regular'>{date}</Typography> }}
                            />
                        </Typography>
                    </Flex>
                </Flex>
                <Flex className={styles.infoButton}>
                    <Button
                        label={t('profile.renew')}
                        type='marketing'
                        size='small'
                        onClick={() => {
                            addModal(MODAL_NAMES.userProfile.renewSubscription)
                        }}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}
