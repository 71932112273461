import { Flex, InputField, Typography } from '@fto/ui'
import { FC, useCallback, useState } from 'react'

import styles from './index.module.scss'
import { Icon } from '@fto/icons'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { useTranslation } from 'react-i18next'

type Props = {
    type: 'deposit' | 'withdraw'
    amount: number | ''
    setAmount: (value: number | '') => void
    comment: string
    setComment: (value: string) => void
}

const Content: FC<Props> = ({ type, amount, comment, setAmount, setComment }) => {
    const { t } = useTranslation()

    const [isError, setIsError] = useState(false)
    const balance = GlobalProcessingCore.ProcessingCore.Balance

    const onChangeAmount = useCallback(
        (value: string) => {
            if (value !== '' || Number(value) !== 0) {
                setIsError(false)
            }
            setAmount(value === '' ? '' : Number(value))
        },
        [setAmount]
    )

    const onBlurAmount = useCallback(() => {
        if (amount === '' || amount === 0) {
            setIsError(true)
        } else {
            setAmount(Number(amount.toFixed(2)))
        }
    }, [setAmount, setIsError, amount])

    const onChangeComment = useCallback(
        (value: string) => {
            if (value.length > 100) {
                return
            }
            setComment(value)
        },
        [setComment]
    )

    const label = (
        <Flex gap={4}>
            <Typography>{t('balanceModal.' + (type === 'withdraw' ? 'toWithdraw' : 'toDeposit'))}</Typography>
            <Typography color='gray-600'>(USD)</Typography>
        </Flex>
    )

    return (
        <Flex block direction='column' gap={16}>
            <Flex block alignItems='center' gap={4} className={styles.currentBalance}>
                <Typography type='interface-medium'>{t('balanceModal.currentBalance')}</Typography>{' '}
                <Typography type='interface-regular'>{balance.toFixed(2)}</Typography>
            </Flex>
            <InputField
                block
                label={label}
                type='number'
                min={0}
                value={amount}
                onChange={onChangeAmount}
                onBlur={onBlurAmount}
                error={isError}
            />
            <InputField
                block
                label={t('balanceModal.comment')}
                type='text'
                value={comment}
                onChange={onChangeComment}
            />
            <Flex className={styles.warning} gap={8}>
                <Icon size={16} name='info' color={'var(--color-orange-600)'} />
                <Flex direction='column' gap={8}>
                    <Typography type='interface-semibold' color='orange-700'>
                        {t('balanceModal.warningHeader')}
                    </Typography>
                    <Typography type='interface-regular' color='orange-700'>
                        {t('balanceModal.warningText')}
                    </Typography>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Content
