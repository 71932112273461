import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'

export abstract class SymbolCalculationStrategy {
    protected symbolData: TSymbolData

    public abstract getPointCostForOneStandardLot(operationType: TTradePositionType | undefined): number

    public abstract calculateMarginRequirementForSymbol(
        operationType: TTradePositionType | undefined,
        openPrice: number | undefined
    ): number

    constructor(symbolData: TSymbolData) {
        this.symbolData = symbolData
    }
}
